export const fetchProjectRole = () => (dispatch) => {
  return dispatch({
    type: "PROJECT_ROLE",
    payload: {
      request: {
        url: `/api/project-role`,
        method: "GET",
      },
    },
  });
};

export const createRole = (roleObj) => (dispatch) => {
  return dispatch({
    type: "CREATE_ROLE",
    payload: {
      request: {
        url: `/api/project-role`,
        method: "POST",
        data: roleObj,
      },
    },
  });
};

export const removeRole = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_ROLE",
    payload: {
      request: {
        url: `/api/project-role/${id}`,
        method: "DELETE",
      },
    },
  });
};
