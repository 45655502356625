import { FlatList, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../../components/header";
import RoundButton from "../../../components/roundButton";
import SelectItemWithCheckbox from "../../../components/selectItemWithCheckbox/selectItemWithCheckbox";
import { useFocusEffect } from "@react-navigation/native";
import { fetchIndividualService } from "../../../redux/actions/individualService.action";
import { primaryColor } from "../../../components/utils";
import { createProject } from "../../../redux/actions/project.action";
const SalesServiceSelection = ({
  user,
  navigation,
  individualServices,
  fetchIndividualService,
  selectedProject,
  createProject,
}) => {
  const [selectedServices, setSelectedServices] = useState(
    selectedProject?.services ?? []
  );
  const addToSelectedServices = (item) => {
    if (selectedServices.find((x) => x._id === item._id)) {
      setSelectedServices([
        ...selectedServices.filter((x) => x._id !== item._id),
      ]);
    } else {
      setSelectedServices([...selectedServices, item]);
    }
  };
  useFocusEffect(
    React.useCallback(() => {
      fetchIndividualService();
      return () => {
        discard();
      };
    }, [])
  );

  const updateProjectAndNavigate = () => {
    createProject({
      _id: selectedProject._id,
      services: selectedServices.map((x) => x._id),
    });
    navigation.navigate("partner-selection");
  };

  const discard = () => {};
  return (
    <View style={{ flex: 1, backgroundColor: "#FAFAFA" }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new projects"
          subtitle="New project details"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <Text style={styles.headerStyle}>
        select the services you think you need
      </Text>
      <FlatList
        data={individualServices}
        keyExtractor={(item) => item._id}
        nestedScrollEnabled
        scrollEnabled
        renderItem={({ item }) => (
          <SelectItemWithCheckbox
            borderColor={
              selectedServices.find((x) => x._id === item._id)
                ? primaryColor(user.userType)
                : "#E2E2E2"
            }
            margin={5}
            borderWidth={2}
            data={item}
            userType={user.userType}
            checked={selectedServices.find((x) => x._id === item._id)}
            onPress={() => addToSelectedServices(item)}
            backgroundColor={"#fff"}
          ></SelectItemWithCheckbox>
        )}
      />
      <View
        style={{
          flexDirection: "row",
          margin: 15,
          marginVertical: 25,
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <Pressable
          style={[styles.pressableStyle]}
          onPress={() => updateProjectAndNavigate()}
        >
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 15,
                fontWeight: "bold",
                alignSelf: "center",
                justifyContent: "center",
              },
            ]}
          >
            {"Next"}
          </Text>
        </Pressable>

        <Pressable
          style={[styles.pressableStyle, { backgroundColor: "#EB4141" }]}
          onPress={() => {}}
        >
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 15,
                fontWeight: "bold",
                alignSelf: "center",
                justifyContent: "center",
              },
            ]}
          >
            {"Discard"}
          </Text>
        </Pressable>
      </View>

      {/* <View style={{ flexDirection: "row", margin: 25 }}>
        <RoundButton
          title="Next"
          style={{
            alignSelf: "center",
            backgroundColor: "#000000",
            marginBottom: 10,
          }}
          pressFunc={() => updateProjectAndNavigate()}
        ></RoundButton>
        <RoundButton
          title="Discard"
          style={{
            alignSelf: "center",
            backgroundColor: "#EB4141",
            marginBottom: 10,
            marginLeft: 15,
          }}
        ></RoundButton>
      </View> */}
    </View>
  );
};

const mapStateToProps = ({ user, individualServices, selectedProject }) => ({
  user,
  individualServices,
  selectedProject,
});
export default connect(mapStateToProps, {
  fetchIndividualService,
  createProject,
})(SalesServiceSelection);

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    margin: 28,
    color: "#000",
  },
  wrapperStyle: {
    marginTop: 70,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginHorizontal: 10,
    marginVertical: 10,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
