import { PixelRatio, Dimensions } from "react-native";

const pixelRatio = PixelRatio.get();
const deviceHeight = Dimensions.get("window").height;
const deviceWidth = Dimensions.get("window").width;

const adjust = (size) => {
  // if (pixelRatio >= 2 && pixelRatio < 3) {
  //   // iphone 5s and older Androids
  //   if (deviceWidth < 360) {
  //     return size * 0.95;
  //   }
  //   // iphone 5
  //   if (deviceHeight < 667) {
  //     return size;
  //     // iphone 6-6s
  //   }
  //   if (deviceHeight >= 667 && deviceHeight <= 735) {
  //     return size * 1.15;
  //   }
  //   // older phablets
  //   return size * 1.25;
  // }
  // if (pixelRatio >= 3 && pixelRatio < 3.5) {
  //   // catch Android font scaling on small machines
  //   // where pixel ratio / font scale ratio => 3:3
  //   if (deviceWidth <= 360) {
  //     return size;
  //   }
  //   // Catch other weird android width sizings
  //   if (deviceHeight < 667) {
  //     return size * 1.15;
  //     // catch in-between size Androids and scale font up
  //     // a tad but not too much
  //   }
  //   if (deviceHeight >= 667 && deviceHeight <= 735) {
  //     return size * 1.2;
  //   }
  //   // catch larger devices
  //   // ie iphone 6s plus / 7 plus / mi note 等等
  //   return size * 1.27;
  // }
  // if (pixelRatio >= 3.5) {
  //   // catch Android font scaling on small machines
  //   // where pixel ratio / font scale ratio => 3:3
  //   if (deviceWidth <= 360) {
  //     return size;
  //     // Catch other smaller android height sizings
  //   }
  //   if (deviceHeight < 667) {
  //     return size * 1.2;
  //     // catch in-between size Androids and scale font up
  //     // a tad but not too much
  //   }
  //   if (deviceHeight >= 667 && deviceHeight <= 735) {
  //     return size * 1.25;
  //   }
  //   // catch larger phablet devices
  //   return size * 1.4;
  // }
  return size;
};

export const thisOrWidth = (size) => {
  return size > deviceWidth ? deviceWidth : size;
};

export const thisPercentOrWidth = (p, min = 0, width) => {
  return (p * width) / 100 > min ? (p * width) / 100 : width;
};

export const thisOrHeight = (size) => {
  return Math.max(size, deviceHeight);
};

export default adjust;
