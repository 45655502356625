import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/partner/partnerDashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Discussions from "../../screens/common/inbox_screens/discussions";
import discussionGroups from "../../screens/common/inbox_screens/discussionGroups";
import chat from "../../screens/chat";
import login from "../../screens/login";
import ChatWithUs from "../../screens/common/chat-with-us";

const window = Dimensions.get("window");

const ClientStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

export const InboxStack = () => {
  return (
    <ClientStack.Navigator name="clientInox" initialRouteName={"discussions"}>
      <ClientStack.Screen
        name="discussions"
        component={Discussions}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="discussionGroups"
        component={discussionGroups}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="chat"
        component={chat}
        options={{ headerShown: false }}
        initialParams={{
          disableTab: true,
        }}
      />
      <ClientStack.Screen
        name="login"
        component={login}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
    </ClientStack.Navigator>
  );
};
