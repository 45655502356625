import { addError, addInfo, addWarning } from "../actions/toast.action";
const initialState = [];

const projects = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ACTIVE_PROJECTS_SUCCESS": {
      return action.payload.data;
    }

    case "CREATE_PROJECT_PROJECT_SUCCESS":
    case "ADD_DELIVERABLES_SUCCESS":
    case "CREATE_PROJECT_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) => (p._id == project._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "CREATE_PROJECT_FAIL": {
      return state;
    }

    case "ADD_DELIVERABLES_FAIL":
    case "ACCEPT_PROJECT_FAIL": {
      action.asyncDispatch(addError(action.error.response?.data.message, 3000));
      return state;
    }

    case "ACCEPT_PROJECT_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) => (p._id == project._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "GIVEUP_PROJECT_SUCCESS": {
      return state.filter((p) => p._id != action.payload.data._id);
    }

    case "TRANSFER_PROJECT_SUCCESS": {
      return state.filter((p) => p._id != action.payload.data._id);
    }

    case "UPDATE_PAYMENT_STATUS_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) =>
            p._id == project._id
              ? {
                  ...p,
                  paymentPhases: action.payload.data.paymentPhases,
                  team: action.payload.data.team,
                  paidAmount: action.payload.data.paidAmount,
                }
              : p
          )
        : state;
    }

    case "REQUEST_CUSTOM_PAYMENT_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) =>
            p._id == project._id
              ? { ...p, paymentPhases: action.payload.data.paymentPhases }
              : p
          )
        : state;
    }

    case "REQUEST_CREATOR_CUSTOM_PAYMENT_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) =>
            p._id == project._id ? { ...p, team: action.payload.data.team } : p
          )
        : state;
    }

    case "REQUEST_CUSTOM_PAYMENT_FAIL": {
      return state;
    }

    case "UPDATE_PAYMENT_STATUS_FAIL": {
      return state;
    }

    case "GIVEUP_PROJECT_FAIL": {
      return state;
    }

    case "TRANSFER_PROJECT_FAIL": {
      return state;
    }

    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default projects;
