export const fetchEngagementType = () => (dispatch) => {
  return dispatch({
    type: "FETCH_ENGAGEMENT_TYPE",
    payload: {
      request: {
        url: `/api/engagement-type`,
        method: "GET",
      },
    },
  });
};

export const createEngagementType = (engagementTypeObj) => (dispatch) => {
  return dispatch({
    type: "CREATE_ENGAGEMENT_TYPE",
    payload: {
      request: {
        url: `/api/engagement-type`,
        method: "POST",
        data: engagementTypeObj,
      },
    },
  });
};

export const removeEngagementType = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_ENGAGEMENT_TYPE",
    payload: {
      request: {
        url: `/api/engagement-type/${id}`,
        method: "DELETE",
      },
    },
  });
};
