import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Dimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import ChatWithUs from "../../screens/common/chat-with-us";
import UserProfile from "../../screens/common/userProfile";
import changePassword from "../../screens/changePassword";
const window = Dimensions.get("window");
const CreatorNewStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({ user }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      // drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="creator"
        component={ProfileStack}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

const ProfileStack = ({}) => {

  return (
    <CreatorNewStack.Navigator name="Profile" initialRouteName={"profile"}>
      <CreatorNewStack.Screen
        name="profile"
        component={UserProfile}
        options={{ headerShown: false }}
      />
      <CreatorNewStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
      <CreatorNewStack.Screen
        name="changePassword"
        component={changePassword}
        options={{ headerShown: false }}
      />
    </CreatorNewStack.Navigator>
  );
};
