import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import BusinessDetails from "../../screens/signUp/businessDetails";
import ProjectDetails from "../../screens/signUp/projectDetails";
import Dashboard from "../../screens/Client/dashboard";
import EditYourProfile from "../../screens/editYourProfile";
import MyProjects from "../../screens/common/myProjects";
import ProjectBrief from "../../screens/signUp/projectBrief";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import ActiveProjectSummary from "../../screens/creator/activeProjectSummary/activeProjectSummary";
import Progress from "../../screens/creator/activeProjectDetails/progress";
import ProjectPayment from "../../screens/creator/activeProjectDetails/projectPayment";
import Chat from "../../screens/chat";
import deliverables from "../../screens/deliverables";
import ReviewFeedback from "../../components/reviewFeedback";
import MyPayments from "../../screens/myPayments";
import ClientPayments from "../../screens/clientPayments";
import ChangePassword from "../../screens/changePassword";
import Discussion from "../../screens/discussion";
import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import RequestProject from "../../screens/Client/requestProject";
import RequestProjectBrief from "../../screens/Client/requestProjectBrief";
import ProjectInitiation from "../../screens/newProjectCreate/projectInitiation";
import NewProjectDetails from "../../screens/common/newProjectDetails";
import ProjectStatus from "../../screens/Client/projectStatus";
import login from "../../screens/login";
import Meetings from "../../screens/common/meeting/meetings";
import ScheduleMeeting from "../../screens/common/meeting/scheduleMeeting";
import ScheduleDetails from "../../screens/common/meeting/scheduleDetails";
import discussionGroups from "../../screens/common/inbox_screens/discussionGroups";
import ChatWithUs from "../../screens/common/chat-with-us";
import CloseProject from "../../screens/Client/closeProject";
const window = Dimensions.get("window");

const ClientStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({ user }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="accountant"
        // component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

export const ClientProjectStack = () => {
  return (
    <ClientStack.Navigator name="clientNew" initialRouteName={"myProjects"}>
      {/* <ClientStack.Screen
        name="dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      /> */}
      <ClientStack.Screen
        name="editYourProfile"
        component={EditYourProfile}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="myProjects"
        component={MyProjects}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="projectBrief"
        component={ProjectBrief}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="discussionGroups"
        component={discussionGroups}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="businessDetails"
        component={BusinessDetails}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="projectDetails"
        component={ProjectDetails}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="activeProjectDetails"
        component={ActiveProjectDetails}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="activeProjectSummary"
        component={ActiveProjectSummary}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="progress"
        component={Progress}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="projectPayment"
        component={ProjectPayment}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="chat"
        component={Chat}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="reviewFeedback"
        component={ReviewFeedback}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="myPayments"
        component={MyPayments}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="clientPayments"
        component={ClientPayments}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="changePassword"
        component={ChangePassword}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="discussion"
        component={Discussion}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="deliverables"
        component={deliverables}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="requestProject"
        component={RequestProject}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="requestProjectBrief"
        component={RequestProjectBrief}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="projectInitiation"
        component={ProjectInitiation}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="newProjectDetails"
        component={NewProjectDetails}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="projectStatus"
        component={ProjectStatus}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="myMeetings"
        component={Meetings}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="scheduleMeeting"
        component={ScheduleMeeting}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="scheduleDetails"
        component={ScheduleDetails}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="login"
        component={login}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="close-project"
        component={CloseProject}
        options={{ headerShown: false }}
      />
    </ClientStack.Navigator>
  );
};
