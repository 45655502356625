import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/partner/partnerDashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import UserProfile from "../../screens/common/userProfile";
import ChatWithUs from "../../screens/common/chat-with-us";
import changePassword from "../../screens/changePassword";

const window = Dimensions.get("window");

const ClientProfileStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={ProfileStack}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

export const ProfileStack = () => {
  return (
    <ClientProfileStack.Navigator
      name="clientInox"
      initialRouteName={"user-profile"}
    >
      <ClientProfileStack.Screen
        name="user-profile"
        component={UserProfile}
        options={{ headerShown: false }}
      />
      <ClientProfileStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />

      <ClientProfileStack.Screen
        name="changePassword"
        component={changePassword}
        options={{ headerShown: false }}
      />
    </ClientProfileStack.Navigator>
  );
};
