import { addError } from "../actions/toast.action";
const initialState = [];
export const salesPersons = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SALES_PERSON_SUCCESS": {
      return action.payload.data;
    }

    case "FETCH_SALES_PERSON_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "LOGOUT": {
      return [];
    }
    default: {
      return state;
    }
  }
};

export const salesPersonEarnings = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SALESPERSON_EARINGS_SUCCESS": {
      return action.payload.data;
    }

    case "FETCH_SALESPERSON_EARINGS_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "BLANK_SALESPERSON_EARINGS":
    case "LOGOUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const salesPersonFinances = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SALESPERSON_FINANCES_SUCCESS": {
      return action.payload.data;
    }

    case "FETCH_SALESPERSON_FINANCES_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "BLANK_SALESPERSON_FINANCES":
    case "LOGOUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
