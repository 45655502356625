const initialState = [];
const partnersByAdmin = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PARTNER_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_PARTNER_FAIL": {
      return state;
    }
    case "REGISTER_PARTNER_SUCCESS": {
      let partner = state.find((a) => a._id == action.payload.data._id);
      return partner
        ? state.map((p) => (p._id == partner._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }
    case "REMOVE_PARTNER_SUCCESS": {
      let index = state.findIndex((x) => x._id === action.payload.data._id);
      state.splice(index, 1);
      return [...state];
    }
    case "LOGOUT":
      return [];

    default: {
      return state;
    }
  }
};
export default partnersByAdmin;
