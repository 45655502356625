import { FlatList, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import RoundButton from "../../../components/roundButton";
import DashboardCardWithArrowAndIcon from "../../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import { fetchMeetings } from "../../../redux/actions/meeting.action";

const Meetings = ({
  user,
  navigation,
  route,
  selectedProject,
  fetchMeetings,
  meetings,
}) => {
  const [selectedTab, setSelectedTab] = useState("upcoming");
  const [meetingList, setMeetingList] = useState([]);
  useEffect(() => {
    if (!route.params && selectedProject && selectedTab) {
      setMeetingList(
        selectedProject.meetingList.filter((x) =>
          selectedTab === "upcoming" ? !x.completed : x.completed
        )
      );
    }
  }, [selectedProject, selectedTab, route.params]);

  useEffect(() => {
    if (route.params && route.params.type === "tab") {
      fetchMeetings();
    }
  }, [route.params]);

  useEffect(() => {
    setMeetingList(meetings);
  }, [meetings]);

  useEffect(() => {}, [meetingList]);
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          disableBackButton={false}
          title="meetings"
          subtitle="all your projects"
          enableAvatar={user.userType === "ONEDESIGN" ? false : true}
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginHorizontal: 15,
          }}
        >
          <RoundButton
            pressFunc={() => {
              setSelectedTab("upcoming");
            }}
            title={"upcoming"}
            style={{
              backgroundColor: selectedTab === "upcoming" ? "#3B8CD1" : "#FFF",
              borderWidth: selectedTab === "completed" ? 1 : 0,

              width: "45%",
            }}
            textStyle={{
              color: selectedTab === "upcoming" ? "#FFF" : "#000",
            }}
          ></RoundButton>
          <RoundButton
            pressFunc={() => {
              setSelectedTab("completed");
            }}
            title={"completed"}
            style={{
              backgroundColor: selectedTab === "completed" ? "#3B8CD1" : "#FFF",
              borderWidth: selectedTab === "upcoming" ? 1 : 0,

              width: "45%",
            }}
            textStyle={{
              color: selectedTab === "completed" ? "#FFF" : "#000",
            }}
          ></RoundButton>
        </View>
        <View style={{ marginTop: 10, marginHorizontal: 15 }}>
          <DashboardCardWithArrowAndIcon
            value=""
            backgroundColor="#073257"
            description="Tap here to schedule a meeting"
            icon={false}
            title="Schedule a Meeting"
            onPressCard={() => {
              navigation.navigate("scheduleMeeting", {
                type: "meeting-screen",
              });
            }}
          ></DashboardCardWithArrowAndIcon>
        </View>
        <View
          style={{
            borderBottomColor: "#707070",
            borderBottomWidth: 1,
            marginHorizontal: 15,
            marginTop: 10,
          }}
        ></View>
        {!route.params && selectedProject.initialCall ? (
          <View style={{ marginHorizontal: 10 }}>
            <DashboardCardWithArrowAndIcon
              valueStyle={{ fontSize: 30, color: "#000" }}
              onPressCard={() =>
                navigation.navigate("scheduleDetails", { type: "initial" })
              }
              value={selectedProject.initialCall.name}
              titleColor="#000"
              descriptionTextColor="#000"
              backgroundColor="#FFF"
              description={`Partner: ${selectedProject.partner?.fName} ${selectedProject.partner?.lName}`}
              icon={false}
              title={selectedProject.title}
            ></DashboardCardWithArrowAndIcon>
          </View>
        ) : (
          <></>
        )}
        <FlatList
          contentContainerStyle={{ paddingBottom: 30 }}
          scrollEnabled
          nestedScrollEnabled
          data={meetingList.filter((x) =>
            selectedTab === "upcoming" ? !x.completed : x.completed
          )}
          keyExtractor={(item) => item._id}
          renderItem={({ item }) => (
            <View style={{ marginHorizontal: 10 }}>
              <DashboardCardWithArrowAndIcon
                onPressCard={() =>
                  navigation.navigate("scheduleDetails", { meeting: item._id })
                }
                // value={item.name}
                titleColor="#000"
                descriptionTextColor="#000"
                backgroundColor="#FFF"
                description={`Partner: ${
                  (item.users?.find((e) => e.userType === "PARTNER")).fName
                } ${(item.users?.find((e) => e.userType === "PARTNER")).lName}`}
                icon={false}
                title={item.name}
              ></DashboardCardWithArrowAndIcon>
            </View>
          )}
        />
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject, meetings }) => ({
  user,
  selectedProject,
  meetings,
});
export default connect(mapStateToProps, { fetchMeetings })(Meetings);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
});
