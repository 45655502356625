import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  Text,
  View,
  ScrollView,
  StyleSheet,
} from "react-native";
import { connect } from "react-redux";
import adjust from "../../../../components/adjust";
import Checkbox from "../../../../components/checkbox";
import Icon from "../../../../components/icon";
import SelectProfile from "./selectProfileComponent";
function SelectTeam({
  onTeamDataChange,
  selectedProject,
  projectRole,
  validate,
  employees,
}) {
  useEffect(() => {
    onTeamDataChange(
      selectedProject.requiredTeam
        ? selectedProject.requiredTeam
        : {
            myself: "",
            members: [
              {
                type: "",
                nos: 0,
                budget: "",
                sourcing: "",
                user: "",
              },
            ],
          }
    );
  }, [selectedProject]);

  const [teams, setTeams] = useState(
    selectedProject.requiredTeam
      ? selectedProject.requiredTeam
      : {
          myself: "",
          members: [
            {
              type: "",
              nos: 0,
              budget: "",
              sourcing: "",
              user: "",
            },
          ],
        }
  );

  return (
    <ScrollView
      nestedScrollEnabled={true}
      style={{
        flex: 1,
        padding: 20,
        height: "100%",
      }}
    >
      <View style={styles.container}>
        <Text style={styles.header}>Build a Team or Perform Yourself</Text>
        <Text style={styles.subHeader}>
          At this stage, choose your team very wisely. The budget will be
          divided according to the job profiles.
        </Text>
      </View>

      <View style={styles.doItMyselfContainer}>
        <Checkbox
          setValue={(e) => {
            setTeams({
              ...teams,
              myself: e,
            });
            onTeamDataChange({
              ...teams,
              myself: e,
            });
            if (e) {
              validate(true);
            } else {
              validate(false);
            }
          }}
          value={teams.myself}
        />
        <Text style={styles.doItMyselfText}>I can do this job myself</Text>
      </View>
      <View style={{ borderBottomWidth: 1, marginTop: 15 }}></View>
      <Text style={styles.buildYourTeamText}>Start building your team</Text>
      <View style={{ flex: 1, marginTop: 15, flexDirection: "row" }}>
        <ScrollView>
          {teams.members?.map((team, index) => (
            <SelectProfile
              key={`id-${team._id ? team._id : team.index}`}
              type="member"
              data={team}
              disable={teams.myself}
              renderData={projectRole}
              validated={(e) => {
                if (!teams.myself) {
                  validate(e);
                }
              }}
              validateNow={true}
              employees={employees}
              selectionValue={
                team.type
                  ? projectRole?.find((e) => e._id == team.type._id)
                  : ""
              }
              quanity={team.nos}
              empSelectionValue={employees?.find((e) => e._id == team.user)}
              onChangeData={(profile) => {
                let newObj = {
                  ...teams,
                  members: [
                    ...teams.members.map((team, i) =>
                      i == index ? profile : team
                    ),
                  ],
                };
                setTeams(newObj);
                onTeamDataChange(newObj);
              }}
              remove={(id) => {
                const v = {
                  ...teams,
                  members: teams.members.filter((t, i) => i != index),
                };
                setTeams(v);
                onTeamDataChange(v);
              }}
              length={teams.members.length}
            ></SelectProfile>
          ))}

          <TouchableOpacity
            style={{
              flex: 1,
              alignSelf: "center",
              marginRight: 10,
            }}
            onPress={
              teams.myself
                ? null
                : () => {
                    let newObj = {
                      ...teams,
                      members: [
                        ...teams.members,
                        {
                          type: "",
                          nos: 0,
                          budget: "",
                          sourcing: "",
                          user: "",
                          index: new Date().getTime(),
                        },
                      ],
                    };
                    setTeams(newObj);
                    onTeamDataChange(newObj);
                  }
            }
          >
            <View
              style={[
                styles.plusContainer,
                { backgroundColor: teams.myself ? "#E9E9E9" : "#FFD500" },
              ]}
            >
              <Icon height={20} width={20} name="plus" fill="#FFF" />
            </View>
          </TouchableOpacity>
        </ScrollView>
      </View>
    </ScrollView>
  );
}

const mapStateToProps = ({ projectRole, selectedProject, employees }) => ({
  projectRole,
  selectedProject,
  employees,
});
export default connect(mapStateToProps, {})(SelectTeam);
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    minHeight: 90,
    padding: 20,
    borderRadius: 10,
  },
  doItMyselfContainer: {
    backgroundColor: "#000000",
    maxHeight: 60,
    minHeight: 60,
    borderRadius: 8,
    padding: 15,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  doItMyselfText: {
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: adjust(16),
    width: "100%",
    marginLeft: 10,
  },
  header: {
    flex: 1,
    fontWeight: "bold",
    fontSize: adjust(18),
    color: "#828282",
    marginBottom: 10,
  },
  subHeader: {
    flex: 1,
    fontSize: adjust(15),
    color: "#606060",
    paddingRight: 5,
  },
  buildYourTeamText: {
    color: "#000",
    fontSize: adjust(15),
    marginTop: 10,
    marginLeft: 5,
    fontWeight: "bold",
  },
  plusContainer: {
    flex: 1,
    backgroundColor: "#FFD500",
    borderRadius: 50,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 45,
    maxWidth: 45,
    minHeight: 45,
    minWidth: 45,
  },
});
