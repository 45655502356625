import * as React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Pressable,
  Dimensions,
} from "react-native";
import { BlurView } from "expo-blur";
import FontOutlineWithShadow from "../../../components/fontOutlineWithShadow/fontOutlineWithShadow";
import adjust from "../../../components/adjust";
import { logout } from "../../../redux/actions/user.action";
import { connect } from "react-redux";
const OnboardingMessage = ({ route, navigation, logout }) => {
  const screen = Dimensions.get("screen");
  const [user, setUser] = React.useState("");
  React.useEffect(() => {
    setUser(route.params.userType);
    logout();
  }, [route.params]);
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.centeredView}>
        <BlurView
          style={styles.modalView}
          intensity={80}
          tint="dark"
          blurType="dark"
          blurAmount={32}
          blurRadius={100}
        >
          <View
            style={{
              backgroundColor:
                user === "client"
                  ? "#3A8CD1"
                  : user === "creator"
                  ? "#FF973E"
                  : "#F27253",
              width: "100%",
              minHeight: screen.height,
              maxHeight: screen.height,
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: "row-reverse",
                minHeight: 17,
                top: 5,
                right: 5,
              }}
              onPress={() => closeModal()}
            ></TouchableOpacity>
            <ScrollView contentContainerStyle={{ paddingBottom: 40 }}>
              <FontOutlineWithShadow stroke={2} color={"#FFF2AF"}>
                <Text
                  style={{
                    fontSize: 100,
                    color:
                      user === "client"
                        ? "#3A8CD1"
                        : user === "creator"
                        ? "#FF973E"
                        : "#F27253",
                    marginLeft: 30,
                    marginTop: 70,
                    lineHeight: 100,
                  }}
                >
                  hello there!
                </Text>
              </FontOutlineWithShadow>
              <Text
                style={{
                  marginLeft: 5,
                  paddingHorizontal: 20,
                  fontSize: 25,
                  fontWeight: "bold",
                  color: "#FFF",
                }}
              >
                {`This is where Freelance Culture meets Agency Trust to become the Agency of the Future! World’s first agency-led remote working platform for independent creators and global clients. This is where Freelance Culture meets Agency Trust to become the Agency of the Future! World’s first agency-led remote working platform for independent creators and global clients.\n\nThis is where Freelance Culture meets Agency Trust to become the Agency of the Future! World’s first agency-led\n`}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  paddingHorizontal: 20,
                  fontSize: 40,
                  color: "#FFF",
                }}
              >
                satya subham rout
              </Text>

              <Pressable
                style={[styles.pressableStyle]}
                onPress={() => {
                  navigation.navigate(route?.params?.navigateTo);
                }}
              >
                <Text
                  style={[
                    {
                      color: "#FFFFFF",
                      fontSize: adjust(15),
                      fontWeight: "bold",
                      alignSelf: "center",
                      justifyContent: "center",
                    },
                  ]}
                >
                  {"Next"}
                </Text>
              </Pressable>
            </ScrollView>
          </View>
        </BlurView>
      </View>
    </View>
  );
};

export default connect(null, { logout })(OnboardingMessage);

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  textStyle: {
    // marginLeft: 25,
    marginHorizontal: 20,
    fontSize: 15,
    fontWeight: "bold",
    color: "#828282",
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginLeft: 25,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
    marginTop: 40,
  },
});
