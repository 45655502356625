import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import adjust from "../../../../components/adjust";
import Icon from "../../../../components/icon";
import RoundButton from "../../../../components/roundButton";

export function ProfileComponent({
  navigation,
  bid,
  onPress,
  type,
  nos,
  budget,
  onPressToggleInvitation,
  quotationsCount,
  acceptedQuotationsCount,
  open,
  validate,
  selectedQuotation,
}) {
  return (
    <View style={styles.profileContainer}>
      <View
        style={{
          borderRadius: 10,
          width: "100%",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={styles.quotationContainer}></View>
          <View style={{ marginLeft: 15 }}>
            <Text style={styles.data}>{`${nos} ${type}`}</Text>
            <Text style={{ fontSize: 12 }}>
              {`${
                acceptedQuotationsCount ? `${acceptedQuotationsCount}` : "0"
              } of ${nos} people selected`}
            </Text>
          </View>
        </View>
        <View style={styles.minusContainer}>
          <Text
            style={{
              flex: 1,
              color: "#989898",
              fontSize: adjust(14),
              fontWeight: "bold",
            }}
          >
            Quotations
          </Text>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(40),
                color: "#000",
              }}
            >
              {quotationsCount}
            </Text>
            <TouchableOpacity
              style={{
                position: "absolute",
                right: 10,
                bottom: -5,
              }}
              onPress={quotationsCount > 0 ? onPress : null}
            >
              <Icon
                height={40}
                width={40}
                name="rightChevronArrowWithCircle"
                fill={quotationsCount > 0 ? "#000000" : "#E9E9E9"}
              ></Icon>
            </TouchableOpacity>
          </View>
          <Text style={{ fontSize: 12 }}>quotes received</Text>
        </View>
        <RoundButton
          title={open ? "Close Invitations" : "Invite Quotations"}
          pressFunc={onPressToggleInvitation}
          style={[
            styles.buttonStyle,
            { backgroundColor: open ? "#000" : "#FED400" },
          ]}
          textStyle={{ fontWeight: "bold" }}
        ></RoundButton>
      </View>

      {/* <View style={styles.profileContainer}>
        <View style={{ flex: 2.5, marginTop: 10 }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Profile</Text>
            <Text style={styles.data}>{type}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Number of People</Text>
            <Text style={styles.data}>{nos}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Individual Budget</Text>
            <Text style={styles.data}>₹ {budget}</Text>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <RoundButton
            title={open ? "Close Invitations" : "Invite Quotations"}
            pressFunc={onPressToggleInvitation}
            style={{
              flex: 1,
              backgroundColor: open ? "#000" : "#FED400",
              minHeight: 50,
              borderRadius: 28,
            }}
            textStyle={{ fontWeight: "bold" }}
          ></RoundButton>
        </View>
      </View>
      <View style={styles.quotationContainer}>
        <Text
          style={{
            flex: 1,
            color: "#575757",
            fontSize: adjust(14),
            fontWeight: "bold",
            minHeight: 23,
          }}
        >
          Quotations Received
        </Text>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: adjust(40),
            minHeight: 45,
          }}
        >
          {quotationsCount}
        </Text>
        <Text style={{ flex: 1, color: "#707070", minHeight: 23 }}>
          Quotations Received
        </Text>
        <Text
          style={{
            flex: 1,
            color: "#707070",
            fontSize: adjust(15),
            minHeight: 25,
            bottom: 3,
          }}
        >
          Accepted:
          {acceptedQuotationsCount ? `${acceptedQuotationsCount}` : "0"}
        </Text>
        <TouchableOpacity
          style={{ position: "absolute", right: 10, bottom: 10 }}
          onPress={quotationsCount > 0 ? onPress : null}
        >
          <Icon
            name="rightChevronArrowWithCircle"
            fill={quotationsCount > 0 ? "#000000" : "#E9E9E9"}
          ></Icon>
        </TouchableOpacity>
      </View> */}
    </View>
  );
}

const mapStateToProps = ({ selectedQuotation }) => ({ selectedQuotation });
export default connect(mapStateToProps, {})(ProfileComponent);

const styles = StyleSheet.create({
  profileContainer: {
    flex: 1,
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 20,
    borderWidth: 1,
    borderColor: "#FFD500",
  },
  quotationContainer: {
    height: 40,
    width: 40,
    borderRadius: 50,
    backgroundColor: "#FFD500",
  },
  minusContainer: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#707070",
    marginTop: 5,
    padding: 7,
  },
  title: { fontSize: adjust(18), fontWeight: "bold", marginBottom: 5 },
  data: { fontSize: adjust(16), color: "#000", fontWeight: "bold" },
  buttonStyle: {
    flex: 1,

    minHeight: 50,
    borderRadius: 28,
  },
});
