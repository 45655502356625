import React, { useState } from "react";
import {
  TouchableOpacity,
  Text,
  View,
  StyleSheet,
  FlatList,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import adjust from "./adjust";
import Modal from "./modal/modal";

export default function settingsPopUp({
  style,
  renderData,
  onSelection,
  showModal,
  navigation,
}) {
  const [modalVisible, setModalVisible] = useState({ showModal });
  const selectItem = (route) => {
    route();
    closeModal();
  };
  const closeModal = () => {
    onSelection(false);
    setModalVisible(false);
  };
  return (
    <View style={[{ height: 0 }, style]}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showModal}
        onRequestClose={() => {}}
        ariaHideApp={false}
      >
        <TouchableWithoutFeedback onPress={() => closeModal()}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <FlatList
                data={renderData}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      borderBottomColor: "#828282",
                      borderBottomWidth:
                        renderData.length - 1 === index ? 0 : 1,
                      paddingVertical: 10,
                    }}
                    onPress={() => selectItem(item.route)}
                  >
                    <Text style={styles.item}>{item.key}</Text>
                  </TouchableOpacity>
                )}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  placeholder: {
    fontSize: adjust(11),
    color: "#B1B1B1",
    marginBottom: 10,
    flex: 1,
  },
  selected: {
    fontSize: adjust(11),
    color: "#000000",
    marginBottom: 10,
    flex: 1,
  },
  item: {
    padding: 10,
    fontSize: adjust(16),
    height: 44,
    borderColor: "#D3D3D3",
    minWidth: "95%",
    textAlign: "center",
  },
  label: {
    fontSize: adjust(13),
    fontWeight: "bold",
    marginBottom: 10,
  },
  centeredView: {
    flex: 1,
    height: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  modalView: {
    width: "60%",
    maxWidth: 400,
    maxHeight: 400,
    backgroundColor: "white",
    // borderTopRightRadius: 20,
    // borderTopLeftRadius: 20,
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    paddingVertical: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    // position: "absolute",
    // bottom: 0,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});
