import _ from "lodash";
const initialState = { fetch: true, chats: [] };
const chats = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CHAT_MSGS_SUCCESS": {
      if (
        action.meta.previousAction.payload.request.url.includes("lastMessageID")
      ) {
        return {
          fetch: true,
          chats: _.unionBy(action.payload.data.chats, state.chats || [], "_id"),
        };
      } else {
        return action.payload.data;
      }
    }

    case "SEND_CHAT_MSG_SUCCESS": {
      return {
        fetch: true,
        chats: _.unionBy(action.payload.data, state.chats || [], "_id"),
      };
    }

    case "FETCH_PREVIOUS_CHAT_MSGS_SUCCESS": {
      if (
        action.meta.previousAction.payload.request.url.includes("lastMessageID")
      ) {
        return {
          fetch: action.payload.data.fetch,
          chats: _.unionBy(state.chats || [], action.payload.data.chats, "_id"),
        };
      } else {
        return action.payload.data;
      }
    }

    case "CLEAR_CHAT": {
      return { fetch: true, chats: [] };
    }

    case "LOGOUT": {
      return { fetch: true, chats: [] };
    }
    default: {
      return state;
    }
  }
};
export default chats;
