import {
  StyleSheet,
  Text,
  View,
  FlatList,
  useWindowDimensions,
  TouchableOpacity,
  Pressable,
} from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import LargeTextInput from "../../../components/largeTextInput";
import RoundButton from "../../../components/roundButton";
import {
  createCaseStudy,
  fetchCaseStudy,
  removeCaseStudy,
} from "../../../redux/actions/case-study.action";
import { connect } from "react-redux";
import CreativeCardComponent from "./creativeCardComponent";
import Icon from "../../../components/icon";

const AddEditCaseStudies = ({
  navigation,
  caseStudy,
  fetchCaseStudy,
  createCaseStudy,
  removeCaseStudy,
}) => {
  useEffect(() => {
    fetchCaseStudy();
  }, []);
  const dimension = useWindowDimensions();
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [selectedStudy, setSelectedStudy] = useState();
  const discard = () => {
    setLink("");
    setDescription("");
    setSelectedStudy();
  };
  const add = () => {
    createCaseStudy({
      _id: selectedStudy,
      url: link,
      description: description,
    });
    discard();
  };
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          enableAvatar={false}
          title="add case studies"
          subtitle="Enter case studies"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <FlatList
        nestedScrollEnabled
        scrollEnabled
        style={{ marginHorizontal: 15 }}
        keyExtractor={(item) => item.header}
        data={caseStudy || []}
        renderItem={({ item, index }) => (
          <Pressable
            onPress={() => {
              setLink(item.url);
              setDescription(item.description);
              setSelectedStudy(item._id);
            }}
            key={index + item.description}
            style={{
              padding: 15,
              backgroundColor: "#FFF",
              borderRadius: 7,
              marginVertical: 5,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{ color: "#000", fontWeight: "bold", alignSelf: "center" }}
            >
              {item.description}
            </Text>
            <TouchableOpacity onPress={() => removeCaseStudy(item._id)}>
              <Icon
                style={{
                  marginLeft: 5,
                }}
                name="delete"
              ></Icon>
            </TouchableOpacity>
          </Pressable>
        )}
      />
      <View style={{ marginHorizontal: 25, height: dimension.height / 7 }}>
        <LargeTextInput
          value={link}
          width={"100%"}
          placeholder={"Add Link"}
          style={{
            borderBottomColor: "#707070",
            fontSize: 20,
            marginHorizontal: 0,
          }}
          onChangeText={(e) => setLink(e)}
        ></LargeTextInput>
        <LargeTextInput
          value={description}
          marginLeft={1}
          width={"100%"}
          placeholder={"Add Description"}
          style={{
            borderBottomColor: "#707070",
            fontSize: 20,
            marginHorizontal: 0,
          }}
          onChangeText={(e) => setDescription(e)}
        ></LargeTextInput>
      </View>
      <View
        style={{
          flexDirection: "row",
          margin: 10,
          justifyContent: "space-around",
        }}
      >
        <RoundButton
          style={{ backgroundColor: "#000000", width: 130 }}
          title={selectedStudy ? "Update" : "Add+"}
          pressFunc={() => add()}
        ></RoundButton>
        <RoundButton
          style={{ backgroundColor: "#EB4141", width: 130 }}
          title={"Discard"}
          pressFunc={() => discard()}
        ></RoundButton>
      </View>
    </View>
  );
};
const mapStateToProps = ({ caseStudy }) => ({ caseStudy });
export default connect(mapStateToProps, {
  fetchCaseStudy,
  createCaseStudy,
  removeCaseStudy,
})(AddEditCaseStudies);

const styles = StyleSheet.create({
  container: { flex: 1 },
});
