import { fetchDashboardStats } from "../actions/dashboard.action";

const initialState = [];

const newProjects = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_PROJECT_PROJECT_SUCCESS":
    case "CREATE_PROJECT_SUCCESS": {
      action.asyncDispatch(fetchDashboardStats());
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) => (p._id == project._id ? project : p))
        : state;
    }
    case "FETCH_ALL_PROJECTS_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_ALL_PROJECTS_FAIL": {
      return state;
    }
    case "PLACE_QUOTATION_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) => {
            p._id == project._id ? action.payload.data : p;
          })
        : state;
    }

    case "GIVEUP_PROJECT_SUCCESS": {
      return [...state, action.payload.data];
    }

    case "START_PROJECT_SUCCESS": {
      return state.filter((p) => p._id != action.payload.data._id);
    }

    case "PLACE_QUOTATION_FAIL": {
      return state;
    }
    case "CLOSE_QUOTATION_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) =>
            p._id == project._id
              ? {
                  ...p,
                  requiredTeam: {
                    ...p.requiredTeam,
                    members: action.payload.data.requiredTeam.members,
                  },
                }
              : p
          )
        : state;
    }

    case "SELECT_QUOTATION_SUCCESS": {
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) =>
            p._id == project._id
              ? {
                  ...p,
                  requiredTeam: {
                    ...p.requiredTeam,
                    members: action.payload.data.requiredTeam.members,
                    team: action.payload.data.requiredTeam.team,
                  },
                }
              : p
          )
        : state;
    }

    case "CLOSE_QUOTATION_FAIL": {
      return state;
    }
    case "LOGOUT": {
      return [];
    }
    default:
      return state;
  }
};
export default newProjects;
