import { StyleSheet, Text, View, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import EarningCardComponentWithTotalSales from "../../components/earningComponents/earningCardComponentWithTotalSales";

import AllTransactionComponents from "../../components/earningComponents/allTransactionComponents";
import { fetchInflowTransactions } from "../../redux/actions/payment.action";
import { connect } from "react-redux";
import { currenyFormat, fullName } from "../../components/utils";
import moment from "moment";
import { fetchAccountantStats } from "../../redux/actions/user.action";

const AccountantInflowceScreen = ({
  navigation,
  fetchInflowTransactions,
  transactions,
  userStats,
  fetchAccountantStats,
}) => {
  useEffect(() => {
    fetchInflowTransactions();
    fetchAccountantStats();
  }, []);
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          enableAvatar={true}
          title="inflow"
          subtitle="All Your active projects"
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ marginHorizontal: 5 }}>
        <EarningCardComponentWithTotalSales
          header={"total inflow for this month"}
          firstCountValue={`${userStats.revenue + userStats.inflowPending} `}
          firstText={"closed total"}
          middleCountValue={`${userStats.revenue}`}
          middleText={"Received"}
          lastCountValue={`${userStats.inflowPending}`}
          lastText={"Pending"}
          CountValue={"#7A9D54"}
          roundButton={false}
          borderColor={"#7A9D54"}
        />
      </View>
      <ScrollView style={{ marginHorizontal: 10 }}>
        {transactions.map((x) => (
          <AllTransactionComponents
            Colors={"#7A9D54"}
            amount={currenyFormat(x.amount) + " GST"}
            transactionTime={`Client: ${fullName(x.project?.user)} | ${moment(
              x.updatedDate,
              "YYYY-MM-DDThh:mm:ss"
            ).format("DD/MM/YYYY hh:mm a")}`}
            transactionDetails={"client paid money"}
            projectName={x?.project?.title}
          />
        ))}
      </ScrollView>
    </View>
  );
};

const mapStateToProps = ({ transactions, userStats }) => ({
  transactions,
  userStats,
});

export default connect(mapStateToProps, {
  fetchInflowTransactions,
  fetchAccountantStats,
})(AccountantInflowceScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
