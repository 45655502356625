export const fetchDiscussions = () => (dispatch) => {
    return dispatch({
        type: "FETCH_DISCUSSION",
        payload: {
            request: {
                url: "/api/discussion",
                method: "GET",
            },
        },
    });
};

export const fetchDiscussionsByProject = (project) => (dispatch) => {
    return dispatch({
        type: "FETCH_DISCUSSION_BY_PROJECT",
        payload: {
            request: {
                url: `/api/discussion/project?project=${project}`,
                method: "GET",
            },
        },
    });
};

export const createDiscussion = (obj) => (dispatch) => {
    return dispatch({
        type: "CREATE_DISCUSSION",
        payload: {
            request: {
                url: "/api/discussion",
                method: "POST",
                data: obj
            },
        },
    });
};
