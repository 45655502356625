import React, { useState } from "react";
import { TouchableOpacity, Text, View, StyleSheet } from "react-native";
import Icon from "./icon";
import SettingsPopUp from "./settingsPopUp";
import { logout } from "../redux/actions/user.action";
import { connect } from "react-redux";
import adjust from "./adjust";
import { fetchNotifications } from "../redux/actions/notification.action";
import { primaryColor } from "./utils";
const Header = ({
  logout,
  backFunction,
  title,
  subtitle,
  enableAvatar,
  disableBackButton,
  style,
  titleStyle,
  titleContainerStyle,
  navigation,
  disableProfilePage,
  user,
  textStyle,
  disableProfile,
  secondarySubtitle,
}) => {
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);

  const handleCallback = (childData) => {
    setSettingsModalVisible(childData);
  };

  const renderData = [
    { key: "My Profile", route: () => navigation.navigate("editYourProfile") },
    { key: "Logout", route: () => logout() },
  ];
  const logoutRenderData = [
    {
      key: "Logout",
      route: () => {
        logout();
        navigation.navigate("login", { screen: "login" });
      },
    },
  ];
  return (
    <View
      style={[
        styles.container,
        style,
        { justifyContent: "center", paddingTop: 20 },
      ]}
    >
      <View
        style={{
          // flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flex: 1, justifyContent: "center" }}>
          {disableBackButton ? (
            <></>
          ) : (
            <TouchableOpacity
              style={{
                left: 25,
                zIndex: 999,
                alignSelf: "flex-start",
                marginTop: 10,
              }}
              onPress={backFunction}
            >
              <Icon name="back" fill="#000" />
            </TouchableOpacity>
          )}
        </View>
        {/* <TouchableOpacity
          onPress={() => {
            fetchNotifications();
            navigations.dispatch(DrawerActions.openDrawer());
          }}
          style={styles.bellWrapper}
        >
          <Image
            style={styles.bellIcon}
            source={require("../assets/bell.png")}
          />
          <View
            style={{
              position: "absolute",
              top: -7,
              right: 0,
              backgroundColor: "#FF000060",
              fontWeight: "700",
              borderRadius: 20,
              height: 20,
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 15,
                paddingVertical: 1,
                paddingHorizontal: 5,
                textAlignVertical: "center",
                textAlign: "center",
                alignSelf: "center",
              }}
              adjustsFontSizeToFit={true}
            >
              {`${notificationCount > 100 ? "99+" : notificationCount} `}
            </Text>
          </View>
        </TouchableOpacity> */}
        {/* <Icon name="notification" style={{ height: 40, width: 40 }}></Icon> */}
      </View>

      <View
        style={{
          flexDirection: "row",
          // marginTop: 15,
          // backgroundColor: "red",
        }}
      >
        <View
          style={[
            {
              flex: 1,
              zIndex: 0,
              justifyContent: "flex-end",
              flexGrow: 1,
            },
            titleContainerStyle,
          ]}
        >
          <Text style={[styles.title, titleStyle]}>{title}</Text>
          {subtitle ? (
            <Text style={[styles.subtitle, textStyle]}>{subtitle}</Text>
          ) : (
            <></>
          )}
          {secondarySubtitle}
        </View>

        {enableAvatar === false ? (
          <></>
        ) : user && user.token ? (
          <TouchableOpacity
            // onPress={() => {
            //   fetchNotifications();
            //   navigations.dispatch(DrawerActions.openDrawer());
            // }}
            onPress={() => navigation.navigate("chat-with-us")}
            style={styles.bellWrapper}
          >
            <Icon name="chat" fill={primaryColor(user.userType)} />
            {/* <View
              style={[
                {
                  position: "absolute",
                  top: -10,
                  right: 5,
                  backgroundColor: "#FF000060",
                  fontWeight: "700",
                  borderRadius: 20,
                  height: 20,
                  justifyContent: "center",
                },
                countStyle,
              ]}
            >
              <Text
                style={{
                  fontSize: 15,
                  padding: 1,
                  paddingHorizontal: 5,
                  textAlignVertical: "center",
                  textAlign: "center",
                  alignSelf: "center",
                  left: 1,
                }}
                adjustsFontSizeToFit={true}
              >
                {`${notificationCount > 100 ? "99+" : notificationCount} `}
              </Text>
            </View> */}
          </TouchableOpacity>
        ) : (
          <></>
        )}

        <SettingsPopUp
          renderData={
            disableProfilePage || disableProfile ? logoutRenderData : renderData
          }
          showModal={settingsModalVisible}
          onSelection={handleCallback}
        ></SettingsPopUp>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, notificationCount }) => ({
  user,
  notificationCount,
});
export default connect(mapStateToProps, { logout, fetchNotifications })(Header);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: "#FFFFFF",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    alignContent: "flex-end",
  },
  title: {
    fontWeight: "bold",
    fontSize: adjust(25),
    marginLeft: 30,
    // marginTop: 30,
    flexWrap: "wrap",
    color: "#000",
  },
  subtitle: {
    color: "#606060",
    fontSize: adjust(13),
    marginLeft: 30,
  },

  headerAvatar: {
    borderWidth: 1.5,
    height: 35,
    width: 35,
    marginBottom: 3,
  },
  headerAvatarWrapper: {
    flex: 1,
    maxWidth: 30,
    zIndex: 2,
    justifyContent: "flex-end",
    // marginBottom: 30,
    marginRight: 30,
  },
  image: {
    width: 35,
    height: 35,
    borderRadius: 15,
    marginBottom: 4,
  },
  bellWrapper: {
    // maxWidth: 30,
    // zIndex: 2,
    justifyContent: "center",
    // alignSelf: "flex-end",
    marginRight: 20,
    marginTop: 10,
    // backgroundColor: "red",
  },
  bellIcon: {
    width: 30,
    height: 30,
    alignSelf: "flex-end",
    marginRight: 2,
    marginTop: 7,
  },
  // icon: { position: "absolute", right: 30, top: 85 },
});
