export const fetchAllCreator = () => (dispatch) => {
  return dispatch({
    type: "FETCH_ALL_CREATOR",
    payload: {
      request: {
        url: `/api/creator/list`,
        method: "GET",
      },
    },
  });
};

export const fetchPendingCreator = () => (dispatch) => {
  return dispatch({
    type: "FETCH_PENDING_CREATOR",
    payload: {
      request: {
        url: `/api/creator/list?pending=true`,
        method: "GET",
      },
    },
  });
};

export const creatorApproval = (creatorId, status, checkData) => (dispatch) => {
  return dispatch({
    type:
      checkData === "SHORTLISTED"
        ? "APPROVE_CREATOR_SHORTLISTED"
        : checkData === "ACCEPTEDACTIVEUSER"
        ? "APPROVE_CREATOR_ACTIVE"
        : "APPROVE_CREATOR_PENDING",
    payload: {
      request: {
        url: `/api/creator/approve?creatorId=${creatorId}&status=${status}`,
        method: "GET",
      },
    },
  });
};

export const fetchShortlistedCreator = () => (dispatch) => {
  return dispatch({
    type: "FETCH_SHORTLISTED_CREATOR",
    payload: {
      request: {
        url: `/api/creator/list?status=SHORTLISTED`,
        method: "GET",
      },
    },
  });
};

export const shortlistCreator = (creatorId) => (dispatch) => {
  return dispatch({
    type: "SHORTLIST_CREATOR",
    payload: {
      request: {
        url: `/api/creator/approve?creatorId=${creatorId}&&status=SHORTLISTED`,
        method: "GET",
      },
    },
  });
};

export const setSelectedMember = (obj) => ({
  type: "SET_SELECTED_MEMBER",
  payload: { data: obj },
});

export const requestCreatorCustomPayment = (obj) => (dispatch) =>
  dispatch({
    type: "REQUEST_CREATOR_CUSTOM_PAYMENT",
    payload: {
      request: {
        url: `/api/project/payment/add/creator`,
        method: "POST",
        data: obj,
      },
    },
  });

export const downloadPortfolioProfile = (id) => (dispatch) => {
  return dispatch({
    type: "DOWNLOAD_PORTFOLIO_PROFILE",
    payload: {
      request: {
        url: `/api/creator/file?fileId=${id}`,
        method: "GET",
        responseType: "blob",
      },
    },
  });
};

export const fetchCreatorWithStats = () => (dispatch) => {
  return dispatch({
    type: "FETCH_CREATOR_WITH_STATS",
    payload: {
      request: {
        url: `/api/user/creators`,
        method: "GET",
      },
    },
  });
};

export const fetchCreatorEarnings = (user) => (dispatch) =>
  dispatch({
    type: "FETCH_CREATOR_EARINGS",
    payload: {
      request: {
        url: `/api/user/creator/earnings?user=${user}`,
        method: "GET",
      },
    },
  });

export const removeCreatorEarnings = () => (dispatch) =>
  dispatch({
    type: "BLANK_CREATOR_EARINGS",
  });

export const fetchCreatorFinances = (user) => (dispatch) =>
  dispatch({
    type: "FETCH_CREATOR_FINANCES",
    payload: {
      request: {
        url: `/api/user/sales-person/finances?user=${user}`,
        method: "GET",
      },
    },
  });

export const removeCreatorFinances = () => (dispatch) =>
  dispatch({
    type: "BLANK_CREATOR_FINANCES",
  });
