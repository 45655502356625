import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  BackHandler,
} from "react-native";
import React, { useEffect, useState } from "react";
import ProjectTitleComponent from "../../components/projectTitleComponent/projectTitleComponent";
import { connect } from "react-redux";
import Header from "../../components/header";
import LargeButton from "../../components/largeButton";
import RoundButton from "../../components/roundButton";
import { createProject } from "../../redux/actions/project.action";
import moment from "moment";
import { addError } from "../../redux/actions/toast.action";
import { fullName, primaryColor } from "../../components/utils";

const CardComponent = ({ item }) => {
  return (
    <View
      style={{
        borderWidth: 2,
        borderColor: "#E5E5E5",
        borderRadius: 8,
        margin: 5,
        padding: 15,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>
        {item.name}
      </Text>
      <Text style={{ fontSize: 11, color: "#B1B1B1" }}>{item.description}</Text>
    </View>
  );
};
const NewProjectDetails = ({
  user,
  navigation,
  route,
  selectedProject,
  createProject,
  addError,
}) => {
  const [timeDiff, setTimeDiff] = useState();

  useEffect(() => {
    if (selectedProject && selectedProject.initialCall) {
      setTimeDiff(
        moment(selectedProject.initialCall.time).diff(moment(), "minute", false)
      );
    }
  }, [selectedProject]);

  const updatePartnerAndNavigate = () => {
    if (selectedProject.clientCallReceived) {
      createProject(
        {
          _id: selectedProject._id,
          partner: user._id,
        },
        navigateAfterSuccess
      );
    } else {
      addError("Cannot proceed untill client received the call", 3000);
    }
  };

  const initiateCall = () => {
    createProject(
      {
        _id: selectedProject._id,
        clientCallReceived: true,
      },
      () => navigation.navigate("myProjects")
    );
  };
  const navigateAfterSuccess = () =>
    navigation.navigate("Project", {
      screen: "partner",
      params: {
        screen: "newProjectSetup",
        update: true,
      },
    });

  console.log(
    moment(
      new Date(
        parseInt(selectedProject._id?.toString().substring(0, 8), 16) * 1000
      ).toLocaleString(),
      "DD/MM/YYYY hh:mm:ss"
    )
      .add(7, "days")
      .diff(moment(new Date()), "days")
  );

  return (
    <View style={{ flex: 1 }}>
      <View style={{ minHeight: "18%", maxHeight: "18%", marginBottom: 10 }}>
        <Header
          title="new projects"
          subtitle="New project details"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginHorizontal: 15 }}
        contentContainerStyle={{ paddingBottom: 30 }}
      >
        <ProjectTitleComponent
          backgroundColor="#000"
          jsx={
            <View>
              <Text style={styles.titleStyle}>{selectedProject?.title}</Text>
              <Text style={{ fontSize: 11, color: "#FFF", marginTop: 10 }}>
                {`Will be accepted or denied in ${moment(
                  new Date(
                    parseInt(
                      selectedProject._id?.toString().substring(0, 8),
                      16
                    ) * 1000
                  ).toLocaleString(),
                  "DD/MM/YYYY hh:mm:ss"
                )
                  .add(7, "days")
                  .diff(moment(new Date()), "days")} days`}
              </Text>
            </View>
          }
        ></ProjectTitleComponent>
        <ProjectTitleComponent
          backgroundColor="#FFF"
          jsx={
            <View>
              {selectedProject.partner ? (
                <Text style={styles.detailsStyle}>
                  {`Partner: ${fullName(selectedProject.partner) || ""}`}
                </Text>
              ) : (
                <></>
              )}
              <Text
                style={styles.detailsStyle}
              >{`Client: ${selectedProject?.user?.fName}`}</Text>
              {selectedProject.budget && selectedProject.budget.length > 0 ? (
                <Text style={styles.detailsStyle}>
                  {`Budget Idea: ${selectedProject?.budget[0] || 0} to ${
                    selectedProject?.budget[1] || 0
                  }`}
                </Text>
              ) : (
                <></>
              )}
            </View>
          }
        ></ProjectTitleComponent>
        <ProjectTitleComponent
          backgroundColor="#FFF"
          jsx={
            <View>
              <Text style={styles.detailsStyle}>
                ONEDESIGN Query: 9999999999
              </Text>
              <Text style={styles.detailsStyle}>
                Email us at: query@onedesignlabs.com
              </Text>
            </View>
          }
        ></ProjectTitleComponent>
        {user.userType === "PARTNER" ? (
          <LargeButton
            arrowColor={"#FFF"}
            titleStyle={{ color: "#FFF" }}
            backgroundColor={
              selectedProject.clientCallReceived
                ? primaryColor("CLIENT")
                : "#ACACAC"
            }
            title={"Start Project Setup"}
            pressFunc={() => updatePartnerAndNavigate()}
          ></LargeButton>
        ) : (
          <></>
        )}

        {selectedProject.services && selectedProject.services.length > 0 ? (
          <View style={styles.marginBetween}>
            <Text style={styles.scopeStyle}>Scope of Work</Text>
            <FlatList
              data={selectedProject.services}
              keyExtractor={(item) => item._id}
              scrollEnabled
              nestedScrollEnabled
              renderItem={({ item }) => <CardComponent item={item} />}
            />
          </View>
        ) : (
          <></>
        )}

        <View style={styles.marginBetween}>
          <Text style={styles.textStyle}>{`${
            user.userType === "PARTNER" ? "Client's" : "Your"
          } Vision`}</Text>
          <Text style={styles.smallTextStyle}>{selectedProject.about}</Text>
        </View>

        {!selectedProject.clientCallReceived ? (
          selectedProject.initialCall ? (
            <View style={styles.marginBetween}>
              <Text style={styles.textStyle}>Initial Call Scheduled</Text>
              <Text style={styles.smallTextStyle}>
                The call is scheduled at:
              </Text>
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: 16,
                  marginHorizontal: 20,
                }}
              >
                {moment(selectedProject.initialCall.time).format("LLLL")}
              </Text>
              <RoundButton
                pressFunc={() => initiateCall()}
                style={{
                  backgroundColor: timeDiff < 0 ? "#000" : "#B7B7B7",
                  width: "60%",
                  marginHorizontal: 10,
                  marginVertical: 10,
                }}
                title={`Starts in ${timeDiff} minutes`}
              ></RoundButton>
            </View>
          ) : (
            <View style={styles.marginBetween}>
              <Text style={styles.textStyle}>
                Initial Call Not Scheduled Yet
              </Text>
            </View>
          )
        ) : (
          <></>
        )}

        {/* {user.userType === "PARTNER" &&
          selectedProject.partner?._id === user._id && (
            <LargeButton
              arrowColor={"#FFF"}
              titleStyle={{ color: "#FFF" }}
              backgroundColor={"#F27253"}
              title={"Discard Project"}
            ></LargeButton>
          )} */}
        {user.userType === "PARTNER" && !selectedProject.partner?._id && (
          <LargeButton
            pressFunc={() =>
              createProject({
                _id: selectedProject._id,
                partner: user._id,
              })
            }
            arrowColor={"#FFF"}
            titleStyle={{ color: "#FFF" }}
            backgroundColor={"#F27253"}
            title={"Accept"}
          ></LargeButton>
        )}

        {["PARTNER", "CLIENT"].includes(user.userType) &&
          selectedProject.partner?._id === user._id && (
            <LargeButton
              arrowColor={"#FFF"}
              titleStyle={{ color: "#FFF" }}
              backgroundColor={"#000"}
              title={
                user.userType === "PARTNER"
                  ? "Transfer Project"
                  : "Edit Project"
              }
              pressFunc={() =>
                navigation.navigate("New", {
                  screen: "sales",
                  params: {
                    screen: "basic-details",
                    update: true,
                  },
                })
              }
            ></LargeButton>
          )}
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, { createProject, addError })(
  NewProjectDetails
);

const styles = StyleSheet.create({
  titleStyle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFF",
    flexWrap: "wrap",
  },
  detailsStyle: {
    fontSize: 13,
    color: "#000",
  },
  scopeStyle: {
    color: "#828282",
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 10,
    marginHorizontal: 15,
  },
  textStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#828282",
    marginHorizontal: 19,
  },
  smallTextStyle: {
    fontSize: 11,
    color: "#606060",
    marginHorizontal: 20,
    marginVertical: 15,
  },
  marginBetween: {
    marginVertical: 10,
  },
});
