import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/partner/partnerDashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import Meeting from "../../screens/common/meeting/meetings";
import ScheduleMeetings from "../../screens/common/meeting/scheduleMeeting";
import ScheduleDetails from "../../screens/common/meeting/scheduleDetails";
import ChatWithUs from "../../screens/common/chat-with-us";
const window = Dimensions.get("window");

const PartnerStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={MeetingStack}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

export const MeetingStack = () => {
  return (
    <PartnerStack.Navigator name="partnerInox" initialRouteName={"meeting"}>
      <PartnerStack.Screen
        initialParams={{ type: "tab" }}
        name="meeting"
        component={Meeting}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="scheduleMeeting"
        component={ScheduleMeetings}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="scheduleDetails"
        component={ScheduleDetails}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
    </PartnerStack.Navigator>
  );
};
