import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import React, { useEffect, useState } from "react";

import Header from "../../../components/header";
import LargeTextInput from "../../../components/largeTextInput";
import RoundButton from "../../../components/roundButton";
import { connect } from "react-redux";
import { createSalesPromotionEntry } from "../../../redux/actions/sales-promotion.action";

const NewClientSetupScreen = ({ navigation, createSalesPromotionEntry }) => {
  const [promotionEntry, setPromotionEntry] = useState(initObj);
  const initObj = {
    fName: "",
    lName: "",
    email: "",
    phone: "",
  };
  useEffect(() => {
    setPromotionEntry(initObj);

    return () => {
      setPromotionEntry(initObj);
    };
  }, []);
  const onChange = (data) => {
    setPromotionEntry({ ...promotionEntry, ...data });
  };
  const create = () => {
    createSalesPromotionEntry(promotionEntry, () =>
      navigation.navigate("VerificationOfUsers")
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new Client Setup "
          subtitle="Please setup new Client"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#000000" }}
        ></Header>
      </View>
      <View style={{ backgroundColor: "#FAFAFA" }}>
        <Text style={styles.headerStyle}>
          enter details about the prospective client
        </Text>
        <View style={{ marginTop: 50 }}>
          <LargeTextInput
            placeholder="enter client's first name"
            style={{
              borderBottomColor: "#707070",
              fontSize: 16,
            }}
            onChangeText={(data) => onChange({ fName: data })}
          ></LargeTextInput>
          <View style={styles.wrapperStyle}>
            <LargeTextInput
              placeholder="enter client's last name"
              style={{ borderBottomColor: "#707070", fontSize: 16 }}
              onChangeText={(data) => onChange({ lName: data })}
            ></LargeTextInput>
          </View>
          <View style={styles.wrapperStyle}>
            <LargeTextInput
              keyboardType={"email-address"}
              placeholder="enter client's your email-id"
              style={{ borderBottomColor: "#707070", fontSize: 16 }}
              onChangeText={(data) => onChange({ email: data })}
            ></LargeTextInput>
          </View>
          <View style={styles.wrapperStyle}>
            <LargeTextInput
              keyboardType={"number-pad"}
              placeholder="enter client's phone number"
              style={{ borderBottomColor: "#707070", fontSize: 16 }}
              onChangeText={(data) => onChange({ phone: data })}
            ></LargeTextInput>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          position: "absolute",
          bottom: 10,
          left: 20,
        }}
      >
        <RoundButton
          style={{ backgroundColor: "#000000", width: 100 }}
          title={"Next"}
          pressFunc={() => create()}
        ></RoundButton>
        <RoundButton
          style={{ backgroundColor: "#EB4141", width: 100, marginLeft: 10 }}
          title={"Discard"}
        ></RoundButton>
      </View>
    </View>
  );
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { createSalesPromotionEntry })(
  NewClientSetupScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headerStyle: {
    fontSize: 22,
    fontWeight: "bold",
    margin: 20,
    color: "#000",
  },
  wrapperStyle: {
    marginTop: 60,
  },
});
