export const fetchExpertMaterial = () => (dispatch) => {
  return dispatch({
    type: "FETCH_EXPERT_MATERIAL",
    payload: {
      request: {
        url: `/api/expert-material`,
        method: "GET",
      },
    },
  });
};

export const createExpertMaterial = (obj) => (dispatch) => {
  return dispatch({
    type: "CREATE_EXPERT_MATERIAL",
    payload: {
      request: {
        url: `/api/expert-material`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const removeExpertMaterial = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_EXPERT_MATERIAL",
    payload: {
      request: {
        url: `/api/expert-material/${id}`,
        method: "DELETE",
      },
    },
  });
};
