export const fetchNotifications = (lastNotification) => (dispatch) => {
  return dispatch({
    type: "FETCH_NOTIFICATION",
    payload: {
      request: {
        url: `/api/notification/fetch?${
          lastNotification ? "lastNotification=" + lastNotification : ""
        }`,
        method: "GET",
      },
    },
  });
};

export const fetchNotificationCount = () => (dispatch) => {
  return dispatch({
    type: "FETCH_NOTIFICATION_COUNT",
    payload: {
      request: {
        url: `/api/notification/count`,
        method: "GET",
      },
    },
  });
};

export const clearNotifications = () => (dispatch) => {
  return dispatch({
    type: "CLEAR_NOTIFICATION",
    payload: {
      request: {
        url: `/api/notification`,
        method: "DELETE",
      },
    },
  });
};

export const seenAllNotifications = () => (dispatch) => {
  return dispatch({
    type: "SEEN_ALL_NOTIFICATION",
    payload: {
      request: {
        url: `/api/notification/seen`,
        method: "GET",
      },
    },
  });
};

export const markAsRead = (notification) => (dispatch) => {
  return dispatch({
    type: "MARK_AS_READ",
    payload: {
      request: {
        url: `/api/notification/read?notification=${notification}`,
        method: "GET",
      },
    },
  });
};
