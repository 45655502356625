import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Linking,
  useWindowDimensions,
  Image,
} from "react-native";
import React, { useEffect } from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import WelcomeMessageComponent from "../../components/welcome_message_compoment/welcomeMessageComponent";
import DashboardCard from "../../components/dashboardCard";
import ItemWithCountValue from "../../components/itemWithCountValue/itemWithCountValue";
import { fetchSalesStats } from "../../redux/actions/user.action";
import moment from "moment";
import { abbrNum, primaryColor } from "../../components/utils";
import { fetchExpertMaterial } from "../../redux/actions/expert-material.action";
import { Video } from "expo-av";
import { fetchWelcomeMessage } from "../../redux/actions/welcome-message.action";
const Dashboard = ({
  user,
  navigation,
  clientList,
  fetchSalesStats,
  userStats,
  fetchExpertMaterial,
  expertMaterials,
  fetchWelcomeMessage,
  welcomeMessages,
}) => {
  useEffect(() => {
    fetchSalesStats();
    fetchExpertMaterial();
    fetchWelcomeMessage("SALES");
  }, []);
  const dimension = useWindowDimensions();
  return (
    <View style={styles.Container}>
      <View style={styles.headerStyle}>
        <Header
          disableBackButton={true}
          title={`Welcome, ${user.fName}`}
          subtitle="Let’s create greatness today!"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      {welcomeMessages.length ? (
        <ScrollView
          nestedScrollEnabled={true}
          style={{ maxHeight: 80 }} // due to scrollview issue in mobile i am giving fix height
          horizontal={true}
        >
          {welcomeMessages.map((x) => (
            <WelcomeMessageComponent
              key={x._id}
              textColor={"#FFF"}
              backgroundColor="#073257"
              message={x.message}
              image={x.image}
            ></WelcomeMessageComponent>
          ))}
        </ScrollView>
      ) : (
        <></>
      )}
      <View style={styles.Card_Container}>
        <ScrollView style={{ marginHorizontal: 5 }}>
          <DashboardCard
            jsx={
              <TouchableOpacity
                disabled
                style={{
                  height: "90%",
                }}
                // onPress={() => navigation.navigate("VerificationOfUsers")}
              >
                <Text style={styles.cardTitleStyle}>
                  {`Sales metrics in ${moment().format("MMMM")}`}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    bottom: 0,
                    position: "absolute",
                  }}
                >
                  <ItemWithCountValue
                    countValue={userStats.clients || 0}
                    itemName={"Clients"}
                    textColor={"#FFF"}
                  ></ItemWithCountValue>
                  <ItemWithCountValue
                    countValue={abbrNum(userStats.revenue, 2) || 0}
                    itemName={"Revenue in INR"}
                    textColor={"#FFF"}
                  ></ItemWithCountValue>
                </View>
              </TouchableOpacity>
            }
            disableIcon={true}
            icon={false}
            style={{
              maxHeight: 150,
              minHeight: 150,
              backgroundColor: "#2F47FF",
            }}
          ></DashboardCard>
          <Text
            style={{
              color: "#828282",
              marginVertical: 10,
              marginHorizontal: 20,
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            expert materials for you
          </Text>
          <ScrollView
            contentContainerStyle={{ paddingBottom: 50 }}
            style={{ width: "100%" }}
            horizontal={true}
            nestedScrollEnabled={true}
            showsHorizontalScrollIndicator={false}
          >
            {expertMaterials?.map((e) => (
              <View
                key={e._id}
                style={[
                  styles.videoCardStyle,
                  {
                    width: 320,
                    backgroundColor: primaryColor("SALES"),
                  },
                ]}
              >
                <TouchableOpacity
                  style={{ height: "75%", width: "100%" }}
                  onPress={() => Linking.openURL(e.url)}
                >
                  {e.type === "Image" ? (
                    <Image
                      source={{ uri: e.url }}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    />
                  ) : (
                    <Video
                      style={[styles.community]}
                      source={{
                        uri: e.url,
                      }}
                      useNativeControls={false}
                      resizeMode="cover"
                      isLooping={false}
                      shouldPlay
                      isMuted
                    />
                  )}
                </TouchableOpacity>
                <Text style={styles.label}>{e.label}</Text>
                <Text style={styles.description}>{e.description}</Text>
              </View>
            ))}
          </ScrollView>
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({
  user,
  clientList,
  userStats,
  expertMaterials,
  welcomeMessages,
}) => ({
  user,
  clientList,
  userStats,
  expertMaterials,
  welcomeMessages,
});
export default connect(mapStateToProps, {
  fetchSalesStats,
  fetchExpertMaterial,
  fetchWelcomeMessage,
})(Dashboard);

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
  contentContainer: {
    backgroundColor: "#FFFFFF",
  },
  cardTitleStyle: {
    margin: 10,
    fontSize: 16,
    color: "#000",
    fontWeight: "bold",
  },
  videoCardStyle: {
    height: 230,
    borderRadius: 7,
    marginHorizontal: 10,
    borderRadius: 10,
    // borderWidth: 1,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
    borderColor: "#707070",
  },
  label: {
    fontSize: 20,
    color: "#FFF",
    fontWeight: "bold",
    marginHorizontal: 10,
  },
  description: {
    fontSize: 18,
    color: "#FFF",
    marginHorizontal: 10,
  },
  textStyle: {
    marginHorizontal: 15,
    marginVertical: 10,
    color: "#828282",
    fontWeight: "bold",
    fontSize: 16,
  },
  cardTitleStyle: {
    alignSelf: "flex-start",
    margin: 15,
    fontSize: 16,
    color: "#FFF",
    fontWeight: "bold",
  },
  community: {
    flex: 1,
    minHeight: 130,
    maxHeight: 200,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    width: "100%",
  },
});
