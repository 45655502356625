import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/admin/screens/dashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import myProjects from "../../screens/common/myProjects";
import editYourProfile from "../../screens/editYourProfile";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import chatWithUs from "../../screens/common/chat-with-us";

const window = Dimensions.get("window");

const AccountantStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <AccountantStack.Navigator
      name="partnerProject"
      initialRouteName={"myProjects"}
    >
      <AccountantStack.Screen
        name="myProjects"
        component={myProjects}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="editYourProfile"
        component={editYourProfile}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="activeProjectDetails"
        component={ActiveProjectDetails}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="chat-with-us"
        component={chatWithUs}
        options={{ headerShown: false }}
      />
    </AccountantStack.Navigator>
  );
};
