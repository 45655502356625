import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/partner/partnerDashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Discussions from "../../screens/common/inbox_screens/discussions";
import discussionGroups from "../../screens/common/inbox_screens/discussionGroups";
import chat from "../../screens/chat";
import login from "../../screens/login";
import EditYourProfile from "../../screens/editYourProfile";
import ChatWithUs from "../../screens/common/chat-with-us";
import userProfile from "../../screens/common/userProfile";
import changePassword from "../../screens/changePassword";

const window = Dimensions.get("window");

const PartnerStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={ProfileStack}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

export const ProfileStack = () => {

  return (
    <PartnerStack.Navigator name="partnerInox" initialRouteName={"profile"}>
      <PartnerStack.Screen
        name="profile"
        component={userProfile}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="changePassword"
        component={changePassword}
        options={{ headerShown: false }}
      />
    </PartnerStack.Navigator>
  );
};
