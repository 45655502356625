const initialState = [];
const engagementType = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ENGAGEMENT_TYPE_SUCCESS": {
      return action.payload.data;
    }

    case "CREATE_ENGAGEMENT_TYPE_SUCCESS": {
      let engType = state.find((a) => a._id == action.payload.data._id);
      return engType
        ? state.map((p) => (p._id == engType._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "REMOVE_ENGAGEMENT_TYPE_SUCCESS": {
      let index = state.findIndex((x) => x._id === action.payload.data._id);
      state.splice(index, 1);
      return [...state];
    }

    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default engagementType;
