import React from "react";
import {
  TouchableOpacity,
  Text,
  View,
  StyleSheet,
  ImageBackground,
} from "react-native";
import Icon from "../components/icon";
import adjust from "./adjust";

const InnerComponent = ({
  title,
  style,
  titleStyle,
  enableINR,
  stats,
  statsStyle,
  label,
  labelStyle,
  inrStyle,
  iconStyle,
  customBox,
  iconFill,
  onPress,
  active,
  activeStyle,
  textStyle,
  icon,
  disableIcon,
}) => {
  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity style={{ flex: 1 }} onPress={onPress}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
          }}
        >
          {!disableIcon ? (
            icon ? (
              icon
            ) : (
              <Icon
                name="box3D"
                style={[styles.icon, iconStyle]}
                fill={iconFill}
              ></Icon>
            )
          ) : (
            <></>
          )}

          <Text style={[styles.title, titleStyle, textStyle]}>{title}</Text>
        </View>
        {customBox ? (
          <View style={{ flex: 4 }}>{customBox}</View>
        ) : (
          <View
            style={{
              flex: 4,
              padding: 10,
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {active ? (
              <View style={{ marginRight: 20 }}>
                <Text style={[styles.inr, inrStyle, textStyle]}></Text>
                <Text style={[styles.active, activeStyle, textStyle]}>
                  {active}
                </Text>
                <Text style={[styles.label, labelStyle, textStyle]}>
                  Active
                </Text>
              </View>
            ) : (
              <></>
            )}
            <View
              style={{ flex: 1, justifyContent: "flex-end", flexWrap: "wrap" }}
            >
              {enableINR ? (
                <Text style={[styles.inr, inrStyle, textStyle]}>₹</Text>
              ) : (
                <Text style={[styles.inr, inrStyle, textStyle]}></Text>
              )}
              {stats ? (
                <Text style={[styles.stats, statsStyle, textStyle]}>
                  {stats}
                </Text>
              ) : (
                <></>
              )}
              <Text style={[styles.label, labelStyle, textStyle]}>{label}</Text>
            </View>
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};
export default function DashboardCard({
  title,
  style,
  titleStyle,
  enableINR,
  stats,
  statsStyle,
  label,
  labelStyle,
  inrStyle,
  iconStyle,
  customBox,
  iconFill,
  background,
  onPress,
  active,
  activeStyle,
  textStyle,
  jsx,
  icon,
  disableIcon,
}) {
  return (
    <View style={[styles.wrapper, style]}>
      {jsx ? (
        jsx
      ) : background ? (
        <ImageBackground source={background} style={styles.backgroundImage}>
          <InnerComponent
            {...{
              title,
              titleStyle,
              enableINR,
              stats,
              statsStyle,
              label,
              labelStyle,
              inrStyle,
              iconStyle,
              customBox,
              iconFill,
              onPress,
              active,
              activeStyle,
              textStyle,
              icon,
              disableIcon,
            }}
          ></InnerComponent>
        </ImageBackground>
      ) : (
        <InnerComponent
          {...{
            title,
            titleStyle,
            enableINR,
            stats,
            statsStyle,
            labelStyle,
            inrStyle,
            iconStyle,
            label,
            customBox,
            iconFill,
            onPress,
            active,
            activeStyle,
            textStyle,
            icon,
            disableIcon,
          }}
        ></InnerComponent>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginTop: 10,
    marginHorizontal: 10,
    marginBottom: 5,
  },
  container: {
    flex: 1,
  },
  title: {
    fontWeight: "bold",
    fontSize: adjust(15),
    marginLeft: 5,
    color: "#a9a9a9",
    flex: 5,
  },
  stats: {
    flex: 2,
    fontWeight: "bold",
    fontSize: adjust(40),
    color: "#707070",
    width: "100%",
  },
  label: {
    flex: 1,
    fontSize: adjust(13),
    color: "#606060",
    justifyContent: "flex-end",
  },
  inr: {
    flex: 1,
    fontSize: adjust(12),
    fontWeight: "bold",
    color: "#FFD500",
    top: 10,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: 10,
  },
  icon: {
    flex: 1,
  },
  active: {
    flex: 2,
    color: "#FFD500",
    minWidth: 50,
    fontWeight: "bold",
    fontSize: adjust(40),
  },
});
