import React from "react";
import { View, Text, TextInput, StyleSheet } from "react-native";
import RoundButton from "../../components/roundButton";
import ProjectTextInput from "../partner/setupProjectComponents/initiateProject/projectTextInput";

const Budget = ({
  onChangeText,
  onPressBudget,
  budget,
  setBudget,
  validateNow,
}) => {
  return (
    <View style={{ flex: 1 }}>
      {/* <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
        <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000000" }}>
          {" "}
          Tell us your budget:
        </Text>
        <View style={styles.iconWrapper}>
          <Text style={{ alignSelf: "center", color: "#fff" }}>?</Text>
        </View>
      </View> */}
      <View style={{ margin: 10 }}>
        <ProjectTextInput
          title="Tell us your budget:"
          onDataChange={onChangeText}
          required={true}
          data={budget}
          textType={false}
          selectionValue={budget}
          onSelectionChange={setBudget}
          inValidText="Project Description cannot be blank"
          validateNow={validateNow}
          placeholder="Tap to select"
          renderData={[
            { name: "Under 01 lakh", minValue: 0, maxValue: 100000 },
            {
              name: "01 lakhs - 02 lakhs",
              minValue: 100000,
              maxValue: 200000,
            },
            {
              name: "02 lakhs - 05 lakhs",
              minValue: 200000,
              maxValue: 500000,
            },
            {
              name: "05 lakhs - 10 lakhs",
              minValue: 500000,
              maxValue: 1000000,
            },
            {
              name: "Above 10 lakhs",
              minValue: 1000000,
              maxValue: 1500000,
            },
          ]}
        ></ProjectTextInput>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  nextPhoto: {
    backgroundColor: "#000",
    maxWidth: 400,
    width: "80%",
    marginTop: 330,
    marginLeft: 30,
    height: 70,
    marginVertical: 5,
  },
  textInputStyle: {
    fontSize: 11,
    alignSelf: "center",
    width: "75%",
    marginTop: 50,
    borderBottomColor: "#707070",
    borderBottomWidth: 1,
  },
  iconWrapper: {
    height: 20,
    width: 20,
    borderRadius: 100,
    justifyContent: "center",
    backgroundColor: "#707070",
  },
});
export default Budget;
