import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import EditYourProfile from "../screens/editYourProfile";
import ChangePassword from "../screens/changePassword";
import { Dimensions } from "react-native";
import NotificationDrawer from "../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

const window = Dimensions.get("window");

const AccountantStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default () => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="accountant"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <AccountantStack.Navigator
      name="clientNew"
      initialRouteName={"editYourProfile"}
    >
      <AccountantStack.Screen
        name="editYourProfile"
        component={EditYourProfile}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="changePassword"
        component={ChangePassword}
        options={{ headerShown: false }}
      />
    </AccountantStack.Navigator>
  );
};
