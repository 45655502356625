import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import PopUp from "./popUp/popUp";
import DatePicker from "./datePicker/datePicker";
import adjust from "./adjust";
export default function CreatorSignupInput({
  title,
  placeholder,
  renderData,
  onSelectionChange,
  data,
  onDataChange,
  calendar,
  textType,
  selectionValue,
  isValid,
  inValidStyle,
  validationType,
  validateNow,
  required = false,
  keyboardType,
  multiline = true,
  numberOfLines,
  secureTextEntry,
  textInputStyle,
  docType,
  pickDocument,
  multiSelect,
  onSelection,
  editable,
  onFocus,
  titleStyle,
  width = Dimensions.get("window").width,
  displayField,
  disableBottomBorder,
  placeholderFontSize,
  placeholderStyle,
}) {
  let validationReg;
  const [valid, setValid] = useState(true);
  useEffect(() => {
    if (validateNow) {
      if (textType) {
        validate(data);
      } else {
        validate(selectionValue);
      }
    }
  }, [validateNow]);

  function validate(v) {
    let val = valid;
    if (required && v) {
      if (validationReg) {
        val = validationReg.test(v);
      } else {
        val = true;
      }
    } else if (v) {
      if (validationReg) {
        val = validationReg.test(v);
      }
    } else {
      val = false;
    }
    setValid(val);
    if (val) {
      setFocusBorderColor("#41B000");
    } else {
      setFocusBorderColor("#F33A3A");
    }
    if (isValid) {
      isValid(val);
    }
  }
  useEffect(() => {
    validationReg =
      validationType === "number"
        ? /^(\d+\.\d+)$|^(\d+)$/i
        : validationType === "email"
        ? /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i
        : validationType === "phone"
        ? /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/i
        : validationType === "aadhar"
        ? /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i
        : validationType === "pan"
        ? /^[A-Z]{5}[0-9]{4}[A-Z]{1}/i
        : validationType === "pin"
        ? /^[1-9][0-9]{5}$/i
        : validationType === "numeric"
        ? /^(\d+\.\d+)$|^(\d+)$/i
        : null;
  });
  const [focusBorderColor, setFocusBorderColor] = useState("#606060");
  const [focusBorderWidth, setFocusBorderWidth] = useState(1);
  const [focusContainerColor, setFocusContainerColor] = useState("#FFFFFF");
  const [focusInputColor, setInputColor] = useState("#FFFFFF");
  const [inputData, setInputData] = useState(data ? data : "");
  const [date, setDate] = useState(
    selectionValue ? selectionValue : new Date()
  );
  const [selectedValue, setSelectedValue] = useState(selectionValue);
  const setData = (e) => {
    validate(e);
    onDataChange(e);
    setInputData(e);
  };
  const onDateChange = (e) => {
    validate(e);
    setDate(e);
    onDataChange(e);
    setFocusBorderColor("#FFD500");
    setFocusBorderWidth(1);
    setFocusContainerColor("#FFFFFF");
    setInputColor("#F7F7F7");
  };

  useEffect(() => {
    setInputData(data);
  }, [data]);

  useEffect(() => {
    setSelectedValue(selectionValue);
  }, [selectionValue]);

  const window = Dimensions.get("window");
  return (
    <View
      style={[
        styles.textContainer,
        {
          borderColor: focusBorderColor,
          zIndex: calendar ? 1 : 0,
          position: "relative",
          height: 100,
          borderBottomWidth: 1,
          width: width - 40,
        },
      ]}
    >
      <Text style={[styles.titleText, titleStyle]}>{title}</Text>
      {renderData ? (
        <PopUp
          disableBottomBorder={disableBottomBorder}
          displayField={displayField}
          renderData={renderData}
          style={{
            width: window.width - 40,
            borderBottomWidth: 0,
            maxHeight: 50,
            minHeight: 50,
          }}
          multiSelectStyle={{
            backgroundColor: "#00000000",
          }}
          placeholder={placeholder}
          selectedItemStyle={styles.selectTextStyle}
          placeholderContainerStyle={{
            minHeight: 50,
            maxHeight: 50,
            borderRadius: 8,
            marginTop: 17,
            // paddingTop: 15,
            // paddingHorizontal: 10,
            // width: width + 15 || window.width - 40,
          }}
          placeholderStyle={[
            {
              width: window.width - 40,
              fontSize: placeholderFontSize,
            },
            placeholderStyle,
          ]}
          iconStyle={{ marginTop: 18 }}
          onSelection={
            multiSelect
              ? (e) => {
                  setFocusBorderColor("#41B000");
                  onSelection(e);
                  validate(e);
                }
              : (e) => {
                  setFocusBorderColor("#41B000");
                  onSelectionChange(e);
                  setSelectedValue(e);
                  setFocusBorderWidth(1);
                  validate(e);
                }
          }
          selectionValue={selectedValue}
          multiSelect={multiSelect}
        ></PopUp>
      ) : (
        <></>
      )}
      {textType ? (
        <TextInput
          secureTextEntry={secureTextEntry}
          placeholder={placeholder}
          placeholderTextColor="#9C9C9C"
          multiline={multiline}
          numberOfLines={numberOfLines}
          style={[
            styles.textInput,
            numberOfLines ? { minHeight: numberOfLines * 13 } : {},
          ]}
          onFocus={() => {
            if (onFocus && typeof onFocus === "function") {
              onFocus();
            }
            setFocusBorderColor("#FFD500");
          }}
          onBlur={() => {
            setFocusBorderColor(
              data ? (valid ? "#41B000" : "#F33A3A") : "#FFF"
            );
          }}
          onChangeText={(e) => setData(e)}
          value={`${inputData}`}
          keyboardType={keyboardType}
          editable={editable}
        ></TextInput>
      ) : docType ? (
        <TouchableOpacity
          onPress={() => {
            pickDocument();
            setFocusBorderColor(data ? "#41B000" : "#FFF");
          }}
        >
          <View
            style={[
              styles.docInput,
              textInputStyle,
              { backgroundColor: focusInputColor },
            ]}
          >
            <Text numberOfLines={1} style={{ color: "#B1B1B1" }}>
              {data ? data.name : placeholder}
            </Text>
          </View>
        </TouchableOpacity>
      ) : (
        <></>
      )}
      {calendar ? (
        <View
          style={{
            flex: 1,
            height: 50,
            borderRadius: 8,
            padding: 10,
            marginTop: 25,
            position: "relative",
            zIndex: 1,
            borderColor: focusBorderColor,
            borderWidth: focusBorderWidth,
            backgroundColor: focusContainerColor,
          }}
        >
          <DatePicker onChange={(e) => onDateChange(e)} value={date} />
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  selectTextStyle: {
    fontSize: adjust(13),
    alignSelf: "center",
    marginBottom: 0,
  },

  titleText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#000",
  },
  textContainer: {
    minHeight: 80,
    maxHeight: 120,
    paddingVertical: 15,
    marginBottom: 30,
  },
  textInput: {
    fontSize: adjust(13),
    height: 60,
    marginTop: 10,
    borderBottomColor: "#000",
    // borderBottomWidth: 1,
  },
  docInput: {
    fontSize: adjust(13),
    height: 25,
    marginTop: 25,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
});
