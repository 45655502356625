import { StyleSheet, Text, View, Pressable } from "react-native";
import React from "react";
import { connect } from "react-redux";
import Header from "../../../components/header";
import RoundButton from "../../../components/roundButton";
import SelectItemWithCheckbox from "../../../components/selectItemWithCheckbox/selectItemWithCheckbox";
import ContainerWithDifferentBg from "../../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import { fullName } from "../../../components/utils";
const SalesEndPhase = ({ user, navigation, selectedProject }) => {
  return (
    <View style={{ flex: 1, backgroundColor: "#FAFAFA" }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new projects"
          subtitle="New project details"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <Text style={styles.headerStyle}>
        we have recorded everything that's needed for now
      </Text>
      <View style={{ margin: 15 }}>
        <ContainerWithDifferentBg
          titleFontSize={20}
          content={selectedProject.title}
          backgroundColor={"#3A8CD1"}
          subTitle={
            selectedProject.partner
              ? "for " + fullName(selectedProject.partner)
              : null
          }
        ></ContainerWithDifferentBg>
      </View>
      <Text style={{ fontSize: 16, fontWeight: "bold", marginLeft: 30 }}>
        {`You’ve done your part`}
      </Text>
      <Text style={{ fontSize: 11, marginHorizontal: 30, marginTop: 15 }}>
        At this point, we have all the information that is needed. Schedule a
        meeting below with our partners to discuss on your project. While
        talking to your partner, share the code below.
      </Text>
      <View style={{ flexDirection: "row" }}>
        <Text
          style={{
            marginLeft: 30,
            color: "#000",
            marginTop: 15,
            fontWeight: "bold",
          }}
        >
          {`Special Code : `}
        </Text>
        <Text
          style={{
            color: "#3A8CD1",
            marginTop: 15,
            fontWeight: "bold",
          }}
        >
          {selectedProject.otp}
        </Text>
      </View>
      <View style={{ flexDirection: "row", margin: 25 }}>
        <Pressable
          style={[styles.pressableStyle]}
          onPress={() =>
            navigation.navigate("scheduleMeeting", { type: "initial" })
          }
        >
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 15,
                fontWeight: "bold",
                alignSelf: "center",
                justifyContent: "center",
              },
            ]}
          >
            {"setup meeting"}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps)(SalesEndPhase);

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    marginHorizontal: 30,
    color: "#000",
  },
  wrapperStyle: {
    marginTop: 70,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginVertical: 10,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 150,
  },
});
