import { StyleSheet, Text, View, Image } from "react-native";
import React from "react";

const PerformanceComponent = ({
  widthSize,
  name,
  title,
  imageUri,
  currency,
}) => {
  return (
    <View style={styles.container}>
      <View>
        <Image
          style={{
            width: 50,
            height: 50,
            borderRadius: 100 / 2,
          }}
          source={imageUri}
        ></Image>
      </View>

      <View style={{ marginLeft: 15, width: "100%" }}>
        <Text style={{ fontSize: 16, fontWeight: "bold" }}>{name}</Text>
        <Text style={{ fontSize: 13 }}>{title}</Text>
        <View
          style={{
            height: 10,
            width: widthSize,
            backgroundColor: "#073257",
            marginTop: 10,
            marginBottom: 10,
          }}
        ></View>

        <Text style={{ fontSize: 13 }}>INR {currency}</Text>
      </View>
    </View>
  );
};

export default PerformanceComponent;

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    flex: 1,
    flexDirection: "row",
  },
  card_image: {
    height: 90,
    width: 90,
  },
});
