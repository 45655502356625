import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { connect } from "react-redux";
import BasicDetails from "../../screens/signUp/common/basic-details";
import OtpScreen from "../../screens/signUp/common/otp";
import CreadentialScreen from "../../screens/signUp/common/creadential";
import ImageScreen from "../../screens/signUp/common/image";
import FinalStep from "../../screens/signUp/common/final-step";
const SalesSignupStack = createStackNavigator();

const SalesSignUpStack = () => {
  return (
    <SalesSignupStack.Navigator name="basicDetails">
      <SalesSignupStack.Screen
        name="basicDetails"
        component={BasicDetails}
        options={{ headerShown: false }}
        initialParams={{ stack: "SALES" }}
      />
      <SalesSignupStack.Screen
        name="otpScreen"
        component={OtpScreen}
        options={{ headerShown: false }}
      />
      <SalesSignupStack.Screen
        name="creadentialScreen"
        component={CreadentialScreen}
        options={{ headerShown: false }}
      />
      <SalesSignupStack.Screen
        name="imageScreen"
        component={ImageScreen}
        options={{ headerShown: false }}
      />
      <SalesSignupStack.Screen
        name="finalStep"
        component={FinalStep}
        options={{ headerShown: false }}
        initialParams={{ stack: "SALES" }}
      />
    </SalesSignupStack.Navigator>
  );
};
const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, {})(SalesSignUpStack);
