export const createProject = (projectObj, navigate) => (dispatch) => {
  return dispatch({
    type: "CREATE_PROJECT",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/project`,
        method: "POST",
        data: projectObj,
      },
    },
  });
};

export const createProjectBySales = (projectObj, navigate) => (dispatch) => {
  return dispatch({
    type: "CREATE_PROJECT",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/project/create/sales`,
        method: "POST",
        data: projectObj,
      },
    },
  });
};

export const createProjectMeeting = (projectObj, navigate) => (dispatch) => {
  return dispatch({
    type: "CREATE_PROJECT_PROJECT",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/project/meetings/add`,
        method: "POST",
        data: projectObj,
      },
    },
  });
};

export const fetchAllActiveProjects = () => (dispatch) => {
  return dispatch({
    type: "FETCH_ACTIVE_PROJECTS",
    payload: {
      request: {
        url: `/api/project/fetch`,
        method: "POST",
        data: { active: true },
      },
    },
  });
};

export const fetchAllClosedProjects = () => (dispatch) => {
  return dispatch({
    type: "FETCH_CLOSED_PROJECTS",
    payload: {
      request: {
        url: `/api/project/fetch`,
        method: "POST",
        data: { closed: true },
      },
    },
  });
};

export const setSelectedProject = (projectObj) => ({
  type: "SET_SELECTED_PROJECT",
  payload: { data: projectObj },
});

export const clearSelectedProject = () => ({
  type: "CLEAR_SELECTED_PROJECT",
});

export const setSelectedQuotation = (projectObj) => ({
  type: "SET_SELECTED_QUOTATION",
  payload: { data: projectObj },
});

export const clearSelectedQuotation = () => ({
  type: "CLEAR_SELECTED_QUOTATION",
});

export const closeQuotation = (quotationObj) => (dispatch) => {
  return dispatch({
    type: "CLOSE_QUOTATION",
    payload: {
      request: {
        url: `/api/project/quotation/close`,
        method: "POST",
        data: quotationObj,
      },
    },
  });
};

export const selectQuotation = (quotationObj) => (dispatch) => {
  return dispatch({
    type: "SELECT_QUOTATION",
    payload: {
      request: {
        url: `/api/project/quotation/select`,
        method: "POST",
        data: quotationObj,
      },
    },
  });
};

export const removeQuotation = (quotationObj) => (dispatch) => {
  return dispatch({
    type: "REMOVE_QUOTATION",
    payload: {
      request: {
        url: `/api/project/team/remove`,
        method: "POST",
        data: quotationObj,
      },
    },
  });
};

// export const startProject = (id, navigate) => (dispatch) =>
//   dispatch({
//     type: "START_PROJECT",
//     navigate: navigate,
//     payload: {
//       request: {
//         url: `/api/project/start?project=${id}`,
//         method: "GET",
//       },
//     },
//   });

export const acceptProject = (id, navigate) => (dispatch) =>
  dispatch({
    type: "ACCEPT_PROJECT",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/project/accept?project=${id}`,
        method: "GET",
      },
    },
  });

export const giveUpProject = (obj) => (dispatch) =>
  dispatch({
    type: "GIVEUP_PROJECT",
    payload: {
      request: {
        url: `/api/project/giveup`,
        method: "POST",
        data: obj,
      },
    },
  });

export const updateStepStatus = (obj) => (dispatch) =>
  dispatch({
    type: "UPDATE_STEP_STATUS",
    payload: {
      request: {
        url: `/api/project/step/status`,
        method: "POST",
        data: obj,
      },
    },
  });

export const updatePaymentStatus = (obj) => (dispatch) =>
  dispatch({
    type: "UPDATE_PAYMENT_STATUS",
    payload: {
      request: {
        url: `/api/payment`,
        method: "POST",
        data: obj,
      },
    },
  });

export const requestCustomPayment = (obj) => (dispatch) =>
  dispatch({
    type: "REQUEST_CUSTOM_PAYMENT",
    payload: {
      request: {
        url: `/api/project/payment/add`,
        method: "POST",
        data: obj,
      },
    },
  });

export const addDeliverables = (obj) => (dispatch) =>
  dispatch({
    type: "ADD_DELIVERABLES",
    payload: {
      request: {
        url: `/api/project/deliverables`,
        method: "POST",
        data: obj,
      },
    },
  });

export const downloadDeliverableFile = (id) => (dispatch) =>
  dispatch({
    type: "DOWNLOAD_DELIVERABLES",
    payload: {
      request: {
        url: `/api/project/deliverables/file?fileId=${id}`,
        method: "GET",
      },
    },
  });

export const fetchAllProjectsByClient = (obj) => (dispatch) => {
  return dispatch({
    type: "FETCH_ALL_PROJECTS_BY_CLIENT",
    payload: {
      request: {
        url: `/api/project/fetch`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const updateFeedback = (obj, navigate) => (dispatch) => {
  return dispatch({
    type: "UPDATE_FEEDBACK",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/project/feedback`,
        method: "POST",
        data: obj,
      },
    },
  });
};
