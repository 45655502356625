import React from "react";
import { useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ScrollView,
  TextInput,
} from "react-native";
import { connect } from "react-redux";
import LargeButton from "../components/largeButton";
import RoundButton from "../components/roundButton";
import ProjectTextInput from "./partner/setupProjectComponents/initiateProject/projectTextInput";
import axios from "axios";
import config from "../config/config";
import { addError, addInfo } from "../redux/actions/toast.action";
import adjust from "../components/adjust";
import Header from "../components/header";

const changePassword = ({ navigation, user, addError, addInfo }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validateNow, setValidateNow] = useState(false);
  const [form, setForm] = useState({});
  const [match, isMatch] = useState(false);
  const [focusBorderColor, setFocusBorderColor] = useState({
    currentPassword: "#FFFFFF",
    newPassword: "#FFFFFF",
    confirmPassword: "#FFFFFF",
  });

  const client = axios.create({
    baseURL: config.baseUrl,
    responseType: "json",
  });

  client.interceptors.request.use((req) => {
    req.baseURL = config.baseUrl;
    req.timeout = 60000;
    if (user && user.token) {
      req.headers.common.Authorization = `Bearer ${user.token}`;
    }
    return req;
  });

  const checkPassword = () => {
    client.get(`/api/user/password/compare/${currentPassword}`).then(
      (result) => {
        setFocusBorderColor({
          ...focusBorderColor,
          currentPassword: result.data.match ? "#41B000" : "#F33A3A",
        });
        if (result.data.match) {
          isMatch(true);
          addInfo("Password matched successfully!", 3000);
        } else {
          isMatch(false);
          addError(
            "Sorry, Given password does not match with old password!",
            3000
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const updatePassword = () => {
    client.get(`/api/user/password/change/${newPassword}`).then(
      (result) => {
        addInfo("Password changed successfully!", 3000);
        navigation.navigate("editYourProfile");
      },
      (error) => {
        addError(error.message, 3000);
        console.error(error);
      }
    );
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Change Password"
          subtitle=""
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      {/* <Text
        style={{
          alignSelf: "center",
          fontSize: 30,
          marginTop: 50,
          fontWeight: "bold",
        }}
      >
        Change Password
      </Text> */}
      <View style={{ paddingHorizontal: 20, flex: 4 }}>
        <View
          style={[
            styles.textContainer,
            {
              borderColor: focusBorderColor.currentPassword,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              position: "relative",
              height: 100,
            },
          ]}
        >
          <Text style={styles.titleText}>Current Password</Text>
          <TextInput
            secureTextEntry={true}
            placeholder={"Enter your current password"}
            placeholderTextColor="#9C9C9C"
            multiline={false}
            style={[
              styles.textInput,
              { backgroundColor: "#FFFFFF", padding: 7 },
            ]}
            onFocus={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                currentPassword: "#FFD500",
              });
            }}
            onBlur={() => checkPassword()}
            onChangeText={(e) => setCurrentPassword(e)}
            value={`${currentPassword}`}
          ></TextInput>
        </View>
        <View
          style={[
            styles.textContainer,
            {
              borderColor: focusBorderColor.newPassword,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              position: "relative",
              height: 100,
            },
          ]}
        >
          <Text style={styles.titleText}>New Password</Text>
          <TextInput
            secureTextEntry={true}
            placeholder={"Enter new password"}
            placeholderTextColor="#9C9C9C"
            multiline={false}
            style={[
              styles.textInput,
              { backgroundColor: "#FFFFFF", padding: 7 },
            ]}
            onFocus={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                newPassword: "#FFD500",
              });
            }}
            onBlur={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                newPassword: "#41B000",
              });
            }}
            onChangeText={(e) => setNewPassword(e)}
            value={`${newPassword}`}
            disabled={!match}
          ></TextInput>
        </View>
        <View
          style={[
            styles.textContainer,
            {
              borderColor: focusBorderColor.confirmPassword,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              position: "relative",
              height: 100,
            },
          ]}
        >
          <Text style={styles.titleText}>Confirm Password</Text>
          <TextInput
            secureTextEntry={true}
            placeholder={"Confirm the password again"}
            placeholderTextColor="#9C9C9C"
            multiline={false}
            style={[
              styles.textInput,
              { backgroundColor: "#FFFFFF", padding: 7 },
            ]}
            onFocus={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                confirmPassword: "#FFD500",
              });
            }}
            onChangeText={(e) => {
              setFocusBorderColor({
                ...focusBorderColor,
                confirmPassword: e === newPassword ? "#41B000" : "#F33A3A",
              });
              setConfirmPassword(e);
            }}
            value={`${confirmPassword}`}
            disabled={!match}
          ></TextInput>
        </View>
        <RoundButton
          pressFunc={() => updatePassword()}
          style={{
            marginTop: 50,
            alignSelf: "center",
            height: "100%",
            width: "70%",
          }}
          title="Update Password"
        ></RoundButton>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps, { addError, addInfo })(changePassword);
const styles = StyleSheet.create({
  titleText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#000",
  },
  textContainer: {
    minHeight: 80,
    maxHeight: 120,
    padding: 15,
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: 15,
  },
  textInput: {
    fontSize: adjust(13),
    height: 40,
    marginTop: 5,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
});
