import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import EditYourProfile from "../../screens/editYourProfile";
import ChangePassword from "../../screens/changePassword";
import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import AccountantDashboard from "../../screens/accountant/accountantDashboard";
import AccountSalesTeamScreen from "../../screens/accountant/accountantSalesTeamScreen";
import SalesEarningScreen from "../../screens/accountant/salesEarningScreen";
import SalesFinanceScreen from "../../screens/accountant/salesFinanceScreen";
import CreatorTeamScreen from "../../screens/accountant/creatorTeam";
import CreatorEarningScreen from "../../screens/accountant/creatorEarningScreen";
import CreatorFinanceScreen from "../../screens/accountant/creatorFinanceScreen";
import AccountantInflowceScreen from "../../screens/accountant/accountantInflowScreen";
import chatWithUs from "../../screens/common/chat-with-us";

const window = Dimensions.get("window");

const AccountantStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default () => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="accountant"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <AccountantStack.Navigator name="clientNew" initialRouteName={"dashboard"} screenOptions={{headerShown: false}}>
      <AccountantStack.Screen
        name="dashboard"
        component={AccountantDashboard}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="sales-team"
        component={AccountSalesTeamScreen}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="sales-earning"
        component={SalesEarningScreen}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="sales-finance"
        component={SalesFinanceScreen}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="creators-list"
        component={CreatorTeamScreen}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="creator-earning"
        component={CreatorEarningScreen}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="creator-finance"
        component={CreatorFinanceScreen}
        options={{ headerShown: false }}
      />

      <AccountantStack.Screen
        name="AccountantInflowceScreen"
        component={AccountantInflowceScreen}
        options={{ headerShown: false }}
      />

      <AccountantStack.Screen
        name="editYourProfile"
        component={EditYourProfile}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="changePassword"
        component={ChangePassword}
        options={{ headerShown: false }}
      />
      <AccountantStack.Screen
        name="chat-with-us"
        component={chatWithUs}
        options={{ headerShown: false }}
      />
    </AccountantStack.Navigator>
  );
};
