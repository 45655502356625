import { addError, addInfo, addWarning } from "../actions/toast.action";
const initialState = [];
const requirements = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_REQUIREMENTS": {
      return [];
    }
    case "FETCH_ALL_PROJECTS_SUCCESS": {
      return action.payload.data.flatMap((project) =>
        project.requiredTeam
          ? project.requiredTeam.members.map((member) => ({
              ...project,
              requiredTeam: member,
            }))
          : []
      );
    }
    case "PLACE_QUOTATION_SUCCESS": {
      let req = state.find(
        ({ requiredTeam }) => requiredTeam._id == action.payload.data._id
      );
      return req
        ? state.map((r) => {
            if (r.requiredTeam._id == req.requiredTeam._id)
              return { ...r, requiredTeam: action.payload.data };
            return r;
          })
        : state;
    }
    case "PLACE_QUOTATION_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }
    case "SET_REQUIREMENTS": {
      return action.payload.data;
    }
    case "LOGOUT": {
      return [];
    }
    default: {
      return state;
    }
  }
};
export default requirements;
