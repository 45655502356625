import * as React from "react";
import { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import adjust from "./adjust";
import RoundButton from "./roundButton";
const ClientPaymentComponent = ({
  disableButton,
  style,
  amount,
  phaseDesc,
}) => {
  return (
    <View style={[styles.container, style]}>
      <View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              fontSize: adjust(15),
              fontWeight: "bold",
              color: "#828282",
              padding: 10,
              marginLeft: 10,
            }}
          >
            {phaseDesc}
          </Text>

          <Text style={{ marginRight: 10, padding: 10 }}>{}</Text>
        </View>
        <View style={{ flex: 2, marginLeft: 10, padding: 10 }}>
          <Text
            style={{
              fontSize: adjust(40),
              fontWeight: "bold",
              color: "#FFD500",
            }}
          >
            ₹
            {amount.payment?.amount}
          </Text>
          <Text style={{ marginBottom: 10 }}>
            {amount.type === "Final"
              ? "Full Amount" +
                (amount.payment?.updatedDate
                  ? " Paid on "
                  : " Yet to be raised")
              : amount.type === "Final"
              ? "Advance" +
                (amount.payment?.updatedDate
                  ? " Paid on "
                  : " Yet to be raised")
              : amount.payment?.updatedDate
              ? " Paid on "
              : " Yet to be raised"}
            {amount.payment?.updatedDate}
          </Text>
        </View>
        {amount.type === "Final" && amount.payment?.updatedDate ? (
          <RoundButton
            title="Close Project"
            style={{
              alignSelf: "center",
              flex: 1,
              backgroundColor: "#000000",
              width: "95%",
              marginBottom: 10,
            }}
          ></RoundButton>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    marginBottom: 10,
  },
});
export default ClientPaymentComponent;
