import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Dimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { connect } from "react-redux";

import Discussions from "../../screens/common/inbox_screens/discussions";
import chatWithUs from "../../screens/common/chat-with-us";

const window = Dimensions.get("window");
const AccountantStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const StackNavigatorComponent = ({ user }) => {

  if (!user) {
    return <></>;
  } else {
    return (
      <AccountantStack.Navigator
        name="creatorInbox"
        initialRouteName={"discussions"}
      >
        <AccountantStack.Screen
          name="discussions"
          component={Discussions}
          options={{ headerShown: false }}
        />
        <AccountantStack.Screen
          name="chat-with-us"
          component={chatWithUs}
          options={{ headerShown: false }}
        />
      </AccountantStack.Navigator>
    );
  }
};

const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps, {})(StackNavigatorComponent);
