import React, { useState, useEffect, useReducer } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Platform,
  BackHandler,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import Header from "../../components/header";
import adjust from "../../components/adjust";
import ProjectTextInput from "../partner/setupProjectComponents/initiateProject/projectTextInput";
import RoundButton from "../../components/roundButton";
import { signup } from "../../redux/actions/user.action";
import { useFocusEffect } from "@react-navigation/native";
import TextInputWithTitle from "../../components/TextInputWithTitle/TextInputWithTitle";
import { connect } from "react-redux";
import { createProject } from "../../redux/actions/project.action";
import SelectIndividualService from "../newProjectCreate/selectIndividualService";
import SelectPartner from "../newProjectCreate/selectPartner";
import Budget from "../newProjectCreate/budget";
import { fetchProjectAudience } from "../../redux/actions/projectAudience.action";
import { fetchNeeds } from "../../redux/actions/need.action";
import NewProjectModal from "../../components/popUp/newProjectModal";
import CreatorSignupInput from "../../components/creator-signup-input";

const reducerForTimeline = (state, action) => {
  switch (action.type) {
    case "next":
      return state.map((x, i) =>
        i === action.value
          ? { ...x, complete: true, current: false }
          : action.value + 1 === i
          ? { ...x, current: true }
          : { ...x, current: false }
      );
    case "back":
      let temp = state.findIndex((x) => x.current);
      return state.map((x, i) =>
        i === temp - 1 ? { ...x, current: true } : { ...x, current: false }
      );
    default:
      return state;
  }
};

const reducerForButtonActive = (state, action) => {
  switch (action.type) {
    case "nextTab":
      return true;
    case "backTab":
      return false;
    default:
      return state;
  }
};

const ProjectBrief = ({
  navigation,
  selectedProject,
  signup,
  fetchNeeds,
  fetchProjectAudience,
  need,
  projectAudience,
  createProject,
}) => {
  let windowWidth =
    Platform.OS === "web"
      ? Dimensions.get("screen").width * 0.6
      : Dimensions.get("window").width;
  const [statuses, dispatch] = useReducer(reducerForTimeline, [
    { status: "One", complete: false, current: true },
    { status: "Two", complete: false, current: false },
    { status: "Three", complete: false, current: false },
    { status: "Four", complete: false, current: false },
    { status: "Five", complete: false, current: false },
    { status: "Six", complete: false, current: false },
    { status: "Seven", complete: false, current: false },
  ]);

  let initialState = {
    title: "",
    budget: [],
    about: "",
    audience: "",
    requirement: null,
    partner: "",
  };
  const [projectObject, setProjectObject] = useState(initialState);
  const [partner, setSelectedPartner] = useState(null);
  const [validateNow, setValidateNow] = useState(false);
  const [buttonName, setButtonName] = useState("Next >");
  const [selected, setSelected] = useState(null);
  const [budget, setBudget] = useState([]);
  const [buttonDisabled, dispatchButtonDisabled] = useReducer(
    reducerForButtonActive,
    true
  );
  const setDeatilsToProjectObject = (data) => {
    setProjectObject({ ...projectObject, ...data });
  };
  const [form, setForm] = useState({ email: false, phone: false });
  const [requirements, setRequirements] = useState([]);
  const addRequirement = (n) => {
    setRequirements([...n]);
    setDeatilsToProjectObject({ requirement: n });
  };
  const [projectModal, setProjectModal] = useState(false);
  useFocusEffect(
    React.useCallback(() => {
      let currentDataIndex = statuses.findIndex((x) => x.current);
      if (currentDataIndex === 0 && selected) {
        dispatchButtonDisabled({
          type: "backTab",
        });
      } else if (currentDataIndex === 1 && partner) {
        dispatchButtonDisabled({
          type: "backTab",
        });
      } else if (currentDataIndex === 2 && projectObject.title) {
        dispatchButtonDisabled({
          type: "backTab",
        });
      } else if (currentDataIndex === 3 && projectObject.budget) {
        dispatchButtonDisabled({
          type: "backTab",
        });
      } else if (currentDataIndex === 4 && projectObject.about) {
        dispatchButtonDisabled({
          type: "backTab",
        });
      } else if (
        currentDataIndex === 5 &&
        projectObject?.requirement?.length > 0
      ) {
        dispatchButtonDisabled({
          type: "backTab",
        });
      } else if (currentDataIndex === 6 && projectObject.audience) {
        dispatchButtonDisabled({
          type: "backTab",
        });
      } else {
        setValidateNow(true);
        dispatchButtonDisabled({
          type: "nextTab",
        });
      }
    }, [projectObject, selected, partner, statuses])
  );
  const createProjectAndNavigate = () => {
    setButtonName("Next >");
    // createProject(projectObject);
    setProjectModal(true);
    // navigation.navigate("dashboard");
  };
  let projectData = {
    ...projectObject,
    otp: Math.floor(100000 + Math.random() * 900000),
  };
  const nextClick = () => {
    let temp = statuses.findIndex((x) => x.current);
    dispatch({
      type: "next",
      value: temp,
    });

    if (temp === 6) {
      createProjectAndNavigate();
    } else if (temp === 1 && partner) {
      setDeatilsToProjectObject({
        partner: partner?.name === "Let Onedesign Decide" ? null : partner?._id,
      });
    }
  };
  const back = () => {
    navigation.goBack();
  };

  useFocusEffect(
    React.useCallback(() => {
      fetchNeeds();
      fetchProjectAudience();
      if (statuses && statuses.length === 0) {
        back();
      }
    }, [statuses])
  );

  useEffect(() => {
    let temp = statuses.findIndex((x) => x.current);
    if (temp == 4) {
      dispatchButtonDisabled({
        type: "backTab",
      });
    } else if (temp == 5) {
      dispatchButtonDisabled({
        type: "backTab",
      });
    } else if (temp == 6) {
      setButtonName("Create Project");
    } else {
    }
    const backAction = () => {
      setButtonName("Next >");
      let temp = statuses.findIndex((x) => x.current);
      dispatchButtonDisabled({
        type: "nextTab",
      });
      if (temp === 0) {
        back();
      } else {
        dispatch({
          type: "back",
          value: temp,
        });
        dispatchButtonDisabled({
          type: "backTab",
        });
      }
      return true;
    };
    BackHandler.addEventListener("hardwareBackPress", backAction);
    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction);
  }, [statuses]);
  const timeline = () => {
    return (
      <View>
        <View style={styles.timelineChildCont}>
          <View style={styles.timeLineContainer}>
            {statuses.map((item, index) => (
              <View
                style={styles.timeLineEachContainer}
                key={`status-${index}`}
              >
                <TouchableOpacity
                  onPress={() => {}}
                  style={[
                    styles.buttonDesign,
                    {
                      backgroundColor:
                        statuses.findIndex((x) => x.current) >= index
                          ? "#000000"
                          : "#D0D0D0",
                    },
                  ]}
                ></TouchableOpacity>
                {index !== statuses.length - 1 ? (
                  <View
                    style={{
                      borderWidth: 1,
                      maxHeight: 5,
                      width:
                        (windowWidth - statuses.length * 15 - 40) /
                        (statuses.length - 1),
                      borderColor:
                        statuses.findIndex((x) => x.current) >= index
                          ? "#000000"
                          : "#D0D0D0",
                    }}
                  ></View>
                ) : (
                  <></>
                )}
              </View>
            ))}
          </View>
        </View>
      </View>
    );
  };
  const Content = () => {
    let item = statuses.find((x) => x.current);
    if (item)
      if (item.status === "One") {
        return (
          <>
            <SelectIndividualService
              setSelectedProject={(e) => setSelected(e)}
            ></SelectIndividualService>
          </>
        );
      } else if (item.status === "Two") {
        return (
          <SelectPartner
            setPartner={(e) => setSelectedPartner(e)}
          ></SelectPartner>
        );
      } else if (item.status === "Three") {
        return (
          <View style={{ margin: 10, marginTop: "30%" }}>
            <TextInputWithTitle
              title="Name this project"
              placeholder="Example: Logo Design for Delhi based Bakery shop"
              onDataChange={(e) => setDeatilsToProjectObject({ title: e })}
              data={projectObject.title}
              required={true}
              textType={true}
              inValidText="Project Name cannot be blank"
              validateNow={validateNow}
              // isValid={(v) => {
              //   setTitleValid(v);
              // }}
              // value={title}
            ></TextInputWithTitle>
          </View>
        );
      } else if (item.status === "Four") {
        return (
          <View style={{ margin: 20, marginTop: "30%" }}>
            <CreatorSignupInput
              title="Tell us your budget:"
              onDataChange={(e) =>
                setDeatilsToProjectObject({
                  budget: [e.minValue, e.maxValue],
                })
              }
              required={true}
              data={budget}
              textType={false}
              selectionValue={budget}
              onSelectionChange={(e) =>
                setDeatilsToProjectObject({
                  budget: [e.minValue, e.maxValue],
                })
              }
              inValidText="Project budget cannot be blank"
              validateNow={validateNow}
              placeholder="Tap to select"
              renderData={[
                { name: "Under 01 lakh", minValue: 0, maxValue: 100000 },
                {
                  name: "01 lakhs - 02 lakhs",
                  minValue: 100000,
                  maxValue: 200000,
                },
                {
                  name: "02 lakhs - 05 lakhs",
                  minValue: 200000,
                  maxValue: 500000,
                },
                {
                  name: "05 lakhs - 10 lakhs",
                  minValue: 500000,
                  maxValue: 1000000,
                },
                {
                  name: "Above 10 lakhs",
                  minValue: 1000000,
                  maxValue: 1500000,
                },
              ]}
            ></CreatorSignupInput>
            {/* <TextInputWithTitle
              keyboardType={"numeric"}
              title="Tell us your budget:"
              placeholder="30,000"
              onDataChange={(e) => setDeatilsToProjectObject({ budget: e })}
              required={true}
              data={projectObject.budget}
              textType={true}
              inValidText="Project Description cannot be blank"
              validateNow={validateNow}
              // isValid={(v) => {
              //   setAboutValid(v);
              // }}
            ></TextInputWithTitle> */}
          </View>
        );
      } else if (item.status === "Five") {
        return (
          <View style={{ margin: 10, marginTop: "30%" }}>
            <TextInputWithTitle
              title="Tell us something about the Project:"
              placeholder="Tell us what you want to achieve"
              onDataChange={(e) => setDeatilsToProjectObject({ about: e })}
              required={true}
              data={projectObject.about}
              textType={true}
              inValidText="Project Description cannot be blank"
              validateNow={validateNow}
              // isValid={(v) => {
              //   setAboutValid(v);
              // }}
              // value={about}
            ></TextInputWithTitle>
          </View>
        );
      } else if (item.status === "Six") {
        return (
          <View style={{ margin: 10, marginTop: "30%" }}>
            <ProjectTextInput
              removeBorder={true}
              style={{
                marginLeft: 30,
              }}
              title="Tell us all that you think you need:"
              placeholder="Tap to select requirements"
              onSelection={(e) => addRequirement(e)}
              required={true}
              renderData={need}
              selectionValue={requirements}
              inValidText="Project Description cannot be blank"
              validateNow={validateNow}
              multiSelect={true}
              isValid={() => {}}
            ></ProjectTextInput>
          </View>
        );
      } else if (item.status === "Seven") {
        return (
          <View style={{ margin: 10, marginTop: "30%" }}>
            <ProjectTextInput
              removeBorder={true}
              title="Who it is for?"
              required={true}
              // data={projectObject.audience}
              textType={false}
              selectionValue={projectObject.audience}
              onSelectionChange={(e) =>
                setProjectObject({ ...projectObject, audience: e })
              }
              inValidText="Project Description cannot be blank"
              validateNow={validateNow}
              placeholder="Tap to select audience"
              renderData={projectAudience}
            ></ProjectTextInput>
          </View>
        );
      } else {
        <></>;
      }
  };
  const buttonView = () => (
    <RoundButton
      pressFunc={() => nextClick()}
      title={buttonName}
      style={styles.nextPhoto}
      disabled={buttonDisabled}
    ></RoundButton>
  );

  return (
    <View style={{ flex: 1, backgroundColor: "#F0F0F0" }}>
      <View
        style={{
          flex: 1,
          maxHeight: 200,
          backgroundColor: "#FFF",
        }}
      >
        <View
          style={{
            minHeight: 150,
            maxHeight: 150,
          }}
        >
          <Header
            title="Create Project"
            enableAvatar={false}
            backFunction={() => navigation.goBack()}
            navigation={navigation}
            disableProfile={true}
            style={{ backgroundColor: "#FFF" }}
          ></Header>
        </View>

        {timeline()}
      </View>
      <ScrollView style={styles.scrollDesign} nestedScrollEnabled={true}>
        {Content()}
      </ScrollView>

      <View>{buttonView()}</View>
      <NewProjectModal
        pressFunc={() => {
          setProjectModal(false);
          navigation.navigate("projectInitiation", { data: projectData });
        }}
        projectSuccess={true}
        showModal={projectModal}
      ></NewProjectModal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  headerHeight: {
    height: 150,
  },
  circle: {
    width: 150,
    height: 150,
    borderRadius: 150 / 2,
    backgroundColor: "#B4B4B4",
    marginLeft: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollDesign: {
    flex: 1,
    height: "100%",
    marginTop: 30,
  },
  title: {
    fontWeight: "bold",
    fontSize: adjust(25),
    color: "#000",
    flexWrap: "wrap",
  },
  bottomContainer: {
    justifyContent: "center",
    alignItems: "center",
    bottom: 45,
    backgroundColor: "#FAFAFA",
  },
  timeLineContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  timeLineEachContainer: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 50,
  },
  subtitle: {
    color: "#606060",
    fontSize: adjust(13),
    marginLeft: 30,
  },
  timelineChildCont: {
    // backgroundColor: "#FFF",
    maxHeight: 100,
    paddingHorizontal: 30,
    marginBottom: 20,
    height: 20,
  },
  buttonDesign: {
    borderRadius: 7.5,
    height: 15,
    width: 15,
  },
  bottomStyle: {
    backgroundColor: "#000000",
    height: "100%",
    width: 307,
  },
  roundContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainer: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  marginView: {
    marginTop: 20,
  },
  nameText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    flex: 1,
  },
  designationText: {
    fontSize: adjust(13),
    color: "#202020",
    flex: 1,
  },
  briefText: {
    fontSize: adjust(15),
    color: "#707070",
    flex: 1,
    lineHeight: 18,
  },
  readThisHeader: {
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#818181",
    marginBottom: 5,
  },
  readThisText: {
    fontSize: adjust(15),
    color: "#606060",
    flex: 12,
    lineHeight: 18,
  },
  contactText: {
    fontSize: adjust(20),
    fontWeight: "bold",
    flex: 2,
  },
  budget: {
    fontSize: adjust(18),
    fontWeight: "bold",
  },
  aboutText: {
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#707070",
    marginBottom: 15,
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: 20,
  },
  modalView: {
    width: "100%",
    padding: 10,
    maxWidth: 400,
    maxHeight: 400,
    minHeight: 400,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
  },
  data: {
    fontSize: adjust(15),
    color: "#606060",
    marginBottom: 10,
  },
  briefHeader: {
    fontSize: adjust(18),
    paddingBottom: 5,
    color: "#707070",
    fontWeight: "bold",
  },
  circle: {
    width: 170,
    height: 170,
    borderRadius: 100,
    backgroundColor: "#B4B4B4",
    marginTop: 10,
    marginLeft: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  nextPhoto: {
    backgroundColor: "#000",
    maxWidth: 400,
    width: "80%",
    marginLeft: 30,
    height: 70,
    marginVertical: 5,
  },
  modalContentStyle: {
    width: 250,
    backgroundColor: "#fff",
    justifyContent: "center",
    borderRadius: 15,
  },
  imagePickerButtonStyle: {
    backgroundColor: "#000",
    maxWidth: 400,
    width: "90%",
    height: 70,
    alignSelf: "center",
  },
});
const mapStateToProps = ({
  selectedProject,
  creatorList,
  employees,
  need,
  projectAudience,
}) => ({
  selectedProject,
  creatorList,
  employees,
  need,
  projectAudience,
});

export default connect(mapStateToProps, {
  signup,
  fetchNeeds,
  fetchProjectAudience,
  createProject,
})(ProjectBrief);
