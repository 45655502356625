const initialState = [];
const pendingProjects = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PENDING_PROJECTS_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_PENDING_PROJECTS_FAIL": {
      return state;
    }
    case "START_PROJECT_SUCCESS": {
      return [...state, action.payload.data];
    }
    case "GIVEUP_PROJECT_SUCCESS": {
      return state.filter((p) => p._id != action.payload.data._id);
    }
    case "GIVEUP_PROJECT_FAIL": {
      return state;
    }

    case "CREATE_PROJECT_PROJECT_SUCCESS":
    case "CREATE_PROJECT_SUCCESS": {
      state = state.length > 0 ? state : [];
      let project = state.find(
        (project) => project._id == action.payload.data._id
      );
      return project
        ? state.map((p) => (p._id == project._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }
    case "LOGOUT": {
      return [];
    }
    default: {
      return state;
    }
  }
};
export default pendingProjects;
