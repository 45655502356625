export const fetchMedias = () => (dispatch) => {
  return dispatch({
    type: "FETCH_MEDIA",
    payload: {
      request: {
        url: `/api/media`,
        method: "GET",
      },
    },
  });
};

export const createMedia = (obj) => (dispatch) => {
  return dispatch({
    type: "CREATE_MEDIA",
    payload: {
      request: {
        url: `/api/media`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const removeMedia = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_MEDIA",
    payload: {
      request: {
        url: `/api/media/${id}`,
        method: "DELETE",
      },
    },
  });
};
