import * as React from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import adjust from "./adjust";

const MyPaymentsCard = ({ payment, user }) => {
  const getDate = (id) => {
    return new Date(payment.updatedDate).toLocaleString();
  };
  return (
    <View
      style={{
        padding: 10,
        minHeight: 100,
        backgroundColor: "white",
        marginBottom: 15,
        borderRadius: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 5,
        }}
      >
        <Text style={[styles.title, { width: "70%" }]}>
          {payment?.type === "Bank"
            ? "To " + payment.type + " Account"
            : payment?.project?.title}
        </Text>
        <Text style={styles.title}>
          {user._id === payment.receivable._id && payment.type !== "Bank"
            ? "+"
            : "-"}
          {(payment?.receivedAmount || payment?.amount)
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: 5,
          paddingBottom: 10,
          paddingRight: 5,
        }}
      >
        <Text style={styles.textClass}>{getDate(payment?._id)}</Text>
        <Text ststyle={styles.textClass}>rupees</Text>
      </View>

      <View
        style={{
          paddingTop: 10,
          paddingLeft: 5,
          borderTopWidth: 1,
          borderTopColor: "gray",
        }}
      >
        <Text style={styles.textClass}>From : {payment?.payable?.name}</Text>
        <Text style={styles.textClass}>To : {payment?.receivable?.name}</Text>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  title: {
    fontSize: adjust(20),
    fontWeight: "bold",
  },
});
export default MyPaymentsCard;
