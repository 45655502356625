import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import SalesInit from "../../screens/Client/new_sales_setup/sales-init";
import BasicDetails from "../../screens/Client/new_sales_setup/basic-details";
import ServiceSelection from "../../screens/Client/new_sales_setup/service-selection";
import PartnerSelection from "../../screens/Client/new_sales_setup/partner-selection";
import FinalStep from "../../screens/Client/new_sales_setup/final-step";
import ScheduleMeeting from "../../screens/common/meeting/scheduleMeeting";
import ScheduleDetails from "../../screens/common/meeting/scheduleDetails";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Dashboard from "../../screens/Sales/dashboard";
import editYourProfile from "../../screens/editYourProfile";
import ChatWithUs from "../../screens/common/chat-with-us";

const window = Dimensions.get("window");

const SalesStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({ user }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="sales"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <SalesStack.Navigator name="sales" initialRouteName={"init"} screenOptions={{headerShown: false}}>
      <SalesStack.Screen
        name="init"
        component={SalesInit}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="basic-details"
        component={BasicDetails}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="service-selection"
        component={ServiceSelection}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="partner-selection"
        component={PartnerSelection}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="final-step"
        component={FinalStep}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="scheduleMeeting"
        component={ScheduleMeeting}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="scheduleDetails"
        component={ScheduleDetails}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="Dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="editYourProfile"
        component={editYourProfile}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
    </SalesStack.Navigator>
  );
};
