import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import LargeTextInput from "../largeTextInput";

const UserBasicDetailsFields = ({ onChangeField, user }) => {
  const [valid, setValid] = useState(true);
  const validate = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      onChangeField({ email: text });
      setValid(false);
    } else {
      setValid(true);
      onChangeField({ email: text });
    }
  };
  return (
    <View>
      <Text style={styles.headerStyle}>
        you need to enter some basic details here
      </Text>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          value={user?.fName}
          placeholder="enter your first name"
          style={{ marginTop: 50, borderBottomColor: "#707070" }}
          onChangeText={(data) => onChangeField({ fName: data })}
        ></LargeTextInput>
      </View>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          value={user?.lName}
          placeholder="enter your last name"
          style={{ borderBottomColor: "#707070" }}
          onChangeText={(data) => onChangeField({ lName: data })}
        ></LargeTextInput>
      </View>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          value={user?.email}
          keyboardType={"email-address"}
          placeholder="enter your email-id"
          style={{ borderBottomColor: "#707070" }}
          onChangeText={(data) => validate(data)}
        ></LargeTextInput>
        {valid ? (
          <></>
        ) : (
          <Text style={styles.textStyle}>Please enter a valid email</Text>
        )}
      </View>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          value={user?.phoneNo}
          keyboardType={"phone-pad"}
          placeholder="enter your phone number"
          style={{ borderBottomColor: "#707070" }}
          onChangeText={(data) => onChangeField({ phoneNo: data })}
        ></LargeTextInput>
      </View>
    </View>
  );
};

export default UserBasicDetailsFields;

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    margin: 25,
    color: "#000",
  },
  wrapperStyle: {
    paddingTop: 70,
  },
  textStyle: {
    color: "#FF0000",
    marginLeft: 30,
    top: 10,
  },
});
