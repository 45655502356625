import { StyleSheet, Text, View, Pressable } from "react-native";
import React from "react";

import Header from "../../../components/header";
import SignUpEndPhase from "../../../components/SignUpComponents/signUpEndPhase";
import { updateProfile } from "../../../redux/actions/user.action";
import { connect } from "react-redux";
import { primaryColor } from "../../../components/utils";

const FinalStep = ({ navigation, route, updateProfile, user }) => {
  //SUVENDU Please change the text color accoring to route.params.stack
  const next = () => {
    updateProfile(
      { email: user.email, signupComplete: true },
      navigateAfterSuccess
    );
  };

  const navigateAfterSuccess = () => {
    navigation.navigate("dashboard");
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#FFF" }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#FF973E" }}
        ></Header>
      </View>
      <SignUpEndPhase textcolor={primaryColor(user.userType)} />
      <Pressable style={[styles.pressableStyle]} onPress={() => next()}>
        <Text
          style={[
            {
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: "bold",
              alignSelf: "center",
              justifyContent: "center",
            },
          ]}
        >
          {"Start"}
        </Text>
      </Pressable>
    </View>
  );
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { updateProfile })(FinalStep);

const styles = StyleSheet.create({
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
