import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";
import Icon from "../../../components/icon";

const CreativeCardComponent = ({
  header,
  description,
  image,
  imageUri,
  onPressItem,
  onPressDelete,
}) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPressItem} style={[styles.card_container]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View
            style={{
              flexDirection: image ? "row" : "column",
              justifyContent: image ? "space-between" : null,
              width: "90%",
            }}
          >
            <Text
              style={[
                styles.text_style,
                { alignSelf: image ? "center" : null },
              ]}
            >
              {header}
            </Text>
            {image ? (
              <Image
                style={{
                  alignSelf: "center",
                  height: 40,
                  width: 40,
                  borderRadius: 50,
                }}
                source={{ uri: imageUri }}
              ></Image>
            ) : (
              <Text style={{ marginLeft: 10, fontSize: 11 }}>
                {description}
              </Text>
            )}
          </View>

          <Pressable onPress={onPressDelete}>
            <Icon name={"delete"} />
          </Pressable>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default CreativeCardComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card_container: {
    width: "100%",
    marginTop: 10,
    padding: 10,
    // alignItems: "center",
    justifyContent: "flex-start",
    elevation: 1,
    borderWidth: 1,
    borderColor: "#efefef",
    borderRadius: 10,
    backgroundColor: "white",
  },
  text_style: {
    fontSize: 16,
    marginLeft: 10,
    color: "#000000",
    fontWeight: "bold",
  },
});
