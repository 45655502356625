import * as React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import adjust from "../../components/adjust";
import Icon from "../../components/icon";
import RoundButton from "../../components/roundButton";
const ProjectSuccess = ({ navigation, selectedProject }) => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon
        name="tick"
        width={60}
        height={60}
        style={{ height: 184, width: 184 }}
      ></Icon>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          maxHeight: 70,
          marginTop: 20,
        }}
      >
        <Text
          style={{
            flex: 0.5,
            fontWeight: "bold",
            fontSize: adjust(28),
          }}
        >
          Project added to
        </Text>
        <Text
          style={{
            flex: 0.5,
            fontWeight: "bold",
            fontSize: adjust(28),
          }}
        >
          Dashboard
        </Text>
      </View>
      <RoundButton
        title="Continue"
        style={{
          backgroundColor: "#000000",
          marginTop: 20,
          height: "100%",
          width: "40%",
        }}
        pressFunc={() => navigation.navigate("dashboard")}
      ></RoundButton>
    </View>
  );
};
const mapStateToProps = ({ selectedProject }) => ({
  selectedProject,
});
export default connect(mapStateToProps, {})(ProjectSuccess);

const styles = StyleSheet.create({});
