import React, { useState } from "react";
import {
  Text,
  TextInput,
  TouchableOpacity,
  View,
  TouchableWithoutFeedback,
} from "react-native";
import Modal from "./modal/modal";
import { StyleSheet } from "react-native";
import Icon from "./icon";
import GestureRecognizer from "react-native-swipe-gestures";
import Avatar from "./avatar";
import { connect } from "react-redux";
import { giveUpProject } from "../redux/actions/project.action";
import adjust from "./adjust";

function GiveUpProjectModal({
  showModal,
  onCloseModal,
  partners,
  giveUpProject,
  selectedProject,
  navigation,
}) {
  const [giveUpobj, setGiveUpObj] = useState({
    message: "",
    letOnedesignDecide: false,
    project: selectedProject._id,
    newPartner: null,
  });

  return (
    <Modal
      animationType="fade"
      transparent={true}
      presentationStyle="overFullScreen"
      visible={showModal}
      isVisible={showModal}
      ariaHideApp={false}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                width: "100%",
                flexDirection: "row",
              }}
            >
              <Icon name="box3D"></Icon>
              <Text
                style={[{ flex: 1, marginLeft: 5 }, styles.projectBriefText]}
              >
                Give Up Project
              </Text>
              <TouchableOpacity
                style={{ flex: 1, maxWidth: 20 }}
                onPress={() => onCloseModal(false)}
              >
                <Text
                  style={{
                    maxWidth: 20,
                    fontSize: adjust(20),
                    position: "absolute",
                    right: 10,
                    color: "#A9A9A9",
                  }}
                >
                  X
                </Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.data}>
              It's not good to give up projects. But just so you have decided to
              give up, please let us know what made you give up this project.
            </Text>
            <TextInput
              placeholder="Type Something"
              placeholderTextColor="#BDBDBD"
              style={{
                backgroundColor: "#F7F7F7",
                fontSize: adjust(13),
                padding: 10,
                borderRadius: 8,
                minHeight: 100,
                marginTop: 10,
              }}
              multiline={true}
              onChangeText={(e) => setGiveUpObj({ ...giveUpobj, message: e })}
            ></TextInput>
          </View>
          <View
            style={{
              flex: 2,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <View style={{ flex: 1, width: "100%" }}>
              <Text style={[{ marginTop: 10 }, styles.projectBriefText]}>
                Transfer Project to
              </Text>
              <Text style={[styles.data]}>Swipe Right to transfer</Text>
              <GestureRecognizer
                onSwipeRight={() => {
                  setGiveUpObj({
                    ...giveUpobj,
                    letOnedesignDecide: true,
                    newPartner: null,
                  });
                }}
                onSwipeLeft={() => {
                  setGiveUpObj({
                    ...giveUpobj,
                    letOnedesignDecide: false,
                    newPartner: null,
                  });
                }}
                style={{ height: 80, marginBottom: 10 }}
              >
                <TouchableWithoutFeedback>
                  <View
                    style={{
                      flex: 1,
                      padding: 10,
                      maxHeight: 60,
                      minHeight: 60,
                      borderRadius: 8,
                      marginTop: 10,
                      marginBottom: 5,
                      justifyContent: "center",
                      backgroundColor: giveUpobj.letOnedesignDecide
                        ? "#FFD500"
                        : "#000000",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: adjust(16),
                        fontWeight: "bold",
                        color: "#fff",
                        width: "100%",
                      }}
                    >
                      Let Onedesign Decide
                    </Text>
                  </View>
                </TouchableWithoutFeedback>
              </GestureRecognizer>
              {partners.map((t, index) => {
                return (
                  <GestureRecognizer
                    key={index}
                    onSwipeRight={() => {
                      setGiveUpObj({
                        ...giveUpobj,
                        newPartner: t._id,
                        letOnedesignDecide: false,
                      });
                    }}
                    onSwipeLeft={() => {
                      setGiveUpObj({ ...giveUpobj, newPartner: null });
                    }}
                    style={[
                      styles.userContainer,
                      {
                        backgroundColor:
                          giveUpobj.newPartner == t._id ? "#FFD500" : "#000000",
                      },
                    ]}
                  >
                    <Avatar
                      source={{
                        uri:
                          t.user?.mimType && t.user.image
                            ? `data:${t.user.mimType};base64,${t.user.image}`
                            : null,
                      }}
                      style={{
                        borderColor: "#ffffff",
                        maxHeight: 40,
                        maxWidth: 40,
                      }}
                    ></Avatar>
                    <View style={{ marginLeft: 5 }}>
                      <Text
                        style={{
                          fontSize: adjust(16),
                          fontWeight: "bold",
                          color: "#ffffff",
                          width: "100%",
                        }}
                      >
                        {t.name ? t.name : null}
                      </Text>
                      <Text
                        style={{
                          fontSize: adjust(11),
                          color: "#ffffff",
                          width: "100%",
                        }}
                      >
                        Partner {t.designation ? "|" + t.designation : null}
                      </Text>
                    </View>
                  </GestureRecognizer>
                );
              })}
            </View>
          </View>
        </View>

        <TouchableOpacity
          style={[
            styles.profileContainer,
            { alignItems: "center", width: "90%" },
          ]}
          onPress={() => {
            giveUpProject(giveUpobj);
            onCloseModal(false);
            navigation.navigate("myProjects");
          }}
        >
          <Text
            style={{
              fontSize: adjust(16),
              fontWeight: "bold",
              color: "#fff",
              width: "100%",
              textAlign: "center",
            }}
          >
            Give Up Project
          </Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}
const mapStateToProps = ({ selectedProject, user, partners }) => ({
  selectedProject,
  user,
  partners,
});
export default connect(mapStateToProps, {
  giveUpProject,
})(GiveUpProjectModal);

const styles = StyleSheet.create({
  projectBriefText: {
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#A9A9A9",
  },
  gestureContainer: {
    flex: 1,
    marginBottom: 5,
    // maxHeight: 70,
  },
  profileContainer: {
    flex: 1,
    backgroundColor: "#000",
    padding: 10,
    maxHeight: 60,
    minHeight: 60,
    borderRadius: 8,
    marginTop: 10,
    justifyContent: "center",
  },
  userContainer: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    minHeight: 60,
    padding: 10,
    marginBottom: 5,
    maxHeight: 60,
    minHeight: 60,
  },
  data: {
    // flex: 1,
    fontSize: adjust(11),
    color: "#606060",
    marginTop: 5,
  },
  label: {
    fontSize: adjust(11),
    fontWeight: "bold",
    color: "#000000",
  },
  dataContainer: {
    marginBottom: 15,
  },
  budget: {
    fontSize: adjust(18),
    marginTop: 20,
    fontWeight: "bold",
  },
  myBidText: {
    fontSize: adjust(11),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginTop: 30,
  },
  centeredView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.75)",
  },
  modalView: {
    backgroundColor: "#ffffff",
    padding: 22,
    justifyContent: "center",
    // alignItems: "center",
    borderRadius: 10,
    width: "90%",
    borderColor: "rgba(0, 0, 0, 0.1)",
    height: "80%",
    position: "relative",
    minHeight: 600,
  },
});
