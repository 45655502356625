import React from "react";

import { View, ScrollView, Dimensions } from "react-native";

const carousal = ({ components, fullScreen }) => {
  let windowWidth = Dimensions.get("screen").width;
  return (
    <View
      style={{
        width: windowWidth,
        maxWidth: "100%",
        // paddingRight: 10,
      }}
    >
      <ScrollView
        horizontal={true}
        contentContainerStyle={
          {
            // width: `${80 * components.length}%`,
          }
        }
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={200}
        decelerationRate="fast"
        pagingEnabled
      >
        {/* <View
    style={{ flex: 1, flexDirection: "row", flexWrap: "nowrap", width: "100%" }}
  > */}
        {components.map(
          (component, index) => (
            <View
              key={`slide-${index}`}
              style={{
                width: windowWidth - (fullScreen ? 0 : 40),
                height: 250,
              }}
            >
              {component}
            </View>
          )
          //</PanGestureHandler>
        )}
      </ScrollView>
    </View>
  );
};

export default carousal;
