import { ScrollView, StyleSheet, Text, View } from "react-native";
import React from "react";
import Header from "../../../components/header";
import DashboardCardWithArrowAndIcon from "../../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import LargeButton from "../../../components/largeButton";
import { logout } from "../../../redux/actions/user.action";
import { connect } from "react-redux";

const Settings = ({ navigation, logout }) => {
  const data = [
    {
      name: "Add or Remove Partners",
      description: "Tap to edit Partners List",
      navigateTo: "editUserByAdmin",
      userType: "partner",
    },
    {
      name: "Add or Remove Creators",
      description: "Tap to edit Creators List",
      navigateTo: "editUserByAdmin",
      userType: "creator",
    },
    {
      name: "Add or Remove Sales People",
      description: "Tap to edit Sales People List",
      navigateTo: "editUserByAdmin",
      userType: "sales people",
    },
    {
      name: "Add or Remove Creative Roles",
      description: "Tap to edit Creative Roles List",
      navigateTo: "addEditServicesByAdmin",
      type: "creative roles",
    },
    {
      name: "Add or Remove Services",
      description: "Tap to edit Services List",
      navigateTo: "addEditServicesByAdmin",
      type: "services",
    },
    {
      name: "Add or Remove Technical Expertise",
      description: "Tap to edit Technical Expertise",
      navigateTo: "addEditServicesByAdmin",
      type: "technical expertise",
    },
    {
      name: "Add or Remove Project Skills",
      description: "Tap to edit Project Skills",
      navigateTo: "addEditServicesByAdmin",
      type: "project skills",
    },
    {
      name: "Add or Remove welcome Message",
      description: "Tap to edit welcome message",
      navigateTo: "welcomeMessageScreen",
      userType: "welcome message",
    },
    {
      name: "Add or Remove Case Studies",
      description: "Tap to edit Case Studies",
      navigateTo: "case-studies",
      userType: "case-studies",
    },
    {
      name: "Add or Remove Expert Material",
      description: "Tap to edit Expert Material",
      navigateTo: "expert-material",
    },
  ];
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          enableAvatar={false}
          title="settings"
          subtitle="Change features around the company"
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 30 }}
        nestedScrollEnabled={true}
        style={{ height: "100%", marginHorizontal: 10 }}
      >
        {data.map((e, i) => (
          <DashboardCardWithArrowAndIcon
            onPressCard={() =>
              navigation.navigate(e.navigateTo, { data: JSON.stringify(e) })
            }
            key={`${e.name} + ${i}`}
            backgroundColor="#000"
            value=""
            icon={false}
            description={e.description}
            title={e.name}
          ></DashboardCardWithArrowAndIcon>
        ))}
        <LargeButton
          arrowColor={"#fff"}
          pressFunc={() => logout()}
          backgroundColor={"#000"}
          textColor={"#fff"}
          title={"Logout"}
        ></LargeButton>
      </ScrollView>
    </View>
  );
};

export default connect(null, { logout })(Settings);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
});
