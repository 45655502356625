import * as React from "react";
import { StyleSheet } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { connect } from "react-redux";
import TabNavigationDesign from "../tabNavigationDesign";
import PartnerStack from "./partner-stack";
import PartnerMeetingStack from "./partner-meeting-stack";
import ProjectStack from "./partner-project-stack";
import InboxStack from "./partner-inbox-stack";
import partnerProfileStack from "./partner-profile-stack";

const Tab = createBottomTabNavigator();

const PartnerTabNavigation = ({ user }) => {
  return (
    <Tab.Navigator
      tabBar={(props) => <TabNavigationDesign {...props} />}
      initialRouteName="Home"
      unmountInactiveTabs={true}
      screenOptions={{ unmountOnBlur: true, headerShown: false  }}
    >
      <Tab.Screen
        name="Home"
        component={PartnerStack}
        options={{
          icon: "home",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Project"
        component={ProjectStack}
        options={{
          icon: "project",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Meetings"
        component={PartnerMeetingStack}
        options={{
          icon: "meeting",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Inbox"
        component={InboxStack}
        options={{
          icon: "inbox",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Profile"
        component={partnerProfileStack}
        options={{
          image: true,
          icon: "profile",
          unmountOnBlur: true,
        }}
      />
    </Tab.Navigator>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const styles = StyleSheet.create({
  headerAvatar: {
    borderWidth: 1.5,
    height: 29,
    width: 29,
    marginBottom: 3,
  },
});
export default connect(mapStateToProps, {})(PartnerTabNavigation);
