import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  TextInput,
} from "react-native";
import React, { useState } from "react";
import Icon from "../../../components/icon";
import adjust from "../../../components/adjust";
import { clearSelectedProject } from "../../../redux/actions/project.action";
import { useFocusEffect } from "@react-navigation/native";
import { connect } from "react-redux";
import LargeButton from "../../../components/largeButton";
import { primaryColor } from "../../../components/utils";
import LargeTextInput from "../../../components/largeTextInput";
const SalesInit = ({ navigation, clearSelectedProject, route, jsx, user }) => {
  const window = Dimensions.get("window");
  useFocusEffect(
    React.useCallback(() => {
      if (route && !route.params?.data?.update) {
        clearSelectedProject();
      } else {
        navigation.navigate("basic-details");
      }
    }, [route])
  );

  return (
    <View style={{ backgroundColor: "#5F5F5F", height: "100%" }}>
      <View style={styles.centeredView}>
        {jsx ? (
          jsx
        ) : (
          <View style={styles.modalView}>
            <View style={{ margin: 20 }}>
              <Text
                style={{
                  fontSize: adjust(30),
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Start a new project
              </Text>
              <Text>Tap below to start a new project</Text>
            </View>
            <LargeButton
              arrowColor={"#FFF"}
              borderColor={"#000"}
              textColor={"#FFFFFF"}
              backgroundColor={"#000"}
              title="Find me a Partner"
              pressFunc={() => navigation.navigate("basic-details")}
            ></LargeButton>

            {/* <TouchableOpacity
              onPress={() => navigation.navigate("basic-details")}
              style={{
                backgroundColor: "#000",
                flexDirection: "row",
                borderRadius: 5,
                marginHorizontal: 10,
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  fontSize: adjust(30),
                  fontWeight: "bold",
                  padding: 7,
                  color: "#fff",
                }}
              >
                Find me a Partner
              </Text>
              <Icon name="rightArrow" fill={"#FFF"}></Icon>
            </TouchableOpacity> */}
            <LargeTextInput
              containerStyle={{ marginTop: 20 }}
              placeholder="enter sales person's name"
              style={{
                borderBottomColor: "#707070",
                marginHorizontal: 20,
              }}
              onChangeText={(data) => {}}
            ></LargeTextInput>
            {/* <View
              style={{
                flexDirection: "row",
                borderRadius: 5,
                marginHorizontal: 10,
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
                marginTop: 30,
              }}
            >
              <TextInput
                placeholder="enter sales person's name"
                placeholderColor="#D4D4D4"
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "#707070",
                  minWidth: window.width - 60,
                  fontSize: adjust(25),
                  fontWeight: "bold",
                }}
              ></TextInput>
            </View> */}
          </View>
        )}
      </View>
      {/* </TouchableWithoutFeedback> */}
      {/* </Modal> */}
    </View>
  );
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { clearSelectedProject })(SalesInit);

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    // height: "50%",
    // marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    // marginBottom: 60,
  },
  modalView: {
    width: "100%",
    // maxWidth: 400,
    maxHeight: 300,
    minHeight: 300,
    backgroundColor: "#FFF",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "absolute",
    bottom: 0,
  },
});
