import * as React from "react";
import { StyleSheet } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { connect } from "react-redux";
import TabNavigationDesign from "../tabNavigationDesign";
import AdminStack from "./admin-dashboard-stack";
import Avatar from "../../components/avatar";
import AdminSettingStack from "./admin-setting-stack";
import AdminProjectStack from "./admin-project-stack";
import AdminMeetingStack from "./admin-meeting-stack";
import AdminInboxStack from "./admin-inbox-stack";
const Tab = createBottomTabNavigator();

const AdminTabNavigation = ({ user }) => {
  return (
    <Tab.Navigator
      tabBar={(props) => <TabNavigationDesign {...props} />}
      initialRouteName="Home"
      unmountInactiveTabs={true}
      screenOptions={{ unmountOnBlur: true, headerShown: false }}
    >
      <Tab.Screen
        name="Home"
        component={AdminStack}
        options={{
          icon: "home",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Project"
        component={AdminProjectStack}
        options={{
          icon: "project",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Meetings"
        component={AdminMeetingStack}
        options={{
          icon: "meeting",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Inbox"
        component={AdminInboxStack}
        options={{
          icon: "inbox",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Setting"
        component={AdminSettingStack}
        options={{
          image: true,
          icon: "settings",
          unmountOnBlur: true,
        }}
      />
    </Tab.Navigator>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const styles = StyleSheet.create({
  headerAvatar: {
    borderWidth: 1.5,
    height: 29,
    width: 29,
    marginBottom: 3,
  },
});
export default connect(mapStateToProps, {})(AdminTabNavigation);
