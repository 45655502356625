import * as React from "react";
import { useState, useEffect } from "react";
import {
  FlatList,
  View,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import InputDialogBox from "../../components/dialogBox/dialog-box";
import Icon from "../../components/icon";
import {
  createMedia,
  fetchMedias,
  removeMedia,
} from "../../redux/actions/media.action";
import { logout } from "../../redux/actions/user.action";
import SettingsPopUp from "../../components/settingsPopUp";
import adjust from "../../components/adjust";
import Modal from "../../components/modal/modal";
import PopUp from "../../components/popUp/popUp";
import RoundButton from "../../components/roundButton";
import { BlurView } from "expo-blur";
import ProjectTextInput from "../partner/setupProjectComponents/initiateProject/projectTextInput";
import Checkbox from "../../components/checkbox";
import { addError } from "../../redux/actions/toast.action";
const CreateMedia = ({
  navigation,
  createMedia,
  fetchMedias,
  medias,
  logout,
  user,
  removeMedia,
}) => {
  const renderData = [
    { key: "My Profile", route: () => navigation.navigate("editYourProfile") },
    { key: "Logout", route: () => logout() },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [type, setType] = useState(null);
  const [url, setURL] = useState("");
  const [id, setId] = useState("");
  const [navigateTo, setNavigateTo] = useState("");
  const [active, setActive] = useState(false);
  const [order, setOrder] = useState("");

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCallback = (childData) => {
    setSettingsModalVisible(childData);
  };

  const remove = (event, id) => {
    event.stopPropagation();
    removeMedia(id);
  };

  const renderItem = ({ item }) => {
    return (
      <View
        style={{
          backgroundColor: "#DEDEDE",
          marginVertical: 2,
          marginHorizontal: 2,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            toggleModal();
            handleMediaCallback(item);
          }}
          style={{ width: "80%", marginTop: 1 }}
        >
          <Text style={{ padding: 12, fontSize: adjust(16) }}>
            TYPE: {item.type}
          </Text>
          <Text style={{ padding: 12 }}>URL: {item.url}</Text>
          <Text style={{ padding: 12 }}>Navigate To: {item.navigateTo}</Text>
          {item.type === "Video" ? (
            <></>
          ) : (
            <Text style={{ padding: 12 }}>Order: {item.order}</Text>
          )}
        </TouchableOpacity>

        <View style={{ padding: 10, justifyContent: "center" }}>
          <TouchableOpacity onPress={(event) => remove(event, item._id)}>
            <Icon
              name="delete"
              fill="#2B3856"
              style={{ marginRight: 18 }}
              stroke="#2B3856"
              height="30"
              width="30"
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const handleMediaCallback = (item) => {
    setURL(item.url || "");
    setType({ name: item.type });
    setNavigateTo(item.navigateTo || "");
    setOrder(item.order || "");
    setActive(item.active);
    setId(item._id);
  };

  useEffect(() => {
    fetchMedias();
  }, []);

  const saveMedia = () => {
    let obj = {
      url: url,
      type: type?.name,
      order: order,
      active: active,
      navigateTo: navigateTo,
    };
    // console.log(obj);
    if (obj.url !== "" && (obj.order !== "" || obj.type === "Video")) {
      if (id) {
        obj._id = id;
      }
      createMedia(obj);
      setIsModalVisible(false);
      clearData();
    } else {
      addError("Please fill all the fields", 3000);
    }
  };

  const clearData = () => {
    setURL("");
    setType(null);
    setNavigateTo("");
    setOrder("");
    setId("");
  };
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <TouchableOpacity onPress={() => toggleModal()}>
          <View>
            <Text
              style={{
                fontSize: adjust(45),
                color: "#2B3856",
                marginRight: 32,
              }}
            >
              +
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.container}>
        <FlatList
          renderItem={renderItem}
          data={medias ? medias : []}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>

      <SettingsPopUp
        renderData={renderData}
        showModal={settingsModalVisible}
        onSelection={handleCallback}
      ></SettingsPopUp>

      <Modal
        animationType="fade"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={isModalVisible}
        onRequestClose={() => {
          setIsModalVisible(false);
        }}
        ariaHideApp={false}
      >
        <View style={styles.centeredView}>
          <BlurView
            style={styles.modalView}
            intensity={80}
            tint="dark"
            blurType="dark"
            blurAmount={32}
            blurRadius={100}
          >
            <View
              style={{
                maxWidth: 350,
                backgroundColor: "#FFFFFF",
                width: "80%",
                borderRadius: 10,
                padding: 15,
                minHeight: 350,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: 30,
                  minHeight: 30,
                }}
              >
                <Icon name="box3D" style={styles.icon}></Icon>
                <Text
                  style={{
                    fontWeight: "bold",
                    marginLeft: 5,
                    color: "#A9A9A9",
                  }}
                >
                  {id ? "Update " : "Create "} Media
                </Text>
              </View>
              {/* <View
                style={{
                  flex: 1,
                  maxHeight: 150,
                  minHeight: 150,
                  flexDirection: "column",
                }}
              > */}
              <View style={{ maxHeight: 40, minHeight: 40 }}>
                <PopUp
                  placeholder={"Media Type"}
                  style={{
                    minHeight: 30,
                    maxHeight: 30,
                    marginLeft: 20,
                    maxWidth: "100%",
                    marginTop: 10,
                  }}
                  selectionValue={type}
                  renderData={[{ name: "Image" }, { name: "Video" }]}
                  onSelection={(e) => setType(e)}
                ></PopUp>
              </View>

              <TextInput
                placeholder={"Enter media URL"}
                value={url}
                onChangeText={(e) => setURL(e)}
                style={{
                  minHeight: 30,
                  marginLeft: 20,
                  maxWidth: "80%",
                  borderBottomColor: "#000",
                  borderBottomWidth: 1,
                  marginBottom: Platform.OS === "web" ? 30 : null,
                }}
              ></TextInput>
              <TextInput
                placeholder={"Navigate to on click"}
                value={navigateTo}
                onChangeText={(e) => setNavigateTo(e)}
                style={{
                  minHeight: 30,
                  marginLeft: 20,
                  maxWidth: "80%",
                  borderBottomColor: "#000",
                  borderBottomWidth: 1,
                  marginBottom: 10,
                }}
              ></TextInput>
              {/* </View> */}
              {type && type.name === "Image" ? (
                <TextInput
                  placeholder={"Order"}
                  value={`${order || ""}`}
                  onChangeText={(e) => setOrder(e)}
                  keyboardType={"numeric"}
                  style={{
                    minHeight: 30,
                    marginLeft: 20,
                    maxWidth: "80%",
                    borderBottomColor: "#000",
                    borderBottomWidth: 1,
                    marginBottom: 10,
                  }}
                ></TextInput>
              ) : (
                <></>
              )}
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  marginTop: 10,
                  maxWidth: "100%",
                  marginLeft: 15,
                  maxHeight: 30,
                }}
              >
                <Checkbox
                  value={active}
                  setValue={() => setActive(true)}
                ></Checkbox>
                <Text
                  style={{
                    marginTop: 8,
                    fontSize: adjust(15),
                    fontWeight: "bold",
                    color: "#000000",
                    marginLeft: 15,
                  }}
                >
                  Active
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: 10,
                  justifyContent: "space-around",
                }}
              >
                <RoundButton
                  style={{ backgroundColor: "#FFD500" }}
                  title={"Submit"}
                  pressFunc={() => {
                    saveMedia();
                  }}
                ></RoundButton>
                <RoundButton
                  style={{ backgroundColor: "#000000", marginLeft: 10 }}
                  title={"Cancel"}
                  pressFunc={() => {
                    clearData();
                    setIsModalVisible(false);
                  }}
                ></RoundButton>
              </View>
            </View>
          </BlurView>
        </View>
        {/* </TouchableWithoutFeedback> */}
      </Modal>
    </View>
  );
};

const mapStateToProps = ({ medias, user }) => ({
  medias,
  user,
});

export default connect(mapStateToProps, {
  createMedia,
  fetchMedias,
  logout,
  removeMedia,
})(CreateMedia);

const styles = StyleSheet.create({
  container: {
    flex: 5,
    marginTop: StatusBar.currentHeight || 0,
  },
  item: {
    backgroundColor: "#DEDEDE",
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: adjust(32),
  },
  iconStyle: {
    minWidth: 50,
    minHeight: 50,
  },
  tabHeaderContainer: {
    padding: 10,
    // maxHeight: 125,
    // minHeight: 125,
    backgroundColor: "white",
    marginBottom: 15,
    borderRadius: 10,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: 10,
    flex: 1,
    flexDirection: "column",
  },
  textClass: {
    fontWeight: "bold",
    fontSize: adjust(10),
    color: "gray",
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    marginBottom: 30,
  },
  icon: {
    // marginLeft: 20,
  },
  customRatingBarStyle: {
    flexDirection: "row",
    marginTop: 5,
  },
});
