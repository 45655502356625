import { StyleSheet, Text, View, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import RoundButton from "../../components/roundButton";
import EarningCardComponentWithTotalSales from "../../components/earningComponents/earningCardComponentWithTotalSales";
import AllTransactionComponents from "../../components/earningComponents/allTransactionComponents";
import {
  fetchCreatorFinances,
  removeCreatorFinances,
} from "../../redux/actions/creator.action";
import { connect } from "react-redux";
import moment from "moment";

const CreatorFinanceScreen = ({
  navigation,
  route,
  creatorFinances,
  fetchCreatorFinances,
  removeCreatorFinances,
}) => {
  useEffect(() => {
    if (route.params) {
      fetchCreatorFinances(route.params.user);
    }
    return () => {
      removeCreatorFinances();
    };
  }, [route.params]);
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          enableAvatar={true}
          title="manage finances"
          subtitle={`Manage Finances for ${route.params?.name}`}
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ marginHorizontal: 10 }}>
        <EarningCardComponentWithTotalSales
          header={"Payment requested"}
          firstCountValue={creatorFinances.reduce((a, b) => a + b.amount, 0)}
          firstText={"Earned total"}
          middleCountValue={creatorFinances
            .filter((x) => x.status === "Received")
            .reduce((a, b) => a + b.amount, 0)}
          middleText={"Withdrawn"}
          lastCountValue={creatorFinances
            .filter((x) => x.status === "Pending")
            .reduce((a, b) => a + b.amount, 0)}
          roundButton={true}
          lastText={"Pending"}
          CountValue={"#7A9D54"}
          borderColor={"#7A9D54"}
          buttonColor={"#7A9D54"}
          buttontext={`INR ${creatorFinances
            .filter((x) => x.status === "Requested")
            .reduce((a, b) => a + b.amount, 0)} requested`}
        />
      </View>
      <ScrollView nestedScrollEnabled={true} style={{ marginHorizontal: 10 }}>
        {creatorFinances.map((x) => (
          <AllTransactionComponents
            key={x._id + "creator-finance"}
            Colors={"#7A9D54"}
            amount={x.amount}
            transactionTime={moment(
              new Date(
                x.updatedDate ||
                  parseInt(x._id.toString().substring(0, 8), 16) * 1000
              )
            ).format("DD/MM/YY h:mm a")}
            transactionDetails={"creator pay added"}
            projectName={x.project?.title}
          />
        ))}
      </ScrollView>
    </View>
  );
};

const mapStateToProps = ({ creatorFinances }) => ({ creatorFinances });
export default connect(mapStateToProps, {
  fetchCreatorFinances,
  removeCreatorFinances,
})(CreatorFinanceScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
