import React from "react";
import { TouchableOpacity, Text, View, Image, StyleSheet } from "react-native";
import Icon from "../components/icon";
import adjust from "./adjust";

export default function Footer({ disableNextButton, style }) {
  return (
    <View style={[styles.container, style]}>
      <View style={{ flex: 1 }}>
        <Image
          source={require("../assets/logo_black.png")}
          style={{ height: 40, width: 150 }}
        />
        <Text style={{ fontSize: adjust(9) }}>
          A product of Anamle Design Solutions Pvt. Ltd. {"\n"}
          All Rights Reserved. Copyright 2020
        </Text>
      </View>
      {disableNextButton ? (
        <></>
      ) : (
        <TouchableOpacity style={{ flex: 1 }}>
          <Icon
            name="rightChevronArrowWithCircle"
            style={{ alignSelf: "flex-end", marginRight: 30, marginTop: 40 }}
          ></Icon>
        </TouchableOpacity>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 30,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 10,
    // position: "absolute",
    // bottom: 30,
  },
});
