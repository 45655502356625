import axios from "axios";
import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import LargeTextInput from "../largeTextInput";
import config from "../../config/config";

const UserSignUpFields = ({ newPassword, object, onChangeText }) => {
  const [active, setActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const client = axios.create({
    baseURL: config.baseUrl,
    responseType: "json",
  });

  client.interceptors.request.use((req) => {
    req.baseURL = config.baseUrl;
    req.timeout = 60000;
    return req;
  });

  const onChangeUserName = (name) => {
    client.get(`/api/user/validate/username/${name}`).then(
      (result) => {
        if (result.data.isValid) {
          setErrorMessage();
          onChangeText({ userName: name });
        } else {
          console.log(result);
          onChangeText({ userName: "" });
          setErrorMessage(result.data.message);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    newPassword?.trim() !== "" && newPassword === object?.password
      ? setActive(true)
      : setActive(false);
  }, [newPassword, object?.password]);
  return (
    <View style={styles.container}>
      <Text style={styles.headerStyle}>setup basic credentials here</Text>
      <LargeTextInput
        placeholder="enter an username"
        style={{ marginTop: 50, borderBottomColor: "#707070" }}
        onChangeText={(data) => onChangeUserName(data)}
        errorMessage={errorMessage}
      ></LargeTextInput>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          secureTextEntry={true}
          placeholder="enter a strong password"
          style={{ borderBottomColor: "#707070" }}
          onChangeText={(data) => onChangeText({ password: data })}
        ></LargeTextInput>
      </View>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          secureTextEntry={true}
          placeholder="re-enter your password"
          style={{ borderBottomColor: "#707070" }}
          onChangeText={(data) => onChangeText({ confirmPassword: data })}
        ></LargeTextInput>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    margin: 25,
    color: "#000",
  },
  wrapperStyle: {
    marginTop: 70,
  },
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
});
export default UserSignUpFields;
