import { StyleSheet, Text, View, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import RoundButton from "../../components/roundButton";
import EarningCardComponentWithTotalSales from "../../components/earningComponents/earningCardComponentWithTotalSales";
import {
  fetchSalesPersonEarnings,
  removeSalesPersonEarnings,
} from "../../redux/actions/sales-person.action";
import { connect } from "react-redux";
import moment from "moment";
import SalesEarningCard from "../../components/earningComponents/salesEarningCard";

const SalesEarningScreen = ({
  navigation,
  salesPersonEarnings,
  fetchSalesPersonEarnings,
  removeSalesPersonEarnings,
  route,
}) => {
  const [currentMonthEarning, setCurentMonthEarning] = useState([]);
  useEffect(() => {
    if (route.params) {
      fetchSalesPersonEarnings(route.params.user);
    }
    return () => {
      removeSalesPersonEarnings();
    };
  }, [route.params]);

  useEffect(() => {
    setCurentMonthEarning(
      salesPersonEarnings.filter(
        (x) =>
          moment(new Date().setDate(1)).diff(
            moment(new Date(x.project.startDate).setDate(1)),
            "days"
          ) < 1
      )
    );
  }, [salesPersonEarnings]);

  const [activeTab, setActiveTab] = useState("this month");
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          enableAvatar={true}
          title="all earnings"
          subtitle={`All earnings for ${route?.params?.name || ""}`}
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <View
        style={{
          maxHeight: 130,
          flexDirection: "row",
        }}
      >
        <RoundButton
          title={"this month"}
          pressFunc={() => {
            setActiveTab("this month");
          }}
          textStyle={{
            color: activeTab === "this month" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor: activeTab === "this month" ? "#7A9D54" : "#FFF",
            width: 150,
            borderWidth: 0.5,
            borderColor: "#000",
            marginLeft: 30,
            alignSelf: "flex-end",
          }}
        ></RoundButton>
        <RoundButton
          title={"overall"}
          pressFunc={() => {
            setActiveTab("overall");
          }}
          textStyle={{
            color: activeTab === "overall" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor: activeTab === "overall" ? "#7A9D54" : "#FFF",
            width: 130,
            borderWidth: 0.5,
            borderColor: "#000",
            marginLeft: 10,
            alignSelf: "flex-end",
          }}
        ></RoundButton>
      </View>
      <View style={{ marginHorizontal: 10 }}>
        <EarningCardComponentWithTotalSales
          onpress={() => navigation.navigate("sales-finance", route.params)}
          header={"total sales in metrics (in INR)"}
          firstCountValue={(activeTab === "this month"
            ? currentMonthEarning
            : salesPersonEarnings
          ).reduce(
            (a, b) => a + b.commission.reduce((x, y) => x + y.amount, 0),
            0
          )}
          firstText={"Commissions"}
          middleCountValue={(activeTab === "this month"
            ? currentMonthEarning
            : salesPersonEarnings
          ).reduce(
            (a, b) => a + b.requested.reduce((x, y) => x + y.amount, 0),
            0
          )}
          middleText={"Earning"}
          lastCountValue={(activeTab === "this month"
            ? currentMonthEarning
            : salesPersonEarnings
          ).reduce(
            (a, b) => a + b.received.reduce((x, y) => x + y.amount, 0),
            0
          )}
          roundButton={true}
          lastText={"Received"}
          CountValue={"#7A9D54"}
          borderColor={"#7A9D54"}
          buttonColor={"#7A9D54"}
          buttontext={"manage finances"}
        />
      </View>
      <ScrollView nestedScrollEnabled={true} style={{ marginHorizontal: 10 }}>
        {(activeTab === "this month"
          ? currentMonthEarning
          : salesPersonEarnings
        ).map((x) => (
          <SalesEarningCard data={x} key={x._id + "sales-earning"} />
        ))}
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ salesPersonEarnings }) => ({ salesPersonEarnings });

export default connect(mapStateToProps, {
  fetchSalesPersonEarnings,
  removeSalesPersonEarnings,
})(SalesEarningScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
