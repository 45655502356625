import * as React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Pressable,
} from "react-native";
import Header from "../../components/header";
import LargeButton from "../../components/largeButton";
import Footer from "../../components/footer";
import adjust from "../../components/adjust";
import RoundButton from "../../components/roundButton";
import Modal from "../../components/modal/modal";
import { BlurView } from "expo-blur";
import { useState } from "react";
import Icon from "../../components/icon";
import CheckBox from "../../components/checkbox";
import FontOutlineWithShadow from "../../components/fontOutlineWithShadow/fontOutlineWithShadow";

const SelectProfile = ({ navigation }) => {
  const [policy, viewPolicy] = useState(null);
  const [accepted, setAccepted] = useState(false);

  // const policyAccepted = () => {
  //   if (accepted) {

  //     closeModal();
  //   }
  // };

  const closeModal = () => {
    viewPolicy(null);
    setAccepted(false);
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#FAFAFA" }}>
      <View style={{ maxHeight: 150, minHeight: 150 }}>
        <Header
          enableAvatar={false}
          title="Select a Profile"
          subtitle="Please enter your phone number"
          backFunction={() => navigation.goBack()}
          style={{ backgroundColor: "#FAFAFA" }}
        ></Header>
      </View>

      <ScrollView
        contentContainerStyle={{
          flex: 1,
          marginHorizontal: 10,
          justifyContent: "space-between",
        }}
        nestedScrollEnabled
        scrollEnabled
        showsVerticalScrollIndicator={false}
      >
        {/* <View style={{ flex: 1 }}> */}
        <Text style={styles.textStyle}>Have a project for us?</Text>
        <LargeButton
          arrowColor={"#FFF"}
          borderColor={"#3A8CD1"}
          textColor={"#FFFFFF"}
          backgroundColor={"#3A8CD1"}
          title="Sign-up as Client"
          pressFunc={() =>
            navigation.navigate("onboardingMessage", {
              userType: "client",
              navigateTo: "clientSignupStack",
            })
          }
        ></LargeButton>

        <Text style={[styles.textStyle, { marginTop: 30 }]}>
          or, work with us?
        </Text>

        <LargeButton
          arrowColor={"#FFF"}
          borderColor={"#F27253"}
          textColor={"#FFFFFF"}
          backgroundColor={"#F27253"}
          title="Join Remote Sales"
          pressFunc={() =>
            navigation.navigate("onboardingMessage", {
              userType: "sales",
              navigateTo: "saleSignupStack",
            })
          }
        ></LargeButton>
        <LargeButton
          arrowColor={"#FFF"}
          borderColor={"#FF973E"}
          textColor={"#FFFFFF"}
          backgroundColor={"#FF973E"}
          title="Join Remote Creative"
          pressFunc={() =>
            navigation.navigate("onboardingMessage", {
              userType: "creator",
              navigateTo: "creatorSignupStack",
            })
          }
        ></LargeButton>
        <View
          style={{
            marginTop: 30,
            borderBottomColor: "#F5F5F5",
            borderBottomWidth: 2,
            marginHorizontal: 18,
          }}
        />
        <View
          style={{
            flex: 1,
            marginTop: 20,
            marginHorizontal: 10,
          }}
        >
          <Text
            style={{
              fontSize: adjust(15),
              fontWeight: "bold",
              color: "#828282",
              marginHorizontal: 10,
            }}
          >
            Join as a Partner?
          </Text>
          <Text
            style={{
              fontSize: adjust(11),
              color: "#606060",
              marginTop: 10,
              marginHorizontal: 10,
            }}
          >
            To apply as a partner, send an e-mail to us at
            chief@onedesignlabs.com and tell us why we should appoint you as a
            Partner and we will get back to you.
          </Text>
        </View>
        {/* </View> */}
        {/* <View style={{ marginBottom: 40 }}> */}
        {/* </View> */}
      </ScrollView>
      {/* <Modal
        animationType="fade"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={policy != null}
        onRequestClose={() => closeModal()}
        ariaHideApp={false}
      >
        <View style={styles.centeredView}>
          <BlurView
            style={styles.modalView}
            intensity={80}
            tint="dark"
            blurType="dark"
            blurAmount={32}
            blurRadius={100}
          >
            <View
              style={{
                backgroundColor:
                  policy === "clientSignupStack"
                    ? "#3A8CD1"
                    : policy === "creatorSignupStack"
                    ? "#FF973E"
                    : "#F27253",
                width: "90%",
                borderRadius: 10,
                height: "90%",
              }}
            >
              <TouchableOpacity
                style={{
                  flexDirection: "row-reverse",
                  minHeight: 17,
                  top: 5,
                  right: 5,
                }}
                onPress={() => closeModal()}
              >
                <Icon name="cross" fill="#000" />
              </TouchableOpacity>
              <ScrollView>
                <FontOutlineWithShadow stroke={2} color={"#FFF2AF"}>
                  <Text
                    style={{
                      fontSize: 100,
                      color:
                        policy === "clientSignupStack"
                          ? "#3A8CD1"
                          : policy === "creatorSignupStack"
                          ? "#FF973E"
                          : "#F27253",
                      marginLeft: 20,
                    }}
                  >
                    hello there!
                  </Text>
                </FontOutlineWithShadow>
                <Text
                  style={{
                    marginLeft: 5,
                    paddingHorizontal: 20,
                    fontSize: 25,
                    fontWeight: "bold",
                    color: "#FFF",
                  }}
                >
                  This is where Freelance Culture meets Agency Trust to become
                  the Agency of the Future! World’s first agency-led remote
                  working platform for independent creators and global clients.
                  This is where Freelance Culture meets Agency Trust to become
                  the Agency of the Future! World’s first agency-led remote
                  working platform for independent creators and global clients.
                  This is where Freelance Culture meets Agency Trust to become
                  the Agency of the Future! World’s first agency-led
                </Text>

                <Text
                  style={{
                    fontSize: 40,
                    marginLeft: 5,
                    paddingHorizontal: 20,
                    color: "#FFF",
                  }}
                >
                  satya subham rout
                </Text>
              </ScrollView>

              <Pressable
                style={[styles.pressableStyle]}
                onPress={() => {
                  closeModal();
                  navigation.navigate(policy);
                }}
              >
                <Text
                  style={[
                    {
                      color: "#FFFFFF",
                      fontSize: adjust(15),
                      fontWeight: "bold",
                      alignSelf: "center",
                      justifyContent: "center",
                    },
                  ]}
                >
                  {"Next"}
                </Text>
              </Pressable>
            </View>
          </BlurView>
        </View>
      </Modal> */}
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  textStyle: {
    // marginLeft: 25,
    marginHorizontal: 20,
    fontSize: 15,
    fontWeight: "bold",
    color: "#828282",
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});

export default SelectProfile;
