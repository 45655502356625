import { addError } from "../actions/toast.action";

const initialState = {};
const discussionsByProject = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_DISCUSSION_SUCCESS":
    case "FETCH_DISCUSSION_BY_PROJECT_SUCCESS": {
      return action.payload.data;
    }

    case "CREATE_DISCUSSION_FAIL":
    case "FETCH_DISCUSSION_BY_PROJECT_FAIL": {
      action.asyncDispatch(addError(action.error.response?.data?.message, 3000));
      return state;
    }
    case "LOGOUT": {
      return {};
    }
    default:
      return state;
  }
};
export default discussionsByProject;
