import * as React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Icon from "./icon";

const Checkbox = ({
  value,
  setValue = () => {},
  style,
  disable,
  disabled,
  fill,
  iconColor = "#41B000",
}) => {
  const [checked, setChecked] = React.useState(value ? value : false);

  React.useEffect(() => {
    setChecked(value ? value : false);
  }, [value]);

  return disable ? (
    <View style={[styles.checkbox, style]}>
      {value ? (
        <Icon
          fill={fill ? fill : "#B1B1B1"}
          name="tick"
          style={styles.icon}
        ></Icon>
      ) : (
        <></>
      )}
    </View>
  ) : (
    <TouchableOpacity
      style={{
        backgroundColor: "#FFF",
        justifyContent: "center",
        width: 24,
        maxWidth: 24,
        minHeight: 24,
        maxHeight: 24,
        alignSelf: "center",
        borderRadius: 100,
      }}
      disabled={disabled ? true : false}
      onPress={() => {
        setChecked(!checked);
        if (setValue) setValue(!checked);
      }}
    >
      <View style={[styles.checkbox, style]}>
        {checked ? (
          <Icon fill={fill || iconColor} name="tick" style={styles.icon}></Icon>
        ) : (
          <></>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    borderRadius: 15,
    borderColor: "#666",
    borderWidth: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: 30,
    maxWidth: 30,
    minHeight: 30,
    maxHeight: 30,
  },
  checkbox: {
    alignSelf: "center",
  },
  icon: {
    marginLeft: 2,
    marginTop: 2,
  },
});

export default Checkbox;
