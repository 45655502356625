import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Dashboard from "../../screens/creator/dashboard";
import EditYourProfile from "../../screens/editYourProfile";
import MyProjects from "../../screens/common/myProjects";
import NewProjects from "../../screens/creator/newProjects";
import CreatorNewProjectDetails from "../../screens/creator/creatorNewProjectDetails";
import ProfessionalProfile from "../../screens/partner/professionalProfile";
import MyPayments from "../../screens/myPayments";
import ProjectPayment from "../../screens/creator/activeProjectDetails/projectPayment";
import ReviewFeedback from "../../components/reviewFeedback";
import deliverables from "../../screens/deliverables";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import ActiveProjectSummary from "../../screens/creator/activeProjectSummary/activeProjectSummary";
import Payments from "../../screens/partner/payments";
import Progress from "../../screens/creator/activeProjectDetails/progress";
import ChangePassword from "../../screens/changePassword";
import Discussion from "../../screens/discussion";
import Chat from "../../components/chat/chat";
import { Dimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import NotificationDrawer from "../../notificationDrawer";
import { connect } from "react-redux";
import login from "../../screens/login";
import AdminVerification from "../../screens/creator/admin-verification";
import ChatWithUs from "../../screens/common/chat-with-us";
import creatorProfile from "../../screens/partner/creator-profile";
import scheduleMeeting from "../../screens/common/meeting/scheduleMeeting";
import Transaction from "../../screens/common/user-payment-screen";
const window = Dimensions.get("window");
const CreatorStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const StackNavigatorComponent = ({ user }) => {
  if (!user) {
    return <></>;
  } else {
    return (
      <CreatorStack.Navigator
        name="creatorDashboard"
        initialRouteName={"dashboard"}
      >
        <CreatorStack.Screen
          name="dashboard"
          component={Dashboard}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="editYourProfile"
          component={EditYourProfile}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="myProjects"
          component={MyProjects}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="projectPayment"
          component={ProjectPayment}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="newProjects"
          component={NewProjects}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="creatorNewProjectDetails"
          component={CreatorNewProjectDetails}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="professionalProfile"
          component={ProfessionalProfile}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="reviewFeedback"
          component={ReviewFeedback}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="myPayments"
          component={MyPayments}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="chat"
          component={Chat}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="activeProjectDetails"
          component={ActiveProjectDetails}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="activeProjectSummary"
          component={ActiveProjectSummary}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="payments"
          component={Payments}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="progress"
          component={Progress}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="changePassword"
          component={ChangePassword}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="discussion"
          component={Discussion}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="deliverables"
          component={deliverables}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="admin-verification"
          component={AdminVerification}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="chat-with-us"
          component={ChatWithUs}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="creator-profile"
          component={creatorProfile}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="scheduleMeeting"
          component={scheduleMeeting}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          options={{ headerShown: false }}
          name="payment-details"
          component={Transaction}
        />
      </CreatorStack.Navigator>
    );
  }
};

const CreatorNavigator = ({ user }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="creator"
        // component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      >
        {() => <StackNavigatorComponent user={user} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps, {})(CreatorNavigator);
