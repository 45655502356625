import { StyleSheet, Text, View, ScrollView } from "react-native";
import React, { useState } from "react";
import Header from "../../../components/header";
import RoundButton from "../../../components/roundButton";
import EarningCardComponentWithTotalSales from "../../../components/earningComponents/earningCardComponentWithTotalSales";
import SalesEarningCard from "../../../components/earningComponents/salesEarningCard";
import {
  fetchSalesPersonEarnings,
  removeSalesPersonEarnings,
} from "../../../redux/actions/sales-person.action";
import { connect } from "react-redux";
import { useEffect } from "react";
const SalesEarningScreen = ({
  navigation,
  salesPersonEarnings,
  fetchSalesPersonEarnings,
  removeSalesPersonEarnings,
  route,
}) => {
  const [activeTab, setActiveTab] = useState("this month");
  const [currentMonthEarning, setCurentMonthEarning] = useState([]);
  useEffect(() => {
    if (route.params) {
      fetchSalesPersonEarnings(route.params.user);
    }
    return () => {
      removeSalesPersonEarnings();
    };
  }, [route.params]);

  useEffect(() => {
    setCurentMonthEarning(
      salesPersonEarnings.filter(
        (x) =>
          moment(new Date().setDate(1)).diff(
            moment(new Date(x.project.startDate).setDate(1)),
            "days"
          ) < 1
      )
    );
  }, [salesPersonEarnings]);

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="all earnings"
          subtitle="All Your Projects"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ backgroundColor: "#FFFFFF", flexDirection: "row" }}>
        <RoundButton
          title={"this month"}
          pressFunc={() => {
            setActiveTab("this month");
          }}
          textStyle={{
            color: activeTab === "this month" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor: activeTab === "this month" ? "#F27253" : "#FFF",
            borderColor: "#000",
            marginLeft: 30,
            //width: "30%",
            borderWidth: 1,
          }}
        ></RoundButton>
        <RoundButton
          title={"overall"}
          pressFunc={() => {
            setActiveTab("overall");
          }}
          textStyle={{
            color: activeTab === "overall" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor: activeTab === "overall" ? "#F27253" : "#FFF",
            borderWidth: 1,
            borderColor: "#000",
            marginLeft: 10,
            //width: "25%",
          }}
        ></RoundButton>
      </View>
      <View style={{ marginHorizontal: 15 }}>
        <EarningCardComponentWithTotalSales
          header={"total sales metrics (in INR)"}
          borderColor={"#F27253"}
          firstCountValue={300000}
          firstText={"revenue"}
          middleCountValue={40000}
          middleText={"Earning (10%)"}
          lastCountValue={103000}
          lastText={"Received"}
          CountValue={"#F27253"}
          roundButton={true}
          buttonColor={"#F27253"}
          buttontext={"manage finances"}
          onpress={() => navigation.navigate("SalesManageFinanceScreen")}
        />
      </View>
      <ScrollView nestedScrollEnabled={true}>
        {(activeTab === "this month"
          ? currentMonthEarning
          : salesPersonEarnings
        ).map((x) => (
          <SalesEarningCard data={x} key={x._id + "sales-earning"} />
        ))}
      </ScrollView>
    </View>
  );
};

const mapStateToProps = ({ salesPersonEarnings }) => ({ salesPersonEarnings });

export default connect(mapStateToProps, {
  fetchSalesPersonEarnings,
  removeSalesPersonEarnings,
})(SalesEarningScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
