import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
} from "react-native";
import adjust from "../adjust";
import Avatar from "../avatar";
import { Video, AVPlaybackStatus } from "expo-av";
import Icon from "../icon";
import * as FileSystem from "expo-file-system";
// import { downloadToFolder } from "expo-file-dl";

import config from "../../config/config";
import Modal from "../../components/modal/modal";
import { BlurView } from "expo-blur";
const window = Dimensions.get("window");
import { BackHandler } from "react-native";
import moment from "moment";

const getDate = (id) => {
  let timestamp = id?.toString().substring(0, 8);
  let date = new Date(parseInt(timestamp, 16) * 1000);
  return date;
};

const MediaBubble = ({ x, setMediaView }) => {
  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
    return () => {
      BackHandler.removeEventListener(
        "hardwareBackPress",
        handleBackButtonClick
      );
    };
  }, []);

  useEffect(() => {
    getFileUri(x.file);
  }, [x]);

  const [uri, setUri] = useState(
    "http://onedesign.yoctotta.com/static/media/onedesign-splash.58306cfa.png"
  );

  function handleBackButtonClick() {
    if (mediaView) {
      setMediaView(null);
    } else {
      navigation.goBack();
    }
    return true;
  }

  const downloadProgressUpdater = ({
    totalBytesWritten,
    totalBytesExpectedToWrite,
  }) => {};

  const getFileUri = (file) => {
    let fileName = file.originalname || file.path.split("/").pop();
    if (Platform.OS === "web") {
      if (file._id) setUri(`${config.baseUrl}api/chat/file?fileId=${file._id}`);
    } else {
      FileSystem.getInfoAsync(
        `${config.baseLocalUri}/file-${file._id}-${fileName}`
      ).then(async (x) => {
        if (!x.exists) {
          // await downloadToFolder(
          //   `${config.baseUrl}api/chat/file?fileId=${file._id}`,
          //   `${fileName}`,
          //   "OneDesign",
          //   "oneDesign",
          //   {
          //     downloadProgressCallback: downloadProgressUpdater,
          //   }
          // );
          FileSystem.downloadAsync(
            `${config.baseUrl}api/chat/file?fileId=${file._id}`,
            `${config.baseLocalUri}/file-${file._id}-${fileName}`
          ).then(({ uri }) => {
            setUri(uri);
          });
        } else {
          setUri(x.uri);
        }
      });
    }
  };

  return (
    <View key={Math.random()}>
      {x.file.type === "image" ? (
        <TouchableOpacity
          onPress={() => setMediaView({ type: x.file.type, uri: uri })}
        >
          <Image
            source={{
              uri: uri,
            }}
            style={{
              minHeight: 130,
              maxHeight: 500,
              width: "100%",
              borderRadius: 10,
              marginVertical: 10,
              // resizeMode: "contain",
            }}
            resizeMethod="scale"
            resizeMode="contain"
          />
        </TouchableOpacity>
      ) : x.file.type === "video" ? (
        <TouchableOpacity
          onPress={() => setMediaView({ type: x.file.type, uri: uri })}
        >
          <Video
            style={{
              minHeight: 130,
              maxHeight: 200,
              borderRadius: 10,
              marginVertical: 10,
            }}
            useNativeControls
            resizeMode="cover"
            isLooping
            isMuted
            source={{
              uri: uri,
            }}
          />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          onPress={() => {
            FileSystem.getInfoAsync(
              `${config.baseLocalUri}/file-${x.file._id}-${
                x.file.originalname || x.file.path.split("/").pop()
              }`
            ).then(async (info) => {
              if (!info.exists) {
                FileSystem.downloadAsync(
                  uri,
                  `${config.baseLocalUri}/file-${x.file._id}-${
                    x.file.originalname || x.file.path.split("/").pop()
                  }`
                );
                // await downloadToFolder(
                //   `${config.baseUrl}api/chat/file?fileId=${x.file._id}`,
                //   `${x.file.originalname || x.file.path.split("/").pop()}`,
                //   "OneDesign",
                //   "oneDesign",
                //   {
                //     downloadProgressCallback: downloadProgressUpdater,
                //   }
                // );
              }
            });
          }}
        >
          <View style={{ marginBottom: 2 }}>
            <Icon name="fileDownload" />
          </View>
          <Text style={{ color: "#4F7DC2", marginLeft: 7 }}>
            {x.file.originalname || x.file.path.split("/").pop()}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const Bubble = ({
  msg,
  user,
  checkPermission,
  navigation,
  selectedProject,
}) => {
  const [mediaView, setMediaView] = useState(null);
  const [fromUser, setFromUser] = useState(
    selectedProject.team.find((x) => x.user._id === msg?.from)?.user
  );
  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
    return () => {
      BackHandler.removeEventListener(
        "hardwareBackPress",
        handleBackButtonClick
      );
    };
  }, []);
  function handleBackButtonClick() {
    if (mediaView) {
      setMediaView(null);
    } else {
      navigation.goBack();
    }
    return true;
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: msg.from === user._id ? "row" : "row-reverse",
        margin: 3,
        marginHorizontal: 10,
      }}
    >
      <View
        style={[
          {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            borderTopLeftRadius: msg.from === user._id ? 10 : 0,
            borderTopRightRadius: msg.from === user._id ? 0 : 10,
            backgroundColor: msg.from === user._id ? "#FFC400" : "#FFFFFF",
            margin: 3,
            padding: 9,
            minWidth: "75%",
            maxWidth: "75%",
          },
        ]}
      >
        {msg.files.length > 0 ? (
          msg.files.map((x, index) => (
            <MediaBubble
              key={index}
              x={x}
              setMediaView={setMediaView}
            ></MediaBubble>
          ))
        ) : (
          <Text
            style={{
              fontSize: adjust(16),
              flexGrow: 1,
              paddingLeft: 0,
              marginVertical: 7,
              color: msg.from === user._id ? "#000" : "#606060",
              textAlign: msg.from === user._id ? "left" : "right",
              margin: 10,
            }}
          >
            {msg.msg}
          </Text>
        )}
        <View
          style={{
            flexDirection: msg.from === user._id ? "row-reverse" : "row",
            top: 5,
            justifyContent: "flex-end",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: adjust(11),
              width: "80%",
              marginLeft: 5,
            }}
          >
            {`${fromUser.fName} ${fromUser.lName || ""}`}
            <Text style={{ fontWeight: "normal" }}>
              {" "}
              | {moment(getDate(msg._id)).format("DD/MM/YY hh:mm A")}
            </Text>
          </Text>
          <View
            style={{
              alignSelf: "center",
              bottom: 5,
              marginLeft: msg.from._id === user._id ? 0 : 5,
            }}
          >
            <Avatar
              style={{ width: 25, height: 25, borderWidth: 0.5 }}
              source={
                fromUser.image && fromUser.mimType
                  ? {
                      uri: `data:${fromUser.mimType};base64,${fromUser.image}`,
                    }
                  : null
              }
            ></Avatar>
          </View>
        </View>
      </View>

      <Modal
        animationType="fade"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={mediaView != null}
        onRequestClose={() => setMediaView(null)}
        ariaHideApp={true}
      >
        <View style={styles.centeredView}>
          <BlurView
            style={styles.modalView}
            intensity={80}
            tint="dark"
            blurType="dark"
            blurAmount={32}
            blurRadius={100}
          >
            <View
              style={{
                backgroundColor: "#FFFFFF",
                width: "90%",
                borderRadius: 10,
                padding: 15,
                height: mediaView?.type === "image" ? window.height - 180 : 380,
              }}
            >
              <TouchableOpacity
                style={{ flexDirection: "row-reverse" }}
                onPress={() => setMediaView(null)}
              >
                <Icon name="cross" fill="#000" />
              </TouchableOpacity>
              {mediaView?.type === "image" ? (
                <View>
                  <Image
                    source={{
                      uri: mediaView?.uri,
                    }}
                    style={{
                      // flex: 1,
                      minHeight: window.height - 300,
                      maxHeight: window.height - 240,
                      borderRadius: 10,
                      marginVertical: 10,
                      resizeMode: "contain",
                    }}
                  />
                </View>
              ) : (
                <View style={{ alignContent: "center" }}>
                  <Video
                    style={{
                      // flex: 1,
                      minHeight: 300,
                      maxHeight: 300,
                      borderRadius: 10,
                      marginVertical: 10,
                    }}
                    useNativeControls
                    resizeMode="cover"
                    isLooping
                    isMuted
                    source={{
                      uri: mediaView?.uri,
                    }}
                  />
                </View>
              )}
            </View>
          </BlurView>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
});

export default Bubble;
