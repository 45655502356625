export const fetchProjectClass = () => (dispatch) => {
  return dispatch({
    type: "FETCH_CLASS",
    payload: {
      request: {
        url: `/api/project-class`,
        method: "GET",
      },
    },
  });
};

export const createClass = (classObj) => (dispatch) => {
  return dispatch({
    type: "CREATE_CLASS",
    payload: {
      request: {
        url: `/api/project-class`,
        method: "POST",
        data: classObj,
      },
    },
  });
};

export const removeClass = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_CLASS",
    payload: {
      request: {
        url: `/api/project-class/${id}`,
        method: "DELETE",
      },
    },
  });
};
