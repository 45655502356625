import React, { useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  Image,
  Platform,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import Header from "../../components/header";
import ProjectTemplateComponent from "../../components/projectTemplateComponent";
import { fetchCatalog } from "../../redux/actions/project-catalog.action";
const RequestProject = ({ navigation, projectCatalog, fetchCatalog }) => {
  useEffect(() => {
    fetchCatalog();
  }, []);
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          textStyle={{ color: "#B1B1B1" }}
          subtitle={"Select from the list below or create one"}
          title="Request a new project"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <ScrollView style={{ flex: 1, backgroundColor: "#E6E6E6" }}>
        <View>
          {projectCatalog?.map((project, index) => {
            return (
              <ProjectTemplateComponent
                key={`${project.name} + ${index}`}
                onPress={() =>
                  navigation.navigate("requestProjectBrief", { data: project })
                }
                data={project}
              ></ProjectTemplateComponent>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const mapStateToProps = ({ user, projectCatalog }) => ({
  user,

  projectCatalog,
});
export default connect(mapStateToProps, {
  fetchCatalog,
})(RequestProject);
