import { StyleSheet, Text, View, ScrollView } from "react-native";
import React from "react";
import Header from "../../../components/header";
import AllTransactionComponents from "../../../components/earningComponents/allTransactionComponents";
import EarningCardComponentWithTotalSales from "../../../components/earningComponents/earningCardComponentWithTotalSales";

const SalesManageFinanceScreen = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="manage finances"
          subtitle="all Your Projects"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <View style={{ marginHorizontal: 15 }}>
        <EarningCardComponentWithTotalSales
          header={"here's your money"}
          borderColor={"#F27253"}
          // borderColor={"#F27253"}
          firstCountValue={300000}
          firstText={"Earning Total"}
          middleCountValue={40000}
          middleText={"withdraw"}
          lastCountValue={103000}
          lastText={"pending"}
          CountValue={"#F27253"}
          roundButton={true}
          buttonColor={"#F27253"}
          buttontext={"Withdraw money"}
          onpress={() => navigation.navigate("SalesWithdrawScreen")}
        />
      </View>
      <ScrollView nestedScrollEnabled={true}>
        <View style={{ marginHorizontal: 20, marginTop: 20 }}>
          <AllTransactionComponents
            amount={"300"}
            Colors={"#F27253"}
            transactionDetails="Deprecated Gradle features were used in this build, making it incompatible with Gradle 7.0."
            projectName={"AllTransactionComponents"}
            transactionTime="30.40"
          />
        </View>
        <View style={{ marginHorizontal: 20, marginTop: 10 }}>
          <AllTransactionComponents
            amount={"300"}
            Colors={"#F27253"}
            transactionDetails="Deprecated Gradle features were used in this build, making it incompatible with Gradle 7.0."
            projectName={"AllTransactionComponents"}
            transactionTime="30.40"
          />
        </View>
        <View style={{ marginHorizontal: 20, marginTop: 10 }}>
          <AllTransactionComponents
            amount={"300"}
            Colors={"#F27253"}
            transactionDetails="Deprecated Gradle features were used in this build, making it incompatible with Gradle 7.0."
            projectName={"AllTransactionComponents"}
            transactionTime="30.40"
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default SalesManageFinanceScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
