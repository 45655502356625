export const fetchSalesPerson = () => (dispatch) => {
  return dispatch({
    type: "FETCH_SALES_PERSON",
    payload: {
      request: {
        url: `/api/user/sales-persons`,
        method: "GET",
      },
    },
  });
};

export const fetchSalesPersonEarnings = (user) => (dispatch) =>
  dispatch({
    type: "FETCH_SALESPERSON_EARINGS",
    payload: {
      request: {
        url: `/api/user/sales-person/earnings?user=${user}`,
        method: "GET",
      },
    },
  });

export const removeSalesPersonEarnings = () => (dispatch) =>
  dispatch({
    type: "BLANK_SALESPERSON_EARINGS",
  });

export const fetchSalesPersonFinances = (user) => (dispatch) =>
  dispatch({
    type: "FETCH_SALESPERSON_FINANCES",
    payload: {
      request: {
        url: `/api/user/sales-person/finances?user=${user}`,
        method: "GET",
      },
    },
  });

export const removeSalesPersonFinances = () => (dispatch) =>
  dispatch({
    type: "BLANK_SALESPERSON_FINANCES",
  });
