import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import React from "react";
import adjust from "../adjust";
import Icon from "../icon";

const AllProjectBudgetComponenet = ({
  title,
  subtitle,
  timeline,
  rupees,
  onPress,
  about,
}) => {
  const dimension = useWindowDimensions();
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <Text style={styles.headerStyle}>{title}</Text>
      <Text style={styles.titleStyle}>{about}</Text>
      <Text style={styles.timelineStyle}>Project TimeLine: {timeline}</Text>
      <View style={styles.bottomWrapStyle}>
        <View>
          <Text>budget in INR</Text>
          <Text style={styles.budgetStyle}>{rupees}</Text>
        </View>
        <View style={styles.iconContainerStyle}>
          <Icon
            width={40}
            height={30}
            fill={"#000000"}
            name="rightArrow"
          ></Icon>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default AllProjectBudgetComponenet;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 12,
    backgroundColor: "#FFFF",
    margin: 10,
    borderRadius: 10,
  },

  headerStyle: {
    fontWeight: "bold",
    fontSize: adjust(16),
    color: "#FF973E",
  },
  titleStyle: {
    color: "#000000",
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 5,
  },
  timelineStyle: {
    color: "#606060",
    fontSize: 11,
  },
  budgetStyle: {
    fontSize: 40,
    fontWeight: "bold",
    color: "#FF973E",
  },
  iconContainerStyle: {
    position: "absolute",
    bottom: 0,
    justifyContent: "center",
    right: 0,
  },
  bottomWrapStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
});
//
