import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import React, { useEffect } from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import WelcomeMessageComponent from "../../components/welcome_message_compoment/welcomeMessageComponent";
import DashboardCard from "../../components/dashboardCard";
import ItemWithCountValue from "../../components/itemWithCountValue/itemWithCountValue";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import { fetchAllActiveProjects } from "../../redux/actions/project.action";
import { fetchDashboardStats } from "../../redux/actions/dashboard.action";
import { fetchClientStats } from "../../redux/actions/user.action";
import { fetchWelcomeMessage } from "../../redux/actions/welcome-message.action";

const Dashboard = ({
  user,
  navigation,
  dashboardStats,
  fetchDashboardStats,
  userStats,
  fetchClientStats,
  fetchWelcomeMessage,
  welcomeMessages,
}) => {
  const dimension = useWindowDimensions();
  useEffect(() => {
    fetchDashboardStats();
    fetchClientStats();
    fetchWelcomeMessage("CLIENT");
  }, []);
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          enableAvatar={true}
          title={`Welcome, ${user.fName}`}
          subtitle={"Let’s create greatness today!"}
          disableBackButton="true"
          style={{ backgroundColor: null, marginTop: 20 }}
          navigation={navigation}
        ></Header>
      </View>
      {welcomeMessages.length ? (
        <ScrollView
          nestedScrollEnabled={true}
          style={{ maxHeight: 80 }} // due to scrollview issue in mobile i am giving fix height
          horizontal={true}
        >
          {welcomeMessages.map((x) => (
            <WelcomeMessageComponent
              key={x._id}
              textColor={"#FFF"}
              backgroundColor="#073257"
              message={x.message}
              image={x.image}
            ></WelcomeMessageComponent>
          ))}
        </ScrollView>
      ) : (
        <></>
      )}
      <ScrollView style={{ flex: 1, height: "100%", marginHorizontal: 5 }}>
        <DashboardCard
          jsx={
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("Project", { screen: "myProjects" })
              }
              style={{
                height: "90%",
                marginLeft: 5,
              }}
            >
              <Text style={styles.cardTitleStyle}>Projects</Text>
              <View
                style={{
                  flexDirection: "row",
                  bottom: 0,
                  position: "absolute",
                }}
              >
                <ItemWithCountValue
                  textColor={"#3A8CD1"}
                  countValue={dashboardStats?.activeProject || 0}
                  itemName={"Projects"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  textColor={"#3A8CD1"}
                  countValue={`${userStats.received}`}
                  itemName={"Total Investment"}
                ></ItemWithCountValue>
              </View>
            </TouchableOpacity>
          }
          disableIcon={true}
          icon={false}
          style={{
            maxHeight: 150,
            borderWidth: 1,
            borderColor: "#3A8CD1",
            minHeight: 150,
          }}
        ></DashboardCard>
        <DashboardCardWithArrowAndIcon
          source={require("../../assets/payment.png")}
          onPressCard={() => navigation.navigate("payment-details")}
          style={{ marginHorizontal: 10 }}
          description="Requested by Partner. Settle now"
          value={`${userStats.requested}`}
          title="Manage Payments (in INR)"
          backgroundColor="#3C581D"
        ></DashboardCardWithArrowAndIcon>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({
  user,
  dashboardStats,
  userStats,
  welcomeMessages,
}) => ({
  user,
  dashboardStats,
  userStats,
  welcomeMessages,
});
export default connect(mapStateToProps, {
  fetchAllActiveProjects,
  fetchDashboardStats,
  fetchClientStats,
  fetchWelcomeMessage,
})(Dashboard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  cardTitleStyle: {
    alignSelf: "flex-start",
    margin: 10,
    fontSize: 16,
    color: "#000",
    fontWeight: "bold",
  },
});
