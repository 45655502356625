import { addError, addInfo, addWarning } from "../actions/toast.action";

const initialState = {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "OTP_VALIDATE_SUCCESS":
    case "SIGNUP_SUCCESS": {
      action.asyncDispatch({
        type: "SET_DEVICE_TOKEN",
        payload: {
          userId: action.payload.data.id,
        },
      });
      return action.payload.data.userType === "CREATOR"
        ? { ...action.payload.data, status: "INCOMPLETE" }
        : action.payload.data;
    }

    case "CREATE_MEETING_FOR_CREATOR_FAIL":
    case "OTP_VALIDATE_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "CREATE_MEETING_FOR_CREATOR_SUCCESS": {
      return { ...state, status: "REVIEWING" };
    }

    case "INVITE_REQUEST_SUCCESS": {
      action.asyncDispatch(addInfo(action.payload?.data?.message, 3000));
      return state;
    }
    case "SIGNUP_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      action.asyncDispatch({
        type: "REMOVE_DEVICE_TOKEN",
        payload: {
          userId: state.id,
        },
      });
      return {};
    }

    case "LOGIN_SUCCESS": {
      action.asyncDispatch({
        type: "SET_DEVICE_TOKEN",
        payload: {
          userId: action.payload.data.id,
        },
      });
      return action.payload.data;
    }

    case "LOGIN_FAIL": {
      action.asyncDispatch({
        type: "REMOVE_DEVICE_TOKEN",
        payload: {
          userId: state.id,
        },
      });
      action.asyncDispatch(addError(action.error.response?.data.message, 3000));

      return {};
    }

    case "UPDATE_CREATOR_SUCCESS": {
      return action.payload.data;
    }

    case "UPDATE_CREATOR_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "REFRESH_SUCCESS": {
      return { ...state, ...action.payload.data };
    }

    case "REFRESH_FAIL": {
      return state;
    }

    case "REFRESH_CLIENT_SUCCESS": {
      return { ...state, ...action.payload.data };
    }

    case "REFRESH_CLIENT_FAIL": {
      return state;
    }

    case "UPDATE_PROFILE_SUCCESS": {
      return action.payload.data;
    }

    case "UPDATE_PROFILE_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "LOGOUT": {
      // action.asyncDispatch({
      //   type: "REMOVE_DEVICE_TOKEN",
      //   payload: {
      //     userId: state.id,
      //   },
      // });
      return {};
    }

    default:
      return state;
  }
};

export default user;
