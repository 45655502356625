import React, { useState } from "react";
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import adjust from "../adjust";
import Avatar from "../avatar";
import Checkbox from "../checkbox";

const TeamProfileComponent = ({
  profile,
  index,
  user,
  disableCheckbox,
  onSelect,
  checked,
}) => {
  return (
    <TouchableOpacity
      onPress={onSelect}
      key={index}
      style={[
        styles.userContainer,
        {
          backgroundColor: profile?.designation
            ? profile?.designation.name == "Partner"
              ? "#000000"
              : profile?.designation.name == "Client"
              ? "#3A8CD1"
              : "#FB953F"
            : "#F7F7F7",
        },
      ]}
    >
      <Avatar
        source={
          profile?.user?.mimType && profile?.user?.image
            ? {
                uri: `data:${profile.user.mimType};base64,${profile.user.image}`,
              }
            : null
        }
        style={{
          borderColor: "#ffffff",
          maxHeight: 40,
          maxWidth: 40,
        }}
        gender={profile.user.gender}
      ></Avatar>

      <View style={{ marginLeft: 5, flex: 1 }}>
        <Text
          style={{
            width: "100%",
            fontSize: adjust(16),
            fontWeight: "bold",
            paddingLeft: 5,
            color: "#ffffff",
            // paddingTop: 10,
          }}
        >
          {["PARTNER", "CLIENT"].includes(user.userType)
            ? profile.user.fName
            : profile.designation.name}
        </Text>

        {user?.userType == "CLIENT" || user?.userType == "PARTNER" ? (
          <Text
            style={{
              width: "100%",
              fontSize: adjust(12),
              color: "#ffffff",
              paddingLeft: 5,
            }}
          >
            {profile?.designation?.name
              ? profile?.designation?.name
              : "Employee"}
          </Text>
        ) : (
          <></>
        )}
      </View>

      {disableCheckbox ? (
        <></>
      ) : (
        <View>
          <Checkbox fill={"#41B000"} value={checked}></Checkbox>
        </View>
      )}
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  data: { flex: 1, fontSize: adjust(13), color: "#606060", marginTop: 5 },
  dataContainer: { flex: 1, marginBottom: 15 },
  userContainer: {
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    minHeight: 55,
    maxHeight: 55,
    padding: 10,
    marginBottom: 5,
  },
});
export default TeamProfileComponent;
