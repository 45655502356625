import React from "react";
import { StyleSheet, Text, Dimensions, View } from "react-native";
import { connect } from "react-redux";
import Header from "../components/header";
import adjust from "../components/adjust";
import DeliverableCard from "../components/deliverable-card";
import {
  addDeliverables,
  downloadDeliverableFile,
} from "../redux/actions/project.action";
import { ScrollView } from "react-native-gesture-handler";

const Deliverables = ({
  navigation,
  user,
  selectedProject,
  addDeliverables,
  downloadDeliverableFile,
}) => {
  return (
    <View
      style={{
        flex: 1,
        maxHeight: Dimensions.get("window").height,
        flexDirection: "column",
      }}
    >
      <View style={[styles.mainContainer]}>
        <Header
          title="Deliverables"
          subtitle="Project Deliverables"
          backFunction={() => navigation.goBack()}
          enableAvatar={true}
          avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          avatarOnPress={() => setSettingsModalVisible(true)}
          navigation={navigation}
        ></Header>
      </View>
      <View
        style={{
          flex: 4,
          padding: 0,
          marginHorizontal: 10,
          marginVertical: 10,
        }}
      >
        {["CREATOR", "EMPLOYEE"].includes(user.userType) ? (
          <View style={{ marginBottom: 10, minHeight: 120 }}>
            <DeliverableCard
              uploadFile={(data) => uploadFile(data)}
              selectedProject={selectedProject}
              type="add"
              deliverable={null}
              addDeliverables={addDeliverables}
            ></DeliverableCard>
          </View>
        ) : (
          <></>
        )}
        <ScrollView nestedScrollEnabled={true}>
          {selectedProject.deliverables?.length > 0 ? (
            selectedProject.deliverables.map((deliverable, index) => {
              return (
                <DeliverableCard
                  key={deliverable._id}
                  type="view"
                  deliverable={deliverable}
                  downloadDeliverableFile={downloadDeliverableFile}
                ></DeliverableCard>
              );
            })
          ) : (
            <></>
          )}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tabHeaderContainer: {
    padding: 10,
    backgroundColor: "white",
    marginBottom: 15,
    borderRadius: 10,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    flexDirection: "column",
  },
  textClass: {
    fontWeight: "bold",
    fontSize: adjust(10),
    color: "gray",
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    marginBottom: 30,
  },
  icon: {
    // marginLeft: 20,
  },
  customRatingBarStyle: {
    flexDirection: "row",
    marginTop: 5,
  },
});

const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});

export default connect(mapStateToProps, {
  addDeliverables,
  downloadDeliverableFile,
})(Deliverables);
