const initialState = [];
const shortlistedCreatorList = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SHORTLISTED_CREATOR_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_SHORTLISTED_CREATOR_FAIL": {
      return state;
    }

    case "SHORTLIST_CREATOR_SUCCESS": {
      return [...state, action.payload.data];
    }

    case "APPROVE_CREATOR_SHORTLISTED_SUCCESS": {
      return [...state, action.payload.data];
    }

    case "APPROVE_CREATOR_PENDING_SUCCESS": {
      return state.filter((e) => e._id != action.payload.data._id);
    }
    
    default:
      return state;
  }
};
export default shortlistedCreatorList;
