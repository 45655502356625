import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import React, { useEffect } from "react";
import Header from "../../components/header";
import DashboardCard from "../../components/dashboardCard";
import ItemWithCountValue from "../../components/itemWithCountValue/itemWithCountValue";
import UserProfileComponent from "../../components/userProfileComponent/userProfileComponent";
import { fetchSalesPerson } from "../../redux/actions/sales-person.action";
import moment from "moment";
import { connect } from "react-redux";
import { abbrNum, fullName } from "../../components/utils";

const AccountSalesTeamScreen = ({
  navigation,
  fetchSalesPerson,
  salesPersons,
  user,
}) => {
  useEffect(() => {
    fetchSalesPerson();
  }, []);

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          enableAvatar={true}
          title="sales team"
          subtitle="All Your active sales personnel"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <DashboardCard
        jsx={
          <TouchableOpacity
            style={{
              height: "90%",
            }}
            onPress={() => {}}
          >
            <Text style={styles.cardTitleStyle}>Sales Performance</Text>
            <Text style={{ color: "#000", marginLeft: 10, marginTop: -10 }}>
              {`tap to see ${moment().format("MMMM")} Performance`}
            </Text>
            <View
              style={{
                flexDirection: "row",
                marginTop: 30,
                justifyContent: "space-between",
              }}
            >
              <ItemWithCountValue
                countValue={
                  salesPersons.reduce((a, b) => a + b.currentMonthCount, 0) ||
                  "0"
                }
                textColor={"#7A9D54"}
                countStyle={{ fontSize: 28 }}
                itemName={"Projects"}
              ></ItemWithCountValue>
              <ItemWithCountValue
                countValue={
                  abbrNum(
                    salesPersons.reduce((a, b) => a + b.revenue, 0),
                    2
                  ) || "0"
                }
                countStyle={{ fontSize: 28 }}
                itemName={"Payouts"}
                textColor={"#7A9D54"}
              ></ItemWithCountValue>
              <ItemWithCountValue
                countValue={abbrNum(
                  salesPersons.reduce((a, b) => a + b.requested, 0),
                  2
                )}
                itemName={"Requested"}
                countStyle={{ fontSize: 28 }}
                textColor={"#7A9D54"}
              ></ItemWithCountValue>
            </View>
          </TouchableOpacity>
        }
        disableIcon={true}
        icon={false}
        style={{
          maxHeight: 150,
          borderWidth: 2,
          borderColor: "#7A9D54",
          minHeight: 150,
        }}
      ></DashboardCard>
      <ScrollView>
        <View style={styles.card_Container}>
          {salesPersons.map((m, i) => {
            return (
              <UserProfileComponent
                key={m._id}
                imageUri={
                  m.mimType && m.image
                    ? { uri: `data:${m.mimType};base64,${m.image}` }
                    : null
                }
                name={fullName(m)}
                description={m.projects + " projects"}
                gender={m.gender}
                onPress={() =>
                  navigation.navigate("sales-earning", {
                    user: m._id,
                    name: fullName(m),
                  })
                }
              ></UserProfileComponent>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const mapStateToProps = ({ user, salesPersons }) => ({
  user,
  salesPersons,
});
export default connect(mapStateToProps, { fetchSalesPerson })(
  AccountSalesTeamScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginHorizontal: 5,
  },
  cardTitleStyle: {
    margin: 10,
    fontSize: 16,
    color: "#000",
    fontWeight: "bold",
  },
  card_Container: {
    margin: 5,
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
