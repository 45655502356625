import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import header from "../../components/header";
import Icon from "../../components/icon";
const CreatorDetailsComponent = ({
  header,
  description,
  icon,
  cross,
  rating,
  subDescription,
  download,
  remove,
  downloadFile,
}) => {
  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        marginTop: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <Text style={styles.header}>{header}</Text>
        {icon ? (
          <View style={{ flexDirection: "row" }}>
            {cross ? (
              <TouchableOpacity onPress={remove}>
                <Icon name="cross"></Icon>
              </TouchableOpacity>
            ) : (
              <></>
            )}
            {download ? (
              <TouchableOpacity
                onPress={downloadFile}
                style={{ marginLeft: 5 }}
              >
                <Icon name="download"></Icon>
              </TouchableOpacity>
            ) : (
              <></>
            )}
          </View>
        ) : (
          <></>
        )}
      </View>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <Text style={styles.desc}>{description}</Text>
        {rating ? <Text style={styles.rating}>{rating}</Text> : <></>}
      </View>
      <View
        style={{
          flexWrap: "wrap",
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            marginTop: 5,
            flex: 9,
          }}
        >
          {subDescription}
        </Text>
        <View style={{ flex: 1 }}></View>
      </View>
    </View>
  );
};

export default CreatorDetailsComponent;

const styles = StyleSheet.create({
  header: {
    fontWeight: "bold",
    color: "#000000",
  },
  desc: {
    fontSize: 13,
    color: "#606060",
    flexWrap: "wrap",
  },
  title: {
    width: "100%",
  },
  subtitle: {
    flexWrap: "wrap",
  },
  rating: {
    marginLeft: 10,
    fontWeight: "bold",
  },
});
