import React, { useEffect, useReducer, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from "react-redux";
import Header from "../components/header";
import { updateFeedback } from "../redux/actions/project.action";
import adjust from "./adjust";
import FeedbackComponent from "./feedback/feedbackComponent";
import { feedbackReducer, initialFeedbackdata } from "../utils/feedbackData";
import { fullName } from "./utils";
import RoundButton from "./roundButton";

const ReviewFeedback = ({
  navigation,
  selectedProject,
  user,
  updateFeedback,
}) => {
  const [feedbackData, setFeedbackData] = useReducer(
    feedbackReducer,
    initialFeedbackdata
  );

  useEffect(() => {
    setFeedbackData({
      type: "ADD_USERS",
      value: selectedProject.team?.filter(
        (x) => x.designation.name != "Client"
      ),
    });
  }, [selectedProject]);

  const generalData = [
    { name: "Experience", description: "Tell us how was the app experience" },
    {
      name: "Response",
      description: "Tell us how was the response from the entire team",
    },
    {
      name: "Service",
      description: "Are you satisfied with the deliverables?",
    },
    {
      name: "Would you refer us?",
      description: "If yes, how likely are you to refer us to someone",
    },
  ];

  const onChangeUserRating = (data) => {
    setFeedbackData({
      type: "ADD_USER_RATING",
      value: data,
    });
  };

  const onChangeUserFeedback = (data) => {
    setFeedbackData({
      type: "ADD_USER_RATING",
      value: data,
    });
  };

  const onChangeGeneralFeedback = (data) => {
    setFeedbackData({
      type: "ADD_GENERAL_RATING",
      value: data,
    });
  };

  const onChangeGeneralRating = (data) => {
    setFeedbackData({
      type: "ADD_GENERAL_RATING",
      value: data,
    });
  };

  const submit = () => {
    updateFeedback(
      { feedback: feedbackData, project: selectedProject._id },
      navigationOnSuccess
    );
  };

  const navigationOnSuccess = () => navigation.goBack();
  return (
    <SafeAreaView style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="project feedback"
          subtitle="Please give feedback to this project"
          backFunction={() => navigation.goBack()}
          enableAvatar={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={[styles.projectTitleContainer, { backgroundColor: "#000" }]}>
        <Text style={styles.mainHeader}>{selectedProject?.title}</Text>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 40 }}
        style={styles.container}
      >
        {selectedProject.team
          ?.filter((x) => x.designation.name != "Client")
          ?.map((member, i) => {
            return (
              <FeedbackComponent
                image={member.user.image ? member.user.image : false}
                key={`${i} + ${Math.random()}`}
                profile={member.designation.name}
                userName={fullName(member.user)}
                user={member.user.userType}
                data={feedbackData.userRatings.find(
                  (x) => x.user === member.user._id
                )}
                onChangeFeedback={(feedback) =>
                  onChangeUserFeedback({
                    user: member.user._id,
                    feedback: feedback,
                  })
                }
                onChangeRating={(rating) =>
                  onChangeUserRating({ user: member.user._id, rating: rating })
                }
              ></FeedbackComponent>
            );
          })}
        {generalData?.map((data, i) => {
          return (
            <FeedbackComponent
              image={false}
              key={`${i} + ${Math.random()}`}
              profile={data.description}
              userName={data.name}
              user={"General"}
              data={feedbackData.generalRatings.find(
                (x) => x.title === data.name
              )}
              onChangeFeedback={(feedback) =>
                onChangeGeneralFeedback({
                  title: data.name,
                  feedback: feedback,
                })
              }
              onChangeRating={(rating) =>
                onChangeGeneralRating({ title: data.name, rating: rating })
              }
            ></FeedbackComponent>
          );
        })}
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <RoundButton
            style={{
              backgroundColor: "#EB4141",
              marginRight: 5,
              minHeight: 35,
              height: "100%",
              width: 120,
            }}
            // textStyle={{ color: "#000" }}
            title={"Cancel"}
            pressFunc={() =>
              setFeedbackData({
                type: "UPDATE_DATA",
                value: initialFeedbackdata,
              })
            }
          ></RoundButton>
          <RoundButton
            style={{
              backgroundColor: "#000",
              marginLeft: 5,
              minHeight: 35,
              height: "100%",
              width: 120,
            }}
            title="Submit"
            pressFunc={() => {
              submit();
            }}
          ></RoundButton>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  titleText: {
    padding: 8,
    fontSize: adjust(16),
    fontWeight: "bold",
  },
  textStyle: {
    fontSize: adjust(23),
    color: "#000",
    marginTop: 15,
    marginLeft: 5,
  },
  textStyleSmall: {
    color: "#828282",
    fontWeight: "bold",
  },
  buttonStyle: {
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 30,
    padding: 15,
    backgroundColor: "#000",
    borderRadius: 30,
  },
  buttonTextStyle: {
    color: "#fff",
    textAlign: "center",
  },
  customRatingBarStyle: {
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 5,
  },
  projectTitleContainer: {
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    margin: 10,
  },
  mainHeader: {
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#ffffff",
    margin: 10,
  },
});

const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});

export default connect(mapStateToProps, {
  updateFeedback,
})(ReviewFeedback);
