import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import BasicDetails from "../../screens/signUp/common/basic-details";
import OtpScreen from "../../screens/signUp/common/otp";
import CreadentialScreen from "../../screens/signUp/common/creadential";
import ImageScreen from "../../screens/signUp/common/image";
import CreatorPrimaryjobScreen from "../../screens/signUp/creatorSignUp/creatorPrimaryjobScreen";
import CreatorAdditionaljobScreen from "../../screens/signUp/creatorSignUp/creatorAdditionaljobScreen";
import CreatorExperienceScreen from "../../screens/signUp/creatorSignUp/creatorExperienceScreen";
import CreatorWorklinkScreen from "../../screens/signUp/creatorSignUp/creatorWorklinkScreen";
import FinalStep from "../../screens/signUp/common/final-step";

const CreatorSignupStack = createStackNavigator();

const CreatorSignUpStack = () => {
  return (
    <CreatorSignupStack.Navigator initialRouteName="basicDetails">
      <CreatorSignupStack.Screen
        name="basicDetails"
        component={BasicDetails}
        options={{ headerShown: false }}
        initialParams={{ stack: "CREATOR" }}
      />
      <CreatorSignupStack.Screen
        name="otpScreen"
        component={OtpScreen}
        options={{ headerShown: false }}
      />
      <CreatorSignupStack.Screen
        name="creadentialScreen"
        component={CreadentialScreen}
        options={{ headerShown: false }}
      />
      <CreatorSignupStack.Screen
        name="imageScreen"
        component={ImageScreen}
        options={{ headerShown: false }}
      />
      <CreatorSignupStack.Screen
        name="creatorPrimaryjobScreen"
        component={CreatorPrimaryjobScreen}
        options={{ headerShown: false }}
      />
      <CreatorSignupStack.Screen
        name="creatorAdditionalJobScreen"
        component={CreatorAdditionaljobScreen}
        options={{ headerShown: false }}
      />
      <CreatorSignupStack.Screen
        name="creatorExperienceScreen"
        component={CreatorExperienceScreen}
        options={{ headerShown: false }}
      />
      <CreatorSignupStack.Screen
        name="creatorWorklinkScreen"
        component={CreatorWorklinkScreen}
        options={{ headerShown: false }}
      />
      <CreatorSignupStack.Screen
        name="finalStep"
        component={FinalStep}
        options={{ headerShown: false }}
      />
    </CreatorSignupStack.Navigator>
  );
};

export default CreatorSignUpStack;
