const initialState = [];
const pendingCreatorList = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PENDING_CREATOR_SUCCESS": {
      return action.payload.data;
    }
    
    case "FETCH_PENDING_CREATOR_FAIL": {
      return state;
    }

    case "APPROVE_CREATOR_ACTIVE_SUCCESS": {
      return state.filter((e) => e._id != action.payload.data._id);
    }

    case "SHORTLIST_CREATOR_SUCCESS": {
      return state.filter((e) => e._id != action.payload.data._id);
    }

    case "APPROVE_CREATOR_SHORTLISTED_SUCCESS": {
      return state.filter((e) => e._id != action.payload.data._id);
    }

    default:
      return state;
  }
};
export default pendingCreatorList;
