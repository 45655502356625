import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import React, { useState, useEffect } from "react";

import Header from "../../../components/header";
import LargeTextInput from "../../../components/largeTextInput";
import RoundButton from "../../../components/roundButton";
import UserProfileComponent from "../../../components/userProfileComponent/userProfileComponent";
import { fetchClients } from "../../../redux/actions/user.action";
import { connect } from "react-redux";
import { addError } from "../../../redux/actions/toast.action";
import { fullName } from "../../../components/utils";

const NewSalesScreen = ({ navigation, clientList, fetchClients, addError }) => {
  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    setRenderData([...clientList]);
  }, [clientList]);

  const [renderData, setRenderData] = useState();
  const [name, setName] = useState("");
  const [selectedClient, setSelectedClient] = useState("");

  const onChangeSearch = (text) => {
    setName(text);
    setRenderData(
      clientList.filter((e) =>
        fullName(e)
          .toLowerCase()
          .includes((text || "").toLowerCase())
      )
    );
  };

  const next = () => {
    if (selectedClient != "") {
      navigation.navigate("create-project", {
        client: selectedClient,
      });
    } else {
      addError("Please select a client!", 3000);
    }
  };
  return (
    <View style={styles.container}>
      <View style={{ maxHeight: 150, minHeight: 150 }}>
        <Header
          title="new Sales"
          subtitle="Please identify the client"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#000000" }}
        ></Header>
      </View>
      <View style={{ backgroundColor: "#fff", flex: 1 }}>
        <Text style={styles.TextStyle}>
          enter client name to start the process
        </Text>
        <View style={{ marginTop: 50 }}>
          <LargeTextInput
            onChangeText={(name) => onChangeSearch(name)}
            placeholder="enter client's first name"
            style={{ borderBottomColor: "#707070", fontSize: 22 }}
          ></LargeTextInput>
          <Text style={styles.Text} onPress={() => {}}>
            couldn't find? May be setup New Client
          </Text>
        </View>
        <ScrollView
          horizontal={true}
          style={{ marginHorizontal: 10, marginVertical: 8 }}
        >
          {renderData?.map((m, i) => {
            return (
              <UserProfileComponent
                key={`${m._id}`}
                gender={m.gender}
                name={`${m.fName} ${m.lName}`}
                description={m.age}
                selected={selectedClient === m._id}
                onPress={() => setSelectedClient(m._id)}
              ></UserProfileComponent>
            );
          })}
        </ScrollView>
        <View style={{ maxHeight: 70 }}>
          <Text style={[styles.bottomText]} onPress={() => {}}>
            +tap to another client to this projects
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: "row", marginBottom: 10 }}>
        <RoundButton
          style={{ backgroundColor: "#000000", width: 100, marginLeft: 30 }}
          title={"Next"}
          pressFunc={() => next()}
        ></RoundButton>
        <RoundButton
          style={{ backgroundColor: "#EB4141", width: 120, marginLeft: 15 }}
          title={"Discard"}
          //pressFunc={}
        ></RoundButton>
      </View>
    </View>
  );
};

const mapStateToProps = ({ clientList }) => ({ clientList });
export default connect(mapStateToProps, { fetchClients, addError })(
  NewSalesScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  TextStyle: {
    fontSize: 25,
    fontWeight: "bold",
    // margin: 25,
    color: "#000",
    marginHorizontal: 30,
  },
  Text: {
    fontSize: 11,
    fontWeight: "bold",
    marginHorizontal: 30,
    color: "#838383",
    marginVertical: 8,
    textDecorationLine: "underline",
  },
  bottomText: {
    fontSize: 15,
    fontWeight: "bold",
    marginHorizontal: 30,
    color: "#838383",
    marginVertical: 8,
    textDecorationLine: "underline",
  },
});
