import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, TextInput } from "react-native";
import adjust from "./adjust";

export default function SmallTextInput({
  placeholder,
  label,
  style,
  smallTextInputStyle,
  onChangeText,
  numberOfLines,
  multiline,
  keyboardType,
  labelStyle,
  value,
  inValidText,
  isValid,
  inValidStyle,
  validationType,
  validationRegex,
  validateNow,
  uploadFile,
  required = false,
  autoFocus,
  editable,
}) {
  const [valid, setValid] = useState(true);
  const [validationReg, setValidationReg] = useState(null);
  useEffect(() => {
    if (validateNow) {
      validate(value);
    }
  }, [validateNow, validationReg]);

  function validate(v) {
    let val = valid;
    if (required && v) {
      if (validationReg) {
        val = validationReg.test(v);
      } else {
        val = true;
      }
    } else if (v) {
      if (validationReg) {
        val = validationReg.test(v);
      }
    } else {
      val = false;
    }

    setValid(val);
    if (isValid) {
      isValid(val);
    }
  }
  useEffect(() => {
    if (validationRegex || validationType)
      setValidationReg(
        validationRegex
          ? new RegExp(validationRegex)
          : validationType === "email"
          ? /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i
          : validationType === "phone"
          ? /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/i
          : validationType === "aadhar"
          ? /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i
          : validationType === "pan"
          ? /^[A-Z]{5}[0-9]{4}[A-Z]{1}/i
          : validationType === "pin"
          ? /^[1-9][0-9]{5}$/i
          : validationType === "numeric"
          ? /^(\d+\.\d+)$|^(\d+)$/i
          : null
      );
  }, [validationRegex, validationType]);
  return (
    <View style={[{ flex: 1, marginLeft: 30 }, smallTextInputStyle]}>
      <Text style={[styles.label, labelStyle]}>{label}</Text>
      {uploadFile ? (
        <View style={[styles.input, style]}>Upload File</View>
      ) : (
        <TextInput
          multiline={multiline}
          placeholder={placeholder}
          placeholderTextColor="#828282"
          style={[
            styles.input,
            style,
            !valid && inValidStyle ? inValidStyle : smallTextInputStyle,
          ]}
          onChangeText={(e) => {
            onChangeText(e);
            validate(e);
          }}
          keyboardType={keyboardType}
          numberOfLines={numberOfLines}
          value={value}
          autoFocus={autoFocus || !valid}
          editable={editable ? editable : true}
        ></TextInput>
      )}

      <Text style={{ color: "red", fontSize: adjust(12), marginTop: 3 }}>
        {!valid && inValidText ? inValidText : ""}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    fontSize: adjust(12),
    width: "90%",
    borderBottomWidth: 1,
    padding: 10,
    paddingLeft: 0,
    color: "#000",
    flex: 1,
    maxHeight: 40,
    minHeight: 40,
    maxWidth: 400,
    textAlignVertical: "bottom",
  },
  label: {
    fontSize: adjust(13),
    fontWeight: "bold",
    marginTop: 5,
  },
});
