import * as React from "react";
import { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import adjust from "../../components/adjust";
import Avatar from "../../components/avatar";
import BudgetPhase from "../../components/budgetPhase";
import Icon from "../../components/icon";
import RoundButton from "../../components/roundButton";
import { setSelectedMember } from "../../redux/actions/creator.action";
import Header from "../../components/header";
import { updatePaymentStatus } from "../../redux/actions/project.action";

const Payments = ({
  navigation,
  selectedProject,
  route,
  selectedMember,
  user,
  setSelectedMember,
  updatePaymentStatus,
}) => {
  const [receivableTab, setReceivableTab] = useState(true);
  const [payableTab, setPayableTab] = useState(false);
  const teamMember = route?.params?.teamMember
    ? route.params.teamMember
    : false;

  const setSelectedMemberAndNavigate = (index) => {
    setSelectedMember(selectedProject.team[index]);
    navigation.navigate("teamPayments", { teamMember: true });
  };

  const payAmount = (payment) => {
    let obj = {
      _id: payment._id,
      swipe: "right",
    };
    if (
      (user._id === payment.receivable && payment.status === "Pending") ||
      (user._id === payment.payable && payment.status === "Requested")
    )
      updatePaymentStatus(obj);
  };
  return (
    <View style={{ flex: 1 }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Back to Project"
          subtitle={""}
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <View style={{ flex: 4, marginHorizontal: 20, marginTop: 20 }}>
        {user.userType == "PARTNER" ? (
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              paddingBottom: 20,
            }}
          >
            <RoundButton
              title={"Receivable"}
              pressFunc={() => {
                setReceivableTab(true);
                setPayableTab(false);
              }}
              textStyle={{
                color: receivableTab ? "#FFF" : "#000",
              }}
              style={{
                backgroundColor: receivableTab ? "#000000" : "#ECECEC",
                borderWidth: 1,
                borderColor: "#000",
              }}
            ></RoundButton>
            <RoundButton
              title={"Payable"}
              pressFunc={() => {
                setPayableTab(true);
                setReceivableTab(false);
              }}
              textStyle={{
                color: payableTab ? "#FFF" : "#000",
              }}
              style={{
                backgroundColor: payableTab ? "#000000" : "#ECECEC",
                borderWidth: 1,
                borderColor: "#000",
                marginLeft: 10,
              }}
            ></RoundButton>
          </View>
        ) : (
          <></>
        )}
        <ScrollView nestedScrollEnabled={true}>
          <View
            style={{
              backgroundColor: "#000000",
              borderRadius: 10,
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Text
              style={{
                fontSize: adjust(16),
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {teamMember
                ? user.userType == "CREATOR"
                  ? selectedProject?.title
                  : selectedMember?.user?.name
                : selectedProject?.title}
            </Text>
          </View>
          {receivableTab ? (
            <View>
              <View style={styles.finalBudgetContainer}>
                <Text style={styles.finalBudgetText}>Budget finalised</Text>
                <Text style={styles.finalammount}>
                  ₹{" "}
                  {teamMember
                    ? user.userType == "CREATOR"
                      ? selectedProject.team
                          ?.find((m) => m.user._id == user._id)
                          .payments?.find((a) => a.type == "FINAL").payment
                          .amount
                      : selectedMember.payments?.find((a) => a.type == "FINAL")
                          .payment.amount
                    : selectedProject.discussedBudget}
                </Text>
                {teamMember ? (
                  <></>
                ) : (
                  <Text style={{ color: "#707070" }}>
                    {selectedProject.paymentPhases.length} Phase Payment
                  </Text>
                )}
              </View>
              {teamMember ? (
                <View style={{ flex: 1, marginTop: 10, marginBottom: 20 }}>
                  <BudgetPhase
                    phase={
                      user.userType == "CREATOR"
                        ? selectedProject.team?.find(
                            (m) => m.user._id == user._id
                          ).payments
                        : selectedMember.payments
                    }
                    teamMember={teamMember}
                  ></BudgetPhase>
                </View>
              ) : (
                selectedProject?.paymentPhases?.map((phase, index) => {
                  return (
                    <View
                      key={index}
                      style={{ flex: 1, marginTop: 10, marginBottom: 20 }}
                    >
                      <BudgetPhase
                        phase={phase}
                        phaseNumber={index + 1}
                      ></BudgetPhase>
                    </View>
                  );
                })
              )}
            </View>
          ) : (
            <></>
          )}
          {payableTab ? (
            selectedProject.team.filter(
              (x) => !["Client", "Partner"].includes(x.designation.name)
            ).length > 0 ? (
              <View
                style={[
                  styles.container,
                  {
                    backgroundColor: "#ffffff",
                    borderRadius: 8,
                    padding: 20,
                    paddingBottom: 10,
                    marginBottom: 10,
                  },
                ]}
              >
                <View>
                  <Text style={styles.finalBudgetText}>Creator Payments</Text>
                </View>

                {selectedProject.team
                  .filter(
                    (x) => !["Client", "Partner"].includes(x.designation.name)
                  )
                  .map((team, index) => {
                    return (
                      <View key={index}>
                        <View
                          style={[
                            styles.userContainer,
                            {
                              backgroundColor: "#F7F7F7",
                              marginBottom: 10,
                            },
                          ]}
                        >
                          <Avatar
                            source={
                              team.user.mimType && team.user.image
                                ? {
                                    uri: `data:${team.user.mimType};base64,${team.user.image}`,
                                  }
                                : require("../../assets/avatar/avatar.png")
                            }
                            style={{
                              borderColor: "#ffffff",
                              maxHeight: 40,
                              maxWidth: 40,
                            }}
                          ></Avatar>
                          <View style={{ marginLeft: 5 }}>
                            <Text
                              style={{
                                width: "100%",
                                fontSize: adjust(10),
                                fontWeight: "bold",
                                paddingLeft: 5,
                                color: "#000000",
                                paddingTop: 5,
                              }}
                            >
                              {team.designation?.name}
                            </Text>
                            <Text
                              style={{
                                width: "100%",
                                fontSize: adjust(16),
                                fontWeight: "bold",

                                paddingTop: 0,
                                paddingLeft: 5,
                                color: "#000000",
                                paddingVertical: 5,
                                textAlignVertical: "center",
                              }}
                            >
                              {team.user?.name}
                            </Text>
                          </View>
                        </View>
                        {/*                  
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: "#B0B0B0",
                        marginBottom: 20,
                      }}
                    >
                      {p.description}
                    </Text> */}
                        {team.payments
                          .filter((p) => p.type != "FINAL" && p?.payment.amount)
                          .map((a, i) => {
                            return (
                              <View
                                key={i}
                                style={{
                                  backgroundColor:
                                    a.payment?.status == "Requested"
                                      ? "#FFFFFF"
                                      : a.payment?.status == "Received"
                                      ? "#41B000"
                                      : "#DCDCDC",
                                  flex: 1,
                                  borderRadius: 10,
                                  marginBottom: 20,
                                  padding: 10,
                                  borderWidth: 1,
                                  borderColor:
                                    a.payment?.status == "Requested"
                                      ? "#FF1010"
                                      : a.payment?.status == "Received"
                                      ? "#41B000"
                                      : "#DCDCDC",
                                }}
                              >
                                <View style={{ flexDirection: "row", flex: 1 }}>
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        a.payment?.status == "Requested"
                                          ? "#FF1010"
                                          : a.payment?.status == "Received"
                                          ? "#FFFFFF"
                                          : "#C3C3C3",
                                    }}
                                  >
                                    {a.type}{" "}
                                  </Text>
                                  {a.payment?.status == "Requested" ? (
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        color: "#FF1010",
                                      }}
                                    >
                                      Requested
                                    </Text>
                                  ) : (
                                    <></>
                                  )}
                                </View>
                                <Text
                                  style={{
                                    fontSize: adjust(40),
                                    fontWeight: "bold",
                                    color:
                                      a.payment?.status == "Requested"
                                        ? "#000000"
                                        : a.payment?.status == "Received"
                                        ? "#FFFFFF"
                                        : "#C3C3C3",
                                  }}
                                >
                                  ₹ {a.payment?.amount}
                                </Text>
                                <Text
                                  style={{
                                    color:
                                      a.payment?.status == "Requested"
                                        ? "#707070"
                                        : a.payment?.status == "Received"
                                        ? "#FFFFFF"
                                        : "#C3C3C3",
                                    fontSize: adjust(13),
                                  }}
                                >
                                  {a.payment?.status == "Received"
                                    ? "Paid"
                                    : a.payment?.status}
                                </Text>
                                {a.payment?.status == "Requested" ? (
                                  <TouchableOpacity
                                    onPress={() => payAmount(a.payment)}
                                    style={{
                                      flex: 1,
                                      borderRadius: 8,
                                      backgroundColor: "#FFD500",
                                      minHeight: 55,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: 5,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: adjust(16),
                                        fontWeight: "bold",
                                        color: "#ffffff",
                                      }}
                                    >
                                      Approve
                                    </Text>
                                  </TouchableOpacity>
                                ) : (
                                  <></>
                                )}
                              </View>
                            );
                          })}
                      </View>
                    );
                  })}
              </View>
            ) : (
              <></>
            )
          ) : (
            /*<View style={styles.container}>
              {/* <Text style={styles.header}>Project Members</Text> 
               {selectedProject.team.map((t, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    style={{ flex: 1 }}
                    onPress={
                      t.designation?.name == "Partner" ||
                      t.designation?.name == "Client" ||
                      !t.designation
                        ? null
                        : () => setSelectedMemberAndNavigate(index)
                    }
                  >
                    <View
                      style={[
                        styles.userContainer,
                        {
                          backgroundColor: t.designation
                            ? t.designation.name == "Partner"
                              ? "#000000"
                              : t.designation.name == "Client"
                              ? "#FFD500"
                              : "#F7F7F7"
                            : "#F7F7F7",
                        },
                      ]}
                    >
                      <Avatar
                        source={
                          user.userType == "PARTNER"
                            ? t.user.mimType || t.user.image
                              ? {
                                  uri: `data:${
                                    t.user.mimType ? t.user.mimType : null
                                  };base64,${
                                    t.user.image ? t.user.image : null
                                  }`,
                                }
                              : require("../../assets/avatar/avatar.png")
                            : null
                        }
                        style={{
                          borderColor: "#ffffff",
                          maxHeight: 40,
                          maxWidth: 40,
                        }}
                      ></Avatar>
                      <View style={{ marginLeft: 5, flex: 1 }}>
                        <Text
                          style={{
                            flex: 1,
                            width: "100%",
                            fontSize: adjust(16),
                            fontWeight: "bold",
                            color:
                              t.designation?.name == "Partner"
                                ? "#ffffff"
                                : "#000000",
                            // paddingTop: 10,
                          }}
                        >
                          {user.userType == "PARTNER"
                            ? t.designation?.name == "Partner" ||
                              t.designation?.name == "Client" ||
                              !t.designation
                              ? t.user.name
                                ? t.user.name
                                : null
                              : t.designation?.name
                            : t.designation?.name}
                        </Text>

                        {user.userType == "CLIENT" ||
                        user.userType == "PARTNER" ? (
                          <Text
                            style={{
                              flex: 1,
                              width: "100%",
                              fontSize: adjust(11),
                              color:
                                t.designation?.name == "Partner"
                                  ? "#ffffff"
                                  : "#000000",
                            }}
                          >
                            {t.designation?.name
                              ? t.designation?.name
                              : "Employee"}
                          </Text>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              })} 
            </View>*/
            <></>
          )}
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({ selectedProject, selectedMember, user }) => ({
  selectedProject,
  selectedMember,
  user,
});
export default connect(mapStateToProps, {
  setSelectedMember,
  updatePaymentStatus,
})(Payments);

const styles = StyleSheet.create({
  titleContainer: {
    backgroundColor: "#000000",
    marginHorizontal: 20,
    borderRadius: 10,
    justifyContent: "center",
    padding: 20,
    flex: 0.2,
  },
  backButton: {
    flex: 1,
    position: "absolute",
    left: 25,
    marginTop: 40,
    flexDirection: "row",
    alignItems: "center",
    zIndex: 999,
  },
  userContainer: {
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    minHeight: 60,
    maxHeight: 60,
    padding: 10,
    marginBottom: 5,
  },
  finalBudgetContainer: {
    flex: 1,
    minHeight: 120,
    maxHeight: 120,
    backgroundColor: "#FFFFFF",
    marginTop: 10,
    borderRadius: 8,
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  finalBudgetText: {
    marginBottom: 10,
    fontWeight: "bold",
    color: "#A9A9A9",
    fontSize: adjust(18),
  },
  subtitle: { fontWeight: "bold" },
  finalammount: {
    fontWeight: "bold",
    fontSize: adjust(30),
  },
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  header: {
    flex: 1,
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginBottom: 5,
  },
  userContainer: {
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    minHeight: 60,
    maxHeight: 60,
    padding: 10,
    marginBottom: 5,
  },
});
