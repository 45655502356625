import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/header";
import LargeButton from "../../components/largeButton";
import RoundButton from "../../components/roundButton";

const VerificationOfUser = ({
  navigation,
  user,
  status = "done",
  onPress,
  onPressNext,
  newClinet,
}) => {
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <View style={styles.headerStyle}>
        <Header
          title={status === "done" ? "get verified" : "waitlist"}
          subtitle="Schedule a meeting for interview"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          avatarSource={{
            uri: `data:${user.mimType};base64,${user.image}`,
          }}
        ></Header>
      </View>
      <ScrollView style={styles.containerStyle}>
        <Text style={styles.titleStyle}>
          {newClinet
            ? "your client needs to complete their profile"
            : status === "pending"
            ? "Schedule a call with our hiring managers"
            : "this shouldn’t take more than a week though"}
        </Text>
        <Text style={styles.status}>
          {newClinet
            ? "Profile Completion Pending"
            : status === "pending"
            ? "Tap to button to schedule"
            : "Pending Acceptance"}
        </Text>
        <Text style={styles.descriptionStyle}>
          {newClinet
            ? "At this point, we have emailed and texted your client that they need to make a profile to start a project with us. You should prompt your client to make it fast."
            : status === "pending"
            ? "We need to understand and assess your skills to understand if you can be a good fit to our remote sales team. Schedule a call by clicking below. Once you have scheduled a call tap “Next” to continue"
            : "We have received your application and it is reviewed by one of our partners. You will get the notification soon. Check back here to know the status."}
        </Text>
        <Text style={[styles.status]}>
          {status === "pending" ? "" : "Status"}
        </Text>
        <View>
          <LargeButton
            changeColorForDisable={status === "review" ? true : false}
            disabled={status === "pending" ? false : true}
            disableIcon={status === "pending" ? false : true}
            titleStyle={{ color: "#FFFFFF", fontSize: 22 }}
            arrowColor={"#FFF"}
            title={
              status === "pending"
                ? "Schedule a Call"
                : status === "review"
                ? "Review pending"
                : status === "shortlisted"
                ? "Shortlisted"
                : "Congrats! You’ve made it"
            }
            backgroundColor={
              status === "pending"
                ? "#F27253"
                : status === "review"
                ? "#B7B7B7"
                : status === "shortlisted"
                ? "#FF973E"
                : "#7A9D54"
            }
          ></LargeButton>
        </View>
        <Text style={{ color: "#000", marginTop: 20, fontSize: 11 }}>
          {newClinet
            ? "once your client has activated their profile and the profile appears on our database, you should see the above button as green and be good to go."
            : status === "pending"
            ? ""
            : `once the above button turns green, you will be able to start off super fast. In case you haven’t been selected, you can reach us for more clarifications `}

          <Text style={{ textDecorationLine: "underline" }} onPress={onPress}>
            here
          </Text>
        </Text>
        <RoundButton
          pressFunc={onPressNext}
          title={
            status === "review" && status === "shortlisted" ? "Wait" : "Next"
          }
          style={{
            backgroundColor: "#000000",
            width: "35%",
            margin: 10,
            marginTop: 50,
          }}
        ></RoundButton>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(VerificationOfUser);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
  containerStyle: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    height: "100%",
    marginHorizontal: 20,
  },
  titleStyle: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#000",
    marginTop: 50,
  },
  status: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#828282",
    marginTop: 20,
    marginLeft: 3,
  },
  descriptionStyle: {
    fontSize: 11,
    color: "#606060",
    marginTop: 15,
  },
});
