import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import {
  View,
  Dimensions,
  AppState,
  FlatList,
  useWindowDimensions,
  Text,
  StyleSheet,
} from "react-native";
import RoundButton from "../../components/roundButton";
import { connect } from "react-redux";
import {
  fetchAllActiveProjects,
  fetchAllClosedProjects,
  setSelectedProject,
} from "../../redux/actions/project.action";
import { setSelectedRequirement } from "../../redux/actions/requirement.action";
import { fetchAllProjects } from "../../redux/actions/newProjects.action";
import { fetchPendingProjects } from "../../redux/actions/pendingProjects.action";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import ProjectInprogressCard from "../../components/projectInprogressCard";
import { primaryColor } from "../../components/utils";

const ProjectList = ({
  user,
  navigation,
  setSelectedProject,
  pendingProjects,
  fetchAllProjects,
  newProjects,
  fetchPendingProjects,
  fetchAllActiveProjects,
  fetchAllClosedProjects,
  closedProjects,
  projects,
}) => {
  const dimension = useWindowDimensions();

  const isFocused = useIsFocused();
  const [pendingTab, setPendingTab] = useState(
    user.userType === "PARTNER" ? true : false
  );
  const appState = useRef(AppState.currentState);

  const setNewProjectAndNavigate = () => {
    navigation.navigate("newProjectDetails");
  };
  const setSelectedProjectAndNavigate = () => {
    if (["CLIENT", "CREATOR"].includes(user.userType)) {
      navigation.navigate("activeProjectDetails");
    } else if (user.userType === "PARTNER") {
      navigation.navigate("projectSetup");
    }
  };

  const setSelectedActiveProjectAndNavigate = (item) => {
    // if (user.userType === "PARTNER") {
    navigation.navigate("activeProjectDetails");
    // }
  };
  const setClosedProjectAndNavigate = () => {
    navigation.navigate("activeProjectDetails");
  };

  useEffect(() => {
    fetchData();
  }, [!pendingProjects, !newProjects, !projects, isFocused]);

  const [renderData, setRenderData] = useState([]);

  useEffect(() => {
    if (user.userType === "CLIENT") {
      setRenderData([
        ...projects.filter((x) => !(x.partner && x.clientCallReceived)),
      ]);
    } else if (
      ["PARTNER", "ONEDESIGN", "SALES", "ACCOUNTANT"].includes(user.userType)
    ) {
      setRenderData([...pendingProjects]);
    } else if (user.userType === "CREATOR") {
      setRenderData([
        ...projects.filter(
          (x) => x.active && x.team.find((y) => y.user._id === user._id)
        ),
      ]);
    }
  }, [projects, pendingProjects]);

  useFocusEffect(
    React.useCallback(() => {
      if (user.userType === "CLIENT") {
        initialSetupForClient();
      } else if (
        ["ACCOUNTANT", "PARTNER", "ONEDESIGN"].includes(user.userType)
      ) {
        initialSetupForAdminOrPartner();
      } else if (user.userType === "CREATOR") {
        initialSetupForCreator();
      } else if (user.userType === "SALES") {
        initialSetupForSales();
      }
    }, [])
  );

  const initialSetupForClient = () => {
    let obj = {
      title: "new",
      onPress: () => updateDataAndNavigation("new"),
      onPressItem: () => setNewProjectAndNavigate(),
    };
    setCurrentTabDetails(obj);
  };

  const initialSetupForCreator = () => {
    let obj = {
      title: "ongoing",

      onPress: () => updateDataAndNavigation("ongoing"),
      onPressItem: () => setSelectedProjectAndNavigate(),
    };
    setCurrentTabDetails(obj);
  };

  const initialSetupForAdminOrPartner = () => {
    let obj = {
      title: "setting up",

      onPress: () => updateDataAndNavigation("setting up"),
      onPressItem: () => setSelectedProjectAndNavigate(),
    };
    setCurrentTabDetails(obj);
  };
  const initialSetupForSales = () => {
    let obj = {
      title: "pending",

      onPress: () => updateDataAndNavigation("pending"),
      onPressItem: () => setSelectedProjectAndNavigate(),
    };
    setCurrentTabDetails(obj);
  };
  useEffect(() => {
    AppState.addEventListener("change", _handleAppStateChange);
    return () => {
      AppState.removeEventListener("change", _handleAppStateChange);
    };
  }, []);
  
  const _handleAppStateChange = (nextAppState) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      setTimeout(() => {
        fetchData();
      }, 10);
    }
    appState.current = nextAppState;
  };

  const fetchData = () => {
    if (
      ["PARTNER", "CREATOR", "ONEDESIGN", "SALES", "ACCOUNTANT"].includes(
        user.userType
      )
    ) {
      fetchAllProjects();
    }
    if (user.userType == "PARTNER" || user.userType == "SALES") {
      fetchPendingProjects();
    }
    fetchAllActiveProjects();
    fetchAllClosedProjects();
  };

  const [currentTabDetails, setCurrentTabDetails] = useState({});
  const updateDataAndNavigation = (key, onPressTab, onPressItem) => {
    let obj = {
      title: key,
      onPressTab: onPressTab,
      onPressItem: onPressItem,
    };
    setCurrentTabDetails(obj);
    setRenderData(
      key === "completed"
        ? [...closedProjects]
        : key === "setting up" || key === "pending"
        ? [...pendingProjects]
        : user.userType === "CREATOR" && key === "ongoing"
        ? [
            ...projects.filter(
              (x) => x.active && x.team.find((y) => y.user._id === user._id)
            ),
          ]
        : [
            ...projects.filter((x) =>
              key === "new"
                ? !(x.partner && x.clientCallReceived)
                : key === "setup"
                ? x.partner && !x.active && x.clientCallReceived
                : x.partner && x.active
            ),
          ]
    );
  };
  const tabItem = [
    {
      title: "pending",
      userType: ["SALES"],
      onPressTab: () => updateDataAndNavigation("pending"),
      onPressItem: () => setNewProjectAndNavigate(),
    },
    {
      title: "new",
      userType: ["CLIENT"],
      onPressTab: () => updateDataAndNavigation("new"),
      onPressItem: () => setNewProjectAndNavigate(),
    },
    {
      title: "setup",
      userType: ["CLIENT"],
      onPressTab: () => updateDataAndNavigation("setup"),
      onPressItem: () => setSelectedProjectAndNavigate(),
    },
    {
      title: "setting up",
      userType: ["PARTNER", "ONEDESIGN", "ACCOUNTANT"],
      onPressTab: () => updateDataAndNavigation("setting up"),
      onPressItem: () => setSelectedProjectAndNavigate(),
    },
    {
      title: "ongoing",
      userType: [
        "CLIENT",
        "PARTNER",
        "CREATOR",
        "ONEDESIGN",
        "SALES",
        "ACCOUNTANT",
      ],
      onPressTab: () => updateDataAndNavigation("ongoing"),
      onPressItem: () => setSelectedActiveProjectAndNavigate(),
    },
    {
      title: "completed",
      userType: [
        "CLIENT",
        "PARTNER",
        "CREATOR",
        "ONEDESIGN",
        "SALES",
        "ACCOUNTANT",
      ],
      onPressTab: () => updateDataAndNavigation("completed"),
      onPressItem: () => setClosedProjectAndNavigate(),
    },
  ];

  return (
    <View
      style={{
        flex: 1,
        maxHeight: Dimensions.get("window").height,
      }}
    >
      <FlatList
        contentContainerStyle={{ paddingHorizontal: 20 }}
        style={{ minHeight: 60, maxHeight: 60 }}
        scrollEnabled
        showsHorizontalScrollIndicator={false}
        data={tabItem.filter((x) => x.userType.includes(user.userType))}
        keyExtractor={(item) => item.title}
        horizontal
        nestedScrollEnabled
        renderItem={({ item }) => (
          <RoundButton
            title={item.title}
            textStyle={{
              color: currentTabDetails.title === item.title ? "#FFF" : "#000",
            }}
            pressFunc={() =>
              updateDataAndNavigation(
                item.title,
                item.onPressTab,
                item.onPressItem
              )
            }
            style={{
              backgroundColor:
                currentTabDetails.title === item.title
                  ? primaryColor(user.userType)
                  : "#FFF",
              borderColor: "#FFF",
              marginBottom: 20,
              marginHorizontal: 5,
              borderWidth: 1,
            }}
            buttonStyle={{
              minHeight: 40,
              maxHeight: 40,
              paddingHorizontal: 20,
            }}
          ></RoundButton>
        )}
      />
      {renderData.length === 0 ? (
        <View style={{ justifyContent: "center", flex: 1 }}>
          <Text style={styles.noProjectTextStyle}>No Projects</Text>
        </View>
      ) : (
        <FlatList
          data={renderData}
          keyExtractor={(item, index) => item.title + " project " + index}
          scrollEnabled={true}
          nestedScrollEnabled
          renderItem={({ item, index }) =>
            currentTabDetails.title == "ongoing" ? (
              <View style={{ marginTop: 5 }}>
                <ProjectInprogressCard
                  key={`${item.title} ${index}`}
                  onPressCard={() => {
                    setSelectedProject(item);
                    currentTabDetails.onPressItem();
                  }}
                  project={item}
                ></ProjectInprogressCard>
              </View>
            ) : (
              <View style={{ marginTop: 5, marginHorizontal: 5 }}>
                <DashboardCardWithArrowAndIcon
                  descriptionTextColor="#000"
                  titleColor="#000"
                  arrowColor={"#000"}
                  backgroundColor="#FFF"
                  key={`${item.title} + ${index}`}
                  title={item.title}
                  description={
                    currentTabDetails.title === "new"
                      ? "Project Not Accepted Yet."
                      : item.about
                  }
                  value={""}
                  icon={false}
                  onPressCard={() => {
                    setSelectedProject({ ...item });
                    currentTabDetails.onPressItem();
                  }}
                  style={{ marginHorizontal: 10, width: dimension.width - 30 }}
                ></DashboardCardWithArrowAndIcon>
              </View>
            )
          }
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  noProjectTextStyle: {
    alignSelf: "center",
    fontSize: 40,
    fontWeight: "bold",
    bottom: 20,
    color: "#B8B8B8",
  },
});
const mapStateToProps = ({
  projects,
  requirements,
  pendingProjects,
  newProjects,
  closedProjects,
}) => ({
  projects,
  requirements,
  pendingProjects,
  newProjects,
  closedProjects,
});
export default connect(mapStateToProps, {
  setSelectedProject,
  setSelectedRequirement,
  fetchAllProjects,
  fetchPendingProjects,
  fetchAllActiveProjects,
  fetchAllClosedProjects,
})(ProjectList);
