import { addError } from "../actions/toast.action";

const initialState = { onBoard: [] };
const userStats = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SALES_STATS_SUCCESS":
    case "FETCH_CLIENT_STATS_SUCCESS":
    case "FETCH_ACCOUNTANT_STATS_SUCCESS":
    case "FETCH_ADMIN_STATS_SUCCESS": {
      return action.payload.data;
    }

    case "FETCH_SALES_STATS_FAIL":
    case "FETCH_CLIENT_STATS_FAIL":
    case "FETCH_ACCOUNTANT_STATS_FAIL":
    case "FETCH_ADMIN_STATS_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "LOGOUT": {
      return { onBoard: [] };
    }
    default:
      return state;
  }
};
export default userStats;
