import { addError } from "../actions/toast.action";

const initialState = [];
const salesPromotionEntries = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SALES_PROMOTION_ENTRY_SUCCESS": {
      return action.payload.data;
    }

    case "CREATE_SALES_PROMOTION_ENTRY_SUCCESS": {
      let skill = state.find((a) => a._id == action.payload.data._id);
      return skill
        ? state.map((p) => (p._id == skill._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "FETCH_SALES_PROMOTION_ENTRY_FAIL":
    case "CREATE_SALES_PROMOTION_ENTRY_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default salesPromotionEntries;
