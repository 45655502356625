import * as React from "react";
import { Text, View, StyleSheet, TouchableOpacity, Image } from "react-native";
import adjust from "./adjust";
import Avatar from "./avatar";
import Icon from "../components/icon";

const DiscussionCard = ({
  discussion,
  user,
  navigation,
  projects,
  setSelectedProject,
}) => {
  return (
    <TouchableOpacity
      onPress={() => {
        setSelectedProject({
          ...projects.find((x) => x._id === discussion.project._id),
        });
        navigation.navigate("chat");
      }}
      style={[
        styles.container,
        {
          backgroundColor: discussion.userMessageMap.find(
            (x) => x.user === user._id && x.seen
          )
            ? "white"
            : "#FFD500",
          padding: 20,
        },
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 5,
        }}
      >
        <Text style={styles.title}>{discussion.project.title}</Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          paddingVertical: 10,
          paddingRight: 5,
          alignItems: "center",
        }}
      >
        {discussion.from.image ? (
          <Avatar
            source={{
              uri: `data:${discussion.from.mimType};base64,${discussion.from.image}`,
            }}
            style={styles.headerAvatar}
          ></Avatar>
        ) : (
          <Icon name="yellowCircle"></Icon>
        )}
        <Text style={{ paddingLeft: 5, paddingRight: 15 }}>
          <Text>{discussion.from.name}:</Text>
          {discussion.msg || discussion.files[0]?.originalFileName}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  title: {
    fontSize: adjust(20),
    fontWeight: "bold",
  },
  headerAvatar: {
    borderWidth: 1.5,
    height: 30,
    width: 30,
  },
  container: {
    padding: 10,
    minHeight: 100,
    marginBottom: 15,
    borderRadius: 10,
  },
});
export default DiscussionCard;
