export const fetchRazorPayKey = () => (dispatch) => {
  return dispatch({
    type: "FETCH_RAZORPAY_KEY",
    payload: {
      request: {
        url: `/api/static-info/findByKey/RAZOR_PAY_KEY`,
        method: "GET",
      },
    },
  });
};
