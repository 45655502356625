import React, { useState } from "react";
import { Text, TouchableWithoutFeedback, View } from "react-native";
import Icon from "../icon";
import Modal from "../modal/modal";
import RoundButton from "../roundButton";
import { BlurView } from "expo-blur";
import adjust from "../adjust";
import Checkbox from "../checkbox";
const NewProjectModal = ({
  showModal,
  onSelection,
  navigation,
  projectSuccess,
  pressFunc,
}) => {
  const [modalVisible, setModalVisible] = useState({ showModal });
  const closeModal = () => {
    onSelection(false);
    setModalVisible(true);
  };

  return (
    // <View style={{ flex: 1 }}>
    <Modal
      animationType="fade"
      transparent={true}
      presentationStyle="overFullScreen"
      visible={showModal}
      ariaHideApp={false}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          if (!projectSuccess) closeModal();
        }}
      >
        <BlurView
          intensity={80}
          tint="dark"
          style={{
            flex: 1,
            height: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 22,
            backgroundColor: "#fff",
            justifyContent: "center",
          }}
          blurType="dark"
          blurAmount={32}
          blurRadius={100}
        >
          {projectSuccess ? (
            <View
              style={{
                flex: 1,
                maxHeight: 240,
                minHeight: 240,
                width: "90%",
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
                maxWidth: 400,
                padding: 20,
              }}
            >
              <View style={{ alignItems: "center", flex: 0.5 }}>
                <Checkbox
                  fill={"#41B000"}
                  disable={true}
                  value={true}
                ></Checkbox>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(18),
                    color: "#000000",
                  }}
                >
                  Understood!
                </Text>
              </View>
              <Text
                style={{
                  flex: 1,
                  color: "#707070",
                  maxWidth: 300,
                  flexWrap: "wrap",
                  marginTop: 30,
                  fontSize: 13,
                  marginLeft: 5,
                  textAlign: "center",
                }}
              >
                {
                  "Thank you for telling us all these things. Let’s get you started quickly!"
                }
              </Text>
              <RoundButton
                title="Proceed to Dashboard"
                style={{
                  alignSelf: "center",
                  backgroundColor: "#000000",
                  width: "100%",
                  marginBottom: 10,
                  borderRadius: 50,
                  flex: 1,
                }}
                pressFunc={pressFunc}
              ></RoundButton>
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                maxHeight: 220,
                minHeight: 220,
                width: "90%",
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
                maxWidth: 400,
                padding: 20,
              }}
            >
              <View style={{ flexDirection: "row", flex: 0.5 }}>
                <Icon name="box3D"></Icon>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(18),
                    marginLeft: 10,
                    color: "#A9A9A9",
                  }}
                >
                  Welcome to Onedesign
                </Text>
              </View>
              <Text
                style={{
                  marginBottom: 10,
                  flex: 1,
                  color: "#202020",
                }}
              >
                It's simple to get started with us. All you need to do is Click
                on the button below and we will start your onboarding process
              </Text>
              <RoundButton
                title="Start a New Project"
                style={{
                  alignSelf: "center",
                  width: "100%",
                  marginBottom: 10,
                  borderRadius: 50,
                }}
                buttonStyle={{
                  padding: 20,
                }}
                pressFunc={() => {
                  navigation.navigate("requestProject");
                  closeModal();
                }}
              ></RoundButton>
            </View>
          )}
        </BlurView>
      </TouchableWithoutFeedback>
    </Modal>
    // </View>
  );
};
export default NewProjectModal;
