import {
  StyleSheet,
  Text,
  View,
  Pressable,
  TouchableOpacity,
} from "react-native";
import React from "react";

const NextAndDiscardButton = ({
  onPressNext,
  onPressDiscard,
  nextButtonStyle,
  discardButtonStyle,
  style,
  firstButtonName,
  onPressSubmit,
}) => {
  return (
    <View style={style || [styles.container]}>
      <TouchableOpacity
        style={[styles.pressableStyle]}
        onPress={onPressSubmit || onPressNext}
      >
        <Text style={[styles.nextButtonStyle, nextButtonStyle]}>
          {firstButtonName || "Next"}
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[styles.pressableStyle, { backgroundColor: "#EB4141" }]}
        onPress={onPressDiscard}
      >
        <Text style={[styles.discardButtonStyle, discardButtonStyle]}>
          {"Discard"}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default NextAndDiscardButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    margin: 15,
    marginVertical: 25,
    flex: 1,
    alignItems: "flex-end",
  },
  nextButtonStyle: {
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    alignSelf: "center",
    justifyContent: "center",
  },
  discardButtonStyle: {
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    alignSelf: "center",
    justifyContent: "center",
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginHorizontal: 10,
    marginVertical: 10,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
