import React from "react";
import firebase from "firebase/app";
import "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyCK94aC1DXSTRujVyf0P9f1MaXuLhBhq-A",
  authDomain: "onedesign-51ed2.firebaseapp.com",
  projectId: "onedesign-51ed2",
  storageBucket: "onedesign-51ed2.appspot.com",
  messagingSenderId: "618425920227",
  appId: "1:618425920227:web:764c4835074fb60a3d73d6",
  measurementId: "G-X713X9DM2K",
  databaseURL: "https://onedesign-51ed2-default-rtdb.firebaseio.com",
};
try {

  firebase.initializeApp(firebaseConfig);
}catch (error) {
  console.log("Firebase initialization error", error.stack);
}

const messaging = firebase?.messaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export const getToken = async (setTokenFound) => {
  try {
    const currentToken = await messaging.getToken({
      vapidKey:
        "BHh-WXWyO8q_QC6biAg4EEnBQgpaKbVyDUfJt5MB22reMU6zchBdlRxGEHwmbKjyv2_Sj1OTsuP4H5k9aiy-_vI",
    });
    if (currentToken) {
      setTokenFound(true);
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      setTokenFound(false);
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const Firebase = () => {
  return <></>;
};
