import React from "react";
import { usestate } from "react";
import { Image } from "react-native";
import { View, StyleSheet } from "react-native";
const Avatar = ({ source, style, gender }) => {
  // console.log(source);
  return (
    <View>
      <Image
        style={[styles.Image, style]}
        source={
          source
            ? source
            : gender === "Female"
            ? require("../assets/avatar/avatar-female.png")
            : require("../assets/avatar/avatar.png")
        }
      ></Image>
    </View>
  );
};
const styles = StyleSheet.create({
  Image: {
    width: 100,
    height: 100,
    borderRadius: 100 / 2,
    borderWidth: 3,
    borderColor: "#ffd500",
  },
});
export default Avatar;
