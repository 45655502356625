const initialState = [];
const helpMessagesForAdmin = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_HELP_BY_ADMIN_SUCCESS": {
            return action.payload.data;
        }

        case "FETCH_HELP_BY_ADMIN_FAIL": {
            action.asyncDispatch(addError(action.error.response?.data.message, 3000));
            return state;
        }

        case "LOGOUT": {
            return [];
        }

        default:
            return state;
    }
};
export default helpMessagesForAdmin;
