const initialState = [];
const welcomeMessages = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_WELCOME_MESSAGE_SUCCESS": {
      return action.payload.data;
    }
    case "CREATE_WELCOME_MESSAGE_SUCCESS": {
      let message = state.find((a) => a._id == action.payload.data._id);
      return message
        ? state.map((p) => (p._id == message._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "REMOVE_WELCOME_MESSAGE_SUCCESS": {
      let index = state.findIndex((x) => x._id === action.payload.data._id);
      state.splice(index, 1);
      return [...state];
    }

    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default welcomeMessages;
