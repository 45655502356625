import React, { useEffect, useState, Dimensions } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import NewProjectDetails from "../../screens/common/newProjectDetails";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import ProjectPayment from "../../screens/creator/activeProjectDetails/projectPayment";
import ActiveProjectSummary from "../../screens/creator/activeProjectSummary/activeProjectSummary";
import ProjectStatus from "../../screens/Client/projectStatus";
import discussionGroups from "../../screens/common/inbox_screens/discussionGroups";
import deliverables from "../../screens/deliverables";
import Meetings from "../../screens/common/meeting/meetings";

import MyProjects from "../../screens/common/myProjects";

const SalesStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({}) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="employee"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <SalesStack.Navigator initialRouteName="myProject" name="sales">
      <SalesStack.Screen
        name="myProject"
        component={MyProjects}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="activeProjectDetails"
        component={ActiveProjectDetails}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="newProjectDetails"
        component={NewProjectDetails}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="projectPayment"
        component={ProjectPayment}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="activeProjectSummary"
        component={ActiveProjectSummary}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="projectStatus"
        component={ProjectStatus}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="discussionGroups"
        component={discussionGroups}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="deliverables"
        component={deliverables}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="myMeetings"
        component={Meetings}
        options={{ headerShown: false }}
      />
      {/* <SalesStack.Screen
        name="newProjectDetails "
        component={newProjectDetails}
        options={{ headerShown: false }}
      /> */}
    </SalesStack.Navigator>
  );
};
