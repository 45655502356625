import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import adjust from "../../../../components/adjust";
import Avatar from "../../../../components/avatar";
import Icon from "../../../../components/icon";
const Summary = ({ navigation, selectedProject, validate }) => {
  const [selectedPhase, setSelectedPhase] = useState(
    selectedProject.paymentPhases ? selectedProject.paymentPhases[0] : null
  );
  useEffect(() => {
    validate(true);
  });
  return (
    <ScrollView
      nestedScrollEnabled={true}
      style={{
        flex: 1,
        marginHorizontal: 20,
        paddingBottom: 20,
      }}
    >
      <View
        style={[
          styles.container,
          { marginTop: 10, backgroundColor: "#FFD500" },
        ]}
      >
        <Text style={styles.paymentText}>Project Overview</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.header}>Project Brief</Text>
        <View style={styles.dataContainer}>
          <Text style={styles.label}>Project Category</Text>
          <Text style={styles.data}>
            {selectedProject.class
              ? selectedProject.class.name
              : "Not Available"}
          </Text>
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.label}>Mood</Text>
          <Text style={styles.data}>
            {selectedProject.mood ? selectedProject.mood : "Not Available"}
          </Text>
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.label}>Audience</Text>
          <Text style={styles.data}>
            {selectedProject.audience
              ? selectedProject.audience.name
              : "Not Available"}
          </Text>
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.label}>Client's Brief</Text>
          <Text style={styles.data}>
            {selectedProject.requirement &&
            selectedProject.requirement.length > 0
              ? selectedProject.requirement.map((x) => x.name).join(",")
              : "Not Available"}
          </Text>
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.label}>About the Client</Text>
          <Text style={styles.data}>
            {selectedProject.clientAbout
              ? selectedProject.clientAbout
              : "Not Available"}
          </Text>
        </View>
      </View>
      <View style={styles.container}>
        <Text style={styles.header}>Payment</Text>
        <View style={styles.dataContainer}>
          <Text style={styles.label}>Final Budget</Text>
          <Text style={styles.data}>
            {selectedProject.discussedBudget
              ? selectedProject.discussedBudget
              : "Not Available"}
          </Text>
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.label}>Payment Schedule</Text>
          <Text style={styles.data}>
            {selectedProject.engagement
              ? selectedProject.engagement.name
              : "Not Available"}
          </Text>
        </View>
      </View>
      <View style={styles.container}>
        <Text style={styles.header}>Project Members</Text>
        {selectedProject.team.map((t, index) => {
          return (
            <View
              key={index}
              style={[
                styles.userContainer,
                {
                  backgroundColor: t
                    ? t.designation
                      ? t.designation.name == "Partner"
                        ? "#000000"
                        : t.designation.name == "Client"
                        ? "#FFD500"
                        : "#F7F7F7"
                      : "#F7F7F7"
                    : "#F7F7F7",
                },
              ]}
            >
              <Avatar
                source={{
                  uri: `data:${t.user?.mimType};base64,${t.user?.image}`,
                }}
                style={{ borderColor: "#ffffff", maxHeight: 40, maxWidth: 40 }}
              ></Avatar>
              <View style={{ marginLeft: 5 }}>
                <Text
                  style={{
                    fontSize: adjust(16),
                    fontWeight: "bold",
                    color: t
                      ? t.designation
                        ? t.designation.name == "Partner"
                          ? "#ffffff"
                          : "#000000"
                        : "#000000"
                      : "#000000",
                  }}
                >
                  {t.user && t.user.name ? t.user.name : null}
                </Text>
                <Text
                  style={{
                    fontSize: adjust(15),
                    color: t
                      ? t.designation
                        ? t.designation.name == "Partner"
                          ? "#ffffff"
                          : "#000000"
                        : "#000000"
                      : "#000000",
                  }}
                >
                  {t.designation ? t.designation.name : null}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={styles.container}>
        <Text style={styles.header}>Project Phase</Text>
        <View
          style={{ flexDirection: "row", flexWrap: "wrap", marginBottom: 5 }}
        >
          {selectedProject.paymentPhases.map((p, i) => {
            return (
              <TouchableOpacity
                key={i}
                style={{
                  borderRadius: 20,
                  alignItems: "center",
                  marginRight: 10,
                }}
                onPress={() =>
                  setSelectedPhase(selectedProject.paymentPhases[i])
                }
              >
                <Text
                  style={{
                    backgroundColor: "#707070",
                    borderRadius: 5,
                    flex: 1,
                    padding: 5,
                    paddingHorizontal: 10,
                    fontSize: adjust(15),
                    color: "#ffffff",
                  }}
                >
                  {p.description}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        {selectedPhase ? (
          selectedPhase.steps.map((s, i) => {
            return (
              <View key={i} style={{ flex: 1 }}>
                <Text style={{ fontSize: adjust(16), fontWeight: "bold" }}>
                  {s.name}
                </Text>
                <Text style={{ fontSize: adjust(15), marginBottom: 10 }}>
                  {s.description}
                </Text>
              </View>
            );
          })
        ) : (
          <></>
        )}
      </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    marginBottom: 10,
  },
  header: {
    flex: 1,
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginBottom: 20,
  },
  subHeader: { flex: 1, fontSize: adjust(15), color: "#606060" },
  projectBriefText: {
    flex: 1,
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginBottom: 5,
  },
  label: {
    flex: 1,
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#000000",
  },
  data: {
    flex: 1,
    fontSize: adjust(15),
    color: "#606060",
    marginTop: 5,
  },
  dataContainer: { flex: 1, marginBottom: 18 },
  userContainer: {
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    minHeight: 60,
    maxHeight: 60,
    padding: 10,
    marginBottom: 5,
  },
  paymentText: {
    flex: 1,
    fontWeight: "bold",
    fontSize: adjust(18),
    color: "#000",
  },
});
export default Summary;
