import { View, Text, StyleSheet, Pressable } from "react-native";
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import Header from "../../../components/header";
import UserSignUpFields from "../../../components/SignUpComponents/userSignUpCredentialFields";
import { updateProfile } from "../../../redux/actions/user.action";
import { addError } from "../../../redux/actions/toast.action";
import { primaryColor } from "../../../components/utils";

const ClientCreadentialScreen = ({
  navigation,
  updateProfile,
  user,
  addError,
  route,
}) => {
  const initData = {
    userName: "",
    password: "",
    confirmPassword: "",
  };
  const [credential, setCredential] = useState(initData);

  const onChangeText = (data) => {
    setCredential({ ...credential, ...data });
  };
  const next = () => {
    if (!credential.userName && credential.userName == "") {
      addError("Please provide a valid username!", 3000);
      return;
    } else if (!credential.password && credential.password == "") {
      addError("Please provide a valid password!", 3000);
      return;
    } else if (credential.password != credential.confirmPassword) {
      addError("Passwords did not match", 3000);
      return;
    } else {
      updateProfile({ ...credential, email: user.email }, navigateAfterSuccess);
    }
  };
  const navigateAfterSuccess = () => {
    navigation.navigate("imageScreen");
  };
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Basic Details"
          subtitle="Please setup your credentials"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{
            color: primaryColor(user.userType) || route.params.stack,
          }}
        ></Header>
      </View>
      <UserSignUpFields onChangeText={onChangeText} />
      <Pressable style={[styles.pressableStyle]} onPress={() => next()}>
        <Text
          style={[
            {
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: "bold",
              alignSelf: "center",
              justifyContent: "center",
            },
          ]}
        >
          {"Next"}
        </Text>
      </Pressable>
    </View>
  );
};
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#FFF" },
  buttonStyle: {
    height: 70,
    marginVertical: 5,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
    marginBottom: 20,
  },
});
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { updateProfile, addError })(
  ClientCreadentialScreen
);
