import * as React from "react";
import { useState } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import adjust from "../../components/adjust";
import BudgetPhase from "../../components/budgetPhase";
const TeamPayments = ({
  navigation,
  selectedProject,
  route,
  selectedMember,
  user,
}) => {
  const teamMember = route?.params?.teamMember
    ? route.params.teamMember
    : false;

  return (
    <View style={{ flex: 1, paddingTop: 35 }}>
      <View style={styles.titleContainer}>
        <Text
          style={{ fontSize: adjust(16), fontWeight: "bold", color: "#fff" }}
        >
          {teamMember
            ? user.userType == "CREATOR"
              ? selectedProject?.title
              : selectedMember?.user?.name
            : selectedProject?.title}
        </Text>
      </View>
      <View style={{ flex: 4, marginHorizontal: 20 }}>
        <ScrollView nestedScrollEnabled={true}>
          <View>
            <View style={styles.finalBudgetContainer}>
              <Text style={styles.finalBudgetText}>Budget finalised</Text>
              <Text style={styles.finalammount}>
                ₹{" "}
                {teamMember
                  ? user.userType == "CREATOR"
                    ? selectedProject.team
                        ?.find((m) => m.user._id == user._id)
                        .payments?.find((a) => a.type == "FINAL").payment.amount
                    : selectedMember.payments?.find((a) => a.type == "FINAL")
                        .payment.amount
                  : selectedProject.discussedBudget}
              </Text>
              {teamMember ? (
                <></>
              ) : (
                <Text style={{ color: "#707070" }}>
                  {selectedProject.paymentPhases.length} Phase Payment
                </Text>
              )}
            </View>
            {teamMember ? (
              <View style={{ flex: 1, marginTop: 10, marginBottom: 20 }}>
                <BudgetPhase
                  phase={
                    user.userType == "CREATOR"
                      ? selectedProject.team?.find(
                          (m) => m.user._id == user._id
                        ).payments
                      : selectedMember.payments
                  }
                  teamMember={teamMember}
                ></BudgetPhase>
              </View>
            ) : (
              selectedProject?.paymentPhases?.map((phase, index) => {
                return (
                  <View
                    key={index}
                    style={{ flex: 1, marginTop: 10, marginBottom: 20 }}
                  >
                    <BudgetPhase
                      phase={phase}
                      phaseNumber={index + 1}
                    ></BudgetPhase>
                  </View>
                );
              })
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({ selectedProject, selectedMember, user }) => ({
  selectedProject,
  selectedMember,
  user,
});
export default connect(mapStateToProps, {})(TeamPayments);

const styles = StyleSheet.create({
  titleContainer: {
    backgroundColor: "#000000",
    marginHorizontal: 20,
    borderRadius: 10,
    justifyContent: "center",
    padding: 10,
    paddingVertical: 20,
  },
  backButton: {
    flex: 1,
    position: "absolute",
    left: 25,
    marginTop: 40,
    flexDirection: "row",
    alignItems: "center",
    zIndex: 999,
  },
  userContainer: {
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    minHeight: 60,
    maxHeight: 60,
    padding: 10,
    marginBottom: 5,
  },
  finalBudgetContainer: {
    flex: 1,
    minHeight: 120,
    maxHeight: 120,
    backgroundColor: "#FFFFFF",
    marginTop: 10,
    borderRadius: 8,
    justifyContent: "center",
    padding: 20,
  },
  finalBudgetText: {
    marginBottom: 10,
    fontWeight: "bold",
    color: "#A9A9A9",
  },
  subtitle: { fontWeight: "bold" },
  finalammount: {
    fontWeight: "bold",
    fontSize: adjust(30),
  },
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  header: {
    flex: 1,
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginBottom: 5,
  },
});
