import * as React from "react";
import { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import Header from "../../components/header";
import Icon from "../../components/icon";
import SmallTextInput from "../../components/smallTextInput";
import PopUp from "../../components/popUp/popUp";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/user.action";
import { createProject } from "../../redux/actions/project.action";
import adjust from "../../components/adjust";
const BusinessDetails = ({
  navigation,
  user,
  selectedProject,
  createProject,
}) => {
  const [, setSettingsModalVisible] = React.useState(false);
  const [industry, setIndustry] = useState(
    selectedProject.industry ? selectedProject.industry : ""
  );
  const [yearInBusiness, setYearInBusiness] = useState(
    selectedProject.yearInBusiness ? selectedProject.yearInBusiness : ""
  );
  const [businessName, setBusinessName] = useState(
    selectedProject.businessName ? selectedProject.businessName : ""
  );
  const [productOrServiceName, setProductOrServiceName] = useState(
    selectedProject.productOrServiceName
      ? selectedProject.productOrServiceName
      : ""
  );
  const [businessStage, setBusinessStage] = useState(
    selectedProject.businessStage ? selectedProject.businessStage : ""
  );
  const [validateNow, setValidateNow] = useState(false);
  const [industryValid, setIndustryValid] = useState(false);
  const [yearInBusinessValid, setYearInBusinessValid] = useState(false);
  const [businessNameValid, setBusinessNameValid] = useState(false);
  const handleCallback = (childData) => {
    setBusinessStage(childData);
  };
  const updateUserAndNavigate = () => {
    let projectObj = {
      _id: selectedProject._id,
      industry: industry,
      yearInBusiness: yearInBusiness,
      businessName: businessName,
      productOrServiceName: productOrServiceName,
      businessStage: businessStage,
    };
    createProject(projectObj).then(() => {
      navigation.navigate("projectDetails");
    });
  };
  useEffect(() => {
    if (industry && yearInBusiness && businessName) {
      setValidateNow(true);
    }
  });
  const businessStages = {
    businessStages: [
      { _id: "Ideation", name: "Ideation" },
      { _id: "Concept", name: "Concept" },
      { _id: "Testing", name: "Testing" },
      { _id: "Sales", name: "Sales" },
    ],
  };
  return (
    <View style={{ flex: 1 }}>
      <Header
        title="Sign-up"
        subtitle="Please follow the Steps"
        backFunction={() => navigation.goBack()}
        enableAvatar={user.image ? user.image : false}
        avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
        avatarOnPress={() => setSettingsModalVisible(true)}
        disableProfilePage={true}
      ></Header>
      <View style={{ flex: 4, backgroundColor: "#FAFAFA" }}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: adjust(15),
            color: "#828282",
            marginTop: 20,
            marginLeft: 30,
          }}
        >
          Business Details
        </Text>
        <ScrollView style={{ flex: 4, marginTop: 40 }}>
          <SmallTextInput
            label="Business Industry:"
            placeholder="Industry Name: "
            onChangeText={(e) => setIndustry(e)}
            required={true}
            inValidText="Please enter Industry Name"
            validateNow={validateNow}
            isValid={(v) => {
              setIndustryValid(v);
            }}
            value={industry}
          ></SmallTextInput>
          <SmallTextInput
            label="Years In Business"
            placeholder="Years:"
            onChangeText={(e) => setYearInBusiness(e)}
            required={true}
            keyboardType="numeric"
            inValidText="Please enter Year"
            validateNow={validateNow}
            isValid={(v) => {
              setYearInBusinessValid(v);
            }}
            value={yearInBusiness}
          ></SmallTextInput>

          <SmallTextInput
            label="Business Name"
            placeholder="Name:"
            onChangeText={(e) => setBusinessName(e)}
            required={true}
            inValidText="Please enter Business Name"
            validateNow={validateNow}
            isValid={(v) => {
              setBusinessNameValid(v);
            }}
            value={businessName}
          ></SmallTextInput>
          <SmallTextInput
            label="Product/Services If any"
            placeholder="Product/Service Name:"
            onChangeText={(e) => setProductOrServiceName(e)}
            // inValidText="Please enter Product/Service Name"
            // validateNow={validateNow}
          ></SmallTextInput>
          <PopUp
            style={{ marginLeft: 30, marginBottom: 30 }}
            label="Business Stage"
            placeholder="Select"
            renderData={businessStages.businessStages}
            onSelection={handleCallback}
          ></PopUp>

          <View
            style={{
              flex: 0.5,
              marginLeft: 30,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View style={{ flexDirection: "row", flex: 5 }}>
              <Icon name="paginationDotInactive"></Icon>
              <Icon name="paginationDotInactive"></Icon>
              <Icon name="paginationDotActive"></Icon>
              <Icon name="paginationDotInactive"></Icon>
            </View>
            <TouchableOpacity
              onPress={() => {
                if (industryValid && yearInBusinessValid && businessNameValid) {
                  updateUserAndNavigate();
                } else {
                  setValidateNow(true);
                }
              }}
              style={{
                flex: 1,
                marginBottom: 20,
              }}
            >
              <Icon
                name="rightChevronArrowWithCircle"
                fill={
                  industryValid && yearInBusinessValid && businessNameValid
                    ? "#000000"
                    : "#E9E9E9"
                }
              ></Icon>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, { logout, createProject })(
  BusinessDetails
);
