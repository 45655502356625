export const fetchCatalog = () => (dispatch) => {
  return dispatch({
    type: "FETCH_PROJECT_BY_ADMIN",
    payload: {
      request: {
        url: `/api/project-catalog/fetch`,
        method: "GET",
      },
    },
  });
};

export const createCatalog = (projectObj) => (dispatch) => {
  return dispatch({
    type: "CREATE_PROJECT_BY_ADMIN",
    payload: {
      request: {
        url: `/api/project-catalog/create`,
        method: "POST",
        data: projectObj,
      },
    },
  });
};

export const removeCatalog = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_PROJECT_BY_ADMIN",
    payload: {
      request: {
        url: `/api/project-catalog/${id}`,
        method: "DELETE",
      },
    },
  });
};
