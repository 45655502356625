import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { connect } from "react-redux";
import BasicDetails from "../../screens/signUp/common/basic-details";
import OtpScreen from "../../screens/signUp/common/otp";
import CreadentialScreen from "../../screens/signUp/common/creadential";
import ImageScreen from "../../screens/signUp/common/image";
import FinalStep from "../../screens/signUp/common/final-step";
const ClientSignupStack = createStackNavigator();

const ClientSignUpStack = () => {
  return (
    <ClientSignupStack.Navigator initialRouteName="basicDetails">
      <ClientSignupStack.Screen
        name="basicDetails"
        component={BasicDetails}
        options={{ headerShown: false }}
        initialParams={{ stack: "CLIENT" }}
      />
      <ClientSignupStack.Screen
        name="otpScreen"
        component={OtpScreen}
        options={{ headerShown: false }}
      />
      <ClientSignupStack.Screen
        name="creadentialScreen"
        component={CreadentialScreen}
        options={{ headerShown: false }}
      />
      <ClientSignupStack.Screen
        name="imageScreen"
        component={ImageScreen}
        options={{ headerShown: false }}
      />
      <ClientSignupStack.Screen
        name="finalStep"
        component={FinalStep}
        options={{ headerShown: false }}
        initialParams={{ stack: "CLIENT" }}
      />
    </ClientSignupStack.Navigator>
  );
};
const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, {})(ClientSignUpStack);
