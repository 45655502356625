import React, { useState } from "react";
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from "react-redux";
import Avatar from "../components/avatar";
import Icon from "../components/icon";
import RoundButton from "../components/roundButton";
import {
  creatorApproval,
  downloadPortfolioProfile,
  shortlistCreator,
} from "../redux/actions/creator.action";
import Modal from "./modal/modal";
import * as WebBrowser from "expo-web-browser";
import config from "../config/config";
import adjust from "./adjust";

const ProfileModal = ({
  renderData,
  onSelection,
  showModal,
  creatorApproval,
  creatorData,
  disableButtons,
  quotation,
  pressFunc,
  selected,
  creatorType,
  downloadPortfolioProfile,
  navigation,
  user,
}) => {
  const [modalVisible, setModalVisible] = useState({ showModal });
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);

  const handleCallback = (childData) => {
    setFeedbackModalVisible(childData);
  };
  const closeModal = () => {
    onSelection(false);
    setModalVisible(true);
  };

  const acceptActiveUser = () => {
    creatorApproval(creatorData._id, "ACCEPTED", "ACCEPTEDACTIVEUSER");
  };

  const acceptPendingUser = () => {
    creatorApproval(creatorData._id, "ACCEPTED", "ACCEPTEDPENDINGUSER");
  };

  const shortlistUser = () => {
    creatorApproval(creatorData._id, "SHORTLISTED", "SHORTLISTED");
  };

  const downloadPortfolio = async (id) => {
    if (id) {
      if (Platform.OS != "web") {
        await WebBrowser.openBrowserAsync(
          `${config.baseUrl}api/creator/file?fileId=${id}`
        );
      } else {
        downloadPortfolioProfile(id);
      }
    }
  };
  return (
    <View>
      {/* <View style={{ display: 'none' }} href='empty'>ref</View> */}
      <Modal
        animationType="fade"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={showModal}
        onRequestClose={() => {}}
        ariaHideApp={false}
      >
        <View style={styles.centeredView}>
          {renderData ? (
            <ScrollView nestedScrollEnabled={true} style={styles.modalView}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontSize: adjust(18),
                    alignSelf: "center",
                    paddingTop: 10,
                    color: "#FFF",
                  }}
                >
                  C R E A T O R
                </Text>
                <TouchableOpacity
                  style={{
                    alignSelf: "flex-end",
                    marginRight: 0,
                    flexDirection: "row",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                  onPress={() => closeModal()}
                >
                  <Text
                    style={{ color: "#FFFFFF", marginRight: 0, fontSize: 15 }}
                  >
                    close
                  </Text>
                  {/* <Text style={{ color: "#FFFFFF" }}>X</Text> */}
                </TouchableOpacity>
              </View>
              <View style={styles.profile}>
                <Avatar
                  source={
                    creatorData.image || creatorData.mimType
                      ? {
                          uri: `data:${
                            creatorData.mimType ? creatorData.mimType : ""
                          };base64,${
                            creatorData.image ? creatorData.image : ""
                          }`,
                        }
                      : require("../assets/avatar/avatar.png")
                  }
                  style={{ borderWidth: 0, height: 50, width: 50 }}
                ></Avatar>

                <View
                  style={{
                    flex: 1,
                    marginLeft: 10,
                    top: 10,
                  }}
                >
                  <Text style={styles.nameText}>
                    {creatorData ? creatorData.name : ""}
                  </Text>
                  {/* <Text
                    style={{
                      borderBottomWidth: 1,
                      color: "#202020",
                      borderBottomColor: "#202020",
                    }}
                  >
                    {creatorData && creatorData.designation
                      ? creatorData.designation
                      : ""}
                  </Text> */}
                  <Text style={{}}>
                    {creatorData && creatorData.applyingFor
                      ? creatorData.applyingFor.map((x) => x.name)
                      : ""}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  backgroundColor: "#FFFFFF",
                  padding: 20,
                  borderRadius: 10,
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                  maxWidth: 400,
                  width: "100%",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    minWidth: 250,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 20,
                      fontWeight: "bold",
                      color: "#828282",
                    }}
                  >
                    About
                  </Text>
                  <Text
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    {creatorData ? creatorData.workThroughProject : ""}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    // borderBottomWidth: 1,
                    marginTop: 5,
                    // maxWidth: 250,
                    minWidth: 250,
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 5,
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    Current Job
                  </Text>
                  <Text
                    style={{
                      textAlign: "justify",
                      color: "#000",
                    }}
                  >
                    {creatorData && creatorData.designation
                      ? creatorData.designation
                      : ""}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>Phone No</Text>
                  <Text>{creatorData ? creatorData.phoneNo : ""}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>Address</Text>
                  <Text>
                    {creatorData
                      ? creatorData.address
                        ? creatorData.address
                        : creatorData.city
                        ? creatorData.city
                        : ""
                      : ""}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>Experience</Text>
                  <Text>
                    {creatorData ? creatorData.yearsOfExperience : ""}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>Education</Text>
                  <Text>{creatorData ? creatorData.education : ""}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 180,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>Email</Text>
                  <Text>{creatorData ? creatorData.email : ""}</Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  backgroundColor: "#FFFFFF",
                  padding: 20,
                  borderRadius: 10,
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                  maxWidth: 400,
                  width: "100%",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    minWidth: 250,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#828282",
                    }}
                  >
                    Professional Details
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    // borderBottomWidth: 1,
                    marginTop: 5,
                    // maxWidth: 250,
                    minWidth: 250,
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 5,
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    Technical Expertise
                  </Text>
                  <Text
                    style={{
                      textAlign: "justify",
                      color: "#000",
                    }}
                  >
                    {creatorData.expertise.map((e) => <Text>{e.name}</Text>) ||
                      ""}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>PAN</Text>
                  <Text>{creatorData.pan || ""}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>Aadhaar</Text>
                  <Text>{creatorData.aadhaar || ""}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Text style={styles.label}>Skills</Text>
                  <Text>
                    {creatorData && creatorData.skill
                      ? creatorData.skill.map((x) => x.name).join(", ")
                      : ""}
                  </Text>
                </View>
              </View>

              {creatorData && creatorData.creatorPortfolioUrl ? (
                <View style={styles.portfolio}>
                  <Text style={{ fontWeight: "bold", marginLeft: 20 }}>
                    Link to Portfolio
                  </Text>
                  <TouchableOpacity
                    onPress={() => downloadPortfolio(creatorData.portfolio[0])}
                  >
                    <Icon
                      style={{ marginRight: 10 }}
                      name="rightChevronArrowWithCircle"
                    ></Icon>
                  </TouchableOpacity>
                </View>
              ) : (
                <></>
              )}

              {creatorData && creatorData.resumeUrl ? (
                <View style={styles.portfolio}>
                  <Text style={{ fontWeight: "bold", marginLeft: 20 }}>
                    Link to Resume
                  </Text>
                  <TouchableOpacity
                    onPress={() => downloadPortfolio(creatorData.portfolio[0])}
                  >
                    <Icon
                      style={{ marginRight: 10 }}
                      name="rightChevronArrowWithCircle"
                    ></Icon>
                  </TouchableOpacity>
                </View>
              ) : (
                <></>
              )}
              {disableButtons ? (
                <></>
              ) : (
                <View
                  style={{
                    flex: 1,
                    marginBottom: 30,
                    marginTop: 10,
                  }}
                >
                  {quotation ? (
                    <View
                      style={{
                        flex: 1,
                      }}
                    >
                      <RoundButton
                        style={{
                          backgroundColor: selected ? "#F33A3A" : "#000000",
                          marginRight: 5,
                          minHeight: 35,
                          height: "100%",
                        }}
                        title={selected ? "Unselect" : "Select & Start"}
                        pressFunc={pressFunc}
                      ></RoundButton>
                    </View>
                  ) : (
                    <View>
                      {creatorType === "pending" ? (
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            flexDirection: "row",
                          }}
                        >
                          <RoundButton
                            style={{
                              backgroundColor: "#FFF",
                              marginRight: 5,
                              minHeight: 35,
                              height: "100%",
                            }}
                            textStyle={{ color: "#000" }}
                            title={"Shortlist"}
                            pressFunc={() => {
                              // setFeedbackModalVisible();
                              shortlistUser(), closeModal();
                            }}
                          ></RoundButton>
                          <RoundButton
                            style={{
                              backgroundColor: "#000",
                              marginLeft: 5,
                              minHeight: 35,
                              height: "100%",
                            }}
                            title="Accept"
                            pressFunc={() => {
                              acceptActiveUser(), closeModal();
                            }}
                          ></RoundButton>
                        </View>
                      ) : (
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            flexDirection: "row",
                          }}
                        >
                          <RoundButton
                            style={{
                              backgroundColor: "#000",
                              marginLeft: 5,
                              minHeight: 35,
                            }}
                            title="Accept"
                            pressFunc={() => {
                              acceptPendingUser();
                              closeModal();
                            }}
                          ></RoundButton>
                        </View>
                      )}
                    </View>
                  )}
                </View>
              )}
            </ScrollView>
          ) : (
            <></>
          )}
        </View>
      </Modal>
      {/* <FeedbackModal
        creatorData={creatorData}
        renderData={true}
        showModal={feedbackModalVisible}
        onSelection={handleCallback}
      ></FeedbackModal> */}
    </View>
  );
};

const styles = StyleSheet.create({
  placeholder: {
    fontSize: adjust(11),
    color: "#B1B1B1",
    marginBottom: 10,
    flex: 1,
  },
  selected: {
    fontSize: adjust(11),
    color: "#000000",
    marginBottom: 10,
    flex: 1,
  },
  item: {
    padding: 10,
    fontSize: adjust(16),
    height: 44,
    borderBottomWidth: 1,
    borderColor: "#D3D3D3",
    minWidth: "95%",
    textAlign: "center",
  },
  label: {
    fontSize: adjust(13),
    fontWeight: "bold",
    marginVertical: 5,
  },
  data: { flex: 1, fontSize: adjust(12), color: "#606060", marginBottom: 10 },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  modalView: {
    width: "90%",
    maxWidth: 400,
    maxHeight: "90%",
    minHeight: "90%",
    backgroundColor: "#707070",
    borderRadius: 20,
    paddingHorizontal: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  portfolio: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    marginVertical: 10,
    flex: 0.5,
    borderRadius: 8,
    maxWidth: 400,
    width: "100%",
    minHeight: 30,
    paddingVertical: 15,
  },
  nameText: {
    fontSize: adjust(19),
    fontWeight: "bold",
  },
  profile: {
    flex: 1,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 10,
    flexDirection: "row",
    marginTop: 10,
    maxWidth: 400,
    width: "100%",
    maxHeight: 90,
    minHeight: 60,
  },
});

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
  creatorApproval,
  shortlistCreator,
  downloadPortfolioProfile,
})(ProfileModal);
