import { StyleSheet, Text, View, Pressable } from "react-native";
import React from "react";

const Button = ({ title, onPress }) => {
  return (
    <Pressable style={[styles.pressableStyle]} onPress={onPress}>
      <Text style={[styles.textStyle]}>{title}</Text>
    </Pressable>
  );
};

export default Button;

const styles = StyleSheet.create({
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginBottom: 25,
    marginLeft: 25,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
    top: 10,
  },
  textStyle: {
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    alignSelf: "center",
    justifyContent: "center",
  },
});
