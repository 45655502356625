export const fetchCaseStudy = () => (dispatch) => {
  return dispatch({
    type: "FETCH_CASE_STUDY",
    payload: {
      request: {
        url: `/api/case-studies`,
        method: "GET",
      },
    },
  });
};

export const createCaseStudy = (obj) => (dispatch) => {
  return dispatch({
    type: "CREATE_CASE_STUDY",
    payload: {
      request: {
        url: `/api/case-studies`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const removeCaseStudy = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_CASE_STUDY",
    payload: {
      request: {
        url: `/api/case-studies/${id}`,
        method: "DELETE",
      },
    },
  });
};
