import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  View,
  Image,
  Platform,
  TouchableOpacityBase,
} from "react-native";
import Modal from "../../../components/modal/modal";
import { StyleSheet, Text } from "react-native";

import adjust from "../../../components/adjust";
import LargeTextInput from "../../../components/largeTextInput";
import RoundButton from "../../../components/roundButton";
import Icon from "../../../components/icon";
import { NavigationContainer } from "@react-navigation/native";

export default function SalesWithdrawScreen({
  submit,
  value,
  addImage,
  success = true,
  recieved = false,
  modalViewStyle,
  modalBackgroundColor = "true",
  withDraw = false,
  navigation,
}) {
  const [input, setInput] = useState("");
  useEffect(() => {
    setInput(value ? value.name : "");
  }, [value]);
  const [showModal, setShowModal] = useState(true);
  return (
    <Modal
      animationType="fade"
      transparent={true}
      presentationStyle="overFullScreen"
      isVisible={showModal}
      visible={showModal}
      ariaHideApp={false}
    >
      <View style={styles.centeredView}>
        <TouchableOpacity
          style={{ position: "absolute", top: 20, left: 20 }}
          onPress={() => {
            console.log("hello");
            setShowModal(false);
            // navigation.navigate("SalesManageFinanceScreen");
            navigation.goBack();
          }}
        >
          <Icon name="back" height={30} width={30} fill={"#7A9D54"}></Icon>
        </TouchableOpacity>
        <View
          style={[
            styles.modalView,
            { height: addImage ? 400 : 300, padding: addImage ? 0 : 10 },
            { backgroundColor: modalBackgroundColor ? "#FFF" : null },
            modalViewStyle,
            { width: "100%" },
          ]}
        >
          <View>
            <View style={{ marginHorizontal: 10 }}>
              {recieved ? (
                <Text style={styles.textstyle}>withdraw initiated</Text>
              ) : (
                <Text style={styles.textstyle}>withdraw money</Text>
              )}
              {withDraw || success ? (
                <Text style={{ fontSize: 13, margin: 5 }}>
                  Tell us how much you would like to withdraw now
                </Text>
              ) : (
                <Text style={{ fontSize: 13, marginHorizontal: 5 }}>
                  We have recieved your request
                </Text>
              )}
            </View>

            {withDraw ? (
              <View style={{ width: "100%" }}>
                <View
                  style={{ flexDirection: "row", top: 5, marginHorizontal: 15 }}
                >
                  <Text style={styles.balanceStyle}>
                    your total balance is{" "}
                  </Text>
                  <Text
                    style={[
                      styles.balanceStyle,
                      { color: "#FF973E", marginLeft: 0 },
                    ]}
                  >
                    INR 1,30,000
                  </Text>
                </View>
                <View style={{ marginTop: 80, marginLeft: 30 }}>
                  <LargeTextInput
                    marginLeft={1}
                    width={"100%"}
                    placeholder="enter withdraw amount"
                    style={styles.TextInputStyle}
                  />
                </View>
                <View>
                  <RoundButton
                    title="Withdraw"
                    style={styles.buttonStyle}
                  ></RoundButton>
                </View>
              </View>
            ) : (
              <></>
            )}
            {success ? (
              <>
                <View style={{ flexDirection: "row", marginHorizontal: 15 }}>
                  <Text style={styles.balanceStyle}>
                    your total balance is{" "}
                  </Text>
                  <Text
                    style={[
                      styles.balanceStyle,
                      { color: "#FF973E", marginLeft: 0 },
                    ]}
                  >
                    INR 1,30,000
                  </Text>
                </View>
                <View style={{ justifyContent: "center", marginTop: 50 }}>
                  <Icon
                    style={{ alignSelf: "center" }}
                    name="tickMarkWithCircle"
                    height={recieved ? 80 : 100}
                    width={recieved ? 80 : 100}
                    fill={"#7A9D54"}
                  ></Icon>
                </View>
              </>
            ) : (
              <></>
            )}

            {recieved ? (
              <View>
                <View
                  style={{
                    alignItems: "center",
                    marginTop: 35,
                  }}
                >
                  <Icon
                    name="tickMarkWithCircle"
                    height={recieved ? 60 : 100}
                    width={recieved ? 60 : 100}
                    fill={"#7A9D54"}
                  ></Icon>
                </View>
                <Text
                  style={{
                    color: "#7A9D54",
                    fontSize: 16,
                    fontWeight: "bold",
                    alignSelf: "center",
                  }}
                >
                  INR 1,30,000
                </Text>
                <Text
                  style={{
                    color: "#828282",
                    fontSize: 16,
                    fontWeight: "bold",
                    alignSelf: "center",
                    marginHorizontal: 50,
                  }}
                >
                  {"will be credited to your bank"}
                </Text>
                <Text
                  style={{
                    color: "#828282",
                    fontSize: 16,
                    fontWeight: "bold",
                    alignSelf: "center",
                    marginHorizontal: 50,
                  }}
                >
                  within the next 48 hours
                </Text>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  modalView: {
    borderRadius: 10,
    // width: 350,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  textstyle: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#000",
    margin: 5,
    top: 5,
  },
  middleText: { color: "#828282" },
  balanceStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#828282",
  },
  TextInputStyle: {
    borderBottomColor: "#707070",
    width: "100%",
    marginRight: 15,
    right: 15,
  },
  buttonStyle: {
    backgroundColor: "#000000",
    width: "50%",
    marginTop: 20,
    marginLeft: 10,
  },
});
