import { View, Text, StyleSheet, Pressable } from "react-native";
import React, { useState } from "react";

import { connect } from "react-redux";
import { launchCamera, launchImageLibrary } from "react-native-image-picker";

import Header from "../../../components/header";
import UserImageUpload from "../../../components/SignUpComponents/userImageUpload";
import { updateProfile } from "../../../redux/actions/user.action";
import { primaryColor } from "../../../components/utils";

const ClientImageScreen = ({ navigation, updateProfile, user, route }) => {
  const [profilePic, setProfilePic] = useState(null);
  const pickImage = () => {
    const options = {
      storageOptions: {
        path: "images",
        mediaType: "photo",
      },
      includeBase64: true,
      base64: true,
    };
    launchImageLibrary(options, (response) => {
      console.log("Success");
      if (response.didCancel) {
        console.log("User cancelled image picker");
      } else if (response.error) {
        console.log("error");
      } else {
        const source = response.assets[0].base64;

        setProfilePic(source);
      }
    });
  };

  const next = () => {
    updateProfile(
      {
        image: profilePic ? "data:image/jpeg;base64," + profilePic : null,
        email: user.email,
      },
      navigateAfterSuccess
    );
  };

  const navigateAfterSuccess = () => {
    navigation.navigate(
      user.userType === "CREATOR" ? "creatorPrimaryjobScreen" : "finalStep"
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Basic Details"
          subtitle="Please setup your credentials"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          s
          titleStyle={{
            color: primaryColor(user.userType) || route.params.stack,
          }}
        ></Header>
      </View>
      <UserImageUpload onPress={pickImage} profilePic={profilePic} />
      <Pressable style={[styles.pressableStyle]} onPress={() => next()}>
        <Text
          style={[
            {
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: "bold",
              alignSelf: "center",
              justifyContent: "center",
            },
          ]}
        >
          {"Next"}
        </Text>
      </Pressable>
    </View>
  );
};
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#FFF" },
  buttonStyle: {
    backgroundColor: "#000",
    height: 70,
    marginVertical: 5,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { updateProfile })(ClientImageScreen);
