import React from "react";
import { TouchableOpacity, Text, View, Pressable, Image } from "react-native";
import Icon from "../components/icon";
import adjust from "./adjust";

export default function LargeButton({
  title,
  pressFunc,
  style,
  titleStyle,
  pressedTitleStyle,
  borderStyle,
  pressedBorderStyle,
  backgroundColor,
  textColor,
  borderColor,
  arrowColor,
  disabled,
  disableIcon,
  changeColorForDisable,
  smallArrow,
}) {
  return (
    <View
      style={[
        {
          flex: 1,
          marginTop: 15,
          maxHeight: 70,
          width: "100%",
          alignSelf: "center",
          marginHorizontal: 10,
        },
        style,
      ]}
    >
      {/* <
        onPress={() => {
          setTimesPressed((current) => current + 1);
        }}
        style={({ pressed }) => [
          {
            backgroundColor: pressed
              ? 'rgb(210, 230, 255)'
              : 'white'
          },
          styles.wrapperCustom
        ]}>
        {({ pressed }) => (
          <Text style={styles.text}>
            {pressed ? 'Pressed!' : 'Press Me'}
          </Text>
        )}
      </Pressable> */}

      <Pressable
        disabled={disabled}
        style={({ pressed }) => [
          {
            borderRadius: 8,
            borderColor: borderColor ? borderColor : "#FFD500",
            borderWidth: 0.5,
            padding: 10,
            height: 70,
            paddingRight: 10,
            paddingLeft: 20,
            backgroundColor:
              disabled && changeColorForDisable
                ? "#B7B7B7"
                : pressed && backgroundColor === "#000"
                ? "#7A95C1"
                : pressed
                ? "#000000"
                : backgroundColor
                ? backgroundColor
                : "#FFFFFF",
            flexDirection: "row",
            alignContent: "flex-start",
            justifyContent: "space-between",
          },
          pressed && pressedBorderStyle ? pressedBorderStyle : borderStyle,
        ]}
        onPress={pressFunc}
      >
        {({ pressed }) => (
          <>
            <Text
              style={[
                {
                  color: pressed
                    ? "#FFFFFF"
                    : textColor
                    ? textColor
                    : "#6D6D6D",
                  fontSize: adjust(25),
                  fontWeight: "bold",
                  marginTop: 9,
                  // flex: 4,
                  // marginLeft: 10,
                },
                pressed && pressedTitleStyle ? pressedTitleStyle : titleStyle,
              ]}
            >
              {title}
            </Text>
            {disabled ? (
              <></>
            ) : disableIcon ? (
              <Icon
                fill={arrowColor ? arrowColor : "#000"}
                name="rightArrow"
                style={{ marginTop: 13, right: 15 }}
              ></Icon>
            ) : (
              <Icon
                fill={arrowColor ? arrowColor : "#000"}
                name="rightArrow"
                style={{ marginTop: 13, right: 15 }}
              ></Icon>
            )}
          </>
        )}
      </Pressable>
    </View>
  );
}
