import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/creator/dashboard";
import { Dimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { connect } from "react-redux";
import MyProjects from "../../screens/common/myProjects";
import activeProjectDetails from "../../screens/common/activeProjectDetails";
import ActiveProjectSummary from "../../screens/creator/activeProjectSummary/activeProjectSummary";
import ProjectStatus from "../../screens/Client/projectStatus";
import ProjectPayment from "../../screens/creator/activeProjectDetails/projectPayment";
import Deliverables from "../../screens/deliverables";
import Meetings from "../../screens/common/meeting/meetings";
import ScheduleMeetings from "../../screens/common/meeting/scheduleMeeting";
import ScheduleDetails from "../../screens/common/meeting/scheduleDetails";
import Chat from "../../screens/chat";
import SalesInit from "../../screens/Client/new_sales_setup/sales-init";
import discussionGroups from "../../screens/common/inbox_screens/discussionGroups";
import ChatWithUs from "../../screens/common/chat-with-us";
const window = Dimensions.get("window");
const CreatorProjectStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const StackNavigatorComponent = ({ user }) => {
  if (!user) {
    return <></>;
  } else {
    return (
      <CreatorProjectStack.Navigator
        name="creatorDashboard"
        initialRouteName={width > 1080 ? "dashboard" : "myProject"}
      >
        <CreatorProjectStack.Screen
          name="myProjects"
          component={MyProjects}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="activeProjectDetails"
          component={activeProjectDetails}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="activeProjectSummary"
          component={ActiveProjectSummary}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="discussionGroups"
          component={discussionGroups}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="projectStatus"
          component={ProjectStatus}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="projectPayment"
          component={ProjectPayment}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="chat"
          component={Chat}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="deliverables"
          component={Deliverables}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="myMeetings"
          component={Meetings}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="scheduleMeeting"
          component={ScheduleMeetings}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="scheduleDetails"
          component={ScheduleDetails}
          options={{ headerShown: false }}
        />
        <CreatorProjectStack.Screen
          name="chat-with-us"
          component={ChatWithUs}
          options={{ headerShown: false }}
        />
      </CreatorProjectStack.Navigator>
    );
  }
};

const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps, {})(StackNavigatorComponent);
