import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  FlatList,
} from "react-native";
import { connect } from "react-redux";
import {
  fetchChat,
  sendMessage,
  uploadFile,
  fetchPreviousChat,
  clearChat,
} from "../../redux/actions/chat.action";
import * as FileSystem from "expo-file-system";
import config from "../../config/config";
import Bubble from "./bubble";
import ChatBox from "./chatBox";
import Header from "../header";
const Chat = ({
  selectedProject,
  user,
  fetchChat,
  sendMessage,
  navigation,
  uploadFile,
  chats,
  fetchPreviousChat,
  clearChat,
  route,
  discussionsByProject,
}) => {
  const [discussionMembers, setDiscussionMembers] = useState("");
  useEffect(() => {
    if (selectedProject && route.params && discussionsByProject) {
      setDiscussionMembers();
      fetchChat(selectedProject?._id, null, route?.params?.discussion);
    }
  }, [selectedProject, route.params, discussionsByProject]);

  useEffect(() => {
    return () => {
      clearChat();
    };
  }, []);
  const onScroll = () => {
    if (chats.fetch) {
      fetchPreviousChat(
        selectedProject._id,
        chats.chats[chats.chats.length - 1]._id
      );
    }
  };
  const checkPermission = async (file) => {
    // Function to check the platform
    // If Platform is Android then check for permissions.

    if (Platform.OS === "ios") {
      // downloadFile();
    } else if (Platform.OS === "android") {
      try {
        // const granted = await PermissionsAndroid.request(
        //   PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        //   {
        //     title: "Storage Permission Required",
        //     message:
        //       "Application needs access to your storage to download File",
        //   }
        // );
        // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        //   // Start downloading
        //   downloadFile(file);
        // } else {
        //   // If permission denied then show alert
        //   Alert.alert("Error", "Storage Permission Not Granted");
        // }
      } catch (err) {
        // To handle permission related exception
        console.log("++++" + err);
      }
    }
  };

  const downloadFile = (file) => {
    FileSystem.downloadAsync(file.uri, config.baseLocalUri + file.name)
      .then(({ uri }) => {
        console.log("Finished downloading to ", uri);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <View style={{ flex: 1, paddingTop: 15, backgroundColor: "#FFF" }}>
      <View
        style={{
          minHeight: "18%",
          maxHeight: "18%",
        }}
      >
        <Header
          disableBackButton={false}
          title={
            user.userType === "CREATOR"
              ? route.params?.discussionTitle
              : selectedProject.title
          }
          subtitle={
            user.userType === "CREATOR"
              ? "Talk to team mates and the client"
              : ""
          }
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      {/* <View
        style={{
          flex: 1,
          maxHeight: 70,
          minHeight: 70,
        }}
      >
        <TouchableOpacity
          style={{
            position: "absolute",
            left: 25,
            marginTop: 20,
            flexDirection: "row",
            alignItems: "center",
            zIndex: 999,
            flex: 1,
          }}
          onPress={() => navigation.goBack()}
        >
          <Icon name="back" fill="#000" />
        </TouchableOpacity>
        <Text
          style={{
            alignSelf: "center",
            flex: 1,
            marginTop: 21,
            fontWeight: "700",
            fontSize: 18,
          }}
        >
          Discussions
        </Text>
      </View> */}
      <View
        style={{
          flex: 1,
          marginBottom: 70,
          paddingBottom: 30,
          backgroundColor: "#ECECEC",
        }}
      >
        <FlatList
          contentContainerStyle={{ paddingBottom: 30 }}
          nestedScrollEnabled={true}
          data={chats.chats}
          keyExtractor={(item) => item._id}
          renderItem={({ item }) => (
            <Bubble
              msg={item}
              user={user}
              checkPermission={checkPermission}
              navigation={navigation}
              selectedProject={selectedProject}
            ></Bubble>
          )}
          inverted
          onEndReached={() => onScroll()}
          onEndReachedThreshold={0.5}
        />
      </View>
      <ChatBox
        style={{
          zIndex: 5,
          bottom: 5,
          width: "100%",
          paddingHorizontal: 10,
          maxHeight: Platform.OS === "android" ? 20 : null,
          minHeight: Platform.OS === "android" ? 20 : null,
          position: "absolute",
          backgroundColor: "#FFF",
          paddingTop: 10,
          // marginTop: 30,
        }}
        selectedProject={selectedProject}
        discussion={route?.params?.discussion}
        discussionTitle={route.params?.discussionTitle}
        onSend={(msg) =>
          sendMessage({
            msg: msg,
            project: selectedProject?._id,
            discussion: route?.params?.discussion,
          })
        }
        uploadFile={(data) => uploadFile(data)}
      ></ChatBox>
    </View>
  );
};

const mapStateToProps = ({
  selectedProject,
  user,
  chats,
  discussionsByProject,
}) => ({
  selectedProject,
  user,
  chats,
  discussionsByProject,
});
export default connect(mapStateToProps, {
  fetchChat,
  sendMessage,
  uploadFile,
  fetchPreviousChat,
  clearChat,
})(Chat);
