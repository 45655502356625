import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  StyleSheet,
  Dimensions,
} from "react-native";
import { connect } from "react-redux";
import Carousal from "../../components/carousal/carousal";
import Checkbox from "../../components/checkbox";
import Header from "../../components/header";
import ProjectTemplateComponent from "../../components/projectTemplateComponent";
import RoundButton from "../../components/roundButton";
const RequestProjectBrief = ({
  route,
  navigation,
  PressFunc,
  projectCatalog,
  members,
}) => {
  let windowWidth = Dimensions.get("window").width;

  let data = route.params.data;
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          textStyle={{ color: "#B1B1B1" }}
          subtitle={"Select from the list below"}
          title="Request a new project"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <ProjectTemplateComponent
        disabled={true}
        data={data}
      ></ProjectTemplateComponent>
      <ScrollView
        nestedScrollEnabled={true}
        style={{
          flex: 1,
        }}
      >
        <View style={styles.containerStyle}>
          <Text style={{ fontSize: 15, fontWeight: "bold" }}>
            Project Brief
          </Text>
          <Text style={{ fontSize: 13, marginTop: 10, color: "#606060" }}>
            {data.longDescription}
          </Text>
          <Text style={{ fontSize: 15, fontWeight: "bold", marginTop: 15 }}>
            Deliverables
          </Text>
          <Text style={{ fontSize: 13, marginTop: 10, color: "#606060" }}>
            {data.deliverables}
          </Text>
        </View>
        {projectCatalog.length > 0 ? (
          <View style={[styles.community]}>
            <Carousal
              style={{
                borderRadius: 10,
              }}
              fullScreen={true}
              components={data?.caseStudy.map((x, index) => (
                <TouchableOpacity
                  key={`${x.name} + ${index}`}
                  onPress={() => navigation.navigate("requestProject")}
                >
                  <Image
                    style={{
                      height: 180,
                      width: "95%",
                      borderRadius: 8,
                      margin: 10,
                    }}
                    source={{
                      uri:
                        Platform.OS === "web"
                          ? x.image
                          : "data:image/jpeg;base64," + x.image,
                    }}
                  ></Image>
                  <View
                    style={[
                      styles.containerStyle,
                      {
                        position: "absolute",
                        bottom: 5,
                        margin: 20,
                        width: windowWidth - 40,
                      },
                    ]}
                  >
                    <Text style={{ fontSize: 15, fontWeight: "bold" }}>
                      {x.name}
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{ fontSize: 13, marginTop: 5, color: "#606060" }}
                      >
                        Project Members:
                      </Text>

                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 5,
                          width: "65%",
                          // flexWrap: "wrap",
                        }}
                      >
                        {x.projectMembers.map((e) => (
                          <Text key={e._id} numberOfLines={1}>
                            {e.name},
                          </Text>
                        ))}
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              ))}
            ></Carousal>
          </View>
        ) : (
          <></>
        )}
        {/* <ScrollView
          nestedScrollEnabled={true}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
        >
          <View>
            {data?.caseStudy.map((e) => {
              return (
                <View>
                  <Image
                    style={{
                      height: 200,
                      width: "95%",
                      borderRadius: 8,
                      margin: 10,
                    }}
                    source={{
                      uri:
                        Platform.OS === "web"
                          ? e.image
                          : "data:image/jpeg;base64," + e.image,
                    }}
                  ></Image>
                  <View
                    style={[
                      styles.containerStyle,
                      {
                        position: "absolute",
                        bottom: 5,
                        margin: 20,
                        width: "90%",
                      },
                    ]}
                  >
                    <Text style={{ fontSize: 15, fontWeight: "bold" }}>
                      Café Sundowner Logo Design
                    </Text>
                    <Text
                      style={{ fontSize: 13, marginTop: 5, color: "#606060" }}
                    >
                      Project Members: Mithilesh, Satya and Deepak
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </ScrollView> */}
      </ScrollView>
      <RoundButton
        pressFunc={() => navigation.navigate("projectBrief")}
        title="Create Project"
        style={styles.nextPhoto}
      ></RoundButton>
    </View>
  );
};
const styles = StyleSheet.create({
  nextPhoto: {
    backgroundColor: "#000",
    maxWidth: 400,
    width: "80%",
    marginLeft: 30,
    height: 70,
    marginVertical: 5,
  },
  containerStyle: {
    width: "95%",
    margin: 10,
    padding: 15,
    backgroundColor: "#FFF",
    borderRadius: 7,
  },
});

const mapStateToProps = ({ projectCatalog, members }) => ({
  projectCatalog,
  members,
});
export default connect(mapStateToProps, {})(RequestProjectBrief);
