import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  TextInput,
} from "react-native";
import React, { useState } from "react";

import Icon from "../../components/icon";
import adjust from "../../components/adjust";

const SalesNewProjectsScreen = ({ navigation }) => {
  const window = Dimensions.get("window");
  return (
    <View style={{ backgroundColor: "#5F5F5F", height: "100%" }}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={{ margin: 20 }}>
            <Text
              style={{
                fontSize: adjust(30),
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Start a new project
            </Text>
            <Text>select how you want to start</Text>
          </View>
          <TouchableOpacity
            onPress={() => navigation.navigate("NewSalesScreen")}
            style={{
              backgroundColor: "#F27253",
              flexDirection: "row",
              borderRadius: 5,
              marginHorizontal: 5,
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Text
              style={{
                fontSize: adjust(25),
                fontWeight: "bold",
                padding: 7,
                color: "#fff",
              }}
            >
              Inintiate New Sales
            </Text>
            <Icon name="rightArrow" fill={"#FFF"}></Icon>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("NewClientSetupScreen")}
            style={{
              backgroundColor: "#3A8CD1",
              flexDirection: "row",
              borderRadius: 5,
              marginHorizontal: 5,
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              marginTop: 10,
            }}
          >
            <Text
              style={{
                fontSize: adjust(25),
                fontWeight: "bold",
                padding: 7,
                color: "#fff",
              }}
            >
              Register New Clients
            </Text>
            <Icon name="rightArrow" fill={"#FFF"}></Icon>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default SalesNewProjectsScreen;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    // height: "50%",
    // marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    // marginBottom: 60,
  },
  modalView: {
    width: "100%",
    // maxWidth: 400,
    maxHeight: 250,
    minHeight: 250,
    backgroundColor: "#FFF",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "absolute",
    bottom: 0,
  },
});
