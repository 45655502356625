import React, { useState, useRef } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ScrollView,
  Animated,
  PanResponder,
} from "react-native";
import Icon from "../../../components/icon";
// import GestureRecognizer from "react-native-swipe-gestures";
import { connect } from "react-redux";
import { updateStepStatus } from "../../../redux/actions/project.action";
import Checkbox from "../../../components/checkbox";
import { Swipeable } from "../../../components/swipeable";
import {
  Directions,
  FlingGestureHandler,
  State,
} from "react-native-gesture-handler";
import SwipeButton from "../../../components/SwipeButton/SwipeButton";
import adjust from "../../../components/adjust";
import SwipeSuccess from "../../../components/swipeable/swipeSuccess";

const ProgressComponent = ({
  phase,
  phaseNumber,
  selectedProject,
  updateStepStatus,
  user,
}) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
    }).start();
  };
  const [leftActionActivated, setLeftActionActivated] = useState(false);
  const [rightActionActivated, setRightActionActivated] = useState(false);
  const [swipeBackgroundColor, setSwipeBackgroundColor] = useState("#ffffff");
  const [swipeActivatedBackgroundColor, setSwipeActivatedBackgroundColor] =
    useState("#ffffff");

  const setNextStatus = (index) => {
    if (phase.steps[index]?.status?.name !== "Confirmed") {
      let projectObj = {
        _id: selectedProject._id,
        paymentId: phase._id,
        stepId: phase.steps[index]._id,
        swipe: "right",
      };
      updateStepStatus(projectObj);
    }
  };
  const setPreviousStatus = (index) => {
    if (phase.steps[index]?.status?.name !== "Backlog") {
      let projectObj = {
        _id: selectedProject._id,
        paymentId: phase._id,
        stepId: phase.steps[index]._id,
        swipe: "left",
      };
      updateStepStatus(projectObj);
    }
  };
  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          flex: 0.5,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Text style={{ flex: 1, fontWeight: "bold", fontSize: adjust(18) }}>
          Phase {phaseNumber}
        </Text>
        {/* <Icon name="yellowCircle" style={{}}></Icon> */}
      </View>
      <View style={{ flex: 1, marginBottom: 10, width: "100%" }}>
        <Text style={{ fontSize: adjust(18) }}>{phase.description}</Text>
        {user.userType === "PARTNER" ? (
          <Text style={{ fontSize: adjust(15), marginTop: 10 }}>
            Swipe Right to declare completed
          </Text>
        ) : (
          <></>
        )}
      </View>
      {user.userType == "PARTNER" ? (
        <View>
          {phase.steps ? (
            phase.steps.map((step, index) => {
              return (
                <Swipeable
                  key={index}
                  leftActionActivationDistance={180}
                  allowSwipeGesture={user.userType == "PARTNER" ? true : false}
                  leftContent={
                    leftActionActivated ? (
                      <SwipeSuccess
                        color={
                          leftActionActivated
                            ? swipeActivatedBackgroundColor
                            : swipeBackgroundColor
                        }
                        message={"Status updated!"}
                        style={{ marginBottom: 10 }}
                      ></SwipeSuccess>
                    ) : (
                      <></>
                    )
                  }
                  rightContent={
                    rightActionActivated ? (
                      <SwipeSuccess
                        color={
                          leftActionActivated
                            ? swipeActivatedBackgroundColor
                            : swipeBackgroundColor
                        }
                        message={"Status updated!"}
                        style={{ marginBottom: 10 }}
                      ></SwipeSuccess>
                    ) : (
                      <></>
                    )
                  }
                  onSwipeStart={() =>
                    setSwipeBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "ffffff"
                    )
                  }
                  onSwipeRelease={() => setSwipeBackgroundColor("#ffffff")}
                  onLeftActionActivate={() => {
                    setLeftActionActivated(true);
                    setSwipeActivatedBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "ffffff"
                    );
                    setNextStatus(index);
                  }}
                  resetOnFinish={true}
                  onLeftActionDeactivate={() => setLeftActionActivated(false)}
                  onRightActionActivate={() => {
                    setRightActionActivated(true);
                    setSwipeActivatedBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "ffffff"
                    );
                    setNextStatus(index);
                  }}
                >
                  <View
                    style={[
                      styles.phaseContainer,
                      {
                        backgroundColor: step.status
                          ? step?.status?.name == "Testing"
                            ? "#FFD500"
                            : step?.status?.name == "Done"
                            ? "#41B000"
                            : step?.status?.name == "Confirmed"
                            ? "#999999"
                            : step?.status?.name == "Development"
                            ? "#0233c4"
                            : "#F7F7F7"
                          : "#F7F7F7",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.stepName,
                        {
                          color: step.status
                            ? step?.status?.name == "Testing" ||
                              step?.status?.name == "Done"
                              ? "#ffffff"
                              : "#D1D1D1"
                            : "#D1D1D1",
                        },
                      ]}
                    >
                      {step?.name} <Text>{step?.status?.name}</Text>
                    </Text>
                    <Text
                      style={[
                        styles.stepDescription,
                        {
                          color: step.status
                            ? step?.status?.name == "Testing" ||
                              step?.status?.name == "Done"
                              ? "#ffffff"
                              : "#D1D1D1"
                            : "#D1D1D1",
                        },
                      ]}
                    >
                      {step?.description}
                    </Text>
                  </View>
                </Swipeable>
              );
            })
          ) : (
            <></>
          )}
        </View>
      ) : (
        <View>
          {phase.steps ? (
            phase.steps.map((step, index) => {
              return (
                <Swipeable
                  key={index}
                  leftActionActivationDistance={180}
                  allowSwipeGesture={true}
                  leftContent={
                    leftActionActivated ? (
                      <SwipeSuccess
                        color={
                          leftActionActivated
                            ? swipeActivatedBackgroundColor
                            : swipeBackgroundColor
                        }
                        message={"Status updated!"}
                      ></SwipeSuccess>
                    ) : (
                      <></>
                    )
                  }
                  rightContent={
                    rightActionActivated ? (
                      <SwipeSuccess
                        color={
                          leftActionActivated
                            ? swipeActivatedBackgroundColor
                            : swipeBackgroundColor
                        }
                        message={"Status updated!"}
                      ></SwipeSuccess>
                    ) : (
                      <></>
                    )
                  }
                  onSwipeStart={() =>
                    setSwipeBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "ffffff"
                    )
                  }
                  onSwipeRelease={() => setSwipeBackgroundColor("#ffffff")}
                  onLeftActionActivate={() => {
                    setLeftActionActivated(true);
                    setSwipeActivatedBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "ffffff"
                    );
                    setNextStatus(index);
                  }}
                  resetOnFinish={true}
                  onLeftActionDeactivate={() => setLeftActionActivated(false)}
                  onRightActionActivate={() => {
                    setRightActionActivated(true);
                    setSwipeActivatedBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "ffffff"
                    );
                    setNextStatus(index);
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Checkbox
                      style={{
                        flex: 1,
                        borderColor: step.status
                          ? step?.status?.name == "Testing"
                            ? "#000000"
                            : step?.status?.name == "Done"
                            ? "#41B000"
                            : "#C9C9C9"
                          : "#C9C9C9",
                        backgroundColor: step.status
                          ? step?.status?.name == "Testing"
                            ? "#FFD500"
                            : step?.status?.name == "Done"
                            ? "#41B000"
                            : step?.status?.name == "Confirmed"
                            ? "#999999"
                            : step?.status?.name == "Development"
                            ? "#0233c4"
                            : "#F7F7F7"
                          : "#F7F7F7",
                      }}
                      disable={true}
                      value={step?.completed}
                    />
                    <View style={{ flex: 1, marginLeft: 10 }}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: adjust(18),
                          color: step.status
                            ? step?.status?.name == "Testing"
                              ? "#000000"
                              : step?.status?.name == "Done"
                              ? "#41B000"
                              : "#C9C9C9"
                            : "#C9C9C9",
                        }}
                      >
                        {step.name} <Text>{step?.status?.name}</Text>
                      </Text>
                      <Text
                        style={{
                          fontSize: adjust(15),
                          color: step.status
                            ? step?.status?.name == "Testing"
                              ? "#000000"
                              : step?.status?.name == "Done"
                              ? "#41B000"
                              : "#C9C9C9"
                            : "#C9C9C9",
                        }}
                      >
                        {step?.description}
                      </Text>
                    </View>
                  </View>
                </Swipeable>
              );
            })
          ) : (
            <></>
          )}
        </View>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    marginTop: 10,
    padding: 20,
  },
  text: {
    marginLeft: 10,
    fontWeight: "bold",
    fontSize: adjust(16),
    color: "#41B000",
  },
  phaseContainer: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    borderRadius: 8,
    justifyContent: "center",
    padding: 20,
    flexWrap: "wrap",
    marginBottom: 10,
  },
  stepName: {
    flex: 1,
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#D1D1D1",
    alignSelf: "flex-start",
    width: "100%",
  },
  stepDescription: {
    flex: 1,
    fontSize: adjust(15),
    color: "#D1D1D1",
    alignSelf: "flex-start",
    width: "100%",
  },
});
const mapStateToProps = ({ selectedProject, user }) => ({
  selectedProject,
  user,
});
export default connect(mapStateToProps, { updateStepStatus })(
  ProgressComponent
);
