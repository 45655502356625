import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Icon from "../icon";

const AllTransactionComponents = ({
  amount,
  transactionDetails,
  transactionTime,
  projectName,
  Colors,
  deducted = false,
}) => {
  return (
    <View style={styles.containerStyle}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "90%" }}>
          <Text style={[styles.amountStyle, { color: Colors }]}>{amount}</Text>
          <Text style={styles.transDetailsStyle}>{transactionDetails}</Text>
          <Text style={{ fontSize: 13 }}>{projectName}</Text>
          <Text style={{ fontSize: 10, marginTop: 5, marginBottom: 5 }}>
            {transactionTime}
          </Text>
        </View>
        <View style={{ width: "10%", justifyContent: "center" }}>
          <Icon
            fill={deducted ? "#0000" : Colors}
            name={deducted ? "minus" : "plus_new"}
          ></Icon>
        </View>
      </View>
    </View>
  );
};

export default AllTransactionComponents;

const styles = StyleSheet.create({
  containerStyle: {
    // marginTop: 10,
    borderBottomColor: "#707070",
    borderBottomWidth: 1,
    // padding: 10,
    margin: 10,
  },
  amountStyle: {
    //color: "#F27253",
    fontSize: 15,
    fontWeight: "bold",
  },
  transDetailsStyle: {
    color: "#000",
    fontSize: 15,
    fontWeight: "bold",
  },
});
