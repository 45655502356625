import {
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import LargeButton from "../../../components/largeButton";
import { connect } from "react-redux";
import WelcomeMessageComponent from "../../../components/welcome_message_compoment/welcomeMessageComponent";
import {
  fetchWelcomeMessage,
  removeWelcomeMessage,
} from "../../../redux/actions/welcome-message.action";

const WelcomeMessageScreen = ({
  navigation,
  welcomeMessages,
  route,
  fetchWelcomeMessage,
  removeWelcomeMessage,
}) => {
  const window = useWindowDimensions();
  const [data, setData] = useState({});
  //   useEffect(() => {
  //     if (route.params?.data) {
  //       setData(JSON.parse(route.params.data));
  //     }
  //   }, [route.params]);
  useEffect(() => {
    fetchWelcomeMessage();
  }, []);
  useEffect(() => {
    setRenderData(welcomeMessages);
  }, [welcomeMessages]);
  const [renderData, setRenderData] = useState([]);
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          enableAvatar={false}
          title={`welcome messages`}
          subtitle={`Add or Remove Welcome Messages`}
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ height: 100, marginHorizontal: 20 }}>
        <LargeButton
          pressFunc={() =>
            navigation.navigate("welcomeMessageforAdmin", {
              data: route?.params?.data,
            })
          }
          arrowColor={"#FFF"}
          textColor={"#FFF"}
          title={`Add Welcome Message`}
          backgroundColor={"#000"}
        ></LargeButton>
      </View>
      <ScrollView style={{ marginHorizontal: 10, height: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {renderData?.map((e, index) => (
            <View
              key={e._id + "welcome-message-list"}
              style={{
                width: "100%",
                justifyContent: "center",
              }}
            >
              <WelcomeMessageComponent
                leftMargin={10}
                minWidth={window.width}
                maxWidth={window.width}
                mimType={e.mimType}
                image={e.image}
                key={`${e.message} + ${index}`}
                message={e.message}
                textColor="#fff"
                backgroundColor={"#7A9D54"}
                editable
                onPressDelete={() => {
                  removeWelcomeMessage(e._id);
                }}
              ></WelcomeMessageComponent>
            </View>
          ))}
        </View>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ salesPersons, creatorList, welcomeMessages }) => ({
  salesPersons,
  creatorList,
  welcomeMessages,
});
export default connect(mapStateToProps, {
  fetchWelcomeMessage,
  removeWelcomeMessage,
})(WelcomeMessageScreen);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
});
