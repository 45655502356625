const initialState = {};
const selectedMember = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_MEMBER": {
      return action.payload.data;
    }

    case "UPDATE_PAYMENT_STATUS_SUCCESS": {
      const member = action.payload.data.team.find((x) => x._id === state._id);

      return member
        ? {
            ...state,
            payments: member.payments,
          }
        : state;
    }

    default: {
      return state;
    }
  }
};

export default selectedMember;
