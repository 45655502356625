import { StyleSheet, Text, View, ScrollView, FlatList } from "react-native";
import React, { useEffect } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import DashboardCardWithArrowAndIcon from "../../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import { primaryColor } from "../../../components/utils";
import { fetchHelpListForAdmin } from "../../../redux/actions/help.action";

const Discussions = ({
  user,
  navigation,
  fetchHelpListForAdmin,
  helpMessagesForAdmin,
}) => {
  useEffect(() => {
    fetchHelpListForAdmin();
  }, []);
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          title="query handling"
          subtitle="Answer queries from across the company"
          enableAvatar={user.userType === "ONEDESIGN" ? false : true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          avatarSource={{
            uri: `data:${user.mimType};base64,${user.image}`,
          }}
        ></Header>
      </View>
      <FlatList
        style={{ marginHorizontal: 10 }}
        scrollEnabled
        nestedScrollEnabled
        data={helpMessagesForAdmin}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => (
          <DashboardCardWithArrowAndIcon
            value={""}
            onPressCard={() =>
              navigation.navigate("chat-with-us", { from: item._id })
            }
            arrowColor={"#000"}
            borderWidth={4}
            borderColor={item.unread ? primaryColor(user.userType) : "#FFF"}
            descriptionFontWeight={"bold"}
            descriptionFontSize={18}
            descriptionTextColor={primaryColor(user.userType)}
            description={`${item.unread} new messages`}
            subDetails={item.user.userType}
            titleColor="#000"
            backgroundColor="#FFF"
            title={`${item.user.fName} ${item.user.lName}`}
            icon={false}
          ></DashboardCardWithArrowAndIcon>
        )}
      />
    </View>
  );
};
const mapStateToProps = ({ user, helpMessagesForAdmin }) => ({
  user,
  helpMessagesForAdmin,
});
export default connect(mapStateToProps, { fetchHelpListForAdmin })(Discussions);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: "18%",
    maxHeight: "18%",
  },
});
