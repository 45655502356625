import {
  StyleSheet,
  Text,
  View,
  FlatList,
  useWindowDimensions,
  TouchableOpacity,
  Pressable,
} from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import LargeTextInput from "../../../components/largeTextInput";
import RoundButton from "../../../components/roundButton";
import {
  createExpertMaterial,
  fetchExpertMaterial,
  removeExpertMaterial,
} from "../../../redux/actions/expert-material.action";
import { connect } from "react-redux";
import CreativeCardComponent from "../common/creativeCardComponent";
import Icon from "../../../components/icon";
import PopUp from "../../../components/popUp/popUp";
import Checkbox from "../../../components/checkbox";

const ExpertMaterial = ({
  navigation,
  expertMaterials,
  fetchExpertMaterial,
  createExpertMaterial,
  removeExpertMaterial,
}) => {
  useEffect(() => {
    fetchExpertMaterial();
  }, []);
  const initObj = {
    _id: null,
    type: "Image",
    url: "",
    label: "",
    description: "",
  };

  const [data, setData] = useState(initObj);

  const updateData = (d) => {
    setData({ ...data, ...d });
  };
  const discard = () => {
    setData(initObj);
  };
  const add = () => {
    if (!data._id) {
      delete data["_id"];
    }
    createExpertMaterial(data);
    discard();
  };
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          enableAvatar={false}
          title="add expert material"
          subtitle="Enter Expert Material"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <FlatList
        nestedScrollEnabled
        scrollEnabled
        style={{ marginHorizontal: 15 }}
        keyExtractor={(item) => item.header}
        data={expertMaterials || []}
        renderItem={({ item, index }) => (
          <Pressable
            onPress={() => {
              updateData(item);
            }}
            key={index + item.description}
            style={{
              padding: 5,
              backgroundColor: "#FFF",
              borderRadius: 7,
              marginVertical: 5,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  //   alignSelf: "center",
                }}
              >
                {item.label}
                {`(${item.type})`}
              </Text>
              <Text
                style={{
                  color: "#000",
                  //   alignSelf: "center",
                }}
              >
                {item.description}
              </Text>
            </View>
            <TouchableOpacity onPress={() => removeExpertMaterial(item._id)}>
              <Icon
                style={{
                  marginLeft: 5,
                }}
                name="delete"
              ></Icon>
            </TouchableOpacity>
          </Pressable>
        )}
      />
      <View style={{ marginHorizontal: 25 }}>
        <View style={{ flexDirection: "row", marginVertical: 5 }}>
          <Pressable
            onPress={() => setData({ type: "Image" })}
            style={{
              flexDirection: "row",
              minWidth: "50%",
              alignItems: "center",
            }}
          >
            <Checkbox
              value={data.type === "Image"}
              setValue={() => updateData({ type: "Image" })}
            />
            <Text style={{ marginLeft: 5 }}>Image</Text>
          </Pressable>
          <Pressable
            onPress={() => updateData({ type: "Video" })}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Checkbox
              value={data.type === "Video"}
              setValue={() => updateData({ type: "Video" })}
            />
            <Text style={{ marginLeft: 5 }}>Video</Text>
          </Pressable>
        </View>

        <LargeTextInput
          value={data.url}
          width={"100%"}
          placeholder={"Add URL"}
          style={{
            borderBottomColor: "#707070",
            fontSize: 20,
            marginHorizontal: 0,
          }}
          onChangeText={(e) => updateData({ url: e })}
        ></LargeTextInput>
        <LargeTextInput
          value={data.label}
          width={"100%"}
          placeholder={"Add Label"}
          style={{
            borderBottomColor: "#707070",
            fontSize: 20,
            marginHorizontal: 0,
          }}
          onChangeText={(e) => updateData({ label: e })}
        ></LargeTextInput>
        <LargeTextInput
          value={data.description}
          marginLeft={1}
          width={"100%"}
          placeholder={"Add Description"}
          style={{
            borderBottomColor: "#707070",
            fontSize: 20,
            marginHorizontal: 0,
          }}
          onChangeText={(e) => updateData({ description: e })}
        ></LargeTextInput>
      </View>
      <View
        style={{
          flexDirection: "row",
          margin: 10,
          justifyContent: "space-around",
        }}
      >
        <RoundButton
          style={{ backgroundColor: "#000000", width: 130 }}
          title={data._id ? "Update" : "Add+"}
          pressFunc={() => add()}
        ></RoundButton>
        <RoundButton
          style={{ backgroundColor: "#EB4141", width: 130 }}
          title={"Discard"}
          pressFunc={() => discard()}
        ></RoundButton>
      </View>
    </View>
  );
};
const mapStateToProps = ({ expertMaterials }) => ({ expertMaterials });
export default connect(mapStateToProps, {
  fetchExpertMaterial,
  createExpertMaterial,
  removeExpertMaterial,
})(ExpertMaterial);

const styles = StyleSheet.create({
  container: { flex: 1 },
});
