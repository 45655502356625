export const fetchIndividualService = () => (dispatch) => {
  return dispatch({
    type: "FETCH_INDIVIDUAL_SERVICE",
    payload: {
      request: {
        url: `/api/individualService`,
        method: "GET",
      },
    },
  });
};

export const createIndividualService = (obj) => (dispatch) => {
  return dispatch({
    type: "CREATE_INDIVIDUAL_SERVICE",
    payload: {
      request: {
        url: `/api/individualService`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const removeIndividualService = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_INDIVIDUAL_SERVICE",
    payload: {
      request: {
        url: `/api/individualService/${id}`,
        method: "DELETE",
      },
    },
  });
};
