import React, { useEffect, useState, Dimensions } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import userProfile from "../../screens/common/userProfile";
import changePassword from "../../screens/changePassword";
import chatWithUs from "../../screens/common/chat-with-us";

const SalesStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({}) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="employee"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <SalesStack.Navigator initialRouteName="userProfile" name="employee">
      <SalesStack.Screen
        name="userProfile"
        component={userProfile}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="changePassword"
        component={changePassword}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="chat-with-us"
        component={chatWithUs}
        options={{ headerShown: false }}
      />
    </SalesStack.Navigator>
  );
};
