import { StyleSheet, Text, View, ScrollView, FlatList } from "react-native";
import React, { useState } from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import ContainerWithDifferentBg from "../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import { Swipeable } from "../../components/swipeable";
import SwipeSuccess from "../../components/swipeable/swipeSuccess";
import { updateStepStatus } from "../../redux/actions/project.action";
import adjust from "../../components/adjust";

const ProjectStatus = ({
  navigation,
  user,
  selectedProject,
  updateStepStatus,
}) => {
  const [leftActionActivated, setLeftActionActivated] = useState(false);
  const [rightActionActivated, setRightActionActivated] = useState(false);
  const [swipeBackgroundColor, setSwipeBackgroundColor] = useState("#ffffff");
  const [swipeActivatedBackgroundColor, setSwipeActivatedBackgroundColor] =
    useState("#ffffff");

  const setNextStatus = (phase, index) => {
    if (phase.steps[index]?.status?.name !== "Confirmed") {
      let projectObj = {
        _id: selectedProject._id,
        paymentId: phase._id,
        stepId: phase.steps[index]._id,
        swipe: "right",
      };
      updateStepStatus(projectObj);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="project status"
          subtitle="New project details"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView
        nestedScrollEnabled={true}
        style={{
          marginHorizontal: 20,
        }}
        contentContainerStyle={{ paddingBottom: 50 }}
      >
        <ContainerWithDifferentBg
          content={selectedProject.title}
          backgroundColor={"#000"}
          titleFontSize={20}
          subTitle={`for ${selectedProject.user?.fName} ${selectedProject.user?.lName}`}
        ></ContainerWithDifferentBg>
        <FlatList
          scrollEnabled
          nestedScrollEnabled
          data={selectedProject.paymentPhases}
          keyExtractor={(item, index) => item._id}
          renderItem={({ item, phaseIndex }) => (
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                marginBottom: 10,
                marginTop: 10,
                padding: 10,
              }}
            >
              <View>
                <Text style={{ fontSize: 16 }}>{item.description}</Text>
              </View>
              {item.steps?.length > 0 ? (
                <View>
                  <Text style={{ fontSize: 11, marginVertical: 10, left: 1 }}>
                    Milestones Completed
                  </Text>
                </View>
              ) : (
                <></>
              )}
              {item.steps.map((step, index) => (
                <Swipeable
                  key={step._id}
                  leftActionActivationDistance={180}
                  allowSwipeGesture={user.userType == "PARTNER" ? true : false}
                  leftContent={
                    leftActionActivated ? (
                      <SwipeSuccess
                        color={
                          leftActionActivated
                            ? swipeActivatedBackgroundColor
                            : swipeBackgroundColor
                        }
                        message={"Status updated!"}
                        style={{ marginBottom: 10 }}
                      ></SwipeSuccess>
                    ) : (
                      <></>
                    )
                  }
                  rightContent={
                    rightActionActivated ? (
                      <SwipeSuccess
                        color={
                          leftActionActivated
                            ? swipeActivatedBackgroundColor
                            : swipeBackgroundColor
                        }
                        message={"Status updated!"}
                        style={{ marginBottom: 10 }}
                      ></SwipeSuccess>
                    ) : (
                      <></>
                    )
                  }
                  onSwipeStart={() =>
                    setSwipeBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "#ffffff"
                    )
                  }
                  onSwipeRelease={() => setSwipeBackgroundColor("#ffffff")}
                  onLeftActionActivate={() => {
                    setLeftActionActivated(true);
                    setSwipeActivatedBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "#ffffff"
                    );
                    setNextStatus(item, index);
                  }}
                  resetOnFinish={true}
                  onLeftActionDeactivate={() => setLeftActionActivated(false)}
                  onRightActionActivate={() => {
                    setRightActionActivated(true);
                    setSwipeActivatedBackgroundColor(
                      step?.status?.name == "Testing"
                        ? "#41B000"
                        : step?.status?.name == "Done"
                        ? "#41B000"
                        : step?.status?.name == "Confirmed"
                        ? "#0233c4"
                        : step?.status?.name == "Development"
                        ? "#FFD500"
                        : "#ffffff"
                    );
                    setNextStatus(item, index);
                  }}
                >
                  <View
                    style={[
                      styles.phaseContainer,
                      {
                        backgroundColor: step.status
                          ? step?.status?.name == "Testing"
                            ? "#FFD500"
                            : step?.status?.name == "Done"
                            ? "#41B000"
                            : step?.status?.name == "Confirmed"
                            ? "#999999"
                            : step?.status?.name == "Development"
                            ? "#0233c4"
                            : "#F7F7F7"
                          : "#F7F7F7",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.stepName,
                        {
                          color: step.status
                            ? step?.status?.name == "Testing" ||
                              step?.status?.name == "Done"
                              ? "#ffffff"
                              : "#D1D1D1"
                            : "#D1D1D1",
                        },
                      ]}
                    >
                      {step?.name} <Text>{step?.status?.name}</Text>
                    </Text>
                    <Text
                      style={[
                        styles.stepDescription,
                        {
                          color: step.status
                            ? step?.status?.name == "Testing" ||
                              step?.status?.name == "Done"
                              ? "#ffffff"
                              : "#D1D1D1"
                            : "#D1D1D1",
                        },
                      ]}
                    >
                      {step?.description}
                    </Text>
                  </View>
                </Swipeable>

                // <ContainerWithDifferentBg
                //   key={x._id}
                //   content={x.name}
                //   backgroundColor={x.completed ? "#7A9D54" : "#B1B1B1"}
                //   titleFontSize={16}
                //   subTitle={x.description}
                // ></ContainerWithDifferentBg>
              ))}
              {item.deliverables?.length > 0 ? (
                <View style={{ paddingTop: 20 }}>
                  <Text style={{ fontSize: 11 }}>Deliverables Included</Text>
                </View>
              ) : (
                <></>
              )}
              {item.deliverables.map((x) => (
                <ContainerWithDifferentBg
                  key={x._id}
                  content={x.name}
                  backgroundColor={"#7A9D54"}
                  titleFontSize={16}
                  subTitle={x.description}
                ></ContainerWithDifferentBg>
              ))}
            </View>
          )}
        />
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, { updateStepStatus })(ProjectStatus);
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  phaseContainer: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    borderRadius: 8,
    justifyContent: "center",
    padding: 15,
    flexWrap: "wrap",
    marginBottom: 10,
  },
  stepName: {
    flex: 1,
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#D1D1D1",
    alignSelf: "flex-start",
    width: "100%",
  },
  stepDescription: {
    flex: 1,
    fontSize: adjust(15),
    color: "#D1D1D1",
    alignSelf: "flex-start",
    width: "100%",
  },
});
