import * as React from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import adjust from "./adjust";
import RoundButton from "./roundButton";
import Modal from "./modal/modal";
import { BlurView } from "expo-blur";
import { useState } from "react";
import LargeTextInput from "./largeTextInput";
import ProjectTextInput from "../screens/partner/setupProjectComponents/initiateProject/projectTextInput";
import * as DocumentPicker from "expo-document-picker";
import Icon from "./icon";
import * as WebBrowser from "expo-web-browser";
import config from "../config/config";
import { addError } from "../redux/actions/toast.action";

const getExt = (fileName) => {
  const fileParts = fileName.split(".");
  return fileParts[fileParts.length - 1];
};

const DeliverableCard = ({
  type,
  deliverable,
  selectedProject,
  uploadFile,
  addDeliverables,
  downloadDeliverableFile,
}) => {
  const [fileName, setFileName] = useState("");
  const [fileValid, setFileValid] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileTypeValid, setFileTypeValid] = useState(false);
  const [file, setFile] = useState(null);

  const [modalVisible, isModalVisible] = useState(false);

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    return splitDataURI.length > 1
      ? b64toBlob(splitDataURI[1])
      : b64toBlob(dataURI);
  }
  const selectFile = async () => {
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: "*/*",
      }).then(async (result) => {
        return {
          name: result.name,
          base64: result.uri,
          type: result.type,
        };
      });
      if (res.type === "success") {
        if (Platform.OS === "web") {
          setFile(DataURIToBlob(res.base64));
        } else {
          setFile({
            uri: res.base64,
            type: `application/${getExt(res.name)}`,
            name: res.name,
          });
        }
        setFileName(res.name);
        var parts = res.name.split(".");

        setFileType(parts[parts.length - 1]);
        setFileTypeValid(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const clear = () => {
    setFile(null);
    setFileType("");
    setFileName("");
    isModalVisible(false);
  };

  const submit = () => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("type", fileType);
    formData.append("project", selectedProject._id);
    addDeliverables(formData);
    clear();
  };

  const downloadFile = async (deliverable) => {
    let id = deliverable.file._id;
    if (id) {
      if (Platform.OS != "web") {
        await WebBrowser.openBrowserAsync(
          `${config.baseUrl}api/project/deliverables/file?fileId=${id}`
        );
      } else {
        downloadDeliverableFile(id)
          .then((result) => {
            const url = window.URL.createObjectURL(
              new Blob([result.payload.data], {
                type: deliverable.type,
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.download = deliverable.fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <View>
      <View>
        {type === "add" ? (
          <TouchableOpacity
            onPress={() => isModalVisible(true)}
            style={[
              styles.card,
              {
                justifyContent: "center",
                padding: 5,
                flex: 1,
              },
            ]}
          >
            <Text
              style={[styles.title, { alignSelf: "center", color: "#a9a9a9" }]}
            >
              Add Deliverable
            </Text>
          </TouchableOpacity>
        ) : (
          <View style={styles.card}>
            <View
              style={{
                justifyContent: "space-between",
                padding: 5,
                flexDirection: "row",
              }}
            >
              <Text style={[styles.title, { fontSize: 18 }]}>Deliverable</Text>
            </View>
            <View
              style={{
                justifyContent: "space-between",
                padding: 5,
                flexDirection: "row",
              }}
            >
              <Text style={styles.title}>{deliverable.fileName}</Text>
              <TouchableOpacity
                onPress={() => downloadFile(deliverable)}
                style={{ marginBottom: 2 }}
              >
                <Icon name="download" />
              </TouchableOpacity>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 5,
                paddingBottom: 10,
                paddingRight: 5,
              }}
            >
              <Text style={[{ fontSize: 15 }]}>Format: {deliverable.type}</Text>
            </View>
          </View>
        )}
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={modalVisible}
        onRequestClose={() => isModalVisible(false)}
        ariaHideApp={false}
      >
        <View style={styles.centeredView}>
          <BlurView
            style={styles.modalView}
            intensity={80}
            tint="dark"
            blurType="dark"
            blurAmount={32}
            blurRadius={100}
          >
            <View
              style={{
                maxWidth: 350,
                backgroundColor: "#FFFFFF",
                width: "80%",
                borderRadius: 10,
                padding: 15,
                height: 470,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  minHeight: 30,
                  paddingBottom: 5,
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    marginLeft: 5,
                    color: "#A9A9A9",
                  }}
                >
                  Add Deliverable
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 10,
                  marginVertical: 10,
                }}
              >
                <TouchableOpacity
                  onPress={selectFile}
                  style={{
                    height: 70,
                    width: 70,
                    justifyContent: "center",
                    borderRadius: 50,
                    backgroundColor: "#B0B0B0",
                  }}
                >
                  <Icon
                    style={{ alignSelf: "center" }}
                    fill="#FFFF"
                    height="30"
                    width="30"
                    name="plus"
                  ></Icon>
                </TouchableOpacity>
                <Text
                  style={{
                    fontWeight: "bold",
                    marginLeft: 15,
                    color: "#A9A9A9",
                    marginTop: 25,
                  }}
                >
                  {file ? "Upload Successful" : "Upload File"}
                </Text>
              </View>
              <ProjectTextInput
                title="File Name "
                placeholder={`Example: Final_Video`}
                textType={true}
                data={fileName}
                onDataChange={(e) => setFileName(e)}
                required={true}
                multiline={false}
                isValid={(v) => {
                  setFileValid(v);
                }}
                validateNow={true}
              ></ProjectTextInput>

              <ProjectTextInput
                title="File Format"
                placeholder="Example: MP4 Video"
                textType={true}
                data={fileType}
                required={true}
                multiline={false}
                isValid={(v) => {
                  setFileValid(v);
                }}
                validateNow={true}
                editable={false}
              ></ProjectTextInput>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <TouchableOpacity>
                  <RoundButton
                    style={{ backgroundColor: "#000000", minWidth: 100 }}
                    title={"Cancel"}
                    pressFunc={() => {
                      clear();
                      isModalVisible(false);
                    }}
                  ></RoundButton>
                </TouchableOpacity>
                <TouchableOpacity>
                  <RoundButton
                    style={{ backgroundColor: "#FFD500", minWidth: 100 }}
                    title={"Submit"}
                    pressFunc={() => submit()}
                  ></RoundButton>
                </TouchableOpacity>
              </View>
            </View>
          </BlurView>
        </View>
      </Modal>
    </View>
  );
};
const styles = StyleSheet.create({
  title: {
    fontSize: adjust(24),
    fontWeight: "bold",
  },
  card: {
    padding: 10,
    maxHeight: 170,
    minHeight: 120,
    backgroundColor: "white",
    marginBottom: 15,
    borderRadius: 10,
    minWidth: "100%",
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
});
export default DeliverableCard;
