import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useEffect } from "react";
import Header from "../../../components/header";
import adjust from "../../../components/adjust";
import DashboardCard from "../../../components/dashboardCard";
import UserProfileComponent from "../../../components/userProfileComponent/userProfileComponent";
import { connect } from "react-redux";
import { fetchOtherPartners } from "../../../redux/actions/partners.action";

const PartnerScreen = ({ navigation, partners, fetchOtherPartners }) => {
  const dimension = useWindowDimensions();

  useEffect(() => {
    fetchOtherPartners();
  }, []);

  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          enableAvatar={false}
          title="Partners"
          subtitle="All Your Active Partners"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView
        style={{
          backgroundColor: "#FFF",
          paddingHorizontal: 5,
        }}
      >
        <DashboardCard
          jsx={
            <TouchableOpacity
              onPress={() => navigation.navigate("performance")}
              style={{ flex: 1, flexDirection: "column" }}
            >
              <Text style={styles.performanceTextStyle}>
                Partner Performance
              </Text>
              <Text
                style={{
                  marginLeft: 15,
                  color: "#606060",
                  fontSize: adjust(12),
                  marginTop: 5,
                }}
              >
                Tap To View November Performance
              </Text>
              <View style={styles.bottomElementContainer}>
                <View
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <Text style={styles.valueStyle}>20k</Text>
                  <Text style={styles.detailsStyle}>Revenue</Text>
                </View>
                <View style={{ flexDirection: "column" }}>
                  <Text style={styles.valueStyle}>20</Text>
                  <Text style={styles.detailsStyle}>Projects</Text>
                </View>
                <View style={{ flexDirection: "column" }}>
                  <Text style={styles.valueStyle}>40%</Text>
                  <Text style={styles.detailsStyle}>Availability</Text>
                </View>
                <View style={{ flexDirection: "column" }}>
                  <Text style={styles.valueStyle}>4.5</Text>
                  <Text style={styles.detailsStyle}>Rating</Text>
                </View>
              </View>
            </TouchableOpacity>
          }
          style={[
            styles.middleContainer,
            {
              width: dimension.width - 30,
              height: 160,
            },
          ]}
        />
        <View style={styles.card_Container}>
          {partners?.map((m, i) => {
            return (
              <UserProfileComponent
                onPress={() =>
                  navigation.navigate("project-list", {
                    type: "partner",
                    _id: m._id,
                  })
                }
                key={`${m._id}`}
                imageUri={
                  m.image && m.mimType
                    ? "data:image/jpeg;base64," + m.image
                    : null
                }
                name={`${m.fName} ${m.lName}`}
                description={`${m.totalProject} projects`}
                gender={m.gender}
              ></UserProfileComponent>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ partners }) => ({ partners });
export default connect(mapStateToProps, { fetchOtherPartners })(PartnerScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  middleContainer: {
    borderWidth: 1,
    borderColor: "#073257",
    marginVertical: 10,
  },
  card_Container: {
    margin: 5,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  valueStyle: {
    fontWeight: "bold",
    fontSize: adjust(30),
    color: "#073257",
  },
  performanceTextStyle: {
    fontWeight: "bold",
    fontSize: adjust(16),
    marginLeft: 15,
    color: "#000",
    marginTop: 10,
  },
  detailsStyle: {
    fontWeight: "700",
    fontSize: adjust(12),
    color: "#073257",
  },
  bottomElementContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 20,
  },
});
