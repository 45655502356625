export const fetchNeeds = () => (dispatch) => {
  return dispatch({
    type: "FETCH_NEED",
    payload: {
      request: {
        url: `/api/needs`,
        method: "GET",
      },
    },
  });
};

export const createNeed = (obj) => (dispatch) => {
  return dispatch({
    type: "CREATE_NEED",
    payload: {
      request: {
        url: `/api/needs`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const removeNeed = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_NEED",
    payload: {
      request: {
        url: `/api/needs/${id}`,
        method: "DELETE",
      },
    },
  });
};
