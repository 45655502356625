const initialState = [];
const raisedTransanctions = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_RAISED_TRANSACTIONS_SUCCESS": {
      return action.payload.data;
    }

    case "FETCH_RAISED_TRANSACTIONS_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "LOGOUT": {
      return [];
    }
    default:
      return state;
  }
};
export default raisedTransanctions;
