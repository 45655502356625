import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import adjust from "../../../components/adjust";
import DashboardCard from "../../../components/dashboardCard";
import UserProfileComponent from "../../../components/userProfileComponent/userProfileComponent";
import { connect } from "react-redux";
import { fetchOtherPartners } from "../../../redux/actions/partners.action";
import ProjectsList from "../../common/projects-list";

const ProjectListScreen = ({ navigation, partners, route, clientList }) => {
  const dimension = useWindowDimensions();
  const [user, setUser] = useState();

  useEffect(() => {
    if (route.params && route.params.type === "partner") {
      setUser(partners.find((x) => x._id === route.params._id));
    } else if (route.params && route.params.type === "client") {
      setUser(clientList.find((x) => x._id === route.params._id));
    }
  }, [route.params]);
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          enableAvatar={
            ["CLIENT", "PARTNER"].includes(user?.userType) ? false : true
          }
          title="all projects"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      {user && <ProjectsList user={user} navigation={navigation} />}
    </View>
  );
};
const mapStateToProps = ({ partners, clientList }) => ({
  partners,
  clientList,
});
export default connect(mapStateToProps, { fetchOtherPartners })(
  ProjectListScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  middleContainer: {
    borderWidth: 1,
    borderColor: "#073257",
    marginVertical: 10,
  },
  card_Container: {
    margin: 5,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  valueStyle: {
    fontWeight: "bold",
    fontSize: adjust(30),
    color: "#073257",
  },
  performanceTextStyle: {
    fontWeight: "bold",
    fontSize: adjust(16),
    marginLeft: 15,
    color: "#000",
    marginTop: 10,
  },
  detailsStyle: {
    fontWeight: "700",
    fontSize: adjust(12),
    color: "#073257",
  },
  bottomElementContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 20,
  },
});
