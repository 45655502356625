export const signup = (user, navigate) => (dispatch) => {
  return dispatch({
    type: "SIGNUP",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/user`,
        method: "POST",
        data: user,
      },
    },
  });
};

export const otpValidate = (user, navigate) => (dispatch) => {
  return dispatch({
    type: "OTP_VALIDATE",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/user/otp`,
        method: "POST",
        data: user,
      },
    },
  });
};

export const updateCreator = (creator) => (dispatch) => {
  return dispatch({
    type: "UPDATE_CREATOR",
    payload: {
      request: {
        url: `/api/creator/update`,
        method: "POST",
        data: creator,
      },
    },
  });
};

export const updateCreatorExperience = (creator) => (dispatch) => {
  return dispatch({
    type: "UPDATE_CREATOR",
    payload: {
      request: {
        url: `/api/creator/experience`,
        method: "POST",
        data: creator,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      },
    },
  });
};

export const login = (loginObj) => (dispatch) =>
  dispatch({
    type: "LOGIN",
    payload: {
      request: {
        url: "/api/user/login",
        method: "POST",
        data: loginObj,
      },
    },
  });

export const refresh = () => (dispatch) =>
  dispatch({
    type: "REFRESH",
    payload: {
      request: {
        url: "/api/creator",
        method: "GET",
      },
    },
  });
export const updateProfile = (user, navigate) => (dispatch) => {
  return dispatch({
    type: "UPDATE_PROFILE",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/user/update`,
        method: "POST",
        data: user,
      },
    },
  });
};

export const inviteRequest = (user, navigate) => (dispatch) => {
  return dispatch({
    type: "INVITE_REQUEST",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/invite-user`,
        method: "POST",
        data: user,
      },
    },
  });
};

export const logout = () => ({
  type: "LOGOUT",
});

export const submitResumeFile = (post) => (dispatch) => {
  return dispatch({
    type: "UPLOAD_RESUME",
    payload: {
      request: {
        url: "/api/creator/upload",
        method: "POST",
        data: post,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      },
    },
  });
};

export const submitPortfolioFile = (post) => (dispatch) => {
  return dispatch({
    type: "UPLOAD_PORTFOLIO",
    payload: {
      request: {
        url: "/api/creator/upload",
        method: "POST",
        data: post,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      },
    },
  });
};

export const refreshClient = () => (dispatch) =>
  dispatch({
    type: "REFRESH_CLIENT",
    payload: {
      request: {
        url: "/api/user",
        method: "GET",
      },
    },
  });

export const fetchClients = () => (dispatch) =>
  dispatch({
    type: "FETCH_CLIENTS",
    payload: {
      request: {
        url: "/api/user/clients",
        method: "GET",
      },
    },
  });

export const fetchClientsWithProjectCount = () => (dispatch) =>
  dispatch({
    type: "FETCH_CLIENT_WITH_PROJECT_COUNT",
    payload: {
      request: {
        url: "/api/user/client/count",
        method: "GET",
      },
    },
  });

export const fetchAdminStats = () => (dispatch) =>
  dispatch({
    type: "FETCH_ADMIN_STATS",
    payload: {
      request: {
        url: "/api/user/stats/admin",
        method: "GET",
      },
    },
  });

export const fetchSalesStats = () => (dispatch) =>
  dispatch({
    type: "FETCH_SALES_STATS",
    payload: {
      request: {
        url: "/api/user/stats/sales",
        method: "GET",
      },
    },
  });

export const fetchAccountantStats = () => (dispatch) =>
  dispatch({
    type: "FETCH_ACCOUNTANT_STATS",
    payload: {
      request: {
        url: "/api/user/stats/accountant",
        method: "GET",
      },
    },
  });

export const fetchClientStats = () => (dispatch) =>
  dispatch({
    type: "FETCH_CLIENT_STATS",
    payload: {
      request: {
        url: "/api/user/stats/client",
        method: "GET",
      },
    },
  });
