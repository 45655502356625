import * as React from "react";
import { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
} from "react-native";
import { connect } from "react-redux";
import Header from "../components/header";
import ClientPaymentComponent from "../components/clientPaymentComponent";
import { fetchAllActiveProjects } from "../redux/actions/project.action";

const ClientPayments = ({ user, navigation, projects }) => {
  useEffect(() => {
    fetchAllActiveProjects();
  }, [!projects]);

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="My Payments"
          subtitle="Manage all your payment here"
          backFunction={() => navigation.goBack()}
          enableAvatar={true}
          avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          avatarOnPress={() => setSettingsModalVisible(true)}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ flex: 4 }}>
        <ScrollView
          style={{ flex: 1, marginHorizontal: 20, marginTop: 10 }}
          nestedScrollEnabled={true}
        >
          {projects.map((project) => {
            return project.paymentPhases?.map((phase) => {
              return phase.amount.map((payment, i) => {
                return (
                  <ClientPaymentComponent
                    key={`payment-${i}`}
                    amount={payment}
                    phaseDesc={phase.description}
                  ></ClientPaymentComponent>
                );
              });
            });
          })}
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, projects }) => ({
  user,
  projects,
});
export default connect(mapStateToProps, { fetchAllActiveProjects })(
  ClientPayments
);
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    minHeight: 150,
    maxHeight: 150,
  },
});
