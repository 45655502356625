import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import LargeTextInput from "../../components/largeTextInput";
import RoundButton from "../../components/roundButton";
import { connect } from "react-redux";
import { placeQuotation } from "../../redux/actions/newProjects.action";

const Bid = ({ selectedProject, placeQuotation, navigation }) => {
  const [budget, setBudget] = useState();

  const placeBidAndNAvigate = () => {
    placeQuotation(
      {
        _id: selectedProject._id,
        memberId: selectedProject.requiredTeam._id,
        budget: budget,
      },
      () => navigation.navigate("newProjects")
    );
  };

  return (
    <View style={{ backgroundColor: "#5F5F5F", height: "100%" }}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.headerStyle}>place your bid (INR)</Text>
          <Text style={styles.chargeTextStyle}>
            Tell us how much you would charge for it
          </Text>
          <View style={styles.budgetContainerStyle}>
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              assignment budget is
            </Text>
            <Text
              style={{ fontSize: 16, fontWeight: "bold", color: "#FF973E" }}
            >
              {` INR ${selectedProject.requiredTeam.budget}`}
            </Text>
          </View>

          <LargeTextInput
            style={{ marginHorizontal: 20 }}
            // marginLeft={1}
            width={"100%"}
            onChangeText={(e) => setBudget(e)}
            placeholder={"enter your bid value"}
          ></LargeTextInput>
          <RoundButton
            style={{
              backgroundColor: "#000000",
              width: 100,
              marginLeft: 20,
              marginTop: 20,
            }}
            title={"Bid"}
            pressFunc={placeBidAndNAvigate}
          ></RoundButton>
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = ({ selectedProject }) => ({ selectedProject });
export default connect(mapStateToProps, { placeQuotation })(Bid);

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  modalView: {
    width: "100%",
    maxHeight: 250,
    minHeight: 250,
    backgroundColor: "#FFF",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "absolute",
    bottom: 0,
  },
  headerStyle: {
    color: "#000",
    fontSize: 25,
    fontWeight: "bold",
    marginLeft: 20,
    marginTop: 20,
  },
  chargeTextStyle: {
    fontSize: 13,
    color: "#606060",
    marginLeft: 20,
    top: 5,
  },
  budgetContainerStyle: {
    flexDirection: "row",
    backgroundColor: "#FFF",
    marginTop: 10,
    marginLeft: 20,
  },
});
