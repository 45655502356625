import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Dimensions,
  Platform,
  Linking,
} from "react-native";
import { connect } from "react-redux";
import RoundButton from "../../components/roundButton";
import Avatar from "../../components/avatar";
import Icon from "../../components/icon";
import Modal from "../../components/modal/modal";
import InitiateProject from "./setupProjectComponents/initiateProject/initiateProject";
import Payment from "./setupProjectComponents/payment/payment";
import SelectTeam from "./setupProjectComponents/selectTeam/selectTeam";
import PriceDivision from "./setupProjectComponents/priceDivision/priceDivision";
import InviteBids from "./setupProjectComponents/inviteBids/inviteBids";
import Summary from "./setupProjectComponents/summary/summary";
import { fetchProjectRole } from "../../redux/actions/projectRole.action";
import { fetchEngagementType } from "../../redux/actions/engagementType.action";
import { fetchProjectClass } from "../../redux/actions/projectClass.action";
import { fetchProjectAudience } from "../../redux/actions/projectAudience.action";

import {
  acceptProject,
  giveUpProject,
  createProject,
  setSelectedProject,
} from "../../redux/actions/project.action";
import GiveUpProjectModal from "../../components/giveUpProjectModal";
import adjust from "../../components/adjust";
import Header from "../../components/header";
import ProjectTextInput from "./setupProjectComponents/initiateProject/projectTextInput";

const SetupProject = ({
  navigation,
  selectedProject,
  createProject,
  giveUpProject,
  acceptProject,
  projectRole,
  engagementType,
  projectClass,
  projectAudience,
  fetchEngagementType,
  fetchProjectClass,
  fetchProjectAudience,
  creatorList,
}) => {
  let windowWidth =
    Platform.OS === "web"
      ? Dimensions.get("screen").width * 0.6
      : Dimensions.get("window").width;
  const [showModal, setShowModal] = useState(false);
  const modalCloseHandler = (childData) => {
    setShowModal(childData);
  };
  const [readThisModalVisible, setReadThisModalVisible] = useState(false);
  const [readText, setReadText] = useState(false);
  const [projectStatus, setProjectStatus] = useState(selectedProject.status);
  const [effect, setEffect] = useState(false);
  const [projectObject, setProjectObject] = useState(null);
  const [selectedProjectState, setSelectedProjectState] = useState(
    selectedProject ? selectedProject : null
  );
  const [code, setCode] = useState("");
  const [valid, setValid] = useState(false);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [statuses, setStatuses] = useState([
    { status: "Client Call", complete: false },
    { status: "Initiate", complete: false },
    { status: "Payment", complete: false },
    { status: "Select Team", complete: false },
    { status: "Price Division", complete: false },
    { status: "Invite Bids", complete: false },
    { status: "Summary", complete: false },
  ]);
  const dialCall = (number) => {
    let phoneNumber = "";
    if (Platform.OS === "android") {
      phoneNumber = `tel:${number}`;
    } else {
      phoneNumber = `telprompt:${number}`;
    }
    Linking.openURL(phoneNumber);
  };

  useEffect(() => {
    if (!statusUpdated) {
      setStatusUpdated(true);
      let newObj = statuses.findIndex(({ status }) => status == projectStatus);
      let newArr = statuses.map((e, index) => {
        if (index < newObj) return { ...e, complete: true };
        return e;
      });
      setStatuses(newArr);
    }
  });

  useEffect(() => {
    fetchEngagementType();
    fetchProjectClass();
    fetchProjectAudience();
  }, [engagementType, projectClass, projectAudience]);

  useEffect(() => {
    setSelectedProjectState(selectedProject);
    setProjectStatus(selectedProject.status);
  }, [selectedProject]);

  useEffect(() => {
    if (projectStatus == "Client Call") {
      setValid(code === selectedProject.otp);
    }
  }, [projectStatus, code]);

  const giveUpProjectAndNavigate = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setReadText(true);
    // setValid(true);
    setReadThisModalVisible(false);
  };

  const updateProject = (projectObj) => {
    let newObj = statuses.find(({ status }) => status == projectStatus);
    let newArr = statuses.map((e) => {
      if (e == newObj) return { ...newObj, complete: true };
      return e;
    });
    setStatuses(newArr);
    // setProjectStatus(projectObj.status);
    createProject(projectObj);
    setValid(false);
  };

  // useEffect(() => {
  //   proceedButtonFunction();
  // }, [selectedProject]);

  const proceedButtonFunction = () => {
    if (projectStatus == "Client Call") {
      let obj = {
        _id: selectedProject._id,
        status: "Initiate",
      };
      updateProject(obj);
    } else if (projectStatus == "Initiate") {
      let obj = {
        ...projectObject,
        _id: selectedProject._id,
        status: "Payment",
      };
      updateProject(obj);
    } else if (projectStatus == "Payment") {
      let obj = {
        _id: selectedProject._id,
        status: "Select Team",
        paymentPhases: projectObject.paymentPhases,
      };

      updateProject(obj);
    } else if (projectStatus == "Select Team") {
      let teamObj = projectObject.members;
      let obj = {
        _id: selectedProject._id,
        status: "Price Division",
        requiredTeam: {
          _id: selectedProject?.requiredTeam?._id,
          myself: projectObject.myself,
          members: teamObj
            .filter((e) => e.sourcing != "One Design Team")
            .map((e) => {
              return {
                type: e.type,
                nos: e.nos,
                budget: e.budget,
                _id: e._id,
              };
            }),
        },
        employees: teamObj
          .filter((e) => e.sourcing == "One Design Team")
          .map((e) => {
            return {
              user: e.user,
              _id: e._id,
            };
          }),
      };
      updateProject(obj);
    } else if (projectStatus == "Price Division") {
      let obj = {
        _id: selectedProject._id,
        status: selectedProject.requiredTeam
          ? selectedProject.requiredTeam.myself
            ? "Summary"
            : "Invite Bids"
          : "Invite Bids",
      };
      updateProject(obj);
    } else if (projectStatus == "Invite Bids") {
      let obj = {
        _id: selectedProject._id,
        status: "Summary",
      };
      updateProject(obj);
    } else if (projectStatus == "Summary") {
      acceptProject(selectedProject._id, () =>
        navigation.navigate("projectSuccess")
      );
      // navigation.navigate("projectSuccess");
    }
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#F0F0F0" }}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          title="Setup Project"
          subtitle={`Step 0${
            statuses.findIndex((x) => x.status === projectStatus) + 1
          } of 08`}
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <View
        style={{
          backgroundColor: "#F0F0F0",
          maxHeight: 100,
          paddingHorizontal: 30,
          height: 20,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {statuses.map((item, index) => (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                maxWidth: 50,
              }}
              key={`status-${index}`}
            >
              <TouchableOpacity
                onPress={() => {
                  item.complete ? setProjectStatus(item.status) : null;
                }}
                style={{
                  backgroundColor: item.complete
                    ? "#41B000"
                    : item.status == projectStatus
                    ? "#000000"
                    : "#D0D0D0",
                  borderRadius: 7.5,
                  height: 15,
                  width: 15,
                }}
              ></TouchableOpacity>
              {index !== statuses.length - 1 ? (
                <View
                  style={{
                    borderWidth: 1,
                    maxHeight: 5,
                    width:
                      (windowWidth - statuses.length * 15 - 40) /
                      (statuses.length - 1),
                    borderColor: item.complete
                      ? "#41B000"
                      : item.status == projectStatus
                      ? "#000000"
                      : "#D0D0D0",
                  }}
                ></View>
              ) : (
                <></>
              )}
            </View>
          ))}
        </View>
      </View>
      <View style={{ flex: 4 }}>
        {projectStatus == "Client Call" ? (
          <ScrollView
            contentContainerStyle={{ paddingBottom: 15 }}
            nestedScrollEnabled={true}
            style={{
              flex: 1,
              padding: 20,
              height: "100%",
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: "#ffffff",
                padding: 20,
                borderRadius: 10,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Text style={styles.briefHeader}>Call Client</Text>
              <Text style={styles.briefText}>
                The first step is to understand what the client has in mind and
                make the mood board accordingly. Speak to the client and fill up
                the following details.
              </Text>
            </View>
            <TouchableOpacity
              style={{
                flex: 1,
                backgroundColor: "#ECECEC",
                padding: 20,
                borderRadius: 10,
                marginBottom: 20,
              }}
              onPress={() => setReadThisModalVisible(true)}
            >
              <Text style={styles.readThisHeader}>Things to keep in mind</Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  maxHeight: 20,
                }}
              >
                <Text style={styles.readThisText}>
                  Read this before calling{" "}
                </Text>
                <View style={{ flex: 1, marginTop: 18 }}>
                  <Icon name="selectDownArrow"></Icon>
                </View>
              </View>
            </TouchableOpacity>
            <View
              style={{
                flex: 1,
                backgroundColor: "#ffffff",
                padding: 20,
                borderRadius: 10,
                flexDirection: "row",
              }}
            >
              <Avatar
                source={
                  selectedProject.user.image || selectedProject.user.mimType
                    ? {
                        uri: `data:${selectedProject.user.mimType};base64,${selectedProject.user.image}`,
                      }
                    : require("../../assets/avatar/avatar.png")
                }
                style={{ flex: 1, borderWidth: 0 }}
              ></Avatar>
              <View style={{ flex: 3, paddingLeft: 20 }}>
                <Text style={styles.nameText}>{selectedProject.user.name}</Text>
                <Text style={styles.designationText}>
                  {selectedProject.user.designation
                    ? selectedProject.user.designation
                    : null}
                  {selectedProject.user.company
                    ? ", " + selectedProject.user.company
                    : null}
                </Text>
                <View
                  style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    marginBottom: 5,
                    maxHeight: 10,
                  }}
                />
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() => {
                    dialCall(selectedProject.user.phoneNo);
                  }}
                >
                  <Text style={styles.contactText}>
                    {selectedProject.user.phoneNo}
                  </Text>
                  <Text style={styles.readThisText}>Tap to call</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ marginTop: 10 }}>
              <ProjectTextInput
                textInputStyle={""}
                title="Enter client code"
                placeholder={" "}
                textType={true}
                data={code}
                onDataChange={(e) => setCode(e)}
                keyboardType={"numeric"}
              ></ProjectTextInput>
            </View>
          </ScrollView>
        ) : (
          <></>
        )}

        {projectStatus == "Initiate" ? (
          <InitiateProject
            selectedProject={selectedProject}
            onInitiateDataChange={(e) => {
              setProjectObject(e);
            }}
            validate={(e) => setValid(e)}
          ></InitiateProject>
        ) : (
          <></>
        )}

        {projectStatus == "Payment" ? (
          <Payment
            selectedProject={selectedProject}
            onPaymentDataChange={(e) => {
              setProjectObject(e);
            }}
            validate={(e) => setValid(e)}
          ></Payment>
        ) : (
          <></>
        )}

        {projectStatus == "Select Team" ? (
          <ScrollView nestedScrollEnabled={true} style={{ flex: 1 }}>
            <SelectTeam
              onTeamDataChange={(e) => {
                setProjectObject(e);
              }}
              validate={(e) => setValid(e)}
            ></SelectTeam>
          </ScrollView>
        ) : (
          <></>
        )}

        {projectStatus == "Price Division" ? (
          <PriceDivision validate={(e) => setValid(e)}></PriceDivision>
        ) : (
          <></>
        )}

        {projectStatus == "Invite Bids" ? (
          <ScrollView nestedScrollEnabled={true} style={{ flex: 1 }}>
            <InviteBids
              navigation={navigation}
              validate={(e) => setValid(e)}
            ></InviteBids>
          </ScrollView>
        ) : (
          <></>
        )}

        {projectStatus == "Summary" ? (
          <Summary
            navigation={navigation}
            selectedProject={selectedProject}
            validate={(e) => setValid(e)}
          ></Summary>
        ) : (
          <></>
        )}
        <View style={{ flexDirection: "row", marginBottom: 40 }}>
          <RoundButton
            title="Give Up Project"
            pressFunc={() => giveUpProjectAndNavigate()}
            style={{
              flex: 1,
              backgroundColor: "#F33A3A",
              minHeight: 50,
              borderRadius: 28,
              marginLeft: 20,
              marginRight: 10,
            }}
            textStyle={{ fontWeight: "bold", fontSize: adjust(14) }}
          ></RoundButton>
          <RoundButton
            title={projectStatus == "Summary" ? "Start" : "Proceed"}
            pressFunc={valid ? () => proceedButtonFunction() : null}
            style={{
              flex: 1,
              backgroundColor:
                projectStatus == "Summary"
                  ? "#FFD500"
                  : valid
                  ? "#000000"
                  : "#E9E9E9",
              minHeight: 50,
              borderRadius: 28,
              marginRight: 20,
            }}
            textStyle={{ fontWeight: "bold", fontSize: adjust(16) }}
          ></RoundButton>
        </View>
        <Modal
          animationType="fade"
          transparent={true}
          presentationStyle="overFullScreen"
          visible={readThisModalVisible}
          onRequestClose={() => {}}
          ariaHideApp={false}
        >
          <TouchableWithoutFeedback onPress={() => closeModal()}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={styles.data}>
                  Fill up the form and learn all details.
                </Text>
                <Text style={styles.data}>
                  Ask the client to download the app so that we can start
                  working. After the call, do remind the client to tick the call
                  received mark on their app so that you can proceed.
                </Text>
                <Text style={styles.data}>
                  While talking to a client, be polite in your words. The client
                  might be or might not be having domain expertise so asking him
                  technicalities would not be advisable. Please extract all
                  necessary information.
                </Text>
                <Text style={styles.data}>
                  When speaking to the client, you represent all of Onedesign.
                  Any sort of misbehaviour or unwanted discussion if noted, will
                  lead to your dismissal.
                </Text>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
        <GiveUpProjectModal
          showModal={showModal}
          onCloseModal={modalCloseHandler}
          navigation={navigation}
        ></GiveUpProjectModal>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  nameText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    flex: 1,
  },
  designationText: {
    fontSize: adjust(13),
    color: "#202020",
    flex: 1,
  },
  briefText: {
    fontSize: adjust(15),
    color: "#707070",
    flex: 1,
    lineHeight: 18,
  },
  readThisHeader: {
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#818181",
    marginBottom: 5,
  },
  readThisText: {
    fontSize: adjust(15),
    color: "#606060",
    flex: 12,
    lineHeight: 18,
  },
  contactText: {
    fontSize: adjust(20),
    fontWeight: "bold",
    flex: 2,
  },
  budget: {
    fontSize: adjust(18),
    fontWeight: "bold",
  },
  aboutText: {
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#707070",
    marginBottom: 15,
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.8)",
    paddingHorizontal: 20,
  },
  modalView: {
    width: "100%",
    padding: 10,
    maxWidth: 400,
    maxHeight: 400,
    minHeight: 400,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    // paddingLeft: 10,
    // paddingRight: 10,
    // alignItems: "center",
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
    // elevation: 5,
    // position: "absolute",
    // bottom: 0,
    // marginBottom: 30,
  },
  data: {
    fontSize: adjust(15),
    color: "#606060",
    marginBottom: 10,
  },
  briefHeader: {
    fontSize: adjust(18),
    paddingBottom: 5,
    color: "#707070",
    fontWeight: "bold",
  },
});
const mapStateToProps = ({ selectedProject, creatorList }) => ({
  selectedProject,
  creatorList,
});
export default connect(mapStateToProps, {
  fetchProjectRole,
  createProject,
  giveUpProject,
  acceptProject,
  fetchEngagementType,
  fetchProjectClass,
  fetchProjectAudience,
})(SetupProject);
