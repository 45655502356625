import { StyleSheet, Text, View } from "react-native";
import React from "react";

const ProjectTitleComponent = ({ backgroundColor = "#000", jsx }) => {
  return (
    <View style={[styles.container, { backgroundColor: backgroundColor }]}>
      {jsx}
    </View>
  );
};

export default ProjectTitleComponent;

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    borderRadius: 8,
    padding: 15,
  },
});
