import { addError } from "../actions/toast.action";

const initialState = [];
const discussions = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LAST_SEEN_MESSAGE_SUCCESS":
    case "FETCH_DISCUSSION_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_DISCUSSION_FAIL": {
      action.asyncDispatch(addError(action.error.response?.data?.message, 3000));
      return state;
    }
    case "LOGOUT": {
      return [];
    }
    default:
      return state;
  }
};
export default discussions;
