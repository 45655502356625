import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
} from "react-native";
import React from "react";

export default function BudgetCardContainer({ budget, changeText }) {
  return (
    <View style={styles.container}>
      <TouchableOpacity style={{}}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 15,
            marginTop: 10,
            color: "#fff",
            marginLeft: 5,
          }}
        >
          assignment budget (in INR)
        </Text>
        <TextInput
          style={{
            fontWeight: "bold",
            fontSize: 50,
            marginBottom: 10,
            color: "#fff",
          }}
          value={budget}
          onChangeText={(e) => changeText(e)}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FF973E",
    marginTop: 15,
    elevation: 1,
    borderRadius: 10,
    padding: 10,
    marginHorizontal: 15,
  },
});
