import * as React from "react";
import {
  Text,
  View,
  StyleSheet,
  Pressable,
  useWindowDimensions,
} from "react-native";
import { TouchableOpacity } from "react-native";
import adjust from "./adjust";
import Icon from "./icon";

const ProjectInprogressCard = ({
  closedProject,
  arrowColor,
  onPressCard,
  project,
}) => {
  const dimension = useWindowDimensions();
  return (
    <TouchableOpacity
      onPress={() => {
        onPressCard();
      }}
      style={styles.mainContainer}
    >
      <View style={styles.container}>
        <View
          style={[
            styles.leftChildView,
            {
              width: closedProject ? "100%" : `${project.completionPercent}%`,
              backgroundColor: closedProject
                ? "#000"
                : project.completionPercent < 50
                ? "#D9AF9F"
                : project.completionPercent > 50 &&
                  project.completionPercent < 80
                ? "#BEAB88"
                : "#99B976",
            },
          ]}
        ></View>

        <View
          style={[
            styles.secondChild,
            {
              width: closedProject ? 0 : `${100 - Math.round(project.completionPercent)}%`,
              backgroundColor:
                project.completionPercent === 0 && closedProject
                  ? "#000"
                  : "#FFF",
            },
          ]}
        ></View>
      </View>
      <View style={styles.dataContainer}>
        <View style={styles.headerContainer}>
          <Text
            style={[
              styles.headerStyle,
              {
                color: closedProject ? "#FFF" : "#000",
                width: dimension.width - 70,
              },
            ]}
            numberOfLines={3}
          >
            {project.title}
          </Text>
          <Text
            style={[
              {
                fontSize: 13,
                color: closedProject ? "#FFF" : "#000",
                width: dimension.width - 70,
                marginVertical: 7,
              },
            ]}
          >
            {`Partner: ${project.partner?.fName} ${
              project.partner?.lName || ""
            } `}
          </Text>
        </View>
        {/* <View style={styles.paddingView}>
          <Text
            style={[
              styles.subTextDesign,
              {
                color: closedProject ? "#FFF" : "#707070",
              },
            ]}
          >
            <Text
              style={[
                styles.subtitle,
                { color: closedProject ? "#fff" : "#606060" },
              ]}
            >
              {`Client: ${project.user?.fName} ${project.user?.lName} | `}
              {project.deadLine
                ? Math.round(
                    (new Date(project.deadLine).getTime() -
                      new Date().getTime()) /
                      (1000 * 3600 * 24)
                  )
                : 0}{" "}
              Days to Deadline
            </Text>
          </Text>
          {/* <Text
            style={[
              styles.subTextDesign,
              { color: closedProject ? "#606060" : "#707070" },
            ]}
          >
            Project ID: {projectId}
          </Text> 
        </View> */}
        <View style={styles.footerContainer}>
          {closedProject ? (
            <View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.rateNumber}>4.3</Text>
                <View style={styles.iconContainer}>
                  <Icon
                    name="star"
                    fill="#FFD500"
                    height={30}
                    width={30}
                  ></Icon>
                </View>
              </View>
              <View>
                <Text
                  style={[
                    styles.paymentText,
                    { color: closedProject ? "#FFF" : "#000" },
                  ]}
                >
                  Payment Processed
                </Text>
              </View>
            </View>
          ) : (
            <Text
              style={[
                styles.completeText,
                {
                  color:
                    closedProject && project.completionPercent != 0
                      ? "#FFF"
                      : "#000",
                },
              ]}
            >
              {Math.round(project.completionPercent) || 0}% Complete
            </Text>
          )}
          <Icon
            style={{ position: "absolute", right: -20 }}
            width={70}
            fill={arrowColor ? arrowColor : "#000"}
            name="rightArrow"
          ></Icon>
        </View>
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  mainContainer: {
    // height: 180,
    backgroundColor: "#FFF",
    marginBottom: 10,
    borderRadius: 10,
    alignItems: "center",
    marginHorizontal: 15,
  },
  container: {
    flexDirection: "row",
    borderRadius: 15,
    padding: 3,
    width: "100%",
  },

  completeText: {
    fontSize: 18,
    fontWeight: "bold",
    top: 10,
    right: 5,
  },
  paymentText: {
    color: "#FFF",
    fontSize: 11,
    marginTop: -10,
  },
  iconContainer: {
    marginLeft: 5,
    paddingTop: 2.5,
  },
  rateNumber: {
    color: "#FFD500",
    fontWeight: "bold",
    fontSize: 18,
  },
  paddingView: { paddingBottom: 10, paddingTop: 10 },
  subTextDesign: {
    fontSize: 11,
  },
  leftChildView: {
    // height: 130,
    borderRadius: 10,
    width: "100%",
  },
  secondChild: {
    backgroundColor: "#FFF",
    minHeight: 130,
    borderRadius: 10,
    marginVertical: 5,
    width: "100%",
  },
  dataContainer: { position: "absolute", padding: 20 },
  headerContainer: {
    paddingBottom: 5,
    borderBottomColor: "#707070",
    right: 5,
    // borderBottomWidth: 2,
  },
  headerStyle: {
    fontWeight: "bold",
    fontSize: 16,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 42,
    marginBottom: 5,
  },
  subtitle: {
    fontSize: adjust(13),
    // marginLeft: 10,
    flex: 1,
    marginTop: 5,
  },
});
export default ProjectInprogressCard;
