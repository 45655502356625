import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import RoundButton from "../../components/roundButton";
import ProfessionalProfileComponent from "./professionalProfileComponent";
import Icon from "../../components/icon";
import { connect } from "react-redux";
import Header from "../../components/header";

import {
  fetchAllCreator,
  fetchPendingCreator,
  fetchShortlistedCreator,
} from "../../redux/actions/creator.action";
import adjust from "../../components/adjust";
import ProfileComponent from "./profileComponent";

const CreatorAcceptance = ({
  user,
  navigation,
  creatorList,
  pendingCreatorList,
  shortlistedCreatorList,
  fetchAllCreator,
  fetchPendingCreator,
  fetchShortlistedCreator,
}) => {
  const [activeTab, setActiveTab] = useState(false);
  const [newTab, setNewTab] = useState(true);
  const [shortlisted, setShortlisted] = useState(false);
  useEffect(() => {
    fetchAllCreator();
  }, [!creatorList]);

  useEffect(() => {
    fetchShortlistedCreator();
  }, [!shortlistedCreatorList]);

  useEffect(() => {
    fetchPendingCreator();
  }, [!pendingCreatorList]);

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          title="Creators"
          subtitle="Onedesign Community"
          backFunction={() => navigation.goBack()}
          // enableAvatar={false}
          enableAvatar={true}
          navigation={navigation}
          avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          // avatarOnPress={() => setSettingsModalVisible(true)}
        ></Header>
      </View>

      <View style={{ flex: 4 }}>
        <View
          style={{
            backgroundColor: "#FFFFFF",
            flexDirection: "row",
          }}
        >
          <RoundButton
            title={"New"}
            pressFunc={() => {
              setActiveTab(false);
              setNewTab(true);
              setShortlisted(false);
            }}
            textStyle={{
              color: newTab ? "#FFF" : "#000",
            }}
            style={{
              backgroundColor: newTab ? "#000000" : "#FFF",
              // width: 100,
              borderWidth: 1,
              borderColor: "#000",
              marginBottom: 20,
              marginLeft: 30,
              alignSelf: "flex-end",
            }}
          ></RoundButton>
          <RoundButton
            title={"Shortlisted"}
            pressFunc={() => {
              setActiveTab(false);
              setNewTab(false);
              setShortlisted(true);
            }}
            textStyle={{
              color: shortlisted ? "#FFF" : "#000",
            }}
            style={{
              backgroundColor: shortlisted ? "#000000" : "#FFF",
              borderWidth: 1,
              borderColor: "#000",
              marginBottom: 20,
              marginLeft: 10,
              alignSelf: "flex-end",
            }}
          ></RoundButton>
          <RoundButton
            title={"Active"}
            pressFunc={() => {
              setActiveTab(true);
              setNewTab(false);
              setShortlisted(false);
            }}
            textStyle={{
              color: activeTab ? "#FFF" : "#000",
            }}
            style={{
              backgroundColor: activeTab ? "#000000" : "#FFF",
              borderWidth: 1,
              borderColor: "#000",
              marginBottom: 20,
              marginLeft: 10,
              alignSelf: "flex-end",
            }}
          ></RoundButton>
        </View>

        {newTab ? (
          <View style={{ flex: 3 }}>
            <View
              style={{
                backgroundColor: "#FFFFFF",
                flex: 0.3,
                borderRadius: 8,
                marginTop: 10,
                marginHorizontal: 15,
                padding: 15,
                maxHeight: 120,
              }}
            >
              <Text style={{ fontWeight: "bold" }}>
                New Application Received
              </Text>
              <Text style={{ fontSize: adjust(40) }}>
                {pendingCreatorList.length}
              </Text>
              <Text style={{}}>New Application Received</Text>
            </View>
            <ScrollView
              nestedScrollEnabled={true}
              style={{ marginTop: 10, flex: 1 }}
            >
              {pendingCreatorList.length > 0 ? (
                pendingCreatorList.map((creator, index) => {
                  return (
                    <ProfessionalProfileComponent
                      key={index}
                      Style={{ borderColor: "#FFFFFF" }}
                      level={creator.levelOfWorkId}
                      quotation={false}
                      creatorImage={true}
                      creatorData={creator}
                      creatorType={"pending"}
                      navigation={navigation}
                    ></ProfessionalProfileComponent>
                  );
                })
              ) : (
                <></>
              )}
            </ScrollView>
          </View>
        ) : (
          <></>
        )}
        {shortlisted ? (
          <ScrollView
            nestedScrollEnabled={true}
            style={{ flex: 3, marginTop: 10 }}
          >
            {shortlistedCreatorList.length > 0
              ? shortlistedCreatorList.map((creator, index) => {
                  return (
                    <ProfessionalProfileComponent
                      key={index}
                      Style={{ borderColor: "#FFFFFF" }}
                      name={creator.name}
                      designation={creator.education}
                      level={creator.level}
                      quotation={false}
                      creatorData={creator}
                      creatorImage={true}
                      creatorType={"short"}
                    ></ProfessionalProfileComponent>
                  );
                })
              : null}
          </ScrollView>
        ) : (
          <></>
        )}
        {activeTab ? (
          <ScrollView
            contentContainerStyle={{ paddingBottom: 20 }}
            nestedScrollEnabled={true}
            style={{ flex: 3, marginTop: 10 }}
          >
            <Text style={{ fontSize: 12, fontWeight: "600", marginLeft: 20 }}>
              Sorted by All
            </Text>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {creatorList.length > 0
                ? creatorList.map((creator, index) => {
                    return (
                      <ProfileComponent
                        onPress={() =>
                          navigation.navigate("CreatorProfileByPartner", {
                            data: creator,
                          })
                        }
                        Style={{ borderColor: "#FFFFFF" }}
                        name={creator.name}
                        designation={creator.education}
                        applyingFor={creator.applyingFor}
                        level={creator.level}
                        quotation={false}
                        creatorData={creator}
                        creatorImage={true}
                        disableButtons={true}
                        key={`${creator} + ${index}`}
                        data={creator}
                      ></ProfileComponent>
                    );
                  })
                : null}
            </View>
          </ScrollView>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};
const mapStateToProps = ({
  user,
  creatorList,
  pendingCreatorList,
  shortlistedCreatorList,
}) => ({
  user,
  creatorList,
  pendingCreatorList,
  shortlistedCreatorList,
});
export default connect(mapStateToProps, {
  fetchAllCreator,
  fetchPendingCreator,
  fetchShortlistedCreator,
})(CreatorAcceptance);
