import React from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import adjust from "../components/adjust";
import Header from "../components/header";
const window = Dimensions.get("window");

const Company = ({ navigation, company, user }) => {
  let colors = ["#000000", "#FFD500", "#64C034", "#FF4658", "#0E686B"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
          // flex: 1,
          // backgroundColor: "#FFFFFF",
        }}
      >
        <Header
          title="Onedesign"
          subtitle="Company at a glance"
          backFunction={() => navigation.goBack()}
          titleStyle={{ color: "#000" }}
          // style={{ backgroundColor: "#000" }}
          // textStyle={{ color: "#fff" }}
          // enableAvatar={false}
          enableAvatar={true}
          avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          navigation={navigation}
          // avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          // avatarOnPress={() => setSettingsModalVisible(true)}
        ></Header>
      </View>

      <View style={{ flex: 4, marginTop: 15, paddingHorizontal: 20 }}>
        <View
          style={{
            // maxHeight: 180,
            // minHeight: 180,
            borderRadius: 8,
            backgroundColor: "#ffffff",
            padding: 15,
          }}
        >
          <Text
            style={{
              color: "#828282",
              fontWeight: "bold",
              fontSize: adjust(18),
              maxHeight: 30,
            }}
          >
            Net Income in {monthNames[new Date().getMonth()]}
          </Text>
          <View style={{ paddingTop: 10 }}>
            <Text
              style={{
                color: "#64C034",
                fontSize: adjust(15),
                paddingBottom: 10,
              }}
            >
              {company?.growth ? company.growth.toFixed(2) : 0}% growth this
              month
            </Text>
            <Text
              style={{
                fontSize: adjust(40),
                color: "#FFD500",
                fontWeight: "bold",
                position: "relative",
                bottom: 10,
              }}
            >
              ₹{" "}
              {company && company.current
                ? company.current.companyRevenue
                  ? company.current.companyRevenue
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"
                : "0"}
            </Text>
          </View>
          <View style={{}}>
            <Text
              style={{
                color: "#707070",
                fontSize: adjust(15),
                // position: "relative",
                // top: 5,
              }}
            >
              Total Revenue: ₹{" "}
              {company && company.current
                ? company.current.companyRevenue
                  ? company.current.companyRevenue
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"
                : "0"}
            </Text>
            <Text style={{ color: "#707070", fontSize: adjust(15) }}>
              Cleared: ₹{" "}
              {company && company.current
                ? company.current.companyCleared
                  ? company.current.companyCleared
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"
                : "0"}
            </Text>
            <Text
              style={{
                color: "#707070",
                fontSize: adjust(15),
                // position: "relative",
                // top: 5,
              }}
            >
              Pending: ₹{" "}
              {company && company.current
                ? company.current.companyPending
                  ? company.current.companyPending
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"
                : "0"}
            </Text>
            <Text
              style={{
                color: "#707070",
                fontSize: adjust(15),
                // position: "relative",
                // top: 5,
              }}
            >
              OneDesign Revenue: ₹{" "}
              {company && company.current
                ? company.current.onedesignRevenue
                  ? company.current.onedesignRevenue
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"
                : "0"}
            </Text>
          </View>
        </View>
        <View
          style={{
            borderRadius: 8,
            backgroundColor: "#ffffff",
            padding: 15,
            marginTop: 10,
          }}
        >
          <Text
            style={{
              color: "#828282",
              fontWeight: "bold",
              fontSize: adjust(18),
              maxHeight: 30,
            }}
          >
            Partners
          </Text>
          {company?.partnerShare?.map((p, i) => {
            return (
              <View
                key={i}
                style={{
                  flexDirection: "row",
                  maxHeight: 30,
                  margin: 10,
                }}
              >
                <View
                  style={{
                    // flex: 1,
                    borderRadius: 50,
                    minHeight: 30,
                    maxHeight: 30,
                    minWidth: 30,
                    maxWidth: 30,
                    backgroundColor: colors[i],
                  }}
                ></View>
                <View
                  style={{
                    width: window.width - 130,
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: adjust(15),
                      borderBottomColor: colors[i],
                      width: "100%",
                      marginLeft: 10,
                      marginBottom: 5,
                    }}
                  >
                    {p.user.name}
                  </Text>
                  <View
                    style={{
                      borderBottomColor: colors[i],
                      borderBottomWidth: 7,
                      width: p.percent + "%",
                      minWidth: p.percent + "%",
                      marginLeft: 10,
                      marginRight: 30,
                    }}
                  ></View>
                </View>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flex: 1,
    maxHeight: 180,
    backgroundColor: "#000000",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 20,
  },
  backButton: {
    flex: 1,
    position: "absolute",
    marginTop: 40,
    flexDirection: "row",
    alignItems: "center",
    zIndex: 999,
  },
});

const mapStateToProps = ({ company, user }) => ({
  company,
  user,
});
export default connect(mapStateToProps, {})(Company);
