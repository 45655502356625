import { addError, addInfo, addWarning } from "../actions/toast.action";

const initialState = [];
const helpMessages = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_HELP_SUCCESS": {
      return [action.payload.data, ...state];
    }

    case "FETCH_HELP_SUCCESS": {
      return action.payload.data;
    }

    case "CREATE_HELP_FAIL":
    case "FETCH_HELP_FAIL": {
      action.asyncDispatch(addError(action.error.response?.data.message, 3000));
      return state;
    }

    case "CLEAR_HELP_MESSAGES":
    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default helpMessages;
