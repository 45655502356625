import React, { useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
  Button,
  TouchableWithoutFeedback,
} from "react-native";
import MyPaymentsCard from "../components/myPaymentsCard";
import { connect } from "react-redux";
import Header from "../components/header";
import RoundButton from "../components/roundButton";
import {
  fetchMypaymentCurrentMonth,
  fetchMypaymentPreviousMonth,
} from "../redux/actions/payment.action";
import {
  fetchPaymentReport,
  redeemPayment,
} from "../redux/actions/paymentReports.action";
import adjust from "../components/adjust";
import Modal from "../components/modal/modal";
import { BlurView } from "expo-blur";
import Icon from "../components/icon";
import LargeTextInput from "../components/largeTextInput";
import { LineChart } from "react-native-chart-kit";

const MyPayments = ({
  navigation,
  user,
  myPaymentCurrentMonth,
  myPaymentPrevious,
  fetchMypaymentPreviousMonth,
  fetchMypaymentCurrentMonth,
  fetchPaymentReport,
  paymentReports,
  dashboardStats,
  redeemPayment,
}) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [currentTab, setCurrentTab] = useState(true);
  const [previousTab, setPreviousTab] = useState(false);
  const [claimModal, setClaimModal] = useState(false);
  const [claimAmount, setClaimAmount] = useState("");
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    fetchMypaymentPreviousMonth();
    fetchMypaymentCurrentMonth();
    fetchPaymentReport();
  }, []);
  return (
    <View
      style={{
        flex: 1,
        maxHeight: Dimensions.get("window").height,
        flexDirection: "column",
      }}
    >
      <View style={[styles.mainContainer]}>
        <Header
          title="My Payments"
          subtitle="Manage all your payment here"
          backFunction={() => navigation.goBack()}
          enableAvatar={true}
          avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          avatarOnPress={() => setSettingsModalVisible(true)}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ flex: 4, padding: 0 }}>
        <View
          style={{
            flexDirection: "row",
            marginTop: 0,
            backgroundColor: "#fff",
          }}
        >
          <RoundButton
            title={"Current"}
            pressFunc={() => {
              setCurrentTab(true);
              setPreviousTab(false);
              // onCLick(myPaymentCurrentMonth);
            }}
            textStyle={{
              color: currentTab ? "#FFF" : "#000",
            }}
            style={{
              backgroundColor: currentTab ? "#000000" : "#FFF",
              // width: 100,
              borderWidth: 1,
              borderColor: "#000",
              marginBottom: 20,
              marginLeft: 30,
            }}
          ></RoundButton>
          <RoundButton
            title={"Previous"}
            pressFunc={() => {
              setCurrentTab(false);
              setPreviousTab(true);
              // onCLick(myPaymentPrevious);
            }}
            textStyle={{
              color: previousTab ? "#FFF" : "#000",
            }}
            style={{
              backgroundColor: previousTab ? "#000000" : "#FFF",
              // width: 100,
              borderWidth: 1,
              borderColor: "#000",
              marginBottom: 20,
              marginLeft: 10,
            }}
          ></RoundButton>
        </View>
        <View style={{ padding: 10, marginHorizontal: 10 }}>
          {currentTab ? (
            <ScrollView nestedScrollEnabled={true}>
              <View style={styles.tabHeaderContainer}>
                <Text
                  style={{
                    color: "gray",
                    fontWeight: "bold",
                    fontSize: adjust(15),
                  }}
                >
                  {monthNames[new Date().getMonth()]}
                </Text>
                <Text
                  style={{
                    color: "#FFD500",
                    fontWeight: "bold",
                    fontSize: adjust(40),
                  }}
                >
                  ₹{" "}
                  {(
                    Math.round(
                      (dashboardStats.clearingAmount -
                        (dashboardStats.toBeGiven || 0)) *
                        100
                    ) / 100
                  )
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Text style={{ color: "gray" }}>
                      Clearing on 01st {monthNames[new Date().getMonth() + 1]},{" "}
                      {new Date().getFullYear()}
                    </Text>
                    <Text style={{ color: "gray" }}>
                      Gross Clearing Amount:{" "}
                      {dashboardStats.clearingAmount
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Text>
                    <Text style={{ color: "gray" }}>
                      Amount Payable:{" "}
                      {(dashboardStats.toBeGiven || 0)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Text>
                  </View>
                  <RoundButton
                    title={"Claim"}
                    pressFunc={() => setClaimModal(!claimModal)}
                    style={{
                      backgroundColor: "#000000",
                      marginTop: 0,
                      minHeight: 30,
                    }}
                  ></RoundButton>
                </View>
              </View>
              <View
                style={{
                  borderTopWidth: 1,
                  borderTopColor: "gray",
                }}
              >
                <Text style={{ padding: 10 }}>Transactions</Text>
              </View>
              <View style={{ flex: 1, marginBottom: 70 }}>
                {myPaymentCurrentMonth.length > 0 ? (
                  myPaymentCurrentMonth.map((payment, index) => {
                    return (
                      <MyPaymentsCard
                        key={index}
                        payment={payment}
                        user={user}
                      ></MyPaymentsCard>
                    );
                  })
                ) : (
                  <></>
                )}
              </View>
              <Modal
                animationType="fade"
                transparent={true}
                presentationStyle="overFullScreen"
                visible={claimModal}
                onRequestClose={() => {}}
                ariaHideApp={false}
              >
                <View style={styles.centeredView}>
                  <BlurView
                    style={styles.modalView}
                    intensity={80}
                    tint="dark"
                    blurType="dark"
                    blurAmount={32}
                    blurRadius={100}
                  >
                    <View
                      style={{
                        maxWidth: 350,
                        backgroundColor: "#FFFFFF",
                        width: "80%",
                        borderRadius: 10,
                        padding: 15,
                        height: 230,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          paddingBottom: 30,
                          minHeight: 30,
                        }}
                      >
                        <Icon name="box3D" style={styles.icon}></Icon>
                        <Text
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            color: "#A9A9A9",
                          }}
                        >
                          Claim payment
                        </Text>
                      </View>
                      <LargeTextInput
                        placeholder={"Enter amount"}
                        placeholderColor="#D4D4D4"
                        onChangeText={(e) => setClaimAmount(e)}
                        value={claimAmount}
                        style={{
                          backgroundColor: "#F1F1F1",
                          borderBottomWidth: 0,
                          width: "100%",
                          marginLeft: 0,
                          maxHeight: 80,
                          borderRadius: 10,
                          fontSize: adjust(40),
                          marginTop: 10,
                        }}
                        keyboardType="numeric"
                      ></LargeTextInput>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          marginTop: 30,
                        }}
                      >
                        <RoundButton
                          style={{ backgroundColor: "#FFD500" }}
                          title={"Redeem"}
                          pressFunc={() => {
                            setClaimModal(!claimModal);
                            redeemPayment({ amount: claimAmount });
                          }}
                        ></RoundButton>
                        <RoundButton
                          style={{ backgroundColor: "#000000", marginLeft: 10 }}
                          title={"Cancel"}
                          pressFunc={() => {
                            setClaimModal(!claimModal);
                          }}
                        ></RoundButton>
                      </View>
                    </View>
                  </BlurView>
                </View>
              </Modal>
            </ScrollView>
          ) : (
            <></>
          )}
          {previousTab ? (
            <ScrollView>
              {paymentReports && paymentReports.length > 0 ? (
                <View style={styles.tabHeaderContainer}>
                  <Text
                    style={{
                      color: "gray",
                      fontWeight: "bold",
                      fontSize: adjust(18),
                      paddingLeft: 15,
                      paddingTop: 10,
                    }}
                  >
                    My Income Trend
                  </Text>
                  <LineChart
                    data={{
                      label: "Showing last 12 Months",
                      // labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                      datasets: [
                        {
                          data: paymentReports?.map((x) => x.sum),
                          color: (opacity = 1) => "#E3E3E3",
                        },
                      ],
                    }}
                    yAxisLabel=""
                    width={Dimensions.get("window").width - 60} // from react-native
                    // height={220}
                    // width={200}
                    // width={width || Dimensions.get("window").width - 70} // from react-native
                    withHorizontalLabels={false}
                    withVerticalLabels={false}
                    height={100}
                    getDotColor={() => "#FFD500"}
                    chartConfig={{
                      backgroundColor: "#FFF",
                      backgroundGradientFrom: "#FFF",
                      backgroundGradientTo: "#FFF",
                      decimalPlaces: 0, // optional, defaults to 2dp
                      color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                      style: {
                        borderRadius: 10,
                      },
                    }}
                    bezier
                    style={{
                      borderRadius: 10,
                      paddingRight: 20,
                    }}
                  />
                  <Text
                    style={{
                      color: "gray",
                      fontSize: adjust(15),
                      paddingLeft: 15,
                      paddingBottom: 10,
                    }}
                  >
                    Showing Last 12 Months
                  </Text>
                </View>
              ) : (
                <></>
              )}

              <View
                style={{
                  borderTopWidth: 1,
                  borderTopColor: "gray",
                }}
              >
                <Text style={{ padding: 10 }}>Transactions</Text>
              </View>
              <View style={{ flex: 1 }}>
                {myPaymentPrevious.length > 0 ? (
                  myPaymentPrevious.map((payment, index) => {
                    return (
                      <MyPaymentsCard
                        key={index}
                        payment={payment}
                        user={user}
                      ></MyPaymentsCard>
                    );
                  })
                ) : (
                  <></>
                )}
              </View>
            </ScrollView>
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tabHeaderContainer: {
    padding: 10,
    // maxHeight: 125,
    // minHeight: 125,
    backgroundColor: "white",
    marginBottom: 15,
    borderRadius: 10,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: 10,
    flex: 1,
    flexDirection: "column",
  },
  textClass: {
    fontWeight: "bold",
    fontSize: adjust(10),
    color: "gray",
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    marginBottom: 30,
  },
  icon: {
    // marginLeft: 20,
  },
  customRatingBarStyle: {
    flexDirection: "row",
    marginTop: 5,
  },
  chartContainer: {
    position: "relative",
    // top: 95,
    // flex: 1,
    height: "40%",
  },
});

const mapStateToProps = ({
  user,
  myPaymentCurrentMonth,
  myPaymentPrevious,
  paymentReports,
  dashboardStats,
}) => ({
  user,
  myPaymentCurrentMonth,
  myPaymentPrevious,
  paymentReports,
  dashboardStats,
});

export default connect(mapStateToProps, {
  fetchMypaymentPreviousMonth,
  fetchMypaymentCurrentMonth,
  fetchPaymentReport,

  redeemPayment,
})(MyPayments);
