import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import WelcomeMessageComponent from "../../components/welcome_message_compoment/welcomeMessageComponent";
import DashboardCard from "../../components/dashboardCard";
import ItemWithCountValue from "../../components/itemWithCountValue/itemWithCountValue";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import adjust from "../../components/adjust";
import { useFocusEffect } from "@react-navigation/native";
import { fetchDashboardStats } from "../../redux/actions/dashboard.action";
import { fetchClients } from "../../redux/actions/user.action";
import {
  fetchAllActiveProjects,
  fetchAllClosedProjects,
} from "../../redux/actions/project.action";
import { fetchPendingProjects } from "../../redux/actions/pendingProjects.action";
import { fetchAllProjects } from "../../redux/actions/newProjects.action";
import { abbrNum, primaryColor } from "../../components/utils";
import { fetchAllCreator } from "../../redux/actions/creator.action";
import moment from "moment";
import { fetchWelcomeMessage } from "../../redux/actions/welcome-message.action";

const Dashboard = ({
  user,
  navigation,
  dashboardStats,
  fetchDashboardStats,
  fetchClients,
  clientList,
  newProjects,
  pendingProjects,
  closedProjects,
  fetchAllActiveProjects,
  fetchAllClosedProjects,
  fetchPendingProjects,
  fetchAllProjects,
  fetchAllCreator,
  creatorList,
  fetchWelcomeMessage,
  welcomeMessages,
}) => {
  const dimension = useWindowDimensions();

  useFocusEffect(
    React.useCallback(() => {
      fetchClients();
      fetchDashboardStats();
      fetchAllClosedProjects();
      fetchAllActiveProjects();
      fetchPendingProjects();
      fetchAllProjects();
      fetchAllCreator();
      fetchWelcomeMessage("PARTNER");
    }, [])
  );
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: "18%",
          maxHeight: "18%",
          // backgroundColor: "red",
        }}
      >
        <Header
          title={`Welcome, ${user.fName}`}
          subtitle={"Let’s create greatness today!"}
          disableBackButton="true"
          style={{ backgroundColor: null, marginTop: 20 }}
          enableAvatar={true}
          navigation={navigation}
        ></Header>
      </View>

      {welcomeMessages.length ? (
        <ScrollView
          nestedScrollEnabled={true}
          style={{ maxHeight: 80 }} // due to scrollview issue in mobile i am giving fix height
          horizontal={true}
        >
          {welcomeMessages.map((x) => (
            <WelcomeMessageComponent
              key={x._id}
              textColor={"#FFF"}
              backgroundColor="#073257"
              message={x.message}
              image={x.image}
            ></WelcomeMessageComponent>
          ))}
        </ScrollView>
      ) : (
        <></>
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, height: "100%", marginHorizontal: 10 }}
      >
        <DashboardCard
          jsx={
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("Project", { screen: "myProjects" })
              }
              style={{
                height: "100%",
                borderRadius: 8,
                backgroundColor: "#2F47FF",
              }}
            >
              <Text style={styles.cardTitleStyle}>
                {moment().format("MMMM") + " Metrics"}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  bottom: 0,
                  position: "absolute",
                }}
              >
                <ItemWithCountValue
                  textColor={"#FFF"}
                  countValue={dashboardStats.client || 0}
                  itemName={"Clients"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  textColor={"#FFF"}
                  countValue={dashboardStats.newProjects || 0}
                  itemName={"Projects"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  textColor={"#FFF"}
                  countValue={abbrNum(dashboardStats?.revenue, 2) || 0}
                  itemName={"Revenue"}
                ></ItemWithCountValue>
              </View>
            </TouchableOpacity>
          }
          disableIcon={true}
          icon={false}
          style={{
            maxHeight: 150,
            borderWidth: 1,
            borderColor: "#073257",
            minHeight: 150,
          }}
        ></DashboardCard>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 10,
            marginTop: 10,
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#31798E",
              maxHeight: dimension.width / 2 - 30,
              minHeight: dimension.width / 2 - 30,
              maxWidth: dimension.width / 2 - 30,
              minWidth: dimension.width / 2 - 30,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 20,
              justifyContent: "space-between",
            }}
            onPress={() => navigation.navigate("userProfileList")}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(16),
                marginLeft: 15,
                color: "#fff",
                marginTop: 10,
              }}
            >
              My Clients
            </Text>
            <View style={{ marginBottom: 0 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: adjust(40),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                {`${dashboardStats.client || 0}`}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                New Client Added
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("partnerNewProjectList")}
            style={{
              backgroundColor: "#FF9A24",
              maxHeight: dimension.width / 2 - 30,
              minHeight: dimension.width / 2 - 30,
              maxWidth: dimension.width / 2 - 30,
              minWidth: dimension.width / 2 - 30,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 20,
              justifyContent: "space-between",
            }}
          >
            <Text style={styles.newProjectStyle}>New Projects</Text>
            <View style={{ marginBottom: 0 }}>
              <Text style={styles.newProjectCountStyle}>
                {`${newProjects?.length || 0}`}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                new projects
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ marginHorizontal: 10 }}>
          <DashboardCardWithArrowAndIcon
            onPressCard={() => navigation.navigate("creator-list")}
            source={require("../../assets/user-shapes.png")}
            description="New Applications Recieved. Tap to see"
            value={
              `${
                creatorList.filter((x) =>
                  ["REVIEWING", "INCOMPLETE"].includes(x.status)
                )?.length
              }` || 0
            }
            title="Creators"
            backgroundColor="#7A95C1"
          ></DashboardCardWithArrowAndIcon>
        </View>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({
  user,
  dashboardStats,
  clientList,
  newProjects,
  pendingProjects,
  closedProjects,
  creatorList,
  welcomeMessages,
}) => ({
  user,
  dashboardStats,
  clientList,
  newProjects,
  pendingProjects,
  closedProjects,
  creatorList,
  welcomeMessages,
});
export default connect(mapStateToProps, {
  fetchDashboardStats,
  fetchClients,
  fetchAllActiveProjects,
  fetchAllClosedProjects,
  fetchPendingProjects,
  fetchAllProjects,
  fetchAllCreator,
  fetchWelcomeMessage,
})(Dashboard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  cardTitleStyle: {
    alignSelf: "flex-start",
    margin: 10,
    fontSize: 16,
    color: "#FFF",
    fontWeight: "bold",
  },
  newProjectStyle: {
    fontWeight: "bold",
    fontSize: adjust(16),
    marginLeft: 15,
    color: "#fff",
    marginTop: 10,
    alignSelf: "flex-start",
  },
  newProjectCountStyle: {
    fontWeight: "bold",
    fontSize: adjust(40),
    marginLeft: 15,
    color: "#fff",
    marginBottom: 10,
  },
});
