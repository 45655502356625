export const fetchTechnicalExpertise = () => (dispatch) => {
  return dispatch({
    type: "FETCH_TECHNICAL_EXPERTISE",
    payload: {
      request: {
        url: `/api/technical-expertise`,
        method: "GET",
      },
    },
  });
};

export const createTechnicalExpertise = (Obj) => (dispatch) => {
  return dispatch({
    type: "CREATE_TECHNICAL_EXPERTISE",
    payload: {
      request: {
        url: `/api/technical-expertise`,
        method: "POST",
        data: Obj,
      },
    },
  });
};

export const removeTechnicalExpertise = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_TECHNICAL_EXPERTISE",
    payload: {
      request: {
        url: `/api/technical-expertise/${id}`,
        method: "DELETE",
      },
    },
  });
};
