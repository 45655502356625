export const setRequirements = (requirementObj) => ({
  type: "SET_REQUIREMENTS",
  payload: { data: requirementObj },
});

export const clearRequirements = () => ({
  type: "CLEAR_REQUIREMENTS",
});

export const setSelectedRequirement = (requirementObj) => ({
  type: "SET_SELECTED_REQUIREMENT",
  payload: { data: requirementObj },
});

export const clearSelectedRequirement = () => ({
  type: "CLEAR_SELECTED_REQUIREMENT",
});
