import React, { useEffect, useRef, useState } from "react";
import {
  Platform,
  SafeAreaView,
  StatusBar as RNStatusBar,
  useWindowDimensions,
} from "react-native";
import useCachedResources from "./hooks/useCachedResources";
import EntryStack from "./navigation/entryStack";
import { persister, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Toast from "./components/toast";
import Loader from "./components/loader";
import LeftScreen from "./screens/leftSideScreenLandscape";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { decode, encode } from "base-64";
import WebViewScreen from "./screens/webview";
import { Firebase } from "./config/firebase/firebase";
import * as Notifications from "expo-notifications";
import DeviceInfo from "react-native-device-info";
import { StatusBar as ExpoStatusBar } from "expo-status-bar";
import { RouteContext } from "./components/routeContext";

const isSimulator = () => {
  // https://github.com/react-native-community/react-native-device-info#isemulator
  return DeviceInfo.isEmulator();
};
import {
  AndroidImportance,
  AndroidNotificationVisibility,
} from "expo-notifications";
if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}
const MainStack = createStackNavigator();

if (!(Platform.OS === "ios" && isSimulator()))
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });

const channelId = "oneDesign";

export default function App() {

  const { width, height } = useWindowDimensions()


  async function setNotificationChannel() {
    if (!(Platform.OS === "ios" && isSimulator())) {
      const loadingChannel = await Notifications.getNotificationChannelAsync(
        channelId
      );

      // if we didn't find a notification channel set how we like it, then we create one
      if (loadingChannel == null) {
        const channelOptions = {
          name: channelId,
          importance: AndroidImportance.LOW,
          lockscreenVisibility: AndroidNotificationVisibility.PUBLIC,
          sound: "default",
          vibrationPattern: [250],
          enableVibrate: true,
        };
        await Notifications.setNotificationChannelAsync(
          channelId,
          channelOptions
        );
      }
    }
  }

  useEffect(() => {
    setNotificationChannel();
  }, []);

  // // IMPORTANT: You MUST obtain MEDIA_LIBRARY permissions for the file download to succeed
  // // If you don't the downloads will fail
  // async function getMediaLibraryPermissions() {
  //   if (Platform.OS !== "web")
  //     await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
  // }

  // // You also MUST obtain NOTIFICATIONS permissions to show any notification
  // // to the user. Please read the docs for more on permissions for notifications
  // // https://docs.expo.io/versions/latest/sdk/notifications/#fetching-information-about-notifications-related-permissions
  // async function getNotificationPermissions() {
  //   if (!(Platform.OS === "ios" && isSimulator()))
  //     await Permissions.askAsync(Permissions.NOTIFICATIONS);
  // }

  // useEffect(() => {
  //   getMediaLibraryPermissions();
  // }, []);

  // useEffect(() => {
  //   getNotificationPermissions();
  // }, []);

  const navigationRef = useRef();

  const [currentRoute, setCurrentRoute] = useState("main");

  let windowHeight = height;
  let deviceHeight = height;
  let bottomNavBarHeight = deviceHeight - windowHeight;

  const statusbarHeight =
    Platform.OS === "android" ? RNStatusBar.currentHeight : 0;

  let isLoadingComplete = useCachedResources();
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store} style={{ flex: 1 }}>
        <PersistGate loading={null} persistor={persister} style={{ flex: 1 }}>
          <RouteContext.Provider value={currentRoute}>
            {width < 1080 ? (
              Platform.OS != "web" ? (
                <KeyboardAwareScrollView
                  bounces={false}
                  alwaysBounceHorizontal={false}
                  alwaysBounceVertical={false}
                  enableOnAndroid={true}
                  // scrollToOverflowEnabled={true}
                  nestedScrollEnabled={true}
                  // enableAutomaticScroll={true}
                  // bounces={false}
                  // extraScrollHeight={100}
                  // extraHeight={30}
                  scrollEnabled={false}
                  keyboardShouldPersistTaps="handled"
                >
                  <SafeAreaView
                    key={"safeAreaView"}
                    style={{
                      paddingTop: 0,
                      flex: 1,
                      minHeight:
                        windowHeight +
                        (bottomNavBarHeight > 25 ? statusbarHeight : 0),
                      maxHeight:
                        windowHeight +
                        (bottomNavBarHeight > 25 ? statusbarHeight : 0),
                    }}
                  >
                    <RNStatusBar
                      barStyle="light-content"
                      animated={true}
                      backgroundColor="#ea392f"
                    />
                    <NavigationContainer
                      ref={navigationRef}
                      onReady={() =>
                        setCurrentRoute(
                          navigationRef.current?.getCurrentRoute().name
                        )
                      }
                      onStateChange={() => {
                        if (
                          currentRoute.currentRoute !==
                          navigationRef.current?.getCurrentRoute().name
                        ) {
                          setCurrentRoute(
                            navigationRef.current?.getCurrentRoute().name
                          );
                        }
                      }}
                      linking={{ enabled: true }}
                    >
                      <MainStack.Navigator>
                        <MainStack.Screen
                          component={EntryStack}
                          options={{ headerShown: false }}
                          name="main"
                        ></MainStack.Screen>
                        <MainStack.Screen
                          component={WebViewScreen}
                          options={{ headerShown: false }}
                          name="web"
                        ></MainStack.Screen>
                      </MainStack.Navigator>
                    </NavigationContainer>
                    <ExpoStatusBar
                      style="auto"
                      hidden={false}
                      translucent={true}
                    />
                    <Toast></Toast>
                    <Firebase></Firebase>
                    <Loader></Loader>
                  </SafeAreaView>
                </KeyboardAwareScrollView>
              ) : (
                <SafeAreaView
                  key={"safeAreaView"}
                  style={{
                    paddingTop: Platform.OS === "android" ? statusbarHeight : 0,
                    flex: 1,
                    minHeight: windowHeight,
                    maxHeight: windowHeight,
                    height: windowHeight,
                  }}
                >
                  <NavigationContainer
                    ref={navigationRef}
                    onReady={() =>
                      setCurrentRoute(
                        navigationRef.current?.getCurrentRoute().name
                      )
                    }
                    onStateChange={async () => {
                      if (
                        currentRoute.currentRoute !==
                        navigationRef.current?.getCurrentRoute().name
                      ) {
                        setCurrentRoute(
                          navigationRef.current?.getCurrentRoute().name
                        );
                      }
                    }}
                    linking={{ enabled: true }}
                  >
                    <MainStack.Navigator>
                      <MainStack.Screen
                        component={EntryStack}
                        options={{ headerShown: false }}
                        name="main"
                      ></MainStack.Screen>
                      <MainStack.Screen
                        component={WebViewScreen}
                        options={{ headerShown: false }}
                        name="web"
                      ></MainStack.Screen>
                    </MainStack.Navigator>
                  </NavigationContainer>
                  <ExpoStatusBar style="auto" hidden={false} />
                  <Toast></Toast>
                  <Firebase></Firebase>
                  <Loader></Loader>
                </SafeAreaView>
              )
            ) : Platform.OS != "web" ? (
              <KeyboardAwareScrollView
                enableOnAndroid={false}
                nestedScrollEnabled={true}
                enableAutomaticScroll={true}
                bounces={false}
                extraHeight={10}
                // extraScrollHeight={100}
                keyboardShouldPersistTaps="handled"
              >
                <SafeAreaView
                  key={"safeAreaView"}
                  style={{
                    paddingTop: 0,
                    flex: 1,
                    minHeight: windowHeight,
                    maxHeight: windowHeight,
                    flexDirection: "row",
                  }}
                >
                  <NavigationContainer
                    ref={navigationRef}
                    onReady={() =>
                      setCurrentRoute(
                        navigationRef.current?.getCurrentRoute().name
                      )
                    }
                    onStateChange={async () => {
                      if (
                        currentRoute.currentRoute !==
                        navigationRef.current?.getCurrentRoute().name
                      ) {
                        setCurrentRoute(
                          navigationRef.current?.getCurrentRoute().name
                        );
                      }
                    }}
                    linking={{ enabled: true }}
                  >
                    <MainStack.Navigator>
                      <MainStack.Screen
                        component={LeftScreen}
                        options={{ headerShown: false }}
                        name="main"
                      ></MainStack.Screen>
                    </MainStack.Navigator>
                  </NavigationContainer>
                  <Toast></Toast>
                  <Firebase></Firebase>
                  <Loader></Loader>
                </SafeAreaView>
              </KeyboardAwareScrollView>
            ) : (
              <SafeAreaView
                key={"safeAreaView"}
                style={{
                  paddingTop: 0,
                  flex: 1,
                  minHeight: windowHeight,
                  maxHeight: windowHeight,
                  flexDirection: "row",
                }}
              >
                <NavigationContainer
                  ref={navigationRef}
                  onReady={() =>
                    setCurrentRoute(
                      navigationRef.current?.getCurrentRoute().name
                    )
                  }
                  onStateChange={async () => {
                    if (
                      currentRoute.currentRoute !==
                      navigationRef.current?.getCurrentRoute().name
                    ) {
                      setCurrentRoute(
                        navigationRef.current?.getCurrentRoute().name
                      );
                    }
                  }}
                  linking={{ enabled: true }}
                >
                  <MainStack.Navigator>
                    <MainStack.Screen
                      component={LeftScreen}
                      options={{ headerShown: false }}
                      name="main"
                    ></MainStack.Screen>
                  </MainStack.Navigator>
                </NavigationContainer>
                <Toast></Toast>
                <Firebase></Firebase>
                <Loader></Loader>
              </SafeAreaView>
            )}
          </RouteContext.Provider>
        </PersistGate>
      </Provider>
    );
  }
}
