import React from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  FlatList,
  TouchableOpacity,
} from "react-native";
import Icon from "./components/icon";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import adjust from "./components/adjust";
import { connect } from "react-redux";
import {
  clearNotifications,
  fetchNotifications,
  seenAllNotifications,
  markAsRead,
} from "./redux/actions/notification.action";

const NotificationDrawer = ({
  props,
  notifications,
  fetchNotifications,
  clearNotifications,
  seenAllNotifications,
  markAsRead,
  user,
  notificationCount,
}) => {
  // useFocusEffect(() => {
  //   fetchNotifications();
  // }, []);
  const onScroll = () => {
    if (notifications.fetch)
      fetchNotifications(
        notifications.notifications[notifications.notifications.length - 1]._id
      );
  };

  const clear = () => {
    clearNotifications();
  };

  const seen = () => {
    seenAllNotifications();
  };
  return (
    <DrawerContentScrollView {...props} style={{ backgroundColor: "#FAFAFA" }}>
      <View style={styles.profile}>
        <Image
          style={{
            width: 200,
            height: 40,
          }}
          source={require("./assets/logo.png")}
        ></Image>
        <Text style={styles.profileText}>{`Welcome`}</Text>
      </View>
      {/* <DrawerItemList {...props} /> */}
      {notificationCount ? (
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            marginVertical: 5,
            marginHorizontal: 3,
          }}
        >
          <TouchableOpacity
            onPress={() => seen()}
            style={{
              backgroundColor: "#FAFAFA",
              padding: 5,
              marginRight: 5,
              borderRadius: 5,
            }}
          >
            <Text style={{ fontSize: adjust(18) }}>Mark All as Seen</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => clear()}
            style={{
              backgroundColor: "#FAFAFA",
              padding: 5,
              marginRight: 5,
              borderRadius: 5,
            }}
          >
            <Text style={{ fontSize: adjust(18) }}>Clear</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
      <FlatList
        scrollEnabled={true}
        data={notifications.notifications}
        keyExtractor={(item) => item._id.toString()}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => markAsRead(item._id)}
            style={{
              backgroundColor: item.users.find(
                (x) => x.user === user._id && x.seen
              )
                ? "#FAFAFA"
                : "#d2e7fa",
              justifyContent: "center",
              paddingTop: 2,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: 5,
                marginRight: 5,
                minHeight: 35,
              }}
            >
              <View
                style={{
                  height: 45,
                  backgroundColor: "#EBE0D0",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <Icon name="exclamation" fill="#3D0610"></Icon>
              </View>
              <Text
                ellipsizeMode="tail"
                numberOfLines={3}
                style={styles.menuText}
              >
                {item?.message?.body}
              </Text>
            </View>
            <View style={styles.sidebarDivider}></View>
          </TouchableOpacity>
        )}
      />
      {notifications.fetch ? (
        <TouchableOpacity onPress={() => onScroll()}>
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 5,
              marginRight: 5,
              minHeight: 35,
            }}
          >
            <Text style={{ fontSize: adjust(18) }}>Load More...</Text>
          </View>
          <View style={styles.sidebarDivider}></View>
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  sideMenuProfileIcon: {
    resizeMode: "center",
    width: 100,
    height: 100,
    borderRadius: 100 / 2,
    alignSelf: "center",
  },
  sidebarDivider: {
    height: 1,
    width: "100%",
    backgroundColor: "lightgray",
    paddingBottom: 2,
  },
  customItem: {
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  profile: {
    margin: 20,
    flex: 1,
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  profileText: {
    fontWeight: "bold",
    fontSize: adjust(14),
  },
  menuText: {
    fontSize: adjust(15),
    color: "#000000",
    textAlign: "left",
    marginLeft: 10,
    paddingRight: 30,
  },
});

const mapStateToProps = ({ user, notifications, notificationCount }) => ({
  user,
  notifications,
  notificationCount,
});
export default connect(mapStateToProps, {
  fetchNotifications,
  clearNotifications,
  seenAllNotifications,
  markAsRead,
})(NotificationDrawer);
