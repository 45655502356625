import * as React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { connect } from "react-redux";
import TabNavigationDesign from "../tabNavigationDesign";
import CreatorStack from "./creator-stack";
import CreatorProjectStack from "./creator-project-stack";
import CreatorNewStack from "./creator-new-stack";
import CreatorInboxStack from "./creator-inbox-stack";
import creatorProfileStack from "./creator-profile-stack";
const Tab = createBottomTabNavigator();

const CreatorTabNavigation = ({ user }) => {
  return (
    <Tab.Navigator
      tabBar={(props) => <TabNavigationDesign {...props} />}
      initialRouteName="Home"
      unmountInactiveTabs={true}
      screenOptions={{ unmountOnBlur: true, headerShown: false }}
    >
      <Tab.Screen
        name="Home"
        component={CreatorStack}
        options={{
          icon: "home",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Project"
        component={CreatorProjectStack}
        options={{
          icon: "project",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="New"
        component={CreatorNewStack}
        options={{
          icon: "plus_round",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Inbox"
        component={CreatorInboxStack}
        options={{
          icon: "inbox",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Profile"
        component={creatorProfileStack}
        options={{
          image: true,
          icon: "profile",
          unmountOnBlur: true,
        }}
      />
    </Tab.Navigator>
  );
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {})(CreatorTabNavigation);
