import React from "react";
import Header from "../../components/header";
import { View, Dimensions } from "react-native";
import { connect } from "react-redux";
import ProjectList from "./projects-list";

const Myprojects = ({ user, navigation }) => {
  return (
    <View style={{ flex: 1, maxHeight: Dimensions.get("window").height }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          disableBackButton={true}
          title="my projects"
          subtitle="Manage your projects"
          enableAvatar={user.userType === "ONEDESIGN" ? false : true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <ProjectList user={user} navigation={navigation} />
    </View>
  );
};
const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps, {})(Myprojects);
