import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ScrollView,
} from "react-native";
import Icon from "../../../components/icon";
import ProgressComponent from "./progressComponent";
import { connect } from "react-redux";
import adjust from "../../../components/adjust";
import Header from "../../../components/header";
const Progress = ({ navigation, selectedProject }) => {
  return (
    <View style={{ flex: 1, marginTop: 40 }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          countStyle={{
            position: "absolute",
            top: -12,
            right: 3,
            backgroundColor: "#FF000060",
            fontWeight: "700",
            borderRadius: 20,
            height: 20,
            justifyContent: "center",
          }}
          title="Back to Project"
          subtitle={""}
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <View style={{ flex: 9 }}>
        <ScrollView
          nestedScrollEnabled={true}
          style={{
            padding: 20,
            height: "100%",
          }}
        >
          <View style={[styles.container, { backgroundColor: "#000" }]}>
            <Text style={[styles.mainHeader]}>{selectedProject.title}</Text>
          </View>
          <View style={{ flex: 1 }}>
            {selectedProject.paymentPhases ? (
              selectedProject.paymentPhases.map((p, i) => {
                return (
                  <ProgressComponent
                    key={i}
                    phase={p}
                    phaseNumber={i + 1}
                  ></ProgressComponent>
                );
              })
            ) : (
              <></>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({ selectedProject, user }) => ({
  selectedProject,
  user,
});
export default connect(mapStateToProps, {})(Progress);
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  mainHeader: {
    flex: 1,
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#ffffff",
    marginBottom: 5,
  },
});
