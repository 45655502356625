export const fetchProjectSkill = () => (dispatch) => {
  return dispatch({
    type: "FETCH_SKILL",
    payload: {
      request: {
        url: `/api/project-skill`,
        method: "GET",
      },
    },
  });
};

export const createSkill = (skillObj) => (dispatch) => {
  return dispatch({
    type: "CREATE_SKILL",
    payload: {
      request: {
        url: `/api/project-skill`,
        method: "POST",
        data: skillObj,
      },
    },
  });
};

export const removeSkill = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_SKILL",
    payload: {
      request: {
        url: `/api/project-skill/${id}`,
        method: "DELETE",
      },
    },
  });
};
