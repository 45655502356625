import React from "react";
import { View, Text, TouchableOpacity, Image, Platform } from "react-native";

const ProjectTemplateComponent = ({ data, onPress, disabled }) => {
  return (
    <TouchableOpacity
      disabled={disabled ? true : false}
      onPress={onPress}
      style={{
        flexDirection: "row",
        width: "95%",
        margin: 10,
        padding: 15,
        backgroundColor: "#FFF",
        borderRadius: 7,
      }}
    >
      <View style={{ height: 70, width: 70, borderRadius: 8 }}>
        <Image
          style={{ height: 70, width: 70, borderRadius: 8 }}
          source={{
            uri:
              Platform.OS === "web"
                ? data.image
                : "data:image/jpeg;base64," + data.image,
          }}
        ></Image>
      </View>

      <View style={{ marginLeft: 5 }}>
        <Text
          style={{
            fontSize: 17,
            color: "#000",
            fontWeight: "600",
            margin: 3,
          }}
        >
          {data.name}
        </Text>
        <Text
          style={{
            fontSize: 10,
            color: "#000",
            fontWeight: "600",
            margin: 3,
          }}
        >
          {data.longDescription}
        </Text>
        <Text
          style={{
            fontSize: 10,
            color: "#BEBEBE",
            fontWeight: "600",
            margin: 1,
          }}
        >
          Average Pricing is around INR {data.averagePricing}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default ProjectTemplateComponent;
