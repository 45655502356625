import { StyleSheet, Text, View, ScrollView } from "react-native";
import React, { useState } from "react";
// import Header from "../../../../components/header";

// import LargeTextInput from "../../../../components/largeTextInput";
// import RoundButton from "../../../../components/roundButton";
import Header from "../../../components/header";
import LargeButton from "../../../components/largeButton";
import RoundButton from "../../../components/roundButton";
import LargeTextInput from "../../../components/largeTextInput";
import { createProjectBySales } from "../../../redux/actions/project.action";
import { addError } from "../../../redux/actions/toast.action";
import { connect } from "react-redux";

const CreateProjectBySales = ({
  navigation,
  route,
  addError,
  createProjectBySales,
}) => {
  const [title, setTitle] = useState("");
  const next = () => {
    if (title && title != "") {
      createProjectBySales({ client: route.params.client, title: title }, () =>
        navigation.navigate("service-selection")
      );
    } else {
      addError("Title is mandatory!", 3000);
    }
  };
  return (
    <View style={styles.container}>
      <View style={{ maxHeight: 150, minHeight: 150 }}>
        <Header
          title="new Sales"
          subtitle="Please identify the client"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#000000" }}
        ></Header>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={styles.TextStyle}>enter some basic project details</Text>
        <View style={{ marginTop: 40 }}>
          <LargeTextInput
            placeholder="give a title to the project"
            style={{ borderBottomColor: "#707070", fontSize: 16 }}
            onChangeText={(data) => setTitle(data)}
          ></LargeTextInput>
        </View>
        <View style={{ marginHorizontal: 20, marginTop: 20 }}>
          <Text style={{ marginLeft: 10 }}>
            {`you can write in something as "Brand identity & stationary Design for Delhi based Arabic Restaurant"`}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            position: "absolute",
            bottom: 30,
          }}
        >
          <RoundButton
            style={{
              backgroundColor: "#000000",
              width: 100,
              marginLeft: 30,
            }}
            title={"Next"}
            pressFunc={next}
          ></RoundButton>
          <RoundButton
            style={{ backgroundColor: "#EB4141", width: 120, marginLeft: 15 }}
            title={"Discard"}
            pressFunc={() => submit()}
          ></RoundButton>
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { createProjectBySales, addError })(
  CreateProjectBySales
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  TextStyle: {
    fontSize: 22,
    fontWeight: "bold",
    margin: 20,
    left: 5,
    color: "#000",
  },
});
