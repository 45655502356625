const initialState = [];
const engagementType = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CLOSED_PROJECTS_SUCCESS": {
      return action.payload.data;
    }

    case "LOGOUT": {
      return {};
    }

    default:
      return state;
  }
};
export default engagementType;
