import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/creator/dashboard";
import { Dimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { connect } from "react-redux";

import Discussions from "../../screens/common/inbox_screens/discussions";
import discussionGroups from "../../screens/common/inbox_screens/discussionGroups";
import chat from "../../screens/chat";
import login from "../../screens/login";
import ChatWithUs from "../../screens/common/chat-with-us";

const window = Dimensions.get("window");
const CreatorStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const StackNavigatorComponent = ({ user }) => {
  if (!user) {
    return <></>;
  } else {
    return (
      <CreatorStack.Navigator
        name="creatorInbox"
        initialRouteName={"discussions"}
      >
        <CreatorStack.Screen
          name="discussions"
          component={Discussions}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="discussionGroups"
          component={discussionGroups}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="chat"
          component={chat}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="login"
          component={login}
          options={{ headerShown: false }}
        />
        <CreatorStack.Screen
          name="chat-with-us"
          component={ChatWithUs}
          options={{ headerShown: false }}
        />
      </CreatorStack.Navigator>
    );
  }
};

const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps, {})(StackNavigatorComponent);
