const initialState = [];
const myPaymentCurrentMonth = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MY_PAYMENT_CURRENT_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_MY_PAYMENT_CURRENT_FAIL": {
      return state;
    }
    case "LOGOUT": {
      return {};
    }
    default:
      return state;
  }
};
export default myPaymentCurrentMonth;
