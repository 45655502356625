const initialState = [];
const clientAllProject = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_PROJECTS_BY_CLIENT_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_ALL_PROJECTS_BY_CLIENT_FAIL": {
      return [];
    }
    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default clientAllProject;
