import React, { useEffect, useState } from "react";
import {
  View,
  TextInput,
  PixelRatio,
  Text,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import adjust from "./adjust";
import Icon from "./icon";
import PopUp from "./popUp/popUp";

let validationReg;

export default function LargeTextInput({
  placeholder,
  style,
  containerStyle,
  onChangeText,
  secureTextEntry,
  placeholderColor,
  isValid,
  inValidStyle,
  validationType,
  validationRegex,
  required = false,
  value,
  keyboardType,
  errorMessage,
  width,
  maxWidth,
  marginLeft,
  renderData,
  iconStyle,
  onFocus,
  displayField,
  selectedValue,
  multiSelect,
  onSelectionChange,
  setSelectedValue,
  marginHorizontal,
  onRequestCloseModal,
  disable = true,
  marginRight,
  onFocusOut,
}) {
  const [valid, setValid] = useState(true);
  function validate(value) {
    if (required && value) {
      if (validationReg) {
        setValid(validationReg.test(value));
      } else {
        setValid(true);
      }
    } else if (value) {
      if (validationReg) {
        setValid(validationReg.test(value));
      }
    } else {
      setValid(false);
    }
    if (isValid) {
      isValid(valid);
    }
  }
  useEffect(() => {
    if (validationRegex || validationType)
      validationReg = validationRegex
        ? new RegExp(validationRegex)
        : validationType === "email"
        ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi
        : validationType === "phone"
        ? /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/gi
        : validationType === "aadhar"
        ? /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/gi
        : validationType === "pan"
        ? /^[A-Z]{5}[0-9]{4}[A-Z]{1}/gi
        : null;
  });
  const window = useWindowDimensions();
  return (
    <View
      style={[
        {
          flex: 1,
          justifyContent: "flex-end",
          maxHeight: 60,
          marginHorizontal: marginHorizontal,
        },
        !valid && inValidStyle ? inValidStyle : containerStyle,
      ]}
    >
      {renderData ? (
        <PopUp
          marginRight={marginRight}
          onRequestCloseModal={onRequestCloseModal}
          displayField={displayField}
          renderData={renderData}
          style={{
            width: window.width - 40,
            borderBottomWidth: 0,
            maxHeight: 50,
            minHeight: 50,
          }}
          multiSelectStyle={{
            backgroundColor: "#00000000",
          }}
          placeholder={placeholder}
          selectedItemStyle={styles.selectTextStyle}
          placeholderContainerStyle={{
            minHeight: 50,
            maxHeight: 50,
            borderRadius: 8,
            marginTop: 17,
          }}
          placeholderStyle={{ width: window.width - 100 }}
          iconStyle={{ marginTop: 14 }}
          onSelection={(e) => {
            onSelectionChange(e);
            // setSelectedValue(e);
          }}
          selectionValue={selectedValue}
          multiSelect={multiSelect}
        ></PopUp>
      ) : (
        <TextInput
          onBlur={onFocusOut}
          editable={disable}
          onFocus={onFocus}
          secureTextEntry={secureTextEntry}
          placeholder={placeholder}
          placeholderTextColor={placeholderColor ? placeholderColor : "#B2AFAF"}
          onChangeText={(e) => {
            validate(e);
            onChangeText(e);
          }}
          keyboardType={keyboardType}
          value={value}
          style={[
            {
              fontSize: 20 * PixelRatio.getFontScale(),
              fontWeight: "bold",
              // width: width ? width : "80%",
              borderBottomWidth: 1,
              paddingLeft: 0,
              color: "black",
              flex: 1,
              maxHeight: 50,
              minHeight: 50,
              // maxWidth: maxWidth ? maxWidth : 400,
              // marginLeft: marginLeft ? marginLeft : 30,
              marginHorizontal: 30,
              justifyContent: "flex-end",
              borderBottomColor: errorMessage ? "#F33A3A" : "#B4B2B2",
            },
            style,
          ]}
        ></TextInput>
      )}

      {errorMessage ? (
        <Text
          style={{ color: "#F33A3A", marginLeft: 30, fontSize: adjust(16) }}
        >
          {errorMessage}
        </Text>
      ) : (
        <></>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  selectTextStyle: {
    fontSize: adjust(16),
    alignSelf: "center",
    marginBottom: 18,
  },

  titleText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#000",
  },
  textContainer: {
    minHeight: 80,
    maxHeight: 120,
    paddingVertical: 15,
    marginBottom: 30,
  },
  textInput: {
    fontSize: adjust(13),
    height: 60,
    marginTop: 10,
    borderBottomColor: "#000",
    // borderBottomWidth: 1,
  },
  docInput: {
    fontSize: adjust(13),
    height: 25,
    marginTop: 25,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
});
