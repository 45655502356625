export const fetchAllProjects = () => (dispatch) => {
  return dispatch({
    type: "FETCH_ALL_PROJECTS",
    payload: {
      request: {
        url: `/api/project/fetch`,
        method: "POST",
        data: { new: true },
      },
    },
  });
};

export const placeQuotation = (quotationObj, navigate) => (dispatch) => {
  return dispatch({
    type: "PLACE_QUOTATION",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/project/quotation`,
        method: "POST",
        data: quotationObj,
      },
    },
  });
};
