import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/partner/partnerDashboard";
// import NewProjects from "../../screens/creator/newProjects";
import PartnerNewProjectDetails from "../../screens/partner/partnerNewProjectDetails";
import SetupProject from "../../screens/partner/setupProject";
import ProfessionalProfile from "../../screens/partner/professionalProfile";
import chat from "../../screens/chat";
import Payments from "../../screens/partner/payments";
import EditYourProfile from "../../screens/editYourProfile";
import ProjectSuccess from "../../screens/partner/projectSuccess";
import CreatorAcceptance from "../../screens/partner/creatorAcceptance";
import TeamPayments from "../../screens/partner/teamPayments";
import MyPayments from "../../screens/myPayments";
import ChangePassword from "../../screens/changePassword";
import Company from "../../screens/company";
import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import CreatorProfileByPartner from "../../screens/partner/setupProjectComponents/creatorProfileByPartner";
import UserProfileList from "../../screens/common/client-list";
import NewProjects from "../../screens/partner/new-projects";
import NewProjectDetails from "../../screens/common/newProjectDetails";
import login from "../../screens/login";
import creatorScreen from "../../screens/admin/screens/creator-screen";
import ChatWithUs from "../../screens/common/chat-with-us";
import creatorProfile from "../../screens/admin/screens/creator-profile-screen";
import ProjectListScreen from "../../screens/admin/screens/project-screen";
const window = Dimensions.get("window");

const PartnerStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <PartnerStack.Navigator
      name="partnerProject"
      initialRouteName={"dashboard"}
    >
      <PartnerStack.Screen
        name="dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="editYourProfile"
        component={EditYourProfile}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="partnerNewProjectList"
        component={NewProjects}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="newProjectDetails"
        component={NewProjectDetails}
        options={{ headerShown: false }}
      />

      <PartnerStack.Screen
        name="userProfileList"
        component={UserProfileList}
        options={{ headerShown: false }}
      />

      <PartnerStack.Screen
        name="partnerNewProjectDetails"
        component={PartnerNewProjectDetails}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="setupProject"
        component={SetupProject}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="professionalProfile"
        component={ProfessionalProfile}
        options={{ headerShown: false }}
      />

      <PartnerStack.Screen
        name="payments"
        component={Payments}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="projectSuccess"
        component={ProjectSuccess}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="creatorAcceptance"
        component={CreatorAcceptance}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="myPayments"
        component={MyPayments}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="company"
        component={Company}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="teamPayments"
        component={TeamPayments}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="changePassword"
        component={ChangePassword}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="CreatorProfileByPartner"
        component={CreatorProfileByPartner}
        options={{ headerShown: false }}
      />

      <PartnerStack.Screen
        name="login"
        component={login}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="creator-list"
        component={creatorScreen}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="creator-profile"
        component={creatorProfile}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="project-list"
        component={ProjectListScreen}
        options={{ headerShown: false }}
      />
    </PartnerStack.Navigator>
  );
};
