import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import adjust from "../../../components/adjust";
import Icon from "../../../components/icon";
import { updatePaymentStatus } from "../../../redux/actions/project.action";
import config from "../../../config/config";
import RazorpayCheckout from "react-native-razorpay";
import { addInfo, addError } from "../../../redux/actions/toast.action";
import Header from "../../../components/header";
import ContainerWithDifferentBg from "../../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import { currenyFormat } from "../../../components/utils";
import BudgetPhase from "../../../components/budgetPhase";

const ProjectPayment = ({
  navigation,
  selectedProject,
  updatePaymentStatus,
  user,
  addError,
  addInfo,
  razorPayKey,
}) => {
  const payAmount = (phase, amount) => {
    if (razorPayKey?.value) {
      var options = {
        description: `${amount.type || amount.name}(${phase.description}) for ${
          selectedProject.title
        } `,
        image: `http://onedesign.yoctotta.com/static/media/logo.a6474724.png`,
        currency: "INR",
        key: razorPayKey.value,
        amount: amount.payment.amount,
        name: "OneDesign",
        order_id: amount.payment.razorPayOrderId,
        theme: { color: "#FFD500" },
      };
      RazorpayCheckout.open(options)
        .then((data) => {
          let obj = {
            _id: amount.payment._id,
            swipe: "right",
            ...data,
          };
          updatePaymentStatus(obj);
          addInfo("Successfully Paid!", 3000);
        })
        .catch((error) => {
          addError(
            JSON.parse(error.description)?.description ||
              JSON.parse(error.description)?.error?.description,
            3000
          );
        });
    } else {
      addError("Not integrated!", 3000);
    }
  };

  const [finalizePayment, setFinalizePayment] = useState(0);

  useEffect(() => {
    if (user && selectedProject) {
      setFinalizePayment(
        user.userType === "CREATOR"
          ? selectedProject.team
              .find((x) => x.user._id === user._id)
              .payments.filter((a) => a.type !== "FINAL")
              .reduce((a, b) => a + b.payment.amount, 0)
          : selectedProject.discussedBudget
      );
    }
  }, [user, selectedProject]);
  return (
    <View style={{ flex: 1 }}>
      <View style={{ maxHeight: 150, minHeight: 150 }}>
        <Header
          title="project payments"
          subtitle="See how the payments look in the project"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 40 }}
        nestedScrollEnabled={true}
        style={{
          flex: 1,
          height: "100%",
          paddingBottom: 40,
          marginHorizontal: 20,
        }}
      >
        {Object.keys(selectedProject).length !== 0 ? (
          <View style={[styles.container, { backgroundColor: "#000" }]}>
            <Text style={[styles.mainHeader]}>{selectedProject.title}</Text>
            <Text
              style={{
                fontSize: adjust(11),
                color: "#FFF",
                marginVertical: 3,
                left: 1,
              }}
            >{`for ${selectedProject?.user?.fName} ${selectedProject?.user?.lName}, `}</Text>
          </View>
        ) : (
          <Text style={styles.textStyle}>Please select a project</Text>
        )}
        {Object.keys(selectedProject).length !== 0 ? (
          <View style={{ marginBottom: 10 }}>
            <ContainerWithDifferentBg
              content={`Total Finalised Payment: ${currenyFormat(
                finalizePayment
              )}`}
              backgroundColor={"#7A9D54"}
            ></ContainerWithDifferentBg>
          </View>
        ) : (
          <></>
        )}
        {user.userType === "SALES"
          ? selectedProject.salesPerson.payments?.map((p, index) => {
              return (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 8,
                    padding: 12,
                    paddingBottom: 10,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#B0B0B0",
                      marginBottom: 20,
                      fontSize: adjust(15),
                    }}
                  >
                    {p.type}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      marginBottom: 20,
                      fontSize: adjust(30),
                      fontWeight: "800",
                    }}
                  >
                    {currenyFormat(p.amount)}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#606060",
                      marginBottom: 10,
                      fontSize: adjust(11),
                    }}
                  >
                    Swipe Right to Request Payment
                  </Text>

                  <BudgetPhase payment={p} sales={true}></BudgetPhase>
                </View>
              );
            })
          : selectedProject.paymentPhases?.map((p, index) => {
              return (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 8,
                    padding: 12,
                    paddingBottom: 10,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#B0B0B0",
                      marginBottom: 20,
                      fontSize: adjust(15),
                    }}
                  >
                    {p.description}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: adjust(11),
                    }}
                  >
                    Total Phase Payment
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      marginBottom: 20,
                      fontSize: adjust(30),
                      fontWeight: "800",
                    }}
                  >
                    {user.userType === "CREATOR"
                      ? currenyFormat(
                          selectedProject.team
                            ?.find((m) => m.user._id == user._id)
                            .payments.filter((x) =>
                              x.type.startsWith(p.description)
                            )
                            .reduce((a, b) => a + b.payment.amount, 0)
                        )
                      : currenyFormat(
                          p.amount?.find((x) => x.type === "Final")?.payment
                            ?.amount
                        )}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#606060",
                      marginBottom: 10,
                      fontSize: adjust(11),
                    }}
                  >
                    Swipe Right to Request Payment
                  </Text>

                  <BudgetPhase
                    phase={
                      user.userType === "CREATOR"
                        ? selectedProject.team
                            ?.find((m) => m.user._id == user._id)
                            .payments.filter((x) =>
                              x.type.startsWith(p.description)
                            )
                        : p
                    }
                    teamMember={user.userType === "CREATOR"}
                  ></BudgetPhase>
                </View>
              );
            })}
        {/* {p.amount
                  .filter((p) => p.type != "Final" && p?.payment.amount)
                  .map((a, i) => {
                    return (
                      <View
                        key={i}
                        style={{
                          backgroundColor:
                            a.payment?.status == "Requested"
                              ? "#FFFFFF"
                              : a.payment?.status == "Received"
                                ? "#41B000"
                                : "#DCDCDC",
                          flex: 1,
                          borderRadius: 10,
                          marginBottom: 20,
                          padding: 10,
                          borderWidth: 1,
                          borderColor:
                            a.payment?.status == "Requested"
                              ? "#FF1010"
                              : a.payment?.status == "Received"
                                ? "#41B000"
                                : "#DCDCDC",
                        }}
                      >
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text
                            style={{
                              fontWeight: "bold",
                              color:
                                a.payment?.status == "Requested"
                                  ? "#FF1010"
                                  : a.payment?.status == "Received"
                                    ? "#FFFFFF"
                                    : "#C3C3C3",
                            }}
                          >
                            {a.type}
                          </Text>
                          {a.payment?.status == "Requested" ? (
                            <Text
                              style={{ fontWeight: "bold", color: "#FF1010" }}
                            >
                              Requested
                            </Text>
                          ) : (
                            <></>
                          )}
                        </View>
                        <Text
                          style={{
                            fontSize: adjust(40),
                            fontWeight: "bold",
                            color:
                              a.payment?.status == "Requested"
                                ? "#000000"
                                : a.payment?.status == "Received"
                                  ? "#FFFFFF"
                                  : "#C3C3C3",
                          }}
                        >
                          ₹ {currenyFormat(a.payment?.amount)}
                        </Text>
                        <Text
                          style={{
                            color:
                              a.payment?.status == "Requested"
                                ? "#707070"
                                : a.payment?.status == "Received"
                                  ? "#FFFFFF"
                                  : "#C3C3C3",
                            fontSize: adjust(13),
                          }}
                        >
                          {a.payment?.status == "Received"
                            ? "Paid"
                            : a.payment?.status}
                        </Text>
                        {a.payment?.status == "Requested" ? (
                          <TouchableOpacity
                            onPress={() => payAmount(p, a)}
                            style={{
                              flex: 1,
                              borderRadius: 8,
                              backgroundColor: "#FFD500",
                              minHeight: 55,
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 5,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: adjust(16),
                                fontWeight: "bold",
                                color: "#ffffff",
                              }}
                            >
                              Pay
                            </Text>
                          </TouchableOpacity>
                        ) : (
                          <></>
                        )}
                      </View>
                    );
                  })} */}

        <View style={{ flex: 1, marginHorizontal: 10 }}>
          <Text>Report an Issue</Text>
          <View style={{ flexDirection: "row" }}>
            <Text>Email us at</Text>
            <TouchableOpacity>
              <Text style={{ color: "#248AFF" }}>
                accounts@onedesignlabs.com
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  mainHeader: {
    flex: 1,
    fontSize: adjust(20),
    fontWeight: "bold",
    color: "#ffffff",
    marginBottom: 5,
    marginVertical: 3,
  },
  finalBudgetContainer: {
    flex: 1,
    minHeight: 120,
    maxHeight: 120,
    backgroundColor: "#FFFFFF",
    marginTop: 10,
    borderRadius: 8,
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginBottom: 10,
  },
  finalBudgetText: {
    marginBottom: 10,
    fontWeight: "bold",
    color: "#000",
    fontSize: adjust(18),
  },
  subtitle: { fontWeight: "bold" },
  finalammount: {
    fontWeight: "bold",
    fontSize: adjust(30),
    color: "#000",
  },
  textStyle: {
    color: "red",
    fontWeight: "bold",
    fontSize: 25,
    margin: 10,
  },
});
const mapStateToProps = ({ selectedProject, user, razorPayKey }) => ({
  selectedProject,
  user,
  razorPayKey,
});
export default connect(mapStateToProps, {
  updatePaymentStatus,
  addError,
  addInfo,
})(ProjectPayment);
