import React, {  } from "react";
import {
  Text,
  Pressable,
  useWindowDimensions,
} from "react-native";
import adjust from "./adjust";

export default function RoundButton({
  title,
  pressFunc,
  style,
  textStyle,
  buttonStyle,
  disabled = false,
}) {
  const { height } = useWindowDimensions()

  return (
    // <View
    //   style={[
    //     {
    //       borderRadius: 30,
    //       marginTop: 10,
    //       // maxHeight: 80,
    //       minHeight: 40, // to check
    //       justifyContent: "center",
    //     },
    //     style,
    //     {
    //       maxHeight: height / 18,
    //     },
    //   ]}
    // >
    <Pressable
      disabled={disabled}
      style={({ pressed }) => [
        {
          borderRadius: 30,
          marginTop: 10,
          // maxHeight: 80,
          minHeight: 40, // to check
          justifyContent: "center",
        },
        style,
        {
          maxHeight: height / 16,
        },
        {
          borderRadius: 30,
          backgroundColor: disabled
            ? "#D3D3D3"
            : pressed
            ? "#FFD500"
            : (style && style.backgroundColor) || "#000",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          padding: 10,
          paddingHorizontal: 15,
          borderColor: pressed ? "#FFD500" : style.borderColor || "#000",
        },
        buttonStyle,
      ]}
      onPress={pressFunc}
    >
      {({ pressed }) => (
        <Text
          style={[
            {
              color: pressed
                ? "#000"
                : (textStyle && textStyle.color) || "#FFFFFF",
              fontSize: adjust(15),
              fontWeight: "bold",
              alignSelf: "center",
              justifyContent: "center",
            },
          ]}
        >
          {title}
        </Text>
      )}
    </Pressable>

    /*  {<TouchableOpacity
        style={[
          {
            padding: 10,
            paddingRight: 30,
            paddingLeft: 30,
          },
          buttonStyle,
        ]}
        onPress={pressFunc}
      >
        <Text
          adjustsFontSizeToFit={true}
          numberOfLines={1}
          style={[
            { color: "#fff", textAlign: "center", fontSize: adjust(14) },
            textStyle,
          ]}
        >
          {title}
        </Text>
      </TouchableOpacity> }*/
    // </View>
  );
}
