import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Header from "../header";
import ItemWithCountValue from "../itemWithCountValue/itemWithCountValue";
import RoundButton from "../roundButton";
import header from "../header";
const EarningCardComponentWithTotalSales = ({
  title,
  onpress,
  header,
  firstText,
  middleText,
  lastText,
  CountValue,
  firstCountValue,
  middleCountValue,
  lastCountValue,
  countValuetext,
  borderColor,
  buttonColor,
  buttontext,
  roundButton,
  withdraw,
  roundButtontext,
}) => {
  return (
    <View style={[styles.cardStyle, { borderColor: borderColor }]}>
      <View>
        <Text style={styles.titleStyle}>{header}</Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ItemWithCountValue
            countStyle={{ fontSize: 15, fontWeight: "bold", color: "#F27253" }}
            itemName={firstText}
            countValue={firstCountValue}
            textColor={CountValue}
          ></ItemWithCountValue>
          <ItemWithCountValue
            countStyle={{ fontSize: 15, fontWeight: "bold", color: "#F27253" }}
            itemName={middleText}
            countValue={middleCountValue}
            textColor={CountValue}
          ></ItemWithCountValue>
          <ItemWithCountValue
            countStyle={{ fontSize: 15, fontWeight: "bold", color: "#F27253" }}
            itemName={lastText}
            countValue={lastCountValue}
            textColor={CountValue}
          ></ItemWithCountValue>
        </View>
        {roundButton ? (
          <RoundButton
            title={buttontext}
            pressFunc={onpress}
            style={{
              backgroundColor: buttonColor,
              width: 200,
              marginLeft: 10,
            }}
          ></RoundButton>
        ) : (
          <></>
        )}
        {/* {withdraw ? (
          <RoundButton
            title={roundButtontext}
            pressFunc={onpress}
            style={{
              backgroundColor: "#F27253",

              width: 200,
              marginLeft: 10,
            }}
          ></RoundButton>
        ) : (
          <></>
        )} */}
      </View>
    </View>
  );
};

export default EarningCardComponentWithTotalSales;

const styles = StyleSheet.create({
  cardStyle: {
    borderWidth: 2,

    padding: 10,
    borderRadius: 8,
    marginHorizontal: 10,
    marginTop: 20,
    backgroundColor: "#fff",
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#000",
    marginLeft: 10,
  },
});
