import { StyleSheet, Text, View, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import RoundButton from "../../components/roundButton";
import AllTransactionComponents from "../../components/earningComponents/allTransactionComponents";
import CreatorBidProfileComponent from "../../components/creatorBidProfileComponent/creatorBidProfileComponent";
import { fullName, primaryColor } from "../../components/utils";
import { connect } from "react-redux";
import {
  fetchAllRequestedTransactions,
  fetchAllTransactions,
} from "../../redux/actions/payment.action";
import { addInfo, addError } from "../../redux/actions/toast.action";
import RazorpayCheckout from "react-native-razorpay";
import { updatePaymentStatus } from "../../redux/actions/project.action";
import moment from "moment";

const AccountantPaymentScreen = ({
  navigation,
  user,
  transactions,
  requestedTransactions,
  fetchAllRequestedTransactions,
  fetchAllTransactions,
  addError,
  addInfo,
  razorPayKey,
  updatePaymentStatus,
}) => {
  useEffect(() => {
    fetchAllRequestedTransactions();
    fetchAllTransactions();
  }, []);
  const [activeTab, setActiveTab] = useState("transactions");

  const payAmount = (payment) => {
    if (razorPayKey?.value) {
      var options = {
        description: `${payment.type} for ${payment.project.title} `,
        image: `http://onedesign.yoctotta.com/static/media/logo.a6474724.png`,
        currency: "INR",
        key: razorPayKey.value,
        amount: payment.amount,
        name: "OneDesign",
        order_id: payment.razorPayOrderId,
        theme: { color: "#FFD500" },
      };
      RazorpayCheckout.open(options)
        .then((data) => {
          let obj = {
            _id: payment._id,
            swipe: "right",
            ...data,
          };
          updatePaymentStatus(obj);
          addInfo("Successfully Paid!", 3000);
        })
        .catch((error) => {
          addError(
            JSON.parse(error.description)?.description ||
              JSON.parse(error.description)?.error?.description,
            3000
          );
        });
    } else {
      addError("Not integrated!", 3000);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          enableAvatar={true}
          title="payments"
          subtitle="All Your active payment raises"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <View
        style={{
          backgroundColor: "#FFFFFF",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <RoundButton
          title={"transactions"}
          pressFunc={() => {
            setActiveTab("transactions");
          }}
          textStyle={{
            color: activeTab === "transactions" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor:
              activeTab === "transactions"
                ? primaryColor(user.userType)
                : "#FFF",
            // backgroundColor: activeTab === "transactions" ? "#7A9D54" : "#FFF",
            // width: 100,
            borderWidth: 0.5,
            borderColor: "#FFF",
            marginBottom: 20,
            marginLeft: 20,
            alignSelf: "flex-end",
          }}
        ></RoundButton>
        <RoundButton
          title={"requested"}
          pressFunc={() => {
            setActiveTab("requested");
          }}
          textStyle={{
            color: activeTab === "requested" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor:
              activeTab === "requested" ? primaryColor(user.userType) : "#FFF",
            borderWidth: 0.5,
            borderColor: "#000",
            marginBottom: 20,
            marginLeft: 10,
            alignSelf: "flex-end",
          }}
        ></RoundButton>
      </View>
      <ScrollView style={{ marginHorizontal: 20 }}>
        {activeTab === "transactions" &&
          transactions.map((x) => (
            <AllTransactionComponents
              key={x._id + " transaction-list"}
              Colors={primaryColor(user.userType)}
              amount={x.amount}
              transactionTime={`Client: ${fullName(x.project?.user)} | ${moment(
                new Date(
                  x.updatedDate ||
                    parseInt(x._id.toString().substring(0, 8), 16) * 1000
                )
              ).format("DD/MM/YY h:mm a")}`}
              transactionDetails={
                x.toUserType === "ONEDESIGN"
                  ? `money paid to ${
                      x.fromUserType === "CREATOR"
                        ? "creator"
                        : "sales personnel"
                    }`
                  : "client paid money"
              }
              deducted={x.toUserType === "ONEDESIGN"}
              projectName={x.project?.title}
            />
          ))}

        {activeTab === "requested" &&
          requestedTransactions.map((x) => (
            <CreatorBidProfileComponent
              key={x._id + " requested-list"}
              name={fullName(x.receivable)}
              profile={`${x.receivable.userType} | Tap to View projects`}
              Quotation={"Payment Request"}
              amount={x.amount}
              checkBox={false}
              roundButton={true}
              buttontext={"Process to Payment"}
              buttonColor={primaryColor(user.userType)}
              onPress={() => payAmount(x)}
            />
          ))}
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({
  user,
  transactions,
  requestedTransactions,
  razorPayKey,
}) => ({
  user,
  transactions,
  requestedTransactions,
  razorPayKey,
});
export default connect(mapStateToProps, {
  fetchAllRequestedTransactions,
  fetchAllTransactions,
  addError,
  addInfo,
  updatePaymentStatus,
})(AccountantPaymentScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
