import React, { useState } from "react";
import ProjectHeader from "../../components/projectHeader";
import RoundButton from "../../components/roundButton";
import LargeTextInput from "../../components/largeTextInput";
import Icon from "../../components/icon";
import Modal from "../../components/modal/modal";
import { connect } from "react-redux";
import { placeQuotation } from "../../redux/actions/newProjects.action";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native";
import adjust from "../../components/adjust";
const CreatorNewProjectDetails = ({
  navigation,
  placeQuotation,
  user,
  selectedRequirement,
}) => {
  const [placeQuotationMode, setPlaceQuotationMode] = useState(false);
  const [bidPlacedModal, setBidPlacedModal] = useState(false);
  const [budget, setBudget] = useState(
    selectedRequirement.requiredTeam
      ? selectedRequirement.requiredTeam.quotations
        ? selectedRequirement.requiredTeam.quotations.find(
            (u) => u.user._id == user._id
          )?.budget
        : 0
      : 0
  );
  const getDate = (id) => {
    let timestamp = id?.toString().substring(0, 8);
    let date = new Date(parseInt(timestamp, 16) * 1000).toLocaleString();
    return date;
  };
  const showModalAndNavigate = () => {
    let projectObj = {
      _id: selectedRequirement._id,
      memberId: selectedRequirement.requiredTeam._id,
      budget: budget,
    };
    placeQuotation(projectObj, () => {
      navigation.navigate("newProjects");
    });
    // .then(() => {
    //   setBidPlacedModal(true);
    //   setTimeout(() => {}, 3000);
    // });
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#FAFAFA" }}>
      <View>
        <ProjectHeader
          title={selectedRequirement.title ? selectedRequirement.title : ""}
          partner={
            selectedRequirement.user ? selectedRequirement.user.name : ""
          }
          projectId={selectedRequirement.projectId}
          dateTime={getDate(selectedRequirement._id)}
          budget={
            selectedRequirement.requiredTeam
              ? selectedRequirement.requiredTeam.budget
                ? selectedRequirement.requiredTeam.budget
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : selectedRequirement.requiredTeam.budget
              : ""
          }
          duration={selectedRequirement.deadLine}
          backFunction={
            placeQuotationMode
              ? () => setPlaceQuotationMode(false)
              : () => navigation.goBack()
          }
          tags={[selectedRequirement.requiredTeam.type]}
        ></ProjectHeader>
      </View>
      <ScrollView
        style={{
          alignContent: "flex-start",
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        {placeQuotationMode ? (
          <View style={{ flex: 1, padding: 20 }}>
            <View
              style={{
                flex: 1,
                backgroundColor: "#ffffff",
                padding: 20,
                borderRadius: 10,
                maxHeight: 300,
              }}
            >
              <Text style={styles.projectBriefText}>Place a Quotation</Text>
              <Text style={[styles.data, { marginTop: 20 }]}>
                Place your bid very wisely as it will be under competition with
                a lot of fellow creators. Still, you can quote what you think
                suits best to your level of work.
              </Text>
              <Text style={styles.budget}>
                Budget:
                {selectedRequirement.requiredTeam.budget
                  ? selectedRequirement.requiredTeam.budget
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : selectedRequirement.requiredTeam.budget}
              </Text>
              <Text style={styles.myBidText}>My Bid</Text>
              <LargeTextInput
                placeholder={
                  selectedRequirement.requiredTeam.budget
                    ? "₹" + selectedRequirement.requiredTeam.budget * 0.9
                    : ""
                }
                placeholderColor="#D4D4D4"
                onChangeText={(e) => setBudget(e)}
                value={budget}
                style={{
                  backgroundColor: "#F1F1F1",
                  borderBottomWidth: 0,
                  width: "100%",
                  marginLeft: 0,
                  maxHeight: 80,
                  borderRadius: 10,
                  fontSize: adjust(40),
                  marginTop: 10,
                  paddingLeft: 20,
                }}
                keyboardType="numeric"
              ></LargeTextInput>
            </View>
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              height: "100%",
              marginHorizontal: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: "#ffffff",
                padding: 20,
                borderRadius: 10,
              }}
            >
              <Text style={[styles.projectBriefText, { paddingBottom: 10 }]}>
                Project Brief
              </Text>
              <View style={styles.dataContainer}>
                <Text style={styles.label}>Project Category</Text>
                <Text style={styles.data}>
                  {selectedRequirement.class
                    ? selectedRequirement.class.name
                    : "Not Available"}
                </Text>
              </View>
              <View style={styles.dataContainer}>
                <Text style={styles.label}>Mood</Text>
                <Text style={styles.data}>
                  {selectedRequirement.mood
                    ? selectedRequirement.mood
                    : "Not Available"}
                </Text>
              </View>
              <View style={styles.dataContainer}>
                <Text style={styles.label}>Audience</Text>
                <Text style={styles.data}>
                  {selectedRequirement.audience
                    ? selectedRequirement.audience.name
                    : "Not Available"}
                </Text>
              </View>
              <View style={styles.dataContainer}>
                <Text style={styles.label}>Project Requirements</Text>
                <Text style={styles.data}>
                  {selectedRequirement.requirement
                    ? selectedRequirement.requirement
                        .map((x) => x.name)
                        .join(", ")
                    : "Not Available"}
                </Text>
              </View>
              <View style={styles.dataContainer}>
                <Text style={styles.label}>About the Client</Text>
                <Text style={styles.data}>
                  {selectedRequirement.clientAbout
                    ? selectedRequirement.clientAbout
                    : "Not Available"}
                </Text>
              </View>
            </View>
          </View>
        )}
        <RoundButton
          title="Place Quotation"
          pressFunc={
            placeQuotationMode
              ? () => showModalAndNavigate()
              : () => setPlaceQuotationMode(true)
          }
          style={{
            flex: 1,
            backgroundColor: "black",
            minHeight: 50,
            borderRadius: 28,
            marginBottom: 20,
            marginLeft: 20,
            marginRight: 20,
          }}
          textStyle={{ fontWeight: "bold", fontSize: adjust(16) }}
        ></RoundButton>
      </ScrollView>
      <Modal
        animationType="fade"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={bidPlacedModal}
        onRequestClose={() => {}}
        ariaHideApp={false}
      >
        <TouchableWithoutFeedback onPress={() => setBidPlacedModal(false)}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View
                style={{
                  flex: 1,
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon name="box3D"></Icon>
                <Text
                  style={[{ flex: 1, marginLeft: 5 }, styles.projectBriefText]}
                >
                  Bid Placed Successfully
                </Text>
              </View>
              <Text style={styles.data}>
                You will be notified if you have been selected or shortlisted
                for this project. Once selected the project will appear on your
                dashboard.
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};
const mapStateToProps = ({ user, selectedRequirement }) => ({
  user,
  selectedRequirement,
});
export default connect(mapStateToProps, { placeQuotation })(
  CreatorNewProjectDetails
);
const styles = StyleSheet.create({
  projectBriefText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#A9A9A9",
  },
  label: {
    fontSize: adjust(11),
    fontWeight: "bold",
    color: "#000000",
  },
  data: {
    fontSize: adjust(15),
    color: "#606060",
    marginTop: 5,
  },
  dataContainer: {
    marginBottom: 15,
  },
  budget: {
    fontSize: adjust(30),
    marginTop: 10,
    fontWeight: "bold",
    marginBottom: 15,
  },
  myBidText: {
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginTop: 20,
    marginBottom: 10,
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  modalView: {
    flex: 1,
    maxWidth: 350,
    maxHeight: 120,
    minWidth: 350,
    minHeight: 120,
    backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    padding: 20,
  },
});
