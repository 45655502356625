import { StyleSheet, Text, View } from "react-native";
import React from "react";

const ItemWithCountValue = ({
  countValue,
  itemName,
  style,
  countStyle,
  textColor,
}) => {
  return (
    <View
      style={[
        {
          marginHorizontal: 10,
          marginVertical: 5,
        },
        style,
      ]}
    >
      <View>
        <Text
          style={[
            styles.countStyle,
            countStyle,
            textColor ? { color: textColor } : {},
          ]}
        >
          {countValue}
        </Text>
        <Text style={textColor ? { color: textColor } : {}}>{itemName}</Text>
      </View>
    </View>
  );
};

export default ItemWithCountValue;

const styles = StyleSheet.create({
  textWrapper: {
    justifyContent: "flex-start",
    margin: 10,
    flex: 1,
  },
  countStyle: {
    fontSize: 40,
    fontWeight: "bold",
    color: "#F27253",
  },
  itemStyle: {
    fontSize: 13,
    color: "#606060",
  },
});
