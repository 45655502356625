import React, { useEffect, useState } from "react";
import { Text, View, ScrollView, StyleSheet } from "react-native";
import SelectProfile from "./profileComponent";
import {
  setSelectedProject,
  setSelectedQuotation,
  closeQuotation,
} from "../../../../redux/actions/project.action";
import { connect } from "react-redux";
import adjust from "../../../../components/adjust";
function InviteBids({
  navigation,
  selectedProject,
  setSelectedQuotation,
  closeQuotation,
  validate,
}) {
  const [valid, setValid] = useState([]);
  useEffect(() => {
    if (selectedProject?.requiredTeam) {
      const validArray = selectedProject.requiredTeam.members.map(
        (m) => m.nos === m.quotations.filter((q) => q.accepted).length
      );
      setValid(validArray);
      validate(validArray.reduce((x, y) => x && y, true));
    }
  }, [selectedProject]);
  const setSelectedQuotationAndNavigate = (index) => {
    setSelectedQuotation(selectedProject.requiredTeam.members[index]);
    navigation.navigate("professionalProfile");
  };

  const toggleQuotation = (index) => {
    let quotationObj = {
      _id: selectedProject._id,
      memberId: selectedProject.requiredTeam.members[index]._id,
      status:
        selectedProject.requiredTeam.members[index].open == true ? false : true,
    };
    closeQuotation(quotationObj);
  };

  return (
    <ScrollView
      nestedScrollEnabled={true}
      style={{
        flex: 1,
        padding: 20,
        height: "100%",
      }}
    >
      <View style={styles.container}>
        <Text style={styles.header}>Invite Bids</Text>
        <Text style={styles.subHeader}>
          Post requirements for the profiles in the community and invite bids on
          the profiles.
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          marginTop: 15,
          flexDirection: "row",
          width: "100%",
        }}
      >
        <ScrollView>
          {selectedProject.requiredTeam ? (
            selectedProject.requiredTeam.members.map((bid, index) => (
              <SelectProfile
                bid={bid}
                key={index}
                type={bid.type ? bid.type.name : null}
                nos={bid.nos ? bid.nos : null}
                budget={bid.budget ? bid.budget : null}
                quotationsCount={bid.quotations ? bid.quotations.length : null}
                acceptedQuotationsCount={
                  bid.quotations
                    ? bid.quotations.filter((q) => q.accepted).length
                    : null
                }
                onPress={() => setSelectedQuotationAndNavigate(index)}
                open={bid.open}
                onPressToggleInvitation={() => toggleQuotation(index)}
              ></SelectProfile>
            ))
          ) : (
            <></>
          )}
        </ScrollView>
      </View>
    </ScrollView>
  );
}

const mapStateToProps = ({ selectedProject }) => ({ selectedProject });
export default connect(mapStateToProps, {
  setSelectedQuotation,
  closeQuotation,
})(InviteBids);
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    minHeight: 90,
    padding: 20,
    borderRadius: 10,
  },
  doItMyselfContainer: {
    backgroundColor: "#000000",
    maxHeight: 60,
    minHeight: 60,
    borderRadius: 8,
    padding: 15,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  doItMyselfText: {
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: adjust(16),
    width: "100%",
    marginLeft: 10,
  },
  header: {
    flex: 1,
    fontWeight: "bold",
    fontSize: adjust(18),
    color: "#828282",
  },
  subHeader: { flex: 1, fontSize: adjust(15), color: "#606060" },
  buildYourTeamText: {
    color: "#606060",
    fontSize: adjust(11),
    marginTop: 10,
    marginLeft: 5,
  },
  plusContainer: {
    flex: 1,
    backgroundColor: "#FFD500",
    borderRadius: 50,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 45,
    maxWidth: 45,
    minHeight: 45,
    minWidth: 45,
  },
});
