import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import adjust from "../../../../components/adjust";
import StepsComponent from "./stepsComponent";
import Icon from "../../../../components/icon";

export default function PhaseComponent({
  phaseData,
  onPhaseDataChange,
  phaseNo,
  inValidStyle,
  validateNow,
  validated,
  removeOnPress,
}) {
  const [phase, setPhase] = useState(phaseData);
  const [descriptionValid, setDescriptionValid] = useState(false);
  const [finalAmountValid, setFinalAmountValid] = useState(false);
  const [advanceAmountValid, setAdvanceAmountValid] = useState(false);
  let validationReg;
  let validationType = null;
  let required = false;
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (validateNow) {
      setDescriptionValid(validate(phase.description, (required = true)));
      let finalAmt = phase.amount
        ? phase.amount.find(({ type }) => type == "Final")?.payment?.amount
        : null;
      setFinalAmountValid(validate(finalAmt, (required = true)));
      let advanceAmt = phase.amount
        ? phase.amount.find(({ type }) => type == "Advance")?.payment?.amount
        : null;
      setAdvanceAmountValid(validate(advanceAmt, (required = true)));
    }
  }, [validateNow]);

  const removeFromSteps = (index) => {
    setPhase({ ...phase, steps: phase.steps.filter((x, i) => i != index) });
  };

  useEffect(() => {
    setPhase(phaseData);
  }, [phaseData]);

  function validate(v, required, validationType) {
    if (validationType) {
      validationReg = validationType === "number" ? /^[0-9]*$/ : null;
    }
    let val = valid;
    if (required && v) {
      if (validationReg) {
        val = validationReg.test(v);
      } else {
        val = true;
      }
    } else if (v) {
      if (validationReg) {
        val = validationReg.test(v);
      }
    } else {
      val = false;
    }
    setValid(val);
    return val;
  }

  useEffect(() => {
    if (descriptionValid && finalAmountValid && advanceAmountValid) {
      validated(true);
    } else {
      validated(false);
    }
  });

  return (
    <View style={styles.phaseContainer}>
      <TouchableOpacity
        onPress={removeOnPress}
        style={{
          flex: 1,
          zIndex: 10,
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        <Icon height={29} width={29} name="cross" fill="#000" />
      </TouchableOpacity>
      <Text style={styles.phaseText}>Phase {phaseNo}</Text>
      <TextInput
        placeholder="Enter Description"
        placeholderTextColor="#D5D5D5"
        style={styles.descriptionTextInput}
        onChangeText={(e) => {
          setDescriptionValid(validate(e, (required = true)));
          let newPhase = { ...phase, description: e };
          setPhase(newPhase);
          onPhaseDataChange(newPhase);
        }}
        value={phase.description}
      ></TextInput>
      <TextInput
        placeholder="Enter Total amount for this phase"
        placeholderTextColor="#D5D5D5"
        style={styles.descriptionTextInput}
        keyboardType="numeric"
        onChangeText={(e) => {
          setFinalAmountValid(
            validate(e, (required = true), (validationType = "number"))
          );
          let newPhase = {
            ...phase,
            amount: phase.amount.map((p) => {
              if (p.type == "Final")
                return { ...p, payment: { amount: Number(e) } };
              return p;
            }),
          };
          setPhase(newPhase);
          onPhaseDataChange(newPhase);
        }}
        value={
          phase.amount &&
          phase.amount?.find(({ type }) => type == "Final")?.payment?.amount
            ? `${
                phase.amount?.find(({ type }) => type == "Final")?.payment
                  ?.amount
              }`
            : ""
        }
      ></TextInput>
      <TextInput
        placeholder="Enter Advance amount for this phase"
        placeholderTextColor="#D5D5D5"
        style={styles.descriptionTextInput}
        keyboardType="numeric"
        onChangeText={(e) => {
          setAdvanceAmountValid(
            validate(e, (required = true), (validationType = "number"))
          );
          let newPhase = {
            ...phase,
            amount: phase.amount.map((p) => {
              if (p.type == "Advance")
                return { ...p, payment: { amount: Number(e) } };
              return p;
            }),
          };
          setPhase(newPhase);
          onPhaseDataChange(newPhase);
        }}
        value={
          phase.amount &&
          phase.amount.find(({ type }) => type == "Advance")?.payment?.amount
            ? `${
                phase.amount.find(({ type }) => type == "Advance")?.payment
                  ?.amount
              }`
            : ""
        }
      ></TextInput>
      <View
        style={[styles.container, { marginTop: 10, backgroundColor: "#FFF" }]}
      >
        <Text style={styles.paymentText}>Phase Milestone(s)</Text>
      </View>
      {phase.steps &&
        phase.steps.map((step, i) => (
          <StepsComponent
            key={i}
            stepNo={i + 1}
            data={step ? step : { name: "", description: "", amount: "" }}
            onDataChange={(step) => {
              const newPhase = {
                ...phase,
                steps: phase.steps.map((s, index) => (i === index ? step : s)),
              };
              setPhase(newPhase);
              onPhaseDataChange(newPhase);
            }}
            removeOnPress={() => removeFromSteps(i)}
          ></StepsComponent>
        ))}
      <TouchableOpacity
        style={{ flex: 1, minHeight: 75, maxHeight: 75 }}
        onPress={() => {
          setPhase({
            ...phase,
            steps: [...phase.steps, { name: "", description: "", amount: "" }],
          });
        }}
      >
        <View style={styles.plusContainer}>
          <Text
            style={{
              fontSize: adjust(20),
              fontWeight: "bold",
              color: "#FFFFFF",
              textAlignVertical: "center",
            }}
          >
            Add Milestone
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  phaseContainer: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#FFF",
    marginTop: 12,
    padding: 20,
    backgroundColor: "#ffffff",
  },
  amountTextInput: {
    flex: 1,
    fontSize: adjust(11),
    borderBottomWidth: 1,
    maxHeight: 40,
    minHeight: 40,
    borderBottomColor: "#BDBDBD",
    marginTop: 15,
  },
  plusContainer: {
    flex: 1,
    backgroundColor: "#FFD500",
    borderRadius: 8,
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionTextInput: {
    flex: 1,
    fontSize: adjust(16),
    fontWeight: "bold",
    borderBottomWidth: 1,
    maxHeight: 40,
    minHeight: 40,
    borderBottomColor: "#707070",
    paddingTop: 10,
    marginTop: 10,
  },
  phaseText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    paddingBottom: 10,
  },
  paymentText: {
    flex: 1,
    fontWeight: "bold",
    fontSize: adjust(15),
    color: "#000",
    marginTop: 10,
  },
});
