import React from "react";
import { Text, View } from "react-native";

export default function SwipeSuccess({ color, style, message }) {
  return (
    <View
      style={[
        style,
        {
          flex: 1,
          width: "100%",
          backgroundColor: color,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            color: "#ffffff",
          }}
        >
          {message}
        </Text>
      </View>
    </View>
  );
}
