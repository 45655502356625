import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import NewProjects from "../../screens/creator/newProjects";
import PartnerNewProjectDetails from "../../screens/partner/partnerNewProjectDetails";
import SetupProject from "../../screens/partner/setupProject";
import ProfessionalProfile from "../../screens/partner/professionalProfile";
import chat from "../../screens/chat";
import deliverables from "../../screens/deliverables";
import MyProjects from "../../screens/common/myProjects";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import ActiveProjectSummary from "../../screens/creator/activeProjectSummary/activeProjectSummary";
import Progress from "../../screens/creator/activeProjectDetails/progress";
import Payments from "../../screens/partner/payments";
import EditYourProfile from "../../screens/editYourProfile";
import ProjectSuccess from "../../screens/partner/projectSuccess";
import CreatorAcceptance from "../../screens/partner/creatorAcceptance";
import TeamPayments from "../../screens/partner/teamPayments";
import MyPayments from "../../screens/myPayments";
import ChangePassword from "../../screens/changePassword";
import Company from "../../screens/company";
import Discussion from "../../screens/discussion";
import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import CreatorProfileByPartner from "../../screens/partner/setupProjectComponents/creatorProfileByPartner";
// import PartnerNewProjectList from "../../screens/partner/partnerNewProjectList";
import NewProjectDetails from "../../screens/common/newProjectDetails";
import projectSetupStatus from "../../screens/partner/setup_project_screens/projectSetupStaus";
import newProjectSetup from "../../screens/partner/setup_project_screens/newProjectSetup";
import ProjectMilestone from "../../screens/partner/setup_project_screens/projectMilestone";
import creatorTeam from "../../screens/partner/setup_project_screens/creatorTeam";
import selectCreator from "../../screens/partner/setup_project_screens/selectCreator";
import creatorProfileDuringBid from "../../screens/setupProject/creatorProfileDuringBid";
import login from "../../screens/login";
import Meetings from "../../screens/common/meeting/meetings";
import ScheduleMeeting from "../../screens/common/meeting/scheduleMeeting";
import ScheduleDetails from "../../screens/common/meeting/scheduleDetails";
import ProjectStatus from "../../screens/Client/projectStatus";
import ProjectPayment from "../../screens/creator/activeProjectDetails/projectPayment";
import discussionGroups from "../../screens/common/inbox_screens/discussionGroups";
import ChatWithUs from "../../screens/common/chat-with-us";
import EndProjectConfirmationScreen from "../../screens/partner/EndProjectConfirmationScreen";
import EndProject from "../../screens/partner/endProject";

const window = Dimensions.get("window");

const PartnerStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={ProjectStack}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

const ProjectStack = () => {
  return (
    <PartnerStack.Navigator
      name="partnerProject"
      initialRouteName={"myProjects"}
    >
      <PartnerStack.Screen
        name="myProjects"
        component={MyProjects}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="activeProjectDetails"
        component={ActiveProjectDetails}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="editYourProfile"
        component={EditYourProfile}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="newProjects"
        component={NewProjects}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="myMeetings"
        component={Meetings}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="projectStatus"
        component={ProjectStatus}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="scheduleMeeting"
        component={ScheduleMeeting}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="scheduleDetails"
        component={ScheduleDetails}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="chat"
        component={chat}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="partnerNewProjectDetails"
        component={PartnerNewProjectDetails}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="setupProject"
        component={SetupProject}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="professionalProfile"
        component={ProfessionalProfile}
        options={{ headerShown: false }}
      />

      <PartnerStack.Screen
        name="activeProjectSummary"
        component={ActiveProjectSummary}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="progress"
        component={Progress}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="projectPayment"
        component={ProjectPayment}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="payments"
        component={Payments}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="projectSuccess"
        component={ProjectSuccess}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="creatorAcceptance"
        component={CreatorAcceptance}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="myPayments"
        component={MyPayments}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="company"
        component={Company}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="teamPayments"
        component={TeamPayments}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="changePassword"
        component={ChangePassword}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="discussion"
        component={Discussion}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="deliverables"
        component={deliverables}
        options={{ headerShown: false }}
      />

      <PartnerStack.Screen
        name="CreatorProfileByPartner"
        component={CreatorProfileByPartner}
        options={{ headerShown: false }}
      />

      <PartnerStack.Screen
        name="discussionGroups"
        component={discussionGroups}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="newProjectDetails"
        component={NewProjectDetails}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="projectSetup"
        component={projectSetupStatus}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="newProjectSetup"
        component={newProjectSetup}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="projectMilestones"
        component={ProjectMilestone}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="createTeam"
        component={creatorTeam}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="selectCreator"
        component={selectCreator}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="creatorProfileDuringBid"
        component={creatorProfileDuringBid}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="login"
        component={login}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="close-project"
        component={EndProjectConfirmationScreen}
        options={{ headerShown: false }}
      />
      <PartnerStack.Screen
        name="end-project"
        component={EndProject}
        options={{ headerShown: false }}
      />
    </PartnerStack.Navigator>
  );
};
