import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  ScrollView,
  Button,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
import InputDialogBox from "../../components/dialogBox/dialog-box";
import TeamProfileComponent from "../../components/teamEachProfileComponent/teamEachProfileComponent";
import LargeButton from "../../components/largeButton";

import Icon from "../../components/icon";
import ModalLargeTextInput from "../../components/modalLargeInput";

const TeamCompositionModal = ({
  isModalVisible,
  onPressCross,
  navigation,
  team,
  user,
  create,
}) => {
  const [name, setName] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);

  useEffect(() => {
    setSelectedMembers([]);
    return () => {
      setSelectedMembers([]);
    };
  }, []);

  const onSelect = (user) => {
    if (selectedMembers.find((x) => x._id === user._id)) {
      setSelectedMembers(selectedMembers.filter((x) => x._id != user._id));
    } else {
      setSelectedMembers([...selectedMembers, user]);
    }
  };

  return (
    <View style={styles.container}>
      <InputDialogBox
        modalBackgroundColor={false}
        modalViewStyle={{ minHeight: 500, maxHeight: 500 }}
        showModal={isModalVisible}
        jsx={
          <View style={{ flex: 1, alignSelf: "center" }}>
            <View style={{ backgroundColor: "#fff", borderRadius: 8 }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.Edit_Text}>Select Discussion Members</Text>
                <TouchableOpacity
                  onPress={() => onPressCross()}
                  style={{
                    right: 10,
                    top: 10,
                    padding: 2,
                  }}
                >
                  <Icon fill="#000" height={30} width={30} name="cross"></Icon>
                </TouchableOpacity>
              </View>

              <ScrollView style={styles.modal_Container}>
                {team?.map((t, i) => {
                  return (
                    <TeamProfileComponent
                      onSelect={() => onSelect(t.user)}
                      checked={
                        selectedMembers.find((x) => x._id === t.user._id)
                          ? true
                          : false
                      }
                      key={t._id}
                      profile={t}
                      user={user}
                    />
                  );
                })}
              </ScrollView>
            </View>
            <View>
              <ModalLargeTextInput
                style={{
                  backgroundColor: "#FFF",
                  borderWidth: 2,
                  borderColor: "#FAFAFA",
                  borderRadius: 10,
                  maxHeight: 55,
                  minHeight: 55,
                  fontSize: 20,
                }}
                placeholder={"Enter Discussion Name!"}
                onChangeText={(e) => {
                  setName(e);
                }}
                value={name}
              ></ModalLargeTextInput>
              <LargeButton
                textColor={"#073257"}
                backgroundColor={"#FFD500"}
                title="Create Discussion"
                disableIcon={true}
                pressFunc={() =>
                  create({
                    users: selectedMembers.map((x) => x._id),
                    name: name,
                  })
                }
              />
            </View>
          </View>
        }
      />
    </View>
  );
};

export default TeamCompositionModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modal_Container: {
    width: 300,
    minHeight: 250,
    marginHorizontal: 10,
    maxHeight: 250,
  },
  Edit_Text: {
    margin: 15,
    fontWeight: "bold",
    color: "#828282",
    fontSize: 16,
  },
});
