import React, { useEffect } from "react";
import { ScrollView, StyleSheet, Text, Dimensions, View } from "react-native";
import { connect } from "react-redux";
import Header from "../components/header";
import adjust from "../components/adjust";
import { fetchDiscussions } from "../redux/actions/chat.action";
import DiscussionCard from "../components/discussion-card";
import {
  fetchAllActiveProjects,
  setSelectedProject,
} from "../redux/actions/project.action";
import { useFocusEffect } from "@react-navigation/native";

const Discussion = ({
  navigation,
  fetchDiscussions,
  user,
  discussions,
  projects,
  fetchAllActiveProjects,
  setSelectedProject,
}) => {
  useFocusEffect(
    React.useCallback(() => {
      fetchDiscussions();
      if (!projects || projects.length == 0) {
        fetchAllActiveProjects();
      }
    }, [])
  );

  return (
    <View
      style={{
        flex: 1,
        maxHeight: Dimensions.get("window").height,
        flexDirection: "column",
      }}
    >
      <View style={[styles.mainContainer]}>
        <Header
          title="Conversations"
          subtitle="Jump into the discussions right away"
          backFunction={() => navigation.goBack()}
          enableAvatar={true}
          avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          avatarOnPress={() => setSettingsModalVisible(true)}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ flex: 4, padding: 0, marginHorizontal: 10 }}>
        <View style={{ padding: 10 }}>
          <View style={{}}>
            <Text style={{ padding: 10, color: "#606060" }}>
              Sorted by <Text style={{ fontWeight: "bold" }}>Most Recents</Text>
            </Text>
          </View>
          <ScrollView nestedScrollEnabled={true}>
            <View style={{ flex: 1 }}>
              {discussions.map((dis, index) => {
                return (
                  <DiscussionCard
                    discussion={dis}
                    user={user}
                    navigation={navigation}
                    projects={projects}
                    setSelectedProject={setSelectedProject}
                    key={dis._id}
                  ></DiscussionCard>
                );
              })}
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tabHeaderContainer: {
    padding: 10,
    backgroundColor: "white",
    marginBottom: 15,
    borderRadius: 10,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    flexDirection: "column",
  },
  textClass: {
    fontWeight: "bold",
    fontSize: adjust(10),
    color: "gray",
  },
  centeredView: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 999,
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    marginBottom: 30,
  },
  icon: {
    // marginLeft: 20,
  },
  customRatingBarStyle: {
    flexDirection: "row",
    marginTop: 5,
  },
});

const mapStateToProps = ({ user, discussions, projects }) => ({
  user,
  discussions,
  projects,
});

export default connect(mapStateToProps, {
  fetchDiscussions,
  fetchAllActiveProjects,
  setSelectedProject,
})(Discussion);
