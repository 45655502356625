import { View, Text, ScrollView } from "react-native";
import React from "react";
import Header from "../../../components/header";
import CreatorProfileComponent from "../../../components/creatorProfileComponent/creatorProfileComponent";
import { connect } from "react-redux";
const CreatorProfileByPartner = ({ route, user, navigation }) => {
  let Profile = route.params.data;
  return (
    <View style={{ flex: 1 }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Creators"
          subtitle="View Creator Profile"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          avatarSource={{
            uri: `data:${user.mimType};base64,${user.image}`,
          }}
        ></Header>
      </View>
      <ScrollView nestedScrollEnabled={true}>
        <CreatorProfileComponent
          partner={true}
          user={Profile}
        ></CreatorProfileComponent>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {})(CreatorProfileByPartner);
