import React, { useEffect, useState, Dimensions } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import EditYourProfile from "../../screens/editYourProfile";
import MyProjects from "../../screens/common/myProjects";
import NewProjects from "../../screens/creator/newProjects";
import CreatorNewProjectDetails from "../../screens/creator/creatorNewProjectDetails";
import ProfessionalProfile from "../../screens/partner/professionalProfile";
import MyPayments from "../../screens/myPayments";
import ProjectPayment from "../../screens/creator/activeProjectDetails/projectPayment";
import ReviewFeedback from "../../components/reviewFeedback";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import ActiveProjectSummary from "../../screens/creator/activeProjectSummary/activeProjectSummary";
import Payments from "../../screens/partner/payments";
import Progress from "../../screens/creator/activeProjectDetails/progress";
import Chat from "../../components/chat/chat";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Discussion from "../../screens/discussion";
import deliverables from "../../screens/deliverables";
import Dashboard from "../../screens/Sales/dashboard";
import VerificationOfUsers from "../../screens/common/verificationOfUsers";
import Meetings from "../../screens/common/meeting/meetings";

const SalesStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({}) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="employee"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <SalesStack.Navigator name="employee">
      <SalesStack.Screen
        name="Dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="editYourProfile"
        component={EditYourProfile}
        options={{ headerShown: false }}
      />

      <SalesStack.Screen
        name="projectPayment"
        component={ProjectPayment}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="newProjects"
        component={NewProjects}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="creatorNewProjectDetails"
        component={CreatorNewProjectDetails}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="professionalProfile"
        component={ProfessionalProfile}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="reviewFeedback"
        component={ReviewFeedback}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="myPayments"
        component={MyPayments}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="activeProjectDetails"
        component={ActiveProjectDetails}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="activeProjectSummary"
        component={ActiveProjectSummary}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="payments"
        component={Payments}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="progress"
        component={Progress}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="chat"
        component={Chat}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="discussion"
        component={Discussion}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="deliverables"
        component={deliverables}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="VerificationOfUsers"
        component={VerificationOfUsers}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="myProjects"
        component={MyProjects}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="Meetings"
        component={Meetings}
        options={{ headerShown: false }}
      />
    </SalesStack.Navigator>
  );
};
