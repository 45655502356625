import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import React, { useEffect } from "react";

import { connect } from "react-redux";
import WelcomeMessageComponent from "../../components/welcome_message_compoment/welcomeMessageComponent";
import DashboardCard from "../../components/dashboardCard";
import ItemWithCountValue from "../../components/itemWithCountValue/itemWithCountValue";
import Header from "../../components/header";
import adjust from "../../components/adjust";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import Icon from "../../components/icon";
import { fetchAccountantStats } from "../../redux/actions/user.action";
import { abbrNum } from "../../components/utils";
import moment from "moment";
const AccountantDashboard = ({
  user,
  navigation,
  fetchAccountantStats,
  userStats,
}) => {
  useEffect(() => {
    fetchAccountantStats();
  }, []);
  const dimension = useWindowDimensions();
  return (
    <View style={styles.Container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          disableBackButton={true}
          title={`Welcome, ${user.fName}`}
          subtitle="Let’s create greatness today!"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView
        style={{ marginBottom: 10 }}
        horizontal={true}
        showsVerticalScrollIndicator={false}
      >
        <WelcomeMessageComponent
          message={"the message reads,GST,needs to be submitted this month"}
          textColor="#fff"
          backgroundColor={"#7A9D54"}
        />
        <WelcomeMessageComponent
          message={"the message reads,GST,needs to be submitted this month"}
          textColor="#fff"
          backgroundColor={"#7A9D54"}
        />
      </ScrollView>

      <ScrollView style={{ marginHorizontal: 5 }}>
        <DashboardCard
          jsx={
            <TouchableOpacity
              style={{
                height: "90%",
              }}
              // onPress={() => navigation.navigate("AccountantEarningScreen")}
            >
              <Text style={styles.cardTitleStyle}>
                {moment().format("MMMM") + " Metrics"}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 30,
                }}
              >
                <ItemWithCountValue
                  countValue={abbrNum(userStats?.revenue || 0, 0)}
                  countStyle={{ color: "#073257" }}
                  itemName={"Total Revenue"}
                  style={{ width: "50%" }}
                  textColor={"#FFF"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  style={{ width: "50%" }}
                  countValue={abbrNum(userStats?.earning || 0, 0)}
                  itemName={"Net Earning"}
                  countStyle={{ color: "#073257" }}
                  textColor={"#FFF"}
                ></ItemWithCountValue>
              </View>
            </TouchableOpacity>
          }
          disableIcon={true}
          icon={false}
          style={{
            maxHeight: 150,
            minHeight: 150,
            backgroundColor: "#2F47FF",
          }}
        ></DashboardCard>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 10,
            marginTop: 10,
            //backgroundColor: "red",
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#F27253",
              maxHeight: dimension.width / 2 - 20,
              minHeight: dimension.width / 2 - 20,
              maxWidth: dimension.width / 2 - 20,
              minWidth: dimension.width / 2 - 20,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 10,
              justifyContent: "space-between",
            }}
            onPress={() => navigation.navigate("creators-list")}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(16),
                marginLeft: 15,
                color: "#fff",
                marginTop: 10,
              }}
            >
              Creator Team
            </Text>
            <View style={{}}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: adjust(40),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                {abbrNum(userStats.creatorRequested, 0)}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                payment requested
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              backgroundColor: "#073257",
              maxHeight: dimension.width / 2 - 20,
              minHeight: dimension.width / 2 - 20,
              maxWidth: dimension.width / 2 - 20,
              minWidth: dimension.width / 2 - 20,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 10,
              justifyContent: "space-between",
            }}
            onPress={() => navigation.navigate("sales-team")}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(16),
                marginLeft: 15,
                color: "#fff",
                marginTop: 10,
                alignSelf: "flex-start",
              }}
            >
              Sales Team
            </Text>
            <View style={{ marginBottom: 0 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: adjust(40),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                {abbrNum(userStats.salesRequested, 0)}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                payment requested
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ marginHorizontal: 10 }}>
          <DashboardCardWithArrowAndIcon
            onPressCard={() => navigation.navigate("AccountantInflowceScreen")}
            source={require("../../assets/user-shapes.png")}
            description="Total earned in the current month"
            value={`${userStats?.revenue} cr`}
            title="inflow"
            backgroundColor="#3D5F18"
          ></DashboardCardWithArrowAndIcon>
        </View>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user, userStats }) => ({ user, userStats });
export default connect(mapStateToProps, { fetchAccountantStats })(
  AccountantDashboard
);

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headerStyle: {
    height: 170,
    marginTop: 20,
  },
  // contentContainer: {
  //   backgroundColor: "#FFFFFF",
  // },
  cardTitleStyle: {
    margin: 10,
    fontSize: 16,
    color: "#000",
    fontWeight: "bold",
  },
  TitleStyle: {
    margin: 10,
    fontSize: 16,
    color: "#fff",
    fontWeight: "bold",
  },
});
