import { useFocusEffect } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import adjust from "../../../../components/adjust";
function PriceDivision({ selectedProject, validate }) {
  const pricePipe = (price) => {
    let x = price.toString();
    let a = x.indexOf(".");
    return a > -1 && a && x.length > a + 3 ? x.substring(0, a + 3) : x;
  };
  let colors = [
    "#000000",
    "#FFD500",
    "#64C034",
    "#FF4658",
    "#0E686B",
    "#13d4a8",
    "#0fe065",
    "#11c0db",
    "#ece031",
    "#df1a65",
  ];
  useEffect(() => {
    validate(true);
  });
  // const [colors, setColors] = useState([]);
  // useFocusEffect(
  //   React.useCallback(() => {
  //     selectedProject.priceDivision.map((x, i) =>
  //       setColors([
  //         ...colors,
  //         Math.floor(Math.random() * 16777215)
  //           .toString(16)
  //           .padStart(6, "0"),
  //       ])
  //     );
  //   }, [selectedProject])
  // );
  return (
    <View style={{ flex: 1, padding: 20 }}>
      <ScrollView scrollEnabled={true} nestedScrollEnabled={true}>
        <View>
          <View style={styles.container}>
            <Text style={styles.header}>Price Division</Text>
            <Text style={styles.subHeader}>
              The price division is based on the decision taken by the
              management of onedesign. Any discrepancy should be reported
              directly to them.
            </Text>
          </View>
          <View style={styles.priceDivisionContainer}>
            {selectedProject.priceDivision.map((division, i) => (
              <View
                key={i}
                style={[
                  i == 0 ? styles.firstPrice : null,
                  i == selectedProject.priceDivision.length - 1
                    ? styles.lastPriceBox
                    : null,
                  {
                    backgroundColor: colors[i % colors.length],
                    width: division.value + "%",
                  },
                ]}
              />
            ))}
          </View>
          <Text style={[styles.budgetText, { borderBottomWidth: 1 }]}>
            ₹{" "}
            {selectedProject.discussedBudget
              ? selectedProject.discussedBudget
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : selectedProject.discussedBudget}
          </Text>
          <Text style={styles.legendText}>Legend</Text>
        </View>
        <View style={{ marginTop: 15 }}>
          {selectedProject.priceDivision.map((division, index) => (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 15,
              }}
              key={index}
            >
              <View
                style={{
                  borderRadius: 50,
                  height: 32,
                  width: 32,
                  backgroundColor: colors[index % colors.length],
                  marginRight: 10,
                }}
              ></View>
              <View>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(18),
                    minWidth: "100%",
                  }}
                >
                  {division.key.name}
                </Text>
                {division.key.name == "Onedesign" ? (
                  <Text style={{ fontSize: adjust(15), marginTop: 5 }}>
                    {division.value}% of the entire Job: ₹:{" "}
                    {pricePipe(
                      (selectedProject.discussedBudget * division.value) / 100
                    )
                      ? pricePipe(
                          (selectedProject.discussedBudget * division.value) /
                            100
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : pricePipe(
                          (selectedProject.discussedBudget * division.value) /
                            100
                        )}
                  </Text>
                ) : (
                  <Text style={{ fontSize: adjust(15), marginTop: 5 }}>
                    Total Share: ₹:{" "}
                    {pricePipe(
                      (selectedProject.discussedBudget * division.value) / 100
                    )
                      ? pricePipe(
                          (selectedProject.discussedBudget * division.value) /
                            100
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : pricePipe(
                          (selectedProject.discussedBudget * division.value) /
                            100
                        )}
                  </Text>
                )}
              </View>
            </View>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}

const mapStateToProps = ({ selectedProject }) => ({
  selectedProject,
});
export default connect(mapStateToProps, {})(PriceDivision);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    minHeight: 110,
    padding: 20,
    borderRadius: 10,
  },
  header: {
    flex: 1,
    fontWeight: "bold",
    fontSize: adjust(18),
    color: "#828282",
    marginBottom: 5,
  },
  subHeader: { flex: 2, fontSize: adjust(15), color: "#606060" },
  priceDivisionContainer: {
    flex: 1,
    maxHeight: 60,
    minHeight: 60,
    marginTop: 15,
    flexDirection: "row",
  },
  firstPrice: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  lastPriceBox: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  budgetText: {
    flex: 1,
    fontWeight: "bold",
    fontSize: adjust(30),
    marginTop: 15,
  },
  legendText: { fontSize: adjust(11), color: "#606060", marginTop: 10 },
});
