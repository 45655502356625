import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import Header from "../../components/header";
import Icon from "../../components/icon";
import SmallTextInput from "../../components/smallTextInput";
import PopUp from "../../components/popUp/popUp";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/user.action";
import {
  createProject,
  clearSelectedProject,
} from "../../redux/actions/project.action";
import { fetchProjectDomain } from "../../redux/actions/projectDomain.action";
import { fetchEngagementType } from "../../redux/actions/engagementType.action";
import adjust from "../../components/adjust";
const ProjectDetails = ({
  navigation,
  logout,
  createProject,
  user,
  selectedProject,
  clearSelectedProject,
  fetchProjectDomain,
  fetchEngagementType,
  engagementType,
  projectDomain,
}) => {
  const [projectDomainState, setProjectDomainState] = React.useState(null);
  const [engagement, setEngagement] = React.useState("");
  const [budget, setBudget] = React.useState(null);
  const [paymentTerm, setPaymentTerm] = React.useState(null);
  const [timeInDays, settimeInDays] = React.useState(null);
  const [effect, setEffect] = useState(false);
  const [validateNow, setValidateNow] = useState(false);
  const [form, setForm] = useState();
  const handleProjectDomainCallback = (childData) => {
    setProjectDomainState(childData);
    // if (childData == projectDomain) {
    //   setEngagement(engagementType);
    //   setPaymentTerm("Monthly");
    // } else if (childData == projectDomain) {
    //   setEngagement(engagementType);
    //   setPaymentTerm("One shot Payment");
    // } else {
    //   setProjectDomainState(childData);
    // }
  };
  const handleEngagementCallback = (childData) => {
    setEngagement(childData);
  };
  useEffect(() => {
    if (projectDomain && !effect) {
      setEffect(true);
      fetchProjectDomain();
      fetchEngagementType();
    }
  });

  const updateProjectAndNavigate = () => {
    let projectObj = {
      _id: selectedProject._id,
      projectDomain: projectDomainState,
      engagement: engagement,
      budget: budget,
      paymentTerm: paymentTerm,
      timeInDays: timeInDays,
    };
    createProject(projectObj).then(() => {
      clearSelectedProject();
      navigation.navigate("dashboard");
    });
  };

  const Project = {
    ProjectDomain: projectDomain,
    Engagement: engagementType,
    Budget: [{ key: "Monthly  " }, { key: "One shot Payment" }],
  };
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <Header
          title="Sign-up"
          subtitle="Please follow the Steps"
          backFunction={() => navigation.goBack()}
          enableAvatar={user.image ? user.image : false}
          avatarSource={{ uri: `data:${user.mimType};base64,${user.image}` }}
          disableProfilePage={true}
        ></Header>
      </View>
      <View style={{ flex: 4, backgroundColor: "#FAFAFA" }}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: adjust(15),
            color: "#828282",
            marginTop: 20,
            marginLeft: 30,
          }}
        >
          Project Details
        </Text>
        <ScrollView style={{ flex: 4, marginTop: 40 }}>
          <PopUp
            style={{ marginLeft: 30, marginBottom: 30 }}
            label="Project Domain"
            placeholder="Select"
            renderData={Project.ProjectDomain}
            onSelection={handleProjectDomainCallback}
            multiSelect={false}
          ></PopUp>
          <PopUp
            style={{ marginLeft: 30, marginBottom: 30 }}
            label="Engagement"
            placeholder="Select"
            renderData={Project.Engagement}
            onSelection={handleProjectDomainCallback}
            // selectionValue={engagement}
            // readOnly={true}
          ></PopUp>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <SmallTextInput
              style={{ flex: 1 }}
              label="Budget"
              placeholder="Amount"
              onChangeText={(e) => setBudget(e)}
              required={true}
              inValidText="Enter Total Amount"
              validateNow={validateNow}
              keyboardType="numeric"
              isValid={(v) => {
                setForm({ ...form, budget: v });
              }}
            ></SmallTextInput>
            <Text style={{ flex: 0.7, marginTop: 35, right: 10 }}>
              {paymentTerm}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              marginTop: 30,
            }}
          >
            <SmallTextInput
              style={{ flex: 1 }}
              label="Delivery (In Days)"
              onChangeText={(e) => settimeInDays(e)}
              required={true}
              inValidText="Enter Days Of Delivery"
              validateNow={validateNow}
              keyboardType="numeric"
              isValid={(v) => {
                setForm({ ...form, timeInDays: v });
              }}
            ></SmallTextInput>
            <Text style={{ flex: 0.7, marginTop: 35, right: 10 }}>Days</Text>
          </View>
        </ScrollView>
        <View
          style={{
            flex: 0.3,
            marginLeft: 30,
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <View style={{ flexDirection: "row", flex: 5 }}>
            <Icon name="paginationDotInactive"></Icon>
            <Icon name="paginationDotInactive"></Icon>
            <Icon name="paginationDotInactive"></Icon>
            <Icon name="paginationDotActive"></Icon>
          </View>
          <TouchableOpacity
            onPress={() => {
              if (form && form.budget && form.timeInDays) {
                updateProjectAndNavigate();
              } else {
                setValidateNow(true);
              }
            }}
            style={{
              flex: 1,
              marginBottom: 20,
            }}
          >
            <Icon
              name="rightChevronArrowWithCircle"
              fill={
                form && form.budget && form.timeInDays ? "#000000" : "#E9E9E9"
              }
            ></Icon>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
const mapStateToProps = ({
  user,
  selectedProject,
  projectDomain,
  engagementType,
}) => ({
  user,
  selectedProject,
  projectDomain,
  engagementType,
});
export default connect(mapStateToProps, {
  logout,
  createProject,
  clearSelectedProject,
  fetchProjectDomain,
  fetchEngagementType,
})(ProjectDetails);
