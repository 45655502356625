import {
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
} from "react-native";
import React, { useState, useReducer, useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../../components/header";
import ContainerWithDifferentBg from "../../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import LargeButton from "../../../components/largeButton";
import LargeTextInput from "../../../components/largeTextInput";
import RoundButton from "../../../components/roundButton";
import moment from "moment";
import InputDialogBox from "../../../components/dialogBox/dialog-box";
import Icon from "../../../components/icon";
import { createProject } from "../../../redux/actions/project.action";
import StepsComponent from "../setupProjectComponents/payment/stepsComponent";
import { useFocusEffect } from "@react-navigation/native";
import { addError } from "../../../redux/actions/toast.action";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import NextAndDiscardButton from "../../../components/nextAndDiscardButton/nextAndDiscardButton";

const initObj = [
  {
    description: "Phase ",
    deadLine: null,
    deliverables: [
      {
        name: "",
      },
    ],
    steps: [
      {
        name: "",
        description: "",
      },
    ],
    amount: [
      {
        type: "Final",
        payment: {
          amount: 0,
        },
      },
      {
        type: "Advance",
        payment: {
          amount: 0,
        },
      },
      {
        type: "Rest",
        payment: {
          amount: 0,
        },
      },
    ],
  },
];
const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_MILESTONE":
      return [
        ...state.map((x, i) =>
          i === action.index
            ? { ...x, steps: [...x.steps, action.value] }
            : { ...x }
        ),
      ];
    case "REMOVE_MILESTONE":
      return [
        ...state.map((x, i) =>
          i === action.index
            ? { ...x, steps: [...x.steps.filter((y, di) => di != action.di)] }
            : { ...x }
        ),
      ];
    case "ADD_DELIVERABLES":
      return [
        ...state.map((x, i) =>
          i === action.index
            ? { ...x, deliverables: [...x.deliverables, action.value] }
            : { ...x }
        ),
      ];
    case "REMOVE_DELIVERABLES":
      return [
        ...state.map((x, i) =>
          i === action.index
            ? {
                ...x,
                deliverables: [
                  ...x.deliverables.filter((y, di) => di != action.di),
                ],
              }
            : { ...x }
        ),
      ];

    case "ADD_DEADLINE":
      console.log(action);
      return [
        ...state.map((x, i) =>
          i === action.index
            ? {
                ...x,
                deadLine: action.value,
              }
            : { ...x }
        ),
      ];
    case "ADD_AMOUNT":
      return [
        ...state.map((x, i) =>
          i === action.index
            ? {
                ...x,
                amount: x.amount.map((y) =>
                  y.type === action.value.type
                    ? { ...y, payment: { amount: action.value.amount } }
                    : { ...y }
                ),
              }
            : { ...x }
        ),
      ];
    case "ADD_PHASE":
      return [
        ...state,
        {
          description: "Phase " + `${state.length + 1}`,
          deliverables: [
            {
              name: "",
            },
          ],
          steps: [
            {
              name: "",
              description: "",
            },
          ],
          amount: [
            {
              type: "Final",
              payment: {
                amount: 0,
              },
            },
            {
              type: "Advance",
              payment: {
                amount: 0,
              },
            },
            {
              type: "Rest",
              payment: {
                amount: 0,
              },
            },
          ],
        },
      ];
    case "REMOVE_PHASE":
      return state.filter((x, i) => i != action.index);
    case "INIT":
      return [...initObj];
    case "INIT_DATA":
      return action.value;
    case "REMOVE_ALL":
      return [];
    default:
      return state;
  }
};

const ProjectMilestone = ({
  user,
  navigation,
  selectedProject,
  createProject,
  addError,
}) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      if (selectedProject.paymentPhases) {
        setPhases({ type: "INIT_DATA", value: selectedProject.paymentPhases });
      }
      return () => {
        setPhases({ type: "REMOVE" });
      };
    }, [selectedProject])
  );

  const [modal, setModal] = useState({ show: false, value: "" });
  const [tempObj, setTempObj] = useState();

  const [phases, setPhases] = useReducer(reducer, initObj);
  const addMileStone = (index) => {
    setModal({ show: true, value: "Milestone", index });
  };
  const addDeliverable = (index) => {
    setModal({ show: true, value: "Deliverable", index });
  };

  const add = () => {
    if (modal.value === "Milestone") {
      setPhases({
        type: "ADD_MILESTONE",
        index: modal.index,
        value: { ...tempObj },
      });
    } else {
      setPhases({
        type: "ADD_DELIVERABLES",
        index: modal.index,
        value: { ...tempObj },
      });
    }
    setTempObj({});
    setModal({ show: false });
  };
  const next = () => {
    const valid = phaseValid.find((e) => e.valid === false);
    if (valid) {
      addError(
        `please check the phase ${valid.index + 1} payment breaks`,
        3000
      );
    } else {
      createProject(
        {
          _id: selectedProject._id,
          paymentPhases: phases,
        },
        navigateAfterSuccess
      );
    }
  };

  const navigateAfterSuccess = () => navigation.navigate("createTeam");
  let paymentObject = {
    final: 0,
    advance: 0,
    rest: 0,
  };
  const [amount, setAmount] = useState(paymentObject);
  const phaseValid = phases.map((x, i) => ({
    index: i,
    valid:
      x.amount
        .filter((z) => z.type != "Final")
        .reduce((p, q) => p + Number(q.payment.amount), 0) > 0 &&
      x.amount.find((y) => y.type === "Final" && y.amount != 0).payment
        .amount ==
        x.amount
          .filter((z) => z.type != "Final")
          .reduce((p, q) => p + Number(q.payment.amount), 0),
  }));

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          title="new project setup"
          subtitle="Setup a new project"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          style={{ backgroundColor: "#F1F1F1" }}
        ></Header>
      </View>
      <Text style={styles.titleStyle}>
        enter details about the project phases
      </Text>
      <ScrollView style={{ height: "100%" }}>
        <FlatList
          nestedScrollEnabled
          scrollEnabled
          data={phases}
          keyExtractor={(item, index) => item.description}
          renderItem={({ item, index }) => (
            <View>
              <View style={styles.containerStyle}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#818181",
                    margin: 10,
                  }}
                >
                  {item.description}
                </Text>
                <Text
                  style={{
                    fontSize: 13,
                    color: "#000",
                    marginHorizontal: 10,
                  }}
                >
                  Milestones
                </Text>

                {phases.length > 1 ? (
                  <TouchableOpacity
                    style={{ position: "absolute", right: 5, top: 5 }}
                    onPress={() => {
                      setPhases({ type: "REMOVE_PHASE", index: index });
                    }}
                  >
                    <Icon name={"cross"}></Icon>
                  </TouchableOpacity>
                ) : (
                  <></>
                )}
                <FlatList
                  nestedScrollEnabled
                  scrollEnabled
                  keyExtractor={(item, index) =>
                    item._id + " " + index + item.name
                  }
                  data={item.steps}
                  renderItem={({ item }) =>
                    item.name ? (
                      <ContainerWithDifferentBg
                        backgroundColor={"#7A9D54"}
                        titleColor="#FFF"
                        titleFontSize={16}
                        title={item.name}
                        subTitle={item.description}
                      ></ContainerWithDifferentBg>
                    ) : (
                      <></>
                    )
                  }
                />
                <LargeButton
                  pressFunc={() => addMileStone(index)}
                  textColor={"#FFF"}
                  backgroundColor={"#000"}
                  disableIcon={true}
                  title={"Add a Milestone +"}
                ></LargeButton>
                <Text style={{ fontSize: 13, color: "#000", margin: 10 }}>
                  Deliverables Included
                </Text>
                <FlatList
                  nestedScrollEnabled
                  scrollEnabled
                  keyExtractor={(item, index) =>
                    item._id + " " + index + item.name || Math.random()
                  }
                  data={item.deliverables}
                  renderItem={({ item }) =>
                    item.name ? (
                      <ContainerWithDifferentBg
                        backgroundColor={"#7A9D54"}
                        titleColor="#FFF"
                        titleFontSize={16}
                        title={item.name}
                        subTitle={"File Format: PDF, EPS, AI, PNG, JPG"}
                      ></ContainerWithDifferentBg>
                    ) : (
                      <></>
                    )
                  }
                />
                <LargeButton
                  pressFunc={() => addDeliverable(index)}
                  textColor={"#FFF"}
                  backgroundColor={"#000"}
                  disableIcon={true}
                  title={"Add a Deliverable +"}
                ></LargeButton>
                <ContainerWithDifferentBg
                  disabled={false}
                  onPress={() => setDatePickerVisibility(true)}
                  backgroundColor={"#E94242"}
                  titleColor="#FFF"
                  titleFontSize={16}
                  title={`Deadline:  ${
                    item.deadLine
                      ? moment(item.deadLine).format("Do MMMM YYYY")
                      : "Please select deadline for this phase!"
                  }`}
                ></ContainerWithDifferentBg>
                <DateTimePickerModal
                  minimumDate={new Date()}
                  maximumDate={new Date(selectedProject.deadLine)}
                  isVisible={isDatePickerVisible}
                  onConfirm={(d) => {
                    console.log(d);
                    setPhases({
                      type: "ADD_DEADLINE",
                      index,
                      value: d,
                    });
                    setDatePickerVisibility(false);
                  }}
                  mode={"date"}
                  format="YYYY-MM-DD"
                  onCancel={() => setDatePickerVisibility(false)}
                />
              </View>
              <View style={styles.containerStyle}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#818181",
                    margin: 10,
                  }}
                >
                  Payment
                </Text>
                <Text
                  style={{
                    fontSize: 13,
                    color: "#000",
                    marginHorizontal: 10,
                  }}
                >
                  Final Phase Payment
                </Text>
                <View style={{ marginTop: 40, marginHorizontal: 10 }}>
                  <LargeTextInput
                    keyboardType={"numeric"}
                    style={{ marginHorizontal: 10 }}
                    value={`${
                      item.amount.find((x) => x.type === "Final")?.payment
                        ?.amount
                    }`}
                    placeholder={"enter total phase payment"}
                    marginLeft={1}
                    width={"100%"}
                    onChangeText={(d) => {
                      setAmount({ final: d, index: index });
                      setPhases({
                        type: "ADD_AMOUNT",
                        index,
                        value: { type: "Final", amount: d },
                      });
                    }}
                  ></LargeTextInput>
                </View>
                <Text style={{ fontSize: 13, color: "#000", margin: 10 }}>
                  Payment in breaks as follows:
                </Text>
                <View style={styles.paymentContainerStyle}>
                  <Text style={styles.paymentTextStyle}>
                    {`Advance Payment: INR `}
                  </Text>
                  <View style={styles.textInput}>
                    <TextInput
                      keyboardType={"numeric"}
                      onChangeText={(d) => {
                        setAmount({ advance: d, index: index });
                        setPhases({
                          type: "ADD_AMOUNT",
                          index,
                          value: { type: "Advance", amount: d },
                        });
                      }}
                      value={`${
                        item.amount
                          ? item.amount.find((x) => x.type === "Advance")
                              .payment.amount
                          : 0
                      }`}
                      style={{ paddingBottom: 0 }}
                    ></TextInput>
                  </View>
                </View>
                <View style={styles.paymentContainerStyle}>
                  <Text style={styles.paymentTextStyle}>
                    {`Rest Payment: INR `}
                  </Text>
                  <View style={styles.textInput}>
                    <TextInput
                      keyboardType={"numeric"}
                      onChangeText={(d) => {
                        setAmount({ rest: d, index: index });
                        setPhases({
                          type: "ADD_AMOUNT",
                          index,
                          value: { type: "Rest", amount: d },
                        });
                      }}
                      value={`${
                        item.amount
                          ? item.amount.find((x) => x.type === "Rest").payment
                              .amount
                          : 0
                      }`}
                      style={{ paddingBottom: 0 }}
                    ></TextInput>
                  </View>
                </View>
              </View>
              {modal && modal.show ? (
                <InputDialogBox
                  jsx={
                    <View style={styles.modalContainerStyle}>
                      <TouchableOpacity
                        onPress={() => setModal({ show: false })}
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 10,
                        }}
                      >
                        <Icon fill="#000" name="cross"></Icon>
                      </TouchableOpacity>
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#000",
                          fontWeight: "bold",
                          position: "absolute",
                          top: 0,
                          left: 20,
                        }}
                      >
                        {`Add ${modal.value}`}
                      </Text>
                      {modal.value === "Milestone" ? (
                        <View style={{ height: "100%", marginTop: 10 }}>
                          <LargeTextInput
                            style={{ marginHorizontal: 10 }}
                            value={tempObj?.name}
                            onChangeText={(e) =>
                              setTempObj({ ...tempObj, name: e })
                            }
                            marginLeft={1}
                            width={"100%"}
                            placeholder={"Add Milestone"}
                          ></LargeTextInput>
                          <LargeTextInput
                            style={{ marginHorizontal: 10 }}
                            value={tempObj?.description}
                            onChangeText={(e) =>
                              setTempObj({ ...tempObj, description: e })
                            }
                            marginLeft={1}
                            width={"100%"}
                            placeholder={"Add Milestone Description"}
                          ></LargeTextInput>
                        </View>
                      ) : (
                        <View
                          style={{
                            height: "100%",
                            justifyContent: "center",
                            bottom: 40,
                          }}
                        >
                          <LargeTextInput
                            style={{ marginHorizontal: 10 }}
                            onChangeText={(e) => setTempObj({ name: e })}
                            value={tempObj?.name}
                            marginLeft={1}
                            width={"100%"}
                            placeholder={"Add a Deliverable"}
                          ></LargeTextInput>
                        </View>
                      )}
                      <View style={[styles.buttonStyle]}>
                        <RoundButton
                          pressFunc={() => add()}
                          title="Add"
                          style={{
                            width: 100,
                            alignSelf: "center",
                            backgroundColor: "#000000",
                            marginBottom: 10,
                          }}
                        ></RoundButton>
                        <RoundButton
                          title="Discard"
                          style={{
                            width: 100,
                            alignSelf: "center",
                            backgroundColor: "#EB4141",
                            marginBottom: 10,
                            marginLeft: 10,
                          }}
                        ></RoundButton>
                      </View>
                    </View>
                  }
                  showModal={modal.show}
                ></InputDialogBox>
              ) : (
                <></>
              )}
            </View>
          )}
        />

        <View style={{ marginHorizontal: 10 }}>
          <LargeButton
            pressFunc={() =>
              setPhases({
                type: "ADD_PHASE",
              })
            }
            textColor={"#FFF"}
            backgroundColor={"#000"}
            disableIcon={true}
            title={"Add a Phase +"}
          ></LargeButton>
        </View>
        <NextAndDiscardButton
          onPressDiscard={() => setPhases({ type: "REMOVE_ALL" })}
          onPressNext={() => next()}
        />
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, { createProject, addError })(
  ProjectMilestone
);

const styles = StyleSheet.create({
  titleStyle: {
    fontWeight: "bold",
    fontSize: 25,
    margin: 25,
    color: "#000",
  },
  headerStyle: {
    minHeight: "18%",
    maxHeight: "18%",
  },
  containerStyle: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#FFF",
    marginHorizontal: 10,
    marginTop: 5,
  },
  paymentContainerStyle: {
    backgroundColor: "#FFF",
    borderWidth: 2,
    borderColor: "#000",
    borderRadius: 10,
    justifyContent: "space-around",
    padding: 20,
    margin: 10,
    flexDirection: "row",
  },
  paymentTextStyle: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
  },
  modalContainerStyle: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    width: 350,
    borderColor: "rgba(0, 0, 0, 0.1)",
    height: 300,
    paddingVertical: 50,
  },
  buttonStyle: {
    width: "100%",
    position: "absolute",
    flexDirection: "row",
    bottom: 20,
    justifyContent: "center",
    marginLeft: 20,
  },
  discardStyle: {
    alignSelf: "center",
    backgroundColor: "#EB4141",
    marginBottom: 10,
    marginLeft: 15,
  },
  nextStyle: {
    alignSelf: "center",
    backgroundColor: "#000000",
    marginBottom: 10,
  },
  textInput: {
    width: 80,
    borderBottomWidth: 1,
    borderBottomColor: "grey",
    height: 35,
    justifyContent: "center",
  },
});
