import React, { useEffect } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  useWindowDimensions,
  Platform,
} from "react-native";
import { connect } from "react-redux";

import { doneLoading } from "../redux/actions/loading.action";

const Loader = ({ loadingReqs, doneLoading }) => {
  useEffect(() => {
    setTimeout(() => {
      loadingReqs
        .filter(
          (x) => Number.parseInt(x.split("$")[0]) + 60000 < new Date().getTime()
        )
        .forEach((l) => {
          setTimeout(() => {
            doneLoading(l);
          }, 1000);
        });
    }, 100);
  }, [loadingReqs]);
  const window = useWindowDimensions();
  return loadingReqs.length > 0 ? (
    <View
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: Platform.OS === "android" ? window.height + 100 : window.height,
        backgroundColor: "#00000080",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ActivityIndicator size="large" color="#FFD500" />
      <Text style={{ color: "#ffffff", marginTop: 10 }}>Loading...</Text>
    </View>
  ) : (
    <></>
  );
};

const mapStateToProps = ({ loadingReqs }) => ({ loadingReqs });

export default connect(mapStateToProps, { doneLoading })(Loader);
