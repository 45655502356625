import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import Icon from "../../components/icon";
import RoundButton from "../../components/roundButton";
import Modal from "../../components/modal/modal";
import { connect } from "react-redux";
import {
  giveUpProject,
  createProject,
} from "../../redux/actions/project.action";
import GiveUpProjectModal from "../../components/giveUpProjectModal";
import { fetchOtherPartners } from "../../redux/actions/partners.action";
import { BlurView } from "expo-blur";
import adjust from "../../components/adjust";
import LargeButton from "../../components/largeButton";
import { useFocusEffect } from "@react-navigation/native";
import { unreadMessages } from "../../redux/actions/chat.action";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import Header from "../../components/header";
import moment from "moment";
import { primaryColor } from "../../components/utils";

const ActiveProjectDetails = ({
  navigation,
  user,
  selectedProject,
  createProject,
  fetchOtherPartners,
  selectedRequirement,
  unreadCount,
  unreadMessages,
}) => {
  const dimension = useWindowDimensions();
  const [requestedAmount, setRequestedAmount] = useState(0);

  useFocusEffect(React.useCallback(() => {}, []));

  function abbrNum(number, decPlaces) {
    decPlaces = Math.pow(10, decPlaces);
    number = Math.round(number * decPlaces) / decPlaces;
    var abbrev = ["K", "M", "B", "T"];
    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        if (number == 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }
        number += abbrev[i];
        break;
      }
    }
    return number;
  }
  const [showModal, setShowModal] = useState(false);
  const [showInactiveProjectModal, setShowInactiveProjectModal] =
    useState(false);
  const modalCloseHandler = (childData) => {
    setShowModal(childData);
  };
  const [partnersFetched, setPartnersFetched] = useState(false);
  useEffect(() => {
    if (!partnersFetched) {
      setPartnersFetched(true);
      fetchOtherPartners(true);
    }
  });

  useEffect(() => {
    if (selectedProject && ["CLIENT"].includes(user.userType)) {
      setRequestedAmount(
        selectedProject?.paymentPhases
          ?.map((x) => [
            ...x.amount.map((y) => y.payment),
            ...x.steps.map((y) => y.payment),
          ])
          .flat()
          ?.filter((x) => x?.status === "Requested")
          ?.reduce((a, b) => a + b.amount, 0)
      );
    }
    if (selectedProject) {
      unreadMessages({ project: selectedProject._id });
    }
  }, [selectedProject]);
  return (
    <View style={{ flex: 1 }}>
      <View style={{ minHeight: 170, maxHeight: 170 }}>
        <Header
          title={selectedProject.title}
          subtitle={`Client: ${selectedProject?.user?.fName} ${
            selectedProject.user.lName
          } | Deadline:  ${moment(selectedProject.deadLine).format(
            "DD/MM/YY"
          )}`}
          secondarySubtitle={
            <View
              style={{ flexDirection: "row", marginLeft: 30, marginTop: 3 }}
            >
              <Text style={styles.subtitleStyle}>{`project started on `}</Text>
              <Text
                style={[
                  styles.subtitleStyle,
                  { color: primaryColor(user.userType) },
                ]}
              >
                {moment(selectedProject.startDate).format("DD/MM/YY")}
              </Text>
            </View>
          }
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
        {/* <Text
          style={{
            marginHorizontal: 30,
            color: "#606060",
          }}
        >
          {user.userType == "PARTNER" ? "Client:" : "Partner:"}
          {user.userType == "PARTNER"
            ? selectedProject.user?.name
            : selectedProject.partner?.name
            ? selectedProject.partner?.name
            : "Partner not yet assigned"}
          {/* | 05 Days to Deadline 
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            color: "#828282",
            fontWeight: "bold",
            paddingVertical: 10,
          }}
        >
          special code: {selectedProject?.otp}
        </Text> */}
      </View>
      {/* <View
        style={{
          backgroundColor: "#fff",
          paddingBottom: 10,
          // minHeight: 400,
          // maxHeight: 400,
          paddingTop: 30,
          marginBottom: 10,
        }}
      >
        <View style={{ paddingHorizontal: 5 }}>
          <TouchableOpacity
            style={{
              left: 25,
              marginTop: 40,
              flexDirection: "row",
              alignItems: "center",
              zIndex: 999,
            }}
            onPress={() => navigation.navigate("dashboard")}
          >
            <Icon name="back" fill="#000" />
          </TouchableOpacity>
        </View>

        <View
          style={{
            marginLeft: 30,
            marginRight: 80,
            justifyContent: "flex-end",
            flexWrap: "wrap",
            flexGrow: 1,
            alignItems: "flex-start",
            paddingVertical: 5,
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: adjust(25),
              overflow: "visible",
              width: "100%",
              color: "#000",
            }}
          >
            {selectedProject.title}
          </Text>
          {selectedProject.projectTags ? (
            selectedProject.projectTags.map((t, i) => {
              return (
                <View key={i} style={{ flexDirection: "row" }}>
                  <View style={styles.skill}>
                    <Text>{t.name}</Text>
                  </View>
                </View>
              );
            })
          ) : (
            <></>
          )}
        </View>

        <View
          style={{
            justifyContent: "flex-start",
            alignContent: "flex-start",
            paddingVertical: 5,
          }}
        >
          <Text
            style={{
              // flex: 1,
              marginLeft: 30,
              marginRight: 30,
              color: "#606060",
              // maxHeight: 30,
            }}
          >
            {user.userType == "PARTNER" ? "Client:" : "Partner:"}
            {user.userType == "PARTNER"
              ? selectedProject.user?.name
              : selectedProject.partner?.name
              ? selectedProject.partner?.name
              : "Partner not yet assigned"}
            | 05 Days to Deadline
          </Text>
        </View>
        <View
          style={{
            justifyContent: "flex-start",
            alignContent: "flex-start",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <Text
            style={{ paddingVertical: 0, color: "#828282", fontWeight: "bold" }}
          >
            special code: {selectedProject?.otp}
          </Text>
        </View>
      </View> */}

      {/* Cards starts here */}
      <ScrollView
        contentContainerStyle={{ paddingBottom: 20, marginHorizontal: 15 }}
        scrollEnabled={true}
        nestedScrollEnabled={true}
      >
        {user.userType === "CLIENT" && requestedAmount ? (
          <LargeButton
            titleStyle={{
              fontSize: adjust(18),
              textAlign: "left",
              paddingTop: 5,
            }}
            pressedTitleStyle={{ fontSize: adjust(18), paddingTop: 5 }}
            title={`₹ ${abbrNum(requestedAmount, 2)} requested`}
            pressFunc={() => navigation.navigate("projectPayment")}
          ></LargeButton>
        ) : (
          <></>
        )}
        <View
          style={{
            marginTop: 10,
          }}
        >
          <DashboardCardWithArrowAndIcon
            title="Project details"
            description="Tap to check project details"
            value=""
            icon={false}
            // onPressCard={() =>
            //   selectedProject?.active
            //     ? navigation.navigate("activeProjectSummary")
            //     : setShowInactiveProjectModal(true)
            // }
            onPressCard={() => navigation.navigate("activeProjectSummary")}
            backgroundColor="#FF973E"
          ></DashboardCardWithArrowAndIcon>
          <DashboardCardWithArrowAndIcon
            source={require("../../assets/chart.png")}
            title="Project status"
            description="Tap to check project status"
            value={`${
              selectedProject?.completionPercent
                ? Math.round(selectedProject?.completionPercent) + "%"
                : "0%"
            } done`}
            // onPressCard={() =>
            //   selectedProject?.active
            //     ? navigation.navigate("progress")
            //     : setShowInactiveProjectModal(true)
            // }
            onPressCard={() => {
              navigation.navigate("projectStatus");
            }}
            backgroundColor="#7A9D54"
          ></DashboardCardWithArrowAndIcon>
          <DashboardCardWithArrowAndIcon
            source={require("../../assets/chat-bubble.png")}
            title="Project discussions"
            description="Tap to check project discussions"
            value={`${
              unreadCount.find((x) => x._id === selectedProject._id)?.count || 0
            } new`}
            onPressCard={() =>
              selectedProject?.active || selectedProject?.closed
                ? navigation.navigate("discussionGroups", {
                    project: selectedProject._id,
                  })
                : setShowInactiveProjectModal(true)
            }
            backgroundColor="#FFB100"
          ></DashboardCardWithArrowAndIcon>
          <DashboardCardWithArrowAndIcon
            source={require("../../assets/payment.png")}
            title="Manage Payments (in INR)"
            description={
              user.userType === "CLIENT"
                ? `Requested by Partner. Settle now`
                : "Received till now"
            }
            value={`${
              user.userType === "CLIENT"
                ? abbrNum(requestedAmount, 2) || "0"
                : abbrNum(
                    user.userType === "PARTNER"
                      ? selectedProject?.paymentPhases
                          ?.map((x) => [
                            ...x.amount.map((y) => y.payment),
                            ...x.steps.map((y) => y.payment),
                          ])
                          .flat()
                          ?.filter((x) => x?.status === "Requested")
                          ?.reduce((a, b) => a + b.amount, 0)
                      : selectedProject?.team
                          ?.filter((x) => x.user._id === user._id)
                          .map((x) => x.payments)
                          .flat()
                          .filter((x) => x.payment.status === "Received")
                          .reduce((a, b) => a + Number(b.payment.amount), 0),
                    2
                  ) || "0"
            }`}
            onPressCard={() => navigation.navigate("projectPayment")}
            backgroundColor="#3C581D"
          ></DashboardCardWithArrowAndIcon>
          <DashboardCardWithArrowAndIcon
            source={require("../../assets/cloud.png")}
            title="Project Deliverables"
            description="Deliverables available. Tap to add more"
            value={`${selectedProject?.deliverables?.length || 0}`}
            onPressCard={() =>
              selectedProject?.active || selectedProject?.closed
                ? navigation.navigate("deliverables")
                : setShowInactiveProjectModal(true)
            }
            backgroundColor="#3DB79B"
          ></DashboardCardWithArrowAndIcon>
          <DashboardCardWithArrowAndIcon
            disabled={selectedProject.closed}
            title="My Meetings"
            description="Next meeting today at 05:30 PM"
            value=""
            icon={false}
            backgroundColor={selectedProject.closed ? "#D3D3D3" : "#073257"}
            onPressCard={() => {
              navigation.navigate("myMeetings", {
                data: selectedProject,
              });
            }}
          ></DashboardCardWithArrowAndIcon>
          {user.userType === "CLIENT" && (
            <DashboardCardWithArrowAndIcon
              title="Project Feedback"
              description="Tap here to give feedbacks to the project"
              value=""
              icon={false}
              backgroundColor="#BA448D"
              onPressCard={() => {
                navigation.navigate("reviewFeedback");
              }}
            ></DashboardCardWithArrowAndIcon>
          )}
          {!selectedProject?.closed &&
          (selectedProject.partner._id === user._id ||
            selectedProject.user._id === user._id) ? (
            <DashboardCardWithArrowAndIcon
              title="Close Project"
              value=""
              icon={false}
              backgroundColor="#000"
              onPressCard={() => {
                navigation.navigate("close-project");
              }}
            ></DashboardCardWithArrowAndIcon>
          ) : (
            <></>
          )}
          {/* <DashboardCard
            jsx={
              <TouchableOpacity
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: selectedProject?.active
                    ? "#FFFFFF"
                    : "transparent",
                  borderRadius: 10,
                }}
                onPress={() =>
                  selectedProject?.active
                    ? navigation.navigate("activeProjectSummary")
                    : setShowInactiveProjectModal(true)
                }
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(16),
                    marginLeft: 15,
                    color: "#a9a9a9",
                    marginTop: 15,
                  }}
                >
                  Project Details
                </Text>
                <View style={{ marginBottom: 0 }}>
                  {/* <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: adjust(40),
                      marginLeft: 15,
                      color: "#000",
                      marginLeft: 15,
                      marginBottom: 10,
                    }}
                  >
                    5
                  </Text> */}
          {/* <Text
                    style={{
                      fontWeight: "700",
                      fontSize: adjust(14),
                      marginLeft: 15,
                      color: "#a9a9a9",
                      marginBottom: 10,
                      paddingRight: 10,
                    }}
                  >
                    {`Read the details about the Project`}
                  </Text>
                </View>
              </TouchableOpacity> */}
          {/* }
            style={[
              styles.largeBoxContainer,
              {
                maxWidth: dimension.width / 2 - 30,
                minHeight: dimension.width / 2 - 30,
                maxWidth: dimension.width / 2 - 30,
                minWidth: dimension.width / 2 - 30,
              },
            ]}
          ></DashboardCard> */}

          {/* <DashboardCard
            jsx={
              <TouchableOpacity
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: selectedProject?.active
                    ? "#FFFFFF"
                    : "transparent",
                  borderRadius: 10,
                }}
                onPress={() =>
                  selectedProject?.active
                    ? navigation.navigate("progress")
                    : setShowInactiveProjectModal(true)
                }
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(16),
                    marginLeft: 15,
                    color: "#a9a9a9",
                    marginTop: 15,
                    paddingRight: 10,
                  }}
                >
                  Project Progress
                </Text>
                <View style={{ marginBottom: 0 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: adjust(40),
                      marginLeft: 15,
                      color: "#000",
                      marginLeft: 15,
                      marginBottom: 10,
                    }}
                  >
                    {selectedProject?.completionPercent
                      ? Math.round(selectedProject?.completionPercent) + "%"
                      : "0%"}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "700",
                      fontSize: adjust(14),
                      marginLeft: 15,
                      color: "#a9a9a9",
                      marginBottom: 10,
                    }}
                  >
                    Complete
                  </Text>
                </View>
              </TouchableOpacity>
            }
            style={[
              styles.largeBoxContainer,
              {
                maxWidth: dimension.width / 2 - 30,
                minHeight: dimension.width / 2 - 30,
                maxWidth: dimension.width / 2 - 30,
                minWidth: dimension.width / 2 - 30,
              },
            ]}
          ></DashboardCard> */}

          {/* {user.userType == "EMPLOYEE" ? (
            <></>
          ) : (
            <DashboardCard
              jsx={
                <TouchableOpacity
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: 10,
                  }}
                  onPress={() =>
                    selectedProject?.active
                      ? user.userType == "CREATOR"
                        ? navigation.navigate("payments", { teamMember: true })
                        : user.userType == "PARTNER"
                        ? navigation.navigate("payments")
                        : navigation.navigate("projectPayment")
                      : setShowInactiveProjectModal(true)
                  }
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: adjust(16),
                      marginLeft: 15,
                      color: "#a9a9a9",
                      marginTop: 15,
                    }}
                  >
                    Project Payments
                  </Text>
                  <View style={{ marginBottom: 0 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: adjust(40),
                        marginLeft: 15,
                        color: "#000",
                        marginLeft: 15,
                        marginBottom: 10,
                      }}
                    >
                      {user.userType === "PARTNER"
                        ? abbrNum(
                            requestedAmount || selectedProject??.paidAmount,
                            2
                          ) || "0"
                        : abbrNum(
                            requestedAmount ||
                              selectedProject??.team
                                .filter((x) => x.user._id === user._id)
                                .map((x) => x.payments)
                                .flat()
                                .filter((x) => x.payment.status === "Received")
                                .reduce(
                                  (a, b) => a + Number(b.payment.amount),
                                  0
                                ),
                            2
                          ) || "0"}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "700",
                        fontSize: adjust(14),
                        marginLeft: 15,
                        color: "#a9a9a9",
                        marginBottom: 10,
                      }}
                    >
                      {user.userType == "PARTNER" || user.userType == "CREATOR"
                        ? `Total ${requestedAmount ? "Requested" : "Approved"}`
                        : `Total ${requestedAmount ? "Requested" : "Paid"}`}
                    </Text>
                  </View>
                </TouchableOpacity>
              }
              style={[
                styles.largeBoxContainer,
                {
                  maxWidth: dimension.width / 2 - 30,
                  minHeight: dimension.width / 2 - 30,
                  maxWidth: dimension.width / 2 - 30,
                  minWidth: dimension.width / 2 - 30,
                },
              ]}
            ></DashboardCard>
          )} */}

          {/* <DashboardCard
            jsx={
              <TouchableOpacity
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: 10,
                }}
                onPress={() =>
                  selectedProject??.active
                    ? navigation.navigate("chat")
                    : setShowInactiveProjectModal(true)
                }
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(16),
                    marginLeft: 15,
                    color: "#a9a9a9",
                    marginTop: 15,
                  }}
                >
                  Discussions
                </Text>
                <View style={{ marginBottom: 0 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: adjust(40),
                      marginLeft: 15,
                      color: "#000",
                      marginLeft: 15,
                      marginBottom: 10,
                    }}
                  >
                    {`${unreadCount}`}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "700",
                      fontSize: adjust(14),
                      marginLeft: 15,
                      color: "#a9a9a9",
                      marginBottom: 10,
                    }}
                  >
                    New
                  </Text>
                </View>
              </TouchableOpacity>
            }
            style={[
              styles.largeBoxContainer,
              {
                maxWidth: dimension.width / 2 - 30,
                minHeight: dimension.width / 2 - 30,
                maxWidth: dimension.width / 2 - 30,
                minWidth: dimension.width / 2 - 30,
              },
            ]}
          ></DashboardCard> */}
          {/* <DashboardCard
            jsx={
              <TouchableOpacity
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: 10,
                }}
                onPress={() =>
                  selectedProject?.active
                    ? navigation.navigate("deliverables")
                    : setShowInactiveProjectModal(true)
                }
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(16),
                    marginLeft: 15,
                    color: "#a9a9a9",
                    marginTop: 15,
                  }}
                >
                  Deliverables
                </Text>
                <View style={{ marginBottom: 0 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: adjust(40),
                      marginLeft: 15,
                      color: "#FFD500",
                      marginLeft: 15,
                      marginBottom: 10,
                    }}
                  >
                    {`${selectedProject?.deliverables?.length || 0}`}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "700",
                      fontSize: adjust(14),
                      marginLeft: 15,
                      color: "#a9a9a9",
                      marginBottom: 10,
                    }}
                  >
                    Deliverables Updated
                  </Text>
                </View>
              </TouchableOpacity>
            }
            style={[
              styles.largeBoxContainer,
              {
                maxWidth: dimension.width - 30,
                minHeight: dimension.width / 2 - 30,
                maxWidth: dimension.width - 30,
                minWidth: dimension.width / 2 - 30,
              },
            ]}
          ></DashboardCard> */}
        </View>

        {/* <View>
          {user.userType == "CLIENT" &&
          !requestedAmount &&
          !selectedProject?.closed ? (
            <RoundButton
              title="Close Project"
              style={{
                alignSelf: "center",
                backgroundColor: "#000",
                width: "90%",
                marginBottom: 10,
                minHeight: 50,
                borderRadius: 35,
              }}
              pressFunc={() => {
                navigation.navigate("reviewFeedback", {
                  team: selectedProject??.team,
                });
              }}
            ></RoundButton>
          ) : (
            <></>
          )}
        </View> */}
      </ScrollView>

      <GiveUpProjectModal
        showModal={showModal}
        onCloseModal={modalCloseHandler}
        navigation={navigation}
      ></GiveUpProjectModal>

      <Modal
        animationType="fade"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={showInactiveProjectModal}
        ariaHideApp={false}
      >
        <TouchableWithoutFeedback
          onPress={() => setShowInactiveProjectModal(false)}
        >
          <BlurView
            intensity={80}
            tint="dark"
            style={{
              flex: 1,
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 22,
              backgroundColor: "#fff",
              justifyContent: "center",
            }}
            blurType="dark"
            blurAmount={32}
            blurRadius={100}
          >
            <View
              style={{
                flex: 1,
                maxHeight: 180,
                minHeight: 180,
                width: "90%",
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
                maxWidth: 400,
                padding: 20,
              }}
            >
              <View style={{ alignItems: "center", flex: 1 }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: adjust(18),
                    color: "#A9A9A9",
                  }}
                >
                  Project not yet started
                </Text>
              </View>
              <Text
                style={{
                  flex: 1,
                  color: "#202020",
                  maxWidth: 350,
                  flexWrap: "wrap",
                  alignSelf: "center",
                }}
              >
                Please wait for the Project to Start.
              </Text>
              <RoundButton
                title="OK"
                style={{
                  alignSelf: "center",
                  backgroundColor: "#000000",
                  width: "100%",
                  marginBottom: 10,
                  borderRadius: 50,
                  flex: 1,
                }}
                pressFunc={() => setShowInactiveProjectModal(false)}
              ></RoundButton>
            </View>
          </BlurView>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};
const styles = StyleSheet.create({
  skill: {
    backgroundColor: "#C2BEBE",
    flexDirection: "row",
    borderRadius: 7,
    padding: 3,
    marginRight: 10,
    alignItems: "center",
    paddingHorizontal: 10,
  },
  largeBoxContainer: {
    minHeight: 160,
    minWidth: 160,
    maxHeight: 176,
    maxWidth: 160,
  },
  statsStyle: { backgroundColor: "#66D315" },
  subtitleStyle: {
    color: "#606060",
    fontSize: adjust(16),
    fontWeight: "800",
  },
});
const mapStateToProps = ({
  selectedProject,
  user,
  partners,
  selectedRequirement,
  unreadCount,
}) => ({
  selectedProject,
  user,
  partners,
  selectedRequirement,
  unreadCount,
});
export default connect(mapStateToProps, {
  giveUpProject,
  createProject,
  fetchOtherPartners,
  unreadMessages,
})(ActiveProjectDetails);
