export const fetchDashboardStats = () => (dispatch) => {
  return dispatch({
    type: "FETCH_DASHBOARD_STATS",
    payload: {
      request: {
        url: `/api/user/dashboard`,
        method: "GET",
      },
    },
  });
};
