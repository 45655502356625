const initialState = [];
const tasksActive = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TASK_ACTIVE_SUCCESS": {
      return action.payload.data;
    }

    case "COMPLETE_TASK_SUCCESS": {
      return state.filter((p) => p._id != action.payload.data._id);
    }

    case "CREATE_TASK_SUCCESS": {
      let task = state.find((a) => a._id == action.payload.data._id);
      return task
        ? task.map((p) => (p._id == task._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "INCOMPLETE_TASK_SUCCESS": {
      let data = state.find((a) => a._id == action.payload.data._id);
      return data
        ? state.map((p) => (p._id == data._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default tasksActive;
