export const fetchMeetings = () => (dispatch) => {
  return dispatch({
    type: "FETCH_MEETINGS",
    payload: {
      request: {
        url: `/api/meeting`,
        method: "GET",
      },
    },
  });
};

export const updateMeeting = (obj, navigate) => (dispatch) => {
  return dispatch({
    type: "UPDATE_MEETING",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/meeting`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const createMeetingForCreator = (obj, navigate) => (dispatch) => {
  return dispatch({
    type: "CREATE_MEETING_FOR_CREATOR",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/meeting/creator`,
        method: "POST",
        data: obj,
      },
    },
  });
};
