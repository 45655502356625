import React, { useEffect, useRef, useContext, useState } from "react";
import { connect } from "react-redux";
import PartnerTab from "./partner/partner-tab-navigation";
import CreatorTab from "./creator/creator-tab-navigation";
import ClientTab from "./client/client-tab-navigation";
import AdminTab from "./admin/admin-tab-navigation";
import AccountantTab from "./accountant/accountant-tab-navigation";
import SalesTab from "./sales/sales-tab-navigation";
import { store, client } from "../redux/store";
import { Platform } from "react-native";
import * as Notifications from "expo-notifications";
import { fetchRazorPayKey } from "../redux/actions/razorpay.action";
import { fetchNotificationCount } from "../redux/actions/notification.action";
import { setSelectedProject } from "../redux/actions/project.action";
import { addInfo } from "../redux/actions/toast.action";
import { RouteContext } from "../components/routeContext";
import { unreadMessages } from "../redux/actions/chat.action";
import { fetchMedias } from "../redux/actions/media.action";

var lastMessageID = null;
var varCurrentRoute = null;
const AppStack = ({
  user,
  selectedProject,
  fetchRazorPayKey,
  token,
  fetchNotificationCount,
  projects,
  pendingProjects,
  setSelectedProject,
  addInfo,
  fetchMedias,
  unreadMessages,
}) => {
  const handleLongPoll = (result) => {
    if (result.data && result.data?.length > 0) {
      fetchNotificationCount();
      result.data.forEach((x) => {
        let action = x;
        if (action.type === "FETCH_CHAT_MSGS") {
          if (varCurrentRoute === "chat") {
            let dispatchedAction = {
              ...action,
              payload: {
                ...action.payload,
                request: {
                  ...action.payload.request,
                  url: `${action.payload.request.url}${
                    store.getState().chats.chats > 0
                      ? "&lastMessageID=" + store.getState().chats.chats[0]._id
                      : ""
                  }`,
                },
              },
            };
            store.dispatch(dispatchedAction);
          }
          unreadMessages();
        } else if (action.type === "FETCH_HELP") {
          console.log("LONG POLL");
          if (
            varCurrentRoute === "chat-with-us" &&
            ((action.payload.request.url.split("=")[1] === "ADMIN" &&
              user.userType != "ONEDESIGN") ||
              action.payload.request.url.split("=")[1] != user._id)
          ) {
            let dispatchedAction = {
              ...action,
              payload: {
                ...action.payload,
                request: {
                  ...action.payload.request,
                  url: `${action.payload.request.url}`,
                },
              },
            };
            store.dispatch(dispatchedAction);
          }
        } else {
          store.dispatch(x);
        }
      });
    }
    setTimeout(longPolling, 1000);
  };

  const longPolling = () => {
    client.get("/api/notification").then(handleLongPoll, (error) => {
      if (error && error.response && error.response.status === 401) {
      } else
        setTimeout(
          longPolling,
          1000 * (error.message == "Network Error" ? 6 : 3)
        );
    });
  };

  if (Platform.OS === "web") {
    navigator.serviceWorker.onmessage = (event) => {
      if (event) {
        if (
          !["FETCH_CHAT_MSGS", "FETCH_HELP"].includes(
            JSON.parse(event.data.data.action).type
          )
        ) {
          notify(event.data);
        }
        let action = JSON.parse(event.data.data.action);
        if (action.type === "FETCH_CHAT_MSGS") {
          if (varCurrentRoute === "chat") {
            let dispatchedAction = {
              ...action,
              payload: {
                ...action.payload,
                request: {
                  ...action.payload.request,
                  url: `${action.payload.request.url}${
                    store.getState().chats.chats > 0
                      ? "&lastMessageID=" + store.getState().chats.chats[0]._id
                      : ""
                  }`,
                },
              },
            };
            store.dispatch(dispatchedAction);
          }
          unreadMessages();
        } else if (action.type === "FETCH_HELP") {
          console.log("Web notification");

          if (
            varCurrentRoute === "chat-with-us" &&
            ((action.payload.request.url.split("=")[1] === "ADMIN" &&
              user.userType != "ONEDESIGN") ||
              action.payload.request.url.split("=")[1] != user._id)
          ) {
            let dispatchedAction = {
              ...action,
              payload: {
                ...action.payload,
                request: {
                  ...action.payload.request,
                  url: `${action.payload.request.url}`,
                },
              },
            };
            store.dispatch(dispatchedAction);
          }
        } else {
          store.dispatch(action);
        }
      }
    };
  }

  useEffect(() => {
    if (
      projects &&
      projects.length > 0 &&
      selectedProject &&
      selectedProject._id
    ) {
      let updatedDetails = projects.find((x) => x._id === selectedProject._id);
      if (updatedDetails) {
        setSelectedProject({
          ...updatedDetails,
        });
      }
    }
  }, [projects]);

  useEffect(() => {
    if (
      pendingProjects &&
      pendingProjects.length > 0 &&
      selectedProject &&
      selectedProject._id
    ) {
      let updatedDetails = pendingProjects.find(
        (x) => x._id === selectedProject._id
      );
      if (updatedDetails) {
        setSelectedProject({
          ...updatedDetails,
        });
      }
    }
  }, [pendingProjects]);
  useEffect(() => {
    if (user && token) {
      fetchRazorPayKey();
      fetchNotificationCount();
      fetchMedias();
    }
  }, [user]);

  const route = useContext(RouteContext);

  useEffect(() => {
    varCurrentRoute = route;
  }, [route]);

  const listener = useRef();

  useEffect(() => {
    longPolling();
    if (Platform.OS != "web") {
      listener.current = Notifications.addNotificationReceivedListener(
        (event) => {
          let action = event?.request?.content?.data?.action;
          if (action) {
            if (action.type === "FETCH_CHAT_MSGS") {
              unreadMessages();
              if (varCurrentRoute === "chat") {
                store.dispatch({
                  ...action,
                  payload: {
                    ...action.payload,
                    request: {
                      ...action.payload.request,
                      url: `${action.payload.request.url}${
                        store.getState().chats.chats > 0
                          ? "&lastMessageID=" +
                            store.getState().chats.chats[0]._id
                          : ""
                      }`,
                    },
                  },
                });
              }
            } else if (action.type === "FETCH_HELP") {
              if (
                varCurrentRoute === "chat-with-us" &&
                ((action.payload.request.url.split("=")[1] === "ADMIN" &&
                  user.userType != "ONEDESIGN") ||
                  action.payload.request.url.split("=")[1] != user._id)
              ) {
                let dispatchedAction = {
                  ...action,
                  payload: {
                    ...action.payload,
                    request: {
                      ...action.payload.request,
                      url: `${action.payload.request.url}`,
                    },
                  },
                };
                store.dispatch(dispatchedAction);
              }
            } else store.dispatch(event.request.content.data.action);
          }
        }
      );
    }
    return () => {
      if (Platform.OS != "web") {
        Notifications.removeNotificationSubscription(listener.current);
      }
    };
  }, []);

  const notify = (data) => {
    if (!globalThis.window.Notification) {
      console.log("Browser does not support notifications.");
    } else {
      if (Notification.permission === "granted") {
      } else {
        Notification.requestPermission()
          .then(function (p) {
            if (p === "granted") {
            }
          })
          .catch(function (err) {
            console.error(err);
          });
      }
    }
  };

  if (user.userType == "CREATOR") {
    return <CreatorTab></CreatorTab>;
  } else if (user.userType == "CLIENT") {
    return <ClientTab></ClientTab>;
  } else if (user.userType == "PARTNER") {
    return <PartnerTab selectedProject={selectedProject}></PartnerTab>;
  } else if (user.userType == "ONEDESIGN") {
    return <AdminTab></AdminTab>;
  } else if (user.userType == "ACCOUNTANT") {
    return <AccountantTab></AccountantTab>;
  } else if (user.userType == "SALES") {
    return <SalesTab></SalesTab>;
  } else {
    return <></>;
  }
};
const mapStateToProps = ({
  user,
  projects,
  selectedProject,
  token,
  pendingProjects,
}) => ({
  user,
  projects,
  selectedProject,
  token,
  pendingProjects,
});
export default connect(mapStateToProps, {
  fetchRazorPayKey,
  fetchNotificationCount,
  setSelectedProject,
  addInfo,
  unreadMessages,
  fetchMedias,
})(AppStack);
