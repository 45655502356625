import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/admin/screens/dashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import chat from "../../screens/chat";
import editYourProfile from "../../screens/editYourProfile";
import discussions from "../../screens/admin/screens/inbox";
import chatWithUs from "../../screens/common/chat-with-us";
const window = Dimensions.get("window");
const AdminStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <AdminStack.Navigator
      name="partnerProject"
      initialRouteName={"discussions"}
    >
      <AdminStack.Screen
        name="discussions"
        component={discussions}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="chat"
        component={chat}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="editYourProfile"
        component={editYourProfile}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="chat-with-us"
        component={chatWithUs}
        options={{ headerShown: false }}
      />
    </AdminStack.Navigator>
  );
};
