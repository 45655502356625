import { StyleSheet, Text, View, Image } from "react-native";
import React, { useEffect } from "react";
import LargeTextInput from "../largeTextInput";
import { fullName } from "../utils";

const FeedbackComponent = ({
  image,
  user,
  userName,
  profile,
  data,
  onChangeFeedback,
  onChangeRating,
}) => {
  const ratings = [
    { name: 1 },
    { name: 2 },
    { name: 3 },
    { name: 4 },
    { name: 5 },
  ];

  return (
    <View style={styles.feedbackWrapper}>
      <Text
        style={{
          marginVertical: image ? 5 : 10,
          fontWeight: "bold",
          color: "#818181",
          marginLeft: image ? 3 : 0,
          fontSize: 16,
        }}
      >
        {user}
      </Text>
      <View style={{ flexDirection: "row" }}>
        {image ? (
          <Image
            style={styles.imageStyle}
            source={require("../../assets/avatar/avatar.png")}
          ></Image>
        ) : (
          <></>
        )}

        <View style={{ alignSelf: "center", marginLeft: image ? 10 : 0 }}>
          <Text style={{ color: "#202020", fontWeight: "bold", fontSize: 20 }}>
            {userName}
          </Text>
          <Text style={{ fontSize: 13, color: "#0D0D0D" }}>{profile}</Text>
        </View>
      </View>
      <View style={{ marginVertical: 20, height: 55 }}>
        <LargeTextInput
          marginRight={25}
          onSelectionChange={(item) => onChangeRating(item.name)}
          renderData={ratings}
          placeholder={"tap to select a rating"}
          selectedValue={{ name: data?.rating || "" }}
        ></LargeTextInput>
      </View>
      <View style={{ marginVertical: 10, height: 55 }}>
        <LargeTextInput
          value={data?.feedback || ""}
          marginLeft={1}
          placeholder="any feedbacks"
          style={{ borderBottomColor: "#B4B2B2", marginHorizontal: 0 }}
          width={"100%"}
          onChangeText={(data) => onChangeFeedback(data)}
        ></LargeTextInput>
      </View>
    </View>
  );
};

export default FeedbackComponent;

const styles = StyleSheet.create({
  feedbackWrapper: {
    backgroundColor: "#FFFFFF",
    borderRadius: 15,
    padding: 15,
    marginVertical: 5,
  },
  imageStyle: { height: 50, width: 50, borderRadius: 100, marginVertical: 10 },
});
