export const registerPartner = (user) => (dispatch) => {
  return dispatch({
    type: "REGISTER_PARTNER",
    payload: {
      request: {
        url: `/api/user/partner`,
        method: "POST",
        data: user,
      },
    },
  });
};

export const fetchPartners = () => (dispatch) => {
  return dispatch({
    type: "FETCH_PARTNER",
    payload: {
      request: {
        url: `/api/user/partner`,
        method: "GET",
      },
    },
  });
};
export const deletePartner = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_PARTNER",
    payload: {
      request: {
        url: `/api/user/partner?id=${id}`,
        method: "DELETE",
      },
    },
  });
};
