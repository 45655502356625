import {
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import Header from "../../components/header";
import adjust from "../../components/adjust";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import LargeTextInput from "../../components/largeTextInput";
import NextAndDiscardButton from "../../components/nextAndDiscardButton/nextAndDiscardButton";
import { connect } from "react-redux";
import { fullName } from "../../components/utils";
import { createProject } from "../../redux/actions/project.action";
import { useEffect } from "react";
const EndProject = ({ navigation, selectedProject, createProject }) => {
  const [reason, setReason] = useState("");
  const [reasonSubmitted, setReasonSubmitted] = useState(false);

  const closeProject = () => {
    if (reasonSubmitted)
      createProject(
        { _id: selectedProject._id, closed: true },
        navigateOnSuccess
      );
  };
  const navigateOnSuccess = () => navigation.navigate("myProjects");

  useEffect(() => {
    setReasonSubmitted(
      selectedProject?.paymentPhases?.find((x) =>
        x.amount.find(
          // (x) => x
          (y) => y.type !== "Final" && y.payment.status != "Received"
        )
      )
        ? false
        : true
    );
  }, [selectedProject]);

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="end project"
          subtitle="close the project"
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          enableAvatar={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={[styles.projectTitleContainer, { backgroundColor: "#000" }]}>
        <Text style={styles.mainHeader}>{selectedProject?.title}</Text>
        <Text style={styles.clientName}>{`Client: ${fullName(
          selectedProject.user
        )}`}</Text>
      </View>

      {!reasonSubmitted ? (
        <>
          <View style={styles.warningStyle}>
            <Text style={{ color: "#FFFFFF" }}>
              You cannot end the project right now as the scope/payment is not
              complete. Please provide reason for project closure before time.
            </Text>
          </View>
          <View
            style={{ marginVertical: 10, height: 55, marginHorizontal: 15 }}
          >
            <LargeTextInput
              value={reason}
              marginLeft={1}
              placeholder="enter reason"
              style={{ borderBottomColor: "#B4B2B2", marginHorizontal: 0 }}
              width={"100%"}
              onChangeText={(data) => setReason(data)}
            ></LargeTextInput>
          </View>
          <NextAndDiscardButton
            onPressSubmit={() => {
              if (reason && reason != "") {
                setReasonSubmitted(true);
              }
            }}
            onPressDiscard={() => {
              setReasonSubmitted(false);
              setReason("");
            }}
            firstButtonName={"Submit"}
            style={styles.buttonTextStyle}
          ></NextAndDiscardButton>
        </>
      ) : (
        <></>
      )}
      <View style={styles.buttonStyle}>
        <DashboardCardWithArrowAndIcon
          onPressCard={() => closeProject()}
          style={{ marginHorizontal: 10 }}
          title="Close Project"
          value=""
          icon={false}
          backgroundColor={reasonSubmitted ? "#000" : "#D3D3D3"}
        ></DashboardCardWithArrowAndIcon>
      </View>
    </View>
  );
};

const mapStateToProps = ({ selectedProject }) => ({ selectedProject });
export default connect(mapStateToProps, { createProject })(EndProject);

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  titleText: {
    padding: 8,
    fontSize: adjust(16),
    fontWeight: "bold",
  },

  buttonStyle: {
    marginVertical: 10,
    flex: 1,
    flexDirection: "column-reverse",
  },
  buttonTextStyle: {
    flexDirection: "row",
    marginVertical: 10,
  },
  warningStyle: {
    backgroundColor: "#FF0000",
    padding: 20,
    borderRadius: 8,
    justifyContent: "center",
    marginHorizontal: 10,
  },
  projectTitleContainer: {
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
    marginHorizontal: 10,
  },
  mainHeader: {
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#ffffff",
    margin: 10,
  },
  clientName: {
    fontSize: 11,
    color: "#FFFFFF",
    marginHorizontal: 10,
    marginVertical: 5,
  },
});
