import * as React from "react";
import { Text, View, StyleSheet, Pressable } from "react-native";
import adjust from "./adjust";
const NewProjects = ({
  user,
  title,
  tags,
  partner,
  dateTime,
  projectId,
  budget,
  duration,
  onPress,
  requiredMember,
  quotedAmount,
  deadLine,
}) => {
  return (
    <Pressable
      style={({ pressed }) => [
        {
          borderColor: "#FFD500",
          borderWidth: 1,
          backgroundColor: pressed ? "#FFD500" : "#FFFFFF",
          alignContent: "center",
          justifyContent: "center",
          flex: 1,
          // minHeight: 220,
          borderRadius: 10,
          padding: 15,
          marginBottom: 15,
        },
        // pressed && pressedBorderStyle ? pressedBorderStyle : borderStyle,
      ]}
      onPress={onPress}
    >
      {({ pressed }) => (
        <>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              flexWrap: "wrap",
              marginBottom: 5,
            }}
          >
            {tags ? (
              tags.map((item, index) => (
                <View
                  key={`project-${index}`}
                  style={{
                    borderRadius: 5,
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <Text style={styles.tagText}>{item.name}</Text>
                </View>
              ))
            ) : (
              <></>
            )}
          </View>
          <View
            style={{
              flex: 1,
              borderBottomWidth: 1,
              borderBottomColor: "#606060",
            }}
          >
            <Text style={styles.title}>{title}</Text>
          </View>
          <View
            style={{
              flex: 1,
              zIndex: 1,
            }}
          >
            {partner ? (
              <Text style={styles.partner}>
                {user.userType == "CREATOR" || user.userType == "EMPLOYEE"
                  ? "Partner: "
                  : "Client: "}
                {partner} |{" "}
                {deadLine
                  ? Math.round(
                      (new Date(deadLine).getTime() - new Date().getTime()) /
                        (1000 * 3600 * 24)
                    )
                  : 0}{" "}
                Days to Deadline
              </Text>
            ) : (
              <></>
            )}
            {requiredMember ? (
              <Text style={styles.partner}>
                Required Member: {requiredMember.name}
              </Text>
            ) : (
              <></>
            )}
            <Text style={styles.projectId}>Project ID: {projectId}</Text>
            <View style={{ flexDirection: "row" }}>
              {budget ? (
                <View style={{ flex: 1 }}>
                  <Text
                    style={[
                      styles.budget,
                      { color: quotedAmount ? "#606060" : "#000000" },
                    ]}
                  >
                    Budget:
                  </Text>
                  <Text
                    style={[
                      styles.budgetAmount,
                      { color: quotedAmount ? "#606060" : "#000000" },
                    ]}
                  >
                    ₹{" "}
                    {budget
                      ? Array.isArray(budget)
                        ? (`${budget[0]}` || "")
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          `${
                            budget[1]
                              ? " to " +
                                (budget[1] || "")
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""
                          }`
                        : budget
                      : ""}
                  </Text>
                </View>
              ) : (
                <></>
              )}
              {quotedAmount ? (
                <View style={{ flex: 1 }}>
                  <Text style={styles.budget}>Quoted:</Text>
                  <Text style={styles.budgetAmount}>
                    ₹{" "}
                    {quotedAmount
                      ? quotedAmount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : quotedAmount}
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
            {duration ? (
              <Text style={styles.duration}>Time: {duration} days</Text>
            ) : (
              <></>
            )}

            {/* <TouchableOpacity
            style={{
              flex: 1,
              width: 30,
              height: 30,
              alignSelf: "flex-end",
              bottom: 0,
            }}
            onPress={onPress}
          >
            <Icon name="rightChevronArrowWithCircle"></Icon>
          </TouchableOpacity> */}
          </View>
        </>
      )}
    </Pressable>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    // minHeight: 220,
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
  },
  title: { fontWeight: "bold", fontSize: adjust(18), paddingBottom: 10 },
  partner: {
    fontSize: adjust(13),
    marginTop: 5,
    color: "#606060",
  },
  tagText: {
    color: "#ffffff",
    fontSize: adjust(9),
    textTransform: "uppercase",
    backgroundColor: "#FFD500",
    borderRadius: 5,
    padding: 5,
    paddingHorizontal: 10,
  },
  projectId: {
    fontSize: adjust(13),
    color: "#606060",
  },
  budget: {
    fontSize: adjust(20),
    fontWeight: "bold",
    marginTop: 10,
  },
  budgetAmount: {
    fontSize: adjust(20),
    fontWeight: "bold",
  },
  duration: {
    fontSize: adjust(13),
    marginTop: 5,
    color: "#606060",
  },
});
export default NewProjects;
