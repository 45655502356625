import { StyleSheet, Text, View, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import RoundButton from "../../components/roundButton";
import AllTransactionComponents from "../../components/earningComponents/allTransactionComponents";
import CreatorBidProfileComponent from "../../components/creatorBidProfileComponent/creatorBidProfileComponent";
import { fullName, primaryColor } from "../../components/utils";
import { connect } from "react-redux";
import {
  fetchClientFulfilledTransactions,
  fetchRaisedTransactions,
} from "../../redux/actions/payment.action";
import moment from "moment";

const Payments = ({
  navigation,
  user,
  raisedTransanctions,
  fetchRaisedTransactions,
  fetchClientFulfilledTransactions,
  transactions,
}) => {
  useEffect(() => {
    fetchRaisedTransactions();
    fetchClientFulfilledTransactions();
  }, []);
  const [activeTab, setActiveTab] = useState("transactions");
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          enableAvatar={true}
          title="payments"
          subtitle="All Your active payment raises"
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <View
        style={{
          backgroundColor: "#FFFFFF",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <RoundButton
          title={"transactions"}
          pressFunc={() => {
            setActiveTab("transactions");
          }}
          textStyle={{
            color: activeTab === "transactions" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor:
              activeTab === "transactions"
                ? primaryColor(user.userType)
                : "#FFF",
            // width: 100,
            borderWidth: 0.5,
            borderColor: "#000",
            marginBottom: 20,
            marginLeft: 20,
            alignSelf: "flex-end",
          }}
        ></RoundButton>
        <RoundButton
          title={"raised"}
          pressFunc={() => {
            setActiveTab("raised");
          }}
          textStyle={{
            color: activeTab === "raised" ? "#FFF" : "#000",
          }}
          style={{
            backgroundColor:
              activeTab === "raised" ? primaryColor(user.userType) : "#FFF",
            borderWidth: 0.5,
            borderColor: "#000",
            marginBottom: 20,
            marginLeft: 10,
            alignSelf: "flex-end",
          }}
        ></RoundButton>
      </View>
      <ScrollView style={{ marginHorizontal: 20 }}>
        {activeTab === "transactions"
          ? transactions.map((x) => (
              <AllTransactionComponents
                key={x._id + "client-fulfilled-transaction"}
                Colors={primaryColor(user.userType)}
                amount={x.amount}
                transactionTime={moment(
                  x.updatedDate,
                  "YYYY-MM-DDThh:mm:ss"
                ).format("DD/MM/YYYY hh:mm a")}
                transactionDetails={"client paid money"}
                projectName={x.project?.title}
                deducted
              />
            ))
          : raisedTransanctions.map((x) => (
              <CreatorBidProfileComponent
                key={x._id + "partner-raise"}
                name={fullName(x.receivable)}
                profile={`${x.receivable.userType} | Tap to View projects`}
                Quotation={"Payment Request"}
                amount={x.amount}
                checkBox={false}
                roundButton={true}
                buttontext={"Process to Payment"}
                buttonColor={primaryColor(user.userType)}
                onPress={() => navigation.navigate("Project")}
              />
            ))}
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user, raisedTransanctions, transactions }) => ({
  user,
  raisedTransanctions,
  transactions,
});
export default connect(mapStateToProps, {
  fetchRaisedTransactions,
  fetchClientFulfilledTransactions,
})(Payments);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
