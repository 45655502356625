import { StyleSheet, Text, View, ScrollView, FlatList } from "react-native";
import React, { useEffect } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import DashboardCardWithArrowAndIcon from "../../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import { primaryColor } from "../../../components/utils";
import { fetchDiscussions } from "../../../redux/actions/discussion.action";
import { unreadMessages } from "../../../redux/actions/chat.action";

const Discussions = ({
  user,
  navigation,
  fetchDiscussions,
  discussions,
  unreadMessages,
  unreadCount,
}) => {
  useEffect(() => {
    fetchDiscussions();
    unreadMessages();
  }, []);
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          disableBackButton={true}
          title="discussions"
          subtitle="All your discussions in one place"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <FlatList
        contentContainerStyle={{ paddingBottom: 30 }}
        style={{ marginHorizontal: 10 }}
        scrollEnabled
        nestedScrollEnabled
        keyExtractor={(item, index) => item._id}
        data={discussions}
        renderItem={({ item, index }) => (
          <DashboardCardWithArrowAndIcon
            value={""}
            onPressCard={() =>
              navigation.navigate("discussionGroups", {
                project: item.project._id,
              })
            }
            arrowColor={"#000"}
            borderWidth={4}
            borderColor={primaryColor(user.userType)}
            descriptionFontWeight={"bold"}
            descriptionFontSize={18}
            descriptionTextColor={primaryColor(user.userType)}
            description={`${
              unreadCount.find((x) => x._id === item.project._id)?.count || 0
            } new messages`}
            subDetails={`Client: ${item.client.fName} ${item.client.lName}`}
            titleColor="#000"
            backgroundColor="#FFF"
            title={item.project.title}
            icon={false}
          ></DashboardCardWithArrowAndIcon>
        )}
      />
    </View>
  );
};
const mapStateToProps = ({ user, discussions, unreadCount }) => ({
  user,
  discussions,
  unreadCount,
});
export default connect(mapStateToProps, { fetchDiscussions, unreadMessages })(
  Discussions
);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: "18%",
    maxHeight: "18%",
  },
});
