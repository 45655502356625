const initialState = {};
const selectedRequirement = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_SELECTED_REQUIREMENT": {
      return {};
    }
    case "SET_SELECTED_REQUIREMENT": {
      return action.payload.data;
    }
    case "LOGOUT": {
      return {};
    }
    default: {
      return state;
    }
  }
};
export default selectedRequirement;
