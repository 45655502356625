import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import React from "react";
import Icon from "../icon";

const DashboardCardWithArrowAndIcon = ({
  backgroundColor = "#FFB100",
  value,
  valueStyle,
  title = "Project discussions",
  description = "Tap to check project discussions",
  icon = true,
  onPressCard,
  source,
  descriptionTextColor = "#FFF",
  titleColor = "#FFF",
  projectDetails,
  subDetails,
  disableArrow,
  arrowColor,
  descriptionFontSize = 13,
  descriptionFontWeight,
  borderWidth,
  borderColor,
  style = {},
  subDetailsTextColor,
  iconStyle,
  titleContainerStyle,
  subtitleStyle,
  descriptionContainerStyle,
  disabled = false,
}) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPressCard}
      style={[
        styles.container,
        {
          backgroundColor: backgroundColor,
          borderWidth: borderWidth,
          borderColor: borderColor,
        },
        style,
      ]}
    >
      <View
        style={[
          titleContainerStyle,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            maxHeight: 50,
          },
        ]}
      >
        {title ? (
          <Text style={[styles.textStyle, { color: titleColor }]}>{title}</Text>
        ) : (
          <></>
        )}
        {projectDetails ? (
          <Text style={styles.projectDetails}>{projectDetails}</Text>
        ) : (
          <></>
        )}
        {icon ? (
          <Image
            style={[iconStyle, { height: 70, width: 80, bottom: 15 }]}
            source={source ? source : require("../../assets/bell-icon.png")}
          ></Image>
        ) : (
          <></>
        )}
      </View>
      {value ? (
        <Text style={[styles.valueStyle, valueStyle]}>{value}</Text>
      ) : (
        <></>
      )}
      {subDetails ? (
        <Text
          style={[
            styles.descriptionStyle,
            subtitleStyle,
            { color: subDetailsTextColor },
          ]}
        >
          {subDetails}
        </Text>
      ) : (
        <></>
      )}

      <View
        style={[
          {
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: description && value === "" && icon === false ? 15 : 0,
          },
          descriptionContainerStyle,
        ]}
      >
        {description ? (
          <Text
            style={[
              styles.descriptionStyle,
              {
                color: descriptionTextColor,
                fontSize: descriptionFontSize,
                fontWeight: descriptionFontWeight,
              },
            ]}
          >
            {description}
          </Text>
        ) : (
          <></>
        )}
        {disableArrow ? (
          <></>
        ) : (
          <Icon
            width={70}
            fill={arrowColor ? arrowColor : "#FFF"}
            name="rightArrow"
            style={{
              flex: 2,
              top: 5,
              left: 10,
            }}
          ></Icon>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default DashboardCardWithArrowAndIcon;

const styles = StyleSheet.create({
  textStyle: {
    fontSize: 16,
    fontWeight: "bold",
    maxWidth: "85%",
  },
  container: {
    padding: 15,
    borderRadius: 8,
    marginTop: 10,
    minHeight: 99,
  },
  valueStyle: {
    fontSize: 40,
    fontWeight: "bold",
    color: "#FFF",
  },
  descriptionStyle: {
    maxWidth: "70%",
    marginTop: 10,
  },
  projectDetails: {
    fontSize: 13,
    color: "#000",
  },
});
