import { FlatList, StyleSheet, Text, View } from "react-native";
import React from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import DashboardCardWithArrowAndIcon from "../../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";

const SelectCreator = ({ navigation, selectedProject }) => {
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          title="select creators"
          subtitle="select creators for your project"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <FlatList
        style={{
          marginHorizontal: 10,
        }}
        scrollEnabled
        nestedScrollEnabled
        data={selectedProject.requiredTeam?.members || []}
        keyExtractor={(item, index) => item._id + "creator-bid" + index}
        renderItem={({ item, index }) => (
          <DashboardCardWithArrowAndIcon
            onPressCard={() =>
              navigation.navigate("creatorProfileDuringBid", {
                memberId: item._id,
              })
            }
            value={`${
              item.quotations.filter((x) => !x.accepted).length || 0
            } new`}
            source={require("../../../assets/chat-bubble.png")}
            title={item.type.name}
            description="Tap to check all quotations"
            backgroundColor="#FFB100"
          ></DashboardCardWithArrowAndIcon>
        )}
      />
    </View>
  );
};
const mapStateToProps = ({ selectedProject }) => ({
  selectedProject,
});
export default connect(mapStateToProps)(SelectCreator);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
});
