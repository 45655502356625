import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, TextInput, Platform } from "react-native";
import adjust from "../../components/adjust";
export default function TextInputWithTitle({
  title,
  placeholder,
  data,
  onDataChange,
  textType,
  selectionValue,
  isValid,
  validationType,
  validateNow,
  required = false,
  keyboardType,
  multiline = true,
  numberOfLines,
  secureTextEntry,
  textInputStyle,
  editable,
  onFocus,
  titleStyle,
}) {
  let validationReg;
  const [valid, setValid] = useState(true);
  const [FocusBorderColor, setFocusBorderColor] = useState("#000");
  useEffect(() => {
    if (validateNow) {
      if (textType) {
        validate(data);
      } else {
        validate(selectionValue);
      }
    }
  }, [validateNow]);

  function validate(v) {
    let val = valid;
    if (required && v) {
      if (validationReg) {
        val = validationReg.test(v);
      } else {
        val = true;
      }
    } else if (v) {
      if (validationReg) {
        val = validationReg.test(v);
      }
    } else {
      val = false;
    }
    setValid(val);
    if (val) {
      setFocusBorderColor("#41B000");
    } else {
      setFocusBorderColor("#F33A3A");
    }
    if (isValid) {
      isValid(val);
    }
  }
  useEffect(() => {
    validationReg =
      validationType === "number"
        ? /^(\d+\.\d+)$|^(\d+)$/i
        : validationType === "email"
        ? /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i
        : validationType === "phone"
        ? /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/i
        : validationType === "aadhar"
        ? /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i
        : validationType === "pan"
        ? /^[A-Z]{5}[0-9]{4}[A-Z]{1}/i
        : validationType === "pin"
        ? /^[1-9][0-9]{5}$/i
        : validationType === "numeric"
        ? /^(\d+\.\d+)$|^(\d+)$/i
        : null;
  });
  const [inputData, setInputData] = useState(data ? data : "");

  const setData = (e) => {
    validate(e);
    onDataChange(e);
    setInputData(e);
  };

  useEffect(() => {
    setInputData(data);
  }, [data]);

  return (
    <View style={[styles.textContainer]}>
      <Text style={[styles.titleText, titleStyle]}>{title}</Text>

      <TextInput
        secureTextEntry={secureTextEntry}
        placeholder={placeholder}
        placeholderTextColor="#9C9C9C"
        multiline={multiline}
        numberOfLines={numberOfLines}
        style={[
          styles.textInput,
          textInputStyle,
          { borderBottomColor: FocusBorderColor },
          numberOfLines ? { minHeight: numberOfLines * 13 } : {},
        ]}
        onFocus={() => {
          setFocusBorderColor("#FFD500");
          if (onFocus && typeof onFocus === "function") {
            onFocus();
          }
          setFocusBorderColor("#FFD500");
        }}
        onBlur={() => {
          setFocusBorderColor(data ? (valid ? "#41B000" : "#F33A3A") : "#000");
        }}
        onChangeText={(e) => setData(e)}
        value={`${inputData}`}
        keyboardType={keyboardType}
        editable={editable}
      ></TextInput>
    </View>
  );
}

const styles = StyleSheet.create({
  selectTextStyle: {
    fontSize: adjust(13),
    alignSelf: "center",
    marginBottom: 0,
  },

  titleText: {
    fontSize: adjust(20),
    fontWeight: "bold",
    color: "#000",
  },
  textContainer: {
    minHeight: 100,
    maxHeight: 100,
    padding: 15,
    marginBottom: 20,
  },
  textInput: {
    fontSize: adjust(13),
    height: 40,
    marginTop: 10,
    borderBottomWidth: 1,
    right: Platform.OS === "android" ? 3 : 0,
    // right: 3,
  },
  docInput: {
    fontSize: adjust(13),
    height: 25,
    marginTop: 25,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
});
