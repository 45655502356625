export const fetchProjectDomain = (projectObj) => (dispatch) => {
  return dispatch({
    type: "FETCH_PROJECT_DOMAIN",
    payload: {
      request: {
        url: `/api/project-domain`,
        method: "GET",
      },
    },
  });
};


export const createProjectDomain = (domainObj) => (dispatch) => {
  return dispatch({
    type: "CREATE_PROJECT_DOMAIN",
    payload: {
      request: {
        url: `/api/project-domain`,
        method: "POST",
        data: domainObj,
      },
    },
  });
};

export const removeProjectDomain = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_PROJECT_DOMAIN",
    payload: {
      request: {
        url: `/api/project-domain/${id}`,
        method: "DELETE",
      },
    },
  });
};
