import { StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";
import React from "react";
import Icon from "../icon";

const UserImageUpload = ({ profilePic, onPress }) => {
  return (
    <View
      style={{
        flex: 8,
      }}
    >
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 25,
            marginTop: 30,
            color: "#000",
            marginHorizontal: 30,
          }}
        >
          we need a good smiling photograph
        </Text>
        {profilePic ? (
          <TouchableOpacity onPress={onPress}>
            <Image
              source={{
                uri:
                  Platform.OS === "web"
                    ? profilePic
                    : "data:image/jpeg;base64," + profilePic,
              }}
              style={{
                width: 180,
                height: 180,
                borderRadius: 100,
                backgroundColor: "#B4B4B4",
                marginTop: 50,
                marginLeft: 10,
                justifyContent: "center",
                alignItems: "center",
                marginRight: 30,
              }}
            ></Image>
          </TouchableOpacity>
        ) : (
          <View
            style={{
              width: 200,
              height: 200,
              borderRadius: 100,
              backgroundColor: "#B4B4B4",
              marginTop: 50,
              justifyContent: "center",
              alignItems: "center",
              marginRight: 30,
            }}
          >
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
              onPress={onPress}
            >
              <Icon
                height={100}
                width={100}
                name="plus"
                style={{ flex: 1 }}
              ></Icon>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

export default UserImageUpload;

const styles = StyleSheet.create({});
