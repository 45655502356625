import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  Linking,
} from "react-native";
import React from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import WelcomeMessageComponent from "../../components/welcome_message_compoment/welcomeMessageComponent";
import DashboardCard from "../../components/dashboardCard";
import ItemWithCountValue from "../../components/itemWithCountValue/itemWithCountValue";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import { useFocusEffect } from "@react-navigation/native";
import { fetchDashboardStats } from "../../redux/actions/dashboard.action";
import moment from "moment";
import { Video } from "expo-av";
import { fetchCaseStudy } from "../../redux/actions/case-study.action";
import { abbrNum } from "../../components/utils";
import { fetchWelcomeMessage } from "../../redux/actions/welcome-message.action";
const DashBoard = ({
  user,
  navigation,
  dashboardStats,
  fetchDashboardStats,
  fetchCaseStudy,
  caseStudy,
  fetchWelcomeMessage,
  welcomeMessages,
}) => {
  useFocusEffect(
    React.useCallback(() => {
      fetchDashboardStats();
      fetchCaseStudy();
      fetchWelcomeMessage("CREATOR");
    }, [])
  );
  const dimension = useWindowDimensions();
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
          // backgroundColor: "red",
        }}
      >
        <Header
          title={`Welcome, ${user.fName}`}
          subtitle={"Let’s create greatness today!"}
          disableBackButton="true"
          style={{ backgroundColor: null, marginTop: 20 }}
          enableAvatar={true}
          navigation={navigation}
        ></Header>
      </View>
      {!["DASHBOARD", "ACCEPTED"].includes(user.status) ? (
        <View>
          <WelcomeMessageComponent
            textColor={"#FFF"}
            backgroundColor="#EB4141"
            message={
              "complete your profile and get verified to start recieving jobs."
            }
            link={"Tap here to do it"}
            onPress={() => navigation.navigate("admin-verification")}
          ></WelcomeMessageComponent>
        </View>
      ) : (
        <></>
      )}
      {welcomeMessages.length ? (
        <ScrollView
          nestedScrollEnabled={true}
          style={{
            maxHeight: 80,
          }} // due to scrollview issue in mobile i am giving fix height
          horizontal={true}
        >
          {welcomeMessages.map((x) => (
            <WelcomeMessageComponent
              key={x._id}
              textColor={"#FFF"}
              backgroundColor="#073257"
              message={x.message}
              image={x.image}
            ></WelcomeMessageComponent>
          ))}
        </ScrollView>
      ) : (
        <></>
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          flex: 1,
          height: "100%",
          marginHorizontal: 5,
        }}
      >
        <DashboardCard
          jsx={
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("Project", { screen: "myProjects" })
              }
            >
              <Text style={styles.cardTitleStyle}>
                {`Project metrics in ${moment().format("MMMM")}`}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <ItemWithCountValue
                  style={{ marginLeft: 15 }}
                  textColor={"#FFF"}
                  countValue={`${dashboardStats.newRequirement || 0}`}
                  itemName={"fresh"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  textColor={"#FFF"}
                  countValue={`${dashboardStats.activeProject || 0}`}
                  itemName={"ongoing"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  style={{ right: 20 }}
                  textColor={"#FFF"}
                  countValue={`${dashboardStats.closedProject || 0}`}
                  itemName={"completed"}
                ></ItemWithCountValue>
              </View>
            </TouchableOpacity>
          }
          disableIcon={true}
          icon={false}
          style={{
            maxHeight: 140,
            minHeight: 140,
            backgroundColor: "#2F47FF",
          }}
        ></DashboardCard>
        <DashboardCardWithArrowAndIcon
          source={require("../../assets/payment.png")}
          onPressCard={
            () => navigation.navigate("payment-details")
            // navigation.navigate("Project", { screen: "projectPayment" })
          }
          description="in your credit shell"
          value={abbrNum(dashboardStats.clearingAmount, 2) || "0"}
          title="Payments (in INR)"
          backgroundColor="#3C581D"
          style={{ marginHorizontal: 10 }}
        ></DashboardCardWithArrowAndIcon>
        <Text style={styles.textStyle}>
          see what creators like you have created
        </Text>
        <ScrollView
          contentContainerStyle={{ paddingBottom: 50 }}
          style={{ width: "100%" }}
          horizontal={true}
          nestedScrollEnabled={true}
          showsHorizontalScrollIndicator={false}
        >
          {caseStudy?.map((e) => (
            <View
              key={e._id}
              style={[
                styles.videoCardStyle,
                {
                  width:
                    caseStudy?.length > 1
                      ? dimension.width - 70
                      : dimension.width - 30,
                },
              ]}
            >
              <TouchableOpacity
                style={{ height: "75%", width: "100%" }}
                onPress={() => Linking.openURL(e.url)}
              >
                <Video
                  style={[styles.community]}
                  source={{
                    uri: e.url,
                  }}
                  useNativeControls={true}
                  resizeMode="cover"
                  isLooping={false}
                  shouldPlay={false}
                  isMuted
                />
              </TouchableOpacity>
              <Text style={styles.description}>{e.description}</Text>
            </View>
          ))}
        </ScrollView>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({
  user,
  dashboardStats,
  caseStudy,
  welcomeMessages,
}) => ({
  user,
  dashboardStats,
  caseStudy,
  welcomeMessages,
});
export default connect(mapStateToProps, {
  fetchDashboardStats,
  fetchCaseStudy,
  fetchWelcomeMessage,
})(DashBoard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  cardTitleStyle: {
    alignSelf: "flex-start",
    margin: 15,
    fontSize: 16,
    color: "#FFF",
    fontWeight: "bold",
  },
  community: {
    flex: 1,
    minHeight: 130,
    maxHeight: 200,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    width: "100%",
  },
  videoCardStyle: {
    height: 170,
    borderRadius: 7,
    marginHorizontal: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#707070",
  },
  description: {
    fontSize: 15,
    color: "#000",
    fontWeight: "bold",
    bottom: 10,
    position: "absolute",
    marginHorizontal: 10,
  },
  textStyle: {
    marginHorizontal: 15,
    marginVertical: 10,
    color: "#828282",
    fontWeight: "bold",
    fontSize: 16,
  },
});
