import { StyleSheet, Text, View, ScrollView, FlatList } from "react-native";
import React from "react";
import Styles from "../../components/SwipeButton/SwipeButton/styles";
import Header from "../../components/header";
import AllProjectBudgetComponenet from "../../components/budget /allProjectBudgetComponenet";
import { connect } from "react-redux";
import { setSelectedProject } from "../../redux/actions/project.action";

const CreatorAllProjectBudgetScreen = ({
  navigation,
  requirements,
  setSelectedProject,
}) => {
  const setAndNavigate = (item) => {
    setSelectedProject(item);
    navigation.navigate("creatorBudgetScreen");
  };
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new Projects"
          subtitle="Daily Fresh gigs for You"
          enableAvatar={true}
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#000000" }}
        ></Header>
      </View>
      {requirements?.length > 0 ? (
        <FlatList
          style={styles.card_Container}
          scrollEnabled
          nestedScrollEnabled
          data={requirements}
          keyExtractor={(item, index) => item._id + "creator" + index}
          renderItem={({ item, index }) => (
            <AllProjectBudgetComponenet
              title={`Need ${item.requiredTeam.type.name} for`}
              timeline={Math.round(
                Math.abs((new Date(item.deadLine) - new Date()) / 86400000)
              )}
              rupees={item.requiredTeam.budget}
              about={item.title}
              onPress={() => setAndNavigate(item)}
            ></AllProjectBudgetComponenet>
          )}
        />
      ) : (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Text
            style={{ alignSelf: "center", fontSize: 35, fontWeight: "bold" }}
          >
            No new projects
          </Text>
        </View>
      )}
    </View>
  );
};
const mapStateToProps = ({ requirements }) => ({
  requirements,
});
export default connect(mapStateToProps, { setSelectedProject })(
  CreatorAllProjectBudgetScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
