import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CreatorProfile from "../../partner/creator-profile";

const CreatorProfileScreen = ({ creatorList, navigation, route }) => {
  const [creator, setCreator] = useState({});
  const [editCreator, setEditCreator] = useState(false);
  const [disableLogout, setDisableLogout] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    if (route.params) {
      if (route.params.creatorId) {
        setCreator(creatorList.find((x) => x._id === route.params.creatorId));
      }
      if (route.params.editCreator) {
        setEditCreator(true);
      }
      if (route.params.disableLogout) {
        setDisableLogout(true);
      }
      if (route.params.currentUser) {
        setCurrentUser(route.params.currentUser);
      }
    }
  }, [route.params, creatorList]);
  return (
    creator && (
      <CreatorProfile
        currentUser={currentUser}
        editCreator={editCreator}
        disableLogout={disableLogout}
        navigation={navigation}
        user={creator}
      />
    )
  );
};

const mapStateToProps = ({ creatorList }) => ({
  creatorList,
});
export default connect(mapStateToProps, {})(CreatorProfileScreen);
