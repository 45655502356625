import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import Header from "../../components/header";
import adjust from "../../components/adjust";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import { connect } from "react-redux";
import { fullName } from "../../components/utils";
import { createProject } from "../../redux/actions/project.action";
const CloseProject = ({ navigation, selectedProject, createProject }) => {
  const closeProject = () => {
    createProject(
      { _id: selectedProject._id, closed: true },
      navigateOnSuccess
    );
  };
  const navigateOnSuccess = () => navigation.goBack();
  return (
    <SafeAreaView style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="end project"
          subtitle="close the project"
          backFunction={() => navigation.goBack()}
          enableAvatar={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ paddingHorizontal: 10 }}>
        <View
          style={[styles.projectTitleContainer, { backgroundColor: "#000" }]}
        >
          <Text style={styles.mainHeader}>{selectedProject.title}</Text>
          <Text
            style={{ fontSize: 11, color: "#FFF", margin: 10 }}
          >{`Partner: ${fullName(selectedProject.partner)}`}</Text>
        </View>
      </View>
      {selectedProject.paymentPhases.find((x) =>
        x.amount.find(
          (y) => y.type !== "Final" && y.payment.status != "Received"
        )
      ) && (
        <View
          style={{
            margin: 10,
            backgroundColor: "#FF0000",
            borderColor: "#000",
            borderRadius: 7,
          }}
        >
          <Text style={{ color: "#FFF", padding: 13, fontSize: 13 }}>
            You cannot end the project right now as the scope/payment is not
            complete. Please contact your assigned Partner for more info.
          </Text>
        </View>
      )}
      <View
        style={{
          flex: 1,
          flexDirection: "column-reverse",
          justifyContent: "flex-start",
          margin: 10,
        }}
      >
        <DashboardCardWithArrowAndIcon
          title="Close Project"
          value=""
          icon={false}
          backgroundColor={
            selectedProject.paymentPhases.find((x) =>
              x.amount.find(
                (y) => y.type !== "Final" && y.payment.status != "Received"
              )
            )
              ? "#D3D3D3"
              : "#000"
          }
          onPressCard={() => {
            if (
              selectedProject.paymentPhases.find((x) =>
                x.amount.find(
                  (y) => y.type !== "Final" && y.payment.status != "Received"
                )
              )
            ) {
            } else {
              closeProject();
            }
          }}
        ></DashboardCardWithArrowAndIcon>
        <DashboardCardWithArrowAndIcon
          title="Project Feedback"
          description="Tap here to give feedbacks to the project"
          value=""
          icon={false}
          backgroundColor="#BA448D"
          onPressCard={() => {
            navigation.navigate("reviewFeedback");
          }}
        ></DashboardCardWithArrowAndIcon>
      </View>
    </SafeAreaView>
  );
};

const mapStateToProps = ({ selectedProject }) => ({ selectedProject });
export default connect(mapStateToProps, { createProject })(CloseProject);

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  titleText: {
    padding: 8,
    fontSize: adjust(16),
    fontWeight: "bold",
  },
  textStyle: {
    fontSize: adjust(23),
    color: "#000",
    marginTop: 15,
    marginLeft: 5,
  },

  buttonStyle: {
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 30,
    padding: 15,
    backgroundColor: "#000",
    borderRadius: 30,
  },
  buttonTextStyle: {
    color: "#fff",
    textAlign: "center",
  },

  projectTitleContainer: {
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  mainHeader: {
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#ffffff",
    margin: 10,
  },
});
