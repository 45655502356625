import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Pressable,
  useWindowDimensions,
} from "react-native";
import Checkbox from "./checkbox";
import { connect } from "react-redux";
import {
  updatePaymentStatus,
  requestCustomPayment,
} from "../redux/actions/project.action";
import { requestCreatorCustomPayment } from "../redux/actions/creator.action";
import {
  Directions,
  FlingGestureHandler,
  State,
} from "react-native-gesture-handler";
import LargeTextInput from "./largeTextInput";
import adjust from "./adjust";
import { Swipeable } from "./swipeable";
import SwipeSuccess from "./swipeable/swipeSuccess";
import { currenyFormat } from "./utils";
import Icon from "./icon";

const BudgetPhase = ({
  phase,
  phaseNumber,
  teamMember,
  user,
  selectedProject,
  updatePaymentStatus,
  requestCustomPayment,
  requestCreatorCustomPayment,
  payment,
  sales,
}) => {
  const window = useWindowDimensions();
  const [customPaymentMode, setCustomPaymentMode] = useState(false);
  const [customAmount, setCustomAmount] = useState("");
  const [customAmountType, setCustomAmountType] = useState("");
  const [leftActionActivated, setLeftActionActivated] = useState(false);
  const [rightActionActivated, setRightActionActivated] = useState(false);
  const [swipeBackgroundColor, setSwipeBackgroundColor] = useState("#ffffff");
  const [swipeActivatedBackgroundColor, setSwipeActivatedBackgroundColor] =
    useState("#ffffff");
  const updateNextStatus = (payment) => {
    let obj = {
      _id: payment._id,
      swipe: "right",
    };
    if (
      (user._id === payment.receivable && payment.status === "Pending") ||
      (user._id === payment.payable && payment.status === "Requested")
    )
      updatePaymentStatus(obj);
  };

  const updateStepStatus = (id) => {
    let obj = {
      _id: id,
      swipe: "right",
    };
    updatePaymentStatus(obj);
  };

  const createCustomPayment = () => {
    let obj = {
      _id: selectedProject._id,
      phaseId: phase._id,
      amount: customAmount,
      type: "ADD-ON",
      description: customAmountType,
    };
    requestCustomPayment(obj);
    setCustomPaymentMode(!customPaymentMode);
  };

  const createCreatorCustomPayment = () => {
    let obj = {
      _id: selectedProject._id,
      amount: customAmount,
      type: "ADD-ON",
      description: customAmountType,
    };
    requestCreatorCustomPayment(obj);
    setCustomPaymentMode(!customPaymentMode);
  };

  return (
    <View style={styles.container}>
      {sales ? (
        <Swipeable
          leftActionActivationDistance={180}
          allowSwipeGesture={payment?.status == "Received" ? false : true}
          leftContent={
            leftActionActivated ? (
              <SwipeSuccess
                color={
                  leftActionActivated
                    ? swipeActivatedBackgroundColor
                    : swipeBackgroundColor
                }
                message={
                  payment?.status == "Requested"
                    ? "Payment already requested!"
                    : "Payment Requested!"
                }
                style={{ marginTop: 10 }}
              ></SwipeSuccess>
            ) : (
              <></>
            )
          }
          onSwipeStart={() =>
            setSwipeBackgroundColor(
              payment?.status == "Requested"
                ? "#FFC400"
                : payment?.status == "Received"
                ? "#7A9D54"
                : "#B7B7B7"
            )
          }
          onSwipeRelease={() => setSwipeBackgroundColor("#ffffff")}
          onLeftActionActivate={() => {
            setLeftActionActivated(true);
            setSwipeActivatedBackgroundColor(
              payment?.status == "Requested"
                ? "#FFD500"
                : payment?.status == "Received"
                ? "#7A9D54"
                : "#B7B7B7"
            );
            updateNextStatus(payment);
          }}
          resetOnFinish={true}
          onLeftActionDeactivate={() => setLeftActionActivated(false)}
        >
          <View style={[styles.phaseContainer, { width: window.width - 80 }]}>
            <View
              style={[
                styles.statusContainer,
                {
                  backgroundColor:
                    payment?.status == "Requested"
                      ? "#FFC400"
                      : payment?.status == "Received"
                      ? "#7A9D54"
                      : "#B7B7B7",
                  borderColor: "#FF973E",
                },
              ]}
            >
              <View>
                <Text
                  style={[
                    styles.advanceText,
                    {
                      color:
                        payment?.status == "Pending" ? "#000000" : "#ffffff",
                    },
                  ]}
                >
                  {currenyFormat(payment?.amount)}
                </Text>
                <Text
                  style={{
                    color: payment?.status == "Pending" ? "#000000" : "#ffffff",
                  }}
                >
                  {payment?.status}
                </Text>
              </View>

              <View
                style={[
                  styles.checkboxContainer,
                  {
                    backgroundColor: "#F7F7F7",
                    justifyContent: "center",
                  },
                ]}
              >
                {payment?.status == "Received" ? (
                  <Icon
                    name="tickMark"
                    style={{ alignSelf: "center" }}
                    width={17}
                    height={17}
                  ></Icon>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
        </Swipeable>
      ) : teamMember ? (
        phase
          .filter((p) => p.type != "FINAL")
          .map((a, index) => {
            return (
              <Swipeable
                key={index}
                leftActionActivationDistance={180}
                allowSwipeGesture={
                  (user.userType == "PARTNER" &&
                    a.payment?.status == "Requested") ||
                  (user.userType == "CREATOR" && a.payment?.status == "Pending")
                    ? true
                    : false
                }
                leftContent={
                  leftActionActivated ? (
                    <SwipeSuccess
                      color={
                        leftActionActivated
                          ? swipeActivatedBackgroundColor
                          : swipeBackgroundColor
                      }
                      message={
                        user.userType == "PARTNER"
                          ? "Payment Request Fulfilled"
                          : user.userType == "CREATOR"
                          ? "Payment Requested!"
                          : " "
                      }
                      style={{ marginTop: 10 }}
                    ></SwipeSuccess>
                  ) : (
                    <></>
                  )
                }
                onSwipeStart={() =>
                  setSwipeBackgroundColor(
                    a.payment?.status == "Requested"
                      ? "#FFC400"
                      : a.payment?.status == "Received"
                      ? "#7A9D54"
                      : "#B7B7B7"
                  )
                }
                onSwipeRelease={() => setSwipeBackgroundColor("#ffffff")}
                onLeftActionActivate={() => {
                  setLeftActionActivated(true);
                  setSwipeActivatedBackgroundColor(
                    a.payment?.status == "Requested"
                      ? "#FFc400"
                      : a.payment?.status == "Received"
                      ? "#7A9D54"
                      : "#B7B7B7"
                  );
                  updateNextStatus(a.payment);
                }}
                resetOnFinish={true}
                onLeftActionDeactivate={() => setLeftActionActivated(false)}
              >
                {/* <View style={{ flex: 1 }}> */}
                <View
                  style={[styles.phaseContainer, { width: window.width - 80 }]}
                >
                  <View
                    style={[
                      styles.statusContainer,
                      {
                        backgroundColor:
                          a.payment?.status == "Requested"
                            ? "#FFC400"
                            : a.payment?.status == "Received"
                            ? "#7A9D54"
                            : "#F7F7F7",
                        borderColor: "#FF973E",
                      },
                    ]}
                  >
                    <View>
                      <Text
                        style={[
                          styles.advanceText,
                          {
                            color:
                              a.payment?.status == "Pending"
                                ? "#000000"
                                : "#ffffff",
                          },
                        ]}
                      >
                        {a.description || a.type}:
                        {currenyFormat(a.payment.amount)}
                      </Text>
                      <Text
                        style={{
                          color:
                            a.payment?.status == "Pending"
                              ? "#000000"
                              : "#ffffff",
                        }}
                      >
                        {a.payment?.status == "Requested" &&
                        user.userType == "PARTNER"
                          ? a.payment?.status + " - Swipe to pay"
                          : a.payment?.status}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.checkboxContainer,
                        {
                          backgroundColor: "#F7F7F7",
                        },
                      ]}
                    >
                      {a.payment?.status == "Received" ? (
                        <Icon
                          name="tickMark"
                          style={styles.icon}
                          width={15}
                        ></Icon>
                      ) : (
                        <></>
                      )}
                    </View>
                  </View>
                </View>
                {/* </View> */}
              </Swipeable>
            );
          })
      ) : (
        phase?.amount
          .filter((p) => p.type != "Final" && p.payment?.amount)
          .map((a, index) => {
            return (
              <Swipeable
                key={index}
                leftActionActivationDistance={180}
                allowSwipeGesture={
                  a.payment?.status == "Received" ? false : true
                }
                leftContent={
                  leftActionActivated ? (
                    <SwipeSuccess
                      color={
                        leftActionActivated
                          ? swipeActivatedBackgroundColor
                          : swipeBackgroundColor
                      }
                      message={
                        a.payment?.status == "Requested"
                          ? "Payment already requested!"
                          : "Payment Requested!"
                      }
                      style={{ marginTop: 10 }}
                    ></SwipeSuccess>
                  ) : (
                    <></>
                  )
                }
                onSwipeStart={() =>
                  setSwipeBackgroundColor(
                    a.payment?.status == "Requested"
                      ? "#FFC400"
                      : a.payment?.status == "Received"
                      ? "#7A9D54"
                      : "#B7B7B7"
                  )
                }
                onSwipeRelease={() => setSwipeBackgroundColor("#ffffff")}
                onLeftActionActivate={() => {
                  setLeftActionActivated(true);
                  setSwipeActivatedBackgroundColor(
                    a.payment?.status == "Requested"
                      ? "#FFD500"
                      : a.payment?.status == "Received"
                      ? "#7A9D54"
                      : "#B7B7B7"
                  );
                  updateNextStatus(a.payment);
                }}
                resetOnFinish={true}
                onLeftActionDeactivate={() => setLeftActionActivated(false)}
              >
                <View
                  style={[styles.phaseContainer, { width: window.width - 80 }]}
                >
                  <View
                    style={[
                      styles.statusContainer,
                      {
                        backgroundColor:
                          a.payment?.status == "Requested"
                            ? "#FFC400"
                            : a.payment?.status == "Received"
                            ? "#7A9D54"
                            : "#B7B7B7",
                        borderColor: "#FF973E",
                      },
                    ]}
                  >
                    <View>
                      <Text
                        style={[
                          styles.advanceText,
                          {
                            color:
                              a.payment?.status == "Pending"
                                ? "#000000"
                                : "#ffffff",
                          },
                        ]}
                      >
                        {a.description || a.type}:{" "}
                        {currenyFormat(a.payment?.amount)}
                      </Text>
                      <Text
                        style={{
                          color:
                            a.payment?.status == "Pending"
                              ? "#000000"
                              : "#ffffff",
                        }}
                      >
                        {a.payment?.status}
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.checkboxContainer,
                        {
                          backgroundColor: "#F7F7F7",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      {a.payment?.status == "Received" ? (
                        <Icon
                          name="tickMark"
                          style={{ alignSelf: "center" }}
                          width={17}
                          height={17}
                        ></Icon>
                      ) : (
                        <></>
                      )}
                    </View>
                  </View>
                </View>
              </Swipeable>
            );
          })
      )}
      {(teamMember && user.userType == "CREATOR") ||
      (!teamMember && user.userType == "PARTNER") ? (
        <Pressable
          disabled={selectedProject.closed}
          style={({ pressed }) => [
            styles.phaseContainer,
            {
              borderRadius: 8,
              maxHeight: 70,
              minHeight: 70,
              backgroundColor: selectedProject.closed
                ? "#D3D3D3"
                : pressed
                ? "#FFD500"
                : "#000",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "center",
            },
          ]}
          onPress={() => setCustomPaymentMode(!customPaymentMode)}
        >
          {({ pressed }) => (
            <>
              <Text
                style={[
                  {
                    color: "#FFFFFF",
                    fontSize: 17,
                    fontWeight: "bold",
                    textAlignVertical: "center",
                    alignSelf: "center",
                  },
                ]}
              >
                Request Add-on Payment
              </Text>
            </>
          )}
        </Pressable>
      ) : (
        <></>
      )}
      {customPaymentMode ? (
        <View
          style={{
            flex: 1,
            borderColor: "#DCDCDC",
            borderWidth: 1,
            borderRadius: 8,
            padding: 15,
            marginTop: 10,
            minHeight: 205,
          }}
        >
          <LargeTextInput
            placeholder="Enter payment type"
            placeholderColor="#D4D4D4"
            onChangeText={(e) => setCustomAmountType(e)}
            style={{
              flex: 1,
              backgroundColor: "#FFF",
              width: "100%",
              maxWidth: "100%",
              maxHeight: 50,
              minHeight: 50,
              borderRadius: 8,
              fontSize: adjust(20),
              marginLeft: 0,
              marginTop: 0,
              paddingLeft: 10,
              borderColor: "#000",
              borderWidth: 1,
            }}
            containerStyle={{ justifyContent: "flex-start" }}
          ></LargeTextInput>
          <LargeTextInput
            placeholder="Enter amount"
            placeholderColor="#D4D4D4"
            onChangeText={(e) => setCustomAmount(e)}
            keyboardType="numeric"
            style={{
              flex: 1,
              backgroundColor: "#FFF",
              width: "100%",
              maxWidth: "100%",
              maxHeight: 50,
              minHeight: 50,
              borderRadius: 8,
              fontSize: adjust(20),
              marginLeft: 0,
              marginTop: 20,
              paddingLeft: 10,
              borderColor: "#000",
              borderWidth: 1,
              marginBottom: 20,
            }}
            containerStyle={{ justifyContent: "flex-start" }}
          ></LargeTextInput>
          <TouchableOpacity
            onPress={
              user.userType == "CREATOR"
                ? () => createCreatorCustomPayment()
                : () => createCustomPayment()
            }
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFD500",
              borderRadius: 8,
              minHeight: 55,
              maxHeight: 55,
              padding: 15,
              marginTop: 20,
            }}
          >
            <Text
              style={{
                flex: 1,
                fontSize: adjust(20),
                fontWeight: "bold",
                color: "#ffffff",
                alignSelf: "center",
              }}
            >
              Request
            </Text>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const mapStateToProps = ({ selectedProject, user }) => ({
  selectedProject,
  user,
});
export default connect(mapStateToProps, {
  updatePaymentStatus,
  requestCustomPayment,
  requestCreatorCustomPayment,
})(BudgetPhase);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
  },
  phaseText: {
    flex: 1,
    fontWeight: "bold",
    color: "#A9A9A9",
    fontSize: adjust(18),
    marginBottom: 10,
  },
  phaseContainer: {
    flex: 1,
    flexDirection: "row",
    maxHeight: 67,
    minHeight: 67,
    marginTop: 10,
  },
  statusContainer: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    borderRadius: 8,
    justifyContent: "center",
    minHeight: 67,
    maxHeight: 67,
    padding: 15,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  advanceText: {
    color: "#D2D2D2",
    fontWeight: "bold",
    fontSize: adjust(16),
  },
  checkboxContainer: {
    // marginLeft: 10,
    backgroundColor: "#F7F7F7",
    height: 30,
    width: 30,
    justifyContent: "flex-start",
    borderRadius: 15,
    padding: 10,
  },
  gestureContainer: {
    flex: 1,
    marginBottom: 10,
  },
  amount: {
    fontWeight: "bold",
    fontSize: adjust(30),
    marginTop: 0,
  },
  text: {
    fontSize: adjust(15),
  },
});
