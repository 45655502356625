import React, { memo } from "react";
import { View } from "react-native";
import Svg, {
  Path,
  G,
  Line,
  Ellipse,
  Polygon,
  Circle,
  Rect,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  Filter,
  Text,
} from "react-native-svg";

const Icon = ({
  name,
  fill,
  width,
  bottomColor,
  height,
  viewBox,
  stroke,
  circleFill,
  ...otherProps
}) => {
  const graphics = {
    yellowCircle: {
      width: 30,
      height: 30,
      viewBox: "0 0 30 30",
      content: (
        <Circle
          cx="12"
          cy="12"
          r="12"
          transform="translate(3 3)"
          fill="none"
          stroke="#ffd500"
          strokeWidth="6"
        />
      ),
    },
    smallYellowCircle: {
      width: 30,
      height: 30,
      viewBox: "0 0 30 30",
      content: (
        <Circle
          cx="12"
          cy="12"
          r="12"
          transform="translate(3 3)"
          fill="none"
          stroke="#ffd500"
          strokeWidth="3"
        />
      ),
    },
    refresh: {
      width: 30,
      height: 30,
      viewBox: "0 0 30 30",
      content: (
        <G>
          <G>
            <G>
              <Path
                d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0
			C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715
			C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651
			c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53
			C512.919,81.362,504.815,71.632,493.815,70.629z"
              />
            </G>
          </G>
          <G>
            <G>
              <Path
                d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472
			c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651
			c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53
			c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512
			c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"
              />
            </G>
          </G>
        </G>
      ),
    },
    rightArrow: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 34.837 24.908",
      content: (
        <Path
          id="Path_8171"
          data-name="Path 8171"
          d="M131.377,84.657a.36.36,0,0,0,0-.043.325.325,0,0,0-.016-.063c0-.005,0-.011,0-.016l0-.006a.3.3,0,0,0-.042-.07l0-.007c-.006-.007-.009-.015-.016-.021L119.911,72.824a.318.318,0,0,0-.455.446L130.3,84.337H97.858a.318.318,0,0,0-.318.318v0a.319.319,0,0,0,.318.318H130.3L119.456,96.046a.318.318,0,1,0,.455.446l11.377-11.609c.006-.007.01-.015.016-.022l0-.007a.3.3,0,0,0,.042-.069l0-.006c0-.005,0-.011,0-.016a.323.323,0,0,0,.016-.062A.36.36,0,0,0,131.377,84.657Z"
          transform="translate(-97.04 -72.179)"
          stroke={fill ? fill : "#000"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      ),
    },
    gallery: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 20.806 20.797",
      content: (
        <Path
          id="Path_8178"
          data-name="Path 8178"
          d="M146.8,59.149a3.064,3.064,0,0,0-3.06-3.06H129.061a3.068,3.068,0,0,0-3.069,3.06V73.825a3.067,3.067,0,0,0,3.069,3.06h14.676a3.06,3.06,0,0,0,3.06-3.06Zm-17.736-1.326h14.676a1.328,1.328,0,0,1,1.327,1.326V69.682l-3.537-4.994a1.74,1.74,0,0,0-2.843,0l-3.225,4.56-1.795-2.523a1.74,1.74,0,0,0-2.843,0l-3.094,4.369V59.149a1.332,1.332,0,0,1,1.335-1.326Z"
          transform="translate(-125.992 -56.089)"
          fill={fill ? fill : "#000"}
        />
      ),
    },
    youTube: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 22.966 15.351",
      content: (
        <Path
          id="Path_8185"
          data-name="Path 8185"
          d="M103.7,101.92H87.262A3.25,3.25,0,0,0,84,105.184v8.824a3.25,3.25,0,0,0,3.264,3.264H103.7a3.25,3.25,0,0,0,3.264-3.264v-8.824a3.265,3.265,0,0,0-3.264-3.263Zm-5.028,8.243-4.762,2.974a.652.652,0,0,1-.991-.556v-5.947a.651.651,0,0,1,.991-.556l4.762,2.973a.678.678,0,0,1,0,1.112Z"
          transform="translate(-83.998 -101.92)"
          fill={fill ? fill : "#000"}
        />
      ),
    },
    mike: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 16.069 22.956",
      content: (
        <G id="noun-mic-1078593" transform="translate(-154)">
          <Path
            id="Path_8186"
            data-name="Path 8186"
            d="M269.443,13.773a3.453,3.453,0,0,0,3.443-3.443h-2.3v-2.3h2.3v-2.3h-2.3v-2.3h2.3a3.443,3.443,0,1,0-6.887,0V10.33A3.382,3.382,0,0,0,269.443,13.773Z"
            transform="translate(-107.409)"
            fill={fill ? fill : "#000"}
          />
          <Path
            id="Path_8187"
            data-name="Path 8187"
            d="M170.069,144.591v-3.443a1.148,1.148,0,0,0-2.3,0v3.443a5.763,5.763,0,0,1-6.313,5.739,5.959,5.959,0,0,1-5.165-5.969v-3.214a1.148,1.148,0,1,0-2.3,0v3.214a8.283,8.283,0,0,0,6.887,8.264v2.3h-3.1c-.23,0-.459.115-.459.344l-1.033,1.951h11.478l-1.033-1.951a.615.615,0,0,0-.459-.344h-3.1v-2.41a8.031,8.031,0,0,0,6.887-7.92Z"
            transform="translate(0 -134.261)"
            fill={fill ? fill : "#000"}
          />
        </G>
      ),
    },
    image: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="none"
        >
          <Path
            d="M240 4541 c-90 -28 -169 -96 -209 -181 l-26 -55 0 -1745 0 -1745 26
-55 c32 -66 86 -123 154 -158 l50 -27 2325 0 2325 0 52 27 c68 36 120 90 152
158 l26 55 0 1745 0 1745 -26 55 c-32 68 -84 122 -152 158 l-52 27 -2310 2
c-1270 1 -2321 -2 -2335 -6z m4550 -1493 l0 -1182 -27 26 c-238 221 -637 575
-668 591 -51 27 -154 29 -205 3 -19 -10 -189 -172 -378 -359 -203 -203 -345
-338 -350 -331 -37 54 -1292 1540 -1316 1558 -86 64 -215 61 -291 -6 -20 -17
-297 -335 -617 -707 -319 -372 -587 -683 -594 -691 -12 -12 -14 166 -14 1133
l0 1147 2230 0 2230 0 0 -1182z"
          />
          <Path
            d="M3755 3806 c-119 -30 -237 -121 -290 -226 -117 -231 -23 -502 213
-613 63 -30 74 -32 182 -32 109 0 119 2 183 32 182 86 291 286 259 473 -46
260 -297 428 -547 366z"
          />
        </G>
      ),
    },
    cancel: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 16.971 16.971",
      content: (
        <G
          id="Group_2341"
          data-name="Group 2341"
          transform="translate(8.485 -7.847) rotate(45)"
        >
          <Rect
            id="Rectangle_1102"
            data-name="Rectangle 1102"
            width="21"
            height="3"
            rx="1.5"
            transform="translate(1.049 10.048)"
            fill={fill ? fill : "#073257"}
          />
          <Rect
            id="Rectangle_1103"
            data-name="Rectangle 1103"
            width="21"
            height="3"
            rx="1.5"
            transform="translate(13.049 1.048) rotate(90)"
            fill={fill ? fill : "#073257"}
          />
        </G>
      ),
    },
    send: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 19.798 19.796",
      content: (
        <Path
          id="noun-send-3264455"
          d="M70.591.012a.619.619,0,0,0-.575.777l2.061,6.449,9.1,2.67-9.1,3.2-2.061,5.917a.619.619,0,0,0,.876.713l18.56-9.28a.619.619,0,0,0,0-1.106L70.892.076a.618.618,0,0,0-.3-.064Z"
          transform="translate(-69.994 -0.011)"
          fill={fill ? fill : "#fff"}
        />
      ),
    },
    home: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 22 22",
      content: (
        <Path
          id="Path_2404"
          data-name="Path 2404"
          d="M1.307,8.47a.734.734,0,0,0-.307.6v13.2A.734.734,0,0,0,1.733,23H9.067a.734.734,0,0,0,.733-.733v-6.6h4.4v6.6a.734.734,0,0,0,.733.733h7.333A.734.734,0,0,0,23,22.267V9.067a.734.734,0,0,0-.307-.6L12.426,1.136a.734.734,0,0,0-.852,0L1.307,8.47Z"
          transform="translate(-1 -1)"
          fill={fill ? fill : "#073257"}
          fillRule="evenodd"
        />
      ),
    },
    project: {
      width: width || 25,
      height: height || 25,
      viewBox: "0 0 25 25",
      content: (
        <G
          id="Group_2540"
          data-name="Group 2540"
          transform="translate(-102 -723)"
        >
          <Rect
            id="Rectangle_1098"
            data-name="Rectangle 1098"
            width="25"
            height="25"
            rx="5"
            transform="translate(102 723)"
            fill={fill || "#707070"}
          />
          <Rect
            id="Rectangle_1099"
            data-name="Rectangle 1099"
            width="15"
            height="3"
            rx="1.5"
            transform="translate(107 728)"
            fill="#fff"
          />
          <Rect
            id="Rectangle_1100"
            data-name="Rectangle 1100"
            width="15"
            height="3"
            rx="1.5"
            transform="translate(107 734)"
            fill="#fff"
          />
          <Rect
            id="Rectangle_1101"
            data-name="Rectangle 1101"
            width="9"
            height="3"
            rx="1.5"
            transform="translate(107 740)"
            fill="#fff"
          />
        </G>
      ),
    },
    meeting: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 25 25",
      content: (
        <G
          id="Group_2464"
          data-name="Group 2464"
          transform="translate(-318 -279)"
        >
          <Rect
            id="Rectangle_1118"
            data-name="Rectangle 1118"
            width="25"
            height="25"
            rx="3"
            transform="translate(318 279)"
            fill={fill ? fill : "#707070"}
          />
          <Rect
            id="Rectangle_1119"
            data-name="Rectangle 1119"
            width="21"
            height="15"
            rx="2"
            transform="translate(320 287)"
            fill="#fff"
          />
          <Rect
            id="Rectangle_1121"
            data-name="Rectangle 1121"
            width="5"
            height="5"
            rx="1"
            transform="translate(322 289)"
            fill={fill ? fill : "#707070"}
          />
          <Rect
            id="Rectangle_1126"
            data-name="Rectangle 1126"
            width="5"
            height="5"
            rx="1"
            transform="translate(328 289)"
            fill={fill ? fill : "#707070"}
          />
          <Rect
            id="Rectangle_1127"
            data-name="Rectangle 1127"
            width="5"
            height="5"
            rx="1"
            transform="translate(334 289)"
            fill={fill ? fill : "#707070"}
          />
          <Rect
            id="Rectangle_1128"
            data-name="Rectangle 1128"
            width="5"
            height="5"
            rx="1"
            transform="translate(322 295)"
            fill={fill ? fill : "#707070"}
          />
          <Rect
            id="Rectangle_1129"
            data-name="Rectangle 1129"
            width="5"
            height="5"
            rx="1"
            transform="translate(328 295)"
            fill={fill ? fill : "#707070"}
          />
          <Rect
            id="Rectangle_1130"
            data-name="Rectangle 1130"
            width="5"
            height="5"
            rx="1"
            transform="translate(334 295)"
            fill={fill ? fill : "#707070"}
          />
        </G>
      ),
    },
    inbox: {
      width: width || 25.057,
      height: height || 25.899,
      viewBox: "0 0 28.057 25.899",
      content: (
        <G
          id="Group_2541"
          data-name="Group 2541"
          transform="translate(-245.971 -724.101)"
        >
          <Path
            id="Path_2407"
            data-name="Path 2407"
            d="M10.791,24.82a1.084,1.084,0,0,0,.738,1.025,1.16,1.16,0,0,0,.341.054,1.075,1.075,0,0,0,.863-.432l2.914-3.885h2.7a9.724,9.724,0,0,0,9.712-9.712V9.712A9.724,9.724,0,0,0,18.345,0H9.712A9.724,9.724,0,0,0,0,9.712V11.87a9.724,9.724,0,0,0,9.712,9.712h1.079Z"
            transform="translate(245.971 724.101)"
            fill={fill || "#707070"}
          />
          <Rect
            id="Rectangle_1104"
            data-name="Rectangle 1104"
            width="12"
            height="3"
            rx="1.5"
            transform="translate(257.493 729.5)"
            fill="#fff"
          />
          <Rect
            id="Rectangle_1105"
            data-name="Rectangle 1105"
            width="9.493"
            height="3"
            rx="1.5"
            transform="translate(250.507 735.5)"
            fill="#fff"
          />
        </G>
      ),
    },
    settings: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="none"
        >
          <Path
            d="M1720 4806 c-214 -75 -544 -265 -665 -383 -91 -88 -110 -203 -60
-353 39 -114 42 -277 8 -385 -74 -232 -269 -397 -513 -434 -128 -20 -210 -73
-259 -169 -45 -88 -77 -380 -67 -612 12 -286 39 -386 124 -461 51 -45 94 -61
217 -84 137 -25 222 -70 325 -174 132 -134 184 -260 183 -446 0 -90 -4 -111
-39 -210 -85 -244 -40 -333 276 -543 107 -71 319 -181 438 -227 122 -47 193
-48 281 -4 39 20 75 47 99 77 116 143 195 203 329 248 247 84 514 4 685 -204
96 -116 215 -158 340 -118 98 32 315 145 450 235 171 114 258 194 287 261 37
86 37 132 1 240 -40 120 -51 268 -26 372 54 231 230 409 461 464 165 40 172
43 229 95 39 36 61 65 75 104 76 204 74 790 -3 961 -26 56 -79 115 -127 140
-17 8 -62 22 -100 30 -265 55 -451 226 -514 474 -24 91 -16 262 15 345 31 84
32 183 2 250 -29 62 -54 90 -165 176 -220 171 -563 345 -703 356 -105 9 -148
-12 -279 -136 -85 -82 -131 -116 -188 -144 -178 -88 -373 -88 -554 0 -61 30
-101 60 -189 144 -85 81 -122 110 -159 123 -67 22 -138 20 -215 -8z m1030
-1470 c208 -56 368 -170 480 -343 240 -366 133 -862 -237 -1103 -136 -89 -259
-125 -433 -125 -226 0 -408 75 -564 231 -156 156 -231 338 -231 564 0 174 36
297 125 433 120 185 307 310 525 353 85 16 257 12 335 -10z"
          />
        </G>
      ),
    },
    back: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 24 24",
      content: (
        <G
          id="icons_back-light"
          data-name="icons/back-light"
          clipPath="url(#clip-path)"
        >
          <Rect id="bg" width="24" height="24" fill="none" />
          <Path
            id="icon"
            d="M6.241,13.291.362,7.411A.751.751,0,0,1,.1,6.391a.757.757,0,0,1,.206-.24L6.241.22A.75.75,0,0,1,7.3,1.281L2.563,6.019H15.251a.75.75,0,0,1,0,1.5H2.591L7.3,12.23a.75.75,0,0,1-1.061,1.061Z"
            transform="translate(4 5.23)"
            fill={fill ? fill : "#000000"}
          />
        </G>
      ),
    },
    plus: {
      width: width || 40,
      height: height || 40,
      viewBox: "0 0 58.012 58.012",
      content: (
        <G
          id="Group_2169"
          data-name="Group 2169"
          transform="translate(-0.002 0)"
        >
          <Path
            id="Path_2318"
            data-name="Path 2318"
            d="M3.721,56.612H59.29c.672,0,1.221-.962,1.221-2.139V46.239c0-1.176-.55-2.139-1.221-2.139H3.721c-.672,0-1.221.962-1.221,2.139v8.234C2.5,55.649,3.05,56.612,3.721,56.612Z"
            transform="translate(-2.498 -21.349)"
            fill={fill ? fill : "#fff"}
          />
          <Path
            id="Path_2319"
            data-name="Path 2319"
            d="M1.221,12.512H56.79c.672,0,1.221-.962,1.221-2.139V2.139C58.012.962,57.462,0,56.79,0H1.221C.55,0,0,.962,0,2.139v8.234C0,11.549.55,12.512,1.221,12.512Z"
            transform="translate(35.263 0) rotate(90)"
            fill={fill ? fill : "#fff"}
          />
        </G>
      ),
    },
    box3D: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 24 24",
      content: (
        <Path
          id="Path_2298"
          data-name="Path 2298"
          d="M35.98,22.055a.425.425,0,0,1,.418,0l9.765,5.519a.425.425,0,0,1,.216.37V39.407a.425.425,0,0,1-.216.37L36.4,45.3a.425.425,0,0,1-.418,0l-9.765-5.519a.425.425,0,0,1-.216-.37V27.944a.425.425,0,0,1,.216-.37Zm-9.131,6.63,4.246,2.492v5.014l-4.246,2.477ZM27.277,39.4l8.488,4.8V39.234l-4.239-2.312Zm4.667-3.218,3.821,2.084V33.918l-3.821-2.243Zm.433-5.237,3.812,2.238L40,30.944l-3.834-2.092Zm4.216-2.829,4.264,2.326L45.1,27.951l-8.492-4.8Zm-.831-4.963-.018,4.964-4.219,2.328-4.251-2.495Zm9.767,5.532-4.246,2.492v5.014l4.246,2.477ZM45.1,39.4l-4.249-2.479-4.239,2.312V44.2Zm-8.488-1.134,3.821-2.084V31.676l-3.821,2.243Z"
          transform="translate(-26 -22)"
          fill={fill || "#a9a9a9"}
          fillRule="evenodd"
        />
      ),
    },
    paginationDotActive: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 24 24",
      content: (
        <Ellipse
          id="Ellipse_184"
          data-name="Ellipse 184"
          cx="3.262"
          cy="3.262"
          rx="3.262"
          ry="3.262"
          fill={fill || "#000000"}
        />
      ),
    },
    paginationDotInactive: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 24 24",
      content: (
        <Ellipse
          id="Ellipse_184"
          data-name="Ellipse 184"
          cx="3.262"
          cy="3.262"
          rx="3.262"
          ry="3.262"
          fill={fill || "#707070"}
        />
      ),
    },
    selectDownArrow: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 24 24",
      content: (
        <G
          id="Group_2133"
          data-name="Group 2133"
          transform="translate(395.284 -310.523) rotate(90)"
        >
          <G
            id="Group_758"
            data-name="Group 758"
            transform="translate(311.494 386.386)"
          >
            <Line
              id="Line_2"
              data-name="Line 2"
              y1="0.317"
              x2="5.446"
              transform="translate(3.851 4.3) rotate(135)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="1"
            />
            <Line
              id="Line_4"
              data-name="Line 4"
              x2="5.446"
              y2="0.317"
              transform="translate(3.626 4.075) rotate(-135)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="1"
            />
          </G>
        </G>
      ),
    },
    rightChevronArrow: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 24 24",
      content: (
        <G
          id="Group_2215"
          dataname="Group 2215"
          transform="translate(-310.08 -384.972)"
        >
          <G
            id="Group_758"
            dataname="Group 758"
            transform="translate(311.494 386.386)"
          >
            <Line
              id="Line_2"
              dataname="Line 2"
              x2="7.866"
              transform="translate(5.562 5.562) rotate(135)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <Line
              id="Line_4"
              dataname="Line 4"
              x2="7.866"
              transform="translate(5.562 5.562) rotate(-135)"
              fill="none"
              stroke="#7b7b7b"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </G>
        </G>
      ),
    },
    rightChevronArrowWithCircle: {
      width: width || 26,
      height: height || 26,
      viewBox: "0 0 26 26",
      content: (
        <G
          id="Group_2133"
          data-name="Group 2133"
          transform="translate(-301 -379)"
        >
          <Circle
            id="Ellipse_191"
            dataname="Ellipse 191"
            cx="13"
            cy="13"
            r="13"
            transform="translate(301 379)"
            fill={fill ? fill : "#000000"}
          />
          <G
            id="Group_758"
            dataname="Group 758"
            transform="translate(311.494 386.386)"
          >
            <Line
              id="Line_2"
              dataname="Line 2"
              x2="7.866"
              transform="translate(5.562 5.562) rotate(135)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <Line
              id="Line_4"
              dataname="Line 4"
              x2="7.866"
              transform="translate(5.562 5.562) rotate(-135)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </G>
        </G>
      ),
    },
    file: {
      width: width || 11,
      height: height || 22,
      viewBox: "0 0 11 22",
      content: (
        <G
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill={fill ? fill : "none"}
          fillRule="evenodd"
        >
          <G id="Outlined" transform="translate(-277.000000, -2103.000000)">
            <G id="Editor" transform="translate(100.000000, 1960.000000)">
              <G
                id="Outlined-/-Editor-/-attach_file"
                transform="translate(170.000000, 142.000000)"
              >
                <G>
                  <Polygon id="Path" points="0 0 24 0 24 24 0 24"></Polygon>
                  <Path
                    d="M16.5,6 L16.5,17.5 C16.5,19.71 14.71,21.5 12.5,21.5 C10.29,21.5 8.5,19.71 8.5,17.5 L8.5,5 C8.5,3.62 9.62,2.5 11,2.5 C12.38,2.5 13.5,3.62 13.5,5 L13.5,15.5 C13.5,16.05 13.05,16.5 12.5,16.5 C11.95,16.5 11.5,16.05 11.5,15.5 L11.5,6 L10,6 L10,15.5 C10,16.88 11.12,18 12.5,18 C13.88,18 15,16.88 15,15.5 L15,5 C15,2.79 13.21,1 11,1 C8.79,1 7,2.79 7,5 L7,17.5 C7,20.54 9.46,23 12.5,23 C15.54,23 18,20.54 18,17.5 L18,6 L16.5,6 Z"
                    id="🔹-Icon-Color"
                    fill="#1D1D1D"
                  ></Path>
                </G>
              </G>
            </G>
          </G>
        </G>
      ),
    },
    fileDownload: {
      width: width || 48,
      height: height || 48,
      viewBox: "0 0 48 48",
      content: (
        <G
          transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <Path
            d="M92 428 c-8 -8 -12 -65 -12 -190 0 -165 1 -178 19 -188 11 -5 76 -10
        145 -10 100 0 128 3 140 16 13 12 16 39 16 132 l0 118 -68 67 -68 67 -80 0
        c-49 0 -85 -5 -92 -12z m148 -73 c0 -64 13 -75 84 -75 l46 0 0 -105 0 -105
        -130 0 -130 0 0 170 0 170 65 0 65 0 0 -55z m70 -10 l34 -35 -37 0 c-36 0 -37
        1 -37 35 0 19 1 35 3 35 2 0 18 -16 37 -35z"
          />
          <Path
            d="M226 244 c-3 -9 -6 -24 -6 -35 0 -12 -5 -16 -15 -13 -22 9 -18 -16 7
        -43 l21 -23 -36 0 c-26 0 -37 -4 -37 -15 0 -12 16 -15 80 -15 64 0 80 3 80 15
        0 11 -11 15 -37 15 l-36 0 21 23 c25 27 29 52 7 43 -10 -3 -15 1 -15 13 0 25
        -10 51 -20 51 -4 0 -11 -7 -14 -16z"
          />
        </G>
      ),
    },
    tickMarkWithCircle: {
      width: width || 12,
      height: height || 12,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="none"
        >
          <Path
            d="M2323 5110 c-598 -58 -1146 -316 -1571 -740 -397 -397 -640 -882
-729 -1460 -24 -161 -24 -539 0 -700 89 -577 330 -1059 727 -1458 397 -397
882 -640 1460 -729 161 -24 539 -24 700 0 577 89 1059 330 1458 727 397 397
640 882 729 1460 24 161 24 539 0 700 -89 577 -330 1059 -727 1458 -394 395
-885 641 -1450 727 -125 19 -468 27 -597 15z m562 -415 c462 -72 869 -279
1200 -610 334 -334 538 -738 612 -1210 12 -78 17 -170 17 -315 0 -145 -5 -237
-17 -315 -74 -472 -278 -876 -612 -1210 -334 -334 -738 -538 -1210 -612 -151
-24 -479 -24 -630 0 -472 74 -876 278 -1210 612 -334 334 -538 738 -612 1210
-24 151 -24 479 0 630 74 472 278 876 612 1210 368 368 805 572 1350 629 81 8
403 -4 500 -19z"
          />
          <Path
            d="M3470 3414 c-22 -9 -257 -237 -647 -627 l-613 -611 -257 256 c-142
140 -272 262 -288 271 -103 52 -219 15 -270 -87 -30 -60 -32 -115 -5 -170 12
-27 142 -163 367 -387 274 -273 356 -349 387 -359 51 -16 128 -7 169 21 48 33
1363 1347 1400 1399 28 40 32 53 32 112 0 58 -4 72 -30 109 -56 79 -159 110
-245 73z"
          />
        </G>
      ),
    },
    starIcon: {
      width: width || 12,
      height: height || 12,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="none"
        >
          <Path
            d="M2205 4135 c-336 -845 -349 -875 -374 -875 -60 1 -1749 -111 -1785
-118 l-38 -7 706 -599 c388 -330 706 -606 706 -613 0 -7 -103 -419 -230 -915
-126 -496 -229 -903 -227 -904 2 -2 361 223 799 500 l797 504 797 -504 c439
-277 798 -503 800 -501 1 1 -282 1119 -440 1735 l-24 93 710 602 710 602 -39
7 c-35 7 -1723 118 -1785 118 -26 0 -34 19 -375 875 -191 481 -351 875 -354
875 -3 0 -162 -394 -354 -875z"
          />
        </G>
      ),
    },
    download: {
      width: width || 40,
      height: height || 40,
      viewBox: "0 0 40 40",
      content: (
        <G
          id="noun_Download_2849794"
          transform="translate(-1 -1)"
          fill="#000000"
        >
          <Path
            id="Path_2397"
            dataName="Path 2397"
            d="M15.922,1A14.922,14.922,0,1,0,30.844,15.922,14.922,14.922,0,0,0,15.922,1Zm0,27.131A12.209,12.209,0,1,1,28.131,15.922,12.209,12.209,0,0,1,15.922,28.131Z"
            transform="translate(0 0)"
          />
          <Path
            id="Path_2398"
            dataName="Path 2398"
            d="M19.96,14.889,15.5,19.366V6.357a1.357,1.357,0,1,0-2.713,0V19.366L8.321,14.889a1.362,1.362,0,1,0-1.926,1.926L13.178,23.6h0a1.153,1.153,0,0,0,.407.258h0a1.357,1.357,0,0,0,.556.136,1.655,1.655,0,0,0,.515-.095,1.614,1.614,0,0,0,.448-.3l6.783-6.783a1.362,1.362,0,1,0-1.926-1.926Z"
            transform="translate(1.781 1.426)"
          />
        </G>
      ),
    },
    tick: {
      width: width || 26,
      height: height || 26,
      viewBox: "0 0 26 26",
      fill: fill || "#41b000",
      content: (
        <G
          id="Group_2246"
          data-name="Group 2246"
          transform="translate(-297 -542)"
        >
          <Circle
            id="Ellipse_216"
            data-name="Ellipse 216"
            cx="12"
            cy="12"
            r="12"
            transform="translate(297 542)"
            fill={fill}
          />
          <G
            id="Group_1778"
            data-name="Group 1778"
            transform="translate(123 456)"
          >
            <Path
              id="Path_2287"
              data-name="Path 2287"
              d="M0,2.259,3.556,6.213,12.608,0"
              transform="translate(179.883 95.821) rotate(-9)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </G>
        </G>
      ),
    },
    dot: {
      width: 10,
      height: 10,
      viewBox: "0 0 10 10",
      content: (
        <G
          id="Ellipse_7"
          data-name="Ellipse 7"
          stroke={stroke ? stroke : "#000"}
          strokeWidth="1"
        >
          <Ellipse cx="1.816" cy="1.816" rx="1.816" ry="1.816" stroke="none" />
          <Ellipse
            cx="1.816"
            cy="1.816"
            rx="1.316"
            ry="1.316"
            fill={fill ? fill : "none"}
          />
        </G>
      ),
    },
    edit: {
      width: 30,
      height: 30,
      viewBox: "0 0 30 30",
      content: (
        <G
          id="Group_1322"
          data-name="Group 1322"
          transform="translate(-273.74 -387.76)"
        >
          <Ellipse
            id="Ellipse_21"
            data-name="Ellipse 21"
            cx="14.054"
            cy="14.054"
            rx="14.054"
            ry="14.054"
            transform="translate(273.74 387.76)"
          />
          <G
            id="Group_1318"
            data-name="Group 1318"
            transform="translate(281.66 395.018)"
          >
            <Path
              id="Path_1672"
              data-name="Path 1672"
              d="M18.571,1.167a1.167,1.167,0,0,1,0,1.65l-.53.53-.587.587L8.974,12.413a1.167,1.167,0,0,1-.726.338l-1.9.161A.324.324,0,0,1,6,12.561l.161-1.9A1.167,1.167,0,0,1,6.5,9.939L14.98,1.458l.587-.587.53-.53a1.167,1.167,0,0,1,1.65,0Z"
              transform="translate(-6 0)"
              fill="#fff"
            />
          </G>
        </G>
      ),
    },
    blackCircle: {
      width: 34,
      height: 34,
      viewBox: "0 0 34 34",
      content: (
        <Ellipse
          id="Ellipse_22"
          data-name="Ellipse 22"
          cx="16.177"
          cy="16.177"
          rx="16.177"
          ry="16.177"
        />
      ),
    },
    rating: {
      width: 34,
      height: 34,
      viewBox: "0 0 34 34",
      content: (
        <G>
          <Path
            id="Path_2337"
            data-name="Path 2337"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            fill="#ffc400"
          />
          <G
            id="Group_1005"
            data-name="Group 1005"
            transform="translate(5.991 5.971)"
          >
            <Path
              id="Path_152"
              data-name="Path 152"
              d="M12.094,960.408a.277.277,0,0,0-.252.189l-1.307,3.979-4.255-.008a.277.277,0,0,0-.264.188.27.27,0,0,0,.1.305l3.448,2.45L8.24,971.485a.27.27,0,0,0,.1.305.282.282,0,0,0,.327,0l3.438-2.464,3.44,2.464a.282.282,0,0,0,.327,0,.27.27,0,0,0,.1-.305l-1.324-3.974,3.448-2.45a.27.27,0,0,0,.1-.305.277.277,0,0,0-.264-.188l-4.256.008L12.369,960.6A.277.277,0,0,0,12.094,960.408Z"
              transform="translate(-6.001 -960.408)"
              fill="#fff"
              fillRule="evenodd"
            />
          </G>
        </G>
      ),
    },
    yellowIcon: {
      width: 34,
      height: 34,
      viewBox: "0 0 34 34",
      content: (
        <G>
          <Circle
            id="Ellipse_201"
            data-name="Ellipse 201"
            cx="8.5"
            cy="8.5"
            r="8.5"
            fill="#ffd500"
          />
        </G>
      ),
    },
    delete: {
      width: 30,
      height: 30,
      viewBox: "0 0 55 55",
      content: (
        <G id="Layer_19" data-name="Layer 19">
          <Path
            fill={fill ? fill : "#000000"}
            d="M49,16.47a1.48,1.48,0,0,0-1.1-.49H16.11a1.5,1.5,0,0,0-1.49,1.63L17.3,48.49a5.45,5.45,0,0,0,5.46,5H41.24a5.45,5.45,0,0,0,5.46-5l2.68-30.88A1.46,1.46,0,0,0,49,16.47ZM43.71,48.23a2.47,2.47,0,0,1-2.47,2.27H22.76a2.47,2.47,0,0,1-2.47-2.27L17.75,19h28.5Z"
          />
          <Path
            fill={fill ? fill : "#000000"}
            d="M25.83,13.5H38.17a1.5,1.5,0,0,0,0-3H25.83A1.5,1.5,0,0,0,25.83,13.5Z"
          />
          <Path
            fill={fill ? fill : "#000000"}
            d="M28,45a1.5,1.5,0,0,0,1.5-1.5V27.37a1.5,1.5,0,0,0-3,0V43.46A1.5,1.5,0,0,0,28,45Z"
          />
          <Path
            fill={fill ? fill : "#000000"}
            d="M36,45a1.5,1.5,0,0,0,1.5-1.5V27.37a1.5,1.5,0,0,0-3,0V43.46A1.5,1.5,0,0,0,36,45Z"
          />
        </G>
      ),
    },
    exclamation: {
      width: 20,
      height: 20,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="none"
        >
          <Path
            d="M2433 5105 c-216 -47 -392 -225 -438 -443 -21 -99 -22 -2764 -1
        -2863 35 -163 160 -324 307 -394 130 -61 245 -77 374 -50 223 47 403 227 450
        450 22 105 22 2745 0 2850 -46 221 -227 405 -442 450 -83 18 -168 18 -250 0z"
          />
          <Path
            d="M2440 1144 c-106 -22 -205 -77 -286 -158 -180 -179 -223 -432 -113
        -663 44 -92 167 -216 261 -261 166 -81 348 -80 514 0 98 47 209 158 258 258
        39 80 66 186 66 260 0 205 -136 424 -320 514 -128 62 -250 78 -380 50z"
          />
        </G>
      ),
    },
    notification: {
      width: width || 30,
      height: height || 30,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="yellow"
        >
          <Path
            d="M2507 4899 c-102 -16 -195 -89 -240 -187 -24 -54 -30 -192 -9 -229
        11 -21 10 -22 -25 -29 -135 -23 -290 -74 -383 -126 -328 -183 -459 -523 -460
        -1193 0 -266 -16 -573 -35 -693 -19 -116 -60 -241 -106 -320 -22 -38 -91 -113
        -209 -227 -96 -93 -262 -258 -369 -365 -220 -222 -241 -253 -241 -361 0 -290
        425 -449 1365 -509 88 -5 199 -10 247 -10 98 0 90 7 103 -92 15 -114 115 -246
        228 -299 126 -59 248 -59 374 0 113 53 213 185 228 299 13 100 4 92 108 92
        134 0 497 27 687 51 537 67 840 193 906 377 22 62 18 151 -11 208 -25 51 -240
        274 -585 609 -191 185 -223 228 -271 367 -54 159 -78 415 -79 858 0 238 -12
        402 -40 552 -83 443 -308 679 -734 769 -50 10 -95 19 -99 19 -4 0 -2 10 5 23
        7 12 12 57 13 102 0 68 -4 89 -28 137 -63 129 -199 199 -340 177z m116 -229
        c38 -30 52 -80 33 -123 -15 -37 -59 -67 -96 -67 -37 0 -81 30 -96 67 -29 68
        21 143 96 143 22 0 48 -8 63 -20z m202 -421 c200 -24 358 -92 465 -199 173
        -173 229 -426 230 -1035 1 -593 55 -854 227 -1083 47 -62 334 -347 430 -426
        60 -50 303 -315 303 -331 0 -8 -16 -32 -35 -53 -101 -113 -461 -198 -1015
        -242 -298 -23 -863 -33 -1242 -21 -625 19 -978 58 -1253 136 -161 46 -295 128
        -295 181 0 15 220 256 287 314 21 18 117 109 215 201 397 380 440 505 458
        1329 10 447 22 585 66 736 70 244 213 387 454 457 180 53 451 66 705 36z m-58
        -3650 c-7 -50 -40 -101 -91 -136 -55 -39 -153 -44 -215 -11 -54 29 -101 92
        -108 147 l-6 41 213 0 213 0 -6 -41z"
          />
        </G>
      ),
    },
    bell: {
      width: width || 30,
      height: height || 30,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G>
          <G
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={fill ? fill : "#000"}
            stroke="black"
          >
            <Path
              d="M85,67.7l-4.5-5.5c-3.3-4.1-5.2-9.2-5.2-14.5V37.5c0-12.1-8.5-22.3-19.9-24.8V7.9c0-3-2.4-5.4-5.4-5.4s-5.4,2.4-5.4,5.4
              v4.8c-11.4,2.5-19.9,12.6-19.9,24.8v10.2c0,5.3-1.8,10.4-5.2,14.5L15,67.7c-3.1,3.8-0.4,9.6,4.5,9.6h60.9
              C85.4,77.3,88.1,71.5,85,67.7z"
            />
            <Path d="M50,97.5c7.2,0,13-5.8,13-13H37C37,91.7,42.8,97.5,50,97.5z" />
          </G>
        </G>
      ),
      //     <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      //  viewBox="0 0 100 125" style="enable-background:new 0 0 100 125;" xml:space="preserve">

      // </svg>
    },
    remove: {
      width: 22,
      height: 22,
      viewBox: "0 0 40 40",
      content: (
        <G>
          <Path
            fill={fill ? fill : "#000000"}
            d="M16,4C9.383,4,4,9.383,4,16s5.383,12,12,12s12-5.383,12-12S22.617,4,16,4 M16,2c7.732,0,14,6.268,14,14s-6.268,14-14,14  S2,23.732,2,16S8.268,2,16,2L16,2z M19.536,11.05l1.414,1.414L17.414,16l3.536,3.536l-1.414,1.414L16,17.414l-3.536,3.536  l-1.414-1.414L14.586,16l-3.536-3.536l1.414-1.414L16,14.586L19.536,11.05z"
          />
        </G>
      ),
    },
    star: {
      width: width || 45,
      height: height || 45,
      viewBox: "0 0 55 55",
      content: (
        <Path
          fill={fill ? fill : "#ffc400"}
          id="Path_152"
          data-name="Path 152"
          d="M23.536,960.408a.8.8,0,0,0-.727.527l-3.762,11.108L6.8,972.021a.794.794,0,0,0-.761.525.742.742,0,0,0,.291.852l9.923,6.841-3.81,11.1a.741.741,0,0,0,.29.852.831.831,0,0,0,.94,0l9.9-6.88,9.9,6.88a.831.831,0,0,0,.94,0,.741.741,0,0,0,.29-.852l-3.81-11.1,9.923-6.841a.742.742,0,0,0,.291-.852.8.8,0,0,0-.761-.526l-12.247.023-3.766-11.108A.8.8,0,0,0,23.536,960.408Z"
          transform="translate(-6.001 -960.408)"
        />
      ),
    },
    userIcon: {
      width: width || 30,
      height: height || 30,
      viewBox: "0 0 17 21.2",
      // style: "enable-background:new 0 0 17 21.2",
      content: (
        <G>
          <Circle fill="#FFF" cx="7.9" cy="7.7" r="2.2" />
          <Path
            fill={fill ? fill : "#FFF"}
            d="M11.7,15.3c2-1.3,0.4-3.3,0.4-3.3l0,0c-0.8-1-2.1-1.6-3.4-1.6H7.2c-1.4,0-2.7,0.7-3.5,1.7c-0.2,0.3-1.3,2.1,0.5,3.2
	C6.5,16.5,9.3,16.5,11.7,15.3C11.7,15.3,11.7,15.3,11.7,15.3z"
          />
        </G>
      ),
    },
    cross: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 13.9 13.9",
      content: (
        <G>
          <Circle fill="#F0F0F0" cx="6.9" cy="6.9" r="6.8" />
          <Path
            fill={fill ? fill : "#FFFFFF"}
            d="M7.6,7.1l2.5-2.5c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L6.9,6.4L4.3,3.8c-0.2-0.2-0.5-0.2-0.7,0
	C3.4,4,3.4,4.4,3.6,4.6l2.5,2.5L3.6,9.6c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l2.5-2.5l2.5,2.5
	c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.5,0-0.7L7.6,7.1z"
          />
        </G>
      ),
    },
    download: {
      width: width || 24,
      height: height || 24,
      viewBox: " 0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="none"
        >
          <Path
            d="M2365 5114 c-363 -37 -636 -113 -930 -258 -563 -277 -989 -727 -1234
-1305 -86 -201 -140 -398 -178 -641 -25 -161 -25 -539 0 -700 38 -243 92 -440
178 -641 245 -578 671 -1028 1234 -1305 256 -126 510 -203 810 -246 118 -17
512 -17 630 0 300 43 554 120 810 246 521 256 915 650 1171 1171 126 256 203
510 246 810 17 118 17 512 0 630 -83 586 -332 1091 -733 1494 -394 393 -884
639 -1449 726 -101 15 -468 28 -555 19z m550 -343 c245 -42 419 -98 640 -206
579 -285 1019 -829 1175 -1453 50 -201 64 -325 64 -552 0 -227 -14 -351 -64
-552 -196 -785 -834 -1423 -1618 -1617 -211 -53 -317 -65 -552 -65 -235 0
-341 12 -552 65 -784 194 -1422 831 -1618 1617 -50 201 -64 325 -64 552 0 227
14 351 64 552 196 786 834 1423 1618 1617 110 28 270 56 372 65 109 10 418 -4
535 -23z"
          />
          <Path
            d="M2495 3826 c-37 -17 -70 -52 -84 -89 -8 -19 -11 -304 -11 -890 l0
-862 -342 342 c-189 188 -356 347 -371 353 -132 49 -259 -84 -203 -213 18 -41
952 -973 989 -987 36 -13 138 -13 174 0 32 12 966 940 989 982 8 15 14 45 14
68 0 115 -117 192 -223 146 -18 -8 -182 -164 -367 -350 l-335 -336 -5 874 c-5
864 -5 875 -26 902 -11 15 -32 37 -46 47 -34 25 -113 32 -153 13z"
          />
        </G>
      ),
    },
    cross: {
      width: width || 24,
      height: height || 24,
      viewBox: " 0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000000"}
          stroke="none"
        >
          <Path
            d="M2370 5113 c-468 -44 -863 -180 -1220 -419 -385 -257 -683 -597 -885
-1009 -168 -340 -248 -661 -262 -1040 -15 -426 73 -832 263 -1214 133 -269
271 -459 488 -677 221 -220 412 -357 681 -489 188 -92 309 -137 500 -185 598
-150 1209 -85 1754 186 274 136 453 266 677 489 217 217 354 407 488 676 309
621 350 1331 115 1993 -47 133 -163 373 -235 486 -211 333 -537 653 -856 843
-413 246 -871 371 -1338 365 -74 0 -151 -3 -170 -5z m440 -373 c1006 -122
1791 -896 1926 -1900 29 -219 14 -536 -35 -757 -204 -909 -960 -1590 -1891
-1703 -122 -15 -398 -12 -526 4 -1005 133 -1782 918 -1904 1926 -13 109 -13
391 0 500 121 1001 893 1787 1890 1925 117 16 426 19 540 5z"
          />
          <Path
            d="M1587 3645 c-92 -32 -141 -124 -117 -217 10 -38 58 -90 423 -455
l412 -413 -412 -412 c-362 -364 -413 -418 -423 -456 -35 -134 88 -257 222
-222 38 10 92 61 456 423 l412 412 413 -412 c363 -362 417 -413 455 -423 134
-35 257 88 222 222 -10 38 -61 92 -423 456 l-412 412 412 413 c362 363 413
417 423 455 35 134 -88 257 -222 222 -38 -10 -92 -61 -455 -423 l-413 -412
-412 412 c-350 349 -419 413 -453 422 -50 14 -61 13 -108 -4z"
          />
        </G>
      ),
    },

    plus_round: {
      width: width || 27,
      height: height || 27,
      viewBox: "0 0 27 27",
      content: (
        <G
          id="Group_2539"
          data-name="Group 2539"
          transform="translate(-173 -723)"
        >
          <G
            id="Ellipse_291"
            data-name="Ellipse 291"
            transform="translate(173 723)"
            fill={fill || "#707070"}
            stroke="#707070"
            // strokeWidth="1"
          >
            <Circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
            {/* <Circle cx="13.5" cy="13.5" r="13" fill="none" /> */}
          </G>
          <Rect
            id="Rectangle_1102"
            data-name="Rectangle 1102"
            width="15"
            height="3"
            rx="1.5"
            transform="translate(179 735)"
            fill="#fff"
          />
          <Rect
            id="Rectangle_1103"
            data-name="Rectangle 1103"
            width="15"
            height="3"
            rx="1.5"
            transform="translate(188 729) rotate(90)"
            fill="#fff"
          />
        </G>
      ),
    },

    earning: {
      width: width || 27,
      height: height || 27,
      viewBox: "0 0 512.000000 512.000000",
      content: (
        <G
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill ? fill : "#000"}
          stroke="none"
        >
          <Path
            d="M2321 5110 c-497 -48 -990 -251 -1376 -565 -114 -92 -294 -274 -384
-387 -229 -287 -417 -675 -495 -1023 -49 -218 -60 -325 -60 -575 0 -250 11
-357 60 -575 79 -355 272 -749 509 -1040 92 -114 274 -294 387 -384 287 -229
675 -417 1023 -495 218 -49 325 -60 575 -60 250 0 357 11 575 60 261 58 603
204 828 353 389 259 688 599 893 1016 125 255 196 484 241 775 24 161 24 539
0 700 -45 291 -116 520 -241 775 -134 272 -283 480 -498 692 -211 209 -404
346 -673 478 -252 124 -486 197 -765 240 -126 19 -468 27 -599 15z m388 -1056
c19 -24 21 -40 21 -165 l0 -139 43 0 c105 0 353 -52 402 -84 66 -43 80 -121
45 -248 -23 -84 -64 -134 -119 -145 -45 -9 -55 -8 -173 23 -114 29 -262 42
-370 32 -142 -13 -232 -77 -245 -176 -8 -59 12 -106 67 -155 63 -57 130 -91
343 -176 98 -40 211 -88 250 -108 209 -104 346 -258 388 -433 20 -83 17 -272
-5 -344 -23 -79 -70 -170 -115 -227 -92 -116 -249 -216 -406 -259 -139 -38
-125 -15 -125 -200 0 -145 -2 -162 -19 -179 -27 -27 -74 -34 -191 -29 -144 5
-144 6 -148 196 -2 98 -7 149 -15 154 -7 4 -30 8 -52 8 -84 1 -304 46 -415 85
-114 40 -142 120 -100 274 45 162 110 191 295 131 144 -46 267 -63 409 -58
144 6 212 28 268 85 49 52 60 76 60 138 1 74 -30 133 -95 183 -70 53 -123 79
-307 147 -347 129 -519 251 -604 428 -112 233 -46 542 152 716 83 73 190 130
311 167 l106 32 5 157 c6 203 -1 195 185 195 132 0 134 0 154 -26z"
          />
        </G>
      ),
    },
    star: {
      width: width || 24,
      height: height || 24,
      viewBox: "0 0 24 24",
      content: (
        <G
          id="Group_2340"
          data-name="Group 2340"
          transform="translate(0.257 -0.117)"
        >
          <Circle
            id="Ellipse_193"
            data-name="Ellipse 193"
            cx="12"
            cy="12"
            r="12"
            transform="translate(-0.257 0.117)"
            fill="#fff"
          />
          <G
            id="Group_1005"
            data-name="Group 1005"
            transform="translate(5.734 6.088)"
          >
            <Path
              id="Path_152"
              data-name="Path 152"
              d="M12.094,960.408a.277.277,0,0,0-.252.189l-1.307,3.979-4.255-.008a.277.277,0,0,0-.264.188.27.27,0,0,0,.1.305l3.448,2.45L8.24,971.485a.27.27,0,0,0,.1.305.282.282,0,0,0,.327,0l3.438-2.464,3.44,2.464a.282.282,0,0,0,.327,0,.27.27,0,0,0,.1-.305l-1.324-3.974,3.448-2.45a.27.27,0,0,0,.1-.305.277.277,0,0,0-.264-.188l-4.256.008L12.369,960.6A.277.277,0,0,0,12.094,960.408Z"
              transform="translate(-6.001 -960.408)"
              fill="#ffc400"
              fillRule="evenodd"
            />
          </G>
        </G>
      ),
    },
    chat: {
      width: width || 30,
      height: height || 30,
      viewBox: "0 0 30 30",
      content: (
        <G
          id="Group_2538"
          data-name="Group 2538"
          transform="translate(-311 -98)"
        >
          <Circle
            id="Ellipse_258"
            data-name="Ellipse 258"
            cx="15"
            cy="15"
            r="15"
            transform="translate(311 98)"
            fill={fill || "#f27253"}
          />
          <Path
            id="Path_2407"
            data-name="Path 2407"
            d="M5.761,13.249a.579.579,0,0,0,.394.547.619.619,0,0,0,.182.029A.574.574,0,0,0,6.8,13.6l1.555-2.074h1.44a5.191,5.191,0,0,0,5.185-5.185V5.185A5.191,5.191,0,0,0,9.793,0H5.185A5.191,5.191,0,0,0,0,5.185V6.337a5.191,5.191,0,0,0,5.185,5.185h.576Z"
            transform="translate(318.511 107.087)"
            fill="#fff"
          />
        </G>
      ),
    },
    profile: {
      width: width || 26.074,
      height: height || 26.074,
      viewBox: "0 0 26.074 26.074",
      content: (
        <G id="noun_profile_2221974" transform="translate(-56 -56)">
          <G
            id="Group_2340"
            data-name="Group 2340"
            transform="translate(56 56)"
          >
            <Path
              id="Path_2405"
              data-name="Path 2405"
              d="M78.255,59.82a13.032,13.032,0,1,0,3.82,9.218A12.985,12.985,0,0,0,78.255,59.82Zm-9.22.194a4.507,4.507,0,1,1-4.5,4.5A4.555,4.555,0,0,1,69.034,60.014Zm7.494,16.514a10.585,10.585,0,0,1-14.981,0,10.816,10.816,0,0,1-1.238-1.484,5.342,5.342,0,0,1,5.1-3.8h7.256a5.342,5.342,0,0,1,5.1,3.8A10.989,10.989,0,0,1,76.528,76.528Z"
              transform="translate(-56 -56)"
              fill={fill || "#707070"}
            />
          </G>
        </G>
      ),
    },
    tickMark: {
      width: width || 13.984,
      height: height || 10.148,
      viewBox: "0 0 13.984 10.148",
      content: (
        <Path
          id="Path_2287"
          data-name="Path 2287"
          d="M0,2.3,2.745,5.834,10.044,0"
          transform="translate(1.606 3.179) rotate(-9)"
          fill="none"
          stroke="#7a9d54"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      ),
    },
    dollar: {
      width: width || 13.492,
      height: height || 24.683,
      viewBox: "0 0 13.492 24.683",
      content: (
        <G id="Group_2592" data-name="Group 2592" transform="translate(0 0)">
          <G
            id="Group_2591"
            data-name="Group 2591"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <Path
              id="Path_8468"
              data-name="Path 8468"
              d="M5.143,24.683V21.81A12.2,12.2,0,0,1,0,20.511L.908,16.97A10.668,10.668,0,0,0,5.93,18.3c1.723,0,2.9-.666,2.9-1.874,0-1.15-.969-1.879-3.206-2.633C2.389,12.7.182,11.191.182,8.258c0-2.661,1.874-4.749,5.113-5.383V0H8.258V2.664a10.931,10.931,0,0,1,4.386,1l-.878,3.42A10.016,10.016,0,0,0,7.41,6.048c-1.965,0-2.6.847-2.6,1.695,0,1,1.059,1.634,3.63,2.6,3.6,1.271,5.052,2.936,5.052,5.66,0,2.691-1.907,4.989-5.385,5.594v3.085Z"
              transform="translate(0 0)"
              fill={fill || "#707070"}
            />
          </G>
        </G>
      ),
    },
    minus: {
      width: width || 25,
      height: height || 5,
      viewBox: "0 0 25 5",
      content: (
        <G
          id="Group_2386"
          data-name="Group 2386"
          transform="translate(-0.021 -6.02)"
        >
          <Rect
            id="Rectangle_1102"
            data-name="Rectangle 1102"
            width="25"
            height="5"
            rx="2.5"
            transform="translate(0.021 6.02)"
          />
        </G>
      ),
    },
    plus_new: {
      width: width || 25,
      height: height || 25,
      viewBox: "0 0 25 25",
      content: (
        <G
          id="Group_2384"
          data-name="Group 2384"
          transform="translate(-0.021 -0.02)"
        >
          <Rect
            id="Rectangle_1102"
            data-name="Rectangle 1102"
            width="25"
            height="5"
            rx="2.5"
            transform="translate(0.021 10.02)"
            fill="#7a9d54"
          />
          <Rect
            id="Rectangle_1103"
            data-name="Rectangle 1103"
            width="25"
            height="5"
            rx="2.5"
            transform="translate(15.021 0.02) rotate(90)"
            fill="#7a9d54"
          />
        </G>
      ),
    },
  };

  return (
    <View pointerEvents="none">
      <Svg
        width={width || graphics[name]?.width}
        height={height || graphics[name]?.height}
        viewBox={viewBox || graphics[name]?.viewBox}
        x={0}
        y={0}
        {...otherProps}
      >
        {graphics[name].content}
      </Svg>
    </View>
  );
};

export default memo(Icon);
