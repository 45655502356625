import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import LargeTextInput from "../largeTextInput";

const OtpField = ({ onChange }) => {
  return (
    <View style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Text style={styles.headerStyle}>
        request an OTP to verify your number
      </Text>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          keyboardType={"numeric"}
          placeholder="enter OTP send to your number"
          style={{ borderBottomColor: "#707070" }}
          onChangeText={(data) => onChange(data)}
        ></LargeTextInput>
      </View>
      <TouchableOpacity>
        <Text
          style={{
            fontSize: 15,
            fontWeight: "bold",
            color: "#828282",
            margin: 30,
            textDecorationLine: "underline",
          }}
        >
          Request a New one
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default OtpField;

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    margin: 25,
    color: "#000",
  },
  wrapperStyle: {
    marginTop: 70,
  },
});
