import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import NewProjectsCard from "../../components/newProjectsCard";
import { setSelectedRequirement } from "../../redux/actions/requirement.action";
import {
  fetchAllActiveProjects,
  setSelectedProject,
} from "../../redux/actions/project.action";
import { connect } from "react-redux";
import { fetchAllProjects } from "../../redux/actions/newProjects.action";

const NewProjectsPage = ({
  navigation,
  user,
  requirements,
  setSelectedRequirement,
  setSelectedProject,
  newProjects,
  projects,
  fetchAllProjects,
  fetchAllActiveProjects,
}) => {
  useEffect(() => {
    if (user.userType == "CREATOR") {
      fetchAllProjects();
    }
  }, [!newProjects]);

  const [newRequirementsArray, setNewRequirementsArray] = useState([]);

  useEffect(() => {
    setNewRequirementsArray(
      user.userType == "PARTNER"
        ? newProjects
          ? newProjects
          : []
        : requirements
        ? requirements
        : []
    );
  }, [requirements, newProjects]);

  const getDate = (id) => {
    let timestamp = id.toString().substring(0, 8);
    let date = new Date(parseInt(timestamp, 16) * 1000).toLocaleString();
    return date;
  };
  const setSelectedProjectAndNavigate = (index) => {
    user.userType == "PARTNER"
      ? setSelectedProject(newRequirementsArray[index])
      : setSelectedRequirement(newRequirementsArray[index]);
    user.userType == "PARTNER"
      ? navigation.navigate("partnerNewProjectDetails")
      : navigation.navigate("creatorNewProjectDetails");
  };
  return (
    <View style={{ flex: 1 }}>
      {user.userType == "CREATOR" ? (
        <View style={{ minHeight: "18%", maxHeight: "18%" }}>
          <Header
            title=""
            subtitle={`Checkout new projects - ${newRequirementsArray.length}`}
            navigation={navigation}
            enableAvatar={true}
            avatarSource={{
              uri: `data:${user.mimType};base64,${user.image}`,
            }}
            backFunction={() => navigation.goBack()}
          ></Header>
        </View>
      ) : (
        <></>
      )}

      <View style={{ flex: 4 }}>
        <View
          style={{
            flexDirection: "row",
            marginLeft: 30,
            marginTop: 20,
          }}
        >
          <Text>Sorted by</Text>
          <Text style={{ fontWeight: "bold" }}> Deadline</Text>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled={true}
          style={{
            flex: 1,
            paddingHorizontal: 15,
            paddingVertical: 10,
            height: "100%",
          }}
        >
          {newRequirementsArray?.map((p, index) => {
            return (
              <NewProjectsCard
                key={index}
                title={p.title ? p.title : ""}
                tags={[
                  ...(p.projectTags ? p.projectTags : []),
                  ...(user.userType == "CREATOR" && p.requiredTeam
                    ? [p.requiredTeam.type]
                    : []),
                ]}
                partner={
                  user.userType == "CREATOR" || user.userType == "EMPLOYEE"
                    ? p.partner
                      ? p.partner.name
                      : null
                    : p.user
                    ? p.user.name
                    : null
                }
                quotedAmount={
                  p.requiredTeam
                    ? p.requiredTeam.quotations
                      ? p.requiredTeam.quotations.find(
                          (u) => u.user._id == user._id
                        )?.budget
                      : ""
                    : ""
                }
                deadLine={p.deadLine}
                dateTime={getDate(p._id)}
                projectId={p.projectId ? p.projectId : ""}
                budget={
                  user.userType == "CREATOR" ? p.requiredTeam.budget : p.budget
                }
                duration={p.timeInDays ? p.timeInDays : ""}
                requiredMember={
                  user.userType == "CREATOR" ? p?.requiredTeam?.type : ""
                }
                onPress={() => setSelectedProjectAndNavigate(index)}
                user={user}
              ></NewProjectsCard>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, newProjects, projects, requirements }) => ({
  user,
  newProjects,
  requirements,
  projects,
});
export default connect(mapStateToProps, {
  setSelectedRequirement,
  setSelectedProject,
  fetchAllProjects,
  fetchAllActiveProjects,
})(NewProjectsPage);
