import React, { useEffect, useState } from "react";
import ProjectHeader from "../../components/projectHeader";
import RoundButton from "../../components/roundButton";
import Avatar from "../../components/avatar";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import { connect } from "react-redux";
import { fetchProjectRole } from "../../redux/actions/projectRole.action";
import { fetchEngagementType } from "../../redux/actions/engagementType.action";
import { fetchProjectClass } from "../../redux/actions/projectClass.action";
import { fetchProjectAudience } from "../../redux/actions/projectAudience.action";
import adjust from "../../components/adjust";

const NewProjectDetails = ({
  navigation,
  user,
  selectedProject,
  engagementType,
  projectClass,
  projectAudience,
  projectRole,
  fetchEngagementType,
  fetchProjectClass,
  fetchProjectRole,
  fetchProjectAudience,
}) => {
  const [effect, setEffect] = useState(false);

  const getDate = (id) => {
    let timestamp = id?.toString().substring(0, 8);
    let date = new Date(parseInt(timestamp, 16) * 1000).toLocaleString();
    return date;
  };
  useEffect(() => {
    if (
      { engagementType, projectClass, projectAudience, projectRole } &&
      !effect
    ) {
      fetchEngagementType();
      fetchProjectClass();
      fetchProjectAudience();
      fetchProjectRole();
      setEffect(true);
    }
  });

  return (
    <View style={{ flex: 1, backgroundColor: "#FAFAFA" }}>
      <ProjectHeader
        title={selectedProject.title}
        projectId={selectedProject.projectId}
        dateTime={getDate(selectedProject._id)}
        tags={selectedProject.projectTags}
        backFunction={() => navigation.goBack()}
        client={selectedProject?.user?.name}
      ></ProjectHeader>
      <View style={{ flex: 1 }}>
        <ScrollView
          nestedScrollEnabled={true}
          style={{
            flex: 1,
            padding: 20,
            height: "100%",
          }}
        >
          <Text style={styles.aboutText}>About the Project:</Text>
          <View
            style={{
              backgroundColor: "#ffffff",
              padding: 20,
              borderRadius: 10,
              flexDirection: "row",
            }}
          >
            <Avatar
              source={
                selectedProject.user.mimType && selectedProject.user.image
                  ? {
                      uri: `data:${selectedProject.user.mimType};base64,${selectedProject.user.image}`,
                    }
                  : null
              }
              style={{ flex: 1, borderWidth: 0, height: 60, width: 60 }}
            ></Avatar>
            <View style={{ flex: 3, paddingLeft: 20, alignSelf: "center" }}>
              <Text style={styles.nameText}>{selectedProject.user.name}</Text>
              <Text style={styles.designationText}>
                {selectedProject.user.designation
                  ? selectedProject.user.designation + ", "
                  : null}
                {selectedProject.user.company
                  ? selectedProject.user.company
                  : null}
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: "#ffffff",
              padding: 20,
              borderRadius: 10,
              marginTop: 20,
            }}
          >
            <Text style={styles.briefHeader}>Client Brief</Text>
            <Text style={styles.briefText}>
              {selectedProject?.user ? selectedProject?.about : null}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: "#ffffff",
              padding: 20,
              borderRadius: 10,
              marginTop: 20,
            }}
          >
            <Text style={styles.briefHeader}>Project Requirements</Text>
            <Text style={styles.briefText}>
              {selectedProject.requirement.map((x) => x.name).join(", ")}
            </Text>
            <Text style={styles.briefHeader}>Project Audience</Text>
            <Text style={styles.briefText}>
              {selectedProject.audience ? selectedProject.audience.name : ""}
            </Text>
            <Text style={styles.briefHeader}>Project Budget</Text>

            <Text style={styles.briefText}>
              {Array.isArray(selectedProject?.budget)
                ? selectedProject?.budget
                  ? selectedProject?.budget[0]
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " to " +
                    selectedProject?.budget[1]
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : null
                : selectedProject?.budget
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Text>
          </View>
        </ScrollView>

        {selectedProject.partner ? (
          selectedProject?.partner?._id == user._id ? (
            <RoundButton
              title={"Continue Setting Up Project"}
              pressFunc={() => navigation.navigate("setupProject")}
              style={styles.proceedButton}
              textStyle={{ fontWeight: "bold", fontSize: adjust(16) }}
            ></RoundButton>
          ) : (
            <RoundButton
              title={"Accept & Start Project"}
              pressFunc={() => navigateAndSetPartner()}
              style={styles.proceedButton}
              textStyle={{ fontWeight: "bold", fontSize: adjust(16) }}
            ></RoundButton>
          )
        ) : (
          <RoundButton
            title={"Accept & Start Project"}
            pressFunc={() => navigateAndSetPartner()}
            style={styles.proceedButton}
            textStyle={{ fontWeight: "bold", fontSize: adjust(16) }}
          ></RoundButton>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  nameText: {
    fontSize: adjust(24),
    fontWeight: "bold",
  },
  designationText: {
    fontSize: adjust(13),
    color: "#202020",
  },
  briefText: {
    fontSize: adjust(15),
    color: "#606060",
    flex: 1,
    lineHeight: 18,
    marginBottom: 20,
  },
  briefHeader: {
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#707070",
    marginBottom: 5,
  },
  budgetText: {
    fontSize: adjust(13),
    fontWeight: "bold",
    flex: 1,
  },
  budget: {
    fontSize: adjust(18),
    fontWeight: "bold",
  },
  aboutText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#707070",
    marginBottom: 15,
  },
  proceedButton: {
    flex: 1,
    backgroundColor: "black",
    minHeight: 50,
    borderRadius: 28,
    marginBottom: 50,
    marginLeft: 20,
    marginRight: 20,
  },
});
const mapStateToProps = ({
  user,
  selectedProject,
  engagementType,
  projectClass,
  projectAudience,
  projectRole,
}) => ({
  user,
  selectedProject,
  engagementType,
  projectClass,
  projectAudience,
  projectRole,
});
export default connect(mapStateToProps, {
  fetchEngagementType,
  fetchProjectClass,
  fetchProjectAudience,
  fetchProjectRole,
})(NewProjectDetails);
