import * as React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import adjust from "../../components/adjust";
import Icon from "../../components/icon";
import RoundButton from "../../components/roundButton";
const signUpSuccess = ({ navigation }) => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon name="tick" style={{ flex: 1, height: 84, width: 84 }}></Icon>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          maxHeight: 100,
        }}
      >
        <Text
          style={{
            flex: 1,
            fontWeight: "bold",
            fontSize: adjust(28),
          }}
        >
          User created successfully!
        </Text>
        <Text
          style={{
            flex: 1,
            fontSize: adjust(16),
          }}
        >
          Use your registered email-id to login
        </Text>
      </View>
      <RoundButton
        title="Login now"
        style={{ backgroundColor: "#000000", marginTop: 20 }}
        pressFunc={() => navigation.navigate("login")}
      ></RoundButton>
    </View>
  );
};
const mapStateToProps = ({ selectedProject }) => ({
  selectedProject,
});
export default connect(mapStateToProps, {})(signUpSuccess);

const styles = StyleSheet.create({});
