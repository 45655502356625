import {
  StyleSheet,
  Text,
  View,
  Image,
  useWindowDimensions,
  TouchableOpacity,
  Pressable,
} from "react-native";
import React from "react";
import { color } from "react-native-reanimated";
import Icon from "../icon";

const WelcomeMessageComponent = ({
  message,
  image,
  textColor,
  backgroundColor,
  link,
  onPress,
  adminTrue,
  enable,
  navigation,
  mimType,
  style,
  minWidth,
  maxWidth,
  leftMargin,
  onPressDelete,
  editable,
}) => {
  const dimension = useWindowDimensions();
  return (
    <TouchableOpacity
      disabled={enable || editable}
      onPress={() => navigation.navigate("welcomeMessageforAdmin")}
      style={[
        {
          borderRadius: 10,
          flexDirection: "row",
          backgroundColor: backgroundColor,
          flex: 1,
          maxWidth:
            minWidth || adminTrue
              ? dimension.width - 40
              : dimension.width / 1.1,
          minWidth:
            maxWidth || adminTrue
              ? dimension.width - 40
              : dimension.width / 1.1,
          marginLeft: leftMargin || 15,
          marginTop: 5,
          padding: 15,
          marginHorizontal: 10,
          alignSelf: "center",
          height: 70,
        },
      ]}
    >
      {link ? (
        <>
          <Text
            style={{
              flexWrap: "wrap",
              color: textColor,
              fontWeight: "bold",
              fontSize: 16,
              flex: 8,
              alignSelf: "center",
            }}
          >
            {message}
            {link ? (
              <Text
                style={{ textDecorationLine: "underline" }}
                onPress={onPress}
              >
                {` ${link}`}
              </Text>
            ) : (
              <></>
            )}
          </Text>
        </>
      ) : (
        <Text style={[styles.messageStyle, { color: textColor }]}>
          {message}
        </Text>
      )}

      <View style={{ flex: 2, justifyContent: "center" }}>
        {image ? (
          <Image
            source={{ uri: `data:${mimType};base64,${image}` }}
            style={{ height: 40, width: 40, alignSelf: "center" }}
          ></Image>
        ) : (
          <></>
        )}
      </View>
      {editable && (
        <Pressable onPress={onPressDelete}>
          <Icon name={"delete"} />
        </Pressable>
      )}
    </TouchableOpacity>
  );
};

export default WelcomeMessageComponent;

const styles = StyleSheet.create({
  container: {
    padding: 10,
    margin: 10,
    borderRadius: 8,
    flexDirection: "row",
    backgroundColor: "#F27253",
    flexWrap: "wrap",
    width: "85%",
  },
  textStyle: {
    flex: 8,
    flexWrap: "wrap",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 16,
  },
  messageStyle: {
    flexWrap: "wrap",
    fontWeight: "bold",
    fontSize: 16,
    flex: 8,
    alignSelf: "center",
  },
});
