import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Header from "../../../components/header";
import LargeTextInput from "../../../components/largeTextInput";
import RoundButton from "../../../components/roundButton";
import { useState } from "react";
import { inviteRequest } from "../../../redux/actions/user.action";
import { connect } from "react-redux";
import { addError } from "../../../redux/actions/toast.action";

const AddUserByAdmin = ({ navigation, route, inviteRequest, addError }) => {
  let data = JSON.parse(route.params.data);
  const [creatorData, setCreatorData] = useState({ name: "", email: "" });

  const invite = () => {
    if (creatorData.name && creatorData.email) {
      inviteRequest(creatorData, navigateOnSuccess);
    } else {
      addError("Please enter valid info!", 3000);
    }
  };
  const navigateOnSuccess = () =>
    navigation.goBack() || navigation.navigate("Home");
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          enableAvatar={false}
          title={`add a ${data.userType}`}
          subtitle={`Enter ${data.userType} details`}
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          navigation={navigation}
        ></Header>
      </View>
      <Text style={styles.titleStyle}>
        {`add details of the ${data.userType} to start with`}
      </Text>
      <View style={{ margin: 30 }}>
        <LargeTextInput
          onChangeText={(text) =>
            setCreatorData({ ...creatorData, name: text })
          }
          style={{ marginHorizontal: 0 }}
          marginLeft={1}
          width={"100%"}
          placeholder={`enter ${data.userType} first name`}
        ></LargeTextInput>
      </View>
      <View style={{ margin: 30 }}>
        <LargeTextInput
          keyboardType={"email-address"}
          onChangeText={(text) =>
            setCreatorData({ ...creatorData, email: text })
          }
          style={{ marginHorizontal: 0 }}
          width={"100%"}
          placeholder={`enter ${data.userType} email-ID`}
        ></LargeTextInput>
      </View>
      <Text style={styles.noteStyle}>Note</Text>
      <Text style={styles.detailsStyle}>
        {`At this point, we have all the information that is needed. Your new${data.userType} will have received a text message on his/her phone with a link to start with ONEDESIGN. Rest everything will be done by him/her.`}
      </Text>
      <View
        style={{
          flexDirection: "row",
          margin: 25,
          // position: "absolute",
          // bottom: 0,
        }}
      >
        <RoundButton
          title="Invite"
          pressFunc={() => invite()}
          style={{
            alignSelf: "center",
            backgroundColor: "#000000",
            marginBottom: 10,
          }}
        ></RoundButton>
        <RoundButton
          pressFunc={() => navigation.goBack()}
          title="Discard"
          style={{
            alignSelf: "center",
            backgroundColor: "#EB4141",
            marginBottom: 10,
            marginLeft: 15,
          }}
        ></RoundButton>
      </View>
    </View>
  );
};

export default connect(null, { inviteRequest, addError })(AddUserByAdmin);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
  titleStyle: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#000",
    margin: 30,
    //marginTop: 50,
    // marginBottom: 60,
  },
  noteStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#828282",
    marginLeft: 35,
  },
  detailsStyle: {
    fontSize: 11,
    color: "#606060",
    marginHorizontal: 30,
  },
});
