import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  useWindowDimensions,
} from "react-native";
import React from "react";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { fetchSalesPerson } from "../../../redux/actions/sales-person.action";
import UserProfileComponent from "../../../components/userProfileComponent/userProfileComponent";
import Header from "../../../components/header";

const SalesPersonScreen = ({
  navigation,
  user,
  fetchSalesPerson,
  salesPersons,
}) => {
  useFocusEffect(
    React.useCallback(() => {
      fetchSalesPerson();
    }, [])
  );
  const dimension = useWindowDimensions();
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          title={"salespersons"}
          subtitle="all your salespersons"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <FlatList
        numColumns={2}
        scrollEnabled
        nestedScrollEnabled
        data={salesPersons}
        keyExtractor={(item) => item._id}
        style={{ height: "100%", flexWrap: "wrap" }}
        contentContainerStyle={styles.card_Container}
        renderItem={({ item }) => (
          <View style={{ marginTop: 5 }}>
            <UserProfileComponent
              onPress={() =>
                navigation.navigate("project-list", {
                  type: "client",
                  _id: item._id,
                })
              }
              backgroundColor={"#000"}
              imageUri={
                item.image
                  ? { uri: "data:image/jpeg;base64," + item.image }
                  : null
              }
              name={`${item.fName} ${item.lName || ""}`}
              //   description={`${item.totalProject.toString() || 0} projects`}
            ></UserProfileComponent>
          </View>
        )}
      />
    </View>
  );
};

const mapStateToProps = ({ user, salesPersons }) => ({ user, salesPersons });
export default connect(mapStateToProps, { fetchSalesPerson })(
  SalesPersonScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  card_Container: {
    // flex: 2,
    flexDirection: "column",
    margin: 10,
  },
});
