import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
} from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import DashboardCardWithArrowAndIcon from "../../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import ContainerWithDifferentBg from "../../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import TeamCompositionModal from "../../admin/teamCompositionModal";
import { primaryColor } from "../../../components/utils";
import {
  fetchDiscussionsByProject,
  createDiscussion,
} from "../../../redux/actions/discussion.action";
import { addError } from "../../../redux/actions/toast.action";
import { setSelectedProject } from "../../../redux/actions/project.action";

const DiscussionGroups = ({
  user,
  navigation,
  fetchDiscussionsByProject,
  discussionsByProject,
  route,
  createDiscussion,
  addError,
  setSelectedProject,
  unreadCount,
  selectedProject,
}) => {
  const [teamModal, setTeamModal] = useState(false);
  useEffect(() => {
    if (route.params) fetchDiscussionsByProject(route.params.project);
  }, [route.params]);

  const setSelectedProjectAndNavigateToChat = (item) => {
    if (!(selectedProject && selectedProject._id)) {
      let project = { ...discussionsByProject };
      delete project["discussions"];
      setSelectedProject(project);
    }
    navigation.navigate("chat", {
      discussion: item._id,
      discussionTitle: item.name,
    });
  };

  const create = (obj) => {
    if (obj.name && obj.name != "" && obj.users.length > 0) {
      let discuss = {
        ...obj,
        project: discussionsByProject._id,
      };
      createDiscussion(discuss);
      setTeamModal(false);
    } else {
      addError("Please provide valid information!", 3000);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          disableBackButton={false}
          title="discussion boards"
          subtitle="All your discussions in one place"
          enableAvatar={true}
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ margin: 20 }}>
        <ContainerWithDifferentBg
          titleFontSize={20}
          content={discussionsByProject.title}
          backgroundColor={"#000"}
        ></ContainerWithDifferentBg>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: 10,
          }}
        >
          <Text style={styles.textStyle}>All Discussion Groups</Text>
          <TouchableOpacity onPress={() => setTeamModal(true)}>
            <Text
              style={[styles.plusStyle, { color: primaryColor(user.userType) }]}
            >
              +
            </Text>
          </TouchableOpacity>
        </View>
        <FlatList
          contentContainerStyle={{ paddingBottom: 300 }}
          scrollEnabled
          nestedScrollEnabled
          keyExtractor={(item, index) => item._id}
          data={discussionsByProject.discussions}
          renderItem={({ item, index }) => (
            <DashboardCardWithArrowAndIcon
              subtitleStyle={{ right: 7 }}
              titleContainerStyle={{ right: 7 }}
              descriptionContainerStyle={{ right: 7 }}
              value={""}
              onPressCard={() => setSelectedProjectAndNavigateToChat(item)}
              arrowColor={"#000"}
              borderWidth={4}
              borderColor={primaryColor(user.userType)}
              descriptionFontWeight={"bold"}
              descriptionFontSize={17}
              descriptionTextColor={primaryColor(user.userType)}
              description={`${
                unreadCount
                  .find((x) => x._id === discussionsByProject._id)
                  ?.discussion?.find((x) => x.id === item._id)?.count || 0
              } new messages`}
              subDetails={item.users
                ?.map(
                  (x) =>
                    discussionsByProject.team.find((y) => y.user._id === x)
                      ?.user?.fName
                )
                .join(", ")}
              titleColor="#000"
              backgroundColor="#FFF"
              title={item.name}
              icon={false}
            ></DashboardCardWithArrowAndIcon>
          )}
        />

        <TeamCompositionModal
          team={discussionsByProject.team || []}
          onPressCross={() => setTeamModal(false)}
          isModalVisible={teamModal}
          user={user}
          create={(value) => create(value)}
        ></TeamCompositionModal>
      </View>
    </View>
  );
};
const mapStateToProps = ({
  user,
  discussionsByProject,
  unreadCount,
  selectedProject,
}) => ({ user, discussionsByProject, unreadCount, selectedProject });
export default connect(mapStateToProps, {
  fetchDiscussionsByProject,
  createDiscussion,
  addError,
  setSelectedProject,
})(DiscussionGroups);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: "18%",
    maxHeight: "18%",
  },
  textStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#828282",
    alignSelf: "center",
    marginLeft: 10,
  },
  plusStyle: {
    fontSize: 30,
    fontWeight: "bold",

    marginRight: 20,
  },
});
