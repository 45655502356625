import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Icon from "../icon";
import adjust from "../adjust";

const UserProfileComponent = ({
  onPress,
  name,
  title,
  color,
  imageUri,
  description,
  userSelection,
  backgroundColor,
  selected,
  gender,
}) => {
  const dimension = useWindowDimensions();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: dimension.width / 2 - 20,
        borderRadius: 10,
        marginHorizontal: 5,
        flexDirection: "column",
        backgroundColor: backgroundColor ? backgroundColor : color,
        paddingBottom: 5,
        marginTop: 5,
      }}
    >
      <Image
        style={styles.card_image}
        source={
          imageUri
            ? imageUri
            : gender === "Male"
            ? require("../../assets/avatar/avatar.png")
            : require("../../assets/avatar/avatar-female.png")
        }
      ></Image>
      {selected ? (
        <View
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: "#F27253",
            borderRadius: 50,
            justifyContent: "center",
          }}
        >
          <Icon style={{ alignSelf: "center" }} name={"tick"}></Icon>
        </View>
      ) : (
        <></>
      )}
      <View
        style={{
          paddingLeft: 10,
          // marginTop: 7,
          backgroundColor: "#000",
          minHeight: 59,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            marginTop: 3,
            fontWeight: "bold",
            color: "#fff",
            alignSelf: "flex-start",
            fontSize: adjust(16),
          }}
        >
          {name}
        </Text>
        {description ? (
          <Text
            style={{
              alignSelf: "flex-start",
              color: "#fff",
              fontSize: adjust(13),
            }}
          >
            {description}
          </Text>
        ) : (
          <></>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default UserProfileComponent;

const styles = StyleSheet.create({
  container: { flex: 1 },

  card_image: {
    width: "100%",
    height: 106,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
});
