import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/Client/dashboard";
import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";
import NewProjectDetails from "../../screens/common/newProjectDetails";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import ActiveProjectSummary from "../../screens/creator/activeProjectSummary/activeProjectSummary";
import ProjectStatus from "../../screens/Client/projectStatus";
import ProjectPayment from "../../screens/creator/activeProjectDetails/projectPayment";
import login from "../../screens/login";
import ChatWithUs from "../../screens/common/chat-with-us";
import Payments from "../../screens/common/user-payment-screen";
const window = Dimensions.get("window");

const ClientStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({ user }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="accountant"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

export const DashboardStack = () => {
  return (
    <ClientStack.Navigator name="clientNew" initialRouteName={"dashboard"}>
      <ClientStack.Screen
        name="dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="newProjectDetails"
        component={NewProjectDetails}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="activeProjectDetails"
        component={ActiveProjectDetails}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="activeProjectSummary"
        component={ActiveProjectSummary}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="ProjectStatus"
        component={ProjectStatus}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="login"
        component={login}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
      <ClientStack.Screen
        options={{ headerShown: false }}
        name="payment-details"
        component={Payments}
      />
    </ClientStack.Navigator>
  );
};
