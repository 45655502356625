import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  Text,
  View,
  ScrollView,
  StyleSheet,
} from "react-native";
import adjust from "../../../../components/adjust";
import PhaseComponent from "./phaseComponent";
import Icon from "../../../../components/icon";

function Payment({ selectedProject, onPaymentDataChange, validate }) {
  let finalAmount;
  let advanceAmount;
  let stepAmount;
  const [totalAmount, setTotalAmount] = useState(0);
  const [phases, setPhases] = useState([]);

  useEffect(() => {
    setPhases(
      selectedProject.paymentPhases
        ? selectedProject.paymentPhases.length >= 1
          ? selectedProject.paymentPhases
          : [
              {
                description: "",
                amount: [
                  { type: "Final", amount: "" },
                  { type: "Advance", amount: "" },
                ],
                steps: [{ name: "", description: "", amount: "" }],
              },
            ]
        : null
    );
  }, [selectedProject]);

  const removeFromPhases = (index) => {
    setPhases(phases.filter((x, i) => i != index));
  };

  const sum = () => {
    phases?.forEach((e) => {
      finalAmount = e.amount.find(({ type }) => type == "Final")?.amount;
      advanceAmount = e.amount.find(({ type }) => type == "Advance")?.amount;
      stepAmount = e.steps.reduce((a, { amount }) => a + amount, 0);
    });
  };

  return (
    <ScrollView
      scrollEnabled={true}
      nestedScrollEnabled={true}
      style={{
        flex: 1,
        height: "100%",
        paddingVertical: 20,
        marginHorizontal: 20,
      }}
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.container}>
        <Text style={styles.paymentText}>Payment Type</Text>
        <Text style={styles.paymentTypeText}>
          {selectedProject?.engagement?.name}
        </Text>
      </View>
      <View
        style={[
          styles.container,
          { marginTop: 10, backgroundColor: "#FFD500" },
        ]}
      >
        <Text style={styles.paymentText}>Discussed Budget</Text>
        <Text style={styles.paymentTypeText}>
          {selectedProject?.discussedBudget
            ? selectedProject?.discussedBudget
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0}
        </Text>
      </View>

      {phases?.map((input, index) => (
        <View key={index} style={{ marginBottom: 15 }}>
          <PhaseComponent
            phaseNo={index + 1}
            phaseData={input}
            onPhaseDataChange={(phase) => {
              setPhases(phases?.map((p, ind) => (ind == index ? phase : p)));
              onPaymentDataChange({
                ...selectedProject,
                paymentPhases: phases?.map((p, ind) =>
                  ind == index ? phase : p
                ),
              });
              sum();
            }}
            validateNow={true}
            validated={(e) => validate(e)}
            removeOnPress={() => removeFromPhases(index)}
          ></PhaseComponent>
        </View>
      ))}
      {selectedProject.engagement?.name == "Phase Wise Payment" ? (
        <TouchableOpacity
          style={[
            {
              flex: 1,
              alignSelf: "center",
            },
            styles.phasePlusContainer,
          ]}
          onPress={() => {
            setPhases([
              ...phases,
              {
                description: "",
                amount: [
                  { type: "Final", amount: "" },
                  { type: "Advance", amount: "" },
                ],
                steps: [{ name: "", description: "", amount: "" }],
              },
            ]);
          }}
        >
          <Icon height={20} width={20} name="plus" fill="#FFF" />
        </TouchableOpacity>
      ) : (
        <></>
      )}
      {/* <View style={styles.container}>
        <Text style={styles.paymentText}>Payment Type</Text>
        <Text style={styles.paymentTypeText}>
          {selectedProject?.engagement?.name}
        </Text>
      </View>
      <View
        style={[
          styles.container,
          { marginTop: 10, backgroundColor: "#FFD500" },
        ]}
      >
        <Text style={styles.paymentText}>Discussed Budget</Text>
        <Text style={styles.paymentTypeText}>
          {selectedProject?.discussedBudget
            ? selectedProject?.discussedBudget
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0}
        </Text>
      </View>
      {phases?.map((input, index) => (
        <View key={index} style={{ marginBottom: 15 }}>
          <PhaseComponent
            phaseNo={index + 1}
            phaseData={input}
            onPhaseDataChange={(phase) => {
              setPhases(phases?.map((p, ind) => (ind == index ? phase : p)));
              onPaymentDataChange({
                ...selectedProject,
                paymentPhases: phases?.map((p, ind) =>
                  ind == index ? phase : p
                ),
              });
              sum();
            }}
            validateNow={true}
            validated={(e) => validate(e)}
            removeOnPress={() => removeFromPhases(index)}
          ></PhaseComponent>
        </View>
      ))}

      {selectedProject.engagement?.name == "Phase Wise Payment" ? (
        <TouchableOpacity
          style={[
            {
              flex: 1,
              alignSelf: "center",
            },
            styles.phasePlusContainer,
          ]}
          onPress={() => {
            setPhases([
              ...phases,
              {
                description: "",
                amount: [
                  { type: "Final", amount: "" },
                  { type: "Advance", amount: "" },
                ],
                steps: [{ name: "", description: "", amount: "" }],
              },
            ]);
          }}
        >
          <Icon height={20} width={20} name="plus" fill="#FFF" /> */}

      {/* <View style={styles.phasePlusContainer}>
            <Text
              style={{
                fontSize: adjust(40),
                fontWeight: "bold",
                color: "#FFFFFF",
                marginBottom: 7,
              }}
            >
              +
            </Text>
          </View> */}
      {/* </TouchableOpacity>
      ) : (
        <></>
      )} */}
    </ScrollView>
  );
}

export default Payment;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    maxHeight: 90,
    minHeight: 90,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "space-around",
  },

  paymentText: {
    fontWeight: "bold",
    fontSize: adjust(15),
    color: "#000",
    minHeight: 30,
    marginTop: 10,
    marginLeft: 10,
  },

  paymentTypeText: {
    fontWeight: "bold",
    fontSize: adjust(21),
    minHeight: 35,
    marginLeft: 10,
  },
  phasePlusContainer: {
    backgroundColor: "#FFD500",
    borderRadius: 50,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 55,
    minWidth: 45,
    maxHeight: 55,
    maxWidth: 45,
  },
});
