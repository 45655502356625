import axios from "axios";
import React from "react";
import { useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ScrollView,
  TextInput,
  ImageBackground,
} from "react-native";
import { connect } from "react-redux";
import adjust from "../../components/adjust";
import LargeTextInput from "../../components/largeTextInput";
import Header from "../../components/header";
import RoundButton from "../../components/roundButton";
import config from "../../config/config";
import { addError, addInfo } from "../../redux/actions/toast.action";

// const [focusBorderColor, setFocusBorderColor] = useState({
//   code: "#FFFFFF",
//   newPassword: "#FFFFFF",
//   confirmPassword: "#FFFFFF",
// });

// client.interceptors.request.use((req) => {
//   req.baseURL = config.baseUrl;
//   req.timeout = 60000;
//   if (user && user.token) {
//     const token = user.token;
//     req.headers.common.Authorization = `Bearer ${token}`;
//   }
//   return req;
// });

const forgotPassword = ({ addError, addInfo, navigation }) => {
  const [sendCode, setSendCode] = useState(true);
  const [userId, setUserId] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [match, isMatch] = useState(false);

  const [focusBorderColor, setFocusBorderColor] = useState({
    code: "#FFFFFF",
    newPassword: "#FFFFFF",
    confirmPassword: "#FFFFFF",
  });

  const client = axios.create({
    baseURL: config.baseUrl,
    responseType: "json",
  });

  const sendCodeToEmail = () => {
    client.get(`/api/user/password/forgot/${userId}`).then(
      (result) => {
        addInfo(result.data.message, 3000);
        setSendCode(false);
      },
      (error) => {
        addError(error.response.data.message, 3000);
      }
    );
  };

  const checkPassword = () => {
    if (code && code.length === 8) {
      setFocusBorderColor({
        ...focusBorderColor,
        code: "#41B000",
      });
      client
        .get(`/api/user/password/code-match?email=${userId}&code=${code}`)
        .then(
          (result) => {
            addInfo("Code matched!", 3000);
            isMatch(true);
          },
          (error) => {
            addError(error.response.data.message, 3000);
          }
        );
    } else {
      setFocusBorderColor({
        ...focusBorderColor,
        code: "#F33A3A",
      });
    }
  };

  const updatePassword = () => {
    client
      .get(`/api/user/password/reset?email=${userId}&password=${newPassword}`)
      .then(
        (result) => {
          addInfo("Password updated successfully!", 3000);
          navigation.navigate("login");
        },
        (error) => {
          addError(error.message, 3000);
          console.error(error);
        }
      );
  };
  return sendCode ? (
    <View style={{ flex: 1 }}>
      <ImageBackground
        style={{
          flex: 1,
          backgroundColor: "#FAFAFA",
          maxHeight: 400,
        }}
        resizeMode="cover"
        source={require("../../assets/login-topbar.png")}
      ></ImageBackground>
      <View style={{ marginTop: 50, flex: 1 }}>
        <LargeTextInput
          placeholder="Enter User ID"
          onChangeText={(e) => setUserId(e)}
          style={{
            marginBottom: 10,
            borderBottomColor: "#828282",
          }}
        ></LargeTextInput>
        <RoundButton
          title="Send Code"
          style={{
            backgroundColor: "#000000",
            width: 150,
            marginTop: 30,
            height: "100%",
            alignSelf: "center",
          }}
          pressFunc={() => sendCodeToEmail()}
        ></RoundButton>
      </View>
    </View>
  ) : (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          backgroundColor: "#FFFFFF",
          flex: 1,
          flexDirection: "column",
          marginBottom: 20,
        }}
      >
        <Header
          title="Change Password"
          subtitle=""
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      {/* <Text
        style={{
          alignSelf: "center",
          fontSize: 30,
          marginTop: 50,
          fontWeight: "bold",
        }}
      >
        Change Password
      </Text> */}
      <View style={{ paddingHorizontal: 20, flex: 4 }}>
        <View
          style={[
            styles.textContainer,
            {
              borderColor: focusBorderColor.code,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              position: "relative",
              height: 100,
            },
          ]}
        >
          <Text style={styles.titleText}>Code</Text>
          <TextInput
            placeholder={"Enter the eight digit Code!"}
            placeholderTextColor="#9C9C9C"
            multiline={false}
            style={[
              styles.textInput,
              { backgroundColor: "#FFFFFF", padding: 7 },
            ]}
            onFocus={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                code: "#FFD500",
              });
            }}
            onBlur={() => checkPassword()}
            onChangeText={(e) => setCode(e)}
            value={`${code}`}
            disabled={match}
          ></TextInput>
        </View>
        <View
          style={[
            styles.textContainer,
            {
              borderColor: focusBorderColor.newPassword,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              position: "relative",
              height: 100,
            },
          ]}
        >
          <Text style={styles.titleText}>New Password</Text>
          <TextInput
            secureTextEntry={true}
            placeholder={"Enter new password"}
            placeholderTextColor="#9C9C9C"
            multiline={false}
            style={[
              styles.textInput,
              { backgroundColor: "#FFFFFF", padding: 7 },
            ]}
            onFocus={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                newPassword: "#FFD500",
              });
            }}
            onBlur={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                newPassword: "#41B000",
              });
            }}
            onChangeText={(e) => setNewPassword(e)}
            value={`${newPassword}`}
            disabled={!match}
          ></TextInput>
        </View>
        <View
          style={[
            styles.textContainer,
            {
              borderColor: focusBorderColor.confirmPassword,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              position: "relative",
              height: 100,
            },
          ]}
        >
          <Text style={styles.titleText}>Confirm Password</Text>
          <TextInput
            secureTextEntry={true}
            placeholder={"Confirm the password again"}
            placeholderTextColor="#9C9C9C"
            multiline={false}
            style={[
              styles.textInput,
              { backgroundColor: "#FFFFFF", padding: 7 },
            ]}
            onFocus={() => {
              setFocusBorderColor({
                ...focusBorderColor,
                confirmPassword: "#FFD500",
              });
            }}
            onChangeText={(e) => {
              setFocusBorderColor({
                ...focusBorderColor,
                confirmPassword: e === newPassword ? "#41B000" : "#F33A3A",
              });
              setConfirmPassword(e);
            }}
            value={`${confirmPassword}`}
            disabled={!match}
          ></TextInput>
        </View>
        <RoundButton
          pressFunc={() => updatePassword()}
          style={{
            marginTop: 50,
            alignSelf: "center",
            height: "100%",
            width: "70%",
          }}
          title="Update Password"
        ></RoundButton>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  titleText: {
    fontSize: adjust(18),
    fontWeight: "bold",
    color: "#000",
  },
  textContainer: {
    minHeight: 80,
    maxHeight: 120,
    padding: 15,
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: 15,
  },
  textInput: {
    fontSize: adjust(13),
    height: 40,
    marginTop: 5,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
});

export default connect(null, { addError, addInfo })(forgotPassword);
