import React, { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import ProjectTextInput from "./projectTextInput";
import { connect } from "react-redux";
function InitiateProject({
  selectedProject,
  onInitiateDataChange,
  engagementType,
  projectClass,
  projectAudience,
  validate,
}) {
  const [counter, setCounter] = useState(1);
  const [engagement, setEngagement] = useState(
    // selectedProject.engagement
    //   ? engagementType.find((e) => e._id == selectedProject.engagement._id)
    //   : ""
    engagementType.find((x) => x.name === "Phase Wise Payment")
  );
  const [clazz, setClazz] = useState(
    selectedProject.class
      ? projectClass
        ? projectClass.find((e) => e._id == selectedProject.class._id)
        : ""
      : ""
  );
  const [aud, setAud] = useState(
    selectedProject.audience
      ? projectAudience
        ? projectAudience.find((e) => e._id == selectedProject.audience._id)
        : ""
      : ""
  );
  const [mood, setMood] = useState(
    selectedProject.mood ? selectedProject.mood : ""
  );
  const [deadLine, setDeadLine] = useState(
    selectedProject.deadLine ? selectedProject.deadLine : ""
  );
  const [projectAbout, setProjectAbout] = useState(
    selectedProject.projectAbout ? selectedProject.projectAbout : ""
  );
  const [clientAbout, setClientAbout] = useState(
    selectedProject.clientAbout ? selectedProject.clientAbout : ""
  );
  const [discussedBudget, setDiscussedBudget] = useState(
    selectedProject.discussedBudget ? selectedProject.discussedBudget : null
  );
  const [projectBrief, setProjectBrief] = useState(
    selectedProject.projectBrief ? selectedProject.projectBrief : ""
  );
  const [clientPsychology, setclientPsychology] = useState(
    selectedProject.clientPsychology ? selectedProject.clientPsychology : ""
  );
  const [preferadPayment, setpreferadPayment] = useState(
    selectedProject.preferadPayment ? selectedProject.preferadPayment : ""
  );
  useEffect(() => {
    setDiscussedBudget(
      selectedProject.discussedBudget ? selectedProject.discussedBudget : 0
    );
  }, [selectedProject]);

  useEffect(() => {
    onInitiateDataChange({
      class: clazz,
      engagement: engagement,
      audience: aud,
      mood,
      deadLine,
      projectAbout,
      clientAbout,
      discussedBudget,
    });
  }, [
    clazz,
    engagement,
    aud,
    mood,
    deadLine,
    projectAbout,
    clientAbout,
    discussedBudget,
  ]);

  useEffect(() => {
    if (
      discussedBudgetValid &&
      clazzValid &&
      audValid &&
      moodValid &&
      deadLineValid &&
      projectAboutValid &&
      clientAboutValid &&
      engagementTypeValid
    ) {
      validate(true);
    } else {
      validate(false);
    }
  });
  const [discussedBudgetValid, setDiscussedBudgetValid] = useState(false);
  const [clazzValid, setClazzValid] = useState(false);
  const [audValid, setAudValid] = useState(false);
  const [moodValid, setMoodValid] = useState(false);
  const [deadLineValid, setdeadLineValid] = useState(false);
  const [projectAboutValid, setProjectAboutValid] = useState(false);
  const [clientAboutValid, setClientAboutValid] = useState(false);
  const [engagementTypeValid, setEngagementValid] = useState(false);
  return (
    <ScrollView
      nestedScrollEnabled={true}
      style={{
        flex: 1,
        padding: 20,
        height: "100%",
      }}
    >
      <ProjectTextInput
        title="Final Discussed Budget "
        placeholder={`Please enter the final budget as decided`}
        textType={true}
        data={`${discussedBudget || ""}`}
        onDataChange={setDiscussedBudget}
        required={true}
        validationType="number"
        keyboardType="numeric"
        multiline={false}
        isValid={(v) => {
          setDiscussedBudgetValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>
      <ProjectTextInput
        title="Project Category"
        placeholder="Select from dropdown"
        renderData={projectClass ? projectClass : []}
        onSelectionChange={setClazz}
        selectionValue={clazz}
        required={true}
        isValid={(v) => {
          setClazzValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>
      <ProjectTextInput
        numberOfLines={1}
        title="Project Mood"
        placeholder="Enter mood with commas For example: Vibrant"
        data={mood}
        textType={true}
        onDataChange={setMood}
        required={true}
        isValid={(v) => {
          setMoodValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>
      <ProjectTextInput
        title="Project Audience"
        placeholder="Select from dropdown"
        renderData={projectAudience ? projectAudience : []}
        onSelectionChange={(e) => setAud(e)}
        selectionValue={aud}
        required={true}
        isValid={(v) => {
          setAudValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>
      <ProjectTextInput
        title="Project Deadline"
        placeholder="Select from dropdown"
        calendar={true}
        onDataChange={setDeadLine}
        selectionValue={deadLine}
        required={true}
        isValid={(v) => {
          setdeadLineValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>
      <ProjectTextInput
        title="About the Project Scope"
        placeholder="Give a brief on the Understood Project Scope"
        textType={true}
        data={projectAbout}
        onDataChange={setProjectAbout}
        required={true}
        isValid={(v) => {
          setProjectAboutValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>
      <ProjectTextInput
        title="About the Client"
        placeholder="Tell us about the behaviour of the client"
        textType={true}
        data={clientAbout}
        onDataChange={setClientAbout}
        required={true}
        isValid={(v) => {
          setClientAboutValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>

      <ProjectTextInput
        title="Client's Payment Schedule"
        placeholder="Select from dropdown"
        renderData={engagementType ? engagementType : []}
        onSelectionChange={setEngagement}
        selectionValue={engagement}
        required={true}
        isValid={(v) => {
          setEngagementValid(v);
        }}
        validateNow={true}
      ></ProjectTextInput>
    </ScrollView>
  );
}

const mapStateToProps = ({
  user,
  engagementType,
  projectClass,
  projectAudience,
}) => ({
  user,
  engagementType,
  projectClass,
  projectAudience,
});
export default connect(mapStateToProps, {})(InitiateProject);
