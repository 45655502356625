import React, { useState } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Pressable,
  StyleSheet,
} from "react-native";
import Icon from "../../components/icon";
import ProfileModal from "../../components/profileModal";

const ProfileComponent = ({
  data,
  disableButtons,
  selected,
  quotation,
  navigation,
  creatorType,
  removeOnPress,
  selectOnPress,
  onPress,
}) => {
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const handleCallback = (childData) => {
    setProfileModalVisible(childData);
  };

  return (
    <Pressable
      onPress={onPress}
      style={{
        width: "42%",
        marginLeft: 20,
        marginTop: 20,
        borderRadius: 10,
        // borderWidth: 0.7,
        // borderColor: "#C0C0C0",
        paddingBottom: 7,
        backgroundColor: "#FFF",
      }}
    >
      <View
        style={{
          height: 100,
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Image
          style={styles.imageStyle}
          source={
            data.image || data.mimType
              ? {
                  uri: `data:${data.mimType ? data.mimType : ""};base64,${
                    data.image ? data.image : ""
                  }`,
                }
              : require("../../assets/avatar/avatar.png")
          }
        ></Image>
        <View
          style={{
            justifyContent: "center",
            height: 20,
            width: 20,
            borderRadius: 50,
            backgroundColor: "#FFC400",
            position: "absolute",
            right: 7,
            top: 7,
          }}
        >
          <Icon
            fill={"#FFF"}
            style={{ alignSelf: "center" }}
            name={"starIcon"}
          ></Icon>
        </View>
      </View>
      <Text
        style={{
          alignSelf: "center",
          fontWeight: "bold",
          fontSize: 15,
          color: "#000",
          padding: 3,
        }}
      >
        {data ? data.name : ""}
      </Text>
      <Text
        style={{
          alignSelf: "center",
          fontSize: 13,
          color: "#A0A0A0",
        }}
      >
        {data ? data.designation : ""}
      </Text>
      {/* <ProfileModal
        disableButtons={disableButtons}
        creatorData={data ? data : {}}
        renderData={true}
        showModal={profileModalVisible}
        onSelection={handleCallback}
        quotation={quotation}
        navigation={navigation}
        selected={selected}
        creatorType={creatorType}
        pressFunc={selected ? removeOnPress : selectOnPress}
      ></ProfileModal> */}
    </Pressable>
  );
};
const styles = StyleSheet.create({
  imageStyle: {
    height: 100,
    width: "100%",
    alignSelf: "center",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
});
export default ProfileComponent;
