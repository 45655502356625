import React, { useEffect, useState, Dimensions } from "react";
import { createStackNavigator } from "@react-navigation/stack";

import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import NewClientSetupScreen from "../../screens/Sales/clientSetupScreen/newClientSetupScreen";
import SalesNewProjectsScreen from "../../screens/Sales/salesNewProjectScreen";
import VerificationOfUsers from "../../screens/common/verificationOfUsers";
import NewSalesScreen from "../../screens/Sales/clientSetupScreen/newSalesScreen";
import CreateProjectBySales from "../../screens/Sales/clientSetupScreen/create-project-by-sales";
import SalesBudgetScreen from "../../screens/Sales/salesBudgetScreen";
import ServiceSelection from "../../screens/Client/new_sales_setup/service-selection";
import PartnerSelection from "../../screens/Client/new_sales_setup/partner-selection";
import FinalStep from "../../screens/Client/new_sales_setup/final-step";
import ScheduleMeeting from "../../screens/common/meeting/scheduleMeeting";
import ScheduleDetails from "../../screens/common/meeting/scheduleDetails";

const SalesStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({}) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="employee"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <SalesStack.Navigator
      initialRouteName="SalesNewProjectsScreen"
      name="employee"
    >
      <SalesStack.Screen
        name="SalesNewProjectsScreen"
        component={SalesNewProjectsScreen}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="NewClientSetupScreen"
        component={NewClientSetupScreen}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="VerificationOfUsers"
        component={VerificationOfUsers}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="NewSalesScreen"
        component={NewSalesScreen}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="create-project"
        component={CreateProjectBySales}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="service-selection"
        component={ServiceSelection}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="SalesBudgetScreen"
        component={SalesBudgetScreen}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="partner-selection"
        component={PartnerSelection}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="final-step"
        component={FinalStep}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="scheduleMeeting"
        component={ScheduleMeeting}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="scheduleDetails"
        component={ScheduleDetails}
        options={{ headerShown: false }}
      />
    </SalesStack.Navigator>
  );
};
