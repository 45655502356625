import React, { Component } from "react";
import { Animated, View, StyleSheet } from "react-native";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import adjust from "../adjust";
const RATIO = 3;
const USE_NATIVE_DRIVER = false;
export class Swipeable extends Component {
  constructor(props) {
    super(props);
    this._width = 0;
    this._dragX = new Animated.Value(0);
    this._transX = this._dragX.interpolate({
      inputRange: [0, RATIO],
      outputRange: [0, 1],
    });
    this._showLeftAction = this._dragX.interpolate({
      inputRange: [-1, 0, 1],
      outputRange: [0, 0, 1],
    });
    this._showRightAction = this._dragX.interpolate({
      inputRange: [-1, 0, 1],
      outputRange: [1, 0, 0],
    });
    this._onGestureEvent = Animated.event(
      [{ nativeEvent: { translationX: this._dragX } }],
      { useNativeDriver: USE_NATIVE_DRIVER }
    );
  }
  _onHandlerStateChange = (event) => {
    if (event.nativeEvent.oldState === State.ACTIVE) {
      const dragToss = 0.05;
      const endOffsetX =
        event.nativeEvent.translationX + dragToss * event.nativeEvent.velocityX;

      let toValue = 0;
      if (endOffsetX > this._width / 2 && this.props.leftContent) {
        toValue = this._width * RATIO;
      } else if (endOffsetX < -this._width / 2 && this.props.rightContent) {
        toValue = -this._width * RATIO;
      }

      Animated.spring(this._dragX, {
        velocity: event.nativeEvent.velocityX,
        tension: 15,
        friction: 5,
        toValue,
        useNativeDriver: USE_NATIVE_DRIVER,
      }).start(() => {
        if (this.props.resetOnFinish) {
          this._reset();
        }
      });

      if (toValue > 0) {
        // left swipe
        if (this.props.onLeftActionActivate) {
          this.props.onLeftActionActivate();
        }
      }
      if (toValue < 0) {
        // right swipe
        if (this.props.onRightActionActivate) {
          this.props.onRightActionActivate();
        }
      }
    }
  };
  _onLayout = (event) => {
    this._width = event.nativeEvent.layout.width;
  };
  _reset = () => {
    Animated.spring(this._dragX, {
      toValue: 0,
      useNativeDriver: USE_NATIVE_DRIVER,
      tension: 15,
      friction: 5,
    }).start();
  };
  reset() {
    setTimeout(() => {
      this._reset();
    }, 2000);
  }
  render() {
    const { children, allowSwipeGesture } = this.props;
    return (
      <View>
        {this.props.leftContent ? (
          <Animated.View
            style={[styles.rowAction, { opacity: this._showLeftAction }]}
          >
            {this.props.leftContent}
          </Animated.View>
        ) : (
          <></>
        )}
        {this.props.rightContent ? (
          <Animated.View
            style={[styles.rowAction, { opacity: this._showRightAction }]}
          >
            {this.props.rightContent}
          </Animated.View>
        ) : (
          <></>
        )}
        <PanGestureHandler
          {...this.props}
          activeOffsetX={[-10, 10]}
          onGestureEvent={allowSwipeGesture ? this._onGestureEvent : null}
          onHandlerStateChange={
            allowSwipeGesture ? this._onHandlerStateChange : null
          }
        >
          <Animated.View
            style={{
              backgroundColor: "white",
              transform: [{ translateX: allowSwipeGesture ? this._transX : 0 }],
            }}
            onLayout={this._onLayout}
          >
            {children}
          </Animated.View>
        </PanGestureHandler>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rectButton: {
    flex: 1,
    height: 60,
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "white",
  },
  rowAction: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  leftAction: {
    backgroundColor: "#4CAF50",
  },
  rightAction: {
    backgroundColor: "#F44336",
  },
  actionButtonText: {
    color: "white",
    fontSize: adjust(16),
  },
  buttonDelimiter: {
    height: 1,
    backgroundColor: "#999",
  },
  buttonText: {
    fontWeight: "bold",
    backgroundColor: "transparent",
  },
  infoButton: {
    width: 40,
    height: 40,
  },
  infoButtonBorders: {
    borderColor: "#467AFB",
    borderWidth: 2,
    width: 20,
    height: 20,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    margin: 10,
  },
  infoButtonText: {
    color: "#467AFB",
    fontWeight: "bold",
    backgroundColor: "transparent",
  },
});
