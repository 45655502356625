import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  StyleSheet,
  Dimensions,
} from "react-native";
import React from "react";
import RoundButton from "../roundButton";
import Avatar from "../avatar";
import Icon from "../icon";
import CreatorDetailsComponent from "../../screens/partner/creatorDetailsComponent";
import adjust from "../adjust";
import moment from "moment";
import LargeButton from "../largeButton";
import Header from "../../components/header";
import { logout } from "../../redux/actions/user.action";
import { primaryColor } from "../utils";
import { creatorApproval } from "../../redux/actions/creator.action";
import { connect } from "react-redux";

const CreatorProfileComponent = ({
  user,
  partner,
  onPressWithOneDesign,
  onAddEducation,
  onPressEdit,
  removeAcademic,
  removeExperience,
  downloadFile,
  navigation,
  editCreator,
  disableLogout,
  currentUser,
  onPressChangePassword,
  onPressRaiseRequest,
  creatorApproval,
  onPressAddExpertise,
}) => {
  const window = Dimensions.get("window");
  return (
    <View>
      <View style={styles.view_page}>
        <View
          style={{
            marginLeft: 10,
          }}
        >
          <View style={styles.imageContainerStyle}>
            <Image
              source={
                user?.image || user?.mimType
                  ? {
                      uri: `data:${user.mimType ? user.mimType : ""};base64,${
                        user.image ? user.image : ""
                      }`,
                    }
                  : user?.gender === "Male"
                  ? require("../../assets/avatar/avatar.png")
                  : require("../../assets/avatar/avatar-female.png")
              }
              style={styles.image}
            ></Image>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text style={styles.nameText}>
                {`${user?.fName} ${user?.lName}`}
              </Text>
              <Text style={{ marginTop: 5 }}>
                {`${
                  user?.userType === "SALES"
                    ? "Remote Sales Executive"
                    : user?.userType === "PARTNER"
                    ? "Partner"
                    : user?.userType === "CLIENT"
                    ? "Client"
                    : user?.primaryJob?.map((x) => x.name).join(", ")
                } since ${moment(
                  new Date(
                    parseInt(user?._id?.toString().substring(0, 8), 16) * 1000
                  )
                ).format("MMMM YYYY")}
                `}
              </Text>
            </View>
            {user?.userType === "CREATOR" ? (
              <View>
                <Icon name="download"></Icon>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottomWidth: 1,
            marginBottom: 17,
            marginHorizontal: 10,
            width: "90%",
          }}
        ></View>
        {user?.bio ? (
          <View style={{ marginLeft: 10 }}>
            <Text>{user?.bio}</Text>
          </View>
        ) : (
          <></>
        )}

        {user?.userType === "CREATOR" && user.starOfTheMonth ? (
          <View
            style={{
              height: 65,
              width: window.width - 60,
              backgroundColor: "#FFD500",
              borderRadius: 10,
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon name="star"></Icon>

            <Text
              style={{
                fontWeight: "bold",
                color: "white",
                fontSize: adjust(18),
                textAlign: "center",
                marginLeft: 10,
              }}
            >
              Sales Star of the Month Award
            </Text>
          </View>
        ) : (
          <></>
        )}
      </View>
      {user?.userType === "CREATOR" ? (
        <View
          style={[styles.view_page, { margin: 10, backgroundColor: "#FFFFFF" }]}
        >
          <View style={styles.middel_view}>
            <Text style={styles.Professional_text}>Technical Expertise</Text>
          </View>
          {user?.expertise?.length === 0 ? (
            <Text style={{ marginLeft: 12, color: "grey", marginVertical: 5 }}>
              no expertises
            </Text>
          ) : (
            <ScrollView
              nestedScrollEnabled={true}
              style={{
                flex: 3,
                maxHeight: 120,
                minHeight: 60,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  //justifyContent: "space-between",
                }}
              >
                {user?.expertise?.map((x) => (
                  <View style={{ margin: 5 }} key={x._id}>
                    {x.mimType && x.image ? (
                      <Image
                        style={styles.image_style}
                        source={{ uri: `data:${x.mimType};base64,${x.image}` }}
                      />
                    ) : (
                      <View
                        style={[
                          styles.image_style,
                          {
                            backgroundColor: "#FFD500",
                            justifyContent: "center",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: adjust(20),
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {x.name
                            ?.split(" ")
                            .map((x) => x.slice(0, 1).toUpperCase())
                            .join("")}
                        </Text>
                      </View>
                    )}
                  </View>
                ))}
              </View>
            </ScrollView>
          )}
          <View style={{ marginLeft: 5 }}>
            {disableLogout ? (
              <></>
            ) : (
              <RoundButton
                pressFunc={onPressAddExpertise}
                title="Add Expertise"
                style={{
                  backgroundColor: "#000000",
                  width: 140,
                  marginTop: 25,
                  bottom: 5,
                }}
              ></RoundButton>
            )}
          </View>
        </View>
      ) : (
        <></>
      )}

      {["CREATOR", "SALES"].includes(user.userType) ? (
        <View style={styles.cardContainer}>
          <Text style={styles.Professional_text}>With Onedesign</Text>
          {user?.onedesignExperiences?.length === 0 ? (
            <Text style={{ color: "grey" }}>no works</Text>
          ) : (
            user?.onedesignExperiences?.map((x) => (
              <CreatorDetailsComponent
                key={x._id}
                cross={partner ? false : true}
                icon={true}
                download={x.deliverables && x.deliverables.length > 0}
                header={x.project.title}
                description={moment(
                  new Date(
                    parseInt(x.project._id?.toString().substring(0, 8), 16) *
                      1000
                  )
                ).format("MMMM DD")}
                subDescription={x.project.about}
                remove={() => removeExperience(x._id)}
                downloadFile={() => downloadFile(x.deliverables)}
              />
            ))
          )}
          {["CREATOR", "SALES"].includes(user.userType) ? (
            <TouchableOpacity>
              {disableLogout ? (
                <></>
              ) : (
                <RoundButton
                  pressFunc={onPressWithOneDesign}
                  title="Add more"
                  style={{
                    backgroundColor: "#000000",
                    width: 140,
                    marginTop: 20,
                    right: 5,
                  }}
                ></RoundButton>
              )}
            </TouchableOpacity>
          ) : (
            <></>
          )}
        </View>
      ) : (
        <></>
      )}

      <View style={styles.cardContainer}>
        <Text style={styles.Professional_text}>Education</Text>
        {user?.academics?.length === 0 ? (
          <Text style={{ color: "grey" }}>please add education</Text>
        ) : (
          user?.academics?.map((x) => (
            <CreatorDetailsComponent
              key={x._id}
              cross={partner ? false : true}
              icon={true}
              header={x.college}
              description={x.primaryCourse}
              subDescription={`Batch of ${x.year}`}
              remove={() => removeAcademic(x._id)}
            />
          ))
        )}

        <View style={{ right: 5 }}>
          {disableLogout ? (
            <></>
          ) : (
            <RoundButton
              pressFunc={onAddEducation}
              title={
                user?.academics && user?.academics.length > 0
                  ? "Add more"
                  : "Add academic"
              }
              style={{
                backgroundColor: "#000000",
                width: 140,
                marginTop: 20,
              }}
            ></RoundButton>
          )}
        </View>
      </View>
      <View style={styles.cardContainer}>
        <Text style={styles.Professional_text}>Professional Information</Text>
        <CreatorDetailsComponent
          header="Current Job"
          description={
            user && user?.currentEmployment ? user?.currentEmployment : ""
          }
        />
        <CreatorDetailsComponent
          header="Gender"
          description={user ? user.gender : ""}
        />
        <CreatorDetailsComponent
          header="Phone No"
          description={user ? user.phoneNo : ""}
        />
        <CreatorDetailsComponent
          header="Email"
          description={user ? user.email : ""}
        />
        <CreatorDetailsComponent
          header="Address"
          description={user ? user.address : ""}
        />
        <CreatorDetailsComponent
          header="PIN Code"
          description={user ? user.pin : ""}
        />
        <CreatorDetailsComponent header="PAN" description={user?.pan || ""} />
        <CreatorDetailsComponent
          header="Aadhar"
          description={user?.aadhar || ""}
        />

        <TouchableOpacity>
          {disableLogout ? (
            <></>
          ) : (
            <RoundButton
              pressFunc={onPressEdit}
              title="Edit"
              style={{
                backgroundColor: "#000000",
                width: 120,
                marginTop: 30,
                right: 5,
              }}
            ></RoundButton>
          )}
        </TouchableOpacity>
      </View>
      {["CREATOR", "SALES", "CLIENT", "PARTNER"].includes(user.userType) &&
      !currentUser ? (
        <>
          <TouchableOpacity
            style={{ alignItems: "center", marginHorizontal: 10 }}
          >
            <LargeButton
              arrowColor={"#fff"}
              pressFunc={onPressChangePassword}
              backgroundColor={primaryColor(user.userType)}
              textColor={"#fff"}
              title={"Change Password"}
            ></LargeButton>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              alignItems: "center",
              marginBottom: 10,
              marginHorizontal: 10,
            }}
          >
            <LargeButton
              pressFunc={onPressRaiseRequest}
              arrowColor={"#fff"}
              backgroundColor={"#000"}
              textColor={"#fff"}
              title={"Raise a Request"}
            ></LargeButton>
          </TouchableOpacity>
        </>
      ) : (
        <></>
      )}
      {["REVIEWING", "INCOMPLETE"].includes(user.status) &&
      currentUser === "PARTNER" ? (
        <>
          <View style={{ alignItems: "center", marginHorizontal: 10 }}>
            <LargeButton
              pressFunc={() =>
                creatorApproval(user._id, "SHORTLISTED", "SHORTLISTED")
              }
              backgroundColor={"#FF973E"}
              textColor={"#fff"}
              title={"Shortlist Creator"}
            ></LargeButton>
          </View>
          <View
            style={{
              alignItems: "center",
              marginBottom: 10,
              marginHorizontal: 10,
            }}
          >
            <LargeButton
              pressFunc={() =>
                creatorApproval(user._id, "ACCEPTED", "ACCEPTEDPENDINGUSER")
              }
              arrowColor={"#fff"}
              backgroundColor={"#7A9D54"}
              textColor={"#fff"}
              title={"Accept Creator"}
            ></LargeButton>
          </View>
          <TouchableOpacity
            style={{
              alignItems: "center",
              marginHorizontal: 10,
              bottom: 10,
            }}
          >
            <LargeButton
              arrowColor={"#fff"}
              backgroundColor={"#EB4141"}
              textColor={"#fff"}
              title={"Reject Creator"}
            ></LargeButton>
          </TouchableOpacity>
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 2 },
  card_Container: {
    flex: 2,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },

  card_image: {
    width: 90,
    height: 90,
    borderWidth: 3,
    borderColor: "#ffd500",
    borderRadius: 100 / 2,
  },

  nameText: {
    fontSize: adjust(19),
    fontWeight: "bold",
  },
  profile: {
    flex: 1,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 10,
    flexDirection: "row",
    marginTop: 10,
    maxWidth: 400,
    width: "100%",
    maxHeight: 90,
    minHeight: 60,
  },

  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  centeredView: {
    flex: 1,
    marginHorizontal: 5,
  },
  label: {
    fontWeight: "bold",
    color: "#000000",
  },
  card_view: {
    flex: 1,
    marginTop: 10,
    maxWidth: 130,
    minWidth: 130,
  },
  Text_style: {
    textAlign: "justify",
    color: "#000",
  },
  upper_text: {
    marginBottom: 5,
    fontWeight: "bold",
    color: "#000",
  },
  image: {
    borderRadius: 100,
    height: 105,
    width: 105,
    alignSelf: "center",
  },

  softwarepage: {
    flex: 1,
    // borderBottomWidth: 1,
    marginTop: 5,
    // maxWidth: 250,
    minWidth: 250,
  },
  Professional_text: {
    marginBottom: 10,
    fontWeight: "bold",
    color: "#828282",
  },
  page_view: {
    flex: 1,
    marginBottom: 10,
  },
  view_page: {
    borderRadius: 10,
    flexWrap: "wrap",
    marginHorizontal: 15,
    padding: 5,
  },
  middel_view: {
    flex: 1,
    minWidth: 250,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  image_style: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  Edit_header: {
    flex: 1,
    minWidth: 250,
    marginTop: 10,

    marginLeft: 20,
  },
  Edit_Text: {
    marginBottom: 10,
    fontWeight: "bold",
    color: "#828282",
    fontSize: 20,
  },
  circle: {
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    backgroundColor: "#B4B4B4",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginHorizontal: 15,
    marginVertical: 10,
    padding: 15,
  },
  imageContainerStyle: {
    height: 120,
    width: 120,
    borderRadius: 100,
    justifyContent: "center",
    borderWidth: 3,
    borderColor: "#ffd500",
    marginBottom: 20,
  },
});

export default connect(null, { creatorApproval })(CreatorProfileComponent);
