export const fetchMypaymentCurrentMonth = () => (dispatch) => {
  return dispatch({
    type: "FETCH_MY_PAYMENT_CURRENT",
    payload: {
      request: {
        url: `/api/payment?currentMonth=${true}`,
        method: "GET",
      },
    },
  });
};

export const fetchMypaymentPreviousMonth = () => (dispatch) => {
  return dispatch({
    type: "FETCH_MY_PAYMENT_PREVIOUS",
    payload: {
      request: {
        url: "/api/payment",
        method: "GET",
      },
    },
  });
};

export const fetchRaisedTransactions = () => (dispatch) => {
  return dispatch({
    type: "FETCH_RAISED_TRANSACTIONS",
    payload: {
      request: {
        url: "/api/payment/raised",
        method: "GET",
      },
    },
  });
};

export const fetchClientFulfilledTransactions = () => (dispatch) => {
  return dispatch({
    type: "FETCH_CLIENT_FULILLED_TRANSACTIONS",
    payload: {
      request: {
        url: "/api/payment/client/fulfillled-transactions",
        method: "GET",
      },
    },
  });
};

export const fetchAllRequestedTransactions = (user) => (dispatch) => {
  return dispatch({
    type: "FETCH_ALL_REQUESTED_TRANSACTIONS",
    payload: {
      request: {
        url: `/api/payment/requested?${user ? "user=" + user : ""}`,
        method: "GET",
      },
    },
  });
};

export const fetchAllTransactions = (user) => (dispatch) => {
  return dispatch({
    type: "FETCH_ALL_TRANSACTIONS",
    payload: {
      request: {
        url: `/api/payment/transactions?${user ? "user=" + user : ""}`,
        method: "GET",
      },
    },
  });
};

export const fetchInflowTransactions = (user) => (dispatch) => {
  return dispatch({
    type: "FETCH_ALL_TRANSACTIONS",
    payload: {
      request: {
        url: `/api/payment/inflow`,
        method: "GET",
      },
    },
  });
};
