import { combineReducers } from "redux";
import user from "./user";
import token from "./token";
import selectedProject from "./selectedProject";
import projects from "./projects";
import newProjects from "./newProjects";
import projectDomain from "./projectDomain";
import engagementType from "./engagementType";
import skills from "./skills";
import projectTag from "./projectTag";
import selectedRequirement from "./selectedRequirement";
import requirements from "./requirements";
import projectClass from "./projectClass";
import projectAudience from "./projectAudience";
import projectRole from "./projectRole";
import selectedQuotation from "./selectedQuotation";
import creatorList from "./creatorList";
import pendingProjects from "./pendingProjects";
import partners from "./partners";
import pendingCreatorList from "./pendingCreatorsList";
import resumeFile from "./resume_file";
import portfolioFile from "./portfolio_file";
import toasts from "./toasts";
import loadingReqs from "./loadingReqs";
import shortlistedCreatorList from "./shortlistedCreatorList";
import myPaymentCurrentMonth from "./myPaymentCurrentMonth";
import myPaymentPrevious from "./myPaymentPrevious";
import tasksActive from "./taskActive";
import tasksFinished from "./taskFinished";
import selectedMember from "./selectedMember";
import deviceToken from "./deviceToken";
import paymentReports from "./paymentReports";
import dashboardStats from "./dashboardStats";
import company from "./company";
import closedProjects from "./closedProjects";
import introSliders from "./introsliders";
import need from "./need";
import reach from "./reach";
import claims from "./claims-reducer";
import razorPayKey from "./razorpay-reducer";
import notifications from "./notification-reducer";
import discussions from "./discussion-reducer";
import unreadCount from "./unread-reducer";
import chats from "./chat-reducer";
import medias from "./media-reducer";
import notificationCount from "./notification-count-reducer";
import partnersByAdmin from "./partnersByAdmin";
import technicalExpertise from "./technical-expertise-reducer";
import projectCatalog from "./project-catalog-reducer";
import members from "./members";
import clientList from "./client-list";
import clientCount from "./client-count-reducer";
import clientAllProject from "./clinetAllProject";
import individualServices from "./individualService";
import meetings from "./meeting-reducer";
import {
  salesPersons,
  salesPersonEarnings,
  salesPersonFinances,
} from "./sales-person-reducer";
import { creators, creatorEarnings, creatorFinances } from "./creators-reducer";
import userStats from "./user-stats-reducer";
import salesPromotionEntries from "./sales-promotion-entry-reducer";
import discussionsByProject from "./dicussions-by-project-reducer";
import caseStudy from "./case-study-reducer";
import helpMessages from "./help-message";
import helpMessagesForAdmin from "./help-list-for-admin-reducer";
import raisedTransanctions from "./raised-payments-reducer";
import { transactions, requestedTransactions } from "./transaction-reducer";
import welcomeMessages from "./welcome-messages";
import expertMaterials from "./expert-material-redcer";

export default combineReducers({
  user,
  token,
  projects,
  newProjects,
  pendingProjects,
  closedProjects,
  selectedProject,
  requirements,
  selectedRequirement,
  partners,
  pendingCreatorList,
  resumeFile,
  portfolioFile,
  toasts,
  loadingReqs,
  shortlistedCreatorList,
  myPaymentCurrentMonth,
  myPaymentPrevious,
  tasksActive,
  tasksFinished,
  selectedMember,
  deviceToken,
  paymentReports,
  dashboardStats,
  company,
  projectDomain,
  engagementType,
  skills,
  projectClass,
  projectAudience,
  projectRole,
  selectedQuotation,
  creatorList,
  projectTag,
  introSliders,
  need,
  reach,
  claims,
  razorPayKey,
  notifications,
  discussions,
  unreadCount,
  chats,
  medias,
  notificationCount,
  partnersByAdmin,
  technicalExpertise,
  projectCatalog,
  members,
  clientList,
  clientCount,
  clientAllProject,
  individualServices,
  meetings,
  salesPersons,
  userStats,
  salesPromotionEntries,
  discussionsByProject,
  caseStudy,
  helpMessages,
  helpMessagesForAdmin,
  raisedTransanctions,
  salesPersonEarnings,
  salesPersonFinances,
  creators,
  creatorEarnings,
  creatorFinances,
  transactions,
  requestedTransactions,
  welcomeMessages,
  expertMaterials,
});
