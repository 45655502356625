import React from "react";
import { connect } from "react-redux";
import CreatorProfile from "../partner/creator-profile";

const UserProfile = ({ user, navigation }) => {
  return <CreatorProfile navigation={navigation} user={user} />;
};

const mapStateToProps = ({ user }) => ({
  user,
});
export default connect(mapStateToProps, {})(UserProfile);
