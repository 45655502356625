const initialState = [];
const myPaymentPrevious = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_MY_PAYMENT_PREVIOUS_SUCCESS": {
            return action.payload.data;
        }
        case "FETCH_MY_PAYMENT_PREVIOUS_FAIL": {
            return state;
        }
        case "LOGOUT": {
            return {};
        }
        default:
            return state;
    }
};
export default myPaymentPrevious;