import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import LargeTextInput from "../../../components/largeTextInput";
import TeamProfileComponent from "../../../components/teamEachProfileComponent/teamEachProfileComponent";
import RoundButton from "../../../components/roundButton";
import FilterComponent from "../../../components/filter/filter";
import Icon from "../../../components/icon";
import { TabRouter } from "@react-navigation/native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import adjust from "../../../components/adjust";
import moment from "moment";
import {
  createProjectMeeting,
  createProject,
} from "../../../redux/actions/project.action";
import { createMeetingForCreator } from "../../../redux/actions/meeting.action";

const ScheduleMeeting = ({
  user,
  navigation,
  route,
  selectedProject,
  createProject,
  createProjectMeeting,
  createMeetingForCreator,
}) => {
  const data = route.params;
  const [isDatePickerVisible, setShowDatePicker] = useState();
  const [meetingDetails, setMeetingDetails] = useState({
    name: "",
    time: new Date(),
    users: [],
    project: null,
  });

  useEffect(() => {
    if (route.params?.type === "creator-review") {
      setMeetingDetails({ ...meetingDetails, name: "Creator Review" });
    } else if (route.params?.type === "initial") {
      setMeetingDetails({ ...meetingDetails, name: "Initial Project Meeting" });
    }
  }, [route.params]);

  useEffect(() => {
    if (selectedProject && selectedProject.initialCall) {
      setMeetingDetails({
        name: selectedProject.initialCall?.name,
        time: selectedProject.initialCall?.time,
        users: selectedProject.initialCall?.users,
      });
    }
  }, [selectedProject]);

  const updateProjectAndNavigate = () => {
    if (route.params && route.params.type === "initial") {
      createProject(
        {
          _id: selectedProject._id,
          initialCall: { ...meetingDetails, project: selectedProject._id },
        },
        () => navigation.navigate("scheduleDetails", { type: "initial" })
      );
    } else if (route.params && route.params.type === "meeting-screen") {
      createProjectMeeting(
        {
          _id: selectedProject._id,
          meetings: { ...meetingDetails, project: selectedProject._id },
        },
        () => navigation.navigate("myMeetings")
      );
    } else if (route.params && route.params.type === "creator-review") {
      createMeetingForCreator(meetingDetails, () =>
        navigation.navigate("dashboard")
      );
    }
  };

  const updateUsers = (user) => {
    if (meetingDetails.users.find((x) => x._id === user._id)) {
      setMeetingDetails({
        ...meetingDetails,
        users: [...meetingDetails.users.filter((x) => x._id != user._id)],
      });
    } else {
      setMeetingDetails({
        ...meetingDetails,
        users: [...meetingDetails.users, user],
      });
    }
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#FFF" }}>
      <View style={styles.headerStyle}>
        <Header
          title="schedule a meeting"
          subtitle="Enter meeting details"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ backgroundColor: "#FFF" }}>
        <View style={styles.wrapperStyle}>
          <LargeTextInput
            value={meetingDetails.name}
            marginLeft={1}
            placeholder="enter meeting name"
            style={{ borderBottomColor: "#707070", marginHorizontal: 0 }}
            width={"100%"}
            onChangeText={(data) =>
              setMeetingDetails({ ...meetingDetails, name: data })
            }
          ></LargeTextInput>
        </View>
        <View style={styles.wrapperStyle}>
          <TouchableOpacity
            onPress={() => setShowDatePicker(true)}
            style={{
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "#fff",
              // position: "absolute",
              borderBottomWidth: 1,
              borderBottomColor: "#707070",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: adjust(16),
                fontWeight: "bold",
                color: meetingDetails.time ? "#000" : "#B2AFAF",
                alignSelf: "auto",
              }}
            >
              {meetingDetails.time
                ? moment(meetingDetails.time).format("DD/MM/YYYY HH:mm")
                : "Select date and time"}
            </Text>

            <Icon
              style={{ alignSelf: "flex-end", marginBottom: 10 }}
              fill="#707070"
              height="30"
              width="30"
              name="meeting"
            ></Icon>
          </TouchableOpacity>
        </View>
        <DateTimePickerModal
          minimumDate={new Date()}
          isVisible={isDatePickerVisible}
          onConfirm={(d) => {
            setMeetingDetails({ ...meetingDetails, time: d });
            setShowDatePicker(false);
          }}
          mode={"datetime"}
          is24Hour={true}
          format="YYYY-MM-DD"
          onCancel={() => setShowDatePicker(false)}
        />
        {route.params &&
        route.params.type != "initial" &&
        route.params.type != "creator-review" ? (
          <View style={{ marginTop: 30 }}>
            <Text style={styles.selectMemberStyle}>Select Group Members</Text>
            <View style={styles.groupContainer}>
              {selectedProject?.team?.map((t, i) => {
                return (
                  <TeamProfileComponent
                    user={user}
                    index={i}
                    profile={t}
                    onSelect={() => updateUsers(t.user)}
                    checked={
                      meetingDetails.users.find((x) => x._id === t.user._id)
                        ? true
                        : false
                    }
                  ></TeamProfileComponent>
                );
              })}
            </View>
          </View>
        ) : (
          <></>
        )}
      </View>
      <View
        style={{
          flexDirection: "row",
          margin: 15,
          marginVertical: 15,
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <Pressable
          style={[styles.pressableStyle]}
          onPress={() => updateProjectAndNavigate()}
        >
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 15,
                fontWeight: "bold",
                alignSelf: "center",
                justifyContent: "center",
              },
            ]}
          >
            {"Create"}
          </Text>
        </Pressable>

        <Pressable
          style={[styles.pressableStyle, { backgroundColor: "#EB4141" }]}
          onPress={() => {}}
        >
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 15,
                fontWeight: "bold",
                alignSelf: "center",
                justifyContent: "center",
              },
            ]}
          >
            {"Discard"}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, {
  createProject,
  createProjectMeeting,
  createMeetingForCreator,
})(ScheduleMeeting);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
  wrapperStyle: {
    marginTop: 70,
    marginHorizontal: 30,
    minHeight: 20,
  },
  selectMemberStyle: {
    fontWeight: "bold",
    fontSize: 15,
    marginHorizontal: 30,
    color: "#828282",
  },
  groupContainer: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    marginBottom: 10,
    marginHorizontal: 7,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginHorizontal: 10,
    marginVertical: 10,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
