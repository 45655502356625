import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import { TextInput } from "react-native-gesture-handler";
import Icon from "./icon";
import PopUp from "./popUp/popUp";
import { fetchProjectSkill } from "../redux/actions/projectSkill.action";
import { updateProfile } from "../redux/actions/user.action";
import adjust from "./adjust";
const Skills = ({
  name,
  options,
  skills,
  setSelectedSkill,
  fetchProjectSkill,
  updateProfile,
  pressFucnc,
  removeOnpress,
}) => {
  const [addSkill, setAddSkill] = useState(false);
  const [input, setInput] = useState(false);
  const [effect, setEffect] = useState(false);
  const [skill, setSkill] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const add = () => {
    updateUser();
    setAddSkill(false);
  };

  const updateUser = () => {
    let userObj = {
      skill,
      userType: "USER",
      role: "USER",
    };
    updateProfile(userObj);
  };

  const handleLevelCallback = (childData) => {
    setSelectedSkill(childData);
    setModalVisible(false);
  };

  useEffect(() => {
    if (skills && !effect) {
      setEffect(true);
      fetchProjectSkill();
    }
  });

  return (
    <View style={{ flex: 1 }}>
      <PopUp
        style={{ marginLeft: 20 }}
        multiSelect={true}
        selectionValue={options}
        renderData={skills}
        onSelection={handleLevelCallback}
        visible={modalVisible}
      ></PopUp>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          minHeight: 50,
          marginTop: 35,
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={[styles.text, { flex: 1 }]}>{name}</Text>
        </View>
        <View
          style={{
            flex: 4,
            flexDirection: "row",
            marginLeft: Platform.OS === "web" ? 50 : 20,
            flexWrap: "wrap",
            bottom: 7,
          }}
        >
          {options.length == 0 ? (
            <></>
          ) : (
            options.map((item, index) => {
              return (
                <View
                  style={{
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: "#8D8888",
                    padding: 2,
                    flexWrap: "wrap",
                    flexDirection: "row",
                    margin: 5,
                    minHeight: 25,
                    maxHeight: 25,
                  }}
                  key={index}
                >
                  <TouchableOpacity onPress={() => setAddSkill(true)}>
                    <Text
                      style={{
                        color: "#8D8888",
                      }}
                    >
                      {item.name}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => removeOnpress(index)}>
                    <Icon
                      style={{
                        marginLeft: 5,
                      }}
                      name="remove"
                    ></Icon>
                  </TouchableOpacity>
                </View>
              );
            })
          )}
          <></>
        </View>
        <TouchableOpacity
          style={{ bottom: 28, width: 40, paddingTop: 10, right: 0 }}
          onPress={() => {
            setModalVisible(true);
          }}
        >
          <Text style={{ fontWeight: "bold", fontSize: adjust(45) }}>+</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  text: { fontWeight: "bold", fontSize: adjust(15) },
  textinput: { marginHorizontal: 15, borderBottomWidth: 2 },
});
const mapStateToProps = ({ user, skills }) => ({ user, skills });
export default connect(mapStateToProps, { fetchProjectSkill, updateProfile })(
  Skills
);
