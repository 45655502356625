import React, { useEffect, useState } from "react";
import {
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Modal,
  StyleSheet,
  Pressable,
} from "react-native";
import Icon from "../icon";
import * as DocumentPicker from "expo-document-picker";
import InputDialogBox from "../dialogBox/dialog-box";
import { Audio } from "expo-av";
import moment from "moment";
const base64 = require("base-64");
const getExt = (fileName) => {
  const fileParts = fileName.split(".");
  return fileParts[fileParts.length - 1];
};

function fileType(filename) {
  var parts = filename.split(".");
  let ext = parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
      return "image";
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
      return "video";
    default:
      return "other";
  }
}
let recording = new Audio.Recording();
const ChatBox = ({
  onSend,
  style,
  uploadFile,
  selectedProject,
  discussion,
  discussionTitle,
}) => {
  const [msg, setMsg] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    return splitDataURI.length > 1
      ? b64toBlob(splitDataURI[1])
      : b64toBlob(dataURI);
  }

  const selectFile = async () => {
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: "*/*",
      }).then(async (result) => {
        return {
          name: result.name,
          base64: result.uri,
        };
      });
      let formData = new FormData();
      formData.append(
        "files",
        Platform.OS == "web"
          ? DataURIToBlob(res.base64)
          : {
              uri: res.base64,
              type: `application/${getExt(res.name)}`,
              name: res.name,
            },
        res.name
      );

      formData.append("type", fileType(res.name));
      formData.append("project", selectedProject._id);
      formData.append("discussion", discussion);

      uploadFile(formData);
    } catch (err) {
      console.error(err);
    }
  };
  const [record, setRecord] = React.useState(false);

  async function startRecording() {
    try {
      // console.log("Requesting permissions..");
      // const permission = await Permissions.askAsync(
      //   Permissions.AUDIO_RECORDING
      // );
      if (permission.granted) {
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: true,
          playsInSilentModeIOS: true,
        });
        console.log("Starting recording..");
        await recording.prepareToRecordAsync(
          Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY
        );
        await recording.startAsync();
        console.log("Recording started");
        setRecord(true);
        setModalVisible(false);
      } else {
        console.error("Permission de be");
      }
    } catch (err) {
      console.error("Failed to start recording", err);
    }
  }

  async function stopRecording() {
    console.log("Stopping recording..");
    setRecord(false);
    await recording.stopAndUnloadAsync();
    const uri = recording.getURI();

    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };

    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const audioBase64 = await blobToBase64(blob);
    let parts = uri.split(".");

    // let obj = {
    //   fileName:
    //     selectedProject.title +
    //     moment().format("DD/MM/YY-HH:mm:ss") +
    //     "." +
    //     parts[parts.length - 1],
    //   duration: recording._finalDurationMillis,
    //   project: selectedProject._id,
    //   base64: audioBase64,
    // };
    let formData = new FormData();
    formData.append(
      "files",
      {
        uri: audioBase64,
        type: `application/${parts[parts.length - 1]}`,
        name:
          selectedProject.title +
          " - " +
          discussionTitle +
          moment().format("DD/MM/YY-HH:mm:ss") +
          "." +
          parts[parts.length - 1],
      },
      res.name
    );

    formData.append("type", fileType(res.name));
    formData.append("project", selectedProject._id);
    formData.append("discussion", discussion);
    formData.append("duration", recording._finalDurationMillis);

    uploadFile(formData);
    recording = new Audio.Recording();
  }

  return (
    <View
      style={[
        {
          backgroundColor: "#FFFFFF",
          // flex: 1,
          flexDirection: "row",
          width: "100%",
          paddingBottom: Platform.OS === "android" ? 40 : 20,
        },
        style,
      ]}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          borderRadius: 15,
          marginLeft: 5,
        }}
      >
        <TextInput
          placeholder="Type Your Message"
          onChangeText={(m) => setMsg(m)}
          value={msg}
          multiline={true}
          style={styles.inputStyle}
        ></TextInput>
      </View>
      <TouchableOpacity
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginHorizontal: 10,
          width: 25,
          height: Platform.OS === "android" ? 30 : null,
          marginTop: Platform.OS === "android" ? -12 : 5,
          left: 5,
        }}
        onPress={() => {
          if (record) {
            stopRecording();
          } else {
            setModalVisible(true);
          }
        }}
      >
        <View
          style={[
            styles.iconContainer,
            { backgroundColor: "#ECECEC", position: "absolute", top: -5 },
          ]}
        >
          <Icon
            name={record ? "send" : modalVisible ? "cancel" : "plus"}
            fill={modalVisible || record ? "#bdbdbd" : "#bdbdbd"}
            height={20}
            width={20}
          ></Icon>
        </View>
      </TouchableOpacity>

      <InputDialogBox
        centerView={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.2)",
        }}
        jsx={
          <>
            <View
              style={{
                position: "absolute",
                bottom: Platform.OS === "ios" ? 125 : 85,
                right: 50,
              }}
            >
              <TouchableOpacity style={[styles.iconContainer, { left: 5 }]}>
                <Icon fill="#bdbdbd" name="gallery" height={20}></Icon>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.iconContainer, { left: 5 }]}>
                <Icon fill="#bdbdbd" name="youTube" height={20}></Icon>
              </TouchableOpacity>
              <Pressable
                onPress={() => startRecording()}
                style={[styles.iconContainer, { left: 5 }]}
              >
                <Icon fill="#bdbdbd" name="mike" height={20}></Icon>
              </Pressable>
              <TouchableOpacity
                style={[
                  styles.iconContainer,
                  {
                    backgroundColor: "#bdbdbd",
                    bottom: -70,
                    marginHorizontal: 5,
                  },
                ]}
                onPress={() => {
                  setModalVisible(false);
                }}
              >
                <Icon fill="rgba(0,0,0,0.2)" name="cancel" height={20}></Icon>
              </TouchableOpacity>
            </View>
          </>
        }
        modalViewStyle={{
          backgroundColor: "rgba(0,0,0,0)",
          width: "100%",
          height: "100%",
        }}
        showModal={modalVisible}
      ></InputDialogBox>
      <TouchableOpacity
        onPress={() => {
          if (msg && msg != "") {
            onSend(msg);
            setMsg("");
          }
        }}
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
          marginRight: 5,
          width: 30,
          height: Platform.OS === "android" ? 30 : null,
          marginTop: Platform.OS === "android" ? -14 : null,
          marginBottom: 5,
        }}
      >
        <Icon height={35} width={35} name="rightChevronArrowWithCircle"></Icon>
      </TouchableOpacity>
    </View>
  );
};

// useEffect(() => {
//   const permission = await Audio.requestPermissionsAsync();
// }, []);
export default ChatBox;

const styles = StyleSheet.create({
  iconContainer: {
    borderRadius: 50,
    backgroundColor: "#fff",
    height: 35,
    width: 35,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    top: 70,
  },
  inputStyle: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 50,
    minHeight: 37,
    // maxHeight: 140,
  },
});
//

{
  /* <View
      style={[
        {
          backgroundColor: "#FFFFFF",
          flex: 1,
          flexDirection: "row",
        },
        style,
      ]}
    >
      <TextInput
        onChangeText={(m) => setMsg(m)}
        value={msg}
        multiline={true}
        style={{
          borderColor: "#000000",
          borderWidth: 1,
          flex: 1,
          borderRadius: 25,
          paddingHorizontal: 25,
        }}
      ></TextInput>
      <TouchableOpacity
        style={{
          // borderColor: "#000000",
          // borderWidth: 1,
          flex: 1,
          alignSelf: "flex-end",
          alignContent: "center",
          justifyContent: "center",
          // backgroundColor: "#FFE700",
          maxWidth: 50,
          // height: "100%",
        }}
        onPress={() => {
          onSend(msg);
          setMsg("");
        }}
      >
        <Icon
          name="rightChevronArrowWithCircle"
          style={{
            flex: 1,
            alignSelf: "flex-end",
            marginRight: 10,
            marginBottom: 5,
          }}
        ></Icon>
      <Text style={{ flex: 1 }}>{"Send"}</Text>
      </TouchableOpacity>
    </View> */
}
