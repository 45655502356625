import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Image,
  TouchableOpacity,
  PixelRatio,
  Platform,
} from "react-native";
import React, { useState } from "react";
import Header from "../../../components/header";
import LargeTextInput from "../../../components/largeTextInput";
import { connect } from "react-redux";
import Icon from "../../../components/icon";
import { launchCamera, launchImageLibrary } from "react-native-image-picker";
import PopUp from "../../../components/popUp/popUp";
import RoundButton from "../../../components/roundButton";
import { createWelcomeMessage } from "../../../redux/actions/welcome-message.action";

const WelcomeMessageforAdmin = ({ navigation, user, createWelcomeMessage }) => {
  const initData = {
    modalVisible: false,
    pickerName: "",
    hourModal: false,
    minuteModal: false,
  };
  const initfromTime = { fromHour: null, fromMinute: null };
  const initToTime = { toHour: null, toMinute: null };
  const [selectTime, setSelectTime] = useState(initData);
  const [welcomeMessageImage, setWelcomeMessageImage] = useState(null);
  const [fromTime, setfromTime] = useState(initfromTime);
  const [toTime, setToTime] = useState(initToTime);
  const [message, setMessage] = useState("");
  const renderData = [
    { _id: 1, name: "CLIENT" },
    { _id: 2, name: "SALES" },
    { _id: 3, name: "PARTNER" },
    { _id: 4, name: "CREATOR" },
  ];
  const [value, setValue] = useState([]);
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const handleOnchangeSelection = (data) => {
    setValue([...data]);
  };
  const pickImage = () => {
    const options = {
      storageOptions: {
        path: "images",
        mediaType: "photo",
      },
      includeBase64: true,
      base64: true,
    };
    launchImageLibrary(options, (response) => {
      console.log("Success");
      if (response.didCancel) {
        console.log("User cancelled image picker");
      } else if (response.error) {
        console.log("error");
      } else {
        const source = response.assets[0].base64;

        var file = response.assets[0].fileName;

        setWelcomeMessageImage(source);
      }
    });
  };
  let hours = [];
  let minutes = [];
  for (let i = 0; i < 60; i++) {
    minutes.push({ name: `${i < 10 ? "0" : ""}${i}` });
    if (i < 24) {
      hours.push({ name: `${i < 10 ? "0" : ""}${i}` });
    }
  }
  const setData = (item) => {
    if (selectTime.pickerName === "from Time") {
      setfromTime({ ...fromTime, fromHour: item });
      setSelectTime({
        pickerName: "from Time",
        modalVisible: true,
        hourModal: false,
        minuteModal: true,
      });
    } else {
      setToTime({ ...toTime, toHour: item });
      setSelectTime({
        pickerName: "To Time",
        modalVisible: true,
        hourModal: false,
        minuteModal: true,
      });
    }
  };
  const setMinutes = (item) => {
    if (selectTime.pickerName === "from Time") {
      setfromTime({ ...fromTime, fromMinute: item });
    } else {
      setToTime({ ...toTime, toMinute: item });
    }
  };
  const discard = () => {
    navigation.goBack();
  };
  const addWelcomeMessage = () => {
    createWelcomeMessage({
      message: message,
      image:
        Platform.OS === "web"
          ? welcomeMessageImage
          : welcomeMessageImage
          ? "data:image/jpeg;base64," + welcomeMessageImage
          : null,

      userType: value?.map((item) => item.name),
      fromHour: fromTime.fromHour?.name || "",
      fromMinute: fromTime.fromMinute?.name || "",
      toHour: toTime.toHour?.name || "",
      toMinute: toTime.toMinute?.name || "",
    });
    navigation.navigate("welcomeMessageScreen");
  };
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          enableAvatar={false}
          title="add welcome message"
          subtitle="Enter welcome message"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <View
        style={{
          flex: 8,
        }}
      >
        <LargeTextInput
          selectedValue={value}
          placeholder="Select User Type"
          renderData={renderData}
          multiSelect={true}
          onSelectionChange={(data) => handleOnchangeSelection(data)}
          containerStyle={{
            marginTop: 20,
            marginHorizontal: 35,
          }}
        ></LargeTextInput>

        <View style={{ marginHorizontal: 30 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ alignSelf: "center" }}>
              <Text
                style={{ fontSize: 18, fontWeight: "bold", color: "#B2AFAF" }}
              >
                Timing:
              </Text>
            </View>
            <View
              style={{
                alignSelf: "center",
                borderBottomWidth: 1,
                borderBottomColor: "#707070",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setSelectTime({
                    pickerName: "from Time",
                    modalVisible: true,
                    hourModal: true,
                    minuteModal: false,
                  });
                }}
                style={{
                  borderBottomColor: "#707070",
                  width: "100%",
                  paddingVertical: 3,
                }}
              >
                <Text style={styles.messageTextStyle}>
                  {fromTime.fromHour && fromTime.fromMinute
                    ? `${fromTime.fromHour?.name} : ${fromTime.fromMinute?.name}`
                    : "From Time"}
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                alignSelf: "center",
                borderBottomWidth: 1,
                borderBottomColor: "#707070",
                paddingBottom: 5,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setSelectTime({
                    pickerName: "To Time",
                    modalVisible: true,
                    hourModal: true,
                    minuteModal: false,
                  });
                }}
                style={{
                  borderBottomColor: "#707070",
                  width: "100%",
                  paddingVertical: 3,
                }}
              >
                <Text style={styles.messageTextStyle}>
                  {toTime.toHour && toTime.toMinute
                    ? `${toTime.toHour?.name} : ${toTime.toMinute?.name}`
                    : "To Time"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styles.textStyle}>
          <LargeTextInput
            onChangeText={(text) => setMessage(text)}
            placeholder="Enter message"
            style={{ borderBottomColor: "#707070" }}
          ></LargeTextInput>
        </View>
        <TouchableOpacity
          onPress={() => pickImage()}
          style={styles.touchabelStyle}
        >
          <Text
            style={{
              fontSize: 20 * PixelRatio.getFontScale(),
              fontWeight: "bold",
              color: "#B4B2B2",
              marginTop: welcomeMessageImage ? 10 : -5,
            }}
          >
            {"logo/image"}
          </Text>
          {welcomeMessageImage ? (
            <Image
              style={{
                height: 50,
                width: 50,
                right: 10,
              }}
              source={{ uri: "data:image/jpeg;base64," + welcomeMessageImage }}
            ></Image>
          ) : (
            <View>
              <Icon style={{ right: 10 }} name="image" fill={"#707070"}></Icon>
            </View>
          )}
        </TouchableOpacity>
      </View>
      <PopUp
        selectionValue={hour}
        onSelection={(e) => setData(e)}
        hideArrow={true}
        // visible={modalVisible}
        visible={selectTime.modalVisible && selectTime.hourModal}
        disableBottomBorder={true}
        renderData={hours}
      ></PopUp>
      <PopUp
        selectionValue={minute}
        onSelection={(e) => setMinutes(e)}
        hideArrow={true}
        visible={selectTime.modalVisible && selectTime.minuteModal}
        disableBottomBorder={true}
        renderData={minutes}
      ></PopUp>
      <View>
        <View
          style={{
            flexDirection: "row",
            margin: 10,
            justifyContent: "space-around",
          }}
        >
          <RoundButton
            style={{ backgroundColor: "#000000", width: 130 }}
            title={"Add+"}
            pressFunc={() => addWelcomeMessage()}
          ></RoundButton>
          <RoundButton
            style={{ backgroundColor: "#EB4141", width: 130 }}
            title={"Discard"}
            pressFunc={() => discard()}
          ></RoundButton>
        </View>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { createWelcomeMessage })(
  WelcomeMessageforAdmin
);
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },

  textStyle: {
    marginTop: 70,
  },
  input: {
    borderBottomWidth: 1,
  },
  messageTextStyle: {
    fontSize: 20 * PixelRatio.getFontScale(),
    fontWeight: "bold",
    color: "#B4B2B2",
  },
  touchabelStyle: {
    marginHorizontal: 30,
    marginTop: 30,
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#707070",
    paddingBottom: 5,
    flexDirection: "row",
  },
});
