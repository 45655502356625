import * as React from "react";
import { useState } from "react";
import { View, Text, Image, StyleSheet } from "react-native";
import RoundButton from "../../components/roundButton";
import Icon from "../../components/icon";
import ProfileModal from "../../components/profileModal";
import adjust from "../../components/adjust";

const professionalProfileComponent = ({
  creatorData,
  bio,
  quotedAmount,
  selectOnPress,
  selected,
  quotation,
  Style,
  disableButtons,
  removeOnPress,
  applyingFor,
  selectedQuotation,
  validated,
  creatorType,
  navigation,
}) => {
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const handleCallback = (childData) => {
    setProfileModalVisible(childData);
  };

  return (
    <View style={[styles.container, Style]}>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <Image
            style={{
              height: 50,
              width: 50,
              borderRadius: 90 / 2,
            }}
            source={
              creatorData.image || creatorData.mimType
                ? {
                    uri: `data:${
                      creatorData.mimType ? creatorData.mimType : ""
                    };base64,${creatorData.image ? creatorData.image : ""}`,
                  }
                : require("../../assets/avatar/avatar.png")
            }
          ></Image>

          <View
            style={{
              flex: 1,
              marginLeft: 10,
              top: 0,
            }}
          >
            <Text style={{ color: "#333333" }}>
              {creatorData ? creatorData.levelOfWorkId : ""}
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(20),
                color: "#202020",
              }}
            >
              {creatorData ? creatorData.name : ""}
            </Text>
            {/* <Text
              style={{
                borderBottomWidth: 1,
                color: "#202020",
                borderBottomColor: "#202020",
              }}
            >
              {quotation
                ? creatorData.designation
                  ? creatorData.designation
                  : ""
                : creatorData.education
                ? creatorData.education
                : ""}
            </Text> */}
            {applyingFor?.length > 0 ? (
              <Text style={{ fontSize: adjust(13), fontWeight: "bold" }}>
                {applyingFor
                  ?.map(({ name }) => {
                    return name;
                  })
                  .join(", ")}
              </Text>
            ) : (
              <></>
            )}
          </View>
          {quotation ? (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                maxHeight: 30,
              }}
            >
              <Text
                style={{
                  fontSize: adjust(22),
                  fontWeight: "bold",
                  color: "#FED400",
                }}
              >
                {creatorData.rating ? creatorData.rating : 0}
              </Text>

              <Icon
                style={{ alignSelf: "flex-end", marginTop: 8, marginLeft: 3 }}
                name="rating"
              ></Icon>
            </View>
          ) : (
            <></>
          )}
        </View>
        {/* {quotation ? (
          <View
            style={{
              flex: 1,
              justifyContent: "space-around",
              borderBottomWidth: 1,
            }}
          >
            <Text style={{ color: "#606060", marginTop: 5, marginBottom: 10 }}>
              {bio}
            </Text>

          </View>
        ) : (
          <></>
        )} */}
      </View>
      {quotation ? (
        <View style={{ flex: 1, marginTop: 10 }}>
          <View style={{ flex: 1, flexDirection: "row", marginVertical: 10 }}>
            <Text style={{ fontWeight: "bold", fontSize: adjust(21) }}>
              Quotation:
            </Text>
            <Text style={{ fontWeight: "bold", fontSize: adjust(21) }}>
              ₹ {quotedAmount}
            </Text>
          </View>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <RoundButton
              title="View Profile"
              style={{
                flex: 1,
                borderRadius: 25,
                backgroundColor: "#FED400",
                marginRight: 5,
              }}
              pressFunc={() => setProfileModalVisible(true)}
            ></RoundButton>
            <RoundButton
              title={selected ? "Unselect" : "Select & Start"}
              style={{
                flex: 1,
                borderRadius: 25,
                backgroundColor: selected ? "#F33A3A" : "#000000",
                marginLeft: 5,
              }}
              pressFunc={selected ? removeOnPress : selectOnPress}
            ></RoundButton>
          </View>
        </View>
      ) : (
        <View style={{ flex: 0.5, flexDirection: "row", marginTop: 0 }}>
          <RoundButton
            title="View Profile"
            style={{
              flex: 1,
              borderRadius: 25,
              backgroundColor: "#FFF",
              marginRight: 5,
              borderWidth: 1,
            }}
            textStyle={{
              color: "#000",
            }}
            pressFunc={() => setProfileModalVisible(true)}
          ></RoundButton>
        </View>
      )}
      <ProfileModal
        disableButtons={disableButtons}
        creatorData={creatorData ? creatorData : {}}
        renderData={true}
        showModal={profileModalVisible}
        onSelection={handleCallback}
        quotation={quotation}
        navigation={navigation}
        selected={selected}
        creatorType={creatorType}
        pressFunc={selected ? removeOnPress : selectOnPress}
      ></ProfileModal>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#FED400",
    backgroundColor: "#FFFFFF",
    marginHorizontal: 15,
    padding: 10,
    // maxHeight: 400,
    // minHeight: 400,
    marginBottom: 10,
  },
});

export default professionalProfileComponent;
