import React, { useEffect, useState, Dimensions } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import SalesEarningScreen from "../../screens/Sales/sales_earning /salesEarningScreen";
import SalesManageFinanceScreen from "../../screens/Sales/sales_earning /salesManageFinanceScreen";
import SalesWithdrawScreen from "../../screens/Sales/sales_earning /salesWithdrawScreen";

const SalesStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({}) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="employee"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <SalesStack.Navigator initialRouteName="salesEarningScreen" name="employee">
      <SalesStack.Screen
        name="SalesEarningScreen "
        component={SalesEarningScreen}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="SalesManageFinanceScreen"
        component={SalesManageFinanceScreen}
        options={{ headerShown: false }}
      />
      <SalesStack.Screen
        name="SalesWithdrawScreen"
        component={SalesWithdrawScreen}
        options={{ headerShown: false }}
      />
    </SalesStack.Navigator>
  );
};
