import React, { useState } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import SelectItemWithCheckbox from "../../components/selectItemWithCheckbox/selectItemWithCheckbox";
import { fetchPartners } from "../../redux/actions/partner.admin.action";
import { useFocusEffect } from "@react-navigation/native";
import RoundButton from "../../components/roundButton";
const SelectPartner = ({
  fetchPartners,
  partnersByAdmin,
  onPressNext,
  setPartner,
}) => {
  const [selectBox, setSelectBox] = useState(false);

  const [partnerList, setPartnerList] = useState([]);

  useFocusEffect(
    React.useCallback(() => {
      fetchPartners();
    }, [])
  );
  useFocusEffect(
    React.useCallback(() => {
      let data = {
        name: "Let Onedesign Decide",
        about: "Logo Design One-Liner",
      };
      partnersByAdmin.unshift(data);
      setPartnerList([
        ...partnersByAdmin.map((x) => ({ ...x, selected: false })),
      ]);
    }, [partnersByAdmin])
  );

  const changeSelected = (partner) => {
    setPartnerList([
      ...partnerList.map((x) =>
        x._id === partner._id
          ? { ...x, selected: true }
          : { ...x, selected: false }
      ),
    ]);
  };
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ marginLeft: 20 }}>
          <Text style={{ fontSize: 20, color: "#000", fontWeight: "bold" }}>
            Select a Partner
          </Text>
          <Text style={{ color: "#B1B1B1", fontSize: 11, marginTop: 5 }}>
            Select all that you think is needed
          </Text>
        </View>
        <View style={styles.label}>
          <Text style={{ alignSelf: "center", color: "#fff" }}>?</Text>
        </View>
      </View>

      <ScrollView style={{ height: "100%" }} nestedScrollEnabled={true}>
        <View>
          {partnerList.map((partner, index) => {
            return (
              <View style={{ margin: 10 }} key={`${partner.title} + ${index}`}>
                <SelectItemWithCheckbox
                  borderWidth={1}
                  padding={10}
                  image={partner.name === "Let Onedesign Decide" ? false : true}
                  checked={partner.selected}
                  onPress={() => {
                    changeSelected(partner);
                    setPartner(partner);
                  }}
                  data={partner}
                ></SelectItemWithCheckbox>
              </View>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  nextPhoto: {
    backgroundColor: "#000",
    maxWidth: 400,
    width: "80%",
    marginLeft: 30,
    height: 70,
    marginVertical: 5,
    marginBottom: 20,
  },
  label: {
    height: 20,
    width: 20,
    borderRadius: 100,
    justifyContent: "center",
    backgroundColor: "#707070",
    marginTop: 6,
    marginRight: 20,
  },
});
const mapStateToProps = ({ user, partnersByAdmin }) => ({
  user,
  partnersByAdmin,
});
export default connect(mapStateToProps, {
  fetchPartners,
})(SelectPartner);
