import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/creator/dashboard";
import { Dimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { connect } from "react-redux";

import CreatorAllProjectBudgetScreen from "../../screens/creator/creatorAllProjectBudgetScreen";
import CreatorBudgetScreen from "../../screens/creator/creatorBudgetScreen";
import SalesInit from "../../screens/Client/new_sales_setup/sales-init";
import Bid from "../../screens/creator/bid";
import ChatWithUs from "../../screens/common/chat-with-us";
const window = Dimensions.get("window");
const CreatorNewStack = createStackNavigator();
const Drawer = createDrawerNavigator();

export default ({ user }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      // drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="creator"
        component={ProjectStack}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

const ProjectStack = ({}) => {

  return (
    <CreatorNewStack.Navigator
      name="new-projects"
      initialRouteName={"newProjects"}
    >
      <CreatorNewStack.Screen
        name="newProjects"
        component={CreatorAllProjectBudgetScreen}
        options={{ headerShown: false }}
      />
      <CreatorNewStack.Screen
        name="creatorBudgetScreen"
        component={CreatorBudgetScreen}
        options={{ headerShown: false }}
      />
      <CreatorNewStack.Screen
        name="bid"
        component={Bid}
        options={{ headerShown: false }}
      />
      <CreatorNewStack.Screen
        name="chat-with-us"
        component={ChatWithUs}
        options={{ headerShown: false }}
      />
    </CreatorNewStack.Navigator>
  );
};
