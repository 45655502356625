import { View, Text, StyleSheet, Pressable } from "react-native";
import React, { useState } from "react";

import { connect } from "react-redux";

import Header from "../../../components/header";
import UserSignUpFields from "../../../components/SignUpComponents/userSignUpCredentialFields";
import LargeTextInput from "../../../components/largeTextInput";
import { updateProfile } from "../../../redux/actions/user.action";
import { useFocusEffect } from "@react-navigation/native";

const CreatorWorklinkScreen = ({ navigation, user, updateProfile }) => {
  useFocusEffect(
    React.useCallback(() => {
      () => {
        setProfiles(initData);
      };
    }, [])
  );
  const initData = {
    behanceProfile: "",
    dribbleProfile: "",
    instaProfile: "",
    bestWork: "",
  };
  const [profiles, setProfiles] = useState(initData);
  const setDataToProfile = (data) => {
    setProfiles({ ...profiles, ...data });
  };
  const next = () => {
    updateProfile({ email: user.email, ...profiles }, navigateAfterSuccess);
  };
  const navigateAfterSuccess = () => {
    navigation.navigate("finalStep");
  };

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Project Details"
          subtitle="Tell us more aboute Your Intersts"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#FF973E" }}
        ></Header>
      </View>
      <View style={{ flex: 8 }}>
        <Text style={styles.headerStyle}>
          show us a few samples of your work
        </Text>
        <LargeTextInput
          placeholder="enter link to  your Behance"
          style={{ marginTop: 50, borderBottomColor: "#707070", height: 100 }}
          onChangeText={(data) => setDataToProfile({ behanceProfile: data })}
        ></LargeTextInput>
        <View style={styles.wrapperStyle}>
          <LargeTextInput
            placeholder="enter link to  your Dribble"
            style={{ borderBottomColor: "#707070" }}
            onChangeText={(data) => setDataToProfile({ dribbleProfile: data })}
          ></LargeTextInput>
        </View>
        <View style={styles.wrapperStyle}>
          <LargeTextInput
            placeholder="enter your best Work"
            style={{ borderBottomColor: "#707070" }}
            onChangeText={(data) => setDataToProfile({ bestWork: data })}
          ></LargeTextInput>
        </View>
        <View style={styles.wrapperStyle}>
          <LargeTextInput
            placeholder="enter your link to Instagram"
            style={{ borderBottomColor: "#707070" }}
            onChangeText={(data) => setDataToProfile({ instaProfile: data })}
          ></LargeTextInput>
        </View>
      </View>
      <Pressable style={[styles.pressableStyle]} onPress={() => next()}>
        <Text
          style={[
            {
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: "bold",
              alignSelf: "center",
              justifyContent: "center",
            },
          ]}
        >
          {"Next"}
        </Text>
      </Pressable>
    </View>
  );
};
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#FFF" },
  buttonStyle: {
    backgroundColor: "#000",
    height: 70,
    marginVertical: 5,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
  wrapperStyle: {
    marginTop: 70,
  },
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    margin: 25,
    color: "#000",
  },
});
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { updateProfile })(
  CreatorWorklinkScreen
);
