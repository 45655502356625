import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import Constants from "expo-constants";

const registerForPushNotificationsAsync = async () =>
  new Promise(async (resolve, reject) => {
    if (Platform.OS !== "web") {
      if (Constants.isDevice) {
        const { status: existingStatus } =
          await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== "granted") {
          console.log("Permission Grantted ? ");
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== "granted") {
          alert("Failed to get push token for push notification!");
          return;
        }
        if (Platform.OS === "android") {
          Notifications.setNotificationChannelAsync("default", {
            name: "default",
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: "#FF231F7C",
          });
        }
        // Notifications.addNotificationsDroppedListener(() => {
        //   alert("notification Dropped");
        // });
        Notifications.getExpoPushTokenAsync({
          experienceId: process.env.SLUG || "@yoctotta/oneDesignUI",
        })
          .then(
            (result) => {
              resolve({
                platform: Platform.OS,
                token: result.data,
              });
            },
            (error) => {
              console.error(error);
              reject(error);
            }
          )
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      } else {
        reject("Must use physical device for Push Notifications");
      }
    } else if (Platform.OS === "web") {
      // firebase
      //   .messaging()
      //   .getToken({
      //     vapidKey:
      //       "BHh-WXWyO8q_QC6biAg4EEnBQgpaKbVyDUfJt5MB22reMU6zchBdlRxGEHwmbKjyv2_Sj1OTsuP4H5k9aiy-_vI",
      //   })
      //   .then(
      //     (result) => {
      //       resolve({
      //         platform: Platform.OS,
      //         token: result,
      //       });
      //     },
      //     (error) => console.error(error)
      //   );
    }
  });

const initialState = null;
const deviceToken = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DEVICE_TOKEN": {
      console.log("Setup Device Token Called");
      registerForPushNotificationsAsync()
        .then((data) => {
          action.asyncDispatch({
            type: "SAVE_DEVICE_TOKEN",
            payload: {
              request: {
                url: "api/notify",
                method: "POST",
                data: data,
              },
            },
          });
          return data;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
      return state;
    }
    case "SAVE_DEVICE_TOKEN":
      return action.payload.request.data;

    case "LOGOUT":
      action.asyncDispatch({
        type: "REMOVE_DEVICE_TOKEN_BE",
        payload: {
          request: {
            url: "api/notify",
            method: "DELETE",
            data: {
              token: state?.token,
            },
          },
        },
      });
      return null;

    // case "REMOVE_DEVICE_TOKEN": {
    //   // await Notifications.deleteNotificationChannelAsync(action.payload.userId);
    //   action.asyncDispatch({
    //     type: "REMOVE_DEVICE_TOKEN_BE",
    //     payload: {
    //       request: {
    //         url: "api/notify",
    //         method: "DELETE",
    //         data: {
    //           token: globalToken,
    //         },
    //       },
    //     },
    //   });
    //   return null;
    // }
    default:
      return state;
  }
};
export default deviceToken;
