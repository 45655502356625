import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Icon from "../icon";
import SelectItemWithCheckbox from "../selectItemWithCheckbox/selectItemWithCheckbox";
import RoundButton from "../roundButton";

const CreatorBidProfileComponent = ({
  ratings,
  source,
  name,
  amount,
  checkBox,
  buttontext,
  buttonColor,
  profile,
  roundButton,
  Quotation,
  starIcon,
  onPress,
  checked,
}) => {
  const dimension = useWindowDimensions();

  let data = {
    name: `${checked ? "Remove" : "Finalise"} Creator`,
    description: checked ? "" : "You can uncheck later if you found better",
  };
  return (
    <View style={styles.containerStyle}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Image
          source={require("../../assets/avatar/avatar.png")}
          style={styles.imageStyle}
        ></Image>
        <View style={{ flexDirection: "row", alignSelf: "center" }}>
          {starIcon ? (
            <View style={styles.iconContainer}>
              <Icon
                fill={"#FFF"}
                style={{ alignSelf: "center" }}
                name={"starIcon"}
              ></Icon>
            </View>
          ) : (
            <></>
          )}
          <Text style={styles.ratingsValueStyle}>{ratings}</Text>
        </View>
      </View>
      <TouchableOpacity style={styles.touchStyle}>
        <View>
          <Text style={styles.nameStyle}>{name}</Text>
          {profile ? <Text style={styles.tapStyle}>{profile}</Text> : <></>}
        </View>
        <View style={{ justifyContent: "center" }}>
          <Icon
            width={40}
            fill={"#000"}
            style={{ alignSelf: "center" }}
            name={"rightArrow"}
          ></Icon>
        </View>
      </TouchableOpacity>
      <View style={styles.lineStyle}></View>
      <Text style={{ fontSize: 11, margin: 5 }}>{Quotation}</Text>
      <Text style={styles.amountStyle}>INR {amount}</Text>
      {checkBox ? (
        <SelectItemWithCheckbox
          fill={"#FFF"}
          descriptionColor={"#FFF"}
          titleColor={"#FFF"}
          backgroundColor={"#7A9D54"}
          borderWidth={2}
          data={data}
          onPress={onPress}
          checked={checked}
          style={{ marginHorizontal: 0, width: dimension.width - 50 }}
        ></SelectItemWithCheckbox>
      ) : (
        <></>
      )}

      {roundButton ? (
        <RoundButton
          title={buttontext}
          pressFunc={onPress ? onPress : {}}
          style={{
            backgroundColor: buttonColor,
            width: 200,
            marginLeft: 10,
          }}
        ></RoundButton>
      ) : (
        <></>
      )}
    </View>
  );
};

export default CreatorBidProfileComponent;

const styles = StyleSheet.create({
  containerStyle: {
    borderRadius: 10,
    padding: 15,
    backgroundColor: "#FFF",
    marginTop: 10,
    borderWidth: 0.5,
    borderColor: "#000",
  },
  imageStyle: {
    height: 60,
    width: 60,
    borderRadius: 100,
  },
  iconContainer: {
    backgroundColor: "#FFC400",
    height: 24,
    width: 24,
    borderRadius: 50,
    justifyContent: "center",
    margin: 5,
  },
  ratingsValueStyle: {
    color: "#FFC400",
    fontWeight: "bold",
    fontSize: 22,
  },
  nameStyle: {
    fontSize: 20,
    color: "#000",
    fontWeight: "bold",
  },
  tapStyle: {
    fontSize: 13,
    color: "#000",
    marginTop: 5,
  },
  touchStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  lineStyle: {
    borderBottomWidth: 1,
    borderBottomColor: "#707070",
    marginTop: 10,
  },
  amountStyle: {
    fontSize: 21,
    color: "#000",
    fontWeight: "bold",
    margin: 5,
  },
});
