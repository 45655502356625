import { useFocusEffect } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View, Text, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import RoundButton from "../../components/roundButton";
import SelectItemWithCheckbox from "../../components/selectItemWithCheckbox/selectItemWithCheckbox";
import { fetchCatalog } from "../../redux/actions/project-catalog.action";
import { fetchIndividualService } from "../../redux/actions/individualService.action";
const SelectIndividualService = ({
  individualServices,
  fetchCatalog,
  setSelectedProject,
  pressFunc,
  fetchIndividualService,
}) => {
  useEffect(() => {
    fetchIndividualService();
    fetchCatalog();
  }, []);

  const [services, setServices] = useState([]);
  useFocusEffect(
    React.useCallback(() => {
      setServices([
        ...individualServices.map((x) => ({ ...x, selected: false })),
      ]);
    }, [individualServices])
  );
  const changeSelected = (project) => {
    setServices([
      ...individualServices.map((x) =>
        x._id === project._id
          ? { ...x, selected: true }
          : { ...x, selected: false }
      ),
    ]);
  };
  return (
    <View style={{ flex: 1 }}>
      <View style={{ marginHorizontal: 20 }}>
        <Text style={{ fontSize: 20, color: "#000", fontWeight: "bold" }}>
          Tell us all that you need
        </Text>
        <Text style={{ color: "#B1B1B1", fontSize: 11, margin: 5 }}>
          Select all that you think is needed
        </Text>
      </View>

      <ScrollView nestedScrollEnabled={true}>
        <View>
          {services.map((service, index) => {
            return (
              <View style={{ margin: 10 }} key={`${service.title} + ${index}`}>
                <SelectItemWithCheckbox
                  backgroundColor={false}
                  borderWidth={1}
                  padding={10}
                  checked={service.selected}
                  onPress={() => {
                    setSelectedProject(service);
                    changeSelected(service);
                  }}
                  data={service}
                ></SelectItemWithCheckbox>
              </View>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  nextPhoto: {
    backgroundColor: "#000",
    maxWidth: 400,
    width: "80%",
    marginTop: 100,
    marginLeft: 30,
    height: 70,
    marginVertical: 5,
    marginBottom: 20,
  },
  label: {
    height: 20,
    width: 20,
    borderRadius: 100,
    justifyContent: "center",
    backgroundColor: "#707070",
    marginTop: 6,
  },
});
const mapStateToProps = ({ user, individualServices }) => ({
  user,
  individualServices,
});
export default connect(mapStateToProps, {
  fetchCatalog,
  fetchIndividualService,
})(SelectIndividualService);
