import { addError } from "../actions/toast.action";

const initialState = [];
const meetings = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MEETINGS_SUCCESS": {
      return action.payload.data;
    }
    
    case "UPDATE_MEETING_SUCCESS": {
      return state.map(x => x._id === action.payload.data._id ? action.payload.data : x) ;
    }

    case "UPDATE_MEETING_FAIL":
    case "FETCH_MEETINGS_FAIL": {
      action.asyncDispatch(addError(action.error.response?.data.message, 3000));
      return state;
    }

    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default meetings;
