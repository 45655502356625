import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  Platform,
  Dimensions,
} from "react-native";
import React, { useEffect } from "react";
import HelpBox from "../../components/chat/helpBox";
import Header from "../../components/header";
import {
  clearHelpMessages,
  createHelp,
  fetchHelp,
  uploadHelpMedia,
} from "../../redux/actions/help.action";
import { connect } from "react-redux";
import HelpBubble from "../../components/chat/help-bubble";

const ChatWithUs = ({
  user,
  fetchHelp,
  sendMessage,
  navigation,
  uploadHelpMedia,
  clearHelpMessages,
  route,
  helpMessages,
  createHelp,
}) => {
  const window = Dimensions.get("window");

  useEffect(() => {
    fetchHelp(route.params?.from || "ADMIN");
  }, [route]);

  useEffect(() => {
    return () => {
      clearHelpMessages();
    };
  }, []);
  const onScroll = () => {};
  const checkPermission = async (file) => {
    // Function to check the platform
    // If Platform is Android then check for permissions.

    if (Platform.OS === "ios") {
      // downloadFile();
    } else if (Platform.OS === "android") {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          {
            title: "Storage Permission Required",
            message:
              "Application needs access to your storage to download File",
          }
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          // Start downloading
          downloadFile(file);
        } else {
          // If permission denied then show alert
          Alert.alert("Error", "Storage Permission Not Granted");
        }
      } catch (err) {
        // To handle permission related exception
        console.log("++++" + err);
      }
    }
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: "18%",
          maxHeight: "18%",
        }}
      >
        <Header
          title={"Chat with Us"}
          subtitle="Talk to our customer care"
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          disableProfile={true}
          navigation={navigation}
          enableAvatar={false}
        ></Header>
      </View>

      <FlatList
        style={{
          paddingBottom: 30,
          backgroundColor: "#ECECEC",
          // flex: 1
          maxHeight: window.height - window.height * 0.18 - 100,
          minHeight: window.height - window.height * 0.18 - 100,
        }}
        contentContainerStyle={{ paddingBottom: 30 }}
        nestedScrollEnabled={true}
        data={helpMessages}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => (
          <HelpBubble
            msg={item}
            user={user}
            checkPermission={checkPermission}
            navigation={navigation}
          ></HelpBubble>
        )}
        inverted
        onEndReached={() => onScroll()}
        onEndReachedThreshold={0.5}
      />

      {/* <View> */}
      <HelpBox
        onSend={(message) =>
          createHelp({
            message: message,
            to: route.params?.from || "ADMIN",
          })
        }
        uploadHelpMedia={(data) => uploadHelpMedia(data)}
      ></HelpBox>
      {/* </View> */}
    </View>
  );
};

const mapStateToProps = ({ user, helpMessages }) => ({ user, helpMessages });
export default connect(mapStateToProps, {
  fetchHelp,
  createHelp,
  clearHelpMessages,
  uploadHelpMedia,
})(ChatWithUs);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    justifyContent: "space-between",
  },
});
