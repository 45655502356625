import { StyleSheet, Text, View } from "react-native";
import Header from "../../components/header";
import React from "react";
import LargeButton from "../../components/largeButton";

import BudgetCardContainer from "../../components/budget /budgetCardContainer";
import { connect } from "react-redux";

const CreatorBudgetScreen = ({ navigation, selectedProject }) => {
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title={selectedProject.title}
          subtitle={`Expected Completion Time : ${Math.round(
            Math.abs(
              (new Date(selectedProject.deadLine) - new Date()) / 86400000
            )
          )} Days`}
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#000000" }}
        ></Header>
      </View>
      <View style={{ flex: 1, backgroundColor: "#FFF" }}>
        <View
          style={{
            flexDirection: "row",
            backgroundColor: "#FFF",
            marginHorizontal: 30,
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: "bold" }}>work as a </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "#FF973E",
            }}
          >
            {selectedProject.requiredTeam.type?.name}
          </Text>
        </View>
        <BudgetCardContainer
          changeText={(e) => setBudget(e)}
          budget={`${selectedProject.requiredTeam.budget}`}
        />
        <View style={{ marginHorizontal: 10 }}>
          <Text style={styles.Edit_Text}>Partner's Brief</Text>
          <Text style={{ marginTop: 10, fontSize: 11, marginHorizontal: 15 }}>
            {selectedProject.requiredTeam.brief}
          </Text>
        </View>
        <View
          style={{
            bottom: 10,
            position: "absolute",
            width: "90%",
            marginHorizontal: 15,
          }}
        >
          <LargeButton
            arrowColor={"#FFF"}
            titleStyle={{ color: "#FFF" }}
            backgroundColor={"#000"}
            title={"Place a Bid"}
            pressFunc={() => navigation.navigate("bid")}
          ></LargeButton>
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = ({ selectedProject }) => ({
  selectedProject,
});

export default connect(mapStateToProps, {})(CreatorBudgetScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  Edit_Text: {
    marginTop: 30,
    fontWeight: "bold",
    color: "#828282",
    fontSize: 16,
    marginHorizontal: 15,
  },
});
