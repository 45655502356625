import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { connect } from "react-redux";
import ContainerWithDifferentBg from "../../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import DashboardCardWithArrowAndIcon from "../../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import LargeButton from "../../../components/largeButton";
import Header from "../../../components/header";
import { acceptProject } from "../../../redux/actions/project.action";

const ProjectSetupStatus = ({ navigation, selectedProject, acceptProject }) => {
  const startProject = () => {
    acceptProject(selectedProject._id, () => navigation.navigate("myProjects"));
  };
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          minHeight: "18%",
          maxHeight: "18%",
        }}
      >
        <Header
          title={`project setup`}
          subtitle={"Setup Status"}
          enableAvatar={true}
          navigation={navigation}
          backFunction={() => navigation.goBack()}
        ></Header>
      </View>
      <View
        style={{
          backgroundColor: "#FFF",
          paddingHorizontal: 10,
          marginVertical: 10,
        }}
      >
        <ContainerWithDifferentBg
          titleFontSize={20}
          projectFor={`for ${selectedProject.user?.fName} ${selectedProject.user?.lName}`}
          titleColor="#FFF"
          title={selectedProject.title}
          backgroundColor={"#000"}
        ></ContainerWithDifferentBg>
        <DashboardCardWithArrowAndIcon
          source={require("../../../assets/user-shapes.png")}
          description={
            selectedProject.requiredTeam
              ? `You have selected ${selectedProject.requiredTeam.members.reduce(
                  (a, b) => a + b.quotations.filter((x) => x.accepted).length,
                  0
                )}/${selectedProject.requiredTeam.members.reduce(
                  (a, b) => a + b.nos,
                  0
                )} creators`
              : "Creator Requirement not filled up yet!"
          }
          value={`${
            selectedProject.requiredTeam
              ? selectedProject.requiredTeam.members.reduce(
                  (x, y) => x + y.quotations.length,
                  0
                )
              : 0
          }`}
          title="Creator Quotations"
          backgroundColor="#7A95C1"
          onPressCard={() => navigation.navigate("selectCreator")}
        ></DashboardCardWithArrowAndIcon>
        <View style={styles.bottonContainer}>
          <LargeButton
            pressFunc={() => navigation.navigate("newProjectSetup")}
            arrowColor={"#FFF"}
            backgroundColor={"#000"}
            titleStyle={{ color: "#FFF" }}
            title={"Edit Project Details"}
          ></LargeButton>
          <LargeButton
            pressFunc={startProject}
            arrowColor={"#FFF"}
            style={{ marginTop: 10 }}
            backgroundColor={"#3A8CD1"}
            titleStyle={{ color: "#FFF" }}
            title={"Start Project"}
          ></LargeButton>
        </View>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, {
  acceptProject,
})(ProjectSetupStatus);

const styles = StyleSheet.create({
  bottonContainer: {
    height: "100%",
  },
});
