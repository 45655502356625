import {
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import Header from "../../components/header";
import adjust from "../../components/adjust";
import Checkbox from "../../components/checkbox";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import { connect } from "react-redux";
import { fullName } from "../../components/utils";

const EndProjectConfirmationScreen = ({
  route,
  navigation,
  selectedProject,
}) => {
  const checkListArray = [
    {
      name: "Client Payments Done",
      description: "Check all payments settled from client side",
      backgroundColor: "#7A9D54",
    },
    {
      name: "Creator Payouts Done",
      description: "Check if all the creators are paid",
      backgroundColor: "#FE973F",
    },
    {
      name: "Deliverables Sent",
      description: "Check if all the deliverables are sent to client",
      backgroundColor: "#FE973F",
    },
  ];
  return (
    <SafeAreaView style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="end project"
          subtitle="close the project"
          backFunction={() => navigation.goBack()}
          enableAvatar={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={[styles.projectTitleContainer, { backgroundColor: "#000" }]}>
        <Text style={styles.mainHeader}>{selectedProject?.title}</Text>
        <Text style={styles.clientName}>{`Client: ${fullName(
          selectedProject.user
        )}`}</Text>
      </View>
      <View
        style={{
          padding: 5,
          backgroundColor: "#FFFFFF",
          borderRadius: 10,
          marginHorizontal: 10,
        }}
      >
        <Text
          style={{
            fontSize: 15,
            fontWeight: "bold",
            color: "#828282",
            marginHorizontal: 20,
            marginVertical: 5,
          }}
        >
          Project Ending Checklist
        </Text>
        {checkListArray.map((item) => (
          <View
            key={item.name}
            style={[
              styles.checkBoxStyle,
              { backgroundColor: item.backgroundColor },
            ]}
          >
            <View>
              <Text style={{ color: "#FFF", fontSize: 16, fontWeight: "bold" }}>
                {item.name}
              </Text>
              <Text style={{ color: "#FFF", fontSize: 11, marginVertical: 5 }}>
                {item.name}
              </Text>
            </View>
            <Checkbox></Checkbox>
          </View>
        ))}
      </View>
      <View style={styles.buttonStyle}>
        <DashboardCardWithArrowAndIcon
          style={{ marginHorizontal: 10 }}
          title="Close Project"
          value=""
          icon={false}
          backgroundColor="#000"
          onPressCard={() => {
            navigation.navigate("end-project");
          }}
        ></DashboardCardWithArrowAndIcon>
      </View>
    </SafeAreaView>
  );
};
const mapStateToProps = ({ selectedProject }) => ({ selectedProject });
export default connect(mapStateToProps, {})(EndProjectConfirmationScreen);

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  titleText: {
    padding: 8,
    fontSize: adjust(16),
    fontWeight: "bold",
  },
  textStyle: {
    fontSize: adjust(23),
    color: "#000",
    marginTop: 15,
    marginLeft: 5,
  },
  checkBoxStyle: {
    padding: 10,
    marginHorizontal: 10,

    marginVertical: 10,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonStyle: {
    flexDirection: "column-reverse",
    flex: 1,
    marginVertical: 10,
  },
  buttonTextStyle: {
    color: "#fff",
    textAlign: "center",
  },
  customRatingBarStyle: {
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 5,
  },
  projectTitleContainer: {
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
    marginHorizontal: 10,
  },
  mainHeader: {
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#ffffff",
    margin: 10,
  },
  clientName: {
    fontSize: 11,
    color: "#FFFFFF",
    marginHorizontal: 10,
    marginVertical: 5,
  },
});
