import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PixelRatio,
} from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import LargeTextInput from "../../../components/largeTextInput";
import TeamProfileComponent from "../../../components/teamEachProfileComponent/teamEachProfileComponent";
import RoundButton from "../../../components/roundButton";
import Icon from "../../../components/icon";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from "moment";
import { createProject } from "../../../redux/actions/project.action";
import adjust from "../../../components/adjust";
import NextAndDiscardButton from "../../../components/nextAndDiscardButton/nextAndDiscardButton";

const NewProjectSetup = ({
  user,
  navigation,
  selectedProject,
  createProject,
}) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});

  useEffect(() => {
    initialSetup();
  }, [selectedProject]);

  const updateProjectAndNavigate = () => {
    createProject({
      ...projectDetails,
      _id: selectedProject._id,
    });
    navigation.navigate("projectMilestones");
  };

  const initialSetup = () => {
    setProjectDetails({
      title: selectedProject.title,
      deadLine: selectedProject.deadLine,
      discussedBudget: selectedProject.discussedBudget,
    });
  };
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          title="new project setup"
          subtitle="Setup a new project"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Text style={styles.titleStyle}>
          enter basic details about the new project
        </Text>
        <View style={styles.wrapperStyle}>
          <LargeTextInput
            placeholder="give a name to the project"
            style={{ borderBottomColor: "#707070" }}
            onChangeText={(data) =>
              setProjectDetails({ ...projectDetails, title: data })
            }
            value={projectDetails.title}
          ></LargeTextInput>
        </View>

        {/* <TouchableOpacity
          onPress={() => setDatePickerVisibility(true)}
          style={[
            styles.wrapperStyle,
            {
              justifyContent: "space-between",
              backgroundColor: "#fff",
              borderBottomWidth: 1,
              borderBottomColor: "#707070",
              flexDirection: "row",
            },
          ]}
        >
          <LargeTextInput
            //value={`${date}`}
            placeholder="select date and time"
            // style={{ borderBottomColor: "#707070" }}
            value={
              projectDetails.deadLine
                ? moment(projectDetails.deadLine).format("DD/MM/YYYY HH:mm")
                : ""
            }
            //   onChangeText={(data) => onChangeField({ email: data })}
          ></LargeTextInput>
       
          <Icon
            style={{ alignSelf: "center" }}
            fill="#707070"
            height="30"
            width="30"
            name="meeting"
          ></Icon>
        </TouchableOpacity> */}
        <TouchableOpacity
          onPress={() => setDatePickerVisibility(true)}
          style={[
            styles.wrapperStyle,
            {
              // width: win,
              marginHorizontal: 30,
              justifyContent: "space-between",
              backgroundColor: "#fff",
              // position: "absolute",
              borderBottomWidth: 1,
              borderBottomColor: "#707070",
              flexDirection: "row",
            },
          ]}
        >
          <Text
            style={{
              fontSize: adjust(20) * PixelRatio.getFontScale(),
              fontWeight: "bold",
              color: projectDetails.deadLine ? "#000" : "#B2AFAF",
              alignSelf: "auto",
            }}
          >
            {projectDetails.deadLine
              ? moment(projectDetails.deadLine).format("DD/MM/YYYY HH:mm")
              : "select project deadline"}
          </Text>

          <Icon
            style={{ alignSelf: "flex-end", marginBottom: 10 }}
            fill="#707070"
            height="30"
            width="30"
            name="meeting"
          ></Icon>
        </TouchableOpacity>
        <View style={{ marginTop: 70 }}>
          <LargeTextInput
            placeholder="enter finalised budget"
            style={{ borderBottomColor: "#707070" }}
            onChangeText={(data) =>
              setProjectDetails({ ...projectDetails, discussedBudget: data })
            }
            value={`${projectDetails.discussedBudget || ""}`}
            keyboardType={"numeric"}
          ></LargeTextInput>
        </View>
        <DateTimePickerModal
          isVisible={isDatePickerVisible}
          onConfirm={(d) => {
            setProjectDetails({ ...projectDetails, deadLine: d });
            setDatePickerVisibility(false);
          }}
          mode={"datetime"}
          is24Hour={true}
          format="YYYY-MM-DD"
          onCancel={() => setDatePickerVisibility(false)}
        />
        <NextAndDiscardButton
          onPressDiscard={() => setProjectDetails({})}
          onPressNext={() => updateProjectAndNavigate()}
        />
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, { createProject })(NewProjectSetup);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
  wrapperStyle: {
    marginTop: 40,
  },
  titleStyle: {
    fontWeight: "bold",
    fontSize: 25,
    margin: 25,
    color: "#000",
  },
});
