const initialState = 0;
const notificationCount = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NOTIFICATION_COUNT_SUCCESS": {
      return action.payload.data;
    }

    case "CLEAR_NOTIFICATION_SUCCESS":
    case "LOGOUT": {
      return 0;
    }

    case "MARK_AS_READ_SUCCESS": {
      return state - 1;
    }
    default:
      return state;
  }
};
export default notificationCount;
