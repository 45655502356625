const initialState = [];
const projectAudience = (state = initialState, action) => {
  switch (action.type) {

    case "FETCH_AUDIENCE_SUCCESS": {
      return action.payload.data;
    }

    case "CREATE_AUDIENCE_SUCCESS": {
      let audience = state.find(
        (a) => a._id == action.payload.data._id
      );
      return audience
        ? state.map((p) => (p._id == audience._id ? action.payload.data : p))
        : [...state, action.payload.data];
    }

    case "REMOVE_AUDIENCE_SUCCESS": {
      let index = state.findIndex(x => x._id === action.payload.data._id);
      state.splice(index, 1);
      return [...state]
    }

    case "LOGOUT": {
      return {};
    }

    default:
      return state;
  }
};
export default projectAudience;
