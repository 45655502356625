export const fetchPendingProjects = () => (dispatch) => {
  return dispatch({
    type: "FETCH_PENDING_PROJECTS",
    payload: {
      request: {
        url: `/api/project/fetch`,
        method: "POST",
        data: { pending: true },
      },
    },
  });
};
