import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  ScrollView,
  FlatList,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState, useReducer } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import LargeTextInput from "../../../components/largeTextInput";
import LargeButton from "../../../components/largeButton";
import RoundButton from "../../../components/roundButton";
import { fetchProjectRole } from "../../../redux/actions/projectRole.action";
import { createProject } from "../../../redux/actions/project.action";
import NextAndDiscardButton from "../../../components/nextAndDiscardButton/nextAndDiscardButton";
import Icon from "../../../components/icon";

const quantityArray = Array.from({ length: 50 }, (a, i) => ({
  name: i,
}));
const remove = quantityArray.shift();
const initialObject = [
  {
    role: "",
    quantity: "",
    budget: "",
    brief: "",
  },
];
const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TEAM":
      return [...state, ...initialObject];
    case "ADD_DATA":
      return [
        ...state.map((e, i) =>
          i === action.index ? { ...e, ...action.value } : { ...e }
        ),
      ];
    case "REMOVE_TEAM":
      return state.filter((x, i) => i != action.index);
    case "INIT":
      return [...initialObject];
    case "INIT_DATA":
      return action.value;
    case "ADD_CREATOR":
      return [...state, initialObject];

    default:
      return state;
  }
};
const CreatorCard = ({ item, index, setTeam, projectRole, team }) => (
  <View style={styles.textInputContainer}>
    <Text
      style={{
        color: "#818181",
        fontSize: 16,
        fontWeight: "bold",
        marginLeft: 1,
      }}
    >
      {`Creator ${index + 1}`}
    </Text>
    {team?.length > 1 ? (
      <TouchableOpacity
        style={{ position: "absolute", right: 7, top: 7 }}
        onPress={() => {
          setTeam({ type: "REMOVE_TEAM", index: index });
        }}
      >
        <Icon name={"cross"}></Icon>
      </TouchableOpacity>
    ) : (
      <></>
    )}
    <Text
      style={{
        color: "#000",
        fontSize: 13,
        marginTop: 10,
        marginLeft: 1,
      }}
    >
      Enter details on the creator
    </Text>

    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
        marginTop: 5,
      }}
    >
      <View style={{ marginVertical: 20, height: 55 }}>
        <LargeTextInput
          selectedValue={item.type}
          onSelectionChange={(e) =>
            setTeam({ type: "ADD_DATA", index, value: { type: e } })
          }
          renderData={projectRole}
          placeholder={"enter creator role"}
        ></LargeTextInput>
      </View>
      <View style={{ marginVertical: 20, height: 55 }}>
        <LargeTextInput
          onSelectionChange={(e) =>
            setTeam({ type: "ADD_DATA", index, value: { quantity: e.name } })
          }
          selectedValue={item.quantity ? { name: item.quantity } : null}
          renderData={quantityArray}
          placeholder={"enter creator quantity"}
        ></LargeTextInput>
      </View>
      <View style={{ marginVertical: 20, height: 55 }}>
        <LargeTextInput
          style={{ marginHorizontal: 0 }}
          placeholder={"enter creator budget"}
          keyboardType={"numeric"}
          onChangeText={(e) =>
            setTeam({
              type: "ADD_DATA",
              index,
              value: { budget: e },
            })
          }
          value={item.budget}
        ></LargeTextInput>
      </View>
      <View style={{ marginVertical: 20, height: 55 }}>
        <LargeTextInput
          style={{ marginHorizontal: 0 }}
          width={"100%"}
          placeholder={"enter brief for creator"}
          onChangeText={(e) =>
            setTeam({
              type: "ADD_DATA",
              index,
              value: { brief: e },
            })
          }
          value={item.brief}
        ></LargeTextInput>
      </View>
    </View>
  </View>
);
const CreatorTeam = ({
  user,
  navigation,
  projectRole,
  fetchProjectRole,
  createProject,
  selectedProject,
}) => {
  const dimension = useWindowDimensions();
  useEffect(() => {
    fetchProjectRole();
  }, []);

  const [team, setTeam] = useReducer(reducer, initialObject);
  const updateAndNavigate = () => {
    createProject(
      {
        _id: selectedProject._id,
        requiredTeam: {
          members: team
            .filter((x) => x.type && x.quantity && x.budget)
            .map((x) => ({
              type: x.type._id,
              nos: x.quantity,
              budget: x.budget,
              brief: x.brief,
            })),
        },
      },
      navigateAfterSuccess
    );
  };
  const navigateAfterSuccess = () => navigation.navigate("selectCreator");
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          title="new project setup"
          subtitle="Setup a new project"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          avatarSource={{
            uri: `data:${user.mimType};base64,${user.image}`,
          }}
        ></Header>
      </View>
      <ScrollView>
        <Text style={styles.titleStyle}>
          create a team of creators from the pool
        </Text>
        <FlatList
          style={{
            maxHeight: dimension.height - 430,
            minHeight: dimension.height - 430,
          }}
          nestedScrollEnabled
          scrollEnabled
          data={team}
          keyExtractor={(item, index) => item._id + " " + index}
          renderItem={({ item, index }) => (
            <CreatorCard
              team={team}
              item={item}
              index={index}
              setTeam={setTeam}
              projectRole={projectRole}
            />
          )}
        />

        <View style={{ marginHorizontal: 10, minHeight: 80 }}>
          <LargeButton
            pressFunc={() => setTeam({ type: "ADD_TEAM" })}
            arrowColor={"#FFF"}
            textColor={"#FFF"}
            backgroundColor={"#000"}
            title={"Add a Creator +"}
          ></LargeButton>
        </View>
        <NextAndDiscardButton
          onPressDiscard={() => setTeam({ type: "INIT" })}
          onPressNext={() => updateAndNavigate()}
        />
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user, projectRole, selectedProject }) => ({
  user,
  projectRole,
  selectedProject,
});
export default connect(mapStateToProps, { fetchProjectRole, createProject })(
  CreatorTeam
);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
  textInputContainer: {
    margin: 10,
    padding: 15,
    borderRadius: 8,
    backgroundColor: "#FFF",
  },
  titleStyle: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#000",
    margin: 20,
  },
});
