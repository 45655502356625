import { addError, addInfo, addWarning } from "../actions/toast.action";
import _ from "lodash";

const initialState = {
  notifications: [],
  fetch: true,
};
const notifications = (state = initialState, action) => {
  switch (action.type) {
    case "SEEN_ALL_NOTIFICATION_SUCCESS":
    case "FETCH_NOTIFICATION_SUCCESS": {
      if (
        action.meta.previousAction.payload.request.url.includes(
          "lastNotification"
        )
      ) {
        return {
          fetch: action.payload.data.fetch,
          notifications: _.unionBy(
            state.notifications || [],
            action.payload.data.notifications,
            "_id"
          ),
        };
      } else {
        return action.payload.data;
      }
    }

    case "MARK_AS_READ_SUCCESS": {
      let temp = state.notifications.findIndex(
        (x) => x._id === action.payload.data._id
      );
      return {
        ...state,
        notifications: [
          ...state.notifications.map((x, i) =>
            i === temp ? action.payload.data : x
          ),
        ],
      };
    }

    case "CLEAR_NOTIFICATION_SUCCESS": {
      return {
        notifications: [],
        fetch: false,
      };
    }

    case "FETCH_NOTIFICATION_FAIL":
    case "CLEAR_NOTIFICATION_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "LOGOUT": {
      return [];
    }

    default:
      return state;
  }
};
export default notifications;
