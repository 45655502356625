import { StyleSheet, Text, View, ScrollView } from "react-native";
import React from "react";
import PerformanceComponent from "../performanceComponent";

import Header from "../../../components/header";
import LargeTextInput from "../../../components/largeTextInput";

const PerformanceScreen = ({ navigation }) => {
  const performances = [
    {
      name: "Peter Parker",
      title: "Core Branding Partner",
      src: require("../../../assets/avatar/avatar.png"),
      inr: "33,00,00",
      width: "30%",
    },
    {
      name: "Swagat Das",
      title: "Core Branding Partner",
      src: require("../../../assets/avatar/avatar.png"),
      inr: "33,00,00",
      width: "60%",
    },
    {
      name: "Hilary Clinton",
      title: "Core Branding Partner",
      src: require("../../../assets/avatar/avatar.png"),
      inr: "33,00,00",
      width: "50%",
    },
    {
      name: "Swagat Das",
      title: "Core Branding Partner",
      src: require("../../../assets/avatar/avatar.png"),
      inr: "33,00,00",
      width: "30%",
    },
  ];
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          enableAvatar={false}
          title="Performance"
          subtitle="All Your Active Partners"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={styles.inputContainerStyle}>
        <LargeTextInput
          renderData={true}
          placeholder={"filter revenue"}
        ></LargeTextInput>
      </View>
      <ScrollView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <View style={styles.card_Container}>
          {performances.map((m, i) => {
            return (
              <PerformanceComponent
                key={`${m.name} + ${i}`}
                widthSize={m.width}
                imageUri={m.src}
                title={m.title}
                name={m.name}
                currency={m.inr}
              ></PerformanceComponent>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

export default PerformanceScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  card_Container: {
    marginHorizontal: 20,
  },
  inputContainerStyle: {
    maxHeight: 90,
    minHeight: 90,
    marginHorizontal: 30,
    backgroundColor: "#FFF",
  },
});
