import { View, Text, StyleSheet, Pressable } from "react-native";
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import Header from "../../../components/header";
import UserBasicDetailsFields from "../../../components/SignUpComponents/userBasicDetailsFields";
import { signup } from "../../../redux/actions/user.action";
import { useFocusEffect } from "@react-navigation/native";
import { primaryColor } from "../../../components/utils";
import Button from "../../../components/button/button";
import { addError } from "../../../redux/actions/toast.action";
const ClientBasicDetails = ({ navigation, signup, route, user, addError }) => {
  const initData = {
    fName: "",
    lName: "",
    email: "",
    phoneNo: "",
  };

  useFocusEffect(
    React.useCallback(() => {
      if (user && user._id) {
        setBasicDetails({
          fName: user.fName,
          lName: user.lName,
          email: user.email,
          phoneNo: user.phoneNo,
        });
      }
    }, [user])
  );
  const [basicDetails, setBasicDetails] = useState(initData);

  const onChangeField = (data) => {
    setBasicDetails({ ...basicDetails, ...data });
  };
  const next = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(basicDetails.email) === false) {
      addError("Please enter a valid email", 3000);
    } else {
      signup(
        {
          ...basicDetails,
          userType: route.params.stack,
          role: route.params.stack,
        },
        navigateAfterSuccess
      );
    }
  };
  const navigateAfterSuccess = () => {
    navigation.navigate("otpScreen");
  };
  useFocusEffect(
    React.useCallback(() => {
      return () => {
        setBasicDetails(initData);
      };
    }, [])
  );
  return (
    <View style={styles.container}>
      <View style={{ maxHeight: "18%", minHeight: "18%" }}>
        <Header
          title="Basic Details"
          subtitle="Please setup your credentials"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          // style={{ backgroundColor: "#FFF" }}
          titleStyle={{
            color: primaryColor(user.userType || route.params.stack),
          }}
        ></Header>
      </View>

      <UserBasicDetailsFields
        user={basicDetails}
        onChangeField={onChangeField}
      />
      <View style={{ justifyContent: "flex-end", flex: 1, marginBottom: 10 }}>
        <Button title={"Next"} onPress={() => next()}></Button>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#fff" },
  buttonStyle: {
    backgroundColor: "#000",
    height: 70,
    marginVertical: 5,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { signup, addError })(
  ClientBasicDetails
);
