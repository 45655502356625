import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/header";
import LargeButton from "../../components/largeButton";
import RoundButton from "../../components/roundButton";

const AdminVerification = ({ navigation, user, onPressNext }) => {
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <View style={styles.headerStyle}>
        <Header
          title={
            user.status === "INCOMPLETE"
              ? "get verified"
              : user.status === "REVIEWING"
              ? "waitlist"
              : "you've made it!"
          }
          subtitle="Schedule a meeting for interview"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView style={styles.containerStyle}>
        <Text style={styles.titleStyle}>
          {user.status === "INCOMPLETE"
            ? "Schedule a call with our hiring managers"
            : user.status === "DASHBOARD"
            ? "you're goo to go!"
            : "this shouldn’t take more than a week though"}
        </Text>
        <Text style={styles.status}>
          {user.status === "INCOMPLETE"
            ? "Tap to button to schedule"
            : user.status === "DASHBOARD"
            ? "Application Accepted"
            : "Pending Acceptance"}
        </Text>
        <Text style={styles.descriptionStyle}>
          {user.status === "REVIEWING"
            ? "We need to understand and assess your skills to understand if you can be a good fit to our remote sales team. Schedule a call by clicking below. Once you have scheduled a call tap “Next” to continue"
            : user.status === "DASHBOARD"
            ? "our partner have reviewed your profile!"
            : "We have received your application and it is reviewed by one of our partners. You will get the notification soon. Check back here to know the status."}
        </Text>
        {user.status === "REVIEWING" ? (
          <Text style={[styles.status]}>Status</Text>
        ) : (
          <></>
        )}
        <LargeButton
          pressFunc={() =>
            navigation.navigate("scheduleMeeting", { type: "creator-review" })
          }
          changeColorForDisable={user.status === "REVIEWING" ? true : false}
          disabled={user.status === "INCOMPLETE" ? false : true}
          disableIcon={user.status === "REVIEWING" ? false : true}
          titleStyle={{ color: "#FFFFFF", fontSize: 22 }}
          arrowColor={"#FFF"}
          title={
            user.status === "INCOMPLETE"
              ? "Schedule a Call"
              : user.status === "REVIEWING"
              ? "Review pending"
              : user.status === "SHORTLISTED"
              ? "Shortlisted"
              : "Congrats! You’ve made it"
          }
          backgroundColor={
            user.status === "INCOMPLETE"
              ? "#F27253"
              : user.status === "REVIEWING"
              ? "#B7B7B7"
              : user.status === "SHORTLISTED"
              ? "#FF973E"
              : "#7A9D54"
          }
        ></LargeButton>
        <Text style={{ color: "#000", marginTop: 20, fontSize: 11 }}>
          {["INCOMPLETE", "DASHBOARD"].includes(user.status)
            ? ""
            : `once the above button turns green, you will be able to start off super fast. In case you haven’t been selected, you can reach us for more clarifications `}
        </Text>
        <RoundButton
          pressFunc={onPressNext}
          title={
            user.status === "REVIEWING" || user.status === "SHORTLISTED"
              ? "Wait"
              : "Next"
          }
          style={{
            backgroundColor: "#000000",
            width: "35%",
            margin: 10,
            marginTop: 50,
          }}
        ></RoundButton>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(AdminVerification);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
  containerStyle: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    height: "100%",
    marginHorizontal: 20,
  },
  titleStyle: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#000",
    marginTop: 50,
  },
  status: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#828282",
    marginTop: 20,
    marginLeft: 3,
  },
  descriptionStyle: {
    fontSize: 11,
    color: "#606060",
    marginTop: 15,
  },
});
