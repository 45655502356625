export const fetchPaymentReport = () => (dispatch) => {
  return dispatch({
    type: "FETCH_PAYMENT_REPORT",
    payload: {
      request: {
        url: `/api/payment/report`,
        method: "GET",
      },
    },
  });
};

export const redeemPayment = (obj) => (dispatch) => {
  return dispatch({
    type: "REDEEM_PAYMENT",
    payload: {
      request: {
        url: `/api/payment/redeem`,
        method: "POST",
        data: obj,
      },
    },
  });
};
