import { Pressable, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import LargeTextInput from "../../../components/largeTextInput";
import { connect } from "react-redux";
import Header from "../../../components/header";
import RoundButton from "../../../components/roundButton";
import { createProject } from "../../../redux/actions/project.action";
import { useFocusEffect } from "@react-navigation/native";
import NextAndDiscardButton from "../../../components/nextAndDiscardButton/nextAndDiscardButton";
const SalesBasicDetails = ({
  user,
  navigation,
  createProject,
  selectedProject,
}) => {
  const initData = {
    title: "",
    about: "",
  };
  const [projectDetails, setProjectDetails] = useState(initData);
  const initiateProject = () => {
    createProject(projectDetails, () =>
      navigation.navigate("service-selection")
    );
  };
  useFocusEffect(
    React.useCallback(() => {
      return () => {
        setProjectDetails(initData);
      };
    }, [])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (selectedProject) {
        setProjectDetails({
          title: selectedProject.title,
          about: selectedProject.about,
          _id: selectedProject._id,
        });
      }
    }, [selectedProject])
  );
  return (
    <View style={{ flex: 1, backgroundColor: "#FAFAFA" }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new projects"
          subtitle="New project details"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <Text style={styles.headerStyle}>enter some basic project details</Text>
      <LargeTextInput
        placeholder="give a name to the project"
        style={{ marginTop: 50, borderBottomColor: "#707070" }}
        onChangeText={(t) => setProjectDetails({ ...projectDetails, title: t })}
        value={projectDetails.title}
      ></LargeTextInput>
      <Text style={{ fontSize: 11, margin: 30, marginVertical: 10 }}>
        {`you can write in something as “ Brand Identity and Stationary Design for Delhi based Arabic Restaurant”`}
      </Text>
      <View style={styles.wrapperStyle}>
        <LargeTextInput
          placeholder="tell us about your vision"
          style={{ borderBottomColor: "#707070" }}
          onChangeText={(t) =>
            setProjectDetails({ ...projectDetails, about: t })
          }
          value={projectDetails.about}
        ></LargeTextInput>
      </View>
      <NextAndDiscardButton
        onPressNext={() => initiateProject()}
        onPressDiscard={() => {}}
      />
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, { createProject })(SalesBasicDetails);

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    margin: 28,
    color: "#000",
  },
  wrapperStyle: {
    marginTop: 70,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginHorizontal: 10,
    marginVertical: 10,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
