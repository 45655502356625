import { addError, addInfo, addWarning } from "../actions/toast.action";
import _ from "lodash";
const initialState = {};
const selectedProject = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_PROJECT_PROJECT_SUCCESS":
    case "CREATE_PROJECT_SUCCESS": {
      return action.payload.data;
    }

    case "CREATE_PROJECT_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "ACCEPT_PROJECT_SUCCESS":
    case "CLEAR_SELECTED_PROJECT": {
      return {};
    }

    case "SET_SELECTED_PROJECT": {
      return action.payload.data;
    }

    case "ADD_DELIVERABLES_SUCCESS":
    case "START_PROJECT_SUCCESS": {
      return action.payload.data;
    }

    case "START_PROJECT_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "GIVEUP_PROJECT_SUCCESS": {
      return action.payload.data;
    }

    case "GIVEUP_PROJECT_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "CLOSE_QUOTATION_SUCCESS": {
      return {
        ...state,
        requiredTeam: {
          ...state.requiredTeam,
          members: action.payload.data.requiredTeam.members,
        },
      };
    }

    case "REMOVE_QUOTATION_SUCCESS":
    case "SELECT_QUOTATION_SUCCESS": {
      return action.payload.data;
    }

    case "REQUEST_CUSTOM_PAYMENT_FAIL":
    case "CLOSE_QUOTATION_FAIL":
    case "SELECT_QUOTATION_FAIL":
    case "REMOVE_QUOTATION_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "UPDATE_STEP_STATUS_SUCCESS": {
      return {
        ...state,
        paymentPhases: action.payload.data.paymentPhases,
        completionPercent: action.payload.data.completionPercent,
      };
    }

    case "REQUEST_CUSTOM_PAYMENT_SUCCESS": {
      return { ...state, paymentPhases: action.payload.data.paymentPhases };
    }

    case "REQUEST_CREATOR_CUSTOM_PAYMENT_SUCCESS": {
      return { ...state, team: action.payload.data.team };
    }

    case "REQUEST_CREATOR_CUSTOM_PAYMENT_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "UPDATE_STEP_STATUS_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "UPDATE_PAYMENT_STATUS_SUCCESS": {
      return {
        ...state,
        paymentPhases: action.payload.data.paymentPhases,
        team: action.payload.data.team,
        paidAmount: action.payload.data.paidAmount,
      };
    }

    case "UPDATE_PAYMENT_STATUS_FAIL": {
      action.asyncDispatch(
        addError(action.error.response?.data?.message, 3000)
      );
      return state;
    }

    case "LOGOUT": {
      return {};
    }
    default: {
      return state;
    }
  }
};
export default selectedProject;
