import React, { useState } from "react";
import {
  CheckBox,
  ImageBackground,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  useWindowDimensions,
  Pressable,
} from "react-native";
import RoundButton from "../components/roundButton";
import Icon from "../components/icon";
import Footer from "../components/footer";
import LargeTextInput from "../components/largeTextInput";
import { connect } from "react-redux";
import { login } from "../redux/actions/user.action";
import LargeButton from "../components/largeButton";
import adjust from "../components/adjust";
// import Button from "../components/button/button";

const Login = ({ navigation, login }) => {
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);
  const loginAndNavigate = () => {
    let loginObj = {
      email: userId,
      password: password,
    };
    login(loginObj);
  };
  const window = useWindowDimensions();
  return (
    <View style={{ flex: 1 }} keyboardShouldPersistTaps={"handled"}>
      {Platform.OS === "web" ? (
        <View style={styles.welcomeTextContainer}>
          {/* <Icon
            name="back"
            style={{ position: "absolute", left: 25, marginTop: 40 }}
          ></Icon> */}

          <Icon
            name="yellowCircle"
            style={{ position: "absolute", right: 30, marginTop: 70 }}
          ></Icon>
        </View>
      ) : (
        <ImageBackground
          style={{
            flex: 1,
            backgroundColor: "#FAFAFA",
            height: "90%",
          }}
          resizeMode="cover"
          source={require("../assets/login-topbar.png")}
        ></ImageBackground>
      )}
      <View style={{ flex: 2, backgroundColor: "#FAFAFA" }}>
        <Text style={styles.welcomeText}>
          {"Manage your agency\nat your fingertips"}
        </Text>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            paddingTop: 20,
          }}
        >
          <LargeTextInput
            containerStyle={{ marginTop: 10 }}
            placeholder="Enter User ID"
            onChangeText={(e) => setUserId(e)}
            style={{ marginBottom: 10, borderBottomColor: "#828282" }}
            keyboardType="email-address"
          ></LargeTextInput>
          <LargeTextInput
            containerStyle={{ marginTop: 10 }}
            placeholder="Enter Password"
            onChangeText={(e) => setPassword(e)}
            secureTextEntry={true}
            style={{ borderBottomColor: "#828282" }}
          ></LargeTextInput>
          {/* <RoundButton
            pressFunc={() => loginAndNavigate()}
            title="Login"
            style={{
              backgroundColor: "#000000",
              width: 150,
              marginLeft: 30,
              marginTop: 30,
              height: "100%",
            }}
          ></RoundButton> */}
          <TouchableOpacity
            style={{ marginLeft: 30, marginTop: 10 }}
            onPress={() => navigation.navigate("forgot-password")}
          >
            <Text style={{ color: "blue", textDecorationLine: "underline" }}>
              Forgot Password?
            </Text>
          </TouchableOpacity>
          <RoundButton
            pressFunc={() => loginAndNavigate()}
            title="Login"
            style={{
              backgroundColor: "#000000",
              width: 130,
              marginLeft: 30,
              marginTop: 30,
              // height: "100%",
            }}
          ></RoundButton>
          {/* <Button onPress={() => loginAndNavigate()} title={"Login"}></Button> */}
        </View>

        <View style={{ flex: 1 }}>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
            }}
          >
            <View>
              <Text style={{ fontSize: adjust(13), marginLeft: 30 }}>
                Don't have an Account? Sign-Up Now
              </Text>
            </View>
            <View style={{ flexDirection: "row", paddingHorizontal: 20 }}>
              <LargeButton
                title="Sign-Up Now"
                borderStyle={{ borderColor: "#000" }}
                pressedBorderStyle={{
                  borderColor: "#000",
                  backgroundColor: "#000",
                }}
                titleStyle={{ color: "#000" }}
                pressedTitleStyle={{ color: "#FFFFFF" }}
                pressFunc={() => navigation.navigate("selectProfile")}
                style={{ marginLeft: 10, marginRight: 10 }}
              ></LargeButton>
            </View>
          </View>
          {/* <View style={{ flex: 1 }}>
            <Footer disableNextButton={true}></Footer>
          </View> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  welcomeText: {
    fontWeight: "bold",
    fontSize: adjust(20),
    // marginTop: 10,
    marginLeft: 30,
    marginBottom: 12,
    color: "#000",
  },
  welcomeTextContainer: {
    flex: 1,
    backgroundColor: "#FFF",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    maxHeight: 300,
  },
  input: {
    fontSize: adjust(25),
    fontWeight: "bold",
    width: "80%",
    borderBottomWidth: 1,
    padding: 10,
    color: "#fff",
    flex: 1,
    maxHeight: 60,
    minHeight: 60,
    maxWidth: 400,
    marginTop: 20,
    marginLeft: 30,
  },
  checkbox: {
    alignSelf: "center",
  },
  termsLabel: {
    margin: 8,
    textDecorationLine: "underline",
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginHorizontal: 25,
    marginTop: 15,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});

export default connect(null, { login })(Login);
