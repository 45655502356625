import * as React from "react";
import { StyleSheet } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { connect } from "react-redux";
import TabNavigationDesign from "../tabNavigationDesign";
import AccountantStack from "./accountant-stack";
import AccountantProjectStack from "./accountant-project-stack";
import AccountantPaymentStack from "./accountant-payment-stack";
import AccountantInboxStack from "./accountant-inbox-stack";

import ProfileStack from "../profile-stack";
import Avatar from "../../components/avatar";

const Tab = createBottomTabNavigator();

const AccountantTabNavigation = ({ user }) => {
  return (
    <Tab.Navigator
      tabBar={(props) => <TabNavigationDesign {...props} />}
      initialRouteName="Home"
      unmountInactiveTabs={true}
      screenOptions={{ unmountOnBlur: true, headerShown: false }}
    >
      <Tab.Screen
        name="Home"
        component={AccountantStack}
        options={{
          icon: "home",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Project"
        component={AccountantProjectStack}
        options={{
          icon: "project",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Payment"
        component={AccountantPaymentStack}
        options={{
          icon: "dollar",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Inbox"
        component={AccountantInboxStack}
        options={{
          icon: "inbox",
          unmountOnBlur: true,
        }}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileStack}
        options={{
          image:
            user.mimType && user.image ? (
              <Avatar
                source={{ uri: `data:${user.mimType};base64,${user.image}` }}
                style={styles.headerAvatar}
              ></Avatar>
            ) : null,
          icon: "profile",
          unmountOnBlur: true,
        }}
      />
    </Tab.Navigator>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const styles = StyleSheet.create({
  headerAvatar: {
    borderWidth: 1.5,
    height: 29,
    width: 29,
    marginBottom: 3,
  },
});
export default connect(mapStateToProps, {})(AccountantTabNavigation);
