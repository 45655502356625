import React, { useState } from "react";
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import adjust from "../../../components/adjust";
import Avatar from "../../../components/avatar";
import Icon from "../../../components/icon";
import { setSelectedMember } from "../../../redux/actions/creator.action";
import Header from "../../../components/header";
import ContainerWithDifferentBg from "../../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import TeamProfileComponent from "../../../components/teamEachProfileComponent/teamEachProfileComponent";
import moment from "moment";

const ActiveProjectSummary = ({
  selectedProject,
  user,
  navigation,
  setSelectedMember,
}) => {
  const setSelectedMemberAndNavigate = (index) => {
    setSelectedMember(selectedProject.team[index]);
    navigation.navigate("payments", { teamMember: true });
  };
  return (
    <View style={{ flex: 1, paddingBottom: 20 }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Project details"
          subtitle={"Everything about your project"}
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <View style={{ flex: 4, marginTop: 5 }}>
        <ScrollView
          nestedScrollEnabled={true}
          style={{
            paddingHorizontal: 20,
            // height: "100%",
          }}
        >
          <View style={[styles.container, { backgroundColor: "#000" }]}>
            <Text style={styles.mainHeader}>{selectedProject.title}</Text>
          </View>
          <ContainerWithDifferentBg
            content={`Deadline: ${moment(selectedProject.deadLine).format(
              "Do MMMM yyyy"
            )}`}
            backgroundColor={"#EB4141"}
          ></ContainerWithDifferentBg>
          <View style={{ marginTop: 10 }}>
            <ContainerWithDifferentBg
              content={"Total Project Payment: INR 8,000"}
              backgroundColor={"#7A9D54"}
            ></ContainerWithDifferentBg>
          </View>
          {selectedProject?.team?.length >= 1 ? (
            <View style={styles.container}>
              <Text style={styles.header}>Team Composition</Text>
              {selectedProject.team.map((t, index) => {
                return (
                  <TeamProfileComponent
                    key={t._id}
                    user={user}
                    index={index}
                    profile={t}
                    disableCheckbox
                  ></TeamProfileComponent>
                );
              })}
            </View>
          ) : (
            <></>
          )}
          {user.userType === "CREATOR" ? (
            <View>
              <Text style={styles.aboutHead}>Client's Notes</Text>
              <Text style={styles.aboutDesc}>{selectedProject.about}</Text>
              <Text style={styles.aboutHead}>Partner's Notes</Text>
              <Text style={styles.aboutDesc}>
                {selectedProject.partnersNote}
              </Text>
              <Text style={styles.aboutHead}>Working as a</Text>
              <Text
                style={{
                  margin: 10,
                  fontSize: 36,
                  fontWeight: "bold",
                  color: "#FB953F",
                }}
              >
                {
                  selectedProject.team.find((x) => x.user._id === user._id)
                    .designation.name
                }
              </Text>
            </View>
          ) : (
            <View>
              <Text style={styles.aboutHead}>Notes</Text>
              <Text style={styles.aboutDesc}>
                At this point, we have emailed and texted your client that they
                need to make a profile to start a project with us. You should
                prompt your client to make it fast.
              </Text>
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  header: {
    flex: 1,
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginBottom: 15,
  },
  mainHeader: {
    flex: 1,
    fontSize: adjust(16),
    fontWeight: "bold",
    color: "#ffffff",
    marginVertical: 5,
  },
  subHeader: { flex: 1, fontSize: adjust(11), color: "#606060" },
  projectBriefText: {
    flex: 1,
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginBottom: 15,
  },
  label: {
    flex: 1,
    fontSize: adjust(13),
    fontWeight: "bold",
    color: "#000000",
  },
  data: { flex: 1, fontSize: adjust(13), color: "#606060", marginTop: 5 },
  dataContainer: { flex: 1, marginBottom: 15 },
  userContainer: {
    flexDirection: "row",
    borderRadius: 8,
    alignItems: "center",
    minHeight: 60,
    maxHeight: 60,
    padding: 10,
    marginBottom: 5,
  },
  rolesCircle: {
    flex: 1,
    borderRadius: 50,
    maxHeight: 50,
    minHeight: 50,
    maxWidth: 50,
    minWidth: 50,
  },
  roleContainer: {
    flexWrap: "wrap",
    marginRight: 5,
    alignItems: "center",
  },
  aboutHead: { margin: 9, fontSize: 16, fontWeight: "bold", color: "#928282" },
  aboutDesc: { margin: 8, fontSize: 10, color: "#606060" },
});
const mapStateToProps = ({ selectedProject, user }) => ({
  selectedProject,
  user,
});
export default connect(mapStateToProps, { setSelectedMember })(
  ActiveProjectSummary
);
