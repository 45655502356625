import { StyleSheet, Text, View, Pressable } from "react-native";
import React, { useState } from "react";

import Header from "../../../components/header";
import OtpField from "../../../components/SignUpComponents/otpField";
import { otpValidate } from "../../../redux/actions/user.action";
import { connect } from "react-redux";
import { primaryColor } from "../../../components/utils";

const ClientOtpScreen = ({ navigation, user, otpValidate, route }) => {
  const [otp, setOtp] = useState();

  const next = () => {
    otpValidate({ email: user.email, otp }, navigateAfterSuccess);
  };
  const navigateAfterSuccess = () => {
    navigation.navigate("creadentialScreen");
  };
  return (
    <View style={{ flex: 1, backgroundColor: "#FFF" }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Basic Details"
          subtitle="Please setup your credentials"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{
            color: primaryColor(user.userType) || route.params.stack,
          }}
        ></Header>
      </View>
      <OtpField onChange={setOtp} />
      <Pressable style={[styles.pressableStyle]} onPress={() => next()}>
        <Text
          style={[
            {
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: "bold",
              alignSelf: "center",
              justifyContent: "center",
            },
          ]}
        >
          {"Next"}
        </Text>
      </Pressable>
    </View>
  );
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { otpValidate })(ClientOtpScreen);

const styles = StyleSheet.create({
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
