import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";

const ContainerWithDifferentBg = ({
  content,
  backgroundColor,
  titleColor = "#FFF",
  titleFontSize = 16,
  title,
  projectFor,
  subTitleColor = "#FFF",
  subTitleFontSize = 11,
  subTitle,
  disabled = true,
  onPress,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={[
        styles.containerStyle,
        {
          backgroundColor: backgroundColor,
        },
      ]}
    >
      <Text
        style={[
          styles.textStyle,
          {
            color: titleColor,
            fontSize: titleFontSize,
          },
        ]}
      >
        {title ? title : content}
      </Text>
      {projectFor || subTitle ? (
        <Text
          style={[
            {
              color: subTitleColor,
              fontSize: subTitleFontSize,
              marginLeft: 2,
            },
          ]}
        >
          {subTitle ? subTitle : projectFor}
        </Text>
      ) : (
        <></>
      )}
    </TouchableOpacity>
  );
};

export default ContainerWithDifferentBg;

const styles = StyleSheet.create({
  containerStyle: {
    padding: 15,
    borderRadius: 8,
    marginTop: 10,
  },
  textStyle: {
    fontWeight: "bold",
    flexWrap: "wrap",
    alignSelf: "flex-start",
    left: 1,
  },
});
