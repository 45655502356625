import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import adjust from "../../../../components/adjust";
import Icon from "../../../../components/icon";

export default function StepsComponent({
  data,
  onDataChange,
  stepNo,
  removeOnPress,
  jsx,
}) {
  const [stepState, setStepState] = useState(data);
  useEffect(() => {
    setStepState(data);
  }, [data]);
  return (
    <View style={styles.innerContainer}>
      <TouchableOpacity
        onPress={removeOnPress}
        style={{
          flex: 1,
          zIndex: 10,
          position: "absolute",
          right: 10,
          top: 10,
          color: "#D9D9D9",
        }}
      >
        <Icon height={20} width={20} name="cross" fill="#000" />
      </TouchableOpacity>
      {jsx ? (
        jsx
      ) : (
        <View style={{ flex: 1 }}>
          <TextInput
            placeholder={"Name Milestone"}
            placeholderTextColor="#5F5F5F"
            style={styles.stepHeaderInput}
            value={stepState.name}
            onChangeText={(e) => {
              setStepState({ ...stepState, name: e });
              onDataChange({ ...stepState, name: e });
            }}
          ></TextInput>
          <TextInput
            placeholder={"Milestone Description "}
            placeholderTextColor="#5F5F5F"
            style={styles.stepDescriptionInput}
            value={stepState.description}
            onChangeText={(e) => {
              const newStep = { ...stepState, description: e };
              setStepState(newStep);
              onDataChange(newStep);
            }}
          ></TextInput>
          <TextInput
            placeholder={"Milestone Completion Amount"}
            placeholderTextColor="#5F5F5F"
            style={styles.stepDescriptionInput}
            value={
              stepState.payment?.amount ? `${stepState.payment?.amount}` : ""
            }
            onChangeText={(e) => {
              const newStep = { ...stepState, payment: { amount: Number(e) } };
              setStepState(newStep);
              onDataChange(newStep);
            }}
            keyboardType="numeric"
          ></TextInput>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  innerContainer: {
    // minHeight: 120,
    // maxHeight: 120,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: "#000",
    marginTop: 12,
    padding: 20,
    flex: 1,
    marginTop: 20,
  },
  stepHeaderInput: {
    flex: 1,
    fontSize: adjust(18),
    fontWeight: "bold",
    borderBottomWidth: 1,
    maxHeight: 40,
    minHeight: 40,
    borderBottomColor: "#FFF",
    color: "#FFF",
  },
  stepDescriptionInput: {
    flex: 1,
    fontSize: adjust(18),
    borderBottomWidth: 1,
    maxHeight: 40,
    minHeight: 40,
    borderBottomColor: "#FFF",
    marginTop: 15,
    fontWeight: "bold",
    color: "#FFF",
  },
});
