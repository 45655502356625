import React from "react";
import Login from "../screens/login";
import SelectProfile from "../screens/signUp/selectProfile";
import { createStackNavigator } from "@react-navigation/stack";
import { connect } from "react-redux";
import SignUpSuccess from "../screens/signUp/signUpSuccess";
import forgotPassword from "../screens/forgot-password/forgotPassword";
import AppStack from "./appStack";
import CreatorSignUpStack from "../navigation/signUpStack/creatorSignup";
import ClientSignUpStack from "../navigation/signUpStack/clientSignUp";
import SalesSignUpStack from "../navigation/signUpStack/salesSignUp";
import OnboardingMessage from "../screens/signUp/common/onboardingMessage";

const LogInStack = createStackNavigator();

const entryNavigation = ({ token, user }) => {
  if (token && user.signupComplete) {
    return <AppStack></AppStack>;
  } else {
    return (
      <LogInStack.Navigator name="login" screenOptions={{
        headerShown: false
      }}>
        <LogInStack.Screen
          name="login"
          component={Login}
          options={{ headerShown: false }}
        />
        <LogInStack.Screen
          name="forgot-password"
          component={forgotPassword}
          options={{ headerShown: false }}
        />
        <LogInStack.Screen
          name="selectProfile"
          component={SelectProfile}
          options={{ headerShown: false }}
        />
        <LogInStack.Screen
          name="creatorSignupStack"
          component={CreatorSignUpStack}
          options={{ headerShown: false }}
        />
        <LogInStack.Screen
          name="saleSignupStack"
          component={SalesSignUpStack}
          options={{ headerShown: false }}
        />
        <LogInStack.Screen
          name="onboardingMessage"
          component={OnboardingMessage}
          options={{ headerShown: false }}
        />
        {/* <LogInStack.Screen
          name="clientSignUp"
          component={ClientSignUp}
          options={{ headerShown: false }}
        /> */}
        <LogInStack.Screen
          name="clientSignupStack"
          component={ClientSignUpStack}
          options={{ headerShown: false }}
        />
        <LogInStack.Screen
          name="signUpSuccess"
          component={SignUpSuccess}
          options={{ headerShown: false }}
        />
      </LogInStack.Navigator>
    );
  }
};
const mapStateToProps = ({ token, user }) => ({
  token,
  user,
});
export default connect(mapStateToProps, {})(entryNavigation);
