import React from "react";
import { ImageBackground, View } from "react-native";
import { connect } from "react-redux";
import EntryStack from "../navigation/entryStack";

const LeftScreen = ({ user, navigation }) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flex: 1,
          maxWidth: 500,
          minWidth: 400,
          backgroundColor: "#fafafa",
          flexDirection: "column",
          zIndex: 2,
        }}
      >
        {/* {user._id ? (
          <Dashboard navigation={navigation}></Dashboard>
        ) : ( */}
        <ImageBackground
          style={{
            flex: 1,
            backgroundColor: "#fafafa",
          }}
          resizeMode="center"
          source={require("../assets/onedesign-splash.png")}
        ></ImageBackground>
        {/* )} */}
      </View>
      <View
        style={{
          flex: 1,
          maxWidth: "100%",
          minWidth: "60%",
          flexDirection: "column",
        }}
      >
        <EntryStack navigation={navigation}></EntryStack>
      </View>
    </View>
  );
  //   return user._id ? (
  //     <LeftDashboardStack.Navigator name="lhs">
  //       <LeftDashboardStack.Screen
  //         name="dashboard"
  //         component={Dashboard}
  //         options={{ headerShown: false }}
  //       />
  //     </LeftDashboardStack.Navigator>
  //   ) : (
  //     <ImageBackground
  //       style={{ flex: 1, resizeMode: "contain" }}
  //       resizeMode="contain"
  //       source={require("../assets/onedesign-splash.png")}
  //     ></ImageBackground>
  //   );
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, null)(LeftScreen);
