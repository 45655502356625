import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Image, Platform } from "react-native";
import Modal from "../modal/modal";
import { StyleSheet, Text } from "react-native";
import ModalLargeTextInput from "../../components/modalLargeInput";
import RoundButton from "../../components/roundButton";
import Icon from "../../components/icon";
import adjust from "../adjust";
import Toast from "../toast";

export default function InputDialogBox({
  showModal,
  submit,
  value,
  placeholder,
  onPressCross,
  jsx,
  addImage,
  onPress,
  profilePic,
  modalViewStyle,
  centerView,
  modalBackgroundColor = "true",
}) {
  const [input, setInput] = useState("");
  useEffect(() => {
    setInput(value ? value.name : "");
  }, [value]);

  return (
    <Modal
      animationType="fade"
      transparent={true}
      presentationStyle="overFullScreen"
      isVisible={showModal}
      visible={showModal}
      ariaHideApp={false}
    >
      <View style={[styles.centeredView, centerView]}>
        <Toast></Toast>
        <View
          style={[
            styles.modalView,
            { minHeight: addImage ? 400 : 300, padding: addImage ? 0 : 7 },
            { backgroundColor: modalBackgroundColor ? "#FFF" : null },
            modalViewStyle,
          ]}
        >
          {jsx ? (
            jsx
          ) : (
            <View style={styles.containerStyle}>
              <TouchableOpacity
                onPress={onPressCross}
                style={{
                  position: "absolute",
                  top: addImage ? 5 : 0,
                  right: 5,
                }}
              >
                <Icon fill="#000" name="cross"></Icon>
                {/* <Text style={{ fontSize: adjust(20) }}>X</Text> */}
              </TouchableOpacity>
              <ModalLargeTextInput
                placeholder={placeholder}
                onChangeText={(e) => {
                  setInput(e);
                }}
                value={input}
              ></ModalLargeTextInput>
              {addImage ? (
                <></>
              ) : (
                <TouchableOpacity>
                  <RoundButton
                    pressFunc={() => {
                      submit({ name: input, _id: value ? value._id : null });
                      setInput("");
                    }}
                    title="Submit"
                    style={{
                      backgroundColor: "#000000",
                      width: 120,
                      marginTop: 30,
                    }}
                  ></RoundButton>
                </TouchableOpacity>
              )}
              {addImage ? (
                <View
                  style={{
                    flex: 0.5,
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 30,
                    marginRight: 15,
                  }}
                >
                  {profilePic ? (
                    <TouchableOpacity onPress={onPress}>
                      <Image
                        source={{
                          uri:
                            Platform.OS === "web"
                              ? profilePic
                              : "data:image/jpeg;base64," + profilePic,
                        }}
                        style={styles.circle}
                      ></Image>
                    </TouchableOpacity>
                  ) : (
                    <View style={styles.circle}>
                      <TouchableOpacity
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                        onPress={onPress}
                      >
                        <Icon name="plus" style={{ flex: 1 }}></Icon>
                      </TouchableOpacity>
                    </View>
                  )}
                  <View style={{ marginLeft: 15, marginTop: 15 }}>
                    <Text
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "#000",
                      }}
                    >
                      Upload Logo
                    </Text>
                  </View>
                </View>
              ) : (
                <></>
              )}
            </View>
          )}
          {addImage ? (
            <TouchableOpacity>
              <RoundButton
                pressFunc={() => {
                  submit({ name: input, _id: value ? value._id : null });
                  setInput("");
                }}
                title="Submit"
                style={{
                  backgroundColor: "#000000",
                  width: 120,
                  marginTop: 30,
                }}
              ></RoundButton>
            </TouchableOpacity>
          ) : (
            <></>
          )}
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  projectBriefText: {
    fontSize: adjust(15),
    fontWeight: "bold",
    color: "#A9A9A9",
  },
  label: {
    fontSize: adjust(11),
    fontWeight: "bold",
    color: "#000000",
  },
  dataContainer: {
    marginBottom: 15,
  },
  budget: {
    fontSize: adjust(18),
    marginTop: 20,
    fontWeight: "bold",
  },
  myBidText: {
    fontSize: adjust(11),
    fontWeight: "bold",
    color: "#A9A9A9",
    marginTop: 30,
  },
  centeredView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  modalView: {
    alignItems: "center",
    borderRadius: 10,
    width: 350,
    borderColor: "rgba(0, 0, 0, 0.1)",

    position: "relative",
  },
  circle: {
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    backgroundColor: "#B4B4B4",
    marginTop: 10,
    marginLeft: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  containerStyle: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    width: 350,
    borderColor: "rgba(0, 0, 0, 0.1)",
    height: 300,
    position: "relative",
  },
});
