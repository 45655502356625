import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import adjust from "../../../../components/adjust";
import Icon from "../../../../components/icon";
import PopUp from "../../../../components/popUp/popUp";
import TextInputWithTitle from "../../../../components/TextInputWithTitle/TextInputWithTitle";

export default function SelectProfileComponent({
  data,
  onChangeData,
  type,
  renderData,
  selectionValue,
  validated,
  disable,
  validateNow,
  employees,
  empSelectionValue,
  remove,
  length,
  quanity,
}) {
  const [profile, setProfile] = useState(data);
  const [count, setCount] = useState(data.nos ? data.nos : 0);
  let validationReg;
  let validationType = null;
  let required = false;
  const [valid, setValid] = useState(true);
  const [roleValid, setRoleValid] = useState(false);
  const [empValid, setEmpValid] = useState(false);
  const [countValid, setCountValid] = useState(false);
  const [budgetValid, setBudgetValid] = useState(false);
  const [selectedMemberType, setSelectedMemberType] = useState(
    data.user ? "One Design Team" : "One Design Community"
  );
  const [memberType, setMemberType] = useState([
    { _id: "One Design Team", name: "One Design Team" },
    { _id: "One Design Community", name: "One Design Community" },
  ]);

  useEffect(() => {
    if (validateNow) {
      setRoleValid(validate(profile.type, (required = true)));
      setBudgetValid(
        validate(profile.budget, (required = true), (validationType = "number"))
      );
    }
  }, [validateNow]);

  function validate(v, required, validationType) {
    if (validationType) {
      validationReg = validationType === "number" ? /^[0-9]*$/ : null;
    }
    let val = valid;
    if (required && v) {
      if (validationReg) {
        val = validationReg.test(v);
      } else {
        val = true;
      }
    } else if (v) {
      if (validationReg) {
        val = validationReg.test(v);
      }
    } else {
      val = false;
    }
    setValid(val);
    return val;
  }

  useEffect(() => {
    if (roleValid && budgetValid) {
      validated(true);
    } else {
      validated(false);
    }
  });

  const handleMemberTypeSelection = (type) => {
    setSelectedMemberType(type._id);
    setProfile({
      ...profile,
      sourcing: type._id,
      type: "",
      nos: 0,
      budget: "",
      user: "",
    });
    onChangeData({
      ...profile,
      sourcing: type._id,
      type: "",
      nos: 0,
      budget: "",
      user: "",
    });
    if (type._id == "One Design Team") {
      setRoleValid(false);
      setCountValid(false);
      setBudgetValid(false);
    } else {
      setEmpValid(false);
    }
  };

  const handleProfileSelection = (type) => {
    setRoleValid(validate(type, (required = true)));
    setProfile({ ...profile, type: type._id });
    onChangeData({ ...profile, type: type._id });
  };
  const handlePersonSelection = (num) => {
    setProfile({ ...profile, nos: Number(num.name.slice(0, 2)) });
    onChangeData({ ...profile, nos: Number(num.name.slice(0, 2)) });
  };
  // const handleEmployeeSelection = (emp) => {
  //   setEmpValid(validate(emp, (required = true)));
  //   setProfile(emp);
  //   onChangeData({ ...profile, user: emp._id });
  // };

  // const increaseCount = () => {
  //   let value = count + 1;
  //   if (value > 0) {
  //     setCountValid(true);
  //   } else {
  //     setCountValid(false);
  //   }
  //   setCount(count + 1);
  //   setProfile({ ...profile, nos: count + 1 });
  //   onChangeData({ ...profile, nos: count + 1 });
  // };

  const decreaseCount = () => {
    let value = count - 1;
    if (value > 0) {
      setCountValid(true);
    } else {
      setCountValid(false);
    }
    if (count == 0) {
      remove(data._id);
    } else {
      setCount(count == 0 ? 0 : count - 1);
      setProfile({ ...profile, nos: count == 0 ? 0 : count - 1 });
      onChangeData({ ...profile, nos: count == 0 ? 0 : count - 1 });
    }
  };
  let countArray = [];
  for (let i = 1; i < 100; i++) {
    if (i == 1) {
      countArray.push({ name: `1 person` });
    } else {
      countArray.push({ name: `${i} persons` });
    }
  }
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: disable ? "#F7F7F7" : "#FFFFFF",
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <Text
          style={{
            flex: 9,
            fontSize: adjust(18),
            fontWeight: "bold",
            marginBottom: 20,
            color: "#000",
          }}
        >
          Select a role
        </Text>
        {length && length > 1 ? (
          <TouchableOpacity onPress={() => decreaseCount()}>
            <Icon style={{ marginBottom: 5 }} fill="#000" name="cross"></Icon>
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </View>
      <View style={{ flex: 9, marginTop: 10, flexDirection: "row" }}>
        <View style={{ width: "100%", flex: 1 }}>
          <Text style={{ marginBottom: 5, fontSize: 12, fontWeight: "bold" }}>
            Team
          </Text>
          <PopUp
            renderData={memberType}
            placeholder="Select member type"
            iconStyle={{ color: "#BBBBBB" }}
            selectedItemStyle={{ color: "#BBBBBB" }}
            placeholderContainerStyle={{ fontSize: adjust(15) }}
            readOnly={disable}
            style={{
              minHeight: 32,
              maxHeight: 32,
              width: "100%",
              borderColor: "#BBBBBB",
              flex: 1,
              fontSize: adjust(15),
            }}
            selectedItemStyle={{ marginBottom: 0 }}
            onSelection={handleMemberTypeSelection}
            selectionValue={memberType.find((e) => e._id == selectedMemberType)}
          ></PopUp>
        </View>
        <View style={{ width: "100%", flex: 1 }}>
          <Text
            style={{
              marginBottom: 5,
              marginLeft: 10,
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            Role
          </Text>
          <PopUp
            renderData={renderData}
            placeholder="Select profile"
            placeholderContainerStyle={{ fontSize: adjust(15) }}
            placeholderStyle={{ marginBottom: 5 }}
            iconStyle={{ color: "#BBBBBB" }}
            selectedItemStyle={{ color: "#BBBBBB" }}
            readOnly={disable}
            style={{
              minHeight: 32,
              maxHeight: 32,
              width: "100%",
              borderColor: "#BBBBBB",
              marginLeft: 10,
            }}
            selectedItemStyle={{ marginBottom: 0 }}
            onSelection={handleProfileSelection}
            selectionValue={selectionValue ? selectionValue : ""}
          ></PopUp>
        </View>
      </View>
      <View style={{ flex: 9, marginTop: 10, flexDirection: "row" }}>
        <View style={{ width: "100%", flex: 1 }}>
          <Text
            style={{
              marginBottom: 5,
              fontSize: 12,
              fontWeight: "bold",
              top: 17,
            }}
          >
            Quantity
          </Text>
          <PopUp
            renderData={countArray}
            placeholder="Select Quantity"
            placeholderContainerStyle={{ fontSize: adjust(15) }}
            placeholderStyle={{ marginBottom: 5 }}
            iconStyle={{ color: "#BBBBBB" }}
            selectedItemStyle={{ color: "#BBBBBB" }}
            readOnly={disable}
            style={{
              width: "100%",
              minHeight: 33,
              maxHeight: 33,
              borderColor: "#BBBBBB",
              marginTop: 27,
            }}
            selectedItemStyle={{ marginBottom: 0 }}
            onSelection={handlePersonSelection}
            selectionValue={countArray.find((x) =>
              x.name.startsWith(quanity + " person")
            )}
          ></PopUp>
        </View>
        <View
          style={{
            flex: 1,
            minHeight: 38,
            maxHeight: 38,
            justifyContent: "center",
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <View style={{ width: "100%", flex: 1 }}>
            <Text
              style={{
                marginBottom: 5,
                fontSize: 12,
                fontWeight: "bold",
                marginLeft: 5,
              }}
            >
              Individual Budget
            </Text>
            <TextInput
              placeholder="Profile Budget"
              style={{
                borderBottomWidth: 1,
                minHeight: 40,
                maxHeight: 40,
                borderBottomColor: "#BDBDBD",
              }}
              keyboardType="numeric"
              editable={!disable}
              onChangeText={(e) => {
                setBudgetValid(
                  validate(e, (required = true), (validationType = "number"))
                );
                setProfile({ ...profile, budget: e });
                onChangeData({ ...profile, budget: e });
              }}
              value={`${profile.budget ? profile.budget : ""}`}
            ></TextInput>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    maxHeight: 240,
    // maxWidth: 320,
    minHeight: 240,
    // minWidth: 320,
    padding: 15,
    borderColor: "#FFF",
    borderWidth: 1,
    // marginRight: 30,
    marginTop: 10,
  },
  plusContainer: {
    flex: 1,
    backgroundColor: "#898989",
    borderRadius: 50,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 45,
    maxWidth: 45,
    minHeight: 45,
    minWidth: 45,
  },
  minusContainer: {
    flex: 1,
    backgroundColor: "#E9E9E9",
    borderRadius: 50,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 45,
    maxWidth: 45,
    minHeight: 45,
    minWidth: 45,
  },
});
