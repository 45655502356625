export const fetchOtherPartners = (transfer) => (dispatch) => {
  return dispatch({
    type: "FETCH_OTHER_PARTNERS",
    payload: {
      request: {
        url: `/api/user/partner${transfer ? "?transfer=" + transfer : ""}`,
        method: "GET",
      },
    },
  });
};
