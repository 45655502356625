import {
  Dimensions,
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import React, { useContext } from "react";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import Header from "../../components/header";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { fetchAllProjects } from "../../redux/actions/newProjects.action";
import { setSelectedProject } from "../../redux/actions/project.action";
import moment from "moment";

const PartnerNewProjectList = ({
  user,
  navigation,
  newProjects,
  fetchAllProjects,
  setSelectedProject,
}) => {
  const dimension = useWindowDimensions();
  useFocusEffect(
    React.useCallback(() => {
      fetchAllProjects();
    }, [])
  );
  const onPressCard = (item) => {
    setSelectedProject(item);
    navigation.navigate("newProjectDetails");
  };
  return (
    <View style={{ flex: 1 }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new projects"
          subtitle="New Projects Available"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>

      <FlatList
        contentContainerStyle={{ paddingBottom: 30 }}
        data={newProjects}
        keyExtractor={(item) => item._id}
        nestedScrollEnabled
        scrollEnabled
        renderItem={({ item, index }) => (
          <DashboardCardWithArrowAndIcon
            arrowColor={"#000"}
            descriptionTextColor="#000"
            titleColor="#000"
            backgroundColor="#FFF"
            value=""
            onPressCard={() => onPressCard(item)}
            description={`Accept or Deny within ${moment(
              new Date(
                parseInt(item._id?.toString().substring(0, 8), 16) * 1000
              ).toLocaleString(),
              "DD/MM/YYYY hh:mm:ss"
            )
              .add(7, "days")
              .diff(moment(new Date()), "days")} days`}
            title={item?.title}
            icon={false}
            style={{ marginHorizontal: 10, width: dimension.width - 20 }}
          ></DashboardCardWithArrowAndIcon>
        )}
      />
    </View>
  );
};
const mapStateToProps = ({ user, newProjects }) => ({ user, newProjects });
export default connect(mapStateToProps, {
  fetchAllProjects,
  setSelectedProject,
})(PartnerNewProjectList);

const styles = StyleSheet.create({});
