export const sendMessage = (chatMessage) => (dispatch) => {
  return dispatch({
    type: "SEND_CHAT_MSG",
    payload: {
      request: {
        url: `/api/chat`,
        method: "POST",
        data: chatMessage,
      },
    },
  });
};

export const fetchChat = (projectId, lastMessageID, discussion) => (dispatch) => {
  return dispatch({
    type: "FETCH_CHAT_MSGS",
    payload: {
      request: {
        url: `/api/chat?projectId=${projectId}${lastMessageID ? `&lastMessageID=${lastMessageID}` : ""
          }&discussion=${discussion}`,
        method: "GET",
      },
    },
  });
};

export const fetchPreviousChat = (projectId, lastMessageID, discussion) => (dispatch) => {
  return dispatch({
    type: "FETCH_PREVIOUS_CHAT_MSGS",
    payload: {
      request: {
        url: `/api/chat/previous?projectId=${projectId}${lastMessageID ? `&lastMessageID=${lastMessageID}` : ""
          }&discussion=${discussion}`,
        method: "GET",
      },
    },
  });
};

export const uploadFile = (post) => (dispatch) => {
  return dispatch({
    type: "SEND_CHAT_MSG",
    payload: {
      request: {
        url: "/api/chat/upload",
        method: "POST",
        data: post,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      },
    },
  });
};

export const unreadMessages = (obj) => (dispatch) => {
  return dispatch({
    type: "UNREAD_MESSAGES",
    payload: {
      request: {
        url: "/api/chat/unreadCount",
        method: "POST",
        data: obj,
      },
    },
  });
};

export const clearChat = () => (dispatch) => {
  return dispatch({
    type: "CLEAR_CHAT",
  });
};
