import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/admin/screens/dashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import login from "../../screens/login";
import userProfileList from "../../screens/common/client-list";
import PartnerScreen from "../../screens/admin/screens/partner-screen";
import Settings from "../../screens/admin/screens/settings";
import editUsersByAdmin from "../../screens/admin/common/editUsersByAdmin";
import AddUserByAdmin from "../../screens/admin/common/addUserByAdmin";
import addEditServicesByAdmin from "../../screens/admin/common/addEditServicesByAdmin";
import editYourProfile from "../../screens/editYourProfile";
import welcomeMessageforAdmin from "../../screens/admin/screens/welcomeMessageforAdmin";
import AddEditCaseStudies from "../../screens/admin/common/addEditCaseStudies";
import chatWithUs from "../../screens/common/chat-with-us";
import welcomeMessageScreen from "../../screens/admin/screens/welcomeMessageScreen";
import media from "../../screens/admin/media";
import expertMaterial from "../../screens/admin/screens/expert-material";

const window = Dimensions.get("window");

const AdminStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="partner"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
        // options={{  }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <AdminStack.Navigator name="partnerProject" initialRouteName={"settings"}>
      <AdminStack.Screen
        name="settings"
        component={Settings}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="clientList"
        component={userProfileList}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="partners"
        component={PartnerScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="editUserByAdmin"
        component={editUsersByAdmin}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="addUserByAdmin"
        component={AddUserByAdmin}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="addEditServicesByAdmin"
        component={addEditServicesByAdmin}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="editYourProfile"
        component={editYourProfile}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="welcomeMessageforAdmin"
        component={welcomeMessageforAdmin}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="welcomeMessageScreen"
        component={welcomeMessageScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="case-studies"
        component={AddEditCaseStudies}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="expert-material"
        component={expertMaterial}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="chat-with-us"
        component={chatWithUs}
        options={{ headerShown: false }}
      />
    </AdminStack.Navigator>
  );
};
