import { StyleSheet, View, FlatList } from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import DashboardCardWithArrowAndIcon from "../../components/dashboardCardWithArrowAndIcon/dashboardCardWithArrowAndIcon";
import CreatorBidProfileComponent from "../../components/creatorBidProfileComponent/creatorBidProfileComponent";
import {
  removeQuotation,
  selectQuotation,
} from "../../redux/actions/project.action";
const CreatorProfileDuringBid = ({
  user,
  navigation,
  selectedProject,
  route,
  selectQuotation,
  removeQuotation,
}) => {
  const [member, setMember] = useState({});

  useEffect(() => {
    if (route.params)
      setMember({
        ...selectedProject.requiredTeam.members.find(
          (x) => x._id === route.params.memberId
        ),
      });
  }, [route.params, selectedProject]);

  const acceptCreator = (item) => {
    if (item.accepted) {
      removeQuotation({
        _id: selectedProject._id,
        user: item.user._id,
        designation: member.type._id,
      });
    } else {
      selectQuotation({
        _id: selectedProject._id,
        uId: item.user._id,
        qId: item._id,
      });
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          title="select creators"
          subtitle="select creators for your project"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          avatarSource={{
            uri: `data:${user.mimType};base64,${user.image}`,
          }}
        ></Header>
      </View>
      <View style={{ marginHorizontal: 10 }}>
        <DashboardCardWithArrowAndIcon
          disableArrow={true}
          icon={false}
          backgroundColor="#000"
          description={`Individual Creator Budget: INR ${member.budget || 0}`}
          subDetails={`Creator finalised: ${
            member.quotations?.filter((x) => x.accepted).length
          }/${member.nos}`}
          subDetailsTextColor={"#FFF"}
          value={member.quotations?.length || 0}
          title={member.type?.name}
        ></DashboardCardWithArrowAndIcon>
        <FlatList
          scrollEnabled
          nestedScrollEnabled
          keyExtractor={(item, index) => item._id + "quotation" + index}
          data={member.quotations || []}
          renderItem={({ item, index }) => (
            <CreatorBidProfileComponent
              name={`${item.user.fName} ${item.user.lName}`}
              ratings={"4.4/5"}
              amount={item.budget}
              checkBox
              starIcon
              onPress={() => acceptCreator(item)}
              checked={item.accepted}
            ></CreatorBidProfileComponent>
          )}
        />
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject }) => ({
  user,
  selectedProject,
});
export default connect(mapStateToProps, { selectQuotation, removeQuotation })(
  CreatorProfileDuringBid
);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
});
