import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  FlatList,
  useWindowDimensions,
} from "react-native";
import React, { useEffect, useState } from "react";
import CreativeCardComponent from "./creativeCardComponent";
import Header from "../../../components/header";
import RoundButton from "../../../components/roundButton";
import LargeTextInput from "../../../components/largeTextInput";
import { connect } from "react-redux";
import Icon from "../../../components/icon";
import { launchCamera, launchImageLibrary } from "react-native-image-picker";
import {
  fetchProjectRole,
  removeRole,
  createRole,
} from "../../../redux/actions/projectRole.action";
import {
  fetchIndividualService,
  removeIndividualService,
  createIndividualService,
} from "../../../redux/actions/individualService.action";
import {
  fetchTechnicalExpertise,
  removeTechnicalExpertise,
  createTechnicalExpertise,
} from "../../../redux/actions/technical-expertise.action";
import {
  createSkill,
  fetchProjectSkill,
  removeSkill,
} from "../../../redux/actions/projectSkill.action";
const AddEditServicesByAdmin = ({
  navigation,
  user,
  route,
  roles,
  onFocus,
  projectRole,
  technicalExpertise,
  individualServices,
  createRole,
  fetchProjectRole,
  createIndividualService,
  fetchIndividualService,
  createTechnicalExpertise,
  fetchTechnicalExpertise,
  fetchProjectSkill,
  createSkill,
  skills,
  removeRole,
  removeIndividualService,
  removeSkill,
  removeTechnicalExpertise,
}) => {
  const dimension = useWindowDimensions();

  let creatorInitObj = {
    name: "",
    description: "",
  };
  let serviceInitObj = {
    name: "",
    description: "",
  };
  let expertiseInitObj = {
    name: "",
    Image: "",
  };
  let skillInitObj = {
    name: "",
  };

  const [data, setData] = useState({});
  useEffect(() => {
    if (route?.params?.data) {
      setData(JSON.parse(route?.params?.data));
    }
  }, [route?.params]);
  const [activeTab, setActiveTab] = useState("Creator");
  const [logo, setLogo] = useState("");

  const [obj, setObj] = useState({});
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    fetchTechnicalExpertise();
    fetchIndividualService();
    fetchProjectRole();
    setInitData();
    fetchProjectSkill();
  }, []);
  const setInitData = () => {
    if (data && data.type === "creative roles") {
      setObj(creatorInitObj);
    } else if (data && data.type === "services") {
      setObj(serviceInitObj);
    } else if (data && data.type === "technical expertise") {
      setObj(expertiseInitObj);
    } else if (data && data.type === "project skills") {
      setObj(skillInitObj);
    }
  };

  const discard = () => {
    setInitData();
  };
  const add = () => {
    if (data && data.type === "creative roles") {
      createRole(obj);
      setObj(creatorInitObj);
    } else if (data && data.type === "services") {
      createIndividualService(obj);
      setObj(serviceInitObj);
    } else if (data && data.type === "technical expertise") {
      createTechnicalExpertise(obj);
      setObj(expertiseInitObj);
      setFileName("");
      setLogo("");
    } else if (data && data.type === "project skills") {
      createSkill(obj);
      setObj(skillInitObj);
    }
  };
  const pickImage = () => {
    const options = {
      storageOptions: {
        path: "images",
        mediaType: "photo",
      },
      includeBase64: true,
      base64: true,
    };
    launchImageLibrary(options, (response) => {
      console.log("Success");
      if (response.didCancel) {
        console.log("User cancelled image picker");
      } else if (response.error) {
        console.log("error");
      } else {
        const source = response.assets[0].base64;
        // console.log(response);
        var file = response.assets[0].fileName;

        setLogo(source);
        setObj({ ...obj, image: "data:image/jpeg;base64," + source });
        setFileName(file);
      }
    });
  };
  return (
    <View style={styles.card_container}>
      <View
        style={{
          minHeight: 170,
          maxHeight: 170,
        }}
      >
        <Header
          enableAvatar={false}
          title={`edit ${data.type}`}
          subtitle={`add or remove ${data.type}`}
          backFunction={() =>
            navigation.goBack() || navigation.navigate("Home")
          }
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ marginHorizontal: 15 }}>
        {data.type === "creative roles" ? (
          <View
            style={{
              backgroundColor: "#FFFFFF",
              flexDirection: "row",
            }}
          >
            <RoundButton
              title={"Creator"}
              pressFunc={() => {
                setActiveTab("Creator");
              }}
              textStyle={{
                color: activeTab === "Creator" ? "#FFF" : "#000",
              }}
              style={{
                backgroundColor: activeTab === "Creator" ? "#073257" : "#FFF",
                width: 140,
                borderWidth: 1,
                borderColor: "#000",
                marginBottom: 20,
                alignSelf: "flex-end",
              }}
            ></RoundButton>
            <RoundButton
              title={"Partners"}
              pressFunc={() => {
                setActiveTab("Partners");
              }}
              textStyle={{
                color: activeTab === "Partners" ? "#FFF" : "#000",
              }}
              style={{
                backgroundColor: activeTab === "Partners" ? "#073257" : "#FFF",
                width: 140,
                borderWidth: 1,
                borderColor: "#000",
                marginBottom: 20,
                marginLeft: 10,
                alignSelf: "flex-end",
              }}
            ></RoundButton>
          </View>
        ) : (
          <></>
        )}
      </View>
      <FlatList
        key={(item) => item._id}
        nestedScrollEnabled
        scrollEnabled
        style={{ marginHorizontal: 15 }}
        keyExtractor={(item) => item.header}
        data={
          data.type === "creative roles"
            ? projectRole
            : data.type === "services"
            ? individualServices
            : data.type === "project skills"
            ? skills
            : technicalExpertise
        }
        renderItem={({ item, index }) => (
          <CreativeCardComponent
            onPressDelete={() => {
              data.type === "creative roles"
                ? removeRole(item._id)
                : data.type === "services"
                ? removeIndividualService(item._id)
                : data.type === "project skills"
                ? removeSkill(item._id)
                : removeTechnicalExpertise(item._id);
            }}
            onPressItem={() =>
              setObj({
                name: item.name,
                description: item.description,
                image: item.image ? "data:image/jpeg;base64," + item.image : "",
                _id: item._id,
              })
            }
            imageUri={"data:image/jpeg;base64," + item.image}
            image={data.type === "technical expertise" ? true : false}
            header={item.name}
            description={item.description}
          />
        )}
      />
      <View style={{ marginHorizontal: 25, height: dimension.height / 7 }}>
        <LargeTextInput
          value={obj.name}
          width={"100%"}
          placeholder={`add ${data.type}`}
          style={{
            borderBottomColor: "#707070",
            fontSize: 20,
            marginHorizontal: 0,
          }}
          onChangeText={(e) => setObj({ ...obj, name: e })}
        ></LargeTextInput>
        {data.type != "project skills" ? (
          <LargeTextInput
            value={
              data.type === "technical expertise" ? fileName : obj.description
            }
            onFocus={
              data.type === "technical expertise" ? () => pickImage() : onFocus
            }
            marginLeft={1}
            width={"100%"}
            placeholder={
              data.type === "technical expertise"
                ? "add logo"
                : "add description"
            }
            style={{
              borderBottomColor: "#707070",
              fontSize: 20,
              marginHorizontal: 0,
            }}
            onChangeText={(e) => setObj({ ...obj, description: e })}
          ></LargeTextInput>
        ) : (
          <></>
        )}
        {data.type === "technical expertise" ? (
          logo ? (
            <Image
              style={{
                height: 30,
                width: 30,
                position: "absolute",
                right: 0,
                bottom: 10,
              }}
              source={{ uri: "data:image/jpeg;base64," + logo }}
            ></Image>
          ) : (
            <Icon
              style={{ position: "absolute", right: 0, bottom: 18 }}
              name="image"
              fill={"#707070"}
            ></Icon>
          )
        ) : (
          <></>
        )}
      </View>
      <View
        style={{
          flexDirection: "row",
          margin: 10,
          justifyContent: "space-around",
        }}
      >
        <RoundButton
          style={{ backgroundColor: "#000000", width: 130 }}
          title={obj._id ? "Update" : "Add+"}
          pressFunc={() => add()}
        ></RoundButton>
        <RoundButton
          style={{ backgroundColor: "#EB4141", width: 130 }}
          title={"Discard"}
          pressFunc={() => discard()}
        ></RoundButton>
      </View>
    </View>
  );
};
const mapStateToProps = ({
  user,
  projectRole,
  technicalExpertise,
  individualServices,
  skills,
}) => ({
  user,
  projectRole,
  technicalExpertise,
  individualServices,
  skills,
});
export default connect(mapStateToProps, {
  createRole,
  fetchProjectRole,
  fetchIndividualService,
  createIndividualService,
  fetchTechnicalExpertise,
  createTechnicalExpertise,
  fetchProjectSkill,
  createSkill,
  removeRole,
  removeIndividualService,
  removeSkill,
  removeTechnicalExpertise,
})(AddEditServicesByAdmin);

const styles = StyleSheet.create({
  card_container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
