const initialState = {};
const selectedProject = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_SELECTED_QUOTATION": {
      return {};
    }
    case "SET_SELECTED_QUOTATION": {
      return action.payload.data;
    }
    case "SELECT_QUOTATION_SUCCESS": {
      return action.payload.data;
    }
    case "REMOVE_QUOTATION_SUCCESS": {
      let quotation = action.payload.data.requiredTeam.members.find(
        (q) => q._id == state._id
      );
      return quotation ? quotation : state;
    }
    case "REMOVE_QUOTATION_FAIL": {
      return state;
    }
    case "LOGOUT": {
      return {};
    }
    default: {
      return state;
    }
  }
};
export default selectedProject;
