import * as React from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import adjust from "../adjust";
import Icon from "../icon";

export default ({ value, onChangeText, style, onPress }) => {
  const [editable, setEditable] = React.useState(false);
  const closeEdit = () => {
    onPress();
    setEditable(false);
  };
  return editable ? (
    <View style={{ flex: 3, flexDirection: "row" }}>
      <TextInput
        style={{
          flex: 9,
          borderBottomWidth: 1,
          fontSize: adjust(11),
          borderBottomColor: "#CECBCB",
        }}
        onChangeText={(text) => {
          if (onChangeText) {
            onChangeText(text);
          }
        }}
        value={value}
      ></TextInput>
      <TouchableOpacity
        style={{ flex: 1, zIndex: 999 }}
        onPress={() => closeEdit()}
      >
        <Icon name="tick" style={{ flex: 1 }}></Icon>
      </TouchableOpacity>
    </View>
  ) : (
    <Text style={style} onPress={() => setEditable(true)}>
      {value}
    </Text>
  );
};
