import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView, Image } from "react-native";
import { connect } from "react-redux";
import Icon from "../../components/icon";
import ProfessionalProfileComponent from "./professionalProfileComponent";
import {
  selectQuotation,
  removeQuotation,
  setSelectedQuotation,
} from "../../redux/actions/project.action";
import adjust from "../../components/adjust";
import Header from "../../components/header";

const ProfessionalProfile = ({
  navigation,
  selectedQuotation,
  selectedProject,
  selectQuotation,
  removeQuotation,
  validate,
  setSelectedQuotation,
}) => {
  const [quotations, setQuotations] = useState(selectedQuotation.quotations);
  const selectOnPress = (userID, qId) => {
    let quotationObj = {
      _id: selectedProject._id,
      uId: userID,
      qId: qId,
    };
    selectQuotation(quotationObj);
  };

  // useEffect(() => {
  //   console.log(selectedQuotation);
  //   if (selectedQuotation && selectedQuotation._id) {
  //     console.log("CALL");
  //     setSelectedQuotation({
  //       ...selectedProject.requiredTeam.members.find(
  //         (x) => x._id === selectedQuotation._id
  //       ),
  //     });
  //   }
  // }, [selectedProject]);

  const removeOnPress = (userID) => {
    let quotationObj = {
      _id: selectedProject._id,
      user: userID,
      designation: selectedQuotation.type._id,
    };
    removeQuotation(quotationObj);
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#FAFAFA" }}>
      {/* <View
        style={{
          flex: 1,
          backgroundColor: "#FFFFFF",
          maxHeight: 100,
          padding: 20,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Icon name="back"></Icon>
          </TouchableOpacity>
          <Text style={{ fontSize: adjust(15), marginLeft: 10 }}>
            Setup Project
          </Text>
        </View>
      </View> */}
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Back to Setup"
          subtitle={""}
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FAFAFA" }}
        ></Header>
      </View>
      <ScrollView style={{ flex: 1, marginHorizontal: 15, marginTop: 20 }}>
        {selectedQuotation?.quotations.map((q, i) => {
          return (
            <ProfessionalProfileComponent
              key={i}
              quotation={true}
              creatorData={q.user ? { ...q.user, ...q.creator } : {}}
              quotedAmount={q.budget ? q.budget : null}
              bio={q.creator ? q.creator.workThroughProject : null}
              designation={q.user.designation ? q.user.designation : null}
              avatar={q.user.image ? q.user.image : null}
              mimType={q.user.mimType ? q.user.mimType : null}
              selectOnPress={() => selectOnPress(q.user._id, q._id)}
              removeOnPress={() => removeOnPress(q.user._id)}
              selected={q.accepted}
              rating={q.creator ? q.creator.rating : null}
              selectedQuotation={selectedQuotation}
              validated={(e) => validate(e)}
              navigation={navigation}
            ></ProfessionalProfileComponent>
          );
        })}
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ selectedQuotation, selectedProject }) => ({
  selectedQuotation,
  selectedProject,
});
export default connect(mapStateToProps, {
  selectQuotation,
  removeQuotation,
  setSelectedQuotation,
})(ProfessionalProfile);
