exports.primaryColor = (userType) =>
  userType === "CLIENT"
    ? "#3B8CD1"
    : ["PARTNER", "ONEDESIGN"].includes(userType)
    ? "#073257"
    : userType === "ACCOUNTANT"
    ? "#7A9D54"
    : userType === "SALES"
    ? "#F27253"
    : "#FF973E";
exports.currenyFormat = (number) =>
  number
    ? "INR " + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";

exports.abbrNum = (number, decPlaces) => {
  decPlaces = Math.pow(10, decPlaces);
  number = Math.round(number * decPlaces) / decPlaces;
  var abbrev = ["K", "M", "B", "T"];
  for (var i = abbrev.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }
      number += abbrev[i];
      break;
    }
  }
  return number;
};

exports.fullName = (user) => `${user?.fName} ${user?.lName}`;
