import React, { useEffect, useState } from "react";
import { View, TextInput, PixelRatio } from "react-native";
import adjust from "./adjust";

let validationReg;

export default function ModalLargeTextInput({
  placeholder,
  style,
  containerStyle,
  onChangeText,
  secureTextEntry,
  placeholderColor,
  isValid,
  inValidStyle,
  validationType,
  validationRegex,
  required = false,
  value,
  keyboardType,
  numberOfLines,
}) {
  const [valid, setValid] = useState(true);
  function validate(value) {
    if (required && value) {
      if (validationReg) {
        setValid(validationReg.test(value));
      } else {
        setValid(true);
      }
    } else if (value) {
      if (validationReg) {
        setValid(validationReg.test(value));
      }
    } else {
      setValid(false);
    }
    if (isValid) {
      isValid(valid);
    }
  }
  useEffect(() => {
    if (validationRegex || validationType)
      validationReg = validationRegex
        ? new RegExp(validationRegex)
        : validationType === "email"
        ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi
        : validationType === "phone"
        ? /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/gi
        : validationType === "aadhar"
        ? /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/gi
        : validationType === "pan"
        ? /^[A-Z]{5}[0-9]{4}[A-Z]{1}/gi
        : null;
  });
  return (
    <View
      style={[
        { width: "100%" },
        !valid && inValidStyle ? inValidStyle : containerStyle,
      ]}
    >
      <TextInput
        numberOfLines={numberOfLines}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
        placeholder={placeholder}
        placeholderTextColor={placeholderColor ? placeholderColor : "#828282"}
        onChangeText={(e) => {
          validate(e);
          onChangeText(e);
        }}
        value={value}
        style={[
          {
            fontSize: adjust(25) * PixelRatio.getFontScale(),
            fontWeight: "bold",
            width: "100%",
            borderBottomWidth: 1,
            padding: 10,
            color: "black",
            maxHeight: 60,
            minHeight: 60,
            maxWidth: "100%",
            marginTop: 20,
          },
          style,
        ]}
      ></TextInput>
    </View>
  );
}
