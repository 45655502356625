import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  Dimensions,
  Platform,
} from "react-native";
import Header from "../../components/header";
import adjust from "../../components/adjust";
import { connect } from "react-redux";
import Icon from "../../components/icon";
import RoundButton from "../../components/roundButton";
import InputDialogBox from "../../components/dialogBox/dialog-box";
import { launchImageLibrary } from "react-native-image-picker";
import SmallTextInput from "../../components/smallTextInput";
import ProjectTextInput from "./setupProjectComponents/initiateProject/projectTextInput";
import * as DocumentPicker from "expo-document-picker";
import CreatorProfileComponent from "../../components/creatorProfileComponent/creatorProfileComponent";
import {
  logout,
  updateCreator,
  updateCreatorExperience,
  updateProfile,
} from "../../redux/actions/user.action";
import CreatorSignupInput from "../../components/creator-signup-input";
import {
  downloadDeliverableFile,
  fetchAllClosedProjects,
} from "../../redux/actions/project.action";
import * as WebBrowser from "expo-web-browser";
import config from "../../config/config";
import LargeButton from "../../components/largeButton";
import { fetchTechnicalExpertise } from "../../redux/actions/technical-expertise.action";

const CreatorProfile = ({
  user,
  navigation,
  updateCreator,
  fetchAllClosedProjects,
  closedProjects,
  updateCreatorExperience,
  downloadDeliverableFile,
  logout,
  disableLogout,
  editCreator,
  updateProfile,
  currentUser,
  fetchTechnicalExpertise,
  technicalExpertise,
}) => {
  let academicObject = {
    college: "",
    primaryCourse: "",
    year: "",
  };
  let personalObject = {
    image: "",
    name: "",
    primaryProfile: "",
    bio: "",
    pan: "",
    aadhar: "",
    pin: "",
    address: "",
    gender: "",
  };
  let experienceObject = {
    project: {},
    work1: null,
    work2: null,
    work3: null,
  };

  const window = Dimensions.get("window");
  const [personalInfo, setPersonalInfo] = useState(personalObject);
  const [personalModal, setPersonalModal] = useState(false);
  const [profile, setProfile] = useState(false);
  const [academicModal, setAcademicModal] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [education, setEducation] = useState(academicObject);
  const [experienceModal, setExperienceModal] = useState(false);
  const [experienceData, setExperienceData] = useState(experienceObject);
  const [expertiseModal, setExpertiseModal] = useState(false);
  const [selectedExpertises, setSelectedExpertises] = useState([]);

  const selectExpertise = (item) => {
    if (!item?.selected) {
      setSelectedExpertises([
        ...selectedExpertises.map((e) =>
          e._id === item._id ? { ...item, selected: true } : { ...e }
        ),
      ]);
    } else {
      setSelectedExpertises([
        ...selectedExpertises.map((e) =>
          e._id === item._id ? { ...item, selected: false } : { ...e }
        ),
      ]);
    }
  };
  const setEducationaldata = (data) => {
    setEducation({ ...education, ...data });
  };
  const setPersonalData = (data) => {
    setPersonalInfo({ ...personalInfo, ...data });
  };

  const updateExperienceData = (data) => {
    setExperienceData({ ...experienceData, ...data });
  };

  const pickImage = () => {
    const options = {
      storageOptions: {
        path: "images",
        mediaType: "photo",
      },
      includeBase64: true,
      base64: true,
    };
    launchImageLibrary(options, (response) => {
      console.log("Success");
      if (response.didCancel) {
        console.log("User cancelled image picker");
      } else if (response.error) {
        console.log("error");
      } else {
        const source = response.assets[0].base64;
        setPersonalData({
          image: "data:image/jpeg;base64," + source,
        });
        setProfilePic(source);
      }
    });
  };
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    return splitDataURI.length > 1
      ? b64toBlob(splitDataURI[1])
      : b64toBlob(dataURI);
  }
  const getExt = (fileName) => {
    const fileParts = fileName.split(".");
    return fileParts[fileParts.length - 1];
  };
  const selectFile = async (dest) => {
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: "*/*",
      }).then(async (result) => {
        return {
          name: result.name,
          base64: result.uri,
          type: result.type,
        };
      });
      if (res.type === "success") {
        if (dest === "work1") {
          updateExperienceData({
            work1:
              Platform.OS === "web"
                ? DataURIToBlob(res.base64)
                : {
                    uri: res.base64,
                    type: `application/${getExt(res.name)}`,
                    name: res.name,
                  },
          });
        }
        if (dest === "work2") {
          updateExperienceData({
            work2:
              Platform.OS === "web"
                ? DataURIToBlob(res.base64)
                : {
                    uri: res.base64,
                    type: `application/${getExt(res.name)}`,
                    name: res.name,
                  },
          });
        }
        if (dest === "work3") {
          updateExperienceData({
            work3:
              Platform.OS === "web"
                ? DataURIToBlob(res.base64)
                : {
                    uri: res.base64,
                    type: `application/${getExt(res.name)}`,
                    name: res.name,
                  },
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const update = (type) => {
    if (type === "academic") {
      updateProfile({ academic: education });
      onCloseAcademicModal();
    } else if (type === "profile") {
      updateProfile(personalInfo);
      setPersonalInfo(personalObject);
      setPersonalModal(false);
    } else if (type === "expertise") {
      updateCreator({
        expertise: selectedExpertises
          .filter((x) => x.selected)
          .map((x) => x._id),
      });
      setExpertiseModal(false);
      setSelectedExpertises([]);
    }
  };
  const remove = (type, id) => {
    if (type === "academic") {
      updateProfile({ removeAcademic: id });
      onCloseAcademicModal();
    } else if (type === "experience") {
      updateProfile({ removeOnedesignExperience: id });
      onCloseExperienceModal();
    }
  };

  const onCloseAcademicModal = () => {
    setAcademicModal(false);
    setEducation(academicObject);
  };

  const onCloseExperienceModal = () => {
    setExperienceModal(false);
    setExperienceData(experienceObject);
  };
  const submitExperience = () => {
    let formData = new FormData();
    formData.append("project", experienceData.project._id);
    formData.append("files", experienceData.work1);
    formData.append("files", experienceData.work2);
    formData.append("files", experienceData.work3);
    updateCreatorExperience(formData);
    setExpertiseModal(false);
  };

  const downloadFile = async (ids) => {
    if (ids && ids.length > 0) {
      for (let index = 0; index < ids.length; index++) {
        const id = ids[index];
        if (Platform.OS != "web") {
          await WebBrowser.openBrowserAsync(
            `${config.baseUrl}api/project/deliverables/file?fileId=${id}`
          );
        } else {
          downloadDeliverableFile(id);
        }
      }
    }
  };

  useEffect(() => {
    setSelectedExpertises(
      technicalExpertise?.map((e) =>
        user.expertise?.find((x) => x._id === e._id)
          ? { ...e, selected: true }
          : { ...e, selected: false }
      )
    );
  }, []);
  useEffect(() => {
    fetchAllClosedProjects();
    setPersonalData(user);
    fetchTechnicalExpertise();
  }, [user]);
  const genderSelect = [
    { _id: "Male", name: "Male" },
    { _id: "Female", name: "Female" },
    { _id: "Transgender", name: "Transgender" },
  ];
  const handleCallback = (childData) => {
    setPersonalData({ gender: childData.name });
  };

  const openExertiseModal = () => {
    setExpertiseModal(true);
    setSelectedExpertises(
      technicalExpertise.map((x) => ({
        ...x,
        selected: user.expertise.find((a) => a._id === x._id) ? true : false,
      }))
    );
  };
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: "18%",
          maxHeight: "18%",
        }}
      >
        <Header
          title={editCreator ? "creator application" : "my profile"}
          subtitle={
            editCreator ? "View Creator Details" : "Tap on anything to edit it"
          }
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          avatarSource={{
            uri: `data:${user?.mimType};base64,${user?.image}`,
          }}
        ></Header>
      </View>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 20 }}
        nestedScrollEnabled={true}
        style={styles.centeredView}
      >
        <CreatorProfileComponent
          onPressAddExpertise={() => openExertiseModal()}
          onPressRaiseRequest={() => navigation.navigate("chat-with-us")}
          onPressChangePassword={() => navigation.navigate("changePassword")}
          currentUser={currentUser}
          disableLogout={disableLogout}
          onPressEdit={() => setPersonalModal(!personalModal)}
          onAddEducation={() => setAcademicModal(!academicModal)}
          onPressWithOneDesign={() => setExperienceModal(!experienceModal)}
          user={user}
          removeAcademic={(id) => remove("academic", id)}
          removeExperience={(id) => remove("experience", id)}
          downloadFile={(ids) => downloadFile(ids)}
        ></CreatorProfileComponent>
        <View style={{ marginHorizontal: 10 }}>
          {disableLogout && currentUser === "ONEDESIGN" ? (
            <TouchableOpacity style={{ alignItems: "center", bottom: 10 }}>
              <LargeButton
                backgroundColor={"#e6ab81"}
                textColor={"#fff"}
                title={"Remove Creator"}
              ></LargeButton>
            </TouchableOpacity>
          ) : currentUser ? (
            <></>
          ) : (
            <LargeButton
              arrowColor={"#fff"}
              pressFunc={() => logout()}
              backgroundColor={"#000"}
              textColor={"#fff"}
              title={"Logout"}
            ></LargeButton>
          )}
        </View>
      </ScrollView>
      <InputDialogBox
        modalViewStyle={{ height: 400 }}
        onPressCross={() => onCloseAcademicModal()}
        showModal={academicModal}
        submit={null}
        jsx={
          <View style={{ flex: 1, width: "100%" }}>
            <Text style={styles.Edit_Text}>Add Academic</Text>
            <TouchableOpacity
              onPress={() => setAcademicModal(false)}
              style={{
                marginTop: 10,
                position: "absolute",
                right: 0,
                top: -10,
              }}
            >
              <Icon fill="#000" name="cross"></Icon>
            </TouchableOpacity>

            <SmallTextInput
              labelStyle={{ color: "#000" }}
              label="Collage Name"
              placeholder="Example: Lovely Professional University "
              onChangeText={(e) => setEducationaldata({ college: e })}
              required={true}
              inValidText="Please enter college Name"
              value={education.college}
            ></SmallTextInput>
            <SmallTextInput
              labelStyle={{ color: "#000" }}
              label="Primary Course"
              placeholder="Example:Mechanical Engineering "
              onChangeText={(e) => setEducationaldata({ primaryCourse: e })}
              required={true}
              inValidText="Please enter primaryCourse Name"
              value={education.primaryCourse}
            ></SmallTextInput>
            <SmallTextInput
              labelStyle={{ color: "#000" }}
              keyboardType={"numeric"}
              label="Year of Passing Out"
              placeholder="Example:2015"
              onChangeText={(e) => setEducationaldata({ year: e })}
              required={true}
              inValidText="Please enter year of passout"
              value={education.year}
            ></SmallTextInput>
            <TouchableOpacity style={{ alignItems: "center", marginTop: 15 }}>
              <RoundButton
                pressFunc={() => update("academic")}
                title="Save"
                style={{
                  backgroundColor: "#000000",
                  width: 250,
                }}
              ></RoundButton>
            </TouchableOpacity>
          </View>
        }
      />
      <InputDialogBox
        modalViewStyle={{ height: 500 }}
        onPressCross={() => {
          setProfile(null);
          setPersonalModal(false);
        }}
        showModal={personalModal}
        submit={null}
        jsx={
          <View style={{ flex: 1, width: "100%" }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={styles.Edit_Text}>Edit Profile </Text>
              <TouchableOpacity
                onPress={() => setPersonalModal(false)}
                style={{
                  marginTop: 10,

                  right: 0,
                  top: -10,
                }}
              >
                <Icon fill="#000" name="cross"></Icon>
              </TouchableOpacity>
            </View>
            <ScrollView style={{ height: "100%", width: "100%" }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                  marginLeft: 30,
                }}
              >
                {profilePic ? (
                  <TouchableOpacity onPress={() => pickImage()}>
                    <Image
                      source={{
                        uri:
                          Platform.OS === "web"
                            ? profilePic
                            : "data:image/jpeg;base64," + profilePic,
                      }}
                      style={styles.circle}
                    ></Image>
                  </TouchableOpacity>
                ) : (
                  <View style={styles.circle}>
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                      onPress={() => pickImage()}
                    >
                      <Icon name="plus" style={{ flex: 1 }}></Icon>
                    </TouchableOpacity>
                  </View>
                )}
                <View style={{ marginLeft: 10 }}>
                  <Text>Upload your photo</Text>
                </View>
              </View>
              <SmallTextInput
                labelStyle={{ color: "#000" }}
                label="Full Name"
                placeholder="Example:Roy johnson "
                onChangeText={(e) => setPersonalData({ name: e })}
                required={true}
                inValidText="Please Enter Name"
                value={
                  `${personalInfo.fName} ${personalInfo.lName}` ||
                  personalInfo.name
                }
              ></SmallTextInput>
              <SmallTextInput
                labelStyle={{ color: "#000" }}
                label="Primary profile"
                placeholder="Example:I love Onedesign"
                onChangeText={(e) => setPersonalData({ primaryProfile: e })}
                required={true}
                inValidText="Please enter primary profile"
                value={personalInfo.primaryProfile}
              ></SmallTextInput>
              <SmallTextInput
                labelStyle={{ color: "#000" }}
                label="Bio"
                placeholder="Example: I love Onedesign"
                onChangeText={(e) => setPersonalData({ bio: e })}
                required={true}
                inValidText="Please enter your bio"
                value={personalInfo.bio}
              ></SmallTextInput>
              <View style={{ maxHeight: 110, marginHorizontal: 15 }}>
                <ProjectTextInput
                  selectionValue={{ name: personalInfo.gender }}
                  placeholderPadding={0}
                  removeBottomBorder={true}
                  removeBorder={true}
                  titleStyle={{ fontSize: 13 }}
                  title="Gender"
                  placeholder="Example: Female"
                  renderData={genderSelect}
                  onSelectionChange={handleCallback}
                  required={true}
                ></ProjectTextInput>
              </View>

              <SmallTextInput
                validationType={"pan"}
                labelStyle={{ color: "#000" }}
                label="Pan"
                placeholder="Example: ABCTY1234D"
                onChangeText={(e) => setPersonalData({ pan: e })}
                required={true}
                inValidText="Please enter your pan"
                value={personalInfo?.pan}
              ></SmallTextInput>
              <SmallTextInput
                validationType={"aadhar"}
                labelStyle={{ color: "#000" }}
                label="Aadhar"
                placeholder="Example: 4444 3333 2222"
                onChangeText={(e) => setPersonalData({ aadhar: e })}
                required={true}
                inValidText="Please enter your Aadhar"
                value={personalInfo?.aadhar}
              ></SmallTextInput>
              <SmallTextInput
                keyboardType={"numeric"}
                validationType={"pin"}
                labelStyle={{ color: "#000" }}
                label="Pin"
                placeholder="Example: 758825"
                onChangeText={(e) => setPersonalData({ pin: Number(e) })}
                required={true}
                inValidText="Please enter your Pin"
                value={personalInfo.pin ? `${Number(personalInfo.pin)}` : ""}
              ></SmallTextInput>
              <SmallTextInput
                labelStyle={{ color: "#000" }}
                label="Address"
                placeholder="Example: At/Po - Bhubaneswar"
                onChangeText={(e) => setPersonalData({ address: e })}
                required={true}
                inValidText="Please enter your Address"
                value={personalInfo?.address}
              ></SmallTextInput>
              <TouchableOpacity style={{ alignItems: "center", marginTop: 15 }}>
                <RoundButton
                  pressFunc={() => update("profile")}
                  title="Save"
                  style={{
                    backgroundColor: "#000000",
                    width: 250,
                  }}
                ></RoundButton>
              </TouchableOpacity>
            </ScrollView>
          </View>
        }
      />
      <InputDialogBox
        modalViewStyle={{ height: 580 }}
        onPressCross={() => onCloseExperienceModal()}
        showModal={experienceModal}
        submit={null}
        jsx={
          <View style={{ flex: 1, width: "100%" }}>
            <Text style={styles.Edit_Text}>
              Add Experience (with Onedesign)
            </Text>
            <TouchableOpacity
              onPress={() => setExperienceModal(false)}
              style={{
                marginTop: 15,
                position: "absolute",
                right: 10,
              }}
            >
              <Icon fill="#000" name="cross"></Icon>
            </TouchableOpacity>
            <View style={{ maxHeight: 110, marginHorizontal: 15 }}>
              <CreatorSignupInput
                placeholderStyle={{
                  top: 15,
                  fontWeight: "200",
                  color: "#B1B1B1",
                }}
                placeholderFontSize={12}
                disableBottomBorder={true}
                bottom={-15}
                displayField={"title"}
                width={350}
                titleStyle={{ fontSize: 13 }}
                removeBorder={true}
                style={{ marginHorizontal: 40 }}
                title="Select from the work"
                renderData={closedProjects}
                onSelectionChange={(data) =>
                  updateExperienceData({ project: data })
                }
                // selectionValue={experienceData.project}
                placeholder="Tap to select from your work(with Onedesign)"
                required={true}
                // isValid={(v) => setApplyingForValid(v)}
              ></CreatorSignupInput>
            </View>
            <View style={{ maxHeight: 110 }}>
              <ProjectTextInput
                removeBottomBorder={true}
                removeBorder={true}
                titleStyle={{ fontSize: 13 }}
                title="Upload Your work"
                placeholder={
                  experienceData.work1 ? "Upload Successful" : "Click to Upload"
                }
                docType={true}
                multiline={false}
                data={experienceData.work1}
                pickDocument={() => selectFile("work1")}
              ></ProjectTextInput>
            </View>
            <View style={{ maxHeight: 110 }}>
              <ProjectTextInput
                removeBottomBorder={true}
                removeBorder={true}
                titleStyle={{ fontSize: 13 }}
                title="Upload Your work"
                placeholder={
                  experienceData.work2 ? "Upload Successful" : "Click to Upload"
                }
                docType={true}
                multiline={false}
                data={experienceData.work2}
                pickDocument={() => selectFile("work2")}
              ></ProjectTextInput>
            </View>
            <View style={{ maxHeight: 110 }}>
              <ProjectTextInput
                removeBottomBorder={true}
                removeBorder={true}
                titleStyle={{ fontSize: 13 }}
                title="Upload Your work"
                placeholder={
                  experienceData.work3 ? "Upload Successful" : "Click to Upload"
                }
                docType={true}
                multiline={false}
                data={experienceData.work3}
                pickDocument={() => selectFile("work3")}
              ></ProjectTextInput>
            </View>

            <RoundButton
              pressFunc={() => submitExperience()}
              title="Save"
              style={{
                backgroundColor: "#000000",
                width: 250,
                alignSelf: "center",
              }}
            ></RoundButton>
          </View>
        }
      />
      <InputDialogBox
        modalViewStyle={{ height: 200 }}
        onPressCross={() => setExpertiseModal(false)}
        showModal={expertiseModal}
        submit={null}
        jsx={
          <View style={{ flex: 1, width: "100%" }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={styles.Edit_Text}>Add Expertise</Text>
              <TouchableOpacity
                onPress={() => setExpertiseModal(false)}
                style={{
                  marginTop: 10,
                  top: -10,
                }}
              >
                <Icon fill="#000" name="cross"></Icon>
              </TouchableOpacity>
            </View>
            <ScrollView>
              <View style={{ flexDirection: "row" }}>
                {selectedExpertises?.map((e, i) => (
                  <TouchableOpacity
                    onPress={() => selectExpertise(e)}
                    style={{
                      borderWidth: 1,
                      borderRadius: 50,
                      borderColor: "#e6e600",
                      padding: 5,
                      marginLeft: 2,
                      marginTop: 16,
                    }}
                    key={`${i}`}
                  >
                    <Image
                      style={{ height: 50, width: 50, borderRadius: 50 }}
                      source={{ uri: "data:image/jpeg;base64," + e.image }}
                    ></Image>
                    {e.selected ? (
                      <View
                        style={{ position: "absolute", top: -10, right: 0 }}
                      >
                        <Icon fill={"#008000"} name={"tick"}></Icon>
                      </View>
                    ) : (
                      <></>
                    )}
                  </TouchableOpacity>
                ))}
              </View>
            </ScrollView>
            <RoundButton
              pressFunc={() => update("expertise")}
              title="Add"
              style={{
                backgroundColor: "#000000",
                width: 250,
                alignSelf: "center",
              }}
            ></RoundButton>
          </View>
        }
      />
    </View>
  );
};

const mapStateToProps = ({ closedProjects, technicalExpertise }) => ({
  closedProjects,
  technicalExpertise,
});
export default connect(mapStateToProps, {
  updateCreator,
  fetchAllClosedProjects,
  updateCreatorExperience,
  downloadDeliverableFile,
  logout,
  updateProfile,
  fetchTechnicalExpertise,
})(CreatorProfile);

const styles = StyleSheet.create({
  container: { flex: 1 },
  card_Container: {
    flex: 2,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },

  card_image: {
    width: 90,
    height: 90,
    borderWidth: 3,
    borderColor: "#ffd500",
    borderRadius: 100 / 2,
  },

  nameText: {
    fontSize: adjust(19),
    fontWeight: "bold",
  },
  profile: {
    flex: 1,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 10,
    flexDirection: "row",
    marginTop: 10,
    maxWidth: 400,
    width: "100%",
    maxHeight: 90,
    minHeight: 60,
  },

  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  centeredView: {
    flex: 1,
  },
  label: {
    fontWeight: "bold",
    color: "#000000",
  },
  card_view: {
    flex: 1,
    marginTop: 10,
    maxWidth: 130,
    minWidth: 130,
  },
  Text_style: {
    textAlign: "justify",
    color: "#000",
  },
  upper_text: {
    marginBottom: 5,
    fontWeight: "bold",
    color: "#000",
  },

  softwarepage: {
    flex: 1,
    // borderBottomWidth: 1,
    marginTop: 5,
    // maxWidth: 250,
    minWidth: 250,
  },
  Professional_text: {
    marginBottom: 10,
    fontWeight: "bold",
    color: "#828282",
  },
  page_view: {
    flex: 1,
    minWidth: 250,
    marginBottom: 10,
  },
  view_page: {
    //flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    flexDirection: "column",
    flexWrap: "wrap",
    marginTop: 10,
    maxWidth: 400,
    width: "100%",
  },
  middel_view: {
    flex: 1,
    minWidth: 250,
    marginBottom: 10,
  },
  image_style: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  Edit_header: {
    flex: 1,
    minWidth: 250,
    marginTop: 10,

    marginLeft: 20,
  },
  Edit_Text: {
    marginBottom: 20,
    marginTop: 10,
    fontWeight: "bold",
    color: "#828282",
    fontSize: 15,
    alignSelf: "flex-start",
    marginLeft: 20,
  },
  circle: {
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    backgroundColor: "#B4B4B4",
    justifyContent: "center",
    alignItems: "center",
  },
});
