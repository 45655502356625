export const fetchProjectAudience = (projectObj) => (dispatch) => {
  return dispatch({
    type: "FETCH_AUDIENCE",
    payload: {
      request: {
        url: `/api/project-audience`,
        method: "GET",
      },
    },
  });
};

export const createAudience = (audienceObj) => (dispatch) => {
  return dispatch({
    type: "CREATE_AUDIENCE",
    payload: {
      request: {
        url: `/api/project-audience`,
        method: "POST",
        data: audienceObj,
      },
    },
  });
};

export const removeAudience = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_AUDIENCE",
    payload: {
      request: {
        url: `/api/project-audience/${id}`,
        method: "DELETE",
      },
    },
  });
};


