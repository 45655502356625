import * as React from "react";
import { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Platform,
} from "react-native";
import Icon from "../components/icon";
import { Image } from "react-native";
import PopUp from "../components/popUp/popUp";
import EditableLabel from "../components/editableLabel/editableLabel";
import Skills from "../components/profSkils";
import { connect } from "react-redux";
import { launchImageLibrary } from "react-native-image-picker";
import { logout, updateProfile } from "../redux/actions/user.action";
import adjust from "../components/adjust";
import LargeButton from "../components/largeButton";
import Header from "../components/header";
import CreatorOwnProfileEdit from "../screens/common/userProfile";
const EditYourProfile = ({ navigation, user, updateProfile, logout }) => {
  const [profilePic, setProfilePic] = useState(user.image ? user.image : null);
  const [name, setName] = useState(user.name);
  const [bio, setBio] = useState(user.bio);
  const [email, setEmail] = useState(user.email);
  const [phoneNo, setPhoneNo] = useState(user.phoneNo);
  const [accountNumber, setAccountNumber] = useState(user.accountNumber);
  const [ifsc, setIfsc] = useState(user.ifsc);
  const [gender, setGender] = useState(user.gender);
  const [levelOfWorkId, setLevel] = useState(user.level);
  const [skill, setSkill] = useState([]);
  const [address, setAddress] = useState(user.address);
  const [link] = useState(true);
  const [active] = useState(true);

  const setSkillFromComponent = (selectedSkill) => {
    let arr = skill;
    selectedSkill.forEach((element) => {
      let index = skill.findIndex((x) => x._id === element._id);
      if (index === -1) {
        arr.push(element);
      }
    });
    setSkill([...arr]);
    updateProfile({ skill: arr });
  };

  useEffect(() => {
    setSkill(user.skill);
  }, [user]);

  const removeSkill = (index) => {
    skill.splice(index, 1);
    setSkill([...skill]);
    updateProfile({ skill: skill });
  };
  const pickImage = () => {
    launchImageLibrary(
      {
        mediaType: "photo",
        includeBase64: true,
        quality: 0.5,
        noData: true,
        maxHeight: 200,
        maxWidth: 200,
      },
      (response) => {
        if (response.didCancel) {
          console.log("User cancelled image picker");
        } else if (response.error) {
          console.log("error");
        } else {
          updateProfile({
            image: `data:${response.assets[0].type};base64,${response.assets[0].base64}`,
          });
        }
      }
    );
  };
  // const pickImage = async () => {
  //   const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();
  //   if (status === "granted") {
  //     try {
  //       let result = await ImagePicker.launchImageLibraryAsync({
  //         mediaTypes: ImagePicker.MediaTypeOptions.Images,
  //         allowsEditing: false,
  //         base64: true,
  //         aspect: [4, 3],
  //         quality: 1,
  //         allowsMultipleSelection: false,
  //       });
  //       setTimeout(async () => {
  //         const androidResult = await ImagePicker.getPendingResultAsync();
  //         let finalResult = androidResult.length ? androidResult[0] : result;

  //         if (!finalResult.cancelled) {
  //           setProfilePic(finalResult.base64);
  //           let imageObj = {
  //             image:
  //               Platform.OS === "web"
  //                 ? finalResult.uri
  //                 : "data:image/jpeg;base64," + finalResult.base64,
  //           };
  //           updateProfile(imageObj);
  //         }
  //       }, 1000);
  //     } catch (E) {
  //       console.error(E);
  //     }
  //   } else {
  //     let { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  //     status === "granted"
  //       ? pickImage()
  //       : alert("Please allow the app for camera access to continue further!");
  //   }
  // };
  let userSkill = user.skill;
  // const updateUser = () => {
  //   let userObj = {
  //     image: "data:image/jpeg;base64," + profilePic,
  //     name,
  //     bio,
  //     phoneNo,
  //     gender,
  //     levelOfWorkId,
  //     skill,
  //     link,
  //     address,
  //     active,
  //     userType: "USER",
  //     role: "USER",
  //   };
  //   updateProfile(userObj);
  // };
  const Gender = {
    Gender: [
      { name: "Male", _id: "Male" },
      { name: "Female", _id: "Female" },
      { name: "Transgender", _id: "Transgender" },
    ],
  };
  const Level = {
    levelOfWorkId: [
      { name: "Rookie" },
      { name: "Intermediate" },
      { name: "Pro" },
    ],
  };
  const handleGenderCallback = (childData) => {
    setGender(childData);
    let gender = { gender: childData };
    updateProfile(gender);
  };
  const handleLevelCallback = (childData) => {
    setLevel(childData);
    let levelOfWorkId = { levelOfWorkId: childData.name };
    updateProfile(levelOfWorkId);
  };

  return user.userType === "CREATOR" ? (
    <CreatorOwnProfileEdit navigation={navigation}></CreatorOwnProfileEdit>
  ) : (
    <View
      style={{
        flex: 1,
        backgroundColor: "#FFFFFF",
        marginBottom: 20,
      }}
    >
      {/* <View
        style={{
          left: 25,
          marginTop: 40,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Icon name="back"></Icon>
        </TouchableOpacity>
        <Text style={styles.title}>Edit Your Profile</Text>
      </View> */}
      <View
        style={{
          backgroundColor: "#FFFFFF",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Header
          title="Edit Your Profile"
          subtitle=""
          backFunction={() => navigation.navigate("Home", {})}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <View style={{ flex: 4 }}>
        <ScrollView
          nestedScrollEnabled={true}
          style={{
            backgroundColor: "#FFFFFF",
          }}
        >
          {user.image ? (
            <TouchableOpacity
              style={{
                flex: 1,
                alignItems: "center",
                maxHeight: 120,
                maxWidth: 120,
                borderRadius: 120 / 2,
                alignSelf: "flex-start",
                marginTop: 30,
                marginLeft: 30,
              }}
              onPress={() => {
                pickImage();
              }}
            >
              <Image
                style={styles.image}
                source={{
                  uri: "data:image/jpeg;base64," + user.image,
                }}
              ></Image>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={{
                flex: 1,
                alignSelf: "center",
                alignSelf: "flex-start",
                marginTop: 30,
                marginLeft: 30,
              }}
              onPress={() => {
                pickImage();
              }}
            >
              <Image
                style={styles.image}
                source={require("../assets/avatar/avatar.png")}
              ></Image>
            </TouchableOpacity>
          )}
          <View
            style={{
              flex: 1,
              marginHorizontal: 30,

              marginTop: 20,
            }}
          >
            <View style={styles.rowStyle}>
              <Text style={styles.text}>Name</Text>
              <EditableLabel
                style={styles.textinput}
                value={name}
                onChangeText={(e) => setName(e)}
                onPress={() => {
                  updateProfile({ name: name });
                }}
              ></EditableLabel>
            </View>
            <View style={styles.rowStyle}>
              <Text style={styles.text}>Bio</Text>
              <EditableLabel
                style={styles.textinput}
                value={bio}
                onChangeText={(e) => setBio(e)}
                onPress={() => {
                  updateProfile({ bio: bio });
                }}
              ></EditableLabel>
            </View>
            <View style={styles.rowStyle}>
              <Text style={styles.text}>E-mail</Text>
              <EditableLabel
                style={styles.textinput}
                value={email}
                onChangeText={(e) => setEmail(e)}
                onPress={() => {
                  updateProfile({ email: email });
                }}
              ></EditableLabel>
            </View>
            <View style={styles.rowStyle}>
              <Text style={styles.text}>Phone</Text>
              <EditableLabel
                style={styles.textinput}
                value={phoneNo}
                onChangeText={(e) => setPhoneNo(e)}
                onPress={() => {
                  updateProfile({ phoneNo: phoneNo });
                }}
              ></EditableLabel>
            </View>
            {user.userType === "PARTNER" ? (
              <></>
            ) : (
              <View style={styles.rowStyle}>
                <Text style={styles.text}>Address</Text>
                <EditableLabel
                  style={styles.textinput}
                  value={address}
                  onChangeText={(e) => setAddress(e)}
                  onPress={() => {
                    updateProfile({ address: address });
                  }}
                ></EditableLabel>
              </View>
            )}
            <View style={styles.rowStyle}>
              <Text style={styles.text2}>Gender</Text>
              <PopUp
                style={styles.popup}
                selectedItemStyle={{
                  fontSize: adjust(13),
                  marginBottom: 0,
                }}
                placeholder={user.gender ? user.gender : "Select Gender"}
                placeholderStyle={{
                  color: "#000",
                  fontSize: adjust(13),
                  marginBottom: 0,
                }}
                renderData={Gender.Gender}
                onSelection={handleGenderCallback}
              ></PopUp>
            </View>
            {user.userType == "PARTNER" ||
            user.userType == "CLIENT" ||
            user.userType == "EMPLOYEE" ||
            user.userType == "ACCOUNTANT" ? (
              <></>
            ) : (
              <View style={styles.rowStyle}>
                <Text style={styles.text2}>Level</Text>

                <PopUp
                  style={styles.popup}
                  selectedItemStyle={{
                    fontSize: adjust(13),
                    marginBottom: 0,
                  }}
                  placeholder={
                    user.levelOfWorkId ? user.levelOfWorkId : "Select Level"
                  }
                  placeholderStyle={{
                    color: "#000",
                    fontSize: adjust(13),
                    marginBottom: 0,
                  }}
                  renderData={Level.levelOfWorkId}
                  onSelection={handleLevelCallback}
                ></PopUp>
              </View>
            )}
            <View
              style={{
                flex: 1,
                // marginHorizontal: 30,
              }}
            >
              {user.userType == "PARTNER" ||
              user.userType == "CLIENT" ||
              user.userType == "EMPLOYEE" ||
              user.userType == "ONEDESIGN" ||
              user.userType == "ACCOUNTANT" ? (
                <></>
              ) : (
                <View
                  style={{
                    flex: 1,
                    bottom: 20,
                  }}
                >
                  <Skills
                    name="Skill"
                    options={skill ? skill : []}
                    setSelectedSkill={setSkillFromComponent}
                    removeOnpress={(i) => removeSkill(i)}
                  ></Skills>
                </View>
              )}
              {/* <View style={styles.rowStyle}>
              <Text style={styles.text}>Link</Text>
              <View style={{ flex: 3, flexDirection: "row" }}>
                <Links circleStyle={{ backgroundColor: "#2177D9" }}></Links>
                <Links circleStyle={{ backgroundColor: "#FF002A" }}></Links>
                <Links circleStyle={{ backgroundColor: "#FFBc00" }}></Links>
                <Links circleStyle={{ backgroundColor: "#FF00FF" }}></Links>
              </View>
            </View> */}
            </View>
            {["ACCOUNTANT", "CLIENT"].includes(user.userType) ? (
              <></>
            ) : (
              <View style={styles.rowStyle}>
                <Text style={styles.text}>Bank Account</Text>
                <EditableLabel
                  style={styles.textinput}
                  value={accountNumber}
                  onChangeText={(e) => setAccountNumber(e)}
                  onPress={() => {
                    updateProfile({ accountNumber: accountNumber });
                  }}
                ></EditableLabel>
              </View>
            )}
            {["ACCOUNTANT", "CLIENT"].includes(user.userType) ? (
              <></>
            ) : (
              <View style={styles.rowStyle}>
                <Text style={styles.text}>IFSC</Text>
                <EditableLabel
                  style={styles.textinput}
                  value={ifsc}
                  onChangeText={(e) => setIfsc(e)}
                  onPress={() => {
                    updateProfile({ ifsc: ifsc });
                  }}
                ></EditableLabel>
              </View>
            )}
          </View>
          <View>
            <View
              style={{
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <View style={{ flexDirection: "row", paddingHorizontal: 20 }}>
                <LargeButton
                  title="Change Password"
                  borderStyle={{ borderColor: "#0045FF" }}
                  pressedBorderStyle={{
                    borderColor: "#0045FF",
                    backgroundColor: "#0045FF",
                  }}
                  titleStyle={{ color: "#0045FF" }}
                  pressedTitleStyle={{ color: "#FFFFFF" }}
                  pressFunc={() => navigation.navigate("changePassword")}
                  style={{ marginLeft: 10, marginRight: 10 }}
                ></LargeButton>
              </View>
            </View>
            <View style={{ marginHorizontal: 10 }}>
              <LargeButton
                arrowColor={"#fff"}
                pressFunc={() => logout()}
                backgroundColor={"#000"}
                textColor={"#fff"}
                title={"Logout"}
              ></LargeButton>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, { updateProfile, logout })(
  EditYourProfile
);
const styles = StyleSheet.create({
  rowStyle: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 20,
    alignItems: "center",
    // alignItems: "flex-end",
  },
  title: {
    fontWeight: "bold",
    fontSize: adjust(25),
    marginLeft: 30,
  },
  image: {
    height: 120,
    width: 120,
    borderRadius: 120 / 2,
  },
  text: {
    flex: 0.9,
    fontWeight: "bold",
    fontSize: adjust(13),
  },
  textinput: {
    flex: 3,
    borderBottomWidth: 1,
    borderBottomColor: "#CECBCB",
    marginLeft: 10,
    // width: "100%",
  },
  popup: {
    width: "100%",
    minHeight: 20,
    maxHeight: 20,
    borderBottomColor: "#CECBCB",
  },
  text2: {
    flex: 0.3,
    fontWeight: "bold",
    fontSize: adjust(14),
    marginRight: 10,
  },
});
