import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  Image,
  FlatList,
  useWindowDimensions,
  Pressable,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../../components/header";
import RoundButton from "../../../components/roundButton";
import SelectItemWithCheckbox from "../../../components/selectItemWithCheckbox/selectItemWithCheckbox";
import LargeButton from "../../../components/largeButton";
import UserProfileComponent from "../../../components/userProfileComponent/userProfileComponent";
import { useFocusEffect } from "@react-navigation/native";
import { fetchPartners } from "../../../redux/actions/partner.admin.action";
import { createProject } from "../../../redux/actions/project.action";
import { fullName } from "../../../components/utils";
const SelectPartnerBySales = ({
  user,
  navigation,
  partnersByAdmin,
  fetchPartners,
  selectedProject,
  createProject,
}) => {
  useFocusEffect(
    React.useCallback(() => {
      fetchPartners();
    }, [])
  );

  const [selectedPartner, setSelectedPartner] = useState({});
  useEffect(() => {
    setSelectedPartner(selectedProject.partner);
  }, [selectedProject]);
  const choosePartner = (item) => {
    if (selectedPartner?._id === item._id) {
      setSelectedPartner({});
    } else {
      setSelectedPartner({ ...item });
    }
  };

  const updateProjectAndNavigate = () => {
    createProject({
      _id: selectedProject._id,
      partner: selectedPartner?._id,
      otp: Math.random().toString(10).substring(2, 8),
    });
    navigation.navigate("final-step");
  };
  const dimension = useWindowDimensions();
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new projects"
          subtitle="Please verify the client"
          enableAvatar={true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView style={{ height: "100%", width: "100%" }}>
        <Text style={styles.headerStyle}>
          select a partner to work with or let us choose
        </Text>
        <FlatList
          contentContainerStyle={{ marginLeft: 20 }}
          style={{
            maxHeight: dimension.height / 3,
            marginTop: 10,
            // marginHorizontal: 10,
          }}
          data={partnersByAdmin}
          keyExtractor={(item) => item._id}
          scrollEnabled
          nestedScrollEnabled
          horizontal
          renderItem={({ item }) => (
            <UserProfileComponent
              cardBackgroundColor={"#000"}
              description={"Partner, Social Media"}
              name={fullName(item)}
              // imageUri={item.image && item.mimType ? 'data'}
              onPress={() => choosePartner(item)}
              selected={item._id === selectedPartner?._id}
            ></UserProfileComponent>
          )}
        />
        <View
          style={{
            flexDirection: "row",
            margin: 15,
            marginVertical: 15,
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <Pressable
            style={[styles.pressableStyle]}
            onPress={() => updateProjectAndNavigate()}
          >
            <Text
              style={[
                {
                  color: "#FFFFFF",
                  fontSize: 15,
                  fontWeight: "bold",
                  alignSelf: "center",
                  justifyContent: "center",
                },
              ]}
            >
              {"Next"}
            </Text>
          </Pressable>

          <Pressable
            style={[styles.pressableStyle, { backgroundColor: "#EB4141" }]}
            onPress={() => {}}
          >
            <Text
              style={[
                {
                  color: "#FFFFFF",
                  fontSize: 15,
                  fontWeight: "bold",
                  alignSelf: "center",
                  justifyContent: "center",
                },
              ]}
            >
              {"Discard"}
            </Text>
          </Pressable>
        </View>
        <View style={styles.underline}></View>
        <Text style={styles.choiceStyle}>or, let ONEDESIGN make a choice</Text>
        <LargeButton
          arrowColor={"#FFF"}
          style={{ width: "90%" }}
          smallArrow={true}
          textColor={"#FFF"}
          backgroundColor={"#000"}
          title={"ONEDESIGN’s choice"}
          disableIcon={true}
          pressFunc={() => updateProjectAndNavigate()}
        ></LargeButton>

        {/* <View
          style={{
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <RoundButton
            title="Next"
            style={{ backgroundColor: "#000000", width: 100, marginLeft: 30 }}
            pressFunc={() => updateProjectAndNavigate()}
          ></RoundButton>
          <RoundButton
            title="Discard"
            style={{ backgroundColor: "#EB4141", width: 120, marginLeft: 15 }}
          ></RoundButton>
        </View> */}
      </ScrollView>
    </View>
  );
};

const mapStateToProps = ({ user, partnersByAdmin, selectedProject }) => ({
  user,
  partnersByAdmin,
  selectedProject,
});
export default connect(mapStateToProps, { fetchPartners, createProject })(
  SelectPartnerBySales
);

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    marginHorizontal: 30,
    //marginVertical: 20,
    color: "#000",
    marginVertical: 10,
  },

  underline: {
    borderBottomColor: "#707070",
    borderBottomWidth: 1,
    marginHorizontal: 15,

    //marginTop: 5,
  },
  choiceStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#828282",
    marginHorizontal: 30,
    marginVertical: 5,
    top: 5,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    marginHorizontal: 10,
    marginVertical: 10,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
});
