export const fetchWelcomeMessage = (userType) => (dispatch) => {
  return dispatch({
    type: "FETCH_WELCOME_MESSAGE",
    payload: {
      request: {
        url: `/api/welcome-message?${userType ? "userType=" + userType : ""}`,
        method: "GET",
      },
    },
  });
};

export const createWelcomeMessage = (Obj) => (dispatch) => {
  return dispatch({
    type: "CREATE_WELCOME_MESSAGE",
    payload: {
      request: {
        url: `/api/welcome-message`,
        method: "POST",
        data: Obj,
      },
    },
  });
};

export const removeWelcomeMessage = (id) => (dispatch) => {
  return dispatch({
    type: "REMOVE_WELCOME_MESSAGE",
    payload: {
      request: {
        url: `/api/welcome-message/${id}`,
        method: "DELETE",
      },
    },
  });
};
