import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  useWindowDimensions,
} from "react-native";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import UserProfileComponent from "../../../components/userProfileComponent/userProfileComponent";
import Header from "../../../components/header";
import { fetchAllCreator } from "../../../redux/actions/creator.action";
import RoundButton from "../../../components/roundButton";
import { primaryColor } from "../../../components/utils";

const CreatorScreen = ({ navigation, user, fetchAllCreator, creatorList }) => {
  useFocusEffect(
    React.useCallback(() => {
      fetchAllCreator();
    }, [])
  );

  const [currentTabDetails, setCurrentTabDetails] = useState({
    name: "Active",
  });
  let users = creatorList.filter((x) =>
    currentTabDetails?.name === "Active"
      ? ["ACCEPTED", "DASHBOARD"].includes(x.status)
      : currentTabDetails?.name === "Shortlisted"
      ? x.status === "SHORTLISTED"
      : ["REVIEWING", "INCOMPLETE"].includes(x.status)
  );
  const tabItems = [
    {
      name: "Active",
    },
    {
      name: "Shortlisted",
    },
    {
      name: "Pending",
    },
  ];
  const dimension = useWindowDimensions();
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: "18%",
          maxHeight: "18%",
        }}
      >
        <Header
          title={"creators"}
          subtitle="all your creators"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>
      <FlatList
        contentContainerStyle={{ paddingRight: 20 }}
        style={{ minHeight: 60, maxHeight: 60 }}
        scrollEnabled
        data={tabItems}
        keyExtractor={(item) => item.name}
        horizontal
        nestedScrollEnabled
        renderItem={({ item }) => (
          <RoundButton
            title={item.name}
            textStyle={{
              color: currentTabDetails.name === item.name ? "#FFF" : "#000",
            }}
            pressFunc={() => setCurrentTabDetails(item)}
            style={{
              backgroundColor:
                currentTabDetails.name === item.name
                  ? primaryColor(user.userType)
                  : "#FFF",
              borderColor: "#000",
              marginBottom: 20,
              marginLeft: 15,
              borderWidth: 1,
            }}
            buttonStyle={{
              minHeight: 40,
              maxHeight: 40,
            }}
          ></RoundButton>
        )}
      />
      {users?.length > 0 ? (
        <FlatList
          numColumns={2}
          scrollEnabled
          nestedScrollEnabled
          data={creatorList.filter((x) =>
            currentTabDetails.name === "Active"
              ? ["ACCEPTED", "DASHBOARD"].includes(x.status)
              : currentTabDetails.name === "Shortlisted"
              ? x.status === "SHORTLISTED"
              : ["REVIEWING", "INCOMPLETE"].includes(x.status)
          )}
          keyExtractor={(item) => item._id}
          style={{ height: "100%", flexWrap: "wrap" }}
          contentContainerStyle={styles.card_Container}
          renderItem={({ item }) => (
            <View style={{ marginTop: 5 }}>
              <UserProfileComponent
                onPress={() =>
                  navigation.navigate("creator-profile", {
                    creatorId: item._id,
                    disableLogout: true,
                    editCreator: true,
                    currentUser: user.userType,
                  })
                }
                backgroundColor={"#000"}
                imageUri={
                  item.image
                    ? { uri: "data:image/jpeg;base64," + item.image }
                    : null
                }
                name={`${item.fName} ${item.lName || ""}`}
                //   description={`${item.totalProject.toString() || 0} projects`}
              ></UserProfileComponent>
            </View>
          )}
        />
      ) : (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Text style={styles.textStyle}>No User</Text>
        </View>
      )}
    </View>
  );
};

const mapStateToProps = ({ user, creatorList }) => ({ user, creatorList });
export default connect(mapStateToProps, { fetchAllCreator })(CreatorScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  card_Container: {
    // flex: 2,
    flexDirection: "column",
    margin: 10,
  },
  textStyle: {
    alignSelf: "center",
    fontSize: 35,
    fontWeight: "bold",
    color: "#D0D0D0",
  },
});
