import { ScrollView, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import LargeButton from "../../../components/largeButton";
import { connect } from "react-redux";
import UserProfileComponent from "../../../components/userProfileComponent/userProfileComponent";
import { fetchOtherPartners } from "../../../redux/actions/partners.action";
import { fetchAllCreator } from "../../../redux/actions/creator.action";
import { fetchSalesPerson } from "../../../redux/actions/sales-person.action";

const EditUsersByAdmin = ({
  navigation,
  partners,
  route,
  salesPersons,
  creatorList,
  fetchOtherPartners,
  fetchAllCreator,
  fetchSalesPerson,
}) => {
  const [data, setData] = useState({});
  useEffect(() => {
    if (route.params?.data) {
      setData(JSON.parse(route.params.data));
    }
  }, [route.params]);
  const [renderData, setRenderData] = useState([]);
  useEffect(() => {
    if (data) {
      if (data.userType === "partner") {
        fetchOtherPartners();
      } else if (data.userType === "creator") {
        fetchAllCreator();
      } else if (data.userType === "sales people") {
        fetchSalesPerson();
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.userType === "partner") {
        setRenderData(partners);
      } else if (data.userType === "creator") {
        setRenderData(creatorList);
      } else if (data.userType === "sales people") {
        setRenderData(salesPersons);
      }
    }
  }, [salesPersons, creatorList, partners, data]);
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          enableAvatar={false}
          title={`edit ${data.userType}`}
          subtitle={`Add or Remove ${data.userType}`}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ height: 100, marginHorizontal: 10 }}>
        <LargeButton
          pressFunc={() =>
            navigation.navigate("addUserByAdmin", { data: route?.params?.data })
          }
          arrowColor={"#FFF"}
          textColor={"#FFF"}
          title={`Add a ${data.userType}`}
          backgroundColor={"#000"}
        ></LargeButton>
      </View>
      <ScrollView style={{ marginHorizontal: 10, height: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {renderData?.map((e) => (
            <UserProfileComponent
              color={"#000"}
              description={"Tap to remove"}
              name={`${e.fName} ${e.lName}`}
            ></UserProfileComponent>
          ))}
        </View>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ salesPersons, creatorList, partners }) => ({
  salesPersons,
  creatorList,
  partners,
});
export default connect(mapStateToProps, {
  fetchOtherPartners,
  fetchAllCreator,
  fetchSalesPerson,
})(EditUsersByAdmin);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: 150,
    maxHeight: 150,
  },
});
