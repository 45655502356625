import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import React, { useEffect } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import WelcomeMessageComponent from "../../../components/welcome_message_compoment/welcomeMessageComponent";
import DashboardCard from "../../../components/dashboardCard";
import ItemWithCountValue from "../../../components/itemWithCountValue/itemWithCountValue";
import adjust from "../../../components/adjust";
import { fetchAdminStats } from "../../../redux/actions/user.action";
import moment from "moment";
import { abbrNum } from "../../../components/utils";

const Dashboard = ({ user, navigation, userStats, fetchAdminStats }) => {
  useEffect(() => {
    fetchAdminStats();
  }, []);

  const dimension = useWindowDimensions();
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
          // backgroundColor: "red",
        }}
      >
        <Header
          disableProfile={true}
          title={`Welcome, ${user.fName}`}
          subtitle={"Let’s create greatness today!"}
          disableBackButton="true"
          style={{ backgroundColor: null, marginTop: 20 }}
          enableAvatar={false}
          navigation={navigation}
        ></Header>
      </View>
      <ScrollView
        style={{ maxHeight: 80, marginHorizontal: 5 }}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        <WelcomeMessageComponent
          navigation={navigation}
          enable={false}
          adminTrue={true}
          backgroundColor={"#F1C40F"}
          message={"tap to add Message to this to selection across the app"}
          textColor="#FFF"
        />
      </ScrollView>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 20 }}
        style={{ flex: 1, height: "100%", marginHorizontal: 10 }}
      >
        <DashboardCard
          jsx={
            <TouchableOpacity
              disabled
              onPress={() => navigation.navigate("myProjects")}
              style={{
                height: "100%",
                backgroundColor: "#2F47FF",
                borderRadius: 8,
              }}
            >
              <Text style={styles.cardTitleStyle}>{`${moment().format(
                "MMMM"
              )} Metrics`}</Text>
              <View
                style={{
                  flexDirection: "row",
                  bottom: 0,
                  position: "absolute",
                }}
              >
                <ItemWithCountValue
                  textColor={"#FFF"}
                  countValue={`${
                    userStats.onBoard.find((x) => x._id === "CLIENT")?.total ||
                    0
                  }`}
                  itemName={"New Clients"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  textColor={"#FFF"}
                  countValue={`${userStats.newProjects}`}
                  itemName={"New Projects"}
                ></ItemWithCountValue>
                <ItemWithCountValue
                  textColor={"#FFF"}
                  countValue={abbrNum(userStats.revenue, 0)}
                  itemName={"Net Revenue"}
                ></ItemWithCountValue>
              </View>
            </TouchableOpacity>
          }
          disableIcon={true}
          icon={false}
          style={{
            maxHeight: 150,
            borderWidth: 1,
            borderColor: "#073257",
            minHeight: 150,
          }}
        ></DashboardCard>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 10,
            marginTop: 10,
            flexWrap: "wrap",
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#31798E",
              maxHeight: dimension.width / 2 - 30,
              minHeight: dimension.width / 2 - 30,
              maxWidth: dimension.width / 2 - 30,
              minWidth: dimension.width / 2 - 30,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 20,
              justifyContent: "space-between",
            }}
            onPress={() => navigation.navigate("clientList")}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(16),
                marginLeft: 15,
                color: "#fff",
                marginTop: 10,
              }}
            >
              Clients
            </Text>
            <View style={{ marginBottom: 0 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: adjust(40),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                {userStats.onBoard.find((x) => x._id === "CLIENT")?.total || 0}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                New Client Onboard
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate("partners")}
            style={{
              backgroundColor: "#FF9A24",
              maxHeight: dimension.width / 2 - 30,
              minHeight: dimension.width / 2 - 30,
              maxWidth: dimension.width / 2 - 30,
              minWidth: dimension.width / 2 - 30,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 20,
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(16),
                marginLeft: 15,
                color: "#fff",
                marginTop: 10,
                alignSelf: "flex-start",
              }}
            >
              Partners
            </Text>
            <View style={{ marginBottom: 0 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: adjust(40),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                {userStats.onBoard.find((x) => x._id === "PARTNER")?.total || 0}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                Partners Onboard
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate("creators")}
            style={{
              backgroundColor: "#7A95C1",
              maxHeight: dimension.width / 2 - 30,
              minHeight: dimension.width / 2 - 30,
              maxWidth: dimension.width / 2 - 30,
              minWidth: dimension.width / 2 - 30,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 20,
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(16),
                marginLeft: 15,
                color: "#fff",
                marginTop: 10,
              }}
            >
              Creators
            </Text>
            <View style={{ marginBottom: 0 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: adjust(40),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                {userStats.onBoard.find((x) => x._id === "CREATOR")?.total || 0}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                Creators in the pool
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate("sales-person")}
            style={{
              backgroundColor: "#F27253",
              maxHeight: dimension.width / 2 - 30,
              minHeight: dimension.width / 2 - 30,
              maxWidth: dimension.width / 2 - 30,
              minWidth: dimension.width / 2 - 30,
              borderRadius: 10,
              flexDirection: "column",
              marginBottom: 20,
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: adjust(16),
                marginLeft: 15,
                color: "#fff",
                marginTop: 10,
                alignSelf: "flex-start",
              }}
            >
              Sales
            </Text>
            <View style={{ marginBottom: 0 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: adjust(40),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                {userStats.onBoard.find((x) => x._id === "SALES")?.total || 0}
              </Text>
              <Text
                style={{
                  fontWeight: "700",
                  fontSize: adjust(14),
                  marginLeft: 15,
                  color: "#fff",
                  marginBottom: 10,
                }}
              >
                Sales in the pool
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
};
const mapStateToProps = ({ user, userStats }) => ({
  user,
  userStats,
});
export default connect(mapStateToProps, { fetchAdminStats })(Dashboard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  cardTitleStyle: {
    alignSelf: "flex-start",
    margin: 10,
    fontSize: 16,
    color: "#FFF",
    fontWeight: "bold",
  },
});
