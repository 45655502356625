import React, { useState } from "react";
import {
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Icon from "../icon";
import * as DocumentPicker from "expo-document-picker";

const getExt = (fileName) => {
  const fileParts = fileName.split(".");
  return fileParts[fileParts.length - 1];
};

function fileType(filename) {
  var parts = filename.split(".");
  let ext = parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
      return "image";
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
      return "video";
    default:
      return "other";
  }
}

const HelpBox = ({ onSend, uploadHelpMedia }) => {
  const [msg, setMsg] = useState("");

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    return splitDataURI.length > 1
      ? b64toBlob(splitDataURI[1])
      : b64toBlob(dataURI);
  }

  const selectFile = async () => {
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: "*/*",
      }).then(async (result) => {
        return {
          name: result.name,
          base64: result.uri,
        };
      });
      let formData = new FormData();
      formData.append(
        "files",
        Platform.OS == "web"
          ? DataURIToBlob(res.base64)
          : {
              uri: res.base64,
              type: `application/${getExt(res.name)}`,
              name: res.name,
            },
        res.name
      );

      formData.append("type", fileType(res.name));
      uploadHelpMedia(formData);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <View
      style={{
        backgroundColor: "#FFFFFF",
        flexDirection: "row",
        zIndex: 5,
        marginBottom: 10,
        // bottom: 5,
        width: "100%",
        paddingHorizontal: 10,
        // maxHeight: 70,
        // minHeight: 80,
        // position: "absolute",
        // marginBottom: 30,
        // paddingVertical: 10,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          justifyContent: "flex-start",
          borderRadius: 15,
          marginLeft: 5,
          width: "80%",
          alignSelf: "center",
          marginBottom: 5,
          bottom: Platform.OS === "android" ? 3 : 0,
        }}
      >
        <TextInput
          placeholder="Type Your Message"
          onChangeText={(m) => setMsg(m)}
          value={msg}
          multiline={true}
          style={{
            paddingVertical: 10,
            paddingHorizontal: 15,
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 50,
            minHeight: 40,
            maxHeight: 130,
          }}
        ></TextInput>
      </View>
      <TouchableOpacity
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: 5,
          width: 30,
          height: Platform.OS === "android" ? 30 : null,
          marginTop: Platform.OS === "android" ? -5 : null,
        }}
        onPress={selectFile}
      >
        <Icon name="gallery" fill={"#bdbdbd"}></Icon>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          if (msg && msg != "") {
            onSend(msg);
            setMsg("");
          }
        }}
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: 5,
          marginRight: 5,
          width: 30,
          height: Platform.OS === "android" ? 30 : null,
          marginTop: Platform.OS === "android" ? -10 : null,
        }}
      >
        <Icon name="rightChevronArrowWithCircle"></Icon>
      </TouchableOpacity>
    </View>
  );
};

export default HelpBox;
