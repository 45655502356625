import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Header from "../../components/header";
import { connect } from "react-redux";
import UserProfileComponent from "../../components/userProfileComponent/userProfileComponent";
import { useFocusEffect } from "@react-navigation/native";
import { fetchClients } from "../../redux/actions/user.action";

const ClientList = ({ navigation, user, fetchClients, clientList }) => {
  useFocusEffect(
    React.useCallback(() => {
      fetchClients();
    }, [])
  );
  const dimension = useWindowDimensions();
  return (
    <View style={styles.container}>
      <View
        style={{
          minHeight: "18%",
          maxHeight: "18%",
        }}
      >
        <Header
          enableAvatar={user.userType === "ONEDESIGN" ? false : true}
          title={user.userType === "PARTNER" ? "my clients" : "clients"}
          subtitle="All Your Active Clients"
          backFunction={() => navigation.goBack()}
          disableProfile={true}
          navigation={navigation}
        ></Header>
      </View>

      <FlatList
        numColumns={2}
        scrollEnabled
        nestedScrollEnabled
        data={clientList}
        keyExtractor={(item) => item._id}
        style={{ height: "100%", flexWrap: "wrap" }}
        contentContainerStyle={styles.card_Container}
        renderItem={({ item }) => (
          <View style={{ marginTop: 5 }}>
            <UserProfileComponent
              onPress={() =>
                navigation.navigate("project-list", {
                  type: "client",
                  _id: item._id,
                })
              }
              backgroundColor={"#000"}
              imageUri={
                item.image
                  ? { uri: "data:image/jpeg;base64," + item.image }
                  : null
              }
              name={`${item.fName} ${item.lName || ""}`}
              description={`${item.totalProject.toString() || 0} projects`}
            ></UserProfileComponent>
          </View>
        )}
      />
    </View>
  );
};

const mapStateToProps = ({ user, clientList }) => ({ user, clientList });
export default connect(mapStateToProps, { fetchClients })(ClientList);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  card_Container: {
    // flex: 2,
    flexDirection: "column",
    margin: 10,
    paddingBottom: 50,
  },
});
