import { StyleSheet, Text, View } from "react-native";
import React from "react";
import FontOutlineWithShadow from "../fontOutlineWithShadow/fontOutlineWithShadow";

const SignUpEndPhase = ({ textcolor }) => {
  return (
    <View style={styles.container}>
      <FontOutlineWithShadow stroke={1} color={textcolor}>
        <Text style={styles.fontOutlineStyle}>you are good to go!</Text>
      </FontOutlineWithShadow>
      <Text style={styles.textStyle}>
        we have recorded what’s essential. you can check your profile section
        later to update more details.
      </Text>
    </View>
  );
};

export default SignUpEndPhase;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    // paddingVertical: 10,
  },
  textStyle: {
    color: "#000",
    fontSize: 25,
    fontWeight: "bold",
    margin: 20,
  },
  fontOutlineStyle: {
    fontSize: 110,
    color: "#FFF",
    marginLeft: 20,
    lineHeight: 90,
    paddingTop: 10,
  },
});
