import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Dashboard from "../../screens/admin/screens/dashboard";

import { Dimensions } from "react-native";
import NotificationDrawer from "../../notificationDrawer";
import { createDrawerNavigator } from "@react-navigation/drawer";

import login from "../../screens/login";
import userProfileList from "../../screens/common/client-list";
import PartnerScreen from "../../screens/admin/screens/partner-screen";
import SalesPersonScreen from "../../screens/admin/screens/sales-person-screen";
import CreatorScreen from "../../screens/admin/screens/creator-screen";
import PerformanceScreen from "../../screens/admin/screens/performanceScreen";
import ProjectScreen from "../../screens/admin/screens/project-screen";
import editYourProfile from "../../screens/editYourProfile";
import ActiveProjectDetails from "../../screens/common/activeProjectDetails";
import CreatorProfile from "../../screens/admin/screens/creator-profile-screen";
import welcomeMessageforAdmin from "../../screens/admin/screens/welcomeMessageforAdmin";
import chatWithUs from "../../screens/common/chat-with-us";
import welcomeMessageScreen from "../../screens/admin/screens/welcomeMessageScreen";

const window = Dimensions.get("window");

const AdminStack = createStackNavigator();
const Drawer = createDrawerNavigator();
export default ({ selectedProject, notifications }) => {
  return (
    <Drawer.Navigator
      drawerPosition="right"
      drawerContentOptions={{
        activeTintColor: "#e91e63",
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <NotificationDrawer {...props} />}
    >
      <Drawer.Screen
        name="admin"
        component={StackNavigator}
        options={{
          headerShown: false,
          swipeEnabled: true,
        }}
      />
    </Drawer.Navigator>
  );
};

const StackNavigator = () => {
  return (
    <AdminStack.Navigator name="admin" initialRouteName={"dashboard"}>
      <AdminStack.Screen
        name="dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="clientList"
        component={userProfileList}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="sales-person"
        component={SalesPersonScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="creators"
        component={CreatorScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="partners"
        component={PartnerScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="performance"
        component={PerformanceScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="project-list"
        component={ProjectScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="activeProjectDetails"
        component={ActiveProjectDetails}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="editYourProfile"
        component={editYourProfile}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="creator-profile"
        component={CreatorProfile}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="welcomeMessageScreen"
        component={welcomeMessageScreen}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="welcomeMessageforAdmin"
        component={welcomeMessageforAdmin}
        options={{ headerShown: false }}
      />
      <AdminStack.Screen
        name="chat-with-us"
        component={chatWithUs}
        options={{ headerShown: false }}
      />
    </AdminStack.Navigator>
  );
};
