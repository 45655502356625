export const createSalesPromotionEntry = (obj, navigate) => (dispatch) => {
  return dispatch({
    type: "CREATE_SALES_PROMOTION_ENTRY",
    navigate: navigate,
    payload: {
      request: {
        url: `/api/sales-promotion`,
        method: "POST",
        data: obj,
      },
    },
  });
};

export const fetchSalesPromotionEntry = () => (dispatch) => {
  return dispatch({
    type: "FETCH_SALES_PROMOTION_ENTRY",
    payload: {
      request: {
        url: `/api/sales-promotion`,
        method: "GET",
      },
    },
  });
};
