import React from "react";
import { TouchableOpacity, Text, View, StyleSheet } from "react-native";
import Icon from "../components/icon";
import Avatar from "../components/avatar";
import adjust from "./adjust";

export default function Header({
  backFunction,
  title,
  projectId,
  budget,
  duration,
  disableBackButton,
  style,
  titleStyle,
  titleContainerStyle,
  tags,
  dateTime,
  client,
}) {
  return (
    <View style={[styles.container, style]}>
      {disableBackButton ? (
        <></>
      ) : (
        <TouchableOpacity
          style={{
            marginTop: 40,
            flexDirection: "row",
            alignItems: "center",
            zIndex: 999,
            marginLeft: 30,
            marginBottom: 20,
          }}
          onPress={backFunction}
        >
          <Icon name="back" fill="#ffffff" />
        </TouchableOpacity>
      )}
      <View style={[{}, titleContainerStyle]}>
        <Text style={[styles.title, titleStyle]}>{title}</Text>
        <View style={{ marginBottom: 20 }}>
          <Text style={styles.dateTime}>Posted: {dateTime}</Text>
          {projectId ? (
            <Text style={styles.projectId}>Project ID: {projectId}</Text>
          ) : (
            <></>
          )}
        </View>
        {budget ? (
          <View style={{ marginBottom: 10 }}>
            <Text style={styles.budget}>
              Budget: {Array.isArray(budget) ? budget.join(" to ") : budget}
            </Text>
            <Text style={styles.duration}>
              Deadline: {new Date(duration).toDateString()}
            </Text>
          </View>
        ) : (
          <></>
        )}
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            paddingLeft: 30,
            paddingBottom: 20,
          }}
        >
          {tags ? (
            tags.map((item, index) => (
              <View
                key={`project-${index}`}
                style={{
                  borderRadius: 5,
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <Text style={styles.tagText}>{item.name}</Text>
              </View>
            ))
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#000000",
    // maxHeight: 370,
  },
  title: {
    fontWeight: "bold",
    fontSize: adjust(28),
    marginLeft: 30,
    marginRight: 40,
    flexWrap: "wrap",
    color: "#ffffff",
  },
  partner: {
    color: "#ffffff",
    fontSize: adjust(11),
    marginLeft: 30,
    marginVertical: 10,
    marginBottom: 20,
  },
  projectId: {
    color: "#606060",
    fontSize: adjust(15),
    marginLeft: 30,
    marginTop: 10,
  },
  dateTime: {
    color: "#ffffff",
    fontSize: adjust(15),
    marginLeft: 30,
    marginTop: 10,
  },
  budget: {
    color: "#ffffff",
    fontSize: adjust(18),
    marginLeft: 30,
    marginTop: 10,
    fontWeight: "bold",
  },
  duration: {
    color: "#ffffff",
    fontSize: adjust(11),
    marginLeft: 30,
    marginTop: 10,
  },
  icon: { position: "absolute", right: 30, top: 85 },
  tagText: {
    color: "#ffffff",
    fontSize: adjust(11),
    textTransform: "capitalize",
    backgroundColor: "#707070",
    borderRadius: 5,
    padding: 5,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});
