import { View, StyleSheet, Pressable, Text, FlatList } from "react-native";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import Header from "../../../components/header";

import SelectItemWithCheckbox from "../../../components/selectItemWithCheckbox/selectItemWithCheckbox";
import { fetchProjectSkill } from "../../../redux/actions/projectSkill.action";
import { updateProfile } from "../../../redux/actions/user.action";

const CreatorExperienceScreen = ({
  navigation,
  skills,
  user,
  updateProfile,
  fetchProjectSkill,
}) => {
  const [jobRoles, setJobRoles] = useState([]);
  useEffect(() => {
    fetchProjectSkill();
  }, []);

  const addToAppliedFor = (data) => {
    if (jobRoles.find((x) => x._id === data._id)) {
      setJobRoles([...jobRoles.filter((x) => x._id !== data._id)]);
    } else {
      setJobRoles([...jobRoles, data]);
    }
  };

  const next = () => {
    updateProfile(
      { skill: jobRoles.map((x) => x._id), email: user.email },
      navigateAfterSuccess
    );
  };

  const navigateAfterSuccess = () => {
    navigation.navigate("creatorWorklinkScreen");
  };

  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="Profile Details"
          subtitle="Tell us more aboute Your Intersts"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#FF973E" }}
        ></Header>
      </View>
      <View style={{ marginVertical: 5 }}>
        <Text style={styles.headerStyle}>
          tell us something about your experience
        </Text>
      </View>
      <FlatList
        data={skills}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => (
          // <View style={styles.marginView}>
          <SelectItemWithCheckbox
            borderWidth={1}
            checked={jobRoles.find((x) => x._id === item._id) ? true : false}
            data={{
              title: item.name,
              about: "only if you are super confident, tap on this.",
            }}
            onPress={() => addToAppliedFor(item)}
          />
          // </View>
        )}
      />
      <View>
        <Pressable style={[styles.pressableStyle]} onPress={() => next()}>
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 15,
                fontWeight: "bold",
                alignSelf: "center",
                justifyContent: "center",
              },
            ]}
          >
            {"Next"}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#FFF" },
  buttonStyle: {
    backgroundColor: "#000",
    height: 70,
    marginVertical: 5,
  },
  pressableStyle: {
    borderRadius: 25,
    height: 50,
    margin: 20,
    backgroundColor: "#000000",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: 120,
  },
  marginView: {
    marginTop: 10,
    marginHorizontal: 10,
  },
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    margin: 25,
    color: "#000",
  },
});
const mapStateToProps = ({ user, skills }) => ({ user, skills });
export default connect(mapStateToProps, { fetchProjectSkill, updateProfile })(
  CreatorExperienceScreen
);
