import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform,
  useWindowDimensions,
} from "react-native";
import adjust from "../adjust";
import Checkbox from "../checkbox";
import { primaryColor } from "../utils";

const SelectItemWithCheckbox = ({
  data,
  content,
  onPress,
  checked,
  image,
  padding,
  borderWidth,
  borderDesign,
  margin,
  backgroundColor,
  borderColor,
  titleColor,
  descriptionColor,
  userType,
  disable,
  fill,
  style,
  hideDescription,
}) => {
  const dimension = useWindowDimensions();
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          padding: padding,
          borderWidth: borderWidth,
          borderColor: borderColor
            ? borderColor
            : borderDesign
            ? "#000"
            : "gray",
          borderRadius: 10,
          flexDirection: "row",
          justifyContent: "space-between",
          margin: margin,
          minHeight: 80,
          padding: 5,
          backgroundColor: backgroundColor
            ? backgroundColor
            : borderDesign
            ? "#FFF"
            : "#FAFAFA",
          width: dimension.width - 25,
          marginHorizontal: 15,
          marginVertical: 5,
        },
        style,
      ]}
    >
      {image ? (
        <Image
          style={{
            alignSelf: "center",
            height: 60,
            width: 60,
            borderRadius: 100,
            borderWidth: 1,
            borderColor: "#FFD500",
          }}
          source={
            data?.image
              ? {
                  uri:
                    Platform.OS === "web"
                      ? data.image
                      : "data:image/jpeg;base64," + data.image,
                }
              : require("../../assets/avatar/avatar.png")
          }
        ></Image>
      ) : (
        <></>
      )}
      <View style={{ justifyContent: "center" }}>
        {content ? (
          content
        ) : (
          <View style={{ justifyContent: "center", alignSelf: "center" }}>
            <Text
              style={{
                fontSize: adjust(18),
                fontWeight: "bold",
                color: titleColor
                  ? titleColor
                  : borderDesign
                  ? "#41B000"
                  : "#000",
                marginLeft: 10,
                // alignSelf: "center",
              }}
            >
              {data?.name ? data.name : data?.title}
            </Text>
            {hideDescription ? (
              <></>
            ) : (
              <Text
                allowFontScaling={true}
                style={{
                  fontSize: 11,
                  fontWeight: "bold",
                  color: descriptionColor ? descriptionColor : "#B1B1B1",
                  marginLeft: 10,
                  marginTop: 7,
                  maxWidth: dimension.width - 100,
                  flexWrap: "wrap",
                }}
              >
                {data?.email ||
                  data?.shortDescription ||
                  data?.description ||
                  data?.about}
              </Text>
            )}
          </View>
        )}
      </View>
      <View
        style={{
          justifyContent: "center",
          marginLeft: image ? -31 : 0,
          // marginRight: 5,
          minWidth: 50,
        }}
      >
        <Checkbox
          fill={fill || primaryColor(userType)}
          style={{
            borderColor: "#eaeaea",
          }}
          disable={disable}
          value={checked}
        ></Checkbox>
      </View>
    </TouchableOpacity>
  );
};

export default SelectItemWithCheckbox;
