export const createHelp = (body) => (dispatch) => {
    return dispatch({
        type: "CREATE_HELP",
        payload: {
            request: {
                url: `/api/help`,
                method: "POST",
                data: body,
            },
        },
    });
};

export const uploadHelpMedia = (post) => (dispatch) => {
    return dispatch({
        type: "CREATE_HELP",
        payload: {
            request: {
                url: "/api/help/media",
                method: "POST",
                data: post,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            },
        },
    });
};

export const fetchHelp = (from) => (dispatch) => {
    return dispatch({
        type: "FETCH_HELP",
        payload: {
            request: {
                url: `/api/help?from=${from}`,
                method: "GET",
            },
        },
    });
};

export const clearHelpMessages = () => (dispatch) => {
    return dispatch({
        type: "CLEAR_HELP_MESSAGES",
    });
};

export const fetchHelpListForAdmin = () => (dispatch) => {
    return dispatch({
        type: "FETCH_HELP_BY_ADMIN",
        payload: {
            request: {
                url: `/api/help/admin`,
                method: "GET",
            },
        },
    });
};