import { StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { connect } from "react-redux";
import RoundButton from "../../../components/roundButton";
import ContainerWithDifferentBg from "../../../components/projectTitleContainerWithDifferentBg/projectTitleContainerWithDifferentBg";
import ProjectTitleComponent from "../../../components/projectTitleComponent/projectTitleComponent";
import moment from "moment";
import { createProject } from "../../../redux/actions/project.action";
import { updateMeeting } from "../../../redux/actions/meeting.action";
import { fullName } from "../../../components/utils";
import { addError } from "../../../redux/actions/toast.action";
const ScheduleDetails = ({
  user,
  navigation,
  selectedProject,
  route,
  createProject,
  meetings,
  updateMeeting,
  addError,
}) => {
  const [timeDiff, setTimeDiff] = useState();
  const [meetingCompleted, setMeetingCompleted] = useState();
  const [meeting, setMeeting] = useState({});

  useEffect(() => {
    if (route.params) {
      if (route.params.meeting) {
        let meet = meetings.find((x) => x._id === route.params.meeting);
        setMeeting(meet);
        setTimeDiff(moment(meet.time).diff(moment(), "minute", false));
        setMeetingCompleted(meet.completed);
      } else if (
        route.params.type === "initial" &&
        selectedProject &&
        selectedProject.initialCall
      ) {
        setMeetingCompleted(selectedProject.initialCall.completed);

        setTimeDiff(
          moment(selectedProject.initialCall.time).diff(
            moment(),
            "minute",
            false
          )
        );
      }
    }
  }, [selectedProject, route.params]);

  const updateProjectAndNavigate = () => {
    if (route.params && route.params.type === "initial") {
      if (selectedProject.partner) {
        createProject(
          {
            _id: selectedProject._id,
            clientCallReceived: true,
          },
          () => navigation.navigate("Project", { screen: "myProjects" })
        );
      } else {
        addError(
          "You cannot start a meeting untill a partner is not assigned to the project!",
          3000
        );
      }
    } else if (route.params && route.params.meeting) {
      updateMeeting({ meeting: meeting._id }, () => navigation.goBack());
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerStyle}>
        <Header
          title="meeting"
          subtitle="your meeting is scheduled below"
          enableAvatar={user.userType === "ONEDESIGN" ? false : true}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
        ></Header>
      </View>
      <View style={{ flex: 1, marginHorizontal: 15 }}>
        <ContainerWithDifferentBg
          titleFontSize={20}
          content={
            route.params?.type == "initial"
              ? selectedProject.title
              : meeting.name
          }
          backgroundColor={"#000"}
        ></ContainerWithDifferentBg>
        <ProjectTitleComponent
          backgroundColor="#FFF"
          jsx={
            meeting.users?.length > 0 ? (
              <View>
                {meeting.users.map((x) => (
                  <Text
                    key={x._id + "meeting-details"}
                    style={styles.detailsStyle}
                  >
                    {`${x.userType}: ${fullName(x)}`}
                  </Text>
                ))}
                <Text style={styles.detailsStyle}>{`Time : ${moment(
                  meeting.time
                ).calendar()}`}</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.detailsStyle}>
                  {`Partner: ${
                    selectedProject.partner
                      ? fullName(selectedProject.partner)
                      : "Not assigned yet!"
                  }`}
                </Text>
                <Text style={styles.detailsStyle}>{`Client: ${fullName(
                  selectedProject?.user
                )}`}</Text>
                {selectedProject.initialCall ? (
                  <Text style={styles.detailsStyle}>{`Time : ${moment(
                    selectedProject.initialCall.time
                  ).calendar()}`}</Text>
                ) : (
                  <></>
                )}
              </View>
            )
          }
        ></ProjectTitleComponent>
        <View
          style={{ flexDirection: "row", marginVertical: 20, marginLeft: 5 }}
        >
          {meetingCompleted ? (
            <RoundButton
              title={`Meeting Done`}
              style={{
                alignSelf: "center",
                backgroundColor: "#7A9D54",
                marginBottom: 10,
                padding: 10,
              }}
            ></RoundButton>
          ) : timeDiff > 0 ? (
            <RoundButton
              title={`Starts in ${timeDiff} minutes`}
              style={{
                alignSelf: "center",
                backgroundColor: "#B7B7B7",
                marginBottom: 10,
                padding: 10,
              }}
            ></RoundButton>
          ) : (
            <RoundButton
              pressFunc={() => updateProjectAndNavigate()}
              title={"Start Meeting"}
              style={{
                alignSelf: "center",
                backgroundColor: "#000",
                marginBottom: 10,
                padding: 10,
              }}
            ></RoundButton>
          )}
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            marginHorizontal: 15,
            marginVertical: 15,
          }}
        >
          {meetingCompleted ? (
            <></>
          ) : (
            <RoundButton
              title="Discard"
              style={{
                alignSelf: "center",
                backgroundColor: "#EB4141",
                marginBottom: 10,
              }}
            ></RoundButton>
          )}
        </View>
      </View>
    </View>
  );
};
const mapStateToProps = ({ user, selectedProject, meetings }) => ({
  user,
  selectedProject,
  meetings,
});
export default connect(mapStateToProps, {
  createProject,
  updateMeeting,
  addError,
})(ScheduleDetails);

const styles = StyleSheet.create({
  headerStyle: {
    minHeight: "18%",
    maxHeight: "18%",
  },
  detailsStyle: {
    fontSize: 13,
    color: "#000",
  },
});
