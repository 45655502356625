import React, { useContext } from "react";

import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import Icon from "../components/icon";
import { connect } from "react-redux";
import { primaryColor } from "../components/utils";
import Avatar from "../components/avatar";
import { RouteContext } from "../components/routeContext";

const TabNavigationDesign = ({
  state,
  descriptors,
  navigation,
  user,
  activeColor = primaryColor(user.userType),
}) => {
  const route = useContext(RouteContext);

  return route && ["chat", "chat-with-us"].includes(route) ? (
    <></>
  ) : (
    <View
      style={{
        backgroundColor:
          descriptors[state.routes[state.index].key].options.backgroundColor ||
          "#ffffff",
        // shadowColor: "#000",
        // shadowOffset: { width: 0, height: 0 },
        // shadowOpacity: 0.5,
        shadowRadius: 15,
        // elevation: 50,
      }}
    >
      <View style={styles.tabNavigationCss}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;
          const icon = options.icon;
          const image = options.image;
          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: "tabPress",
              target: route.key,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: "tabLongPress",
              target: route.key,
            });
          };

          return (
            <View key={`menu-${label}`}>
              <TouchableOpacity
                accessibilityRole="button"
                accessibilityStates={isFocused ? ["selected"] : []}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                onLongPress={onLongPress}
              >
                <View
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <View style={{}}>
                    {image ? (
                      <Avatar
                        source={
                          user.mimType && user.image
                            ? {
                                uri: `data:${user.mimType};base64,${user.image}`,
                              }
                            : null
                        }
                        style={styles.headerAvatar}
                      ></Avatar>
                    ) : (
                      <Icon
                        fill={isFocused ? activeColor : "#A9A9A9"}
                        name={icon}
                      ></Icon>
                    )}
                  </View>
                </View>
                <View style={{ marginTop: 3 }}>
                  <Text
                    style={{
                      color: isFocused ? activeColor : "#A9A9A9",
                      alignSelf: "center",
                      fontSize: 12,
                    }}
                  >
                    {label.trim()}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
    </View>
  );
};
const mapStateToProps = ({ user }) => ({ user });
const styles = StyleSheet.create({
  tabNavigationCss: {
    backgroundColor: "#fff",
    flexDirection: "row",
    borderBottomWidth: 0,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderTopWidth: 1,
    borderColor: "#FAFAFA",
    height: 65,
    justifyContent: "space-evenly",
    alignItems: "center",
    borderBottomColor: "transparent",
    // shadowColor: "#000",
    // shadowOffset: { width: 0, height: 0 },
    // shadowOpacity: 0.5,
    // shadowRadius: 15,
    // elevation: 50,
  },
  headerAvatar: {
    borderWidth: 1.5,
    height: 29,
    width: 29,
    marginBottom: 3,
  },
});

export default connect(mapStateToProps)(TabNavigationDesign);
