import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
  FlatList,
} from "react-native";
import React, { useState } from "react";
import Header from "../../components/header";
import RoundButton from "../../components/roundButton";
import SelectItemWithCheckbox from "../../components/selectItemWithCheckbox/selectItemWithCheckbox";

const SalesBudgetScreen = ({ navigation }) => {
  const data = [
    { title: "no idea on the Budget", description: "hjgcusgfcu", _id: 1 },
    { title: "no idea on the Budget", description: "hjgcusgfcu", _id: 2 },
    { title: "no idea on the Budget", description: "hjgcusgfcu", _id: 3 },
    { title: "no idea on the Budget", description: "hjgcusgfcu", _id: 4 },
  ];
  const dimension = useWindowDimensions();
  const [list, setList] = useState([]);
  const setItem = (data) => {
    let index = list.findIndex((x) => x._id === data._id);
    if (index === -1) {
      setList([...list, data]);
    } else {
      setList(list.filter((x) => x._id !== data._id));
    }
  };
  return (
    <View style={styles.container}>
      <View style={{ minHeight: "18%", maxHeight: "18%" }}>
        <Header
          title="new Sales"
          subtitle="Please identify the client"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#FFF" }}
          titleStyle={{ color: "#000000" }}
        ></Header>
      </View>
      <Text style={styles.headerStyle}>
        give a Basic idea about the Client's Budget
      </Text>
      {/* <FlatList
        nestedScrollEnabled={true}
        scrollEnabled={true}
        contentContainerStyle={{
          marginHorizontal: 20,
          maxHeight: dimension.height / 2,
          marginTop: 20,
        }}
        data={data}
        keyExtractor={(item, index) => "service" + index}
        renderItem={({ item, index }) => (
          <View style={{ marginTop: 5 }}>
            <SelectItemWithCheckbox
              onPress={() => setChecked(!chekced)}
              borderWidth={1}
              data={item}
              checked={chekced}
              borderColor={chekced ? "#FF973E" : "#E5E5E5"}
            />
          </View>
        )}
      ></FlatList> */}
      <ScrollView
        style={{
          marginHorizontal: 20,
          maxHeight: dimension.height / 1.9,
          marginTop: 20,
        }}
      >
        {data.map((item) => (
          <View style={{ marginTop: 5 }}>
            <SelectItemWithCheckbox
              disable={true}
              onPress={() => setItem(item)}
              borderWidth={1}
              data={item}
              checked={list.findIndex((x) => x._id === item._id) !== -1}
              // borderColor={list.findIndex((x) =>
              //   x._id === item._id ? "#FF973E" : "#E5E5E5"
              // )}
            />
          </View>
        ))}
      </ScrollView>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <RoundButton
          style={{ backgroundColor: "#000000", width: 100, marginLeft: 30 }}
          title={"Next"}
          pressFunc={() => navigation.navigate("PartnerSelection")}
        ></RoundButton>
        <RoundButton
          style={{ backgroundColor: "#EB4141", width: 120, marginLeft: 15 }}
          title={"Discard"}
        ></RoundButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headerStyle: {
    fontSize: 25,
    fontWeight: "bold",
    marginHorizontal: 30,
    marginTop: 20,
    color: "#000",
  },
});
export default SalesBudgetScreen;
