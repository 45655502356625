import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Header from "../header";
import ItemWithCountValue from "../itemWithCountValue/itemWithCountValue";
import RoundButton from "../roundButton";
const CreatorEarningCard = ({ data }) => {
  return (
    <View style={styles.cardStyle}>
      <View>
        <Text style={styles.titleStyle}>{data.project.title}</Text>
        <Text style={styles.percentStyle}>
          {data.project.completionPercent}% Complete
        </Text>
        <View style={styles.underline}></View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ItemWithCountValue
            textColor={"#000"}
            countStyle={styles.countStyle}
            itemName={"Earning"}
            countValue={data.earning?.reduce((a, b) => a + b.amount, 0)}
          ></ItemWithCountValue>
          <ItemWithCountValue
            textColor={"#000"}
            countStyle={styles.countStyle}
            itemName={"Received"}
            countValue={data.received?.reduce((a, b) => a + b.amount, 0)}
          ></ItemWithCountValue>
          <ItemWithCountValue
            textColor={"#000"}
            countStyle={styles.countStyle}
            itemName={"Balance"}
            countValue={data.balance?.reduce((a, b) => a + b.amount, 0)}
          ></ItemWithCountValue>
        </View>
      </View>
    </View>
  );
};

export default CreatorEarningCard;

const styles = StyleSheet.create({
  cardStyle: {
    borderWidth: 2,
    borderColor: "#F1F1F1",
    padding: 10,
    borderRadius: 8,
    marginHorizontal: 10,
    marginTop: 20,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#000",
    marginLeft: 10,
  },
  percentStyle: {
    color: "#7A9C54",
    fontSize: 18,
    fontWeight: "bold",
    margin: 10,
  },
  countStyle: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#000",
  },
  underline: {
    borderBottomWidth: 1,
    borderBottomColor: "#707070",
    marginHorizontal: 10,
  },
});
