const initialState = [];
const partners = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_OTHER_PARTNERS_SUCCESS": {
      return action.payload.data;
    }
    case "FETCH_OTHER_PARTNERS_FAIL": {
      return state;
    }

    default: {
      return state;
    }
  }
};
export default partners;
