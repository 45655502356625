import { addError, addInfo, addWarning } from "../actions/toast.action";

const initialState = [];
const creatorList = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_CREATOR_SUCCESS": {
      return action.payload.data;
    }

    case "FETCH_ALL_CREATOR_FAIL": {
      return state;
    }

    case "APPROVE_CREATOR_ACTIVE_SUCCESS": {
      return [...state, action.payload.data];
    }
    case "APPROVE_CREATOR_PENDING_SUCCESS": {
      return [...state, action.payload.data];
    }
    case "LOGOUT": {
      return [];
    }
    default:
      return state;
  }
};
export default creatorList;
