import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { connect } from "react-redux";
import Header from "../../components/header";
import RoundButton from "../../components/roundButton";
import { createProject } from "../../redux/actions/project.action";
const ProjectInitiation = ({ route, navigation, pressFunc, createProject }) => {
  let projectObj = route.params.data;
  const onPressBegin = () => {
    createProject(projectObj);
    navigation.navigate("dashboard");
  };
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          minHeight: 150,
          maxHeight: 150,
        }}
      >
        <Header
          subtitle={`At this phase, our partner will call you and
Discuss the project brief and lock it.`}
          title="Project Initiation"
          enableAvatar={false}
          backFunction={() => navigation.goBack()}
          navigation={navigation}
          disableProfile={true}
          style={{ backgroundColor: "#F0F0F0" }}
        ></Header>
      </View>
      <View
        style={{
          backgroundColor: "#000000",
          margin: 20,
          padding: 15,
          flexWrap: "wrap",
          flexDirection: "row",
          borderRadius: 7,
        }}
      >
        <Text
          style={{
            color: "#fff",
            fontSize: 25,
            flexWrap: "wrap",
            fontWeight: "bold",
            margin: 10,
          }}
        >
          {projectObj.title || ""}
        </Text>
        <View style={{ flexDirection: "row", margin: 10 }}>
          <View
            style={{
              backgroundColor: "#707070",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <Text style={{ color: "#fff", fontSize: 11 }}>Logo Design</Text>
          </View>
          <View
            style={{
              backgroundColor: "#707070",
              borderRadius: 10,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 11,
                padding: 10,
              }}
            >
              Stationary Design
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          marginTop: 5,
          marginHorizontal: 20,
        }}
      >
        <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000000" }}>
          Did you receive the call?
        </Text>
        <Text style={{ marginTop: 20 }}>
          Our partner will call you and discuss the project Briefs again and
          start with the project.
        </Text>
        <View style={{ marginTop: 30 }}>
          <Text>Please give the below OTP to the partner:</Text>
          <Text style={{ fontSize: 59, color: "#000", fontWeight: "bold" }}>
            {projectObj.otp}
          </Text>
        </View>
      </View>
      <View style={{ flex: 1, justifyContent: "flex-end", marginBottom: 20 }}>
        <RoundButton
          pressFunc={() => onPressBegin()}
          title="Let’s begin"
          style={styles.nextPhoto}
        ></RoundButton>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  nextPhoto: {
    backgroundColor: "#000",
    // maxWidth: 400,
    // width: "80%",
    // marginLeft: 30,
    height: 70,
    margin: 20,
    alignContent: "flex-end",
  },
  textInputStyle: {
    fontSize: 11,
    alignSelf: "center",
    width: "75%",
    marginTop: 50,
    borderBottomColor: "#707070",
    borderBottomWidth: 1,
  },
  iconWrapper: {
    height: 20,
    width: 20,
    borderRadius: 100,
    justifyContent: "center",
    backgroundColor: "#707070",
    marginTop: 5,
  },
});
export default connect(null, { createProject })(ProjectInitiation);
