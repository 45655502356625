import { addError, addInfo, addWarning } from "../actions/toast.action";

const initialState = [];
const company = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CLAIM_SUCCESS": {
      return action.payload.data;
    }
    case "FULFIL_CLAIM_SUCCESS": {
      return [...state.filter((x) => x._id != action.payload.data._id)];
    }
    case "FULFIL_CLAIM_FAIL":
    case "FETCH_CLAIM_FAIL": {
      return state;
    }

    case "LOGOUT": {
      return [];
    }
    default:
      return state;
  }
};
export default company;
