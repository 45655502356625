export const initialFeedbackdata = {
  userRatings: [
    {
      user: "",
      rating: "",
      feedback: "",
    },
  ],
  generalRatings: [
    {
      rating: "",
      title: "Experience",
      feedback: "",
    },
    {
      rating: "",
      title: "Response",
      feedback: "",
    },
    {
      rating: "",
      title: "Service",
      feedback: "",
    },
    {
      rating: "",
      title: "Would you refer us?",
      feedback: "",
    },
  ],
};

export const feedbackReducer = (state, action) => {
  switch (action.type) {
    case "ADD_USERS": {
      return {
        ...state,
        userRatings: action.value.map((x) => ({
          user: x.user._id,
          rating: "",
          feedback: "",
        })),
      };
    }
    case "ADD_GENERAL_RATING":
      return {
        ...state,
        generalRatings: state.generalRatings.map((x) =>
          x.title === action.value.title ? { ...x, ...action.value } : x
        ),
      };
    case "ADD_USER_RATING":
      return {
        ...state,
        userRatings: state.userRatings.map((x) =>
          x.user === action.value.user ? { ...x, ...action.value } : x
        ),
      };
    case "UPDATE_DATA": {
      return action.value;
    }

    default:
      return state;
  }
};
